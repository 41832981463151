import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { CommonLayoutComponent } from './layouts/common-layout.component';
import { AuthenticationLayoutComponent } from './layouts/authentication-layout.component';
import { AuthenticatedRoutes } from './pages/authenticated/authenticated.routing';
import { RedirectComponent } from './pages/unauthenticated/redirect/redirect.component';
import { InitializeAppResolver } from './resolvers/initializeApp.resolver';
import { EmptyComponent } from '~/app/emptyComponent';
import { ShareExportPageComponent } from '~/app/pages/authenticated/share-export/share-export-page/share-export-page.component';
import { CanAccessAuthenticatedRoutesGuard } from '~/app/guards/can-access-authenticated-routes.guard';

const routes: Routes = [
	{
		path: '',
		redirectTo: 'login',
		pathMatch: 'full',
	},
	{
		path: 'export/:exportId',
		component: ShareExportPageComponent,
	},
	{
		path: '',
		component: CommonLayoutComponent,
		children: [
			{ path: 'empty', component: EmptyComponent },
			{
				path: '',
				loadChildren: async () => AuthenticatedRoutes,
				canActivate: [CanAccessAuthenticatedRoutesGuard],
				resolve: {
					initializeApp: InitializeAppResolver,
				},
			},
		],
	},

	{
		path: '',
		component: AuthenticationLayoutComponent,
		children: [
			{
				loadChildren: () => import('./pages/unauthenticated/authentication.modules').then((m) => m.AuthenticationModule),
				path: '',
			},
		],
	},

	{
		path: '',
		loadChildren: () => import('./pages/unauthenticated/sharing/sharing.modules').then((m) => m.SharingModule),
	},

	{ path: 'redirect', component: RedirectComponent },
	{
		path: '**',
		redirectTo: '/login',
	},
];

@NgModule({
	imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy', preloadingStrategy: PreloadAllModules })],
	exports: [RouterModule],
	providers: [InitializeAppResolver],
})
export class AppRoutingModule {}
