import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Company } from '#/models/company/company.model';
import { CompanyService } from '#/services/company/company.service';
import { CostUnit } from '#/models/company/dimension/cost-unit.model';
import { CompanyCostUnitService } from '#/services/company/dimension/company-cost-unit.service';
import { UserService } from '~/app/modules/user/user.service';

@Component({
	selector: 'app-company-cost-unit-label',
	templateUrl: './company-cost-unit-label.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CompanyCostUnitLabelComponent implements OnInit, OnDestroy {
	private static objectCache = new Map<string, CostUnit>();
	public value: string;

	@Input()
	public costunit = '';

	@Input()
	public company: Company;

	public costUnitObject?: CostUnit;
	destroyed = false;

	constructor(
		private ref: ChangeDetectorRef,
		private userService: UserService,
		protected companyAPIService: CompanyService,
		private companyCostUnit: CompanyCostUnitService,
	) {}

	ngOnInit() {
		this.loadCostUnit();
	}

	ngOnDestroy() {
		this.destroyed = true;
	}

	updateView() {
		if (!this.destroyed) {
			this.ref.markForCheck();
		}
	}

	loadCostUnit() {
		this.costUnitObject = null;
		if (this.costunit && this.costunit !== '') {
			if (CompanyCostUnitLabelComponent.objectCache.has(this.costunit)) {
				this.costUnitObject = CompanyCostUnitLabelComponent.objectCache.get(this.costunit);
				this.updateView();
			} else {
				this.companyCostUnit
					.getCompanyCostUnit(this.company?.id ?? this.userService.getCurrentLoggedUser().company, this.costunit)
					.then((costunit) => {
						CompanyCostUnitLabelComponent.objectCache.set(this.costunit, costunit);
						this.costUnitObject = costunit;

						this.updateView();
					});
			}
		} else {
			this.updateView();
		}
	}
}
