import { CsvSeparator, DecimalSeparator } from '#/models/company/export';

export interface Column {
	label: string;
	value?: any;
	context?: string;
	condition?: ExportCondition;
	type?: string;
	enabled?: boolean;
	customFieldName?: string;
	showCurrencyToggle?: boolean;
	showDateFormatPicker?: boolean;
	showCustomLabelInput?: boolean;
	sub_column?: ExportColumnValues[];
}

export interface ExportColumnValues extends Column {
	add_currency_symbol: boolean;
	custom_label: string;
	date_format: string;
	showCustomExtraSettings?: boolean;
}

export interface ExportCondition {
	type: XmlConditionType;
	trueBranch: Array<ExportColumnValues>;
	falseBranch: Array<ExportColumnValues>;
}

export interface ExportRequest {
	template?: string;
	columns?: Array<ExportColumnValues>; //deprecated
	travel_columns: Array<ExportColumnValues>; //deprecated
	travel_and_receipt_columns: Array<ExportColumnValues>; //deprecated
	registrationColumns: Array<ExportColumnValues>;
	sorted_export_columns: Array<ExportColumnValues>;
	URL?: string;
	csv_separator: CsvSeparator;
	decimal_separator: DecimalSeparator;
	email: any;
	items: Array<{
		id: string;
		type: ExportType;
	}>;
	type: any;
	overview: boolean;
	combine_travel_and_receipts: boolean;
	save_template: boolean;
	company_export: boolean;
	split_per_person: boolean;
	ubl_attachments: boolean;
	ubl_combine_attachments: boolean;
	ubl_add_pdf: boolean;
	email_add_attachments: boolean;
	stats: boolean;
	timezone: string;
	empty_footer?: boolean;
	split_images_from_data?: boolean;
}

export interface ExportResponse {
	result: string;
	data: { url: string };
	request_id: string;
}

export interface ApiExportNode {
	key: string;
	folderName: string;
	columns: ApiExportColumn[];
	subFolders: ApiExportNode[];
}

export interface ApiExportColumn {
	key: string;
	label: string;
	name: string;
	repeats: boolean;
	showCurrencySymbol: boolean;
	showCustomDateFormat: boolean;
	showCustomLabel: boolean;
	disabledByDefault?: boolean;
}

export enum ExportType {
	RECEIPT = 'receipt',
	CREDITCARD = 'creditcard',
	BOOKING = 'booking',
	REPORT = 'report',
	REGISTRATION = 'registration',
	SUMMARY_REGISTRATION = 'summary_registration',
}

export enum ExportFormat {
	CSV = 'csv',
	XLSX = 'xlsx',
	UBL = 'ubl',
	XML = 'xml',
	PDF = 'pdf',
	DATEV = 'datev',
}

export const ExportFormats: Array<ExportFormat> = [
	ExportFormat.CSV,
	ExportFormat.XLSX,
	ExportFormat.UBL,
	ExportFormat.XML,
	ExportFormat.PDF,
	ExportFormat.DATEV,
];

export enum XmlConditionType {
	IS_CREDIT_LINE = 'isCreditLine',
}
