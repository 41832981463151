<ngx-async-template [promise]="authorizationFlowStatusPromise">
	<ng-template ngx-async-pending>
		{{ 'Loading...' | translate }}
	</ng-template>

	<ng-template ngx-async-success let-authorizationFlowStatus="value">
		<ng-container *ngIf="authorizationFlowStatus.has_flow">
			<div *ngFor="let approval of authorizationFlowStatus.approvals">
				<i class="ti-check mr-1"></i>
				<span translate [translateParams]="{ user_name: approval.user_name }">Approved by %user_name%</span>
			</div>

			<ng-template
				[ngIf]="
					authorizationFlowStatus.next_approvers.length > 0 &&
					authorizationFlowStatus.require_approver_count &&
					!authorizationFlowStatus.require_approver_order
				"
				[ngIfElse]="showApproversList"
			>
				<div *ngIf="authorizationFlowStatus.require_approver_count - authorizationFlowStatus.approvals.length === 1">
					<i class="ti-time mr-1"></i>
					<span translate>Waiting for a approval from</span>
					<span *ngFor="let next_approver of authorizationFlowStatus.next_approvers; last as isLast">{{
						' ' + next_approver.user_name + (isLast ? '' : (' or' | translate))
					}}</span>
				</div>

				<div *ngIf="authorizationFlowStatus.require_approver_count - authorizationFlowStatus.approvals.length > 1">
					<i class="ti-time mr-1"></i>
					<span
						translate
						[translateParams]="{
							approval_count: authorizationFlowStatus.require_approver_count - authorizationFlowStatus.approvals.length
						}"
						>Waiting for %approval_count% approvals from</span
					>
					<span *ngFor="let next_approver of authorizationFlowStatus.next_approvers; last as isLast">{{
						' ' + next_approver.user_name + (isLast ? '' : ',')
					}}</span>
				</div>
			</ng-template>

			<ng-template #showApproversList>
				<div *ngFor="let next_approver of authorizationFlowStatus.next_approvers">
					<i class="ti-time mr-1"></i>
					<span translate [translateParams]="{ user_name: next_approver.user_name }">Waiting for %user_name%</span>
				</div>
			</ng-template>

			<div *ngFor="let waiting_approver of authorizationFlowStatus.waiting_approvers">
				<i class="ti-control-pause mr-1"></i>
				<span translate [translateParams]="{ user_name: waiting_approver.user_name }">%user_name% is waiting on the other approver(s)</span>
			</div>
		</ng-container>
		<ng-container *ngIf="!authorizationFlowStatus.has_flow && lastDeclarationStatusUpdate?.status === 'Approved'">
			<i class="ti-check mr-1"></i>
			<span translate [translateParams]="{ user_name: lastDeclarationStatusUpdate.by.name }"> Approved by %user_name% </span>
		</ng-container>
	</ng-template>
</ngx-async-template>
