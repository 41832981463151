import { Injectable } from '@angular/core';
import { Company, CompanySubCompaniesListAPIRequest } from '#/models/company/company.model';
import { CompanyApiService } from '#/services/company/company-api.service';
import { isValueSet, stringIsSetAndFilled } from '#/util/values';
import { CompanyService } from '#/services/company/company.service';
import { UserService } from '~/app/modules/user/user.service';
import { User } from '#/models/user/user.model';

@Injectable({
	providedIn: 'root',
})
export class SubCompanyService {
	constructor(private companyApiService: CompanyApiService, private companyService: CompanyService, private userService: UserService) {}

	public async userIsParentCompanyUser(): Promise<boolean> {
		const user: User = this.userService.getCurrentLoggedUser();
		const userCompany: Company = this.companyService.getCompanyOfLoggedUser();

		if (!stringIsSetAndFilled(user.company)) {
			return false;
		}

		if (stringIsSetAndFilled(userCompany.parentcompany) && user.canAccessSubcompanies()) {
			// normally a subcompany cannot know about the parent company
			// that is why admin role is required to successfully call getCompanySubCompanies otherwise the API will throw insufficient permissions
			const request = new CompanySubCompaniesListAPIRequest();
			request.company = userCompany.parentcompany;
			request.id = user.company;

			return this.companyApiService
				.getCompanySubCompanies(request)
				.then((subCompanyOfUserCompany) => isValueSet(subCompanyOfUserCompany))
				.catch(() => false);
		}

		return false;
	}
}
