import { SidePanelActions, SidePanelActionTypes } from './side-panel.actions';

export interface SidePanelState {
	open: boolean;
}

export const initialSidePanelState: SidePanelState = {
	open: false,
};

export function sidePanelReducer(state = initialSidePanelState, action: SidePanelActions): SidePanelState {
	switch (action.type) {
		case SidePanelActionTypes.SetSidePanelOpen:
			return {
				...state,
				open: action.payload.open,
			};
		case SidePanelActionTypes.ToggleSidePanel:
			return {
				...state,
				open: !state.open,
			};
		default:
			return state;
	}
}
