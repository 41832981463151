import { Injectable } from '@angular/core';
import { APIService } from '~/app/api/services/api.service';
import { APINotificationsService } from '~/app/api/services/apinotifications.service';
import { Merchant } from '#/models/merchant';
import { ItemsWithHasMoreResultsPromise } from '#/models/appSelectOption.model';
import { objectToQueryParamString } from '#/util/objects';
import { Order } from '#/models/utils/order';
import { CompanyService } from '#/services/company/company.service';
import { UserService } from '~/app/modules/user/user.service';

@Injectable({
	providedIn: 'root',
})
export class MerchantService {
	constructor(
		protected apiService: APIService,
		protected apiNotifications: APINotificationsService,
		protected companyService: CompanyService,
		protected userService: UserService,
	) {}

	public getMerchants(
		start: number = 0,
		searchQuery: string,
		max: number = 100,
		sort: string = 'title',
		sortOrder: Order = Order.ASCENDING,
	): ItemsWithHasMoreResultsPromise<Merchant> {
		const userId: string = this.userService.getCurrentLoggedUser().id;
		const companyId: string = this.companyService.getCompanyOfLoggedUser().id;
		const params: string = objectToQueryParamString({
			user: userId,
			company: companyId,
			start: start,
			max: max,
			search: searchQuery,
			sort: sort,
			sortorder: sortOrder,
		});
		return this.apiService
			.getFromApi(`merchant?${params}`)
			.then((res) => {
				return {
					hasMoreResults: res.data.moreresults,
					items: res.data.merchants?.map((merchant) => new Merchant(merchant)),
				};
			})
			.catch((e) => {
				this.apiNotifications.handleAPIError(e);
				throw e;
			});
	}

	public getMerchantsByIds(ids: Array<string>): Promise<Array<Merchant>> {
		return this.apiService
			.postToApi(`merchant/list`, { ids: ids })
			.then((e) => {
				return e.data.merchants.map((merchant) => new Merchant(merchant));
			})
			.catch((e) => {
				this.apiNotifications.handleAPIError(e);
				throw e;
			});
	}
}
