import { Merchant } from '#/models/merchant';
import { Receipt } from '#/models/transaction/receipt';
import { CompanyGroup } from '#/models/company/company.model';
import { CostCenter } from '#/models/company/dimension/cost-center.model';
import { Project } from '#/models/company/dimension/project.model';
import { CostUnit } from '#/models/company/dimension/cost-unit.model';
import { Category } from '#/models/company/category.model';

export function upsert(items, item, key) {
	const index = items.findIndex((x) => x[key] === item[key]);
	if (index > -1) {
		items[index] = item;
	} else {
		items.push(item);
	}
	return items;
}

export function remove(items, id, key) {
	return items.filter((x) => x[key] !== id);
}

export class Factory {
	public static merchant = (data) => new Merchant(data);
	public static receipt = (data) => new Receipt(data);
	public static company_group = (data) => new CompanyGroup(data);
	public static company_category = (data) => new Category(data);
	public static company_costcenter = (data) => new CostCenter(data);
	public static company_costunit = (data) => new CostUnit(data);
	public static company_project = (data) => new Project(data);
}

export interface OnDismissHandler {
	onDismiss(): void;
}

// The old minimal length, use for current password fields.
export const PasswordOldMinLength = 5;

// The new minimal length, use for new passwords: new accounts/password resets.
export const PasswordMinLength = 8;
export const PasswordMaxLength = 32;
