import { Injectable, NgModule } from '@angular/core';
import { format as formatDate, isMatch } from 'date-fns';
import { enUS } from 'date-fns/locale';
import { CommonModule } from '@angular/common';
import {
	DATE_PICKER_LOCALE,
	DATE_PICKER_TRANSLATIONS,
	DATE_TIME_PICKER_TRANSLATIONS,
	FORM_ERROR_MESSAGES,
	KLP_DATE_FORMATS,
	NgxEnhancyFormsModule,
	SELECT_TRANSLATIONS,
} from '@klippa/ngx-enhancy-forms';
import { LoaderComponent } from './loader/loader.component';
import { PagerComponent } from './pager/pager.component';
import { CurrencyPickerDeprecatedComponent } from './currency-picker/currency-picker-deprecated.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AmountInputComponent } from './amount-input/amount-input.component';
import { CurrencyMaskModule } from 'ng2-currency-mask';
import { PercentageInputComponent } from './percentage-input/percentage-input.component';
import { AttachmentViewerComponent } from './attachment-viewer/attachment-viewer.component';
import { PdfJsViewerModule } from 'ng2-pdfjs-viewer';
import { ImageViewerModule } from '@hallysonh/ngx-imageviewer';
import { LanguagePickerComponent } from './language-picker/language-picker.component';
import { BooleanStatusComponent } from './boolean-status/boolean-status.component';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { InfoTooltipComponent } from './info-tooltip/info-tooltip.component';
import { NgbDateParserFormatter, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { BooleanBadgeComponent } from './boolean-badge/boolean-badge.component';
import { AgreementsTableComponent } from './agreements-table/agreements-table.component';
import { ConfirmModalComponent } from './confirm-modal/confirm-modal.component';
import { VATInputComponent } from './vat-input/vat-input.component';
import { DirectivesModule } from '../../directives/directives.module';
import { DateFormatComponent } from './date-format/date-format.component';
import { DatetimePickerDeprecatedComponent } from './datetime-picker/datetime-picker-deprecated.component';
import { DeprecatedPeriodPickerComponent } from './deprecated-period-picker/period-picker.component';
import { MerchantPickerDeprecatedComponent } from './merchant-picker/merchant-picker-deprecated.component';
import { PaymentMethodPickerComponent } from './payment-method-picker/payment-method-picker.component';
import { AutofocusDirective } from './auto-focus/auto-focus.directive';
import { KlippaProModalComponent } from './klippa-pro-modal/klippa-pro-modal.component';
import { PaymentMethodLabelComponent } from './payment-method-label/payment-method-label.component';
import { PipesModule } from '../../helpers/pipes/pipes.module';
import { ReceiptPickerComponent } from '~/app/shared/ui/receipt-picker/receipt-picker.component';
import { MerchantLabelComponent } from '~/app/shared/ui/merchant-label/merchant-label.component';
import { OCRModePickerComponent } from '~/app/shared/ui/ocr-mode-picker/ocr-mode-picker.component';
import { EmptyViewComponent } from './empty-view/empty-view.component';
import { DisplayModePickerComponent } from '~/app/shared/ui/display-mode-picker/display-mode-picker.component';
import { NotificationFrequencyPickerComponent } from '~/app/shared/ui/notification-frequency-picker/notification-frequency-picker.component';
import { BooleanLockToggleComponent } from '~/app/shared/ui/boolean-lock-toggle/boolean-lock-toggle.component';
import { NotificationMomentPickerComponent } from '~/app/shared/ui/notification-moment-picker/notification-moment-picker.component';
import { SortablejsModule } from 'ngx-sortablejs';
import { InboxNotificationComponent } from '~/app/shared/ui/inbox-notification/inbox-notification.component';
import { InboxNotificationListComponent } from './inbox-notification/inbox-notification-list/inbox-notification-list.component';
import { RouterModule } from '@angular/router';
import { SearchBarComponent } from './search-bar/search-bar.component';
import { DataTableComponent } from './data-table/data-table.component';
import { ToastNotificationComponent } from './toast-notification/toast-notification-entity/toast-notification.component';
import { ToastNotificationsManagerComponent } from './toast-notification/toast-notifications-manager/toast-notifications-manager.component';
import { MultipleEmailComponent } from './forms/composed/multiple-email/multiple-email.component';
import { ButtonComponent } from './button/button.component';
import {
	DateFormatPickerComponent,
	getSupportedFormatsAsDateFns,
} from './forms/composed/pickers/date-format-picker/date-format-picker.component';
import { ErrorMessageComponent } from './error-message/error-message.component';
import { ToggleableSettingComponent } from './forms/composed/toggleable-setting/toggleable-setting.component';
import { ConfigurableSettingComponent } from './forms/composed/configurable-setting/configurable-setting.component';
import { AppSidebarNavigationItemComponent } from './app-sidebar-navigation-item/app-sidebar-navigation-item.component';
import { TableWrapperComponent } from '~/app/shared/ui/table/table-wrapper/table-wrapper.component';
import { TableRowComponent } from '~/app/shared/ui/table/table-row/table-row.component';
import { DataTablesModule } from 'angular-datatables';
import { CompanyProjectLabelComponent } from '~/app/shared/ui/company-project-label/company-project-label.component';
import { CompanyProjectPickerDeprecatedComponent } from '~/app/shared/ui/company-project-picker-deprecated/company-project-picker.component';
import { CompanyCostUnitPickerComponent } from '~/app/shared/ui/company-cost-unit-picker/company-cost-unit-picker.component';
import { CompanyCostCenterPickerComponent } from '~/app/shared/ui/company-cost-center-picker/company-cost-center-picker.component';
import { CountryPickerDeprecatedComponent } from './forms/composed/pickers/country-picker/country-picker-deprecated.component';
import { SelectActionComponent } from './select-action/select-action.component';
import { StatusBadgeComponent } from './status-badge/status-badge.component';
import { MultipleFactorAuthComponent } from '~/app/shared/ui/multiple-factor-auth/multiple-factor-auth.component';
import { ReceiptDeleteModalComponent } from './receipt-delete-modal/receipt-delete-modal.component';
import { ExpenseReportPickerComponent } from './expense-report-picker/expense-report-picker.component';
import { ExpenseReportEditModalComponent } from './expense-report-edit-modal/expense-report-edit-modal.component';
import { ExpenseReportDeleteModalComponent } from './expense-report-delete-modal/expense-report-delete-modal.component';
import { ActionsMenuComponent } from '~/app/shared/ui/actions-menu/actions-menu.component';
import { MfaLoginComponent } from '~/app/shared/ui/login/components/mfa/mfa-login.component';
import { LoginModalComponent } from '~/app/shared/ui/login/components/modals/login-modal/login-modal.component';
import { EmailPasswordComponent } from './login/components/email-password/email-password.component';
import { OauthComponent } from './login/components/oauth/oauth.component';
import { CategoryPickerComponent } from './forms/composed/pickers/dynamic/category-picker.component';
import { AdministrationPickerComponent } from './forms/composed/pickers/dynamic/administration-picker.component';
import { CostCenterPickerComponent } from './forms/composed/pickers/dynamic/administration-dependant/cost-center-picker/cost-center-picker.component';
import { CostUnitPickerComponent } from './forms/composed/pickers/dynamic/administration-dependant/cost-unit-picker/cost-unit-picker.component';
import { ProjectPickerComponent } from './forms/composed/pickers/dynamic/administration-dependant/project-picker/project-picker.component';
import { TableColumnConfigComponent } from '~/app/shared/ui/table/table-column-config/table-column-config.component';
import { CompanyUserLabelComponent } from './company-user-label/company-user-label.component';
import { ChangeStatusModalComponent } from './change-status-modal/change-status-modal.component';
import { CompanyCategoryLabelComponent } from './company-category-label/company-category-label.component';
import { CompanyPaymentMethodLabelComponent } from './company-payment-method-label/company-payment-method-label.component';
import { CompanyAdministrationPicker } from '~/app/shared/ui/company-administration-picker/company-administration-picker.component';
import { CurrencyPickerComponent } from '~/app/shared/ui/forms/composed/pickers/currency-picker/currency-picker.component';
import { CompanyPickerComponent } from '~/app/shared/ui/forms/composed/pickers/dynamic/company-picker.component';
import { CompanyCostCenterLabelComponent } from './company-cost-center-label/company-cost-center-label.component';
import { CompanyCostUnitLabelComponent } from './company-cost-unit-label/company-cost-unit-label.component';
import { BookingStatusBadgeComponent } from '~/app/shared/ui/booking-status-badge/booking-status-badge.component';
import { CompanyAdministrationLabelComponent } from './company-administration-label/company-administration-label.component';
import { DuplicateNotifierComponent } from '~/app/shared/ui/duplicate-notifier/duplicate-notifier.component';
import { RenamableFieldComponent } from './renamable-field/renamable-field.component';
import { ConfirmPromptComponent } from './confirm-prompt/confirm-prompt.component';
import { ReceiptDuplicateModal } from '~/app/shared/ui/receipt-duplicate-modal/receipt-duplicate-modal.component';
import { AuthorizationFlowTooltipComponent } from '~/app/shared/ui/authorization-flow-tooltip/authorization-flow-tooltip.component';
import { IconComponent } from './icon/icon.component';
import { CompanyAuthorizationFlowPickerComponent } from '~/app/shared/ui/company-authorization-flow-picker/company-authorization-flow-picker.component';
import { ExpenseReportEditAuthFlowModal } from '~/app/shared/ui/expense-report-edit-auth-flow-modal/expense-report-edit-auth-flow-modal';
import { CompanyUserPickerComponent } from '~/app/shared/ui/company-user-picker/company-user-picker.component';
import { CompanyDivisionPickerDeprecatedComponent } from '~/app/shared/ui/company-division-picker-deprecated/company-division-picker-deprecated.component';
import { CompanyJournalPickerDeprecatedComponent } from '~/app/shared/ui/company-journal-picker-deprecated/company-journal-picker-deprecated.component';
import { DivisionAdministrationWarningComponent } from '~/app/shared/ui/division-administration-warning/division-administration-warning.component';
import { WarningTextComponent } from '~/app/shared/ui/warning-text/warning-text.component';
import { CompanyIntegrationRelationPickerComponent } from '~/app/shared/ui/company-integration-relation-picker/company-integration-relation-picker.component';
import { FiltersComponent } from '~/app/shared/ui/filters/filters.component';
import { StatusPickerComponent } from '~/app/shared/ui/forms/composed/pickers/status-picker/status-picker.component';
import { CompanyVATCodePickerComponent } from '~/app/shared/ui/company-vat-code-picker/company-vat-code-picker.component';
import { FiltersCountComponent } from '~/app/shared/ui/filters-count/filters-count.component';
import { IconModule } from '~/app/modules/icon/icon.module';
import { MerchantModule } from '~/app/modules/merchant/merchant.module';
import { TableRowIcon } from '~/app/shared/ui/table/table-row-icon/table-row-icon.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { CustomXmlExportComponent } from '~/app/shared/ui/custom-xml-export/custom-xml-export.component';
import { TreeDragDropComponent } from '~/app/shared/ui/custom-xml-export/components/tree-drag-drop/tree-drag-drop.component';
import { MatTreeModule } from '@angular/material/tree';
import { TreeNodeComponent } from '~/app/shared/ui/custom-xml-export/components/tree-node/tree-node.component';
import { TreeNodeSettingsComponent } from '~/app/shared/ui/custom-xml-export/components/tree-node-settings/tree-node-settings.component';
import { ExportTemplatePickerComponent } from './forms/composed/pickers/export-template-picker/export-template-picker.component';
import { UserGroupLabelComponent } from '~/app/shared/ui/user-group-label/user-group-label.component';
import { ExportModulePickerComponent } from './forms/composed/pickers/export-module-picker/export-module-picker.component';
import { PasswordStrengthMeterComponent } from './password-strength-meter/password-strength-meter.component';
import { ChangePasswordModalComponent } from './change-password-modal/change-password-modal.component';
import { FormLanguagePickerComponent } from './forms/composed/pickers/language-picker/language-picker.component';
import { VirusScanResultComponent } from './virus-scan-result/virus-scan-result.component';
import { RoundBadgeComponent } from '~/app/shared/ui/round-badge/round-badge.component';
import { MaterialModule } from '~/app/material.module';
import { NgxAsyncTemplateModule } from '@klippa/ngx-async-template';
import { NgxGridModule } from '@klippa/ngx-grid';
import { TableActionsMenuComponent } from '~/app/shared/ui/table-actions-menu/table-actions-menu.component';
import { ShowMoreComponent } from '~/app/shared/ui/show-more/show-more.component';
import { NavAndCtaComponent } from './nav-and-cta/nav-and-cta.component';
import { HourPickerComponent } from '~/app/shared/ui/forms/composed/pickers/hour-picker/hour-picker.component';
import { DayOfTheWeekPickerComponent } from '~/app/shared/ui/forms/composed/pickers/day-of-week-picker/day-of-the-week-picker.component';
import { ExportStatusPickerComponent } from '~/app/shared/ui/forms/composed/pickers/export-status-picker/export-status-picker.component';
import { ExportBookingStatusPickerComponent } from '~/app/shared/ui/forms/composed/pickers/export-booking-status-picker/export-booking-status-picker.component';
import { TypeOfDocumentPickerComponent } from '~/app/shared/ui/forms/composed/pickers/type-of-document-picker/type-of-document-picker.component';
import { ExpenseTypePickerComponent } from '~/app/shared/ui/forms/composed/pickers/expense-type-picker/expense-type-picker.component';
import { OpenClosedPickerComponent } from '~/app/shared/ui/forms/composed/pickers/open-closed-picker/open-closed-picker.component';
import { KlippaEmailAddressComponent } from './klippa-email-address/klippa-email-address.component';
import { CompanyFeatureFlagPicker } from '~/app/shared/ui/forms/composed/pickers/company-feature-flag-picker/feature-flag-picker.component';
import { TimezonePickerComponent } from './forms/composed/pickers/timezone-picker/timezone-picker.component';
import { MfaConfigModalComponent } from './multiple-factor-auth/modals/mfa-config-modal/mfa-config-modal.component';
import { UserFeatureFlagPickerComponent } from './forms/composed/pickers/user-feature-flag-picker/user-feature-flag-picker.component';
import { AuthorizationFlowStatusPickerComponent } from './authorization-flow-status-picker/authorization-flow-status-picker.component';
import { SocialNetworkConnectionComponent } from './social-network-connection/social-network-connection.component';
import { CardContainerComponent } from './card/card-container/card-container.component';
import { CardTitleComponent } from './card/card-title/card-title.component';
import { CardBodyComponent } from './card/card-body/card-body.component';
import { PartnerAccountComponent } from './partner-account/partner-account.component';
import { KlpEmailComponent } from './klippa-email/klippa-email.component';
import { BusinessRuleActionPickerComponent } from './business-rule-action-picker/business-rule-action-picker.component';
import { BusinessRulesActionStatusPickerComponent } from './business-rule-action-status-picker/business-rule-action-status-picker.component';
import { DocumentTypePickerComponent } from './document-type-picker/document-type-picker.component';
import { BusinessRulesConditionTypePickerComponent } from './business-rules-condition-type-picker/business-rules-condition-type-picker.component';
import { BusinessRulesConditionValueFieldOptionsPickerComponent } from './business-rules-condition-value-field-options-picker/business-rules-condition-value-field-options-picker.component';
import { AmountOperatorPickerComponent } from './amount-operator-picker/amount-operator-picker.component';
import { TextOperatorPickerComponent } from './text-operator-picker/text-operator-picker.component';
import { TypeOperatorPickerComponent } from './document-type-operator-picker/type-operator-picker.component';
import { ReceiptTypePickerComponent } from './receipt-type-picker/receipt-type-picker.component';
import { FinanceTypePickerComponent } from './forms/composed/pickers/static/finance-type-picker.component';
import { DistanceTypePickerComponent } from './distance-type-picker/distance-type-picker.component';
import { TimeRangePickerComponent } from './time-range-picker/time-range-picker.component';
import { TimeUnitPickerComponent } from './time-unit-picker/time-unit-picker.component';
import { AndOrPickerComponent } from './and-or-picker/and-or-picker.component';
import { CustomInputSelectorComponent } from './custom-input-selector/custom-input-selector.component';
import { FullTextOperatorPickerComponent } from './full-text-operator-picker/full-text-operator-picker.component';
import { DateAdapter } from '@angular/material/core';
import { NGX_MAT_DATEFNS_LOCALES, NgxDateFnsDateAdapter } from 'ngx-mat-datefns-date-adapter';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { DateFormat } from '~/app/helpers/pipes/date/date-format.pipe';
import { AmountOrDistancePickerComponent } from './amount-or-distance-picker/amount-or-distance-picker.component';
import { AmountWithCurrencyInputComponent } from './forms/composed/amount-with-currency-input/amount-with-currency-input.component';
import { AuthorizationApproverCountComponent } from './authorization-approver-count/authorization-approver-count.component';
import { DynamicOptionsPickerComponent } from './forms/composed/pickers/dynamic/dynamic-options-picker/dynamic-options-picker.component';
import { InsightsFiltersFieldValueOptionsPickerComponent } from './forms/composed/insights-filters-submission-date-options-picker/insights-filters-insights-filters-field-value-options-picker.component';
import { InsightsFilterTypePickerComponent } from '~/app/shared/ui/forms/composed/insight-filter-type-picker/insight-filter-type-picker.component';
import { InsightsFiltersFieldValueComponent } from './forms/composed/insights-filters-field-value/insights-filters-field-value.component';
import { PaymentConditionPickerComponent } from './forms/composed/pickers/dynamic/payment-condition-picker.component';
import { TimeOperatorPickerComponentComponent } from './forms/composed/pickers/time-operator-picker-component/time-operator-picker-component.component';
import { ExportStatePickerComponent } from './forms/composed/pickers/export-state-picker/export-state-picker.component';
import { EitherOrPickerComponent } from './forms/composed/pickers/either-or-picker/either-or-picker.component';
import { FileViewerDeprecatedComponent } from '~/app/shared/ui/file-viewer-deprecated/file-viewer-deprecated.component';
import { StatusButtonsComponent } from '~/app/shared/ui/forms/composed/status-buttons/status-buttons.component';
import { IntegrationPickerComponent } from '~/app/shared/ui/forms/composed/pickers/dynamic/integration-picker/integration-picker.component';
import { CompanyIntegrationPickerDeprecatedComponent } from './company-integration-picker-deprecated/company-integration-picker-deprecated.component';
import { DivisionPickerComponent } from '~/app/shared/ui/forms/composed/pickers/dynamic/division-picker.component';
import { JournalPickerComponent } from '~/app/shared/ui/forms/composed/pickers/dynamic/journal-picker.component';
import { LedgerPickerComponent } from '~/app/shared/ui/forms/composed/pickers/dynamic/ledger-picker.component';
import { VatCodePickerComponent } from '~/app/shared/ui/forms/composed/pickers/dynamic/vat-code-picker.component';
import { FormCompanyPaymentMethodPickerComponent } from '~/app/shared/ui/forms/composed/pickers/dynamic/custom-payment-method-picker.component';
import { FormCompanyUserPickerComponent } from '~/app/shared/ui/forms/composed/pickers/dynamic/user-picker.component';
import { ExtraInputPickerComponent } from './forms/composed/pickers/static/extra-input-picker.component';
import { SegmentationPickerComponent } from './forms/composed/pickers/static/segmentation-picker.component';
import { MerchantPickerComponent } from '~/app/shared/ui/forms/composed/pickers/dynamic/merchant-picker/merchant-picker.component';
import { CountryPickerComponent } from '~/app/shared/ui/forms/composed/pickers/dynamic/country-picker.component';
import { LocationsPickerComponent } from '~/app/shared/ui/forms/composed/pickers/dynamic/locations-picker.component';
import { VatPercentageComponent } from '~/app/shared/ui/forms/composed/vat-percentage/vat-percentage.component';
import { IntegrationDimensionPickerComponent } from '~/app/shared/ui/forms/composed/pickers/dynamic/integration-dimension-picker.component';
import { NgxGretelBarModule } from '@klippa/ngx-gretel-bar';
import { GAccountInput } from '~/app/shared/ui/forms/composed/g-account-input/g-account-input.component';
import { CurrencyExchangeComponent } from '~/app/shared/ui/forms/subforms/currency-exchange/currency-exchange.component';
import { AttachmentActionsBarComponent } from '~/app/shared/ui/attachment-actions-bar/attachment-actions-bar.component';
import { ChartPickerComponent } from '~/app/shared/ui/forms/composed/pickers/static/chart-picker.component';
import { CurrentApproverComponent } from '~/app/shared/ui/current-approver/current-approver.component';
import { PercentageWithAmountInputComponent } from '~/app/shared/ui/forms/composed/percentage-with-amount-input/percentage-with-amount-input.component';
import { VatLinesInputComponent } from '~/app/shared/ui/forms/composed/vat-lines-input/vat-lines-input.component';
import { AuthorizationFlowComponent } from '~/app/shared/ui/forms/subforms/authorization-flow/authorization-flow.component';
import { AlertBlockComponent } from '~/app/shared/ui/alert-block/alert-block.component';
import { CompanyUserSortComponent } from '~/app/modules/company/components/ui/company-user-sort/company-user-sort.component';
import { ConversationButtonComponent } from './conversation/conversation-button/conversation-button.component';
import { ConversationTimelineComponent } from '~/app/shared/ui/conversation/conversation-timeline/conversation-timeline.component';
import { ReceiptExpenseStatusComponent } from '~/app/modules/receipt/components/receipt-expense-status/receipt-expense-status.component';
import { AskForApprovalComponent } from './forms/composed/ask-for-approval/ask-for-approval.component';
import { TransactionTypePickerComponent } from '~/app/shared/ui/forms/composed/pickers/static/transaction-type-picker.component';
import { SettingWithCollapsibleContentComponent } from './forms/composed/setting-with-collapsible-content/setting-with-collapsible-content.component';
import { PeriodPickerComponent } from '~/app/shared/ui/forms/composed/pickers/static/period-picker.component';
import { IntegrationPaymentMethodPickerComponent } from '~/app/shared/ui/forms/composed/pickers/dynamic/integration-payment-method-picker.component';
import { ScheduledExportFrequencyPickerComponent } from '~/app/shared/ui/forms/composed/pickers/static/scheduledExportFrequencyPicker.component';
import { AuditTrailComponent } from '~/app/shared/ui/audit-trail/audit-trail.component';
import { LatestRemarkComponent } from '~/app/shared/ui/latest-remark/latest-remark.component';
import { AttachmentMissingBadgeComponent } from './attachment-missing-badge/attachment-missing-badge.component';
import { IntegrationPaymentMethodTypesPickerComponent } from '~/app/shared/ui/forms/composed/pickers/static/integration-payment-method-types-picker.component';
import { BookkeepingIntegrationPaymentMethodPickerComponent } from '~/app/shared/ui/forms/composed/pickers/dynamic/bookkeeping-integration-payment-method-picker.component';
import { VelosImportTypePicker } from '~/app/shared/ui/forms/composed/pickers/static/velos-import-type-picker';
import { GroupPickerComponent } from '~/app/shared/ui/forms/composed/pickers/dynamic/group-picker.component';
import { CompensationTypePicker } from '~/app/shared/ui/forms/composed/pickers/static/compensation-type-picker';
import { RoutesPickerComponent } from '~/app/shared/ui/forms/composed/routes-picker/routes-picker.component';
import { CompensationRulesCalculationVisualiserComponent } from './compensation-rules-calculation-visualiser/compensation-rules-calculation-visualiser.component';
import { UserRolePickerComponent } from '~/app/shared/ui/forms/composed/pickers/dynamic/user-role-picker.component';
import { DistanceUnitPicker } from '~/app/shared/ui/forms/composed/pickers/static/distance-unit-picker';
import { CategoryTypePickerComponent } from '~/app/shared/ui/forms/composed/pickers/static/category-type-picker.component';
import { getLocale } from '##/util/locale';
import { ExportFormatPicker } from './forms/composed/pickers/static/export-format-picker';
import { FileDropZoneDirective } from '~/app/directives/fileDropZone.directive';
import { FolderPickerComponent } from '~/app/shared/ui/forms/composed/pickers/dynamic/folder-picker.component';
import { TagsPickerComponent } from '~/app/shared/ui/forms/composed/pickers/dynamic/tags-picker.component';
import { InterfacePickerComponent } from '~/app/shared/ui/forms/composed/pickers/dynamic/interface-picker.component';
import { TravelExpenseTopBarComponent } from './travel-expense-top-bar/travel-expense-top-bar.component';
import { DynamicFormInputComponent } from './forms/composed/dynamic-form-element/dynamic-form-input.component';
import { LastValueInsteadOfUnsetPipe } from '#/pipes/lastValueInsteadOfUnset.pipe';
import { ReportTypePickerComponent } from '~/app/shared/ui/forms/composed/pickers/static/report-type-picker.component';
import { PaymentMethodTypePickerComponent } from '~/app/shared/ui/forms/composed/pickers/static/payment-method-type-picker.component';
import { IntegrationV2PickerComponent } from '~/app/shared/ui/forms/composed/pickers/dynamic/integration-v2-picker/integration-v2-picker.component';
import { BookingStatusPickerComponent } from '~/app/shared/ui/forms/composed/pickers/static/booking-status-picker.component';
import { BookedBatchStatusPickerComponent } from '~/app/shared/ui/forms/composed/pickers/static/booked-batch-status-picker.component';
import { OcrModePicker } from '~/app/shared/ui/forms/composed/pickers/static/ocr-mode-picker';
import { TransactionWarningPopupComponent } from '~/app/shared/ui/transaction-warning-popup/transaction-warning-popup.component';
import { SearchInputComponent } from '~/app/shared/ui/forms/composed/search-input/search-input.component';
import { MarkdownComponent } from '~/app/shared/ui/markdown/markdown.component';
import { LinkPreTransactionComponent } from './link-pre-transaction/link-pre-transaction.component';
import { PreTransactionPickerComponent } from '~/app/shared/ui/forms/composed/pickers/dynamic/pre-transaction-picker/pre-transaction-picker.component';
import { TransportationTypeLabelComponent } from '~/app/shared/ui/transportation-type-label/transportation-type-label.component';
import { IntegrationInputFieldsComponent } from '~/app/shared/ui/forms/subforms/integration-input-fields/integration-input-fields.component';
import { ActiveStatePickerComponent } from '~/app/shared/ui/forms/composed/pickers/static/active-state-picker.component';
import { SvgIconComponent } from './svg-icon/svg-icon.component';
import { DeprecatedActiveStatePickerComponent } from '~/app/shared/ui/active-state-picker/active-state-picker.component';
import { CategoryRegistrationTypePickerComponent } from './forms/composed/pickers/dynamic/category-registration-type-picker.component';
import { TransportationTypePickerComponent } from '~/app/shared/ui/forms/composed/pickers/dynamic/transportation-type-picker.component';
import { GenericDynamicOptionsPickerComponent } from '~/app/shared/ui/forms/composed/pickers/dynamic/generic-dynamic-options-picker.component';
import { RegistrationVisualiserComponent } from '~/app/shared/ui/registration-visualiser/registration-visualiser.component';
import { RegistrationTypeLabelComponent } from '~/app/shared/ui/registration-type-label/registration-type-label.component';
import { ExpenseRegistrationTypePicker } from '~/app/shared/ui/forms/composed/pickers/static/expense-registration-type-picker';
import { XmlConditionPicker } from '~/app/shared/ui/forms/composed/pickers/static/xml-condition-picker';

@Injectable()
class MultiParseDateFnsDateAdapter extends NgxDateFnsDateAdapter {
	// override the parse function to take arrays of parseFormats and try each in turn.
	parse(value: any, parseFormat: any) {
		if (typeof value === 'string') {
			if (value.length === 0) {
				return null;
			}
			// replace all '/' and '.' with '-' for locales that use '/' or '.' seperated dates.
			value = value.replace(/[\/.]/g, '-');
		}
		if (Array.isArray(parseFormat)) {
			for (const format of parseFormat) {
				if (isMatch(value, format)) {
					return super.parse(value, format);
				}
			}
		}
		return null;
	}
}

@NgModule({
	imports: [
		CommonModule,
		FormsModule,
		NgxEnhancyFormsModule,
		NgbTooltipModule,
		NgSelectModule,
		CurrencyMaskModule,
		PdfJsViewerModule,
		ImageViewerModule,
		TranslateModule,
		DirectivesModule,
		SortablejsModule,
		PipesModule,
		RouterModule,
		DataTablesModule,
		ReactiveFormsModule,
		IconModule,
		MerchantModule,
		DragDropModule,
		MatTreeModule,
		MaterialModule,
		NgxAsyncTemplateModule,
		NgxGridModule,
		NgxGretelBarModule,
	],
	declarations: [
		LoaderComponent,
		PagerComponent,
		CurrencyPickerDeprecatedComponent,
		LanguagePickerComponent,
		AmountInputComponent,
		PercentageInputComponent,
		FileViewerDeprecatedComponent,
		AttachmentViewerComponent,
		BooleanStatusComponent,
		InfoTooltipComponent,
		BooleanBadgeComponent,
		AgreementsTableComponent,
		ConfirmModalComponent,
		VATInputComponent,
		DatetimePickerDeprecatedComponent,
		DeprecatedPeriodPickerComponent,
		MerchantPickerDeprecatedComponent,
		PaymentMethodPickerComponent,
		PaymentMethodLabelComponent,
		AutofocusDirective,
		KlippaProModalComponent,
		ReceiptPickerComponent,
		MerchantLabelComponent,
		OCRModePickerComponent,
		EmptyViewComponent,
		DeprecatedActiveStatePickerComponent,
		DisplayModePickerComponent,
		NotificationFrequencyPickerComponent,
		NotificationMomentPickerComponent,
		BooleanLockToggleComponent,
		InboxNotificationComponent,
		InboxNotificationListComponent,
		SearchBarComponent,
		DataTableComponent,
		ToastNotificationsManagerComponent,
		ToastNotificationComponent,
		ButtonComponent,
		HourPickerComponent,
		DayOfTheWeekPickerComponent,
		ExportStatusPickerComponent,
		MultipleEmailComponent,
		ButtonComponent,
		DateFormatPickerComponent,
		ErrorMessageComponent,
		ToggleableSettingComponent,
		AppSidebarNavigationItemComponent,
		TableWrapperComponent,
		TableRowComponent,
		CompanyProjectLabelComponent,
		CompanyProjectPickerDeprecatedComponent,
		CompanyCostUnitPickerComponent,
		CompanyCostCenterPickerComponent,
		CountryPickerDeprecatedComponent,
		SelectActionComponent,
		StatusBadgeComponent,
		MultipleFactorAuthComponent,
		ReceiptDeleteModalComponent,
		ExpenseReportPickerComponent,
		ExpenseReportEditModalComponent,
		ExpenseReportDeleteModalComponent,
		ActionsMenuComponent,
		TableActionsMenuComponent,
		MfaLoginComponent,
		LoginModalComponent,
		OauthComponent,
		EmailPasswordComponent,
		CategoryPickerComponent,
		AdministrationPickerComponent,
		CostCenterPickerComponent,
		CostUnitPickerComponent,
		ProjectPickerComponent,
		TableColumnConfigComponent,
		CompanyUserLabelComponent,
		ChangeStatusModalComponent,
		CompanyCategoryLabelComponent,
		CompanyPaymentMethodLabelComponent,
		CompanyAdministrationPicker,
		CurrencyPickerComponent,
		CompanyCostCenterLabelComponent,
		CompanyCostUnitLabelComponent,
		CompanyIntegrationPickerDeprecatedComponent,
		CompanyIntegrationRelationPickerComponent,
		CompanyDivisionPickerDeprecatedComponent,
		DivisionAdministrationWarningComponent,
		CompanyJournalPickerDeprecatedComponent,
		BookingStatusBadgeComponent,
		CompanyAdministrationLabelComponent,
		DuplicateNotifierComponent,
		RenamableFieldComponent,
		ConfirmPromptComponent,
		ReceiptDuplicateModal,
		AuthorizationFlowTooltipComponent,
		IconComponent,
		TableRowIcon,
		CompanyAuthorizationFlowPickerComponent,
		ExpenseReportEditAuthFlowModal,
		CompanyUserPickerComponent,
		WarningTextComponent,
		FiltersComponent,
		StatusPickerComponent,
		CompanyVATCodePickerComponent,
		FiltersCountComponent,
		CompanyPickerComponent,
		CustomXmlExportComponent,
		TreeDragDropComponent,
		TreeNodeComponent,
		TreeNodeSettingsComponent,
		ExportTemplatePickerComponent,
		UserGroupLabelComponent,
		ExportModulePickerComponent,
		PasswordStrengthMeterComponent,
		ChangePasswordModalComponent,
		FormLanguagePickerComponent,
		VirusScanResultComponent,
		RoundBadgeComponent,
		ShowMoreComponent,
		NavAndCtaComponent,
		ExportBookingStatusPickerComponent,
		TypeOfDocumentPickerComponent,
		ExpenseTypePickerComponent,
		OpenClosedPickerComponent,
		KlippaEmailAddressComponent,
		CompanyFeatureFlagPicker,
		TimezonePickerComponent,
		MfaConfigModalComponent,
		UserFeatureFlagPickerComponent,
		AuthorizationFlowStatusPickerComponent,
		ExtraInputPickerComponent,
		ConfigurableSettingComponent,
		SocialNetworkConnectionComponent,
		CardContainerComponent,
		CardTitleComponent,
		CardBodyComponent,
		PartnerAccountComponent,
		KlpEmailComponent,
		BusinessRuleActionPickerComponent,
		BusinessRulesActionStatusPickerComponent,
		DocumentTypePickerComponent,
		BusinessRulesConditionTypePickerComponent,
		BusinessRulesConditionValueFieldOptionsPickerComponent,
		AmountOperatorPickerComponent,
		TypeOperatorPickerComponent,
		ReceiptTypePickerComponent,
		FinanceTypePickerComponent,
		TextOperatorPickerComponent,
		DistanceTypePickerComponent,
		TimeRangePickerComponent,
		TimeUnitPickerComponent,
		AndOrPickerComponent,
		CustomInputSelectorComponent,
		FullTextOperatorPickerComponent,
		AmountOrDistancePickerComponent,
		AmountWithCurrencyInputComponent,
		AuthorizationApproverCountComponent,
		DynamicOptionsPickerComponent,
		InsightsFiltersFieldValueOptionsPickerComponent,
		InsightsFilterTypePickerComponent,
		InsightsFiltersFieldValueComponent,
		PaymentConditionPickerComponent,
		TimeOperatorPickerComponentComponent,
		ExportStatePickerComponent,
		EitherOrPickerComponent,
		StatusButtonsComponent,
		IntegrationPickerComponent,
		IntegrationV2PickerComponent,
		DivisionPickerComponent,
		JournalPickerComponent,
		LedgerPickerComponent,
		VatCodePickerComponent,
		FormCompanyPaymentMethodPickerComponent,
		FormCompanyUserPickerComponent,
		FormCompanyUserPickerComponent,
		SegmentationPickerComponent,
		MerchantPickerComponent,
		CountryPickerComponent,
		CategoryTypePickerComponent,
		CategoryRegistrationTypePickerComponent,
		BookingStatusPickerComponent,
		BookedBatchStatusPickerComponent,
		LocationsPickerComponent,
		VatPercentageComponent,
		IntegrationDimensionPickerComponent,
		GAccountInput,
		CurrencyExchangeComponent,
		TransactionWarningPopupComponent,
		AttachmentActionsBarComponent,
		ChartPickerComponent,
		CurrentApproverComponent,
		PercentageWithAmountInputComponent,
		VatLinesInputComponent,
		AuthorizationFlowComponent,
		AlertBlockComponent,
		CompanyUserSortComponent,
		ConversationButtonComponent,
		ConversationTimelineComponent,
		ReceiptExpenseStatusComponent,
		AskForApprovalComponent,
		TransactionTypePickerComponent,
		SettingWithCollapsibleContentComponent,
		ReportTypePickerComponent,
		PeriodPickerComponent,
		IntegrationPaymentMethodPickerComponent,
		ScheduledExportFrequencyPickerComponent,
		AuditTrailComponent,
		LatestRemarkComponent,
		AttachmentMissingBadgeComponent,
		IntegrationPaymentMethodTypesPickerComponent,
		BookkeepingIntegrationPaymentMethodPickerComponent,
		VelosImportTypePicker,
		GroupPickerComponent,
		CompensationTypePicker,
		RoutesPickerComponent,
		CompensationRulesCalculationVisualiserComponent,
		UserRolePickerComponent,
		DistanceUnitPicker,
		ExportFormatPicker,
		FileDropZoneDirective,
		FolderPickerComponent,
		TagsPickerComponent,
		InterfacePickerComponent,
		TravelExpenseTopBarComponent,
		DynamicFormInputComponent,
		GenericDynamicOptionsPickerComponent,
		LastValueInsteadOfUnsetPipe,
		PaymentMethodTypePickerComponent,
		OcrModePicker,
		SearchInputComponent,
		MarkdownComponent,
		IntegrationInputFieldsComponent,
		LinkPreTransactionComponent,
		PreTransactionPickerComponent,
		TransportationTypeLabelComponent,
		IntegrationInputFieldsComponent,
		ActiveStatePickerComponent,
		SvgIconComponent,
		TransportationTypePickerComponent,
		RegistrationVisualiserComponent,
		RegistrationTypeLabelComponent,
		ExpenseRegistrationTypePicker,
		XmlConditionPicker,
	],
	exports: [
		NgxEnhancyFormsModule,
		NgxGretelBarModule,
		LoaderComponent,
		PagerComponent,
		CurrencyPickerDeprecatedComponent,
		LanguagePickerComponent,
		AmountInputComponent,
		PercentageInputComponent,
		FileViewerDeprecatedComponent,
		AttachmentViewerComponent,
		AttachmentActionsBarComponent,
		BooleanStatusComponent,
		TableActionsMenuComponent,
		InfoTooltipComponent,
		BooleanBadgeComponent,
		AgreementsTableComponent,
		VATInputComponent,
		DatetimePickerDeprecatedComponent,
		DeprecatedPeriodPickerComponent,
		MerchantPickerDeprecatedComponent,
		PaymentMethodPickerComponent,
		PaymentMethodLabelComponent,
		AutofocusDirective,
		ReceiptPickerComponent,
		MerchantLabelComponent,
		OCRModePickerComponent,
		EmptyViewComponent,
		DeprecatedActiveStatePickerComponent,
		DisplayModePickerComponent,
		NotificationFrequencyPickerComponent,
		NotificationMomentPickerComponent,
		BooleanLockToggleComponent,
		InboxNotificationComponent,
		SearchBarComponent,
		DataTableComponent,
		ToastNotificationComponent,
		ToastNotificationsManagerComponent,
		HourPickerComponent,
		DayOfTheWeekPickerComponent,
		ExportStatusPickerComponent,
		MultipleEmailComponent,
		ButtonComponent,
		DateFormatPickerComponent,
		ErrorMessageComponent,
		ToggleableSettingComponent,
		AppSidebarNavigationItemComponent,
		TableWrapperComponent,
		CompanyProjectLabelComponent,
		CompanyProjectPickerDeprecatedComponent,
		CompanyCostUnitPickerComponent,
		CompanyCostCenterPickerComponent,
		CountryPickerDeprecatedComponent,
		SelectActionComponent,
		StatusBadgeComponent,
		MultipleFactorAuthComponent,
		ReceiptDeleteModalComponent,
		ExpenseReportPickerComponent,
		ExpenseReportEditModalComponent,
		ExpenseReportDeleteModalComponent,
		ActionsMenuComponent,
		MfaLoginComponent,
		LoginModalComponent,
		OauthComponent,
		EmailPasswordComponent,
		CategoryPickerComponent,
		CategoryTypePickerComponent,
		CategoryRegistrationTypePickerComponent,
		BookingStatusPickerComponent,
		BookedBatchStatusPickerComponent,
		AdministrationPickerComponent,
		CostCenterPickerComponent,
		CostUnitPickerComponent,
		ProjectPickerComponent,
		CompanyUserLabelComponent,
		ChangeStatusModalComponent,
		CompanyCategoryLabelComponent,
		CompanyPaymentMethodLabelComponent,
		CurrencyPickerComponent,
		CompanyCostCenterLabelComponent,
		CompanyCostUnitLabelComponent,
		CompanyAdministrationPicker,
		CompanyAdministrationLabelComponent,
		CompanyIntegrationPickerDeprecatedComponent,
		CompanyIntegrationRelationPickerComponent,
		CompanyDivisionPickerDeprecatedComponent,
		DivisionAdministrationWarningComponent,
		CompanyJournalPickerDeprecatedComponent,
		RenamableFieldComponent,
		ConfirmModalComponent,
		ConfirmPromptComponent,
		ReceiptDuplicateModal,
		AuthorizationFlowTooltipComponent,
		IconComponent,
		TableRowIcon,
		CompanyAuthorizationFlowPickerComponent,
		ExpenseReportEditAuthFlowModal,
		CompanyUserPickerComponent,
		WarningTextComponent,
		FiltersComponent,
		StatusPickerComponent,
		CompanyVATCodePickerComponent,
		BookingStatusBadgeComponent,
		FiltersCountComponent,
		CompanyPickerComponent,
		UserGroupLabelComponent,
		ExportModulePickerComponent,
		PasswordStrengthMeterComponent,
		ChangePasswordModalComponent,
		FormLanguagePickerComponent,
		NgxAsyncTemplateModule,
		RoundBadgeComponent,
		NavAndCtaComponent,
		ExportTemplatePickerComponent,
		ShowMoreComponent,
		TypeOfDocumentPickerComponent,
		ExportBookingStatusPickerComponent,
		ExpenseTypePickerComponent,
		OpenClosedPickerComponent,
		KlippaEmailAddressComponent,
		NgxGridModule,
		CompanyFeatureFlagPicker,
		TimezonePickerComponent,
		MfaConfigModalComponent,
		UserFeatureFlagPickerComponent,
		FormsModule,
		ReactiveFormsModule,
		ExtraInputPickerComponent,
		ConfigurableSettingComponent,
		SocialNetworkConnectionComponent,
		CardContainerComponent,
		CardTitleComponent,
		CardBodyComponent,
		PartnerAccountComponent,
		KlpEmailComponent,
		BusinessRuleActionPickerComponent,
		BusinessRulesActionStatusPickerComponent,
		DocumentTypePickerComponent,
		BusinessRulesConditionTypePickerComponent,
		BusinessRulesConditionValueFieldOptionsPickerComponent,
		AmountOperatorPickerComponent,
		TypeOperatorPickerComponent,
		ReceiptTypePickerComponent,
		FinanceTypePickerComponent,
		TextOperatorPickerComponent,
		DistanceTypePickerComponent,
		TimeRangePickerComponent,
		TimeUnitPickerComponent,
		AndOrPickerComponent,
		CustomInputSelectorComponent,
		FullTextOperatorPickerComponent,
		AmountOrDistancePickerComponent,
		AmountWithCurrencyInputComponent,
		AuthorizationApproverCountComponent,
		DynamicOptionsPickerComponent,
		InsightsFiltersFieldValueOptionsPickerComponent,
		InsightsFilterTypePickerComponent,
		InsightsFiltersFieldValueComponent,
		PaymentConditionPickerComponent,
		TimeOperatorPickerComponentComponent,
		ExportStatePickerComponent,
		EitherOrPickerComponent,
		StatusButtonsComponent,
		IntegrationPickerComponent,
		IntegrationV2PickerComponent,
		DivisionPickerComponent,
		JournalPickerComponent,
		LedgerPickerComponent,
		VatCodePickerComponent,
		FormCompanyPaymentMethodPickerComponent,
		FormCompanyUserPickerComponent,
		FormCompanyUserPickerComponent,
		SegmentationPickerComponent,
		MerchantPickerComponent,
		CountryPickerComponent,
		LocationsPickerComponent,
		VatPercentageComponent,
		IntegrationDimensionPickerComponent,
		GAccountInput,
		CurrencyExchangeComponent,
		TransactionWarningPopupComponent,
		AttachmentActionsBarComponent,
		ChartPickerComponent,
		CurrentApproverComponent,
		PercentageWithAmountInputComponent,
		VatLinesInputComponent,
		AuthorizationFlowComponent,
		AlertBlockComponent,
		ConversationButtonComponent,
		ReceiptExpenseStatusComponent,
		AskForApprovalComponent,
		TransactionTypePickerComponent,
		SettingWithCollapsibleContentComponent,
		ReportTypePickerComponent,
		PeriodPickerComponent,
		IntegrationPaymentMethodPickerComponent,
		ScheduledExportFrequencyPickerComponent,
		AuditTrailComponent,
		LatestRemarkComponent,
		AttachmentMissingBadgeComponent,
		IntegrationPaymentMethodTypesPickerComponent,
		BookkeepingIntegrationPaymentMethodPickerComponent,
		VelosImportTypePicker,
		GroupPickerComponent,
		CompensationTypePicker,
		RoutesPickerComponent,
		CompensationRulesCalculationVisualiserComponent,
		UserRolePickerComponent,
		DistanceUnitPicker,
		CustomXmlExportComponent,
		FileDropZoneDirective,
		ExportFormatPicker,
		FolderPickerComponent,
		TagsPickerComponent,
		InterfacePickerComponent,
		TravelExpenseTopBarComponent,
		DynamicFormInputComponent,
		GenericDynamicOptionsPickerComponent,
		LastValueInsteadOfUnsetPipe,
		PaymentMethodTypePickerComponent,
		OcrModePicker,
		SearchInputComponent,
		MarkdownComponent,
		IntegrationInputFieldsComponent,
		LinkPreTransactionComponent,
		PreTransactionPickerComponent,
		TransportationTypeLabelComponent,
		IntegrationInputFieldsComponent,
		ActiveStatePickerComponent,
		SvgIconComponent,
		TransportationTypePickerComponent,
		RegistrationVisualiserComponent,
		RegistrationTypeLabelComponent,
		ExpenseRegistrationTypePicker,
		XmlConditionPicker,
	],
	providers: [
		{ provide: NgbDateParserFormatter, useClass: DateFormatComponent },
		{ provide: DateAdapter, useClass: MultiParseDateFnsDateAdapter },
		{ provide: NGX_MAT_DATEFNS_LOCALES, useValue: [enUS] },
		{ provide: DATE_PICKER_LOCALE, useValue: getLocale },
		{
			provide: KLP_DATE_FORMATS,
			useValue: (format) => ({
				parse: {
					// if the selected format fails to parse try all supported and all long locale formats.
					dateInput: [format, ...getSupportedFormatsAsDateFns(), 'PP', 'PPP', 'PPPP'],
				},
				display: {
					dateInput: format, // Display as selected format.
					monthLabel: 'MMM',
					monthYearLabel: 'MMM yyyy',
					dateA11yLabel: 'MMM dd, yyyy',
					monthYearA11yLabel: 'MMMM yyyy',
				},
			}),
		},
		{
			provide: FORM_ERROR_MESSAGES,
			deps: [TranslateService],
			useFactory: (translate: TranslateService) => {
				return {
					min: () => translate.instant(_('Use a number larger than %min%')),
					max: () => translate.instant(_('Use a number smaller than %max%')),
					required: () => translate.instant(_('Required')),
					email: () => translate.instant(_('Use a valid email address')),
					minLength: () => translate.instant(_('Has to be longer than %minLength% character(s)')),
					maxLength: () => translate.instant(_('Has to be shorter than %maxlength% character(s)')),
					pattern: () => translate.instant(_('This input is not valid')),
					date: () => translate.instant(_('Enter a valid date')),
					MatchPassword: () => translate.instant(_('Passwords must match')),
				};
			},
		},
		{
			provide: DATE_TIME_PICKER_TRANSLATIONS,
			deps: [TranslateService, DateFormat],
			useFactory: (translate: TranslateService, dateFormat: DateFormat) => {
				return {
					placeholder: () => translate.instant(_('Select date')),
					selectDays: () => translate.instant(_('Select day(s)')),
					selectedDate: (date: Date) => dateFormat.transform(date),
					daysSelected: (amount) => translate.instant(_(`%amount% days selected`), { amount }),
					selectedInMonth: (date: Date) => {
						const monthName = formatDate(date, 'MMMM', { locale: getLocale() });
						return translate.instant(_(` in %monthName%`), { monthName });
					},
				};
			},
		},
		{
			provide: DATE_PICKER_TRANSLATIONS,
			deps: [TranslateService],
			useFactory: (translate: TranslateService) => {
				return {
					placeholder: () => translate.instant(_('Select date')),
				};
			},
		},
		{
			provide: SELECT_TRANSLATIONS,
			deps: [TranslateService],
			useFactory: (translate: TranslateService) => {
				return {
					placeholder: () => translate.instant(_('Pick an option')),
					amountSelected: (amount) => {
						return translate.instant(_('%amount% selected'), { amount });
					},
				};
			},
		},
	],
})
export class UiModule {}
