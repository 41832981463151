export enum SelectActionEnum {
	edit = 'edit',
	delete = 'delete',
	retract = 'retract',
	submit = 'submit',
	change_status = 'change_status',
	duplicate = 'duplicate',
	split = 'split',
	export = 'export',
}

export interface SelectActionInterface {
	title: string;
	action: SelectActionEnum;
	multiple: boolean; // Multiple allows the action, when multiple items are selected.
}
