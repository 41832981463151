<klp-form-select
	[options]="currencies"
	[(ngModel)]="innerValue"
	[ngClass]="{ showErrors: isInErrorState(), noBorderLeft: !hasBorderLeft }"
	(ngModelChange)="setInnerValueAndNotify($event)"
	[placeholder]="'All' | translate"
	[multiple]="multiple"
	[clearable]="clearable"
	[disabled]="disabled"
	[customSearchFn]="search"
	[truncateOptions]="false"
	dropdownAlignment="right"
>
</klp-form-select>
