import { Injectable } from '@angular/core';
import { APIService } from '~/app/api/services/api.service';
import { APINotificationsService } from '~/app/api/services/apinotifications.service';
import {
	ExportRegistrationResponse,
	RegistrationExportRequest,
	RegistrationGovernmentSummaryExportRequest,
} from '#/models/transaction/registration-export';

@Injectable({
	providedIn: 'root',
})
export class RegistrationExportService {
	constructor(private apiService: APIService, private notifications: APINotificationsService) {}

	public exportRegistrations(companyId: string, request: RegistrationExportRequest): Promise<ExportRegistrationResponse> {
		return this.apiService.postToApi('company/' + companyId + '/registrations/export/complete', request).catch((r) => {
			this.notifications.handleAPIError(r);
			throw r;
		});
	}

	public exportGovernmentSummaryRegistrations(
		companyId,
		request: RegistrationGovernmentSummaryExportRequest,
	): Promise<ExportRegistrationResponse> {
		return this.apiService.postToApi('company/' + companyId + '/registrations/export/summary', request).catch((r) => {
			this.notifications.handleAPIError(r);
			throw r;
		});
	}
}
