<section *ngIf="anySelected && filteredActions.length > 0">
	<app-button variant="greenFilled" size="small" (click)="toggleActionList()">
		{{ 'Actions' | translate }} <i class="ti-angle-down font-size-11"></i>
	</app-button>
	<ul *ngIf="showActionList" class="actionsContainer">
		<li *ngFor="let action of filteredActions">
			<app-button [hasBorder]="false" variant="contextMenuItem" [fullWidth]="true" size="large" (click)="emitAction(action.action)">
				{{ action.title }}
			</app-button>
		</li>
	</ul>
</section>
