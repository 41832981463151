import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import * as fromIcon from '../../models/icon.reducer';
import { AppState } from '../../../../reducers';
import { Subscription } from 'rxjs';
import { environment } from '../../../../../environments/environment';

@Component({
	selector: 'app-icon-image',
	templateUrl: './icon-image.component.html',
})
export class IconImageComponent implements OnInit, OnDestroy {
	@Input() IconID: string;
	public image: string;
	public imageURL: string;

	private store: Store<AppState>;
	private iconSub: Subscription;

	constructor(store: Store<AppState>) {
		this.store = store;
	}

	ngOnInit() {
		this.iconSub = this.store
			.select('icon')
			.pipe(select(fromIcon.getItemById(this.IconID)))
			.subscribe((val) => {
				if (val && val.hasImage() && val.getID() !== this.image) {
					this.image = val.getID();
					this.imageURL = `${environment.api_url}/api/v1/icon/${this.image}/image?w=48&h=48`;
				}
			});
	}

	ngOnDestroy(): void {
		this.iconSub.unsubscribe();
	}
}
