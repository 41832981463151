<klp-grid-container [fluid]="true">
	<app-nav-and-cta>
		<klp-gretel-bar></klp-gretel-bar>
		<div class="header-actions">
			<app-button variant="greenFilled" (click)="openFilters()">
				<span translate>Filters</span>
				<app-filters-count [requestQuery]="paginatedExpenseReportsRequest"></app-filters-count>
			</app-button>
			<app-button *ngIf="canAddNewReport()" variant="greenFilled" (click)="openCreateReportModal()">
				<app-icon variant="plus" color="white"></app-icon>&nbsp;<span translate>Add report</span>
			</app-button>
		</div>
	</app-nav-and-cta>
	<section>
		<div class="card">
			<div class="card-block">
				<ngx-async-template [promise]="initialReportDeclarationsPromise">
					<ng-template ngx-async-error let-errorMessages="errorMessages">
						<app-error-message [errorMessages]="errorMessages"></app-error-message>
					</ng-template>
					<ng-template ngx-async-pending>
						<app-loader-deprecated></app-loader-deprecated>
					</ng-template>
					<ng-template ngx-async-success>
						<div *ngIf="reportDeclarations.count === 0">
							<app-empty-view [text]="getEmptyListTitle()"></app-empty-view>
						</div>
						<div *ngIf="showSelectActions" class="actionMenu">
							<app-select-action [actions]="getSelectActions" [selected]="selected" (onAction)="executeRequestedSelectAction($event)">
							</app-select-action>
						</div>
						<app-table
							*ngIf="reportDeclarations.count > 0"
							[rows]="reportDeclarations.reports"
							[total]="reportDeclarations.count"
							[start]="paginatedExpenseReportsRequest.start"
							[max]="paginatedExpenseReportsRequest.max"
							[sortingParameters]="{ sort: paginatedExpenseReportsRequest.sort, sortorder: paginatedExpenseReportsRequest.sortorder }"
							[columns]="columns"
							typeName="report"
							[itemName]="'report' | translate"
							[itemsName]="'reports' | translate"
							[hasClickableRows]="true"
							[rowActions]="getRowActions"
							[selectAllItemFn]="selectAllReportsOnAllPages"
							(onRowAction)="executeRequestedRowAction($event)"
							(clickRow)="onRowClicked($event)"
							(navigateTable)="navigateTable($event)"
							(openSelectActions)="openSelectActions($event)"
							(sortTable)="sortTable($event)"
							(onSortingArrowClicked)="fetchSortedColumns($event); updateRouteWithSortingParams($event)"
						></app-table>
					</ng-template>
				</ngx-async-template>
			</div>
		</div>
	</section>
</klp-grid-container>

<ng-template #createReportModal>
	<app-expense-report-edit-modal
		(onSave)="closeCreateReportModal()"
		(onDismiss)="closeCreateReportModal()"
		[company]="company"
		[user]="user"
	>
	</app-expense-report-edit-modal>
</ng-template>

<ng-template #editReportModal>
	<app-expense-report-edit-modal
		(onSave)="closeEditReportModal()"
		(onDismiss)="closeEditReportModal()"
		[expenseReport]="currentReport"
		[company]="company"
		[user]="user"
	>
	</app-expense-report-edit-modal>
</ng-template>

<app-export-form-modal #exportFormModal [exportIds]="exportIds"> </app-export-form-modal>

<div *ngIf="showFilters">
	<app-filters
		[requestQuery]="paginatedExpenseReportsRequest"
		[filtersLocalStorageKey]="filtersLocalStorageKey"
		[filterOptions]="filterOptions"
		(onDismiss)="closeFilters()"
		(onSave)="updateRequestFilters($event)"
	>
	</app-filters>
</div>
