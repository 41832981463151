import { ListAPIRequest, QueryParam } from '../listApiRequest.model';
import { isValueSet } from '#/util/values';
import { Order } from '#/models/utils/order';

export class CompanyObjectListAPIRequest extends ListAPIRequest {
	active: boolean;
	company: string;
	administration: string;
	sort: string;
	sortorder: Order;
	search: string;
	code: string;
	list: boolean;
	groups: string[];

	getQueryParams(): QueryParam[] {
		const parts = super.getQueryParams();
		if (isValueSet(this.active)) {
			if (this.active) {
				parts.push({ key: 'active', value: '1' });
			} else {
				parts.push({ key: 'active', value: '0' });
			}
		}

		if (this.search !== undefined && this.search !== '') {
			parts.push({ key: 'search', value: this.search });
		}

		if (this.code !== undefined && this.code !== '') {
			parts.push({ key: 'code', value: this.code });
		}

		if (this.groups !== undefined && this.groups && this.groups.length > 0) {
			parts.push({ key: 'groups', value: this.groups.join(',') });
		}

		if (this.administration !== undefined && this.administration !== '' && this.administration !== null) {
			parts.push({ key: 'administration', value: this.administration });
		}

		return parts;
	}
}
