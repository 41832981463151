import { Injectable } from '@angular/core';
import { APIService } from '~/app/api/services/api.service';
import { APINotificationsService } from '~/app/api/services/apinotifications.service';
import { NotificationService } from '~/app/services/notification.service';
import { PreTransactionApiModel } from '#/models/transaction/pre-transaction/apiModel';
import { objectToQueryParamString } from '#/util/objects';

@Injectable({
	providedIn: 'root',
})
export class PreTransactionService {
	private txCache = new Map<string, { timestamp: number; data: Promise<PreTransactionApiModel> }>();
	private baseUrl: string = '/api/v1/pre-transactions';

	constructor(
		protected apiService: APIService,
		protected notifications: APINotificationsService,
		protected notificationService: NotificationService,
	) {}

	public async getPreTransactionById(id: string, fromCacheIfNewerThanMsAgo: number = 0): Promise<PreTransactionApiModel> {
		if (fromCacheIfNewerThanMsAgo > 0) {
			const cached = this.txCache.get(id);
			if (cached && cached.timestamp + fromCacheIfNewerThanMsAgo > new Date().getTime()) {
				return cached.data;
			}
		}
		const promise = this.apiService
			.get(this.baseUrl + '/' + id)
			.then((res) => {
				return new PreTransactionApiModel(res.data);
			})
			.catch((r) => {
				this.txCache.delete(id);
				this.notifications.handleAPIError(r);
				throw r;
			});
		this.txCache.set(id, { timestamp: new Date().getTime(), data: promise });
		return promise;
	}

	public async getPreTransactionByIds(ids: Array<string>): Promise<Array<PreTransactionApiModel>> {
		return this.apiService
			.post(this.baseUrl + '/list', { ids })
			.then((res) => {
				const result: Array<PreTransactionApiModel> = res.data.preTransactions.map(
					(preTransaction) => new PreTransactionApiModel(preTransaction),
				);
				result.forEach((e) => {
					this.txCache.set(e.id, { timestamp: new Date().getTime(), data: Promise.resolve(e) });
				});
				return result;
			})
			.catch((r) => {
				this.notifications.handleAPIError(r);
				throw r;
			});
	}
	public async getPreTransactions(
		start: number,
		itemsPerPage: number,
		search?: string,
		sortField?: string,
		sortOrder?: 'ASC' | 'DESC',
	): Promise<PreTransactionListData> {
		const queryParams = objectToQueryParamString({
			start,
			search,
			max: itemsPerPage,
			sort: sortField,
			sortorder: sortOrder?.toLowerCase(),
		});
		return this.apiService
			.getFromApi(`pre-transactions?${queryParams}`)
			.then((res) => {
				const result = new PreTransactionListData(res.data);
				result.preTransactions.forEach((e) => {
					this.txCache.set(e.id, { timestamp: new Date().getTime(), data: Promise.resolve(e) });
				});
				return result;
			})
			.catch((r) => {
				this.notifications.handleAPIError(r);
				throw r;
			});
	}

	public async createPreTransaction(preTransaction: PreTransactionApiModel): Promise<PreTransactionApiModel> {
		return this.apiService
			.post(this.baseUrl, preTransaction)
			.then((res): PreTransactionApiModel => {
				return new PreTransactionApiModel(res['data']);
			})
			.catch((r) => {
				this.notifications.handleAPIError(r);
				throw r;
			});
	}

	public async updatePreTransaction(preTransaction: PreTransactionApiModel, id: string): Promise<PreTransactionApiModel> {
		return this.apiService
			.patch(this.baseUrl + '/' + id, preTransaction)
			.then((res): PreTransactionApiModel => {
				return new PreTransactionApiModel(res['data']);
			})
			.catch((r) => {
				this.notifications.handleAPIError(r);
				throw r;
			});
	}

	public async deletePreTransaction(id: string): Promise<void> {
		return this.apiService.delete(this.baseUrl + '/' + id).catch((r) => {
			this.notifications.handleAPIError(r);
			throw r;
		});
	}

	public async getPreTransactionsToLink(
		transactionId: string,
		start: number,
		searchQuery: string,
		itemsPerPage: number = 100,
	): Promise<PreTransactionListData> {
		const queryParams = objectToQueryParamString({
			start,
			max: itemsPerPage,
			search: searchQuery,
		});
		return this.apiService
			.getFromApi(`receipt/${transactionId}/pretransactions/toLink?${queryParams}`)
			.then((res) => new PreTransactionListData(res.data))
			.catch((error) => {
				this.notifications.handleAPIError(error);
				throw error;
			});
	}
}

export class PreTransactionListData {
	preTransactions: PreTransactionApiModel[] = [];
	count: number;
	moreResults: boolean;

	constructor(data = null) {
		if (data) {
			Object.assign(this, data);

			if (data.preTransactions) {
				this.preTransactions = data.preTransactions.map((preTransaction) => new PreTransactionApiModel(preTransaction));
			}
		}
	}
}
