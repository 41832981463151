<div class="booking-status-badge" [ngClass]="{ reportVariant: variant === 'report' }">
	<span *ngIf="isbooked && !canbePickedUpByQueue && !backgroundQueueError">
		<app-icon color="primary" variant="check"></app-icon>
		<span [attr.title]="getBookingInformation()" class="booking-status-information">
			<span *ngIf="variant === 'receipt'" translate>Booked!</span>
			<span *ngIf="variant === 'report'" translate>This report has been booked!</span>
		</span>
	</span>
	<span *ngIf="canbePickedUpByQueue && !backgroundQueueError">
		<app-icon color="gray" variant="time"></app-icon>
		<span [ngbTooltip]="'This item will be booked soon, the booking might take up to a few hours to complete' | translate" translate
			>Booked soon
		</span>
	</span>
	<span *ngIf="backgroundQueueError">
		<app-icon color="gray" variant="alert"></app-icon>
		<span [ngbTooltip]="('Background booking error:' | translate) + '\n' + backgroundQueueError" translate>Booking error </span>
	</span>
	<a *ngIf="!isbooked && isBookable() && !this.backgroundQueueError && company.authorizations.size > 0" (click)="bookReceipt()">
		<app-icon color="gray" variant="cloudUp"></app-icon>
		<span translate>Book item</span>
	</a>
</div>
