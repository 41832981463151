import { Injectable } from '@angular/core';
import { APIService } from '~/app/api/services/api.service';
import {
	ExportTemplate,
	ExportTemplateDataList,
	ExportTemplateUserRole,
} from '~/app/modules/export-templates/models/export-template.model';
import { ExportType } from '#/models/company/exporterInterfaces';
import { User, UserRole } from '#/models/user/user.model';

@Injectable({
	providedIn: 'root',
})
export class ExportTemplatesService {
	constructor(private apiService: APIService) {}

	public getAllAvailableTemplates(exportType: ExportType) {
		const url = 'user/exportTemplate' + (exportType ? `?type=${exportType}` : '');
		return this.apiService.getFromApi(url);
	}

	public getAllGlobalAdminTemplates(): Promise<ExportTemplateDataList> {
		const url = 'admin/exportTemplate';
		return this.apiService.getFromApi(url);
	}

	public getAllCompanyTemplates(companyId: string): Promise<ExportTemplateDataList> {
		const url = 'company/' + companyId + '/exportTemplate?access_types=company,global';
		return this.apiService.getFromApi(url);
	}

	public getAllUserTemplates(access_type: ExportTemplateUserRole[]): Promise<ExportTemplateDataList> {
		const url = 'user/exportTemplate?access_types=' + access_type.join(',');
		return this.apiService.getFromApi(url);
	}

	public getTemplates(exportType: ExportType, userRole: ExportTemplateUserRole, companyId?: string): Promise<ExportTemplateDataList> {
		const url = this.urlSwitcher(userRole, companyId) + (exportType ? `?type=${exportType}` : '');
		return this.apiService.getFromApi(url);
	}

	saveTemplate(data: ExportTemplate, userRole: ExportTemplateUserRole, companyId?: string) {
		const url = this.urlSwitcher(userRole, companyId);
		return this.apiService.postToApi(url, data);
	}

	getTemplate(templateId: string, userRole: ExportTemplateUserRole, companyId?: string) {
		const url = this.urlSwitcher(userRole, companyId);
		return this.apiService.getFromApi(url + '/' + templateId).then((res) => res.data);
	}

	updateTemplate(templateId: string, data: ExportTemplate, userRole: ExportTemplateUserRole, companyId?: string) {
		const url = this.urlSwitcher(userRole, companyId);
		return this.apiService.patchToApi(url + '/' + templateId, data);
	}

	deleteTemplate(templateId: string, userRole: ExportTemplateUserRole, companyId?: string) {
		const url = this.urlSwitcher(userRole, companyId);
		return this.apiService.deleteToApi(url + '/' + templateId);
	}

	duplicateTemplate(id: string, userRole: ExportTemplateUserRole, companyId?: string) {
		return this.getTemplate(id, userRole, companyId).then((template) => {
			const exportTemplateRequest: ExportTemplate = new ExportTemplate(template);
			exportTemplateRequest.description = 'Copy of ' + exportTemplateRequest.description;
			return this.saveTemplate(exportTemplateRequest, userRole, companyId);
		});
	}

	duplicateDownTemplate(templateId: string, body: any) {
		const url: string = 'admin/exportTemplate/' + templateId + '/duplicate';
		return this.apiService.postToApi(url, body);
	}

	private urlSwitcher(userRole: ExportTemplateUserRole, companyId?: string): string {
		switch (userRole) {
			case ExportTemplateUserRole.admin:
				return 'admin/exportTemplate';
			case ExportTemplateUserRole.company:
				return 'company/' + companyId + '/exportTemplate';
			case ExportTemplateUserRole.user:
				return 'user/exportTemplate';
		}
	}

	isTemplateOwner(item: ExportTemplate, user: User) {
		return item.users?.[0] === user.id;
	}
	isCompanyTemplate(item: ExportTemplate, user: User) {
		return item.users == null && item.access_type === 'company' && user.hasCompanyAdminRole();
	}
	isGlobalTemplate(item: ExportTemplate, user: User) {
		return item.users == null && item.access_type === 'global' && user.hasReadWriteAdminRoleOrHigher();
	}
	canBeEdited(item: ExportTemplate, user: User) {
		if (user.hasReadWriteAdminRoleOrHigher()) {
			return true;
		}
		if (user.hasRole(UserRole.CompanyAdmin)) {
			if (item.access_type === 'company') {
				return item.can_edit;
			}
			if (item.access_type === 'user') {
				return this.isTemplateOwner(item, user);
			}
		}

		// if you are not a god admin, or a company admin, you can only edit template that are created by yourself
		return this.isTemplateOwner(item, user);
	}

	getExportType(userRole: ExportTemplateUserRole, companyId?: string) {
		const url = this.urlSwitcher(userRole, companyId) + '/types';
		return this.apiService.getFromApi(url);
	}
}
