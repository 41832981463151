import { Component, EventEmitter, Output, ViewChild } from '@angular/core';
import { DataTableBase } from '~/app/shared/ui/data-tables/data-table-base';
import { PreTransactionFrontendModel } from '#/models/transaction/pre-transaction/frontendModel';
import { PreTransactionService } from '#/services/transaction/pre-transaction.service';
import { memoize } from 'lodash';
import { MerchantService } from '~/app/modules/merchant/merchant.service';
import { Merchant } from '#/models/merchant';
import { TranslateService } from '@ngx-translate/core';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { DataTableComponent } from 'wutu-data-table';
import { Company } from '#/models/company/company.model';
import { CompanyService } from '#/services/company/company.service';
import { TranslationReplaceService } from '~/app/services/translation-replace.service';

@Component({
	selector: 'app-pre-transactions-data-table',
	templateUrl: './pre-transactions-data-table.component.html',
	styleUrls: ['./pre-transactions-data-table.component.scss'],
})
export class PreTransactionsDataTable extends DataTableBase<PreTransactionFrontendModel> {
	@Output() onRowClicked = new EventEmitter<PreTransactionFrontendModel>();
	@Output() onPreTransactionDeleted = new EventEmitter<PreTransactionFrontendModel>();
	@ViewChild(DataTableComponent) dataTable: DataTableComponent;

	public company: Company;

	constructor(
		private preTransactionsService: PreTransactionService,
		private merchantService: MerchantService,
		private translate: TranslateService,
		private companyService: CompanyService,
		private translationReplaceService: TranslationReplaceService,
	) {
		super();
		this.company = this.companyService.getCompanyOfLoggedUser();
	}

	public fetchItemsFn = async (
		start: number,
		searchQuery: string,
		itemsPerPage: number,
		sortField: string,
		sortOrder: 'ASC' | 'DESC',
	): Promise<{ totalAmount: number; data: Array<PreTransactionFrontendModel> }> => {
		const result = await this.preTransactionsService.getPreTransactions(start, itemsPerPage, searchQuery, sortField, sortOrder);
		const preTransactions = result.preTransactions.map((preTransaction) => {
			const preTransactionFrontEndModel = preTransaction.transformToFrontend(preTransaction.user);
			preTransactionFrontEndModel.amount = preTransactionFrontEndModel.useExchangeCurrency
				? preTransactionFrontEndModel.exchangeCurrency.toAmountWithCurrency
				: preTransactionFrontEndModel.amount;
			return preTransactionFrontEndModel;
		});
		return {
			totalAmount: result.count,
			data: preTransactions,
		};
	};

	public _onRowClicked(row: PreTransactionFrontendModel): void {
		this.onRowClicked.emit(row);
	}

	/* tslint:disable:member-ordering */
	public getMerchant = memoize(async (merchantId: string): Promise<string> => {
		return this.merchantService.getMerchant(merchantId).then((merchant: Merchant) => merchant.title);
	});

	public getActionsForRowFn = (
		row: PreTransactionFrontendModel,
	): Array<{
		caption: string;
		action: () => void;
	}> => {
		return [
			{
				caption: this.translate.instant(_('Delete')),
				action: () => {
					this.preTransactionsService.deletePreTransaction(row.id).then(this.refreshTable);
				},
			},
		];
	};

	public getActionsForMultipleRowsFn = (
		rows: Array<PreTransactionFrontendModel>,
	): Array<{
		caption: string;
		action: () => void;
	}> => {
		return [
			{
				caption: this.translate.instant(_('Delete')),
				action: () => {
					const promises = rows.map((row) => this.preTransactionsService.deletePreTransaction(row.id));
					Promise.allSettled(promises).then(() => this.refreshTable());
				},
			},
		];
	};

	private refreshTable(): void {
		this.dataTable._ext_refreshTable();
	}

	public getCustomCaptionForField(fieldName: string): string {
		switch (fieldName) {
			case 'administration':
				return this.translationReplaceService.administration;
			case 'costCenter':
				return this.translationReplaceService.costCenter;
			case 'costUnit':
				return this.translationReplaceService.costUnit;
			case 'project':
				return this.translationReplaceService.project;
			case 'category':
				return this.translationReplaceService.category;
			default:
				return this.translate.instant(fieldName);
		}
	}
}
