import { isValueSet } from '#/util/values';
import { Injectable } from '@angular/core';
import { AppSelectOptions } from '@klippa/ngx-enhancy-forms';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
	providedIn: 'root',
})
export class TranslateUtilService {
	constructor(private translateService: TranslateService) {}

	public translateSelectOptions(options: AppSelectOptions): AppSelectOptions {
		return options.map((option) => ({
			...option,
			name: this.translateService.instant(option.name),
		}));
	}

	public setLanguage(language: string): void {
		const languages = ['en-gb', 'en-us', 'nl-nl', 'de-de', 'fr-fr', 'es-es', 'pt-pt', 'el-gr'];

		if (!isValueSet(language) || !languages.includes(language)) {
			return;
		}

		this.translateService.use(language);

		if (language === 'en-gb') {
			window['__localeId__'] = 'enGB';
		} else if (language === 'en-us') {
			window['__localeId__'] = 'enUS';
		} else {
			const language_parts = language.split('-');
			window['__localeId__'] = language_parts[0];
		}
	}
}
