import { Injectable } from '@angular/core';
import { UIFieldKey } from '#/models/transaction/interface/frontendModel';
import { isValueSet } from '#/util/values';
import { CompensationType } from '#/models/compensation-type';
import { UserService } from '~/app/modules/user/user.service';
import { isDateInBetweenRange } from '#/util/dateRange';
import { AmountWithCurrency } from '#/models/transaction/amountWithCurrency';
import { arrayIsSetAndFilled } from '#/util/arrays';
import { CompensationRule } from '#/models/company/compensationRule.model';
import { CompanyApiService } from '#/services/company/company-api.service';
import { CompanyService } from '#/services/company/company.service';
import { DistanceUnit, KilometerToMileRatio } from '#/models/distance-unit';
import { TimeUnit } from '#/models/time-unit';
import { convertDateToYMD } from '#/util/date';

export type DatesPerAppliedCompensationRule = Map<string, Array<string>>; // Map<compRuleId, Array<dateYMD>>

export interface CompensationRulesAndAmountWithCurrency {
	amountWithCurrency: AmountWithCurrency;
	datesPerAppliedCompensationRule: DatesPerAppliedCompensationRule;
}

export interface RuleAndDates {
	compensationRule: CompensationRule;
	dates: Array<string>;
}

@Injectable({
	providedIn: 'root',
})
export class CompensationRulesService {
	constructor(private companyApiService: CompanyApiService, private companyService: CompanyService, private userService: UserService) {}
	public currency: string;

	public async getCompanyCompensationRules(): Promise<Array<CompensationRule>> {
		return await this.companyApiService.getCompanyCompensationRules(this.companyService.getCompanyId());
	}

	public async getCompensationRule(compensationRuleId: string): Promise<CompensationRule> {
		return await this.companyApiService.getCompensationRuleById(this.companyService.getCompanyId(), compensationRuleId);
	}

	public createCompensationRule(compensationRule: CompensationRule): Promise<CompensationRule> {
		return this.companyApiService.createCompensationRule(compensationRule, this.companyService.getCompanyId());
	}
	public editCompensationRule(compensationRule: CompensationRule): Promise<CompensationRule> {
		return this.companyApiService.editCompensationRule(compensationRule, this.companyService.getCompanyId());
	}

	public deleteCompensationRule(compensationRuleId: string): Promise<void> {
		return this.companyApiService.deleteCompensationRule(compensationRuleId, this.companyService.getCompanyId());
	}

	public async calculateCompensationForTime(
		txFormValues: { [k in UIFieldKey]?: any },
		toCurrency: string,
	): Promise<CompensationRulesAndAmountWithCurrency> {
		let datesArray: Array<string> = (isValueSet(txFormValues.dates) ? txFormValues.dates : [txFormValues.purchaseDate]).filter(isValueSet);
		datesArray = arrayIsSetAndFilled(datesArray) ? datesArray : [convertDateToYMD(new Date())];
		const dateWithCompensationRuleApplied = await this.getDatesWithCompensationRuleApplied(datesArray, txFormValues, toCurrency);
		const totalAmount = this.calculateCompensationTotalAmountFixedOrTimeCompensation(
			txFormValues,
			dateWithCompensationRuleApplied,
			datesArray,
		);
		return this.getCompensationRulesWithAmountWithCurrency(dateWithCompensationRuleApplied, totalAmount, toCurrency);
	}

	private calculateCompensationTotalAmountFixedOrTimeCompensation(
		txFormValues: { [k in UIFieldKey]?: any },
		dateWithCompensationRuleApplied: Array<{ compensationRule: CompensationRule; dateYMD: string }>,
		datesArray: Array<string>,
	): number {
		// hour based time compensation
		if (
			isValueSet(txFormValues.amountOfMinutes) &&
			dateWithCompensationRuleApplied[0].compensationRule?.type === CompensationType.TIME &&
			dateWithCompensationRuleApplied[0].compensationRule?.unit === TimeUnit.HOUR
		) {
			return (dateWithCompensationRuleApplied[0].compensationRule.amount / 60) * txFormValues.amountOfMinutes;
		}
		// day based time compensation
		if (
			arrayIsSetAndFilled(datesArray) &&
			dateWithCompensationRuleApplied[0].compensationRule?.type === CompensationType.TIME &&
			dateWithCompensationRuleApplied[0].compensationRule?.unit === TimeUnit.DAY
		) {
			return dateWithCompensationRuleApplied[0].compensationRule.amount * datesArray.length;
		}
		// fixed allowance type compensation
		if (dateWithCompensationRuleApplied[0].compensationRule?.type === CompensationType.FIXED_ALLOWANCE) {
			return dateWithCompensationRuleApplied[0].compensationRule.amount;
		}
		return 0;
	}

	public async calculateCompensationForDistance(
		txFormValues: { [k in UIFieldKey]?: any },
		toCurrency: string,
	): Promise<CompensationRulesAndAmountWithCurrency> {
		const datesArray: Array<string> = txFormValues.dates ?? [];
		const datesWithCompensationRuleApplied = await this.getDatesWithCompensationRuleApplied(datesArray, txFormValues, toCurrency);
		const totalAmount = datesWithCompensationRuleApplied.reduce(
			(acc: number, cur: { compensationRule: CompensationRule; dateYMD: string }) => {
				return acc + this.calculateCompensationPerDistance(cur.compensationRule, txFormValues.travelRoute.distance);
			},
			0,
		);

		return this.getCompensationRulesWithAmountWithCurrency(datesWithCompensationRuleApplied, totalAmount, toCurrency);
	}

	private async getCompensationRulesWithAmountWithCurrency(
		dateWithCompensationRuleApplied: Array<{
			compensationRule: CompensationRule;
			dateYMD: string;
		}>,
		totalAmount: number,
		toCurrency: string,
	): Promise<CompensationRulesAndAmountWithCurrency> {
		const datesPerAppliedCompensationRule: DatesPerAppliedCompensationRule = dateWithCompensationRuleApplied.reduce(
			(acc: DatesPerAppliedCompensationRule, cur: { compensationRule: CompensationRule; dateYMD: string }) => {
				if (!isValueSet(cur.compensationRule)) {
					return acc;
				}

				if (!acc.has(cur.compensationRule.id)) {
					acc.set(cur.compensationRule.id, []);
				}
				acc.get(cur.compensationRule.id).push(cur.dateYMD);
				return acc;
			},
			new Map<string, Array<string>>(),
		);

		if (totalAmount !== 0) {
			return {
				amountWithCurrency: {
					amount: Math.round(totalAmount),
					currency: this.currency,
				},
				datesPerAppliedCompensationRule,
			};
		}
		return {
			amountWithCurrency: { amount: null, currency: toCurrency },
			datesPerAppliedCompensationRule,
		};
	}

	private async getDatesWithCompensationRuleApplied(
		datesArray: Array<string>,
		txFormValues: { [k in UIFieldKey]?: any },
		toCurrency: string,
	): Promise<Array<{ compensationRule: CompensationRule; dateYMD: string }>> {
		const promisedDatesWithCompensationRuleApplied: Array<Promise<{ compensationRule: CompensationRule; dateYMD: string }>> =
			datesArray.map(async (date: string): Promise<{ compensationRule: CompensationRule; dateYMD: string }> => {
				const compensationRule: CompensationRule = await this.getCompensationRulesForFixedCompensation(txFormValues, date).then(
					// we only take the first applicable rule as we do not apply more than one rule for the same situation at the moment
					(r) => r[0],
				);
				this.currency = compensationRule?.currency.toString() ?? toCurrency;
				return {
					compensationRule: compensationRule,
					dateYMD: date,
				};
			});
		return await Promise.all(promisedDatesWithCompensationRuleApplied);
	}

	private calculateCompensationPerDistance(compensationRules: CompensationRule, distance: number): number {
		if (isValueSet(compensationRules) && compensationRules.unit === DistanceUnit.KILOMETER) {
			return distance * compensationRules.amount;
		}
		if (isValueSet(compensationRules) && compensationRules.unit === DistanceUnit.MILE) {
			return distance * compensationRules.amount * KilometerToMileRatio.KM_TO_MILE;
		}
		return 0;
	}

	private async getCompensationRulesForFixedCompensation(
		txFormValues: { [k in UIFieldKey]?: any },
		dateYMDToCheck: string,
	): Promise<Array<CompensationRule>> {
		if (isValueSet(txFormValues.travelRoute)) {
			return await this.getCompensationRulesForDistance(txFormValues, dateYMDToCheck);
		}
		if (isValueSet(txFormValues.amountOfMinutes)) {
			return await this.getCompensationRulesForTime(txFormValues, dateYMDToCheck, TimeUnit.HOUR);
		}
		const dailyRules = (await this.getCompanyCompensationRules()).filter(
			(e) => e.type === CompensationType.TIME && e.unit === TimeUnit.DAY,
		);
		const filteredDailyRules = this.getCompensationRulesBasedOnFilters(dailyRules, txFormValues, dateYMDToCheck);
		if (isValueSet(txFormValues.dates) && arrayIsSetAndFilled(filteredDailyRules)) {
			return filteredDailyRules;
		}
		return await this.getCompensationRulesForFixedAllowance(txFormValues, dateYMDToCheck);
	}

	private async getCompensationRulesForDistance(
		txFormValues: { [k in UIFieldKey]?: any },
		dateYMDToCheck: string,
	): Promise<Array<CompensationRule>> {
		let compensationRules = await this.getCompanyCompensationRules().then((rules) =>
			rules.filter((rule) => rule.type === CompensationType.DISTANCE),
		);
		compensationRules = this.getCompensationRulesBasedOnFilters(compensationRules, txFormValues, dateYMDToCheck);
		return compensationRules;
	}

	private async getCompensationRulesForTime(
		txFormValues: { [k in UIFieldKey]?: any },
		dateYMDToCheck: string,
		timeUnit: TimeUnit,
	): Promise<Array<CompensationRule>> {
		let compensationRules = await this.getCompanyCompensationRules().then((rules: Array<CompensationRule>) => {
			return rules.filter((rule) => rule.type === CompensationType.TIME && rule.unit === timeUnit);
		});
		compensationRules = this.getCompensationRulesBasedOnFilters(compensationRules, txFormValues, dateYMDToCheck);

		return compensationRules;
	}

	private async getCompensationRulesForFixedAllowance(
		txFormValues: { [k in UIFieldKey]?: any },
		dateYMDToCheck: string,
	): Promise<Array<CompensationRule>> {
		let compensationRules = await this.getCompanyCompensationRules().then((rules) =>
			rules.filter((rule) => rule.type === CompensationType.FIXED_ALLOWANCE),
		);
		compensationRules = this.getCompensationRulesBasedOnFilters(compensationRules, txFormValues, dateYMDToCheck);

		return compensationRules;
	}

	private getCompensationRulesBasedOnFilters(
		compensationRules: Array<CompensationRule>,
		txFormValues: { [k in UIFieldKey]?: any },
		dateYMDToCheck: string,
	): Array<CompensationRule> {
		compensationRules = this.getActiveCompensationRules(compensationRules);
		compensationRules = this.getCompensationRulesActiveDuringPurchaseDate(compensationRules, dateYMDToCheck);
		compensationRules = this.getCompensationRulesForApplicableCompanyGroups(compensationRules);
		compensationRules = this.getCompensationRulesForProject(compensationRules, txFormValues.project);
		compensationRules = this.getCompensationRulesForCostCenter(compensationRules, txFormValues.costCenter);
		compensationRules = this.getCompensationRulesForCategory(compensationRules, txFormValues.category);
		compensationRules = this.getCompensationRulesForAdministration(compensationRules, txFormValues.administration);
		compensationRules = this.getCompensationRulesForCostUnit(compensationRules, txFormValues.costUnit);
		compensationRules = this.getCompensationRulesForCountry(compensationRules, txFormValues.country);
		return compensationRules;
	}

	private getActiveCompensationRules(compensationRules: Array<CompensationRule>): Array<CompensationRule> {
		return compensationRules.filter((rule) => rule.active === true);
	}

	private getCompensationRulesForApplicableCompanyGroups(compensationRules: Array<CompensationRule>): Array<CompensationRule> {
		return compensationRules.filter((rule: CompensationRule) =>
			this.userService
				.getCurrentLoggedUser()
				.companygroups.some((companyGroup) => rule.scope?.groups.includes(companyGroup) || !arrayIsSetAndFilled(rule.scope?.groups)),
		);
	}

	private getCompensationRulesForProject(compensationRules: Array<CompensationRule>, project: string): Array<CompensationRule> {
		return compensationRules.filter(
			(rule: CompensationRule) => rule.scope?.projects.includes(project) || !arrayIsSetAndFilled(rule.scope?.projects),
		);
	}

	private getCompensationRulesForCategory(compensationRules: Array<CompensationRule>, category: string): Array<CompensationRule> {
		return compensationRules.filter(
			(rule: CompensationRule) => rule.scope?.categories.includes(category) || !arrayIsSetAndFilled(rule.scope?.categories),
		);
	}

	private getCompensationRulesForCostCenter(compensationRules: Array<CompensationRule>, costCenter: string): Array<CompensationRule> {
		return compensationRules.filter(
			(rule: CompensationRule) => rule.scope?.costCenters.includes(costCenter) || !arrayIsSetAndFilled(rule.scope?.costCenters),
		);
	}

	private getCompensationRulesForCostUnit(compensationRules: Array<CompensationRule>, costUnit: string): Array<CompensationRule> {
		return compensationRules.filter(
			(rule: CompensationRule) => rule.scope?.costUnits.includes(costUnit) || !arrayIsSetAndFilled(rule.scope?.costUnits),
		);
	}

	private getCompensationRulesForCountry(compensationRules: Array<CompensationRule>, country: string): Array<CompensationRule> {
		return compensationRules.filter(
			(rule: CompensationRule) => rule.scope?.countries.includes(country) || !arrayIsSetAndFilled(rule.scope?.countries),
		);
	}

	private getCompensationRulesForAdministration(
		compensationRules: Array<CompensationRule>,
		administration: string,
	): Array<CompensationRule> {
		return compensationRules.filter(
			(rule: CompensationRule) => rule.scope?.administrations.includes(administration) || !arrayIsSetAndFilled(rule.scope?.administrations),
		);
	}

	private getCompensationRulesActiveDuringPurchaseDate(
		compensationRules: Array<CompensationRule>,
		purchaseDateYMD: string,
	): Array<CompensationRule> {
		const purchaseDateAsDate = isValueSet(purchaseDateYMD) ? new Date(purchaseDateYMD) : null;
		return compensationRules.filter((rule: CompensationRule) => {
			if (
				isValueSet(rule.endDate) &&
				isValueSet(rule.startDate) &&
				isDateInBetweenRange(purchaseDateAsDate, new Date(rule.startDate), new Date(rule.endDate))
			) {
				return true;
			}

			if (isValueSet(rule.endDate) && !isValueSet(rule.startDate) && purchaseDateAsDate <= new Date(rule.endDate)) {
				return true;
			}

			if (!isValueSet(rule.endDate) && isValueSet(rule.startDate) && purchaseDateAsDate >= new Date(rule.startDate)) {
				return true;
			}

			return !isValueSet(rule.endDate) && !isValueSet(rule.startDate) && isValueSet(purchaseDateAsDate);
		});
	}
}
