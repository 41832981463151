import { Injectable } from '@angular/core';
import { Category, CompanyCategoryData, CompanyCategoryListAPIRequest } from '#/models/company/category.model';
import { ImportResultCount } from '#/models/import-result.model';
import { CompanyDimensionsService } from '#/services/company/dimension/company-dimensions.service';
import { arrayIsSetAndFilled } from '#/util/arrays';
import { filterUndefinedValues } from '#/util/objects';
import { CategoryApiModel } from '#/models/transaction/category/apiModel';
import { CompanyService } from '#/services/company/company.service';
import { APIService } from '~/app/api/services/api.service';
import { APINotificationsService } from '~/app/api/services/apinotifications.service';
import { CategoryFrontendModel } from '#/models/transaction/category/frontendModel';
import { UserService } from '~/app/modules/user/user.service';
import { DistanceUnit } from '#/models/distance-unit';

@Injectable({
	providedIn: 'root',
})
export class CompanyCategoryService extends CompanyDimensionsService {
	constructor(
		private companyService: CompanyService,
		private userService: UserService,
		protected apiService: APIService,
		protected notifications: APINotificationsService,
	) {
		super(apiService, notifications);
	}

	private getDistanceUnit(): DistanceUnit {
		return this.userService.getCurrentLoggedUser().preferences.show_miles_instead_of_km ? DistanceUnit.MILE : DistanceUnit.KILOMETER;
	}

	getCompanyCategories(companyCategoryListAPIRequest: CompanyCategoryListAPIRequest): Promise<CompanyCategoryData> {
		companyCategoryListAPIRequest.list = true;
		const requestBody = {
			types: undefined,
		};
		if (arrayIsSetAndFilled(companyCategoryListAPIRequest.types)) {
			requestBody.types = companyCategoryListAPIRequest.types;
		}
		return this.apiService
			.post(companyCategoryListAPIRequest.getRequestURL(), filterUndefinedValues(requestBody))
			.then((r) => {
				return new CompanyCategoryData(r.data);
			})
			.catch((e) => {
				this.notifications.handleAPIError(e);
				throw e;
			});
	}

	public getCompanyCategoriesByIDsDeprecated(
		companyCategoryListAPIRequest: CompanyCategoryListAPIRequest,
		ids: Array<string>,
	): Promise<CompanyCategoryData> {
		companyCategoryListAPIRequest.list = true;
		companyCategoryListAPIRequest.max = 9999;
		return this.apiService
			.post(companyCategoryListAPIRequest.getRequestURL(), {
				ids,
			})
			.then((r) => {
				return Promise.resolve(new CompanyCategoryData(r.data));
			})
			.catch((e) => {
				this.notifications.handleAPIError(e);
				return Promise.reject(e);
			});
	}

	public getCompanyCategoriesByIds(ids: Array<string>): Promise<Array<CategoryFrontendModel>> {
		const companyId: string = this.companyService.getCompanyOfLoggedUser().id;
		return this.apiService
			.postToApi(`company/${companyId}/companyCategory/list`, {
				ids,
			})
			.then((r) => {
				return (r.data.company_categories ?? []).map((category: CategoryApiModel) => new CategoryApiModel(category).transformToFrontend());
			})
			.catch((e) => {
				this.notifications.handleAPIError(e);
				throw e;
			});
	}

	importCompanyCategories(companyId: string, file: File, skipFirstRow = true): Promise<ImportResultCount> {
		const formData = new FormData();
		formData.append('file', file);

		if (skipFirstRow) {
			formData.append('skip_first_row', '1');
		}

		let url = '/api/v1/companyCategory/import';
		if (companyId !== '') {
			url = '/api/v1/company/' + companyId + '/companyCategory/import';
		}

		return this.apiService
			.post(url, formData)
			.then((r) => {
				return Promise.resolve(new ImportResultCount(r.data));
			})
			.catch((e) => {
				this.notifications.handleAPIError(e);
				return Promise.reject(e);
			});
	}

	public bulkDeleteCompanyCategories(categoriesToUpdate: Array<CategoryFrontendModel>): Promise<ImportResultCount> {
		const companyId: string = this.companyService.getCompanyOfLoggedUser().id;
		const categories: Array<CategoryApiModel> = categoriesToUpdate.map((category: CategoryFrontendModel) =>
			category.transformToApi(this.getDistanceUnit()),
		);
		return this.apiService
			.postToApi(`company/${companyId}/companyCategory/bulk`, {
				type: 'delete',
				categories: categories,
			})
			.then((r) => {
				return new ImportResultCount(r.data);
			})
			.catch((e) => {
				this.notifications.handleAPIError(e);
				throw e;
			});
	}

	public bulkUpdateCompanyCategories(categoriesToDelete: Array<CategoryFrontendModel>): Promise<ImportResultCount> {
		const companyId: string = this.companyService.getCompanyOfLoggedUser().id;
		const categories: Array<CategoryApiModel> = categoriesToDelete.map((category: CategoryFrontendModel) =>
			category.transformToApi(this.getDistanceUnit()),
		);
		return this.apiService
			.postToApi(`company/${companyId}/companyCategory/bulk`, {
				type: 'update',
				categories: categories,
			})
			.then((r) => {
				return new ImportResultCount(r.data);
			})
			.catch((e) => {
				this.notifications.handleAPIError(e);
				throw e;
			});
	}

	public createCompanyCategory(category: CategoryFrontendModel): Promise<CategoryFrontendModel> {
		const companyId: string = this.companyService.getCompanyOfLoggedUser().id;
		return this.apiService
			.postToApi(`company/${companyId}/companyCategory`, category.transformToApi(this.getDistanceUnit()))
			.then((r) => {
				return new CategoryApiModel(r.data).transformToFrontend();
			})
			.catch((e) => {
				this.notifications.handleAPIError(e);
				throw e;
			});
	}

	public updateCompanyCategory(category: CategoryFrontendModel): Promise<CategoryFrontendModel> {
		const companyId: string = this.companyService.getCompanyOfLoggedUser().id;
		return this.apiService
			.patchToApi(`company/${companyId}/companyCategory/${category.id}`, category.transformToApi(this.getDistanceUnit()))
			.then((r) => {
				return new CategoryApiModel(r.data).transformToFrontend();
			})
			.catch((e) => {
				this.notifications.handleAPIError(e);
				throw e;
			});
	}

	public getCompanyCategory(companyId: string, id: string): Promise<Category> {
		let url = '/api/v1/companyCategory/' + id;
		if (companyId !== '') {
			url = '/api/v1/company/' + companyId + '/companyCategory/' + id;
		}
		if (CompanyDimensionsService.requestCache.has(url)) {
			return CompanyDimensionsService.requestCache.get(url);
		}
		const promise = this.apiService
			.get(url)
			.then((r) => {
				CompanyDimensionsService.requestCache.delete(url);
				return Promise.resolve(new Category(r.data));
			})
			.catch((e) => {
				CompanyDimensionsService.requestCache.delete(url);
				this.notifications.handleAPIError(e);
				return Promise.reject(e);
			});
		CompanyDimensionsService.requestCache.set(url, promise);
		return promise;
	}

	getCompanyCategoriesFilteredOnPresets(
		ListAPIRequest: CompanyCategoryListAPIRequest,
		presetOptions: string[],
	): Promise<CompanyCategoryData> {
		return this.getCompanyCategories(ListAPIRequest).then((data) => {
			const tempData = data;
			if (arrayIsSetAndFilled(presetOptions)) {
				tempData.company_categories = tempData.company_categories.filter((item) => presetOptions.includes(item.id));
			}
			return tempData;
		});
	}

	deleteCompanyCategory(companyId: string, id: string): Promise<any> {
		let url = '/api/v1/companyCategory/' + id;
		if (companyId !== '') {
			url = '/api/v1/company/' + companyId + '/companyCategory/' + id;
		}

		return this.apiService
			.delete(url)
			.then((r) => {
				return Promise.resolve(r);
			})
			.catch((e) => {
				this.notifications.handleAPIError(e);
				return Promise.reject(e);
			});
	}
}
