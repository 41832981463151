import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PipesModule } from '~/app/helpers/pipes/pipes.module';
import { UiModule } from '~/app/shared/ui/ui.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { ModalModule } from '~/app/shared/ui/modal/modal.module';
import { ExportModule } from '~/app/modules/export/export.module';
import { WutuDataTableModule } from 'wutu-data-table';
import { PersonalRegistrationsDataTable } from '~/app/shared/ui/data-tables/registrations-data-table/personal-registrations-data-table.component';
import { RegistrationsListComponent } from '~/app/pages/authenticated/registrations/personal/registrations-list.component';
import { ManageRegistrationsListComponent } from '~/app/pages/authenticated/registrations/manage/manage-registrations-list.component';
import { ManageRegistrationsDataTable } from '~/app/shared/ui/data-tables/registrations-data-table/manage-registrations-data-table.component';

@NgModule({
	imports: [
		CommonModule,
		PipesModule,
		UiModule,
		FormsModule,
		TranslateModule,
		ReactiveFormsModule,
		ModalModule,
		ExportModule,
		WutuDataTableModule,
	],
	declarations: [
		RegistrationsListComponent,
		PersonalRegistrationsDataTable,
		ManageRegistrationsDataTable,
		ManageRegistrationsListComponent,
		ManageRegistrationsListComponent,
	],
	exports: [],
	providers: [],
})
export class RegistrationsListModule {}
