import { Injectable } from '@angular/core';
import { TranslationReplaceService } from '~/app/services/translation-replace.service';
import { DynamicRouteNameResolver } from '~/app/modules/company/resolvers/dynamicRouteNameResolvers/dynamic-route-name-resolver';

export enum RouteNames {
	COST_CENTERS,
	COST_UNITS,
	PROJECTS,
	DASHBOARD,
	SAVED_RECEIPTS,
}

@Injectable({
	providedIn: 'root',
})
export class TranslationReplaceRouteNameResolver implements DynamicRouteNameResolver {
	constructor(private translateReplace: TranslationReplaceService) {}

	resolve(routeName: RouteNames): Promise<string> {
		switch (routeName) {
			case RouteNames.COST_CENTERS:
				return Promise.resolve(this.translateReplace.costCenters);
			case RouteNames.COST_UNITS:
				return Promise.resolve(this.translateReplace.costUnits);
			case RouteNames.PROJECTS:
				return Promise.resolve(this.translateReplace.projects);
			case RouteNames.DASHBOARD:
				return Promise.resolve(this.translateReplace.myKlippa);
			case RouteNames.SAVED_RECEIPTS:
				return Promise.resolve(this.translateReplace.savedReceipts);
		}
		return null;
	}
}
