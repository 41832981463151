import { CompanyUnitBase } from './company-unit-base.model';
import { CompanyObjectListAPIRequest } from '../company-object-api-request.model';

export class Project extends CompanyUnitBase {
	constructor(data = null) {
		super(data);
	}

	static fromData(data): Project[] {
		if (data == null) {
			return [];
		}
		return data.map((item) => new Project(item));
	}

	clone(): Project {
		return new Project(JSON.parse(JSON.stringify(this)));
	}
}

export class CompanyProjectData {
	company_projects: Project[] = [];
	count: number;
	moreresults: boolean;

	constructor(data = null) {
		if (data) {
			Object.assign(this, data);

			if (data.company_projects) {
				this.company_projects = Project.fromData(data.company_projects);
			}
		}
	}
}

export class CompanyProjectListAPIRequest extends CompanyObjectListAPIRequest {
	ids?: Array<string>;
	administrations?: Array<string>;
	getURL(): string {
		if (this.list) {
			if (this.company && this.company !== '') {
				return `/api/v1/company/${this.company}/companyProject/list`;
			}
			return `/api/v1/companyProject/list`;
		}
		if (this.company && this.company !== '') {
			return `/api/v1/company/${this.company}/companyProject`;
		}
		return `/api/v1/companyProject`;
	}
}
