<ngx-async-template [promise]="getCapabilities()">
	<ng-template ngx-async-success>
		<div class="addCustomRelationModalContainer">
			<klp-grid-row>
				<klp-grid-column [width]="{ xs: 6 }">
					<div class="fileContainer">
						<ng-container *ngIf="attachments.length === 0">
							<div class="noAttachmentAvailableContainer" translate>No attachment available.</div>
						</ng-container>
						<ng-container *ngIf="attachments.length > 0">
							<div class="filesActionBar">
								<app-attachment-actions-bar
									#attachmentsActionsController
									[showUploadButton]="false"
									[showDeleteButton]="false"
									[isUploading]="false"
								></app-attachment-actions-bar>
							</div>
							<div class="attachmentViewerContainer">
								<app-attachment-viewer
									[actionsController]="attachmentsActionsController"
									[receiptId]="transactionId"
									[attachments]="attachments"
								></app-attachment-viewer>
							</div>
						</ng-container>
					</div>
				</klp-grid-column>
				<klp-grid-column [width]="{ xs: 6 }">
					<div class="editorContainer">
						<div class="formContainer" *ngIf="baseField">
							<klp-grid-row>
								<klp-grid-column>
									<div class="titleContainer">
										<h4 *ngIf="isOrg()" translate>Create an organisation</h4>
										<h4 *ngIf="!isOrg()" translate>Create a person</h4>
										<app-button variant="icon" [hasBorder]="false" (click)="closeModal()">
											<app-icon variant="close" size="medium"></app-icon>
										</app-button>
									</div>
								</klp-grid-column>
							</klp-grid-row>
							<klp-grid-row>
								<klp-grid-column [width]="{ xs: 12 }">
									<klp-form [formGroup]="customIntegrationRelationForm">
										<klp-form-element *ngIf="typeOfRelations?.length > 1">
											<klp-form-radio
												formControlName="typeOfRelation"
												[options]="typeOfRelations"
												variant="button"
												[orientation]="orientation"
											>
											</klp-form-radio>
										</klp-form-element>
									</klp-form>
								</klp-grid-column>
								<klp-grid-column [width]="{ xs: 12 }">
									<app-integration-custom-relation-field
										[field]="baseField"
										[isOrg]="isOrg()"
										[integration]="integrationId"
										[division]="divisionId"
										[newCustomRelationFieldName]="newCustomRelationFieldName"
									></app-integration-custom-relation-field>
								</klp-grid-column>
							</klp-grid-row>
						</div>
						<div class="buttonsContainerOuter">
							<klp-grid-row>
								<klp-grid-column>
									<div class="buttonsContainer">
										<app-button variant="greenFilled" (click)="closeModal()" translate>Cancel</app-button>
										<app-button variant="greenFilled" [disabled]="!fieldValid(baseField)" (click)="create()" translate>Create</app-button>
									</div>
								</klp-grid-column>
							</klp-grid-row>
						</div>
					</div>
				</klp-grid-column>
			</klp-grid-row>
		</div>
	</ng-template>
</ngx-async-template>
