import { Component, Input } from '@angular/core';
import { InboxItem } from '../../models/inbox';
import { DeclarationStatusFlag, getExpenseLabel } from '#/models/transaction/receipt';
import { TranslateService } from '@ngx-translate/core';
import { CompanyFeatureFlagsService } from '#/services/company/company-feature-flags.service';
import { memoize } from 'lodash';
import { PossibleCompanyFeatureFlags } from '#/models/company/possible-feature-flags';

@Component({
	selector: '[app-inbox-table-row]',
	templateUrl: './inbox-table-row.component.html',
})
export class InboxTableRowComponent {
	@Input()
	public inboxItem: InboxItem;
	constructor(private translate: TranslateService, private companyFeatureFlagsService: CompanyFeatureFlagsService) {}

	private companyHasTransactionInterfaces = memoize((): Promise<boolean> => {
		return this.companyFeatureFlagsService.companyHasSpecificFeatureFlagEnabled(PossibleCompanyFeatureFlags.TRANSACTION_INTERFACES);
	});

	public statusToLabel = memoize(async (status: DeclarationStatusFlag): Promise<string> => {
		const hasTxi = await this.companyHasTransactionInterfaces();
		const key = getExpenseLabel(status, hasTxi);
		return key ? this.translate.instant(key) : key;
	});
}
