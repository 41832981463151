import { Component, Host, Optional } from '@angular/core';
import { AppSelectOption, FormElementComponent } from '@klippa/ngx-enhancy-forms';
import { ControlContainer, NG_VALUE_ACCESSOR } from '@angular/forms';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { DynamicOptionsValueAccessorBase } from '~/app/shared/ui/forms/composed/pickers/dynamic/dynamic-options-picker/dynamic-options-value-accessor-base';
import { ItemsWithHasMoreResultsPromise } from '#/models/appSelectOption.model';
import { RegistrationType } from '#/models/transaction/registrationType';
import { TransportationType } from '#/models/transaction/transportationType';
import { RegistrationService } from '#/services/transaction/registration.service';

@Component({
	selector: 'app-form-category-registration-type-picker',
	templateUrl: '../static/base/static-options-picker.template.html',
	styleUrls: ['../static/base/static-options-picker.template.scss'],
	providers: [{ provide: NG_VALUE_ACCESSOR, useExisting: CategoryRegistrationTypePickerComponent, multi: true }],
})
export class CategoryRegistrationTypePickerComponent extends DynamicOptionsValueAccessorBase<string, RegistrationType> {
	constructor(
		@Optional() @Host() protected parent: FormElementComponent,
		@Optional() @Host() protected controlContainer: ControlContainer,
		private registrationService: RegistrationService,
	) {
		super(parent, controlContainer);
		this.defaultPlaceHolder = _('Select registration type');
	}

	fetchItemsFn = async (start: number, searchQuery: string): ItemsWithHasMoreResultsPromise<RegistrationType> => {
		return await this.registrationService.getRegistrationTypes(start, searchQuery);
	};

	public fetchSelectedItemsFn = (ids: Array<string>): Promise<Array<RegistrationType>> => {
		return this.registrationService.getRegistrationTypesById(ids);
	};

	mapToSelectOptionFn = (e: TransportationType): AppSelectOption => {
		return {
			id: e.id,
			name: e.name,
		};
	};
}
