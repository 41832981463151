import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { UserService } from '~/app/modules/user/user.service';
import { CompanyService } from '#/services/company/company.service';
import { AsyncInfoDependantGuard } from '~/app/guards/async-info-dependant-guard.service';
import { CompanyApiService } from '#/services/company/company-api.service';
import { TransactionEditorService } from '#/services/transaction/transaction-editor.service';

@Injectable({
	providedIn: 'root',
})
export class TransactionRedirectGuard extends AsyncInfoDependantGuard implements CanActivate {
	constructor(
		protected userService: UserService,
		protected companyService: CompanyService,
		protected companyApiService: CompanyApiService,
		private router: Router,
		private transactionEditorService: TransactionEditorService,
	) {
		super(userService, companyService, companyApiService);
	}

	public async canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<UrlTree> {
		const transactionId = next.params.txId;
		const transaction = await this.transactionEditorService.getTransaction(transactionId);

		if (transaction?.isInvoice) {
			return this.router.createUrlTree([`/dm/invoices/todo/tx/${transactionId}`]);
		}

		return this.router.createUrlTree([`/dashboard/tx/${transactionId}`]);
	}
}
