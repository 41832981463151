import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { NotificationService } from '~/app/services/notification.service';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { TranslateService } from '@ngx-translate/core';
import { Random } from '~/app/helpers/random';
import { NotificationConfig } from '~/app/shared/ui/toast-notification/toast-notifications-manager/toast-notifications-manager.component';
import { firstValueFrom } from 'rxjs';

@Injectable()
export class VersionCheckService {
	// this will be replaced by actual hash post-build.js
	private currentHash = '{{POST_BUILD_ENTERS_HASH_HERE}}';
	private checkInterval = null;

	constructor(private http: HttpClient, private notificationService: NotificationService, private translate: TranslateService) {}

	/**
	 * Checks in every set frequency the version of frontend application
	 * @param url
	 * @param {number} frequency - in milliseconds, defaults to 30 minutes
	 */
	public initVersionCheck(url, frequency = 1000 * 60 * 30) {
		this.checkInterval = setInterval(() => {
			this.checkVersion(url);
		}, frequency);
	}

	public async getAppVersion(): Promise<string> {
		try {
			const versionJson = (await firstValueFrom(this.http.get('./version.json?ts=' + Date.now()))) as any;
			return versionJson.version;
		} catch (e) {
			return 'Local dev';
		}
	}

	/**
	 * Will do the call and check if the hash has changed or not
	 * @param url
	 */
	private checkVersion(url) {
		const notificationConfig: NotificationConfig = {
			timeout: 0,
			bodyMaxLength: 500,
			buttons: [
				{
					text: this.translate.instant(_('Refresh page')),
					action: () => {
						window.location.reload();
					},
				},
			],
		};
		// timestamp these requests to invalidate caches
		url += `?cb=${Random.randomStringLowerUpper()}`;
		this.http.get(url).subscribe(
			(response: any) => {
				const hash = response.hash;
				const hashChanged = this.hasHashChanged(this.currentHash, hash);
				// If new version, do something
				if (hashChanged) {
					this.notificationService.info(
						'🎉 ' +
							this.translate.instant(
								_(
									'There is a new version of the Klippa webapp available, click on "Refresh page" to refresh the page and to enjoy the latest version of Klippa.',
								),
							),
						notificationConfig,
					);
				}
				// store the new hash so we wouldn't trigger versionChange again
				// only necessary in case you did not force refresh
				this.currentHash = hash;
			},
			(err) => {
				console.error(err, 'Could not get version');
			},
		);
	}

	/**
	 * Checks if hash has changed.
	 * This file has the JS hash, if it is a different one than in the version.json
	 * we are dealing with version change
	 * @param currentHash
	 * @param newHash
	 * @returns {boolean}
	 */
	private hasHashChanged(currentHash, newHash) {
		if (!currentHash || currentHash === '{{POST_BUILD_ENTERS_HASH_HERE}}') {
			return false;
		}

		return currentHash !== newHash;
	}
}
