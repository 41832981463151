import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DuplicateMessage, Receipt } from '#/models/transaction/receipt';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { AuthenticatedComponent } from '~/app/pages/authenticated/authenticated.component';

@Component({
	selector: 'app-duplicate-document-warning-deprecated',
	templateUrl: './duplicate-document-warning-deprecated.component.html',
})
export class DuplicateDocumentWarningDeprecatedComponent extends AuthenticatedComponent implements OnInit {
	@Input() receipt: Receipt;
	@Input() render: 'label' = 'label';
	@Input() showIcon = false;
	@Input() showIgnore = false;
	@Output() public receiptChange: EventEmitter<Receipt> = new EventEmitter<Receipt>();
	@Output()
	public duplicateReceiptChange = new EventEmitter<Receipt>();
	protected hasEmitted;

	/* Items that are a duplicate of the receipt provided as input. */
	protected duplicateReceipts: Receipt[] = [];
	public loading = false;
	public duplicateMessages: DuplicateMessage[] = [];

	constructor(private changeDetector: ChangeDetectorRef) {
		super();
	}

	ngOnInit(): void {
		super.ngOnInit();
		this.loading = true;
		this.init();
	}

	init() {
		if (this.receipt) {
			const promises = [];
			this.duplicateReceipts = [];

			this.receipt.getDuplicateDocuments().forEach((receiptId, i) => {
				promises.push(
					this.receiptAPIService
						.getReceipt(receiptId, false)
						.then((receipt) => {
							this.emitDuplicateReceipt(receipt);
							this.duplicateReceipts.push(receipt);
						})
						.catch((e) => {}),
				);
			});

			Promise.all(promises)
				.then((r) => {
					this.loading = false;
					this.updateDuplicateMessages();
				})
				.catch((e) => {
					this.loading = false;
				});
		} else {
			this.loading = false;
		}
	}

	emitDuplicateReceipt(receipt) {
		if (!this.hasEmitted) {
			this.duplicateReceiptChange.emit(receipt);
			this.hasEmitted = true;
		}
	}

	updateDuplicateMessages(): DuplicateMessage[] {
		const duplicateMessages = [];

		if (this.receipt.hasDuplicateDocuments()) {
			if (this.duplicateReceipts.length > 0) {
				this.duplicateReceipts.forEach((receipt) => {
					if (receipt.getDescription() && receipt.getDescription().length > 0) {
						duplicateMessages.push(
							new DuplicateMessage(
								this.translate.instant(_('Possible duplicate of %description% on %date%.'), {
									description: receipt.getDescription(),
									date: receipt.getFormattedPurchaseDate(),
								}),
								receipt.getID(),
							),
						);
					} else {
						duplicateMessages.push(
							new DuplicateMessage(
								this.translate.instant(_('Possible duplicate on %date%.'), {
									description: receipt.getDescription(),
									date: receipt.getFormattedPurchaseDate(),
								}),
								receipt.getID(),
							),
						);
					}
				});
			} else if (this.receipt.getDuplicateDocuments().length === 1) {
				duplicateMessages.push(new DuplicateMessage(this.translate.instant(_('Possible duplicate of another document.'))));
			} else if (this.receipt.getDuplicateDocuments().length > 1) {
				duplicateMessages.push(
					new DuplicateMessage(
						this.translate.instant(_('Possible duplicate of %count% other documents.'), {
							count: this.receipt.getDuplicateDocuments().length,
						}),
					),
				);
			}
		}

		this.duplicateMessages = duplicateMessages;
		this.changeDetector.detectChanges();
		return duplicateMessages;
	}

	ignoreDuplicate(duplicateId) {
		this.receiptAPIService
			.removeDuplicate(this.receipt.getID(), duplicateId)
			.then((updatedReceipt) => {
				this.receipt = updatedReceipt;
				this.init();
				this.notificationService.success(this.getTranslation(_('Duplicate ignored successfully.')));
			})
			.catch((e) => {});
	}

	get receiptValue() {
		return this.receipt;
	}

	set receiptValue(value) {
		this.receipt = value;
		this.receiptChange.emit(value);
	}
}
