<div class="side-panel" [ngClass]="state.open && 'side-panel-open'">
	<div class="side-panel-wrapper bg-white">
		<ul class="nav nav-tabs" role="tablist">
			<li class="nav-item">
				<a class="nav-link disabled" role="tab" data-toggle="tab">&nbsp;</a>
			</li>
			<li class="panel-close">
				<a class="side-panel-toggle cursor-pointer" (click)="toggle()">
					<i class="ti-close"></i>
				</a>
			</li>
		</ul>

		<!--<div class="carzd">-->
		<!--<h5 class="card-header">Featured</h5>-->
		<div class="card-body">
			<h5 class="card-title" translate>Filters</h5>
			<form>
				<button type="button" class="btn btn-primary btn-block">Submit</button>
			</form>
		</div>
		<!--</div>-->
		<!--<ul class="nav nav-tabs" role="tablist">-->
		<!--<li class="nav-item">-->
		<!--<a class="nav-link" href="#tab1" role="tab" data-toggle="tab">-->
		<!--<span>{{user.name}}</span>-->
		<!--</a>-->
		<!--</li>-->
		<!--<li class="nav-item">-->
		<!--<a class="nav-link" href="#tab2" role="tab" data-toggle="tab">-->
		<!--<span>Tab 2</span>-->
		<!--</a>-->
		<!--</li>-->
		<!--<li class="nav-item ">-->
		<!--<a class="nav-link" href="#tab3" role="tab" data-toggle="tab">-->
		<!--<span>Tab 3</span>-->
		<!--</a>-->
		<!--</li>-->
		<!--<li class="panel-close">-->
		<!--<a class="side-panel-toggle" (click)="toggle()">-->
		<!--<i class="ti-close"></i>-->
		<!--</a>-->
		<!--</li>-->
		<!--</ul>-->
	</div>
</div>
