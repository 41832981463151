import { Injectable } from '@angular/core';
import { APIService } from './api.service';
import { APINotificationsService } from './apinotifications.service';
import { NotificationService } from '../../services/notification.service';

@Injectable({
	providedIn: 'root',
})
export class GeneralAPIService {
	constructor(
		private apiService: APIService,
		private notifications: APINotificationsService,
		private notificationService: NotificationService,
	) {}

	currencies() {
		return this.apiService
			.get('/api/v1/currencies')
			.then((r) => {
				return Promise.resolve(r);
			})
			.catch((r) => {
				this.notifications.handleAPIError(r);
				return Promise.reject(r);
			});
	}
}
