import { CompanyUnitBase } from './company-unit-base.model';
import { CompanyObjectListAPIRequest } from '../company-object-api-request.model';

export class CostCenter extends CompanyUnitBase {
	constructor(data = null) {
		super(data);
	}

	static fromData(data): CostCenter[] {
		if (data == null) {
			return [];
		}
		return data.map((item) => new CostCenter(item));
	}

	clone(): CostCenter {
		return new CostCenter(JSON.parse(JSON.stringify(this)));
	}
}

export class CompanyCostCenterData {
	company_costcenters: CostCenter[] = [];
	count: number;
	moreresults: boolean;

	constructor(data = null) {
		if (data) {
			Object.assign(this, data);

			if (data.company_costcenters) {
				this.company_costcenters = CostCenter.fromData(data.company_costcenters);
			}
		}
	}
}

export class CompanyCostCenterListAPIRequest extends CompanyObjectListAPIRequest {
	ids?: Array<string>;
	administrations?: Array<string>;
	getURL(): string {
		if (this.list) {
			if (this.company && this.company !== '') {
				return `/api/v1/company/${this.company}/companyCostCenter/list`;
			}
			return `/api/v1/companyCostCenter/list`;
		}
		if (this.company && this.company !== '') {
			return `/api/v1/company/${this.company}/companyCostCenter`;
		}
		return `/api/v1/companyCostCenter`;
	}
}
