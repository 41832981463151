import { AuthorizationFlow, DeclarationStatus, DeclarationStatusFlag } from '#/models/transaction/receipt';
import { endOfDay, startOfDay } from 'date-fns';
import { cloneDeep } from 'lodash';
import { DimensionSettings } from '#/models/company/dimension/dimenstionSettings.model';
import { Order } from '#/models/utils/order';
import { stringIsSetAndFilled } from '#/util/values';
import { CreatorType } from '#/models/transaction/creatorType.model';
import { ExportType } from '#/models/company/exporterInterfaces';
import { ReportType } from '#/models/reportType.model';
import { AccountingStatus } from '../accounting-integrations/accounting-integration-v2';

export class PaginatedExpenseReports {
	reports: ExpenseReport[];
	count: number;
	moreresults: boolean;

	constructor(data = null) {
		if (data) {
			Object.assign(this, data);
			this.reports = this.reports.map((value) => new ExpenseReport(value));
			if (data.vatitems) {
				this.reports = ExpenseReport.fromData(data.reports);
			}
		}
	}
}

export class ReportExportColumn {
	type: string;
	label: string;
	value: string;
	enabled: boolean;
	add_currency_symbol?: boolean;
	date_format?: string;
	custom_label?: string;

	constructor(data?: any) {
		if (!data) {
			return;
		}

		Object.assign(this, data);
	}
}

export class ReportsExportItem {
	id: string;
	type: ExportType.REPORT;

	constructor(id: string) {
		this.id = id;
		this.type = ExportType.REPORT;
	}
}

export class ExpenseReportExportRequest {
	mapColumns: {
		Columns: Array<ReportExportColumn>;
	};
	csv_separator: string;
	decimal_separator: string;
	email: string;
	items: Array<ReportsExportItem>;
	type: string;
	overview: boolean;
	combine_travel_and_receipts: boolean;
	save_template: boolean;
	company_export: boolean;
	split_per_person: boolean;
	ubl_attachments: boolean;
	ubl_combine_attachments: boolean;
	ubl_add_pdf: boolean;
	email_add_attachments: boolean;
	stats: boolean;
	timezone: string;

	constructor(data?: any) {
		this.mapColumns = { Columns: [] };
		this.items = [];
		if (!data) {
			return;
		}
		Object.assign(this, data);
		if (data.columns) {
			this.mapColumns.Columns = data.columns.map((column) => new ReportExportColumn(column));
		}
		if (data.items) {
			this.items = data.items.map((item) => new ReportsExportItem(item.id));
		}
	}
}

export class BookingQueueData {
	canbePickedUpByQueue: boolean;
	backgroundQueueError: string | undefined;
}

export class ReportBookingStatus {
	is_booked: boolean;
	bookedon: Date;

	constructor(data = null) {
		if (data) {
			Object.assign(this, data);

			if (data.bookedon) {
				this.bookedon = Date.parse(data.bookedon) > 0 ? new Date(data.bookedon) : null;
			}
		}
	}
}

export class ReportBookingData {
	provider?: string;
	division?: string;
	journal?: string;
	relation?: string;
	paymentCondition?: string;
	booking_date: string;
	bookingstatus: ReportBookingStatus;
	startedBooking: boolean;
	bookingQueue_data: BookingQueueData;

	constructor(data = null) {
		if (data) {
			Object.assign(this, data);
			this.bookingstatus = new ReportBookingStatus(data.bookingstatus);
		}
	}
}

export class ExpenseReport {
	constructor(data = null) {
		if (data) {
			Object.assign(this, data);
			this.booking_data = new ReportBookingData(data.booking_data);
			this.createdate = Date.parse(data.createdate) > 0 ? new Date(data.createdate) : null;
			this.updatedate = Date.parse(data.updatedate) > 0 ? new Date(data.updatedate) : null;
			this.deleteddate = Date.parse(data.deleteddate) > 0 ? new Date(data.deleteddate) : null;
			this.start_date = Date.parse(data.start_date) > 0 ? new Date(data.start_date) : null;
			this.end_date = Date.parse(data.end_date) > 0 ? new Date(data.end_date) : null;
			this.status_to_show = data.expense_status?.current_status?.status || DeclarationStatusFlag.NotSubmitted;
			this.authorization_flow = new AuthorizationFlow(data?.authorization_flow);
		}
	}
	id: string;
	accounting: {
		bookedOn: string;
		status: AccountingStatus;
		authorization: string;
		fields: Record<string, any>;
	};
	type: ReportType;
	createdate?: Date;
	updatedate?: Date;
	deleteddate?: Date;
	start_date?: Date;
	end_date?: Date;
	currency: string;
	company: string;
	administration: string;
	project: string;
	cost_center: string;
	cost_unit: string;
	description: string;
	user: string;
	authorization_flow?: AuthorizationFlow;
	booking_data?: ReportBookingData;
	locked: boolean;
	expense_status: {
		current_status: DeclarationStatus;
	};
	status_to_show: DeclarationStatusFlag;
	dimension_settings: DimensionSettings;
	receipt_count: number;
	allowedActions: {
		submit: boolean;
		retract: boolean;
		removeReceipts: boolean;
		delete: boolean;
		addReceipts: boolean;
		shareReceipts: boolean;
	};
	creator: {
		type: CreatorType;
	};

	static fromData(data: ExpenseReport[]): ExpenseReport[] {
		return data.map((item: ExpenseReport) => new ExpenseReport(item));
	}

	canEditBooking(): boolean {
		if (this.expense_status?.current_status?.status !== 'Approved') {
			return false;
		}
		if (stringIsSetAndFilled(this.accounting?.authorization)) {
			return true;
		}
		return stringIsSetAndFilled(this.booking_data?.provider) && !this.booking_data?.bookingstatus?.is_booked;
	}

	waitingToBeBooked(): boolean {
		return (
			!this.booking_data.bookingstatus?.is_booked &&
			this.booking_data.bookingstatus &&
			this.booking_data.bookingQueue_data.canbePickedUpByQueue &&
			typeof this.booking_data.bookingQueue_data.backgroundQueueError !== 'string'
		);
	}

	bookingBlocked(): boolean {
		return this.locked || this.booking_data.bookingstatus?.is_booked || this.waitingToBeBooked();
	}
}

export class ExportDocumentResponse {
	data: {
		url: string;
	};
	request_id: string;
	result: string;
}
export class PaginatedExpenseReportsRequest {
	constructor(start: number = null, max: number = null) {
		this.start = start;
		this.max = max;
	}

	// Conditions for the base URL
	expenses?: boolean;
	company?: string;

	// Filter arguments
	start?: number;
	max?: number;
	search?: string;
	purchaseDatePeriod?: string;
	since?: Date;
	before?: Date;
	users?: string[];
	type?: ReportType;
	administration?: string;
	cost_center?: string;
	cost_unit?: string;
	project?: string;
	statusses?: string[];
	booked?: string;
	authorization_flow?: string;
	sort: string;
	sortorder: Order;
	current_approver: string;

	getRequestURL(): string {
		const parts = [];
		if (this.start != null) {
			parts.push({
				key: 'start',
				value: this.start,
			});
		}
		if (this.max != null) {
			parts.push({
				key: 'max',
				value: this.max,
			});
		}
		if (this.search != null) {
			parts.push({
				key: 'search',
				value: this.search,
			});
		}
		if (this.purchaseDatePeriod != null) {
			parts.push({
				key: 'purchaseDatePeriod',
				value: this.purchaseDatePeriod,
			});
		}
		if (this.since) {
			let since = cloneDeep(this.since);
			if (typeof since === 'object') {
				since = startOfDay(since);
			}
			parts.push({
				key: 'since',
				value: since,
			});
		}
		if (this.before) {
			let before = cloneDeep(this.before);
			if (typeof before === 'object') {
				before = endOfDay(before);
			}
			parts.push({
				key: 'before',
				value: before,
			});
		}
		if (this.users != null && this.expenses) {
			parts.push({
				key: 'users',
				value: this.users.toString(),
			});
		}
		if (this.statusses != null) {
			parts.push({
				key: 'status',
				value: this.statusses.toString(),
			});
		}
		if (this.type != null) {
			parts.push({
				key: 'type',
				value: this.type,
			});
		}
		if (this.administration != null) {
			parts.push({
				key: 'administration',
				value: this.administration,
			});
		}
		if (this.cost_center != null) {
			parts.push({
				key: 'cost_center',
				value: this.cost_center,
			});
		}
		if (this.cost_unit != null) {
			parts.push({
				key: 'cost_unit',
				value: this.cost_unit,
			});
		}
		if (this.project != null) {
			parts.push({
				key: 'project',
				value: this.project,
			});
		}
		if (this.booked != null) {
			parts.push({
				key: 'booked',
				value: this.booked,
			});
		}
		if (this.authorization_flow != null) {
			parts.push({
				key: 'authorization_flow',
				value: this.authorization_flow,
			});
		}
		if (stringIsSetAndFilled(this.sort)) {
			parts.push({
				key: 'sort',
				value: this.sort === 'start_date' ? 'has_receipts,end_date,createdate' : this.sort,
			});
		}
		if (stringIsSetAndFilled(this.sortorder)) {
			const receiptSortOrder = this.sortorder === Order.DESCENDING ? Order.ASCENDING : Order.DESCENDING;

			parts.push({
				key: 'sortorder',
				value: this.sort === 'start_date' ? `${receiptSortOrder},${this.sortorder},${this.sortorder}` : this.sortorder,
			});
		}
		if (stringIsSetAndFilled(this.current_approver)) {
			parts.push({
				key: 'current_approver',
				value: this.current_approver,
			});
		}
		let url = '/api/v1/report';
		if (this.expenses && this.company) {
			url = `/api/v1/report/company/${this.company}/expenses`;
		}
		return url + '?' + parts.map((part) => encodeURIComponent(part.key) + '=' + encodeURIComponent(part.value)).join('&');
	}
}
