import { Component, EventEmitter, Input, Output } from '@angular/core';
import { EnableTOTPResponse, MultipleFactorAuthService } from '~/app/shared/ui/multiple-factor-auth/multiple-factor-auth.service';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { APINotificationsService } from '~/app/api/services/apinotifications.service';

@Component({
	selector: 'app-multiple-factor-auth',
	templateUrl: './multiple-factor-auth.component.html',
	styleUrls: ['./multiple-factor-auth.component.scss'],
})
export class MultipleFactorAuthComponent {
	@Input() public variant: 'settingsPage' | 'loginPage' | 'modal' = 'settingsPage';
	@Output() public onMFAConfirmed = new EventEmitter<void>();
	sendVerifyCodeToEnableTOTPViaEmailPromise: Promise<void>;
	confirmTOTPViaEmailPromise: Promise<void>;
	totpEnablePromise: Promise<EnableTOTPResponse>;
	totpConfirmPromise: Promise<void>;
	backupCodesPromise: Promise<void>;
	QRImgUrl: string;
	QRActivationKey: string;
	token: string;
	enabledTOTPOptionsPromise: Promise<Array<string>>;

	backupCodesAlreadyGenerated: boolean = false;

	emailConfirmTOTPViaEmailForm: UntypedFormGroup = this.fb.group({
		emailVerificationCode: [null, Validators.required],
	});

	disableTOTPForm: UntypedFormGroup = this.fb.group({
		mfaKey: [null, Validators.required],
		useRecoveryKey: [false],
	});

	constructor(
		private multipleFactorAuthService: MultipleFactorAuthService,
		private fb: UntypedFormBuilder,
		private notifications: APINotificationsService
	) {}

	ngOnInit() {
		this.getEnabledOptions();
		// for the login page and modal, we immediately want to show the QR code
		this.enabledTOTPOptionsPromise.then((options) => {
			if (this.variant === 'loginPage' || this.variant === 'modal') {
				this.enabledTOTPOptionsPromise.then(() => {
					this.enableTOTP();
				});
			}

			this.backupCodesAlreadyGenerated = options.includes('backupCodes');
		});
	}

	getEnabledOptions() {
		this.enabledTOTPOptionsPromise = this.multipleFactorAuthService.getEnabledMFAOptions();
	}

	sendVerifyCodeToEnableTOTPViaEmail() {
		this.sendVerifyCodeToEnableTOTPViaEmailPromise = this.multipleFactorAuthService.sendVerifyCodeToEnableTOTPViaEmail();
	}

	confirmTOTPViaEmailAndEnableTOTP = (formValue) => {
		this.confirmTOTPViaEmailPromise = this.multipleFactorAuthService
			.confirmTOTPViaEmail(formValue.emailVerificationCode)
			.then(() => this.enableTOTP());
		return this.confirmTOTPViaEmailPromise;
	};

	enableTOTP() {
		this.totpEnablePromise = this.multipleFactorAuthService.enableTOTP();
		this.totpEnablePromise.then((res) => {
			this.QRImgUrl = 'data:image/png;base64,' + res.QRBytes;
			this.QRActivationKey = res.activationKey;
		});
	}

	confirmTOTP() {
		this.totpConfirmPromise = this.multipleFactorAuthService.confirmTOTP(this.QRActivationKey, this.token).then((value) => {
			this.onMFAConfirmed.emit();
			this.getEnabledOptions();
			return value;
		});
	}

	generateBackupCodes() {
		this.backupCodesPromise = this.multipleFactorAuthService.generateBackupCodes();
	}

	disableTOTP = async (value: any) => {
		const mfaPromise = value.useRecoveryKey
			? this.multipleFactorAuthService.disableTOTPWithRecoveryKey(value.mfaKey)
			: this.multipleFactorAuthService.disableTOTP(value.mfaKey);

		await mfaPromise.catch((e) => {
			this.notifications.handleAPIError(e);
		});
		this.getEnabledOptions();
		this.sendVerifyCodeToEnableTOTPViaEmailPromise = null;
		this.confirmTOTPViaEmailPromise = null;
		this.totpEnablePromise = null;
		this.totpConfirmPromise = null;
		this.backupCodesPromise = null;
	};
}
