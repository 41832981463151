import { Component, EventEmitter, Input, Output, OnInit, OnDestroy } from '@angular/core';
import { Folder } from '#/models/user/folder';
import { User } from '#/models/user/user.model';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap/modal/modal-ref';
import { Company } from '#/models/company/company.model';
import { Store } from '@ngrx/store';
import { AppState } from '~/app/reducers';
import { ModalService } from '~/app/services/modal.service';

@Component({
	selector: '[app-folders-table-row]',
	templateUrl: './folders-table-row.component.html',
})
export class FoldersTableRowComponent implements OnInit, OnDestroy {
	@Input() group: Folder;
	@Input() checkboxes: {};
	@Input() user: User;
	@Output() groupsChanged = new EventEmitter<any>();
	@Output() checkboxClick = new EventEmitter<any>();
	@Output() openExport = new EventEmitter<string>();

	protected company: Company;

	protected destroyCallbacks = [];

	constructor(private modalService: ModalService, private store: Store<AppState>) {}

	ngOnInit() {
		const companySubscription = this.store.select('company').subscribe((val) => {
			this.company = val.company;
		});

		this.destroyCallbacks.push(() => {
			companySubscription.unsubscribe();
		});
	}

	ngOnDestroy() {
		this.destroyCallbacks.forEach((cb) => cb());
	}

	async rowActionEdit(event) {
		const { FoldersEditModalComponent } = await import('../modals/folders-edit-modal/folders-edit-modal.component');
		const modalRef = this.modalService.open(FoldersEditModalComponent);
		modalRef.componentInstance.group = this.group;
		this.setupGroupsModal(modalRef);
		return false;
	}

	async rowActionSubmitExpense(event) {
		const { ExpensesModalComponent } = await import('~/app/modules/generic/components/modals/expenses-modal/expenses-modal.component');
		const modalRef = this.modalService.open(ExpensesModalComponent);
		modalRef.componentInstance.type = 'group';
		modalRef.componentInstance.action = 'submit';
		this.setupGroupsModal(modalRef);
		return false;
	}

	async rowActionRetractExpense(event) {
		const { ExpensesModalComponent } = await import('~/app/modules/generic/components/modals/expenses-modal/expenses-modal.component');
		const modalRef = this.modalService.open(ExpensesModalComponent);
		modalRef.componentInstance.type = 'group';
		modalRef.componentInstance.action = 'retract';
		this.setupGroupsModal(modalRef);
		return false;
	}

	async rowActionDelete(event) {
		const { DeleteModalComponent } = await import('~/app/modules/generic/components/modals/delete-modal/delete-modal.component');
		const modalRef = this.modalService.open(DeleteModalComponent);
		modalRef.componentInstance.type = 'group';
		this.setupGroupsModal(modalRef);
		return false;
	}

	async rowActionShare(event) {
		const { FoldersShareModalComponent } = await import('../modals/folders-share-modal/folders-share-modal.component');
		const modalRef = this.modalService.open(FoldersShareModalComponent);
		this.setupGroupsModal(modalRef);
		return false;
	}

	setupGroupsModal(modalRef: NgbModalRef) {
		modalRef.componentInstance.checkboxes = {};
		modalRef.componentInstance.checkboxes[this.group.id] = true;
		modalRef.componentInstance.user = this.user;
		let doneSubscription = modalRef.componentInstance.done.subscribe(() => {
			this.groupsChanged.emit(true);
		});

		modalRef.result
			.then((reason) => {
				doneSubscription.unsubscribe();
				doneSubscription = null;
			})
			.catch((reason) => {});

		this.destroyCallbacks.push(() => {
			if (doneSubscription) {
				doneSubscription.unsubscribe();
			}
		});
	}

	checkBoxClicked($event) {
		this.checkboxClick.emit({
			event: $event,
			id: this.group.id,
		});
	}
}
