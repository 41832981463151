import { Component, Host, Input, Optional } from '@angular/core';
import { ValueAccessorBase, FormElementComponent, AppSelectOptions } from '@klippa/ngx-enhancy-forms';
import { TranslateService } from '@ngx-translate/core';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { ControlContainer, NG_VALUE_ACCESSOR } from '@angular/forms';
import { stringIsSetAndFilled } from '#/util/values';

@Component({
	selector: 'app-authorization-flow-status-picker',
	templateUrl: './authorization-flow-status-picker.component.html',
	styleUrls: ['./authorization-flow-status-picker.component.scss'],
	providers: [{ provide: NG_VALUE_ACCESSOR, useExisting: AuthorizationFlowStatusPickerComponent, multi: true }],
})
export class AuthorizationFlowStatusPickerComponent extends ValueAccessorBase<string> {
	@Input() placeholder: string;
	@Input() clearable = true;
	@Input() multiple = false;
	@Input() inTurnIsAvailable = true;

	public options: AppSelectOptions = [
		{
			id: 'in_auth_flow',
			name: this.translateService.instant(_('In authorization flow')),
		},
		{
			id: 'not_in_auth_flow',
			name: this.translateService.instant(_('Not in authorization flow')),
		},
	];

	constructor(
		@Host() @Optional() protected parent: FormElementComponent,
		@Host() @Optional() protected controlContainer: ControlContainer,
		private translateService: TranslateService,
	) {
		super(parent, controlContainer);
	}

	ngOnInit() {
		super.ngOnInit();
		if (!stringIsSetAndFilled(this.placeholder)) {
			this.placeholder = this.translateService.instant(_('Pick an option'));
		}

		if (this.inTurnIsAvailable) {
			this.options.push(
				{
					id: 'my_turn',
					name: this.translateService.instant(_('My turn to approve')),
				},
				{
					id: 'not_my_turn',
					name: this.translateService.instant(_('Not my turn to approve')),
				},
				{
					id: 'not_in_auth_flow_or_my_turn',
					name: this.translateService.instant(_('Not in authorization flow or my turn to approve')),
				},
				{
					id: 'not_in_auth_flow_or_not_my_turn',
					name: this.translateService.instant(_('Not in authorization flow or not my turn to approve')),
				},
			);
		}
	}
}
