import { Injectable } from '@angular/core';
import { PossibleCompanyFeatureFlags } from '#/models/company/possible-feature-flags';
import { CompanyFeatureFlagsService } from '#/services/company/company-feature-flags.service';
import { UserService } from '~/app/modules/user/user.service';
import { ActivatedRoute, Router } from '@angular/router';
import { isValueSet } from '#/util/values';
import { ModalService } from '~/app/services/modal.service';
import { ReceiptService } from '#/services/transaction/receipt.service';

@Injectable({
	providedIn: 'root',
})
export class TransactionRoutingService {
	constructor(
		private companyFeatureFlagsService: CompanyFeatureFlagsService,
		private userService: UserService,
		private activatedRoute: ActivatedRoute,
		private router: Router,
		private modalService: ModalService,
		private receiptAPIService: ReceiptService,
	) {}

	public async openReceipt(receiptId: string, tableType: 'expenses' | 'invoices' | 'personal' = 'personal'): Promise<void> {
		const redirectedToTXI = await this.companyFeatureFlagsService
			.getAllFeatureFlags(this.userService.getCurrentLoggedUser().company)
			.then((res) => {
				const hasNewTxInterfacesFeature = res.includes(PossibleCompanyFeatureFlags.TRANSACTION_INTERFACES);
				if (hasNewTxInterfacesFeature) {
					this.router.navigate([`/tx/${receiptId}`], {
						relativeTo: this.activatedRoute,
					});
					return true;
				}
				return false;
			});
		if (redirectedToTXI) {
			return;
		}

		try {
			const { ReceiptEditModalComponent } = await import(
				'~/app/modules/receipt/components/modals/receipt-edit-modal/receipt-edit-modal.component'
			);
			const receipt = await this.receiptAPIService.getReceipt(receiptId);

			const modalRef = this.modalService.open(ReceiptEditModalComponent, { size: 'lg' });
			const user = this.userService.getCurrentLoggedUser();
			modalRef.componentInstance.receipt = receipt;
			modalRef.componentInstance.savedInReport = isValueSet(receipt.report);
			modalRef.componentInstance.user = user;
			modalRef.componentInstance.tableType = tableType;
		} catch (e) {
			throw e;
		}
	}
}
