import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import { AsyncInfoDependantGuard } from '~/app/guards/async-info-dependant-guard.service';
import { UserService } from '~/app/modules/user/user.service';
import { CompanyService } from '#/services/company/company.service';
import { UserFeatureFlagsService } from '~/app/modules/admin/components/admin-user/user-feature-flags-service/user-feature-flags.service';
import { CompanyApiService } from '#/services/company/company-api.service';

@Injectable({
	providedIn: 'root',
})
export class CanAccessUiPlaygroundGuard extends AsyncInfoDependantGuard implements CanActivate {
	constructor(
		protected userService: UserService,
		protected companyService: CompanyService,
		protected companyApiService: CompanyApiService,
		protected userFeatureFlagsService: UserFeatureFlagsService,
	) {
		super(userService, companyService, companyApiService);
	}

	async canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
		try {
			await this.getUser();
			// TODO: check for the feature flags
			// if (user.hasFeatureFlags()) {
			return true;
			// }
		} catch (e) {
			return false;
		}
	}
}
