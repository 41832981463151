export class InvoiceSuggestionFrontendModel {
	accountNumber: string;
	administration: string;
	category: string;
	costCenter: string;
	costUnit: string;
	project: string;
	customPaymentMethod: string;
	paymentMethod: string;
	transportationType: string;
}
