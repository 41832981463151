export class Icon {
	id: string;
	title: string;
	image: string;
	keywords: string[];

	constructor(data = null) {
		if (data) {
			Object.assign(this, data);
		}
	}

	hasImage(): boolean {
		return this.image && this.image !== '';
	}

	getID(): string {
		return this.id;
	}

	getTitle(): string {
		return this.title;
	}

	getImage(): string {
		return this.image;
	}

	getKeywords(): string[] {
		return this.keywords;
	}

	getSortedKeywords(): string[] {
		if (this.getKeywords()) {
			return this.getKeywords().sort((a, b) => a.localeCompare(b));
		}
		return [];
	}

	clone(): Icon {
		return new Icon(JSON.parse(JSON.stringify(this)));
	}
}
