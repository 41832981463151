// Capabilities

// booking headers
// journals: van welk stukje vermogen het geld vandaan komt. From what capital a purchase is made. eg: ING bank/rabo bank/kas/fooienpot
// vatIncExcl: normally it is always vat inclusive. With this capability, you can choose to set it to exclusive, which changes the ui, but the fact that amount in booking line is vatinclusive does NOT change
// VAtPercentages: not relevant anymore
// VAtPercentagesByDate: vat percentages in a code can change, if this capability is true, depending on the date, the percentage can be different. Frontend should always just send the date along when asking for the vat codes
// VAtReverseCharge: cross country terug krijgen van btw. capability tells me if a vat code can be reverse charge
// booking lines

import { AmountWithCurrency } from '#/models/transaction/amountWithCurrency';
import { GAccountValue } from '#/models/gAccount';

export class BookingLineFrontendModel {
	// describe what this booking line is for
	description: string;
	// general ledger / grootboekrekening: in accounting bepaalde bedragen bij elkaar. Zie het als een categorie voor uitgaven. Binnen klippa zou dit kunne zijn
	// kostensoort: food, hardware, travel, office supplies
	ledger: string;
	// the amount of money of this booking line, always vat inclusive. (bruto/gross)
	amountWithCurrency: AmountWithCurrency;
	// the amount of money that is the vat part of the amount.
	// lets say that you bought an item for 121eur, with 21% btw. Then amount = 121 (bruto), vatAmount = 21. the 100 eur is netto/net, and is NOT sent to the api in a field
	vatAmountWithCurrency: AmountWithCurrency;
	// btw code, bepaalt percentage en of het incl excl
	vatCode: string;
	// percentage that belongs to the vatCode
	vatPercentage: number;
	// order = inkooporder/bestelling. bookinglines that have the same purchaseOrder belong to eachother
	// TODO
	purchaseOrder: string;
	// single entity of a list of purchases. eg: 100x chair, 20x table. Then the 100x chairs is a line. The value of this is a remote id that can be found in the bookkeeping software
	// TODO
	purchaseOrderLine: string;

	// dimensions here are fully dynamic keys and can be anything
	// To get the available dimensions: company/${COMP_ID}/integrations/${INTEGRATION_ID}/dimensions?division=${DIV_ID}

	// DisplayNameMultiple: "Vestigingen"
	// DisplayNameSingle: "Vestiging"
	// Identifier: "cost_centers"
	// Localize: false
	// Searchable: false

	// To get the dataset that belongs to a dimension, you can call the endpoint:
	// TODO: I need start and search here
	// company/${COMPANY_ID}/integrations/${INTEGRATION_ID}/dimensionData/${DIMENSION_ID}?division=${DIV_ID}

	// examples of dimensions
	// division: spendControl, docHorizon, dataNorth
	// costCenter: Brasov, Adam, Groningen
	// costUnit: Dev, ops, marketing, sales
	// project: receipt edit modal rewrite, accounting api rewrite, credit card feature
	dimensions: { [key: string]: string };
	// spreiden van kosten over periode. start date
	deferredFrom: string; // YYYY-MM-DD
	// spreiden van kosten over periode. end date
	deferredTo: string; // YYYY-MM-DD
	// date of booking
	bookingDate: string; // YYYY-MM-DD
	// payment condition
	paymentCondition: string;
	// https://www.betaalvereniging.nl/betaalproducten-en-diensten/g-rekening/
	// special account from which you can only make payments to other gAccounts, or a special account from the belastingdienst.
	// You can put money in, but not take it out. Used when countries outside of NL hire people within NL.
	gAccountAmountAndPercentage: GAccountValue;

	constructor(data?: any) {
		Object.assign(this, data);
	}
}
