<ngx-async-template [promise]="clearIntegrationsCachePromise">
	<ng-template ngx-async-success ngx-async-inactive ngx-async-error>
		<div class="componentContainer">
			<app-dynamic-options-picker
				[getPlaceholderTextFn]="getPlaceholderTextFn"
				[fetchItemsFn]="fetchItemsFn"
				[fetchSelectedItemsFn]="fetchSelectedItemsFn"
				[mapToSelectOptionFn]="mapToSelectOptionFn"
				[sortValuesFn]="sortValuesFn"
				[selectableItems]="selectableItems"
				[ngClass]="{ showErrors: isInErrorState() }"
				[(ngModel)]="innerValue"
				(ngModelChange)="setInnerValueAndNotify($event)"
				[multiple]="multiple"
				[disabled]="disabled"
				[clearable]="clearable"
				[dropdownPosition]="dropdownPosition"
				[orientation]="orientation"
				[truncateOptions]="truncateOptions"
				[autoSelectOnSingleResult]="autoSelectOnSingleResult"
			></app-dynamic-options-picker>
			<app-button
				variant="icon"
				[hasPadding]="false"
				[hasBorder]="false"
				[fullWidth]="true"
				(click)="refreshIntegrations()"
				[disabled]="!hasSomethingSelected() || disabled"
				*ngIf="showClearCacheButton"
			>
				<app-icon variant="refresh"></app-icon>
			</app-button>
		</div>
	</ng-template>
	<ng-template ngx-async-pending>
		<klp-form-loading-indicator variant="picker"></klp-form-loading-indicator>
	</ng-template>
</ngx-async-template>
