<td class="table-end-left-padding" (click)="$event.stopPropagation()">
	<div class="checkbox">
		<input
			[attr.id]="'tag-checkbox-' + tag.id"
			[attr.name]="'tag-checkbox-' + tag.id"
			type="checkbox"
			(click)="checkBoxClicked($event)"
			[(ngModel)]="checkboxes[tag.id]"
			[value]="true"
		/>
		<label [attr.for]="'tag-checkbox-' + tag.id"></label>
	</div>
</td>
<td>
	<div class="mrg-top-10">
		{{ tag.getTag() }}
	</div>
</td>
<td>
	<div class="mrg-top-10">
		{{ tag.getTotalReceipts() }}
	</div>
</td>
<td class="table-end-right-padding text-center no-click">
	<div class="mrg-top-10">
		<button
			class="btn btn-icon btn-flat btn-rounded dropdown-toggle"
			role="button"
			[attr.id]="'receipt-dropdown-' + tag.id"
			data-toggle="dropdown"
			aria-haspopup="true"
			aria-expanded="false"
		>
			<i class="ti-more-alt"></i>
		</button>
		<div class="dropdown-menu" [attr.aria-labelledby]="'receipt-dropdown-' + tag.id">
			<button class="dropdown-item" (click)="rowActionEdit($event)" translate>Edit</button>
			<button class="dropdown-item" *ngIf="userIsConnectedToCompany()" (click)="rowActionSubmitExpenses($event)" translate>
				Submit expenses
			</button>
			<button class="dropdown-item" *ngIf="userIsConnectedToCompany()" (click)="rowActionRetractExpenses($event)" translate>
				Retract expenses
			</button>
			<button class="dropdown-item" (click)="openExport.emit(tag.getID())" translate>Export</button>
			<button class="dropdown-item" (click)="rowActionDelete($event)" translate>Delete</button>
		</div>
	</div>
</td>
