export class Random {
	static charSetLowerUpper = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
	static charSetUpper = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
	static charSetLower = 'abcdefghijklmnopqrstuvwxyz0123456789';

	static randomStringLowerUpper(length: number = 16) {
		return Random.randomString(Random.charSetLowerUpper, length);
	}

	static randomStringUpper(length: number = 16) {
		return Random.randomString(Random.charSetUpper, length);
	}

	static randomStringLower(length: number = 16) {
		return Random.randomString(Random.charSetLower, length);
	}

	static randomString(charSet: string, length: number = 16) {
		let result = '';
		for (let i = 0; i < length; i++) {
			const randomPoz = Math.floor(Math.random() * charSet.length);
			result += charSet.substring(randomPoz, randomPoz + 1);
		}
		return result;
	}
}
