import { Injectable } from '@angular/core';
import { ExtraInputField, Receipt, ReceiptFile, ReceiptTravelDeclaration } from '#/models/transaction/receipt';
import { FileUtils } from '~/app/helpers/file-utils';
import { ReceiptService } from '#/services/transaction/receipt.service';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngrx/store';
import {
	Company,
	CompanyModuleCompensationRulesRule,
	CompanyModuleReceiptPresetChainedPreset,
	CompanyModuleReceiptPresetsPreset,
} from '#/models/company/company.model';
import { CompanyService } from '#/services/company/company.service';
import { AppState } from '~/app/reducers';
import { User } from '#/models/user/user.model';
import { isValueSet, stringIsSetAndFilled, useIfArrayIsSetWithOneItem, useIfStringIsSet } from '#/util/values';
import { UserService } from '~/app/modules/user/user.service';
import { MerchantService } from '~/app/modules/merchant/merchant.service';
import { APIService } from '~/app/api/services/api.service';
import { Merchant } from '#/models/merchant';
import { arrayIsSetAndFilled } from '#/util/arrays';
import { CompanyObjectListAPIRequest } from '#/models/company/company-object-api-request.model';
import { DimensionSettings } from '#/models/company/dimension/dimenstionSettings.model';
import { cloneDeep } from 'lodash';
import { FileService } from '#/services/file.service';

class ReceiptFlowStep {
	public preset: CompanyModuleReceiptPresetsPreset;
	public chainedPreset: CompanyModuleReceiptPresetChainedPreset;
	public receipt: Receipt;
	public files: ReceiptFile[];
	public compensationRule: CompanyModuleCompensationRulesRule;

	public enabled = true;
	public active = false;
	public isNew = true;

	constructor(preset: CompanyModuleReceiptPresetsPreset, user: User, enabled: boolean = true, active: boolean = false) {
		this.preset = preset;
		this.enabled = enabled;
		this.active = active;
		this.initReceipt(user);
	}

	setChainedPreset(chainedPreset: CompanyModuleReceiptPresetChainedPreset) {
		this.chainedPreset = chainedPreset;
		return this;
	}

	initReceipt(user: User) {
		let currency = this.preset.currency;
		if (
			(!currency || currency === '') &&
			user &&
			user.getPreferences() &&
			user.getPreferences().currency &&
			user.getPreferences().currency !== ''
		) {
			currency = user.getPreferences().currency;
		}

		const data = {
			type: useIfStringIsSet(this.preset.receipt_type),
			finance_type: useIfStringIsSet(this.preset.finance_type),
			description: useIfStringIsSet(this.preset.description),
			amount: this.preset.amount,
			currency: useIfStringIsSet(currency),
			presets: {
				merchants: this.preset?.merchants,
				companyadministrations: this.preset?.companyadministrations,
				companycategories: this.preset?.companycategories,
				companycostcenters: this.preset?.companycostcenters,
				companycostunits: this.preset?.companycostunits,
				companyprojects: this.preset?.companyprojects,
				countries: this.preset?.countries,
				payment_methods: this.preset?.payment_methods,
				payment_method_ids: this.preset?.payment_method_ids,
			},
			merchant: useIfArrayIsSetWithOneItem(this.preset.merchants),
			companyadministration: useIfArrayIsSetWithOneItem(this.preset.companyadministrations),
			companycategory: useIfArrayIsSetWithOneItem(this.preset.companycategories),
			companycostcenter: useIfArrayIsSetWithOneItem(this.preset.companycostcenters),
			companycostunit: useIfArrayIsSetWithOneItem(this.preset.companycostunits),
			companyproject: useIfArrayIsSetWithOneItem(this.preset.companyprojects),
			country: useIfArrayIsSetWithOneItem(this.preset.countries),
		};

		/* Initialize the receipt object for this ReceiptFlowStep. */
		this.receipt = new Receipt(data);
		if (this.preset.receipt_type === 'travel') {
			this.receipt.traveldeclaration = ReceiptTravelDeclaration.new();
		}

		this.receipt.created_with_preset = this.preset.code;
	}

	copyFieldsFromPreviousStep(previousStep: ReceiptFlowStep = null) {
		/* Copy over any fields from the previousStep. */
		if (previousStep && this.chainedPreset && !this.receipt['__copied__']) {
			this.chainedPreset.copy_fields_over.forEach((field) => {
				const value = previousStep.receipt[field];
				if (field === 'start_end_dates' || field === 'start_end_dates_with_time') {
					previousStep.receipt.copyStartEndDates(this.receipt);
				} else if (typeof value !== 'undefined') {
					/* Clone value. */
					this.receipt[field] = JSON.parse(JSON.stringify(value));
				}
				if (field === 'attachments' && previousStep.files) {
					/* Copy over files. */
					this.files = JSON.parse(JSON.stringify(previousStep.files));
				}
			});

			this.receipt = new Receipt(this.receipt);
			this.receipt['__copied__'] = true;
		}
	}
}

@Injectable({
	providedIn: 'root',
})
export class ReceiptFlowService {
	public steps: ReceiptFlowStep[] = [];
	public company: Company;
	public isNew = false;
	private receiptOnInit: Receipt; // used for comparison to check if things have changed

	constructor(
		protected store: Store<AppState>,
		private receiptAPIService: ReceiptService,
		private fileService: FileService,
		private translate: TranslateService,
		private companyAPIService: CompanyService,
		private userService: UserService,
		private merchantService: MerchantService,
		private apiService: APIService,
	) {
		this.store.select('company').subscribe((val) => {
			this.company = val.company;
		});
	}

	public initWithPreset(preset: CompanyModuleReceiptPresetsPreset, user: User) {
		this.isNew = false;
		this.steps = [];

		if (preset) {
			this.isNew = true;
			this.steps = [new ReceiptFlowStep(preset, user, true, true)];
			if (preset.preset_chain) {
				preset.preset_chain.forEach((chainedPreset) => {
					/* We also need the add chained presets to the receipt flow. */
					const extraPreset = this.getPresetByCode(chainedPreset.preset_code);
					if (chainedPreset.open_if && chainedPreset.open_if.check_box) {
						/* Optional chained preset. */
						this.steps.push(new ReceiptFlowStep(extraPreset, user, false, false).setChainedPreset(chainedPreset));
					} else {
						/* Non-optional chained preset. */
						this.steps.push(new ReceiptFlowStep(extraPreset, user, true, false).setChainedPreset(chainedPreset));
					}
				});
			}
		}
	}

	initWithReceipt(receipt: Receipt, user: User) {
		this.isNew = false;
		this.steps = [];

		if (receipt.created_with_preset !== '') {
			const createdWithPreset = this.getPresetByCode(receipt.created_with_preset);
			if (createdWithPreset) {
				const clonedPreset = createdWithPreset.clone();

				// Disable chain when existing receipt.
				clonedPreset.preset_chain = [];

				if (clonedPreset.hide_fields && clonedPreset.hide_fields.length > 0) {
					const newHiddenFields = [];
					clonedPreset.hide_fields.forEach((hideField) => {
						if (typeof receipt[hideField] === 'undefined' || !receipt[hideField] || clonedPreset[hideField]) {
							newHiddenFields.push(hideField);
						}
					});

					clonedPreset.hide_fields = newHiddenFields;
				}

				this.steps = [new ReceiptFlowStep(clonedPreset, user, true, true)];
				this.steps[0].receipt = receipt;
				this.steps[0].isNew = false;
			}
		}
	}

	public getStepForChainedPreset(chainedPreset: CompanyModuleReceiptPresetChainedPreset) {
		return this.steps.find((step) => step.preset.code === chainedPreset.preset_code);
	}

	public toggleChainedPreset(chainedPreset: CompanyModuleReceiptPresetChainedPreset) {
		const stepForChainedPreset = this.getStepForChainedPreset(chainedPreset);
		if (stepForChainedPreset) {
			stepForChainedPreset.enabled = !stepForChainedPreset.enabled;
		}
	}

	public isInReceiptFlow() {
		return this.steps.length > 0;
	}

	public hasMultipleSteps() {
		return this.steps.length > 1;
	}

	public checkForMissingFields(dimensionSettings: DimensionSettings, receipt_for_dimensions?: Receipt): Array<string> {
		/* Finds missing fields on this preset. Returns an array of missing, but required fields. */
		const missingFields = [];

		if (isValueSet(dimensionSettings)) {
			const receiptToCheck = receipt_for_dimensions;
			if (dimensionSettings.costcenter?.required && !stringIsSetAndFilled(receiptToCheck.companycostcenter)) {
				missingFields.push('companycostcenter');
			}
			if (dimensionSettings.costunit?.required && !stringIsSetAndFilled(receiptToCheck.companycostunit)) {
				missingFields.push('companycostunit');
			}
			if (dimensionSettings.project?.required && !stringIsSetAndFilled(receiptToCheck.companyproject)) {
				missingFields.push('companyproject');
			}
		}

		const currentPreset = this.getCurrentPreset();
		if (currentPreset && currentPreset.require_fields) {
			const receipt = this.getCurrentStep().receipt;
			/* Preset active, check the required fields. */
			currentPreset.require_fields.forEach((fieldName) => {
				const value = receipt[fieldName];
				if (fieldName === 'attachments') {
					if (
						(!this.getCurrentStep().files || this.getCurrentStep().files.length === 0) &&
						(!receipt.images || receipt.images.length === 0) &&
						(!receipt.documents || receipt.documents.length === 0)
					) {
						missingFields.push(fieldName);
					}
				} else if (fieldName === 'amount' && value === 0) {
					missingFields.push(fieldName);
				} else if (fieldName === 'start_end_dates' && value && (!value.start || !value.end)) {
					missingFields.push(fieldName);
				} else if (fieldName === 'dates' && !arrayIsSetAndFilled(value)) {
					missingFields.push(fieldName);
				} else if (fieldName === 'paymentmethod' && !stringIsSetAndFilled(value)) {
					const user = this.userService.getCurrentLoggedUser();
					if (user.canUsePaymentInfo() && user.preferences.paymentinfo) {
						missingFields.push(fieldName);
					}
				} else if (fieldName === 'payment_method_id' && !stringIsSetAndFilled(value)) {
					const user = this.userService.getCurrentLoggedUser();
					if (user.canUsePaymentInfo() && user.preferences.paymentinfo) {
						missingFields.push(fieldName);
					}
				} else if (value === null || value === undefined || value === '') {
					missingFields.push(fieldName);
				} else if (fieldName === 'vatitems' || fieldName === 'tags') {
					if (!Array.isArray(value) || value.length === 0) {
						/* No items.*/
						missingFields.push(fieldName);
					} else {
						if (fieldName === 'vatitems') {
							/* Check for at least one valid VAT item. */
							if (!value.some((receiptVatItem) => receiptVatItem.amount && receiptVatItem.percentage)) {
								missingFields.push(fieldName);
							}
						} else {
							/* Check for at least one valid tag. */
							if (!value.some((item) => Boolean(item))) {
								missingFields.push(fieldName);
							}
						}
					}
				}
			});
		}
		return missingFields;
	}

	isRequiredField(fieldName: string): boolean {
		return this.getCurrentPreset() ? this.getCurrentPreset().isRequiredField(fieldName) : false;
	}

	shouldShowExtraField(fieldName: ExtraInputField): boolean {
		return this.getCurrentPreset()?.shouldShowExtraField(fieldName);
	}

	shouldHideField(fieldName: string, hasMultipleOptions: boolean = false): boolean {
		if (hasMultipleOptions) {
			if (!isValueSet(this.receiptOnInit?.[fieldName])) {
				return false;
			}
		}
		return this.getCurrentPreset() ? this.getCurrentPreset().shouldHideField(fieldName) : false;
	}

	private getCompanyObjectListAPIRequest(): CompanyObjectListAPIRequest {
		const filters = new CompanyObjectListAPIRequest();
		filters.company = this.company.id;
		filters.administration = this.currentReceipt.companyadministration;
		filters.active = true;
		filters.groups = this.userService.getCurrentLoggedUser().getCompanyGroups();
		return filters;
	}

	public getCountryBasedOnPresets(preset: string[]): Promise<any> {
		return this.apiService.getFromApi('country/names/en-GB').then((data) => {
			if (arrayIsSetAndFilled(preset)) {
				return data.data.filter((item) => preset.includes(item.code));
			}
			return data.data;
		});
	}

	public getMerchantBasedOnPresets(preset: string[]): Promise<Merchant[]> {
		return this.merchantService.getAllAvailableMerchants().then((data) => {
			if (arrayIsSetAndFilled(preset)) {
				return data.filter((item) => preset.includes(item.id));
			}
			return data;
		});
	}

	public getCurrentPreset() {
		return this.getCurrentStep() ? this.getCurrentStep().preset : null;
	}

	public getPresetByCode(preset_code: string): CompanyModuleReceiptPresetsPreset {
		let presets = [];
		if (this.company.modules.receipt_presets) {
			presets = this.company.modules.receipt_presets.presets;
		}
		return presets.find((preset) => preset.code === preset_code);
	}

	get currentReceipt() {
		return this.getCurrentStep() ? this.getCurrentStep().receipt : null;
	}

	set currentReceipt(receipt: Receipt) {
		if (this.getCurrentStep()) {
			this.getCurrentStep().receipt = receipt;
		}
	}

	hasPreviousStep() {
		return Boolean(this.getPreviousStep());
	}

	hasNextStep() {
		return Boolean(this.getNextStep());
	}

	getNextStep() {
		return this.steps.slice(this.getCurrentStepIndex() + 1).find((step) => step.enabled === true);
	}

	getPreviousStep() {
		return this.steps
			.slice(0, this.getCurrentStepIndex())
			.reverse()
			.find((step) => step.enabled === true);
	}

	getCurrentStep() {
		return this.steps.find((step) => step.active === true);
	}

	getCurrentStepIndex() {
		return this.steps.findIndex((step) => step.active === true);
	}

	receiptFlowStepBack(): boolean {
		if (this.hasPreviousStep()) {
			const previousStep = this.getPreviousStep();
			const currentStep = this.getCurrentStep();
			previousStep.active = true;
			currentStep.active = false;
			return true;
		}
		return false;
	}

	receiptFlowStep(): Promise<any> {
		return new Promise((resolve, reject) => {
			if (this.hasNextStep()) {
				const nextStep = this.getNextStep();
				const currentStep = this.getCurrentStep();
				nextStep.active = true;
				nextStep.copyFieldsFromPreviousStep(currentStep);
				currentStep.active = false;
				this.receiptOnInit = cloneDeep(nextStep.receipt);
				return resolve(false);
			} else {
				return this.completeFlow()
					.then((r) => {
						return resolve(true);
					})
					.catch((reason) => {
						reject(reason);
					});
			}
		});
	}

	completeFlow(): Promise<any> {
		// Save all receipts
		const promises = [];
		this.steps.forEach((step) => {
			if (step.enabled) {
				promises.push(
					this.saveStep(step)
						.then((r) => {
							return Promise.resolve(r);
						})
						.catch((r) => {
							return Promise.reject(r);
						}),
				);
			}
		});
		return Promise.all(promises)
			.then((r) => {
				this.steps = [];
				return Promise.resolve(r);
			})
			.catch((r) => {
				this.steps = [];
				return Promise.reject(r);
			});
	}

	saveStep(step: ReceiptFlowStep): Promise<any> {
		return new Promise<any>((resolve, reject) => {
			const promises = [];

			if (step.preset && step.preset.skip_save) {
				return resolve(undefined);
			}

			if (step.isNew) {
				return this.receiptAPIService
					.createReceipt(step.receipt)
					.then((new_receipt) => {
						if (step.files) {
							step.files.forEach((file) => {
								if (FileUtils.isImage(file.file)) {
									// Image
									promises.push(
										this.fileService
											.addImage(new_receipt.id, file.file)
											.then((r) => {
												return Promise.resolve(r);
											})
											.catch((r) => {
												return Promise.resolve(r);
											}),
									);
								} else {
									// Document.
									promises.push(
										this.fileService
											.addDocument(new_receipt.id, file.file)
											.then((r) => {
												return Promise.resolve(r);
											})
											.catch((r) => {
												return Promise.resolve(r);
											}),
									);
								}
							});
						}
						// All attachments uploaded.
						if (this.company?.modules?.report?.enabled) {
							return resolve(new_receipt);
						}
						return Promise.all(promises).then((r) => {
							return this.receiptAPIService.submitExpense(new_receipt.id).then((s) => {
								return resolve(new_receipt);
							});
						});
					})
					.catch((r) => {
						return reject(r);
					});
			} else {
				return this.receiptAPIService
					.updateReceipt(step.receipt)
					.then((updatedReceipt) => {
						return resolve(updatedReceipt);
					})
					.catch((r) => {
						return reject(r);
					});
			}
		});
	}
}
