import { Component, DoCheck, ElementRef, EventEmitter, HostListener, Input, IterableDiffer, IterableDiffers, Output } from '@angular/core';
import { SelectActionInterface } from '~/app/shared/ui/select-action/select-action';
import { cloneDeep } from 'lodash';
import { isValueSet } from '#/util/values';

@Component({
	selector: 'app-select-action',
	templateUrl: './select-action.component.html',
	styleUrls: ['./select-action.component.scss'],
})
export class SelectActionComponent implements DoCheck {
	@Output() public onDismiss: EventEmitter<void> = new EventEmitter<void>();
	@Output() public onAction = new EventEmitter<string>();

	@Input() actions: () => SelectActionInterface[];
	filteredActions: SelectActionInterface[];
	@Input() selected: {
		rows: Array<string>;
		all: boolean;
	} = { rows: [], all: false };
	public multipleSelected = false;
	public anySelected = true;
	public showActionList = false;
	private differ: IterableDiffer<any>;

	constructor(private differs: IterableDiffers, private elementRef: ElementRef) {
		this.differ = this.differs.find(this.selected.rows).create();
	}

	ngOnInit() {
		this.populateFilteredActions();
	}

	closeModal(): void {
		this.onDismiss.emit();
	}

	emitAction(action: string) {
		this.onAction.emit(action);
	}

	openActionList() {
		this.showActionList = true;
	}

	toggleActionList() {
		this.showActionList = !this.showActionList;
	}

	@HostListener('document:click', ['$event']) _(event) {
		if (!this.elementRef.nativeElement.contains(event.target)) {
			this.showActionList = false;
		}
	}

	ngDoCheck() {
		const changes = this.differ.diff(this.selected.rows);
		if (isValueSet(changes)) {
			this.populateFilteredActions();
		}
	}

	private populateFilteredActions() {
		if (this.selected.all === true) {
			this.multipleSelected = true;
			this.anySelected = true;
		}
		if (this.selected.rows.length > 1) {
			this.multipleSelected = true;
			this.anySelected = true;
		}
		if (this.selected.rows.length === 1) {
			this.multipleSelected = false;
			this.anySelected = true;
		}
		if (this.selected.rows.length < 1) {
			this.anySelected = false;
		}

		this.filteredActions = cloneDeep(this.actions()) || [];
		// If multiple rows are selected, only show actions whom can be executed on multiple items.
		if (this.multipleSelected) {
			this.filteredActions = this.filteredActions.filter((action) => action.multiple === true);
		}
	}
}
