import { Injectable } from '@angular/core';
import { Receipt } from '#/models/transaction/receipt';
import { APIService } from '~/app/api/services/api.service';
import { APINotificationsService } from '~/app/api/services/apinotifications.service';
import { AttachmentType } from '#/models/transaction/attachment';
import { ApiOCREnhancedFile } from '#/models/ocrEnhancedFiles.model';
import { OcrEnhancedFilesService } from '##/ocrEnhancedFiles/ocrEnhancedFiles.service';

@Injectable({
	providedIn: 'root',
})
export class FileService {
	private filesCache = new Map<string, File>();

	constructor(
		private apiService: APIService,
		private notifications: APINotificationsService,
		private ocrEnhancedFilesService: OcrEnhancedFilesService,
	) {}

	public async getFile(receiptId: string, fileId: string, type: AttachmentType): Promise<Blob> {
		if (this.filesCache.has(fileId)) {
			return this.filesCache.get(fileId);
		} else {
			if (type === AttachmentType.IMAGE) {
				return this.getImage(receiptId, fileId);
			} else if (type === AttachmentType.OCR_ENHANCED_FILE) {
				return this.getOcrEnhancedFile(fileId);
			} else {
				return this.getDocument(receiptId, fileId);
			}
		}
	}

	private putFileInCache(fileId: string, file: File): void {
		this.filesCache.set(fileId, file);
	}

	public getImage(receiptId: string, imageId: string): Promise<File> {
		return this.apiService.getBlob('/api/v1/receipt/' + receiptId + '/image/' + imageId).catch((r) => {
			this.notifications.handleAPIError(r);
			throw r;
		});
	}

	public getOcrEnhancedFile(fileId: string): Promise<Blob> {
		return this.ocrEnhancedFilesService.downloadWebOcrEnhancedFile(fileId).catch((r) => {
			this.notifications.handleAPIError(r);
			throw r;
		});
	}

	public async getOcrEnhancedFileInformation(fileId: string): Promise<ApiOCREnhancedFile> {
		return await this.ocrEnhancedFilesService.getOcrEnhancedFileById(fileId).catch((r) => {
			this.notifications.handleAPIError(r);
			throw r;
		});
	}

	public addImage(receiptId: string, file: File, skip_ocr = false, direct_ocr = false): Promise<Receipt> {
		const formData = new FormData();
		formData.append(AttachmentType.IMAGE, file);
		formData.append('skip_ocr', '' + skip_ocr);
		formData.append('direct_ocr', '' + direct_ocr);
		return this.apiService
			.post('/api/v1/receipt/' + receiptId + '/image', formData)
			.then((r) => {
				const receipt = new Receipt(r['data']);
				const imageId = receipt.images[receipt.images.length - 1].id;
				this.putFileInCache(imageId, file);
				return Promise.resolve(receipt);
			})
			.catch((r) => {
				this.notifications.handleAPIError(r);
				return Promise.reject(r);
			});
	}

	public deleteReceiptImage(id: string, image_id: string): Promise<Receipt> {
		return this.apiService
			.delete('/api/v1/receipt/' + id + '/image/' + image_id)
			.then((r) => {
				const receipt = new Receipt(r['data']);
				return Promise.resolve(receipt);
			})
			.catch((r) => {
				this.notifications.handleAPIError(r);
				return Promise.reject(r);
			});
	}

	public getDocument(id: string, document_id: string): Promise<Blob> {
		return this.apiService
			.getBlob('/api/v1/receipt/' + id + '/document/' + document_id)
			.then((r) => {
				return Promise.resolve(r);
			})
			.catch((r) => {
				this.notifications.handleAPIError(r);
				return Promise.reject(r);
			});
	}

	public addDocument(receiptId: string, file: File, skip_ocr = false, direct_ocr = false): Promise<Receipt> {
		const formData = new FormData();
		formData.append('document', file);
		formData.append('skip_ocr', '' + skip_ocr);
		formData.append('direct_ocr', '' + direct_ocr);
		return this.apiService
			.post('/api/v1/receipt/' + receiptId + '/document', formData)
			.then((r) => {
				const receipt = new Receipt(r['data']);
				const documentId = receipt.documents[receipt.documents.length - 1].id;
				this.putFileInCache(documentId, file);
				return Promise.resolve(receipt);
			})
			.catch((r) => {
				this.notifications.handleAPIError(r);
				return Promise.reject(r);
			});
	}

	public deleteReceiptDocument(id: string, document_id: string): Promise<Receipt> {
		return this.apiService
			.delete('/api/v1/receipt/' + id + '/document/' + document_id)
			.then((r) => {
				const receipt = new Receipt(r['data']);
				return Promise.resolve(receipt);
			})
			.catch((r) => {
				this.notifications.handleAPIError(r);
				return Promise.reject(r);
			});
	}

	public async addOcrEnhancedImage(file: File): Promise<ApiOCREnhancedFile> {
		return await this.ocrEnhancedFilesService.postWebFile(file);
	}

	public async deleteOcrEnhancedImage(fileId: string): Promise<boolean> {
		return await this.ocrEnhancedFilesService.deleteOcrEnhancedFile(fileId);
	}
}
