import { Component, ContentChild, EventEmitter, Input, Output, QueryList, TemplateRef, ViewChildren } from '@angular/core';
import { KlpSelectOptionTemplateDirective, ValueAccessorBase } from '@klippa/ngx-enhancy-forms';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { UI_TYPE } from '#/models/uiType';
import { GenericDynamicOptionsPickerComponent } from '~/app/shared/ui/forms/composed/pickers/dynamic/generic-dynamic-options-picker.component';
import { CanRefetchItemsInterface } from '#/models/interfaces/CanRefetchItemsInterface';
import { DynamicFormElementParameters } from '#/models/dynamic-form-element';
import { IconVariants } from '../../../icon/icon.component';

@Component({
	selector: 'app-dynamic-form-input',
	templateUrl: './dynamic-form-input.component.html',
	styleUrls: ['./dynamic-form-input.component.scss'],
	providers: [{ provide: NG_VALUE_ACCESSOR, useExisting: DynamicFormInputComponent, multi: true }],
})
export class DynamicFormInputComponent extends ValueAccessorBase<any> implements CanRefetchItemsInterface {
	public UI_TYPE = UI_TYPE;
	@ContentChild(KlpSelectOptionTemplateDirective, { read: TemplateRef }) customOptionTpl: TemplateRef<any>;
	@ViewChildren(GenericDynamicOptionsPickerComponent) pickers: QueryList<GenericDynamicOptionsPickerComponent>;

	@Input() truncateOptions: boolean = false;
	@Input() dropdownPosition: 'top' | 'bottom' | 'left' | 'right' | 'auto' = null;
	@Input() parameters: DynamicFormElementParameters;
	@Input() sideButton: keyof typeof IconVariants;
	@Output() onPickerOpened = new EventEmitter<void>();
	@Output() onPickerClosed = new EventEmitter<void>();
	@Output() onSideButtonClicked = new EventEmitter<void>();

	public _ext_refetchItems() {
		this.pickers.forEach((e) => e._ext_refetchOptions());
	}
}
