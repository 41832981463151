import { Component, Input } from '@angular/core';

@Component({
	selector: 'app-attachment-missing-badge',
	templateUrl: './attachment-missing-badge.component.html',
	styleUrls: ['./attachment-missing-badge.component.scss'],
})
export class AttachmentMissingBadgeComponent {
	@Input() markedAsNotAvailable: boolean;
	@Input() hasAttachments: boolean;
}
