import { Component, Host, Input, Optional } from '@angular/core';
import { ControlContainer, NG_VALUE_ACCESSOR } from '@angular/forms';
import { CompanyService } from '#/services/company/company.service';
import { AppSelectOption, FormElementComponent } from '@klippa/ngx-enhancy-forms';
import { UserService } from '~/app/modules/user/user.service';
import { DynamicOptionsValueAccessorBase } from '~/app/shared/ui/forms/composed/pickers/dynamic/dynamic-options-picker/dynamic-options-value-accessor-base';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { InterfaceFrontendModel as TransactionInterfaceModel } from '#/models/transaction/interface/frontendModel';
import { TransactionInterfaceService } from '#/services/transaction/transaction-interface.service';
import { ItemsWithHasMoreResultsPromise } from '#/models/appSelectOption.model';

@Component({
	selector: 'app-interface-picker',
	templateUrl: './dynamic-options-picker/dynamic-options-picker.template.html',
	styleUrls: ['./dynamic-options-picker/dynamic-options-picker.template.scss'],
	providers: [{ provide: NG_VALUE_ACCESSOR, useExisting: InterfacePickerComponent, multi: true }],
})
export class InterfacePickerComponent extends DynamicOptionsValueAccessorBase<string, TransactionInterfaceModel> {
	@Input() public filterOnUserGroups: boolean = true;
	@Input() public includeInactiveInterfaces: boolean = true;

	constructor(
		@Optional() @Host() protected parent: FormElementComponent,
		@Optional() @Host() protected controlContainer: ControlContainer,
		private companyApiService: CompanyService,
		private userService: UserService,
		private transactionInterfaceService: TransactionInterfaceService,
	) {
		super(parent, controlContainer);
		this.defaultPlaceHolder = _('Select transaction interface');
	}

	public fetchItemsFn = async (start: number, searchQuery: string = ''): ItemsWithHasMoreResultsPromise<TransactionInterfaceModel> => {
		return this.transactionInterfaceService.getInterfaces(false, this.includeInactiveInterfaces, this.filterOnUserGroups).then((res) => {
			return {
				hasMoreResults: false,
				// TODO: Remove this when the API is improved and supports it.
				items: res.filter((i) => i.matches(searchQuery)),
			};
		});
	};

	public fetchSelectedItemsFn = (ids: Array<string>): Promise<Array<TransactionInterfaceModel>> => {
		return this.transactionInterfaceService.getInterfaceByIds(ids);
	};

	public mapToSelectOptionFn = (e: TransactionInterfaceModel): AppSelectOption => {
		return {
			id: e.id,
			name: e.name,
			description: e.transactionType,
			active: e.active,
		};
	};
}
