<div class="entry" *ngFor="let e of helper; index as i; trackBy: trackByIndex">
	<klp-form-email-input
		[(ngModel)]="helper[i]"
		(ngModelChange)="valueChanged()"
		[placeholder]="'Enter email' | translate"
	></klp-form-email-input>
	<app-button class="removeButton" *ngIf="i >= 1" (click)="remove(i)">
		<i class="ti-trash"></i>
	</app-button>
</div>
<app-button variant="linkWithBrandColor" *ngIf="helper[helper.length - 1]" class="addAnother" (click)="addAnother()">
	+ <span translate>Add an extra email address</span>
</app-button>
