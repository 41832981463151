import { Injectable } from '@angular/core';
import { objectToQueryParamString } from '#/util/objects';
import { BookingSuggestion } from '#/models/company/bookingSuggestions.model';
import { APIService } from '~/app/api/services/api.service';
import { APINotificationsService } from '~/app/api/services/apinotifications.service';

@Injectable({
	providedIn: 'root',
})
export class BookingSuggestionsService {
	constructor(private apiService: APIService, private notifications: APINotificationsService) {}

	public getBookingSuggestions(
		txId: string,
		provider: string,
		division: string,
		merchant: string,
		vatType: string,
		vatPercentages?: string,
	): Promise<BookingSuggestion> {
		const params = objectToQueryParamString({
			provider: provider,
			division: division,
			merchant: merchant,
			vat_type: vatType,
			vat_percentages: vatPercentages,
		});

		return this.apiService
			.get(`/api/v1/receipt/${txId}/suggestions/booking?${params}`)
			.then((r) => {
				return new BookingSuggestion(r.data);
			})
			.catch((e) => {
				this.notifications.handleAPIError(e);
				throw e;
			});
	}
}
