import { Folder } from '#/models/user/folder';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { FolderActionsUnion, FolderActionTypes } from '~/app/modules/folder/models/folder.actions';
import { createSelector } from '@ngrx/store';

export interface FolderState extends EntityState<Folder> {
	currentPage: number | null;
	totalPages: number | null;
}

export const adapter: EntityAdapter<Folder> = createEntityAdapter<Folder>();

export const initialState: FolderState = adapter.getInitialState({
	currentPage: null,
	totalPages: null,
});

export function folderReducer(state = initialState, action: FolderActionsUnion): FolderState {
	switch (action.type) {
		case FolderActionTypes.ADD_GROUP: {
			return adapter.addOne(action.payload.group, state);
		}

		case FolderActionTypes.UPSERT_GROUP: {
			return adapter.upsertOne(action.payload.group, state);
		}

		case FolderActionTypes.ADD_GROUPS: {
			return adapter.addMany(action.payload.groups, state);
		}

		case FolderActionTypes.UPSERT_GROUPS: {
			return adapter.upsertMany(action.payload.groups, state);
		}

		case FolderActionTypes.UPDATE_GROUP: {
			return adapter.updateOne(action.payload.group, state);
		}

		case FolderActionTypes.UPDATE_GROUPS: {
			return adapter.updateMany(action.payload.groups, state);
		}

		case FolderActionTypes.DELETE_GROUP: {
			return adapter.removeOne(action.payload.id, state);
		}

		case FolderActionTypes.DELETE_GROUPS: {
			return adapter.removeMany(action.payload.ids, state);
		}

		case FolderActionTypes.LOAD_GROUPS: {
			return adapter.addAll(action.payload.groups, state);
		}

		case FolderActionTypes.CLEAR_GROUPS: {
			return adapter.removeAll({ ...state, selectedGroupId: null });
		}

		default: {
			return state;
		}
	}
}

const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors();

// select the array of group ids
export const selectFolderIds = selectIds;

// select the dictionary of group entities
export const selectFolderEntities = selectEntities;

// select the array of groups
export const selectAllFolders = selectAll;

// select the total group count
export const selectFolderTotal = selectTotal;

export const getItemById = (id) => createSelector(selectEntities, (allItems) => allItems[id] || null);
