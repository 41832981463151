import { Component, OnInit } from '@angular/core';
import { AuthenticatedComponent } from '../authenticated.component';
import { Observable } from 'rxjs';
import { select } from '@ngrx/store';
import { Folder } from '#/models/user/folder';
import * as fromFolder from '~/app/modules/folder/models/folder.reducer';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { needsGroupListUpdate, WSMessage } from '#/models/websocket.model';
import { AddFolders, ClearFolders } from '~/app/modules/folder/models/folder.actions';

@Component({
	templateUrl: 'folders.html',
})
export class FoldersComponent extends AuthenticatedComponent implements OnInit {
	groups: Observable<Folder[]>;
	groupCount = 0;
	checkboxes: {};
	checkAll: { checked: boolean };
	initialLoaded = false;

	constructor() {
		super();
		this.groups = this.store.select('group').pipe(select(fromFolder.selectAllFolders));
		this.checkboxes = {};
		this.initialLoaded = true;
		this.checkAll = { checked: false };
	}

	ngOnInit(): void {
		super.ngOnInit();
		const websocketSubscription = this.websocketsService.onMessage.subscribe((message: WSMessage) => {
			// Refresh grousps list, might be the group was in our current page.
			if (needsGroupListUpdate(message.type)) {
				this.loadGroups();
			}
		});

		this.destroyCallbacks.push(() => {
			websocketSubscription.unsubscribe();
		});
	}

	loadGroups() {
		this.folderService.getAllFolders().then((g) => {
			const Groups: any[] = g;
			const newGroups: Folder[] = [];
			if (Groups != null) {
				Groups.map((group) => newGroups.push(new Folder(group)));
			}
			this.store.dispatch(new ClearFolders());
			this.store.dispatch(new AddFolders({ groups: newGroups }));
		});
	}

	updateGroups() {
		this.loadGroups();
	}

	changedCheckAll(event) {
		this.groups.forEach((groups) => {
			groups.forEach((group) => {
				this.checkboxes[group.id] = this.checkAll;
			});
		});
	}

	getSelectedGroupsCount(): number {
		return Object.keys(this.checkboxes).filter((checkbox) => this.checkboxes[checkbox]).length;
	}

	async tableActionNew(event) {
		const { FoldersNewModalComponent } = await import(
			'~/app/modules/folder/components/modals/folders-new-modal/folders-new-modal.component'
		);

		const modalRef = this.modalService.open(FoldersNewModalComponent);
		this.setupGroupsModal(modalRef);
		return false;
	}

	setupGroupsModal(modalRef: NgbModalRef) {
		modalRef.componentInstance.checkboxes = this.checkboxes;
		modalRef.componentInstance.user = this.user;

		let doneSubscription = modalRef.componentInstance.done.subscribe(() => {
			this.updateGroups();
		});

		modalRef.result
			.then((reason) => {
				doneSubscription.unsubscribe();
				doneSubscription = null;
			})
			.catch((reason) => {});

		this.destroyCallbacks.push(() => {
			if (doneSubscription) {
				doneSubscription.unsubscribe();
			}
		});
	}
}
