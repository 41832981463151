import { Component, Host, Input, Optional } from '@angular/core';
import { FormElementComponent, ValueAccessorBase } from '@klippa/ngx-enhancy-forms';
import { ControlContainer, NG_VALUE_ACCESSOR } from '@angular/forms';
import * as fromGeneric from '~/app/modules/generic/generic.reducer';
import { select, Store } from '@ngrx/store';
import { AppState } from '~/app/reducers';
import { Subscription } from 'rxjs';
import { Currency } from '#/models/currency';
import { isValueSet, stringIsSetAndFilled, useIfStringIsSet } from '#/util/values';
import { AmountWithCurrency } from '#/models/transaction/amountWithCurrency';
import { roundToX } from '#/util/numbers';
import { stringifyAmount } from '##/util/numbers';

@Component({
	selector: 'app-amount-with-currency-input',
	templateUrl: './amount-with-currency-input.component.html',
	styleUrls: ['./amount-with-currency-input.component.scss'],
	providers: [{ provide: NG_VALUE_ACCESSOR, useExisting: AmountWithCurrencyInputComponent, multi: true }],
})
export class AmountWithCurrencyInputComponent extends ValueAccessorBase<AmountWithCurrency> {
	@Input() hideCurrencyPicker = false;
	@Input() showPer100 = true;
	@Input() amountOfDecimals: number = 2;

	private currenciesSubscription: Subscription;
	private allCurrencies: Currency[];

	public amount: string;
	public currency: string;
	public info: string;
	public amountPer100: string;

	constructor(
		@Optional() @Host() protected parent: FormElementComponent,
		@Optional() @Host() protected controlContainer: ControlContainer,
		private store: Store<AppState>,
	) {
		super(parent, controlContainer);

		this.currenciesSubscription = this.store
			.select('generic')
			.pipe(select(fromGeneric.selectAllCurrencies))
			.subscribe((currencies) => {
				this.allCurrencies = currencies;
			});
	}

	writeValue(value: AmountWithCurrency) {
		if (Number.isFinite(value?.amount) || stringIsSetAndFilled(value?.currency)) {
			this.amount = Number.isFinite(value?.amount) ? String(value.amount) : null;
			this.amountPer100 = Number.isFinite(value?.amount)
				? stringifyAmount(roundToX(value.amount / 100, this.amountOfDecimals), this.amountOfDecimals)
				: null;
			this.currency = useIfStringIsSet(value?.currency);
			this.info = useIfStringIsSet(value?.info);
			super.writeValue(value);
		} else {
			this.amount = null;
			this.amountPer100 = null;
			this.currency = null;
			this.info = null;
			super.writeValue(undefined);
		}
	}

	public getCurrencySymbol(a): string {
		return this.allCurrencies.find((e) => e.getCode() === a)?.getTitleOrSymbol();
	}

	public onChange(): void {
		const input = (this.showPer100 ? this.amountPer100 : this.amount)?.trim();
		if (!isValueSet(input)) {
			this.setInnerValueAndNotify({
				amount: null,
				currency: useIfStringIsSet(this.currency),
				info: useIfStringIsSet(this.info),
			});
			return;
		}
		const decimalSeparator = input
			?.split('')
			.reverse()
			.find((e) => e === ',' || e === '.');
		const isNegative = input?.split('-').length > 1;
		const [intPart, decimalPart] = input?.split(decimalSeparator) ?? [null, null];
		const parsedAsNumber = Number((intPart ?? '0').replace(/[^0-9]/, '') + '.' + (decimalPart ?? '0'));
		const amountOfDecimals = decimalPart?.length ?? 0;
		let amount = this.showPer100 ? roundToX(parsedAsNumber * 100, amountOfDecimals - 2) : parsedAsNumber;
		if (isNegative) {
			amount *= -1;
		}
		if (!stringIsSetAndFilled(input) && !stringIsSetAndFilled(this.currency)) {
			this.setInnerValueAndNotify(null);
			return;
		}
		this.setInnerValueAndNotify({
			amount: stringIsSetAndFilled(input) && Number.isFinite(amount) ? amount : null,
			currency: useIfStringIsSet(this.currency),
			info: useIfStringIsSet(this.info),
		});
	}

	public changeInterceptor = async (prev, cur): Promise<void> => {
		// we need these timeout when rejecting to circumvent the browser blocking us denying input (at least, i think that is what happens)
		return new Promise((resolve, reject) => {
			const amountOfCommasAndDots = cur.split('').filter((e) => e === ',' || e === '.').length;
			if (amountOfCommasAndDots > 1) {
				return setTimeout(reject);
			}
			const decimalSeparator = cur
				.split('')
				.reverse()
				.find((e) => e === ',' || e === '.');
			const splitOnDecimal = cur.split(decimalSeparator);
			if (splitOnDecimal.length === 1) {
				return resolve(null);
			}
			if (splitOnDecimal.length > 2) {
				return setTimeout(reject);
			}
			if (splitOnDecimal[1].length > this.amountOfDecimals) {
				return setTimeout(reject);
			}
			return resolve(null);
		});
	};
}
