<ngx-async-template [promise]="approverCountAndAuthFlowStatusPromise">
	<ng-template ngx-async-pending>
		{{ 'Loading...' | translate }}
	</ng-template>
	<ng-template ngx-async-success let-approverCountAndAuthFlowStatus="value">
		<ng-container *ngIf="approverCountAndAuthFlowStatus.authorizationFlowStatus?.in_flow">
			<ng-template
				[ngIf]="hasMultipleNextApproversInAnyOrder(approverCountAndAuthFlowStatus.authorizationFlowStatus)"
				[ngIfElse]="showApproversList"
			>
				<div
					*ngIf="
						approverCountAndAuthFlowStatus.authorizationFlowStatus.require_approver_count -
							approverCountAndAuthFlowStatus.authorizationFlowStatus.approvals.length ===
						1
					"
				>
					<span *ngFor="let next_approver of approverCountAndAuthFlowStatus.authorizationFlowStatus.next_approvers; last as isLast">{{
						next_approver.user_name + (isLast ? '' : (' or' | translate))
					}}</span>
				</div>

				<div
					*ngIf="
						approverCountAndAuthFlowStatus.authorizationFlowStatus.require_approver_count -
							approverCountAndAuthFlowStatus.authorizationFlowStatus.approvals.length >
						1
					"
				>
					<span *ngFor="let next_approver of approverCountAndAuthFlowStatus.authorizationFlowStatus.next_approvers; last as isLast"
						>{{ next_approver.user_name + (isLast ? '' : ',') }}
					</span>
				</div>
			</ng-template>

			<ng-template #showApproversList>
				<div
					*ngIf="
						approverCountAndAuthFlowStatus.require_approver_count !==
						approverCountAndAuthFlowStatus.authorizationFlowStatus.next_approvers?.length
					"
				>
					<div *ngFor="let next_approver of approverCountAndAuthFlowStatus.authorizationFlowStatus.next_approvers">
						<span>{{ next_approver.user_name }}</span>
					</div>
				</div>
			</ng-template>
		</ng-container>
	</ng-template>
</ngx-async-template>
