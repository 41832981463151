import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Company } from '#/models/company/company.model';
import { CompanyService } from '#/services/company/company.service';
import { CostCenter } from '#/models/company/dimension/cost-center.model';
import { CompanyCostCenterService } from '#/services/company/dimension/company-cost-center.service';
import { UserService } from '~/app/modules/user/user.service';

@Component({
	selector: 'app-company-cost-center-label',
	templateUrl: './company-cost-center-label.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CompanyCostCenterLabelComponent implements OnInit, OnDestroy {
	private static objectCache = new Map<string, CostCenter>();
	public value: string;

	@Input()
	public costcenter = '';
	@Input()
	public company: Company;

	public costCenterObject?: CostCenter;
	destroyed = false;

	constructor(
		private ref: ChangeDetectorRef,
		private userService: UserService,
		protected companyAPIService: CompanyService,
		private companyCostCenter: CompanyCostCenterService,
	) {}

	ngOnInit() {
		this.loadCostCenter();
	}

	ngOnDestroy() {
		this.destroyed = true;
	}

	updateView() {
		if (!this.destroyed) {
			this.ref.markForCheck();
		}
	}

	loadCostCenter() {
		this.costCenterObject = null;
		if (this.costcenter && this.costcenter !== '') {
			if (CompanyCostCenterLabelComponent.objectCache.has(this.costcenter)) {
				this.costCenterObject = CompanyCostCenterLabelComponent.objectCache.get(this.costcenter);
				this.updateView();
			} else {
				this.companyCostCenter
					.getCompanyCostCenter(this.company?.id ?? this.userService.getCurrentLoggedUser().company, this.costcenter)
					.then((costcenter) => {
						CompanyCostCenterLabelComponent.objectCache.set(this.costcenter, costcenter);
						this.costCenterObject = costcenter;

						this.updateView();
					});
			}
		} else {
			this.updateView();
		}
	}
}
