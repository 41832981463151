import { Component, EventEmitter, Injector, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ReceiptService } from '#/services/transaction/receipt.service';
import { FolderService } from '../../../../folder/folder.service';
import { User } from '#/models/user/user.model';
import { NotificationService } from '~/app/services/notification.service';
import { TranslateService } from '@ngx-translate/core';
import { UntypedFormBuilder } from '@angular/forms';
import { Store } from '@ngrx/store';
import { AppState } from '~/app/reducers';
import { StatisticsService } from '~/app/pages/authenticated/statistics/statistics.service';
import { DomSanitizer } from '@angular/platform-browser';
import { ModalMessages } from '~/app/helpers/strings';
import { Company } from '#/models/company/company.model';
import { ModalService } from '~/app/services/modal.service';
import { TranslationReplaceService } from '~/app/services/translation-replace.service';
import { CompanyCategoryService } from '#/services/company/company-category.service';
import { CompanyProjectService } from '#/services/company/dimension/company-project.service';
import { CompanyAdministrationService } from '#/services/company/company-administration.service';
import { CompanyCostUnitService } from '#/services/company/dimension/company-cost-unit.service';
import { CompanyCostCenterService } from '#/services/company/dimension/company-cost-center.service';
import { FileService } from '#/services/file.service';
import { CompanyService } from '#/services/company/company.service';

@Component({
	selector: 'app-modal-base',
	template: '',
})
export class ModalBaseComponent implements OnInit, OnDestroy {
	static injector: Injector;

	@Input() checkboxes: {};
	@Input() administration: string;
	@Input() user: User;
	@Input() company: Company;
	@Output() done = new EventEmitter<any>();

	protected notificationService: NotificationService;
	protected receiptAPIService: ReceiptService;
	protected fileService: FileService;
	protected companyService: CompanyService;
	protected folderService: FolderService;
	protected formBuilder: UntypedFormBuilder;
	protected store: Store<AppState>;
	protected translate: TranslateService;
	protected statsService: StatisticsService;
	protected domSanitizer: DomSanitizer;
	protected modalService: ModalService;
	protected companyProjectService: CompanyProjectService;
	protected companyAdministrationService: CompanyAdministrationService;
	protected companyCostUnitService: CompanyCostUnitService;
	protected companyCostCenterService: CompanyCostCenterService;
	protected companyCategoryService: CompanyCategoryService;
	public translationReplaceService: TranslationReplaceService;

	public inProgress = false;
	protected currentItem = 0;
	public failedItems = 0;
	protected succeededItems = 0;
	protected alreadySubmitted = 0;
	protected notSubmitted = 0;

	protected destroyCallbacks = [];

	constructor(public activeModal: NgbActiveModal) {
		this.notificationService = ModalBaseComponent.injector.get(NotificationService);
		this.receiptAPIService = ModalBaseComponent.injector.get(ReceiptService);
		this.companyService = ModalBaseComponent.injector.get(CompanyService);
		this.folderService = ModalBaseComponent.injector.get(FolderService);
		this.formBuilder = ModalBaseComponent.injector.get(UntypedFormBuilder);
		this.fileService = ModalBaseComponent.injector.get(FileService);
		this.store = ModalBaseComponent.injector.get(Store);
		this.translate = ModalBaseComponent.injector.get(TranslateService);
		this.statsService = ModalBaseComponent.injector.get(StatisticsService);
		this.domSanitizer = ModalBaseComponent.injector.get(DomSanitizer);
		this.modalService = ModalBaseComponent.injector.get(ModalService);
		this.translationReplaceService = ModalBaseComponent.injector.get(TranslationReplaceService);
		this.companyProjectService = ModalBaseComponent.injector.get(CompanyProjectService);
		this.companyAdministrationService = ModalBaseComponent.injector.get(CompanyAdministrationService);
		this.companyCostUnitService = ModalBaseComponent.injector.get(CompanyCostUnitService);
		this.companyCostCenterService = ModalBaseComponent.injector.get(CompanyCostCenterService);
		this.companyCategoryService = ModalBaseComponent.injector.get(CompanyCategoryService);
	}

	ngOnInit(): void {
		const companySubscription = this.store.select('company').subscribe((val) => {
			if (!this.company) {
				this.company = val.company;
			}
		});
		this.destroyCallbacks.push(() => {
			companySubscription.unsubscribe();
		});
	}

	ngOnDestroy(): void {
		this.destroyCallbacks.forEach((cb) => cb());
	}

	getSelectedIDs(): string[] {
		return Object.keys(this.checkboxes).filter((checkbox) => this.checkboxes[checkbox]);
	}

	getSelectedCount(): number {
		return this.getSelectedIDs().length;
	}

	getTranslation(str: string) {
		return this.translate.instant(str);
	}

	getUserNamedType(str: string) {
		return ModalMessages.getUserNamedType(str);
	}

	getCurrentCountSentence(type: string, action: string, success: string, count: number, totalCount?: number): string {
		return ModalMessages.getCurrentCountSentence(type, action, success, count, totalCount);
	}

	getCurrentCountSentenceTranslated(type: string, action: string, success: string, count: number, totalCount: number, am?: boolean) {
		const results = ModalMessages.getCurrentCountSentenceTranslated(type, action, success, count, totalCount, am);
		return this.translate.instant(results.message, results.params);
	}

	upperFirst(str: string) {
		return str.charAt(0).toUpperCase() + str.substr(1);
	}

	isAdministrationOffice(): boolean {
		return this.company && this.company.isadministrationoffice;
	}

	transformDate($event) {
		return $event === false ? null : $event;
	}
}
