import { Component, Input, OnInit } from '@angular/core';
import { stringIsSetAndFilled } from '#/util/values';
import { RegistrationService } from '#/services/transaction/registration.service';
import { TransportationType } from '#/models/transaction/transportationType';

@Component({
	selector: 'app-transportation-type-label',
	templateUrl: './transportation-type-label.component.html',
	styleUrls: ['./transportation-type-label.component.scss'],
})
export class TransportationTypeLabelComponent implements OnInit {
	@Input() public transportationTypeId: string;
	public name: string;

	constructor(private registrationService: RegistrationService) {}

	async ngOnInit(): Promise<void> {
		if (stringIsSetAndFilled(this.transportationTypeId)) {
			this.name = await this.registrationService
				.getTransportationTypesById([this.transportationTypeId])
				.then((res: Array<TransportationType>) => [res[0]?.name, res[0].description].join(' '));
		}
	}
}
