<div class="dataTables_paginate paging_full_numbers custom-pagination" id="DataTables_Table_1_paginate">
	<a
		class="paginate_button first"
		aria-controls="DataTables_Table_1"
		data-dt-idx="0"
		tabindex="0"
		[class.disabled]="isFirstPage()"
		(keyup.enter)="first()"
		(click)="first()"
		translate
		>First</a
	>
	<a
		class="paginate_button previous"
		aria-controls="DataTables_Table_1"
		data-dt-idx="1"
		tabindex="0"
		[class.disabled]="isFirstPage()"
		(keyup.enter)="previous()"
		(click)="previous()"
		translate
		>Previous</a
	>
	<span>
		<a
			[class.current]="getCurrent() === page.value"
			class="paginate_button"
			aria-controls="DataTables_Table_1"
			[attr.data-dt-idx]="page.value + 1"
			tabindex="0"
			(keyup.enter)="setCurrent(page.value)"
			(click)="setCurrent(page.value)"
			*ngFor="let page of pages"
			>{{ page.label }}</a
		>
	</span>
	<a
		class="paginate_button next"
		aria-controls="DataTables_Table_1"
		data-dt-idx="4"
		tabindex="0"
		[class.disabled]="isLastPage()"
		(keyup.enter)="next()"
		(click)="next()"
		translate
		>Next</a
	>
	<a
		class="paginate_button last"
		aria-controls="DataTables_Table_1"
		data-dt-idx="5"
		tabindex="0"
		[class.disabled]="isLastPage()"
		(keyup.enter)="last()"
		(click)="last()"
		translate=""
		>Last</a
	>
</div>
