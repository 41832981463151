import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { UiModule } from '../../shared/ui/ui.module';
import { NgSelectModule } from '@ng-select/ng-select';
import { TranslateModule } from '@ngx-translate/core';
import { CompanyOauthAuthorizedComponent } from './components/company-oauth-authorized/company-oauth-authorized.component';
import { CreditcardStatementsOverviewComponent } from './components/creditcard-statements/creditcard-statements-overview/creditcard-statements-overview.component';
import { CreditcardStatementUploadComponent } from '~/app/modules/company/components/creditcard-statements/creditcard-statement-upload/creditcard-statement-upload.component';
import { CreditcardStatementsTableComponent } from '~/app/modules/company/components/creditcard-statements/creditcard-statements-table/creditcard-statements-table.component';
import { CreditcardStatementDetailComponent } from '~/app/modules/company/components/creditcard-statements/creditcard-statement-detail/creditcard-statement-detail.component';
import { CreditcardStatementLinesTableComponent } from '~/app/modules/company/components/creditcard-statements/creditcard-statement-lines-table/creditcard-statement-lines-table.component';
import { CreditcardStatementsFilterModalComponent } from '~/app/modules/company/components/creditcard-statements/creditcard-statements-filter-modal/creditcard-statements-filter-modal.component';
import { CreditcardStatementLineMatchComponent } from '~/app/modules/company/components/creditcard-statements/creditcard-statement-line-match/creditcard-statement-line-match.component';
import { CreditcardStatementEditModalComponent } from '~/app/modules/company/components/creditcard-statements/creditcard-statement-edit-modal/creditcard-statement-edit-modal.component';
import { TransactionsOverviewComponent } from '~/app/modules/company/components/transactions/transactions-overview/transactions-overview.component';
import { TransactionsTableComponent } from '~/app/modules/company/components/transactions/transactions-table/transactions-table.component';
import { TransactionEditComponent } from '~/app/modules/company/components/transactions/transaction-edit/transaction-edit.component';
import { CompanyReceiptComponent } from '~/app/modules/company/components/company-receipt/company-receipt.component';
import { CompanyUI } from './components/ui/ui.module';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { PipesModule } from '~/app/helpers/pipes/pipes.module';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { SortablejsModule } from 'ngx-sortablejs';
import { ExportModule } from '~/app/modules/export/export.module';
import { ModalModule } from '~/app/shared/ui/modal/modal.module';
import { ManualCardUploadModalComponent } from './components/creditcard-statements/manual-card-upload/manual-card-upload-modal/manual-card-upload-modal.component';
import { ManualCardUploadFormComponent } from './components/creditcard-statements/manual-card-upload/manual-card-upload-form/manual-card-upload-form.component';

@NgModule({
	imports: [
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		UiModule,
		NgSelectModule,
		TranslateModule,
		CompanyUI,
		RouterModule,
		PipesModule,
		NgbTooltipModule,
		SortablejsModule,
		ExportModule,
		ModalModule,
	],
	declarations: [
		CompanyOauthAuthorizedComponent,
		CompanyReceiptComponent,
		CreditcardStatementsOverviewComponent,
		CreditcardStatementUploadComponent,
		CreditcardStatementsTableComponent,
		CreditcardStatementDetailComponent,
		CreditcardStatementLinesTableComponent,
		CreditcardStatementsFilterModalComponent,
		CreditcardStatementLineMatchComponent,
		CreditcardStatementEditModalComponent,
		TransactionsOverviewComponent,
		TransactionsTableComponent,
		TransactionEditComponent,
		ManualCardUploadModalComponent,
		ManualCardUploadFormComponent,
	],
	exports: [ManualCardUploadModalComponent],
	providers: [],
})
export class CompanyModule {}
