import { BookingLineApiModel } from '#/models/transaction/booking-line/apiModel';
import { BookingLineFrontendModel } from '#/models/transaction/booking-line/frontendModel';
import { stringIsSetAndFilled } from '#/util/values';
import { filterEmptyStringValues } from '#/util/objects';
import { convertDateToYMD } from '#/util/date';

export function apiToFrontend(apiModel: BookingLineApiModel, currencyCode: string): BookingLineFrontendModel {
	const result = new BookingLineFrontendModel();
	result.description = apiModel.description;
	result.ledger = apiModel.ledger;
	result.amountWithCurrency = {
		amount: apiModel.amount,
		currency: currencyCode,
	};
	result.vatAmountWithCurrency = {
		amount: apiModel.vatamount,
		currency: currencyCode,
	};
	result.vatCode = apiModel.vatcode;
	result.vatPercentage = apiModel.vatpercentage;
	result.purchaseOrder = apiModel.purchaseorder;
	result.purchaseOrderLine = apiModel.purchaseorderline;
	result.dimensions = filterEmptyStringValues(apiModel.dimensions);
	result.deferredFrom = apiModel.deferred_from instanceof Date ? convertDateToYMD(apiModel.deferred_from) : undefined;
	result.deferredTo = apiModel.deferred_to instanceof Date ? convertDateToYMD(apiModel.deferred_to) : undefined;
	result.gAccountAmountAndPercentage = {
		amountWithCurrency: {
			amount: apiModel.gAccountAmount,
			currency: currencyCode,
		},
		percentage: apiModel.gAccountPercentage,
	};
	result.bookingDate = apiModel.bookingDate instanceof Date ? convertDateToYMD(apiModel.bookingDate) : undefined;
	return result;
}

export function frontendToApi(frontendModel: BookingLineFrontendModel): BookingLineApiModel {
	const result = new BookingLineApiModel();
	result.description = frontendModel.description;
	result.ledger = frontendModel.ledger;
	result.amount = Math.round(frontendModel.amountWithCurrency?.amount);
	result.vatamount = Math.round(frontendModel.vatAmountWithCurrency?.amount);
	result.vatcode = frontendModel.vatCode;
	result.vatpercentage = Number(frontendModel.vatPercentage);
	result.purchaseorder = frontendModel.purchaseOrder;
	result.purchaseorderline = frontendModel.purchaseOrderLine;
	result.dimensions = frontendModel.dimensions;
	result.deferred_from = stringIsSetAndFilled(frontendModel.deferredFrom) ? new Date(frontendModel.deferredFrom) : undefined;
	result.deferred_to = stringIsSetAndFilled(frontendModel.deferredTo) ? new Date(frontendModel.deferredTo) : undefined;
	const gAccountAmount = frontendModel.gAccountAmountAndPercentage?.amountWithCurrency?.amount;
	result.gAccountAmount = Number.isFinite(gAccountAmount) ? Math.round(gAccountAmount) : undefined;
	result.gAccountPercentage = frontendModel.gAccountAmountAndPercentage?.percentage;
	result.bookingDate = stringIsSetAndFilled(frontendModel.bookingDate) ? new Date(frontendModel.bookingDate) : undefined;
	return result;
}
