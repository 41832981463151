import { Component, ElementRef, EventEmitter, HostListener, Input, Output } from '@angular/core';
import { ActionsMenuInterface } from './actions-menu';

@Component({
	selector: 'app-action-menu',
	templateUrl: './actions-menu.component.html',
	styleUrls: ['./actions-menu.component.scss'],
})
export class ActionsMenuComponent {
	@Input() public actions: ActionsMenuInterface[];
	@Input() public placement: 'table' | 'row' = 'table';

	@Output() public onDismiss: EventEmitter<void> = new EventEmitter<void>();
	@Output() public onAction = new EventEmitter<ActionsMenuInterface>();
	private allowClosing: boolean = false;

	constructor(private elementRef: ElementRef) {}

	ngOnInit() {
		setTimeout(() => {
			// so we dont fire the closing action directly after opening the menu
			this.allowClosing = true;
		});
	}

	@HostListener('document:click', ['$event'])
	documentClick(event) {
		if (this.allowClosing && !this.elementRef.nativeElement.contains(event.target)) {
			this.closeModal();
		}
	}
	trackByIndex = (index: number): number => {
		return index;
	};

	emitAction(action: ActionsMenuInterface) {
		this.onAction.emit(action);
	}

	closeModal(): void {
		this.onDismiss.emit();
	}
}
