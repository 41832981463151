import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NotificationService } from './notification.service';
import { NotificationConfig } from '~/app/shared/ui/toast-notification/toast-notifications-manager/toast-notifications-manager.component';
import { Router } from '@angular/router';
import { UserRole } from '#/models/user/user.model';
import { UserService } from '../modules/user/user.service';
import { LocalStorageService } from 'angular-2-local-storage';
import { arrayIsSetAndFilled } from '#/util/arrays';

enum AnnouncementKey {
	INSIGHTS_DASHBOARD = 'insightsDashboard',
}

enum AnnouncementType {
	INFO = 'info',
}

interface AnnouncementFromAPI {
	key: AnnouncementKey;
	dismissed: boolean;
}

interface Announcement {
	key: AnnouncementKey;
	dismissed: boolean;
	userRoles: Array<UserRole>;
	announcementType: AnnouncementType;
	announcementText: string;
	notificationConfig: NotificationConfig;
}

@Injectable({
	providedIn: 'root',
})
export class AnnouncementsService {
	constructor(
		private localStorageService: LocalStorageService,
		private userService: UserService,
		private router: Router,
		private translateService: TranslateService,
		private notificationService: NotificationService,
	) {}

	private getAnnouncementsInClient(): Array<Announcement> {
		return [];
	}

	private getAnnouncements(): Array<Announcement> {
		const localStorageAnnouncements = this.localStorageService.get<Array<AnnouncementFromAPI>>('announcements') || [];

		if (arrayIsSetAndFilled(localStorageAnnouncements)) {
			return this.getAnnouncementsInClient().map((announcement, i) => {
				return {
					...announcement,
					dismissed: localStorageAnnouncements[i]?.dismissed,
				};
			});
		} else {
			this.updateAnnouncements(this.getAnnouncementsInClient());
			return this.getAnnouncementsInClient();
		}
	}

	private updateAnnouncements(announcements: Array<Announcement>): void {
		const mappedAnnouncements = announcements.map((announcement) => {
			return {
				key: announcement.key,
				dismissed: announcement.dismissed,
			};
		});

		this.localStorageService.set('announcements', mappedAnnouncements);
	}

	private dismissAnnouncement(key: AnnouncementKey): void {
		const announcements = this.getAnnouncements();
		const updatedAnnouncements = this.getAnnouncementsInClient().map((announcement, i) =>
			announcement.key === key ? { ...announcement, dismissed: true } : announcements[i],
		);

		this.updateAnnouncements(updatedAnnouncements);
	}

	public showAnnouncements(): void {
		const updatedAnnouncements = this.getAnnouncements();

		updatedAnnouncements
			.filter((announcement) => !announcement.dismissed)
			.forEach((announcement) => {
				const userHasRole = announcement.userRoles.some((userRole) => this.userService.getCurrentLoggedUser().hasRole(userRole));

				if (userHasRole) {
					this.notificationService.info(announcement.announcementText, announcement.notificationConfig);
				}
			});
	}
}
