<div class="modal-header">
	<h4 class="modal-title" translate>Edit tag</h4>
	<button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
		<span aria-hidden="true">&times;</span>
	</button>
</div>
<div class="modal-body">
	<div class="text-center py-3" *ngIf="progress">
		<app-loader-deprecated></app-loader-deprecated>
	</div>
	<div *ngIf="!progress">
		<form [formGroup]="editForm">
			<div class="form-group row">
				<label class="col-md-12 control-label text-bold text-dark mrg-top-10" translate>Change tag to</label>
				<div class="col-md-12">
					<input
						type="text"
						class="form-control"
						id="tag"
						name="tag"
						formControlName="tag"
						[ngClass]="{ 'is-invalid': submitted && f.tag.errors }"
					/>
					<div *ngIf="f.tag.errors" class="invalid-feedback">
						<div *ngIf="f.tag.errors.required" translate>New tag name is required</div>
					</div>
				</div>
			</div>
		</form>
	</div>
</div>
<div class="modal-footer" *ngIf="!progress">
	<button class="btn btn-default btn-sm" (click)="activeModal.close('Close click')" translate [disabled]="inProgress">Cancel</button>
	<button class="btn btn-primary btn-sm" translate (click)="changeName()" [disabled]="inProgress">Save</button>
</div>
