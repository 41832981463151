import { Injectable } from '@angular/core';
import { numberIsSet } from '#/util/values';
import { arrayIsSetAndFilled } from '#/util/arrays';

@Injectable({
	providedIn: 'root',
})
export class PageStateService {
	private currentPage: number = 1;
	private scrollPosition: number = 0;
	private selectedIds: Array<string> = [];

	constructor() {
		document.addEventListener('scroll', () => {
			const scrollY = window.scrollY;
			if (scrollY > 0) {
				this.scrollPosition = scrollY;
			}
		});
	}

	public initPageState(): void {
		this.currentPage = 1;
		this.scrollPosition = 0;
		this.selectedIds = [];
	}

	public isPageStateSaved(): boolean {
		const page = numberIsSet(this.currentPage) && this.currentPage > 1;
		const position = numberIsSet(this.scrollPosition) && this.scrollPosition > 0;
		const selectedItems = arrayIsSetAndFilled(this.selectedIds);
		return page || position || selectedItems;
	}

	public getScrollPosition(): number {
		return this.scrollPosition;
	}

	public getPageNumber(): number {
		return this.currentPage;
	}

	public getSelectedIds(): Array<string> {
		return this.selectedIds;
	}

	public setSelectedIds(selectedIds: Array<string>): void {
		this.selectedIds = selectedIds;
	}

	public setCurrentPage(currentPage: number): void {
		this.currentPage = currentPage;
	}
}
