import { apiToFrontend } from './transformer';
import { PreTransactionFrontendModel } from './frontendModel';

export class PreTransactionApiModel {
	id: string;
	user: string;
	company: string;
	transactionInterfaceId: string;
	description: string;
	purchaseDate: string; // DATEYMD
	money: {
		amount: number;
		currency: string;
	};
	moneyExchangedFrom: {
		amount: number;
		currency: string;
	};
	exchangeRate?: number;
	vatItems: Array<{
		currency: string;
		amount: number;
		vatPercentage: number;
	}>;
	administration?: string;
	project?: string;
	costCenter?: string;
	costUnit?: string;
	merchant?: string;
	category?: string;
	// this is the front end's custom payment method
	paymentMethod?: string;
	ocrEnhancedFiles?: Array<string>;
	createDate: string; // YYYY-MM-DD readOnly property
	updateDate: string; // YYYY-MM-DD readOnly property

	constructor(data?: any) {
		Object.assign(this, data);
	}

	public transformToFrontend(loggedUserId: string): PreTransactionFrontendModel {
		return apiToFrontend(this, loggedUserId, PreTransactionFrontendModel);
	}
}
