<div class="modal-header">
	<h4 *ngIf="receiptIds.length === 1" class="modal-title" translate>Duplicate receipt</h4>
	<h4 *ngIf="receiptIds.length > 1" class="modal-title" translate>Duplicate receipts</h4>
</div>

<div class="modal-body">
	<div translate>
		Duplicating a receipt creates a second receipt just like the one selected. You have the option to duplicate the receipt with or without
		the attached documents and images.
	</div>

	<app-toggleable-setting
		[(ngModel)]="duplicateAttachments"
		caption="{{ 'Duplicate including the attached documents and images' | translate }}"
	></app-toggleable-setting>

	<div *ngIf="receiptIds.length === 1" translate>Are you sure that you want to duplicate 1 receipt?</div>
	<div *ngIf="receiptIds.length > 1" [translateParams]="{ receiptCount: receiptIds.length }" translate>
		Are you sure that you want to duplicate %receiptCount% receipts?
	</div>
</div>

<div class="modal-footer">
	<app-button aria-haspopup="true" aria-expanded="false" (click)="closeModal()">
		<span translate>Cancel</span>
	</app-button>
	<app-button variant="greenFilled" aria-haspopup="true" aria-expanded="false" (click)="duplicateReceipts()">
		<span translate>Duplicate</span>
	</app-button>
</div>
