import { Injectable } from '@angular/core';
import { Administration, CompanyAdministrationData, CompanyAdministrationListAPIRequest } from '#/models/company/administration.model';
import { ImportResultCount } from '#/models/import-result.model';
import { CompanyDimensionsService } from '#/services/company/dimension/company-dimensions.service';
import { isValueSet } from '#/util/values';

@Injectable({
	providedIn: 'root',
})
export class CompanyAdministrationService extends CompanyDimensionsService {
	getCompanyAdministrationFilteredOnPresets(ListAPIRequest: CompanyAdministrationListAPIRequest, presetOptions: string[]) {
		return this.getCompanyAdministrations(ListAPIRequest).then((data) => {
			const tempData = data;
			if (isValueSet(presetOptions)) {
				tempData.administrations = tempData.administrations.filter((item) => presetOptions.includes(item.id));
			}
			return tempData;
		});
	}

	getCompanyAdministrations(request: CompanyAdministrationListAPIRequest, clearCache = true): Promise<CompanyAdministrationData> {
		request.max = 9999;
		const requestUrl = request.getRequestURL();
		if (clearCache) {
			CompanyDimensionsService.requestCache.delete(requestUrl);
		}

		if (CompanyDimensionsService.requestCache.has(requestUrl)) {
			return CompanyDimensionsService.requestCache.get(requestUrl);
		}
		const apiPromise = this.apiService
			.get(requestUrl)
			.then((r) => {
				return new CompanyAdministrationData(r.data);
			})
			.catch((e) => {
				// on failures, remove it from the cache
				CompanyDimensionsService.requestCache.delete(requestUrl);
				this.notifications.handleAPIError(e);
				throw e;
			});

		CompanyDimensionsService.requestCache.set(requestUrl, apiPromise);
		return apiPromise;
	}

	setDefaultAdministration(companyId: string, administration: Administration): Promise<Administration> {
		const url = '/api/v1/company/' + companyId + '/administration/' + administration.id + '/default';
		return this.apiService
			.post(url, {})
			.then((r) => new Administration(r.data))
			.catch((e) => {
				this.notifications.handleAPIError(e);
				throw e;
			});
	}

	createCompanyAdministration(companyId: string, administration: Administration): Promise<Administration> {
		const url = '/api/v1/company/' + companyId + '/administration';
		return this.apiService
			.post(url, administration)
			.then((r) => {
				return Promise.resolve(new Administration(r.data));
			})
			.catch((e) => {
				this.notifications.handleAPIError(e);
				return Promise.reject(e);
			});
	}

	updateCompanyAdministration(companyId: string, administration: Administration): Promise<Administration> {
		const url = '/api/v1/company/' + companyId + '/administration/' + administration.id;
		return this.apiService
			.patch(url, administration)
			.then((r) => {
				return Promise.resolve(new Administration(r.data));
			})
			.catch((e) => {
				this.notifications.handleAPIError(e);
				return Promise.reject(e);
			});
	}

	public getCompanyAdministration(
		companyId: string,
		id: string,
		clearCache = false,
		showNotificationOnError = true,
	): Promise<Administration> {
		const url = '/api/v1/company/' + companyId + '/administration/' + id;
		if (CompanyDimensionsService.requestCache.has(url) && !clearCache) {
			return CompanyDimensionsService.requestCache.get(url);
		}
		const promise = this.apiService
			.get(url)
			.then((r) => {
				return new Administration(r.data);
			})
			.catch((e) => {
				if (showNotificationOnError) {
					this.notifications.handleAPIError(e);
				}
				CompanyDimensionsService.requestCache.delete(url);
				return Promise.reject(e);
			});
		CompanyDimensionsService.requestCache.set(url, promise);
		return promise;
	}

	deleteCompanyAdministration(companyId: string, id: string): Promise<any> {
		const url = '/api/v1/company/' + companyId + '/administration/' + id;
		return this.apiService
			.delete(url)
			.then((r) => {
				return Promise.resolve(r);
			})
			.catch((e) => {
				this.notifications.handleAPIError(e);
				return Promise.reject(e);
			});
	}

	getAdministrationsByIDs(
		companyAdministrationListAPIRequest: CompanyAdministrationListAPIRequest,
		ids: string[],
	): Promise<CompanyAdministrationData> {
		companyAdministrationListAPIRequest.list = true;
		companyAdministrationListAPIRequest.max = 9999;
		return this.apiService
			.post(companyAdministrationListAPIRequest.getRequestURL(), {
				ids,
			})
			.then((r) => {
				return Promise.resolve(new CompanyAdministrationData(r.data));
			})
			.catch((e) => {
				this.notifications.handleAPIError(e);
				return Promise.reject(e);
			});
	}

	importAdministrations(companyId: string, file: File, skipFirstRow = true): Promise<ImportResultCount> {
		const formData = new FormData();
		formData.append('file', file);

		if (skipFirstRow) {
			formData.append('skip_first_row', '1');
		}

		const url = '/api/v1/company/' + companyId + '/administration/import';
		return this.apiService
			.post(url, formData)
			.then((r) => {
				return Promise.resolve(new ImportResultCount(r.data));
			})
			.catch((e) => {
				this.notifications.handleAPIError(e);
				return Promise.reject(e);
			});
	}

	bulkAdministration(companyId: string, type: string, administrations: Administration[]): Promise<ImportResultCount> {
		const url = '/api/v1/company/' + companyId + '/administration/bulk';
		return this.apiService
			.post(url, {
				type,
				administrations,
			})
			.then((r) => {
				return Promise.resolve(new ImportResultCount(r.data));
			})
			.catch((e) => {
				this.notifications.handleAPIError(e);
				return Promise.reject(e);
			});
	}
}
