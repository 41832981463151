export class BookingLineApiModel {
	ledger: string;
	vatcode: string;
	amount: number;
	vatamount: number;
	vatpercentage: number;
	purchaseorder: string;
	purchaseorderline: string;
	companycostcenter: string;
	costunit: string;
	project: string;
	dimensions: { [key: string]: string };
	deferred_from: Date;
	deferred_to: Date;
	description: string;
	bookingDate: Date;
	gAccountAmount: number;
	gAccountPercentage: number;

	constructor(data?: any) {
		Object.assign(this, data);
	}
}
