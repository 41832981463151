export class PaymentConditionData {
	payment_conditions: Array<PaymentCondition> = [];
	count: number;
	moreresults: boolean;

	constructor(data = null) {
		if (data) {
			Object.assign(this, data);

			if (data.payment_conditions) {
				this.payment_conditions = PaymentCondition.fromData(data.payment_conditions);
			}
		}
	}
}

export class PaymentCondition {
	id: string;
	Code: string;
	Description: string;
	Identifier: string;
	Name: string;

	constructor(data) {
		if (data) {
			Object.assign(this, data);
		}
		this.id = this.Identifier;
	}
	static fromData(data): Array<PaymentCondition> {
		if (data == null) {
			return [];
		}
		return data.map((item) => new PaymentCondition(item));
	}
}
