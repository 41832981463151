import { Inject, Injectable } from '@angular/core';
import { APIService } from '../../api/services/api.service';
import { DOCUMENT } from '@angular/common';
import { APINotificationsService } from '../../api/services/apinotifications.service';
import { AddFolders, ClearFolders } from '~/app/modules/folder/models/folder.actions';
import { Store } from '@ngrx/store';
import { FolderService as BaseFolderService } from '#/services/folder.service';

@Injectable({
	providedIn: 'root',
})
export class FolderService extends BaseFolderService {
	constructor(
		protected apiService: APIService,
		protected notifications: APINotificationsService,
		@Inject(DOCUMENT) private document: any,
		private store: Store,
	) {
		super(apiService, notifications);
	}

	public async initializeFolders(): Promise<void> {
		return this.getAllFolders().then((groups) => {
			this.store.dispatch(new ClearFolders());
			this.store.dispatch(new AddFolders({groups: groups}));
		});
	}
}
