import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InboxTableRowComponent } from './components/inbox-table-row/inbox-table-row.component';
import { InboxItemTypePipe } from './inbox.pipe';
import { TranslateModule } from '@ngx-translate/core';
import { InboxTableComponent } from './components/inbox-table/inbox-table.component';
import { FormsModule } from '@angular/forms';
import { UiModule } from '~/app/shared/ui/ui.module';
import { PipesModule } from '~/app/helpers/pipes/pipes.module';

@NgModule({
	imports: [CommonModule, TranslateModule, FormsModule, UiModule, PipesModule],
	declarations: [InboxTableRowComponent, InboxItemTypePipe, InboxTableComponent],
	exports: [InboxItemTypePipe, InboxTableComponent, InboxTableRowComponent],
})
export class InboxModule {}
