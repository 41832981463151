import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ExpenseReportsService } from '#/services/transaction/expense-reports.service';
import { ExpenseReport, PaginatedExpenseReportsRequest } from '#/models/transaction/expense-reports';
import { Receipt, ReceiptListAPIRequest } from '#/models/transaction/receipt';
import { AuthenticatedComponent } from '~/app/pages/authenticated/authenticated.component';
import { ActivatedRoute } from '@angular/router';
import { debounceTime, Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { ColumnsInterface, DataTablesParameters } from '~/app/shared/ui/table/table';
import { ActionsMenuActionEnum, ActionsMenuInterface } from '~/app/shared/ui/actions-menu/actions-menu';
import { ConfirmModalComponent } from '~/app/shared/ui/confirm-modal/confirm-modal.component';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { ActionService, ColumnService, FilterService } from './report-declarations-single.service';
import { SelectActionEnum, SelectActionInterface } from '~/app/shared/ui/select-action/select-action';
import { CompanyModuleReceiptPresetsPreset } from '#/models/company/company.model';
import { isValueSet, stringIsSetAndFilled } from '#/util/values';
import { FilterEnum } from '~/app/shared/ui/filters/filter';
import { needsReceiptListUpdate } from '#/models/websocket.model';
import { CompanyCategoryListAPIRequest } from '#/models/company/category.model';
import { CompanyCategoryService } from '#/services/company/company-category.service';
import { ColumnSortingLocalStorageInterface, DashboardColumnSortingService } from '~/app/services/dashboard-column-sorting.service';
import { Order } from '#/models/utils/order';
import { CompanyFeatureFlagsService } from '#/services/company/company-feature-flags.service';
import { PossibleCompanyFeatureFlags } from '#/models/company/possible-feature-flags';
import { InterfaceFrontendModel as TransactionInterfaceModel } from '#/models/transaction/interface/frontendModel';
import { CompanyService } from '#/services/company/company.service';
import { TransactionInterfaceService } from '#/services/transaction/transaction-interface.service';
import { TransactionEditorService } from '#/services/transaction/transaction-editor.service';
import { TransactionType } from '#/models/transaction/transactionType';
import { RouteUtilsService } from '#/services/util/route-utils.service';
import { ExportStatus } from '~/app/modules/scheduled-export/models/scheduled-export.model';
import { CreatorType } from '#/models/transaction/creatorType.model';
import { VelosPluginTypes } from '#/models/velosPluginTypes';
import { ReportType } from '#/models/reportType.model';
import { PageStateService } from '#/services/util/page-state.service';
import { SelectAction } from '#/models/utils/tables';
import { arrayIsSetAndFilled, getObjectFromKeyValuePairs } from '#/util/arrays';

const DEPRECATED_COLUMN_SORTING_LOCALSTORAGE_KEY = 'column_configuration_reports_single';
const COLUMN_SORTING_LOCALSTORAGE_KEY = 'column_configuration_reports_single_v2022-06-21';
const FILTERS_LOCALSTORAGE_KEY = 'filters-report-declarations-single';

@Component({
	templateUrl: './report-declarations-single.component.html',
	styleUrls: ['./report-declarations-single.component.scss'],
})
export class ReportDeclarationsSingleComponent extends AuthenticatedComponent implements OnInit, OnDestroy {
	private receipts: any[];

	constructor(
		private expenseReportsService: ExpenseReportsService,
		private route: ActivatedRoute,
		private columnService: ColumnService,
		private actionService: ActionService,
		private filterService: FilterService,
		private companyCategory: CompanyCategoryService,
		private routeUtilsService: RouteUtilsService,
		private dashboardColumnSortingService: DashboardColumnSortingService,
		private companyFeatureFlagsService: CompanyFeatureFlagsService,
		private companyService: CompanyService,
		private transactionInterfaceService: TransactionInterfaceService,
		private transactionEditorService: TransactionEditorService,
		private pageStateService: PageStateService,
	) {
		super();
		this.pageStateService.initPageState();
	}

	@ViewChild('duplicateReceiptModal') duplicateReceiptModal: ElementRef;
	@ViewChild('filtersModal') filtersModal: ElementRef;
	@ViewChild('deleteReportModal') deleteReportModal: ElementRef;
	@ViewChild('retractReportModal') retractReportModal: ElementRef;

	public reportPromise: Promise<ExpenseReport>;
	public reportReceiptsPromise: Promise<{ data: { receipts: Receipt[] } }>;
	public columns: ColumnsInterface[];
	public totalColumns: ColumnsInterface[];
	public pageActions: ActionsMenuInterface[] = this.actionService.getPageActions();
	public transactionInterfaces: Array<TransactionInterfaceModel> = undefined;
	public filterOptions: FilterEnum[] = this.filterService.getFilterOptions();
	public filtersLocalStorageKey: string = FILTERS_LOCALSTORAGE_KEY;
	public reportId: string;

	private predefinedColumns: ColumnsInterface[];
	private prefixedColumns: ColumnsInterface[];

	private endColumns: ColumnsInterface[];
	private routeSubscription: Subscription = new Subscription();
	private queryParamsSubscription: Subscription = new Subscription();
	private report: ExpenseReport;
	private paymentInfoEnabled: boolean;

	public showFilters = false;

	public receiptListAPIRequest = new ReceiptListAPIRequest();
	public showPageActions = false;
	public showSelectActions = false;
	public selected: {
		rows: Array<string>;
		all: boolean;
	};
	public toDuplicateIds: Array<string>;

	public async ngOnInit(): Promise<void> {
		super.ngOnInit();

		const categoryAPIFilters = new CompanyCategoryListAPIRequest();
		categoryAPIFilters.max = 1;
		categoryAPIFilters.start = 0;

		const companyCategoryData = await this.companyCategory.getCompanyCategories(categoryAPIFilters);
		const states = {
			categoriesEnabled: Math.floor(companyCategoryData.count) > 0,
			paymentInfoEnabled: this.paymentInfoEnabled,
			compactModeEnabled: this.user?.preferences?.display_mode === 'compact',
		};
		this.setPredefinedColumns(states);

		if (!this.dashboardColumnSortingService.hasColumnsSavedLocally(COLUMN_SORTING_LOCALSTORAGE_KEY)) {
			this.dashboardColumnSortingService.setFirstTimeLocalStorageColumnOrder(
				COLUMN_SORTING_LOCALSTORAGE_KEY,
				(this.localStorageService.get(DEPRECATED_COLUMN_SORTING_LOCALSTORAGE_KEY) as ColumnSortingLocalStorageInterface)?.sortedColumns ??
					this.columnService.getColumns(states),
				DEPRECATED_COLUMN_SORTING_LOCALSTORAGE_KEY,
			);
		}

		this.columns = this.dashboardColumnSortingService.getColumnsBasedOnSortingOrder(
			COLUMN_SORTING_LOCALSTORAGE_KEY,
			this.predefinedColumns,
			this.prefixedColumns,
			this.endColumns,
		);
		if (this.company?.modules?.cardReports?.enabled) {
			this.columns.splice(this.columns.findIndex((e) => e.id === 'declaration_status') + 1, 0, {
				name: 'Document',
				slug: 'document',
				type: 'document',
				enabled: true,
				sortable: false,
				id: 'document',
				sortingProperty: 'document',
			});
		}
		this.totalColumns = this.dashboardColumnSortingService.getBottomRowColumns(this.columns);
		this.receiptListAPIRequest.start = 0;
		this.receiptListAPIRequest.max = 25;
		this.receiptListAPIRequest.sort = 'purchasedate';
		this.receiptListAPIRequest.sortorder = Order.DESCENDING;

		this.routeSubscription = this.route.params.subscribe((params) => {
			this.reportId = params.id;
			this.receiptListAPIRequest.report = this.reportId;
		});

		this.routeSubscription.unsubscribe();

		const websocketSubscription = this.websocketsService.onMessage
			.pipe(
				filter((msg) => needsReceiptListUpdate(msg.type)),
				debounceTime(800),
			)
			.subscribe(async () => {
				await this.fetchReport();
				await this.fetchReceipts();
			});

		this.destroyCallbacks.push(() => {
			websocketSubscription.unsubscribe();
		});

		if (isValueSet(this.companyService.getCompanyOfLoggedUser())) {
			await this.companyFeatureFlagsService.getAllFeatureFlags(this.companyService.getCompanyOfLoggedUser().id).then((flags) => {
				if (flags.includes(PossibleCompanyFeatureFlags.TRANSACTION_INTERFACES)) {
					return this.transactionInterfaceService.getInterfaces(false, false, true, null, true).then((res) => {
						this.transactionInterfaces = res.filter((e) => {
							switch (e.transactionType) {
								case TransactionType.FixedCompensation:
								case TransactionType.Receipt:
									return true;
								default:
									return false;
							}
						});
					});
				}
			});
		}

		await this.fetchReport();
		this.fetchPaymentInfoState();
		await this.setFilteredTransactions();
		await this.updateAPIRequestBasedOnQueryParams();
		await this.fetchReceipts();

		this.queryParamsSubscription = this.route.queryParams.subscribe(async (params) => {
			if (stringIsSetAndFilled(this.reportId)) {
				await this.updateAPIRequestBasedOnQueryParams();
				await this.fetchReport();
				await this.fetchReceipts();
			}
		});
	}

	private async setFilteredTransactions(): Promise<void> {
		const localStoragefilters: PaginatedExpenseReportsRequest = this.localStorageService.get(this.filtersLocalStorageKey);
		if (isValueSet(localStoragefilters)) {
			const allowedFilterFields = Object.entries(localStoragefilters).filter(([key]) => this.filterOptions.includes(key as FilterEnum));
			await this.updateAPIRequestBasedOnQueryParams();
			await this.updateRequestFilters(Object.assign(this.receiptListAPIRequest, getObjectFromKeyValuePairs(allowedFilterFields)));
		}
	}

	private async updateAPIRequestBasedOnQueryParams(): Promise<void> {
		if (stringIsSetAndFilled(this.route.snapshot.queryParams.itemsPerPage)) {
			this.receiptListAPIRequest.max = Number(this.route.snapshot.queryParams.itemsPerPage);
		}
		if (stringIsSetAndFilled(this.route.snapshot.queryParams.page)) {
			this.receiptListAPIRequest.start = (Number(this.route.snapshot.queryParams.page) - 1) * this.receiptListAPIRequest.max;
		}

		if (stringIsSetAndFilled(this.route.snapshot.queryParams.sort)) {
			this.receiptListAPIRequest.sort = this.route.snapshot.queryParams.sort;
		}

		if (stringIsSetAndFilled(this.route.snapshot.queryParams.sortOrder)) {
			this.receiptListAPIRequest.sortorder = this.route.snapshot.queryParams.sortOrder;
		}
	}

	public isSetTransactionInterfaces(): boolean {
		return arrayIsSetAndFilled(this.transactionInterfaces);
	}

	private setPredefinedColumns(states: { categoriesEnabled: boolean; paymentInfoEnabled: boolean; compactModeEnabled: boolean }): void {
		this.predefinedColumns = this.columnService.getColumns(states);
		this.prefixedColumns = this.predefinedColumns.filter((e) => e.position === 'pre');
		this.endColumns = this.predefinedColumns.filter((e) => e.position === 'end');
	}

	// Change of table events such as pageLength or next, previous.
	public async navigateTable(dataTablesParameters: DataTablesParameters): Promise<void> {
		this.receiptListAPIRequest.start = dataTablesParameters.start;
		this.receiptListAPIRequest.max = dataTablesParameters.length;
		await this.fetchReceipts();
	}

	public async updateRequestFilters(request: ReceiptListAPIRequest): Promise<void> {
		this.receiptListAPIRequest = Object.assign(this.receiptListAPIRequest, request);
		await this.fetchReceipts();
		this.closeFilters();
	}

	public async sortTable(sortedColumnsObj): Promise<void> {
		this.columns = sortedColumnsObj.sortedColumns;
		this.totalColumns = sortedColumnsObj.sortedTotalColumns;
		this.dashboardColumnSortingService.setColumnSortingToLocalStorage(sortedColumnsObj.sortedColumns, COLUMN_SORTING_LOCALSTORAGE_KEY);
		await this.fetchReceipts();
		await this.fetchReport();
	}

	public async fetchSortedColumns(event: { sortingProperty: string; sortingOrder: Order }): Promise<void> {
		this.receiptListAPIRequest.sort = event.sortingProperty;
		this.receiptListAPIRequest.sortorder = event.sortingOrder;
		await this.fetchReceipts();
		await this.fetchReport();
	}

	public async openEditModalWithReceiptId(id: string): Promise<void> {
		const flags = await this.companyFeatureFlagsService.getAllFeatureFlags(this.company.id);
		if (flags.includes(PossibleCompanyFeatureFlags.TRANSACTION_INTERFACES)) {
			this.router.navigate([`tx/${id}`], { relativeTo: this.route, queryParams: { 'view-mode': 'submit' } });
			return;
		}
		this.reportReceiptsPromise.then(async (receipts) => {
			if (receipts.data.receipts.find((x) => x.id === id)) {
				// Null is used because type is already known, we do not request new type.
				this.openEditReceiptModal(new Receipt(receipts.data.receipts.find((x) => x.id === id)), null);
			} else {
				// If receipt is not in current page, fetch it directly.
				const receipt = await this.receiptAPIService.getReceipt(id);
				this.openEditReceiptModal(receipt, null);
			}
		});
	}

	// Function that receives the callback from the select action.
	public executeRequestedSelectAction(action: SelectActionEnum, reportId: string): void {
		switch (action) {
			case SelectActionEnum.edit:
				// Edit is only active for when 1 item is selected.
				this.openEditModalWithReceiptId(this.selected.rows[0]);
				break;
			case SelectActionEnum.retract:
				this.retractSelectedReceipts(reportId);
				break;
			case SelectActionEnum.duplicate:
				this.toDuplicateIds = this.selected.rows;
				this.modalService.open(this.duplicateReceiptModal);
				break;
			default:
				throw new Error(`Missing action to preform: '${action}'`);
		}
		this.closeSelectActions();
	}

	// Function that receives callback for page action.
	public executeRequestedPageAction(action: ActionsMenuInterface): void {
		switch (action.action) {
			case ActionsMenuActionEnum.add_receipt:
				this.openEditReceiptModal(null, 'receipt'); // Null is used for creating new receipts.
				break;
			case ActionsMenuActionEnum.add_receipt_with_preset:
				this.openEditReceiptModal(null, 'receipt', action.context); // Null is used for creating new receipts.
				break;
			case ActionsMenuActionEnum.travel_expense:
				this.openEditReceiptModal(null, 'travel'); // Null is used for creating new receipts.
				break;
			case ActionsMenuActionEnum.split:
				this.openDocumentSplitModal();
				break;
			default:
				throw new Error(`Missing action to preform: '${action}'`);
		}
		this.showPageActions = false;
	}

	// Function that receives callback for row action and id of the item.
	public executeRequestedRowAction(rowActionObj: { action: ActionsMenuActionEnum; id: string }): void {
		switch (rowActionObj.action) {
			case ActionsMenuActionEnum.edit:
				this.openEditModalWithReceiptId(rowActionObj.id);
				break;
			case ActionsMenuActionEnum.exclude:
				this.excludeReceipt(rowActionObj.id);
				break;
			case ActionsMenuActionEnum.delete:
				this.deleteReceipt(rowActionObj.id);
				break;
			case ActionsMenuActionEnum.duplicate:
				this.toDuplicateIds = [rowActionObj.id];
				this.modalService.open(this.duplicateReceiptModal);
				break;
			default:
				throw new Error(`Missing action to preform: '${rowActionObj.action}'`);
		}
	}

	public submitReport(): void {
		this.reportPromise = this.expenseReportsService.submitExpenseReport(this.report.id);
		this.reportPromise.then(async () => {
			await this.fetchReceipts();
			await this.fetchReport();
			this.notificationService.success(this.getTranslation('Report submitted'));
		});
	}

	public async fetchReport(): Promise<void> {
		this.reportPromise = this.expenseReportsService.getExpenseReport(this.reportId, false);
		this.report = await this.reportPromise;
	}

	public async fetchReceipts(): Promise<void> {
		this.transactionEditorService.setLatestDashboardFilters(this.receiptListAPIRequest);
		const interfaces = await this.transactionInterfaceService.getInterfaces(false, true, false, null, true);
		this.reportReceiptsPromise = this.receiptAPIService.getReceipts(this.receiptListAPIRequest).then((res) => {
			// TODO Implement report tab on BE
			if (this.route.snapshot.data.tab === 'todo') {
				switch (this.route.snapshot.data.variant) {
					case ReportType.OUT_OF_POCKET:
					case ReportType.CARD:
						const filteredReceipts = (res.data.receipts ?? []).filter((e) => {
							return (e.attachments_count === 0 && !e.noDocumentAvailable) || e.declarationstatus?.status === ExportStatus.INCOMPLETE;
						});
						res.data.receipts = filteredReceipts;
				}
			}
			res.data.receipts?.forEach((e) => {
				e.transaction_interface_type = interfaces.find((int) => int.id === e.transaction_interface)?.transactionType;
			});
			return res;
		});

		this.reportReceiptsPromise.then((res) => {
			this.receipts = res.data.receipts;
		});
	}

	public openPageActions(): void {
		this.showPageActions = true;
	}

	public closePageActions(): void {
		this.showPageActions = false;
	}

	public openFilters(): void {
		this.showFilters = true;
	}

	public closeFilters(): void {
		this.showFilters = false;
	}

	public async closeDuplicateModal(): Promise<void> {
		this.modalService.close(this.duplicateReceiptModal);
		await this.fetchReport();
		await this.fetchReceipts();
	}

	private async openDocumentSplitModal(): Promise<void> {
		const { DocumentSplitModalComponent } = await import(
			'~/app/modules/receipt/components/modals/document-split-modal/document-split-modal.component'
		);

		const modalRef = this.modalService.open(DocumentSplitModalComponent);
		modalRef.componentInstance.user = this.user;
		modalRef.componentInstance.reportId = this.reportId;

		modalRef.result
			.then(async () => {
				await this.fetchReport();
				await this.fetchReceipts();
			})
			.catch(async () => {
				await this.fetchReport();
				await this.fetchReceipts();
			});

		const doneSubscription = modalRef.componentInstance.done.subscribe(async () => {
			await this.fetchReport();
			await this.fetchReceipts();
		});

		this.destroyCallbacks.push(() => {
			if (doneSubscription) {
				doneSubscription.unsubscribe();
			}
		});
	}

	public getSelectActions = (): SelectActionInterface[] => {
		const selectedReceipts = this.selected.rows.map((selection) => this.receipts.find((e) => e.id === selection));
		return this.actionService.getSelectActions(this.report, selectedReceipts);
	};

	public getRowActions = (row: Receipt): ActionsMenuInterface[] => {
		return this.actionService.getRowActions(this.report, row);
	};

	// FIXME: Replace this modal service
	private async openEditReceiptModal(
		receipt: Receipt,
		new_receipt_type: string,
		preset: CompanyModuleReceiptPresetsPreset = null,
	): Promise<void> {
		if (stringIsSetAndFilled(receipt?.created_with_preset)) {
			this.receiptFlowService.initWithReceipt(receipt, this.user);
		} else if (isValueSet(preset)) {
			this.receiptFlowService.initWithPreset(preset, this.user);
			new_receipt_type = preset.receipt_type;
		} else {
			this.receiptFlowService.initWithPreset(null, this.user);
		}

		this.showSelectActions = false;
		const { ReceiptEditModalComponent } = await import(
			'~/app/modules/receipt/components/modals/receipt-edit-modal/receipt-edit-modal.component'
		);
		const modalRef = this.modalService.open(ReceiptEditModalComponent, {
			size: 'lg',
			beforeDismiss: () => {
				if (modalRef.componentInstance.hasReceiptBeenChanged()) {
					return new Promise((resolve, reject) => {
						const confirmModalRef = this.modalService.open(ConfirmModalComponent);
						confirmModalRef.componentInstance.type = 'text';
						confirmModalRef.componentInstance.title = this.translate.instant(_('Dismiss changes'));
						confirmModalRef.componentInstance.message = this.translate.instant(_('Are you sure that you want to dismiss your changes?'));
						const sub = confirmModalRef.componentInstance.result.subscribe((data) => {
							resolve(data.result);
							sub.unsubscribe();
						});
						this.destroyCallbacks.push(() => {
							sub.unsubscribe();
						});
					});
				}
			},
		});

		modalRef.componentInstance.report = this.report;
		modalRef.componentInstance.new_receipt_type = new_receipt_type;
		if (isValueSet(preset)) {
			modalRef.componentInstance.isNew = true;
			modalRef.componentInstance.receipt = this.receiptFlowService.currentReceipt;
			modalRef.componentInstance.receipt.report = this.report.id;
			modalRef.componentInstance.onReportChange(this.report.id);
		} else {
			modalRef.componentInstance.receipt = receipt;
		}

		modalRef.componentInstance.savedInReport = isValueSet(receipt?.report);
		modalRef.componentInstance.user = this.user;
		modalRef.componentInstance.tableType = 'personal';
		try {
			await modalRef.result;
		} finally {
			await this.fetchReceipts();
			await this.fetchReport();
		}
	}

	private excludeReceipt(receiptId: string): void {
		this.expenseReportsService.excludeReceipt(this.report.id, receiptId).then(async () => {
			await this.fetchReport();
			await this.fetchReceipts();
			this.notificationService.success(this.translate.instant(_('Receipt excluded')));
		});
	}

	private deleteReceipt(receiptId: string): void {
		this.receiptAPIService.deleteReceipt(receiptId).then(async () => {
			await this.fetchReport();
			await this.fetchReceipts();
			this.notificationService.success(this.translate.instant(_('Receipt deleted')));
		});
	}

	private retractSelectedReceipts(reportId: string): void {
		const promiseArray = [];
		for (const i in this.selected.rows) {
			if (this.selected.rows.hasOwnProperty(i)) {
				promiseArray.push(Promise.resolve(this.expenseReportsService.excludeReceipt(reportId, this.selected.rows[i])));
			}
		}
		Promise.all(promiseArray).then(async () => {
			await this.fetchReport();
			await this.fetchReceipts();
			this.notificationService.success(this.getTranslation('Receipts retracted'));
		});
	}

	public openSelectActions(event: any): void {
		this.selected = event;
		this.showSelectActions = true;
	}

	private closeSelectActions(): void {
		this.showSelectActions = false;
	}

	private fetchPaymentInfoState(): void {
		this.paymentInfoEnabled = this.userAPIService.getCurrentLoggedUser().preferences.paymentinfo;
	}

	private isCreatorTypeVelos(): boolean {
		return this.report?.creator?.type === CreatorType.VELOS;
	}

	public canAddToReport(): boolean {
		return this.expenseReportsService.canAddItemInReport(this.report);
	}

	public showSubmitButton(): boolean {
		return this.expenseReportsService.showSubmitButton(this.report);
	}

	public canSubmitReport(): boolean {
		return this.expenseReportsService.canSubmitReport(this.report);
	}

	public canRetractReport(): boolean {
		return this.expenseReportsService.canRetractReport(this.report);
	}

	public canDeleteReport(): boolean {
		return this.expenseReportsService.canDeleteReport(this.report);
	}

	public openDeleteReportModal(): void {
		this.modalService.open(this.deleteReportModal);
	}

	public closeDeleteReportModal(): void {
		this.modalService.close(this.deleteReportModal);
		this.fetchReport();
	}

	public openRetractReportModal(): void {
		this.modalService.open(this.retractReportModal);
	}

	public closeRetractReportModal(): void {
		this.modalService.close(this.retractReportModal);
	}

	public goToParentRoute(): void {
		this.routeUtilsService.navigateToParentRoute(this.route);
	}

	public getEmptyTableText(): string {
		if (this.canSubmitReport()) {
			return this.translate.instant(_('All to-do items completed, you can now submit your report.'));
		}
		return this.translate.instant(_('All to-do items completed, no action required'));
	}

	onTabTodoClicked() {
		this.router.navigateByUrl(`${this.getParentUrl()}/${this.reportId}/todo`);
	}

	onTabAllClicked() {
		this.router.navigateByUrl(`${this.getParentUrl()}/${this.reportId}/all`);
	}

	private getParentUrl(): string {
		switch (this.route.snapshot.data.variant) {
			case ReportType.CARD:
				return '/reports/card';
			case ReportType.OUT_OF_POCKET:
				return '/reports/out-of-pocket';
			default:
				return '/reports';
		}
	}

	public updateRouteWithSortingParams(event: { sortingProperty: string; sortingOrder: Order }): void {
		const newUrl: string = this.routeUtilsService.getUpdateRouteWithNewParamValue(
			this.router.url.split('?')[0],
			this.route.snapshot.queryParams,
			{
				sort: event.sortingProperty,
				sortOrder: event.sortingOrder,
			},
		);
		this.router.navigateByUrl(newUrl);
	}

	public showTabs(): boolean {
		return this.route.snapshot.data.showTabs;
	}

	public getActiveTab(): 'todo' | 'all' {
		return this.route.snapshot.data.tab ?? 'all';
	}

	public getTooltipText(): string {
		if (!this.showSubmitButton) {
			return;
		}

		if (!this.canSubmitReport() && this.isCreatorTypeVelos() && this.company?.modules?.velos?.type === VelosPluginTypes.SYNC) {
			return this.translate.instant(_('You can not submit unsettled expenses, finish your to-dos and wait until the expenses are settled'));
		}

		if (!this.canSubmitReport()) {
			return this.translate.instant(_('You have to complete your todos before submitting your report'));
		}

		return '';
	}

	public selectAllTransactionsOnAllPages = async (selectAction: SelectAction): Promise<Array<string>> => {
		this.selected = await this.receiptAPIService.getSelectedForAllReceipts(selectAction, this.receiptListAPIRequest);
		return this.selected.rows;
	};

	public ngOnDestroy(): void {
		super.ngOnDestroy();
		this.routeSubscription.unsubscribe();
		this.queryParamsSubscription.unsubscribe();
	}
}
