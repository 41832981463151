import { de, el, enGB, enUS, es, fr, nl, pt } from 'date-fns/locale';
import { LanguagesSupported } from '#/models/translations';

const locales = { nl, de, fr, es, pt, el, enGB, enUS };

export function getLocale() {
	return locales[window['__localeId__']];
}

export function getLocaleForNumberLocalization(): LanguagesSupported {
	const windowLocale = window['__localeId__'];
	switch (windowLocale) {
		case 'enGB':
			return LanguagesSupported.EN_GB;
		case 'enUS':
			return LanguagesSupported.EN_US;
		case 'nl':
			return LanguagesSupported.NL;
		case 'de':
			return LanguagesSupported.DE;
		case 'fr':
			return LanguagesSupported.FR;
		case 'es':
			return LanguagesSupported.ES;
		case 'pt':
			return LanguagesSupported.PT;
		case 'el':
			return LanguagesSupported.GR;
		default:
			return LanguagesSupported.EN_GB;
	}
}
