import { Component, Host, Input, Optional } from '@angular/core';
import { AppSelectOption, FormElementComponent } from '@klippa/ngx-enhancy-forms';
import { DynamicOptionsValueAccessorBase } from '~/app/shared/ui/forms/composed/pickers/dynamic/dynamic-options-picker/dynamic-options-value-accessor-base';
import { ControlContainer } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { stringIsSetAndFilled } from '#/util/values';
import { ItemsWithHasMoreResultsPromise } from '#/models/appSelectOption.model';

export type AppSelectOptionsWithTranslateParams = Array<
	AppSelectOption & { nameTranslateParams?: Object; descriptionTranslateParams?: Object }
>;

@Component({
	selector: '',
	template: '',
})
export abstract class StaticOptionsValueAccessorBase<T> extends DynamicOptionsValueAccessorBase<T, AppSelectOption> {
	@Input() customSearchFn: (term: string, item: { id: string; name: string; description: string }) => boolean = (term, item) => {
		return (
			item.id?.toLowerCase().includes(term?.toLowerCase()) ||
			item.name?.toLowerCase().includes(term?.toLowerCase()) ||
			item.description?.toLowerCase().includes(term?.toLowerCase())
		);
	};

	constructor(
		@Optional() @Host() protected parent: FormElementComponent,
		@Optional() @Host() protected controlContainer: ControlContainer,
		protected translate: TranslateService,
	) {
		super(parent, controlContainer);
	}

	public abstract fetchStaticItemsFn(): AppSelectOptionsWithTranslateParams;

	fetchItemsFn = (start: number, searchQuery: string): ItemsWithHasMoreResultsPromise<AppSelectOption> => {
		return Promise.resolve(this.fetchStaticItemsFn()).then((items) => {
			return {
				hasMoreResults: false,
				items: items.map((e) => ({
					...e,
					name: this.translate.instant(e.name, e.nameTranslateParams ?? {}),
					description: stringIsSetAndFilled(e.description)
						? this.translate.instant(e.description, e.descriptionTranslateParams ?? {})
						: null,
				})),
			};
		});
	};

	fetchSelectedItemsFn = (ids: Array<T>): Promise<Array<AppSelectOption>> => {
		return this.fetchItemsFn(0, '').then((res) => res.items.filter((e) => ids.includes(e.id)));
	};

	mapToSelectOptionFn = (e: AppSelectOption): AppSelectOption => {
		return e;
	};
}
