import { Component, Host, Input, Optional } from '@angular/core';
import { AppSelectOption, FormElementComponent } from '@klippa/ngx-enhancy-forms';
import { ControlContainer, NG_VALUE_ACCESSOR } from '@angular/forms';
import { DynamicOptionsValueAccessorBase } from '~/app/shared/ui/forms/composed/pickers/dynamic/dynamic-options-picker/dynamic-options-value-accessor-base';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { ItemsWithHasMoreResultsPromise } from '#/models/appSelectOption.model';

@Component({
	selector: 'app-generic-dynamic-options-picker',
	templateUrl: './dynamic-options-picker/dynamic-options-picker.template.html',
	styleUrls: ['./dynamic-options-picker/dynamic-options-picker.template.scss'],
	providers: [{ provide: NG_VALUE_ACCESSOR, useExisting: GenericDynamicOptionsPickerComponent, multi: true }],
})
export class GenericDynamicOptionsPickerComponent extends DynamicOptionsValueAccessorBase<string, AppSelectOption> {
	@Input() fetchItemsFn: (start: number, searchQuery: string) => ItemsWithHasMoreResultsPromise<AppSelectOption>;
	@Input() fetchSelectedItemsFn: (ids: Array<string>) => Promise<Array<AppSelectOption>>;

	constructor(
		@Optional() @Host() protected parent: FormElementComponent,
		@Optional() @Host() protected controlContainer: ControlContainer,
	) {
		super(parent, controlContainer);
		this.defaultPlaceHolder = _('Select option');
	}

	mapToSelectOptionFn = (e: AppSelectOption): AppSelectOption => {
		return e;
	};
}
