export enum FilterEnum {
	search = 'search',
	period = 'period', // Emitted to since and before.
	since = 'since',
	before = 'before',
	user = 'user',
	users = 'users',
	type = 'type',
	category = 'category',
	companycategory = 'companycategory',
	administration = 'administration',
	companyadministration = 'companyadministration',
	companycostcenter = 'companycostcenter',
	cost_center = 'cost_center',
	companycostunit = 'companycostunit',
	cost_unit = 'cost_unit',
	companyproject = 'companyproject',
	project = 'project',
	companygroup = 'companygroup',
	exported = 'exported',
	status = 'status',
	statusses = 'statusses',
	booked = 'booked',
	authorization_flow = 'authorization_flow',
	currency = 'currency',
	merchant = 'merchant',
	relation = 'relation',
	vat = 'vat',
	paymentmethod = 'paymentmethod', // payment methods module is disabled
	payment_method_id = 'payment_method_id', // payment methods module is enabled - CompanyPaymentMethod
	accountnumber = 'accountnumber',
	group = 'group',
	tag = 'tag',
	current_approver = 'current_approver',
}
