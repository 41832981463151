import { Component, Input, OnInit } from '@angular/core';
import { DeclarationStatusFlag, getBookingProviderLabel, ReceiptBookingStatus } from '#/models/transaction/receipt';
import { format } from '~/app/util/i18n';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { TranslateService } from '@ngx-translate/core';
import { Company } from '#/models/company/company.model';
import { ReportBookingData } from '#/models/transaction/expense-reports';
import { Themed } from '#/providers/themed';

@Component({
	selector: 'app-booking-status-badge',
	templateUrl: './booking-status-badge.component.html',
	styleUrls: ['./booking-status-badge.component.scss'],
})
export class BookingStatusBadgeComponent implements OnInit {
	@Input() public bookingStatus: ReceiptBookingStatus; // for receipts
	@Input() public bookingData: ReportBookingData; // for reports
	@Input() public declarationStatus: DeclarationStatusFlag;
	@Input() public company: Company;
	@Input() public variant: 'receipt' | 'report' = 'receipt';

	public canbePickedUpByQueue: boolean;
	public backgroundQueueError: string | undefined;
	public isbooked: boolean;

	constructor(protected translate: TranslateService, private themed: Themed) {}

	ngOnInit() {
		if (this.bookingStatus) {
			this.backgroundQueueError = this.bookingStatus.backgroundQueueError;
			this.canbePickedUpByQueue = this.bookingStatus.canbePickedUpByQueue;
			this.isbooked = this.bookingStatus.isbooked;
		}
		if (this.bookingData) {
			this.backgroundQueueError = this.bookingData.bookingQueue_data.backgroundQueueError;
			this.canbePickedUpByQueue = this.bookingData.bookingQueue_data.canbePickedUpByQueue;
			this.isbooked = this.bookingData.bookingstatus.is_booked;
		}
	}

	getBookingInformation(): string {
		if (this.isbooked) {
			// Todo: add bookedby? Resolve to company user.

			if (this.bookingStatus) {
				return this.translate.instant(_('Booked to %provider% on %date%'), {
					provider: getBookingProviderLabel(this.bookingStatus.externalprovider, this.themed.getThemeProductTitle()),
					date: format(this.bookingStatus.bookedon, 'PPPPp'),
				});
			}
			if (this.bookingData) {
				return this.translate.instant(_('Booked to %provider% on %date%'), {
					provider: getBookingProviderLabel(this.bookingData.provider, this.themed.getThemeProductTitle()),
					date: format(new Date(this.bookingData.bookingstatus.bookedon), 'PPPPp'),
				});
			}
		}
		return '';
	}

	isBookable(): boolean {
		// Can't book when already booked.
		if (this.isbooked) {
			return false;
		}

		// Can't be booked if the booking is already in the queue
		if (this.canbePickedUpByQueue && !this.backgroundQueueError) {
			return false;
		}

		// Can't book when wrong declaration status.
		if (this.declarationStatus !== DeclarationStatusFlag.Approved) {
			return false;
		}

		// FIXME this function does not check (yet) for invoice, relation number or provider, division and journal like in Receipt.isBookable()
		return false;
	}

	// TODO add booking options for receipts to this component. Reports won't be booked via this component.
	bookReceipt() {}
}
