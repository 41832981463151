import { Component, ElementRef, inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { stringIsSetAndFilled } from '#/util/values';

@Component({
	selector: '',
	template: '',
})
export abstract class DataTableBase<T> {
	private readonly activatedRoute: ActivatedRoute;
	private readonly router: Router;
	private readonly elRef: ElementRef;

	protected constructor() {
		this.activatedRoute = inject(ActivatedRoute);
		this.router = inject(Router);
		this.elRef = inject(ElementRef);
	}

	public abstract fetchItemsFn(
		start: number,
		searchQuery: string,
		itemsPerPage: number,
		sortField: string,
		sortOrder: 'ASC' | 'DESC',
		filters: Record<string, any>,
	): Promise<{
		totalAmount: number;
		data: Array<T>;
	}>;

	public abstract _onRowClicked(row: T): void;

	public async updateRoute(queryParams) {
		await this.router.navigate([], {
			relativeTo: this.activatedRoute,
			queryParams,
			queryParamsHandling: 'merge',
		});
	}

	public getSearchParams() {
		return this.activatedRoute.snapshot.queryParams;
	}

	protected retrieveColumnsFn = async (): Promise<Array<{ key: string; active: boolean }>> => {
		const componentName = this.elRef?.nativeElement?.nodeName?.toLowerCase();
		if (stringIsSetAndFilled(componentName)) {
			const cols = localStorage.getItem(`wdt-columns-${componentName}`);
			if (cols) {
				return JSON.parse(cols);
			}
		}
		return [];
	};

	protected persistColumnsFn = async (cols: Array<{ key: string; active: boolean }>): Promise<void> => {
		const componentName = this.elRef?.nativeElement?.nodeName?.toLowerCase();
		if (stringIsSetAndFilled(componentName)) {
			localStorage.setItem(`wdt-columns-${componentName}`, JSON.stringify(cols));
		}
	};
}
