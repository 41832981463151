import { Component, Host, Input, OnDestroy, OnInit, Optional } from '@angular/core';
import { AuthorizationFlow, Company } from '#/models/company/company.model';
import { sortBy, uniqBy } from 'lodash';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { emptyID } from '#/util/mongoDB';
import { ControlContainer, NG_VALUE_ACCESSOR } from '@angular/forms';
import { FormElementComponent, ValueAccessorBase } from '@klippa/ngx-enhancy-forms';
import { AppState } from '~/app/reducers';
import { Subscription } from 'rxjs';

@Component({
	selector: 'app-company-authorization-flow-picker',
	templateUrl: './company-authorization-flow-picker.component.html',
	styleUrls: ['./company-authorization-flow-picker.component.scss'],
	providers: [{ provide: NG_VALUE_ACCESSOR, useExisting: CompanyAuthorizationFlowPickerComponent, multi: true }],
})
export class CompanyAuthorizationFlowPickerComponent extends ValueAccessorBase<string> implements OnInit, OnDestroy {
	protected companySubscription: Subscription;
	protected company: Company;
	protected companyAuthorizationFlows: AuthorizationFlow[];
	protected defaultPlaceHolder: string;
	public authorizationFlowsOptions: { id: string; name: string }[];

	public authorizationFlows: AuthorizationFlow[] = [];
	public emptyId = emptyID;

	@Input() public showAll = false;
	@Input() disabled = false;
	@Input() allowCustom = false;
	@Input() documentType = '';
	@Input() public clearable = true;
	@Input() public multiple = false;
	@Input() placeholder: string;

	constructor(
		private store: Store<AppState>,
		public translate: TranslateService,
		@Optional() @Host() protected parent: FormElementComponent,
		@Optional() @Host() protected controlContainer: ControlContainer,
	) {
		super(parent, controlContainer);
		this.defaultPlaceHolder = _('Select authorization flow');
	}

	ngOnInit(): void {
		super.ngOnInit();
		this.companySubscription = this.store.select('company').subscribe((val) => {
			this.authorizationFlows = [];
			this.companyAuthorizationFlows = val.companyAuthorizationFlows ?? [];

			if (this.showAll) {
				this.companyAuthorizationFlows.forEach((p) => {
					this.authorizationFlows.push(p);
				});
			} else {
				this.companyAuthorizationFlows
					.filter((p) => p.active)
					.forEach((p) => {
						this.authorizationFlows.push(p);
					});
			}

			if (this.documentType !== '') {
				this.authorizationFlows = this.authorizationFlows.filter(
					(authorizationFlow: AuthorizationFlow) =>
						authorizationFlow.document_type === '' || authorizationFlow.document_type === this.documentType,
				);
			}
			if (this.documentType === '') {
				this.authorizationFlows = this.authorizationFlows.filter(
					(authorizationFlow: AuthorizationFlow) => authorizationFlow.document_type !== 'report',
				);
			}

			this.authorizationFlows = sortBy(this.authorizationFlows, ['description']);

			if (this.allowCustom) {
				const customOption = new AuthorizationFlow();
				customOption.id = this.emptyId;
				customOption.description = this.translate.instant(_('Custom'));
				this.authorizationFlows.push(customOption);
			}

			this.authorizationFlows = uniqBy(this.authorizationFlows, (authorizationFlow: AuthorizationFlow) => authorizationFlow.id);
			this.authorizationFlowsOptions = this.authorizationFlows.map((authFlow: AuthorizationFlow) => {
				return { id: authFlow.id, name: authFlow.description };
			});
		});
	}

	ngOnDestroy() {
		this.companySubscription.unsubscribe();
		super.ngOnDestroy();
	}

	public getPlaceholderTextFn = (): string => {
		return this.placeholder ?? this.translate.instant(this.defaultPlaceHolder);
	};
}
