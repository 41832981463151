import { Component, inject, TemplateRef, ViewChild } from '@angular/core';
import {
	ActionMenuBtnRefToken,
	CheckBoxRefToken,
	ConfigBtnRefToken,
	FilterBtnRefToken,
	SaveBtnRefToken,
	SearchInputRefToken,
	ToggleRefToken,
	TranslationsToken,
} from 'wutu-data-table';
import { TranslateService } from '@ngx-translate/core';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';

/**
 * Used for a trick to force reloading a path. Check route-utils.service.ts
 */

@Component({
	selector: 'app-external-template-deps',
	templateUrl: './externalTemplateDeps.html',
	providers: [
		{
			provide: CheckBoxRefToken,
			useFactory: () => inject(ExternalTemplateDeps, { self: true }).checkbox,
		},
		{
			provide: ConfigBtnRefToken,
			useFactory: () => inject(ExternalTemplateDeps, { self: true }).configBtn,
		},
		{
			provide: ToggleRefToken,
			useFactory: () => inject(ExternalTemplateDeps, { self: true }).toggleBtn,
		},
		{
			provide: SaveBtnRefToken,
			useFactory: () => inject(ExternalTemplateDeps, { self: true }).saveBtn,
		},
		{
			provide: ActionMenuBtnRefToken,
			useFactory: () => inject(ExternalTemplateDeps, { self: true }).actionMenuBtn,
		},
		{
			provide: SearchInputRefToken,
			useFactory: () => inject(ExternalTemplateDeps, { self: true }).searchInput,
		},
		{
			provide: FilterBtnRefToken,
			useFactory: () => inject(ExternalTemplateDeps, { self: true }).filterButton,
		},
		{
			provide: TranslationsToken,
			deps: [TranslateService],
			useFactory: (translate: TranslateService) => {
				return {
					'Configure your table': translate.instant(_('Configure your table')),
					'Selected %length% item(s)': translate.instant(_('Selected %length% item(s)')),
					'Showing %from% to %to% of %total% results': translate.instant(_('Showing %from% to %to% of %total% results')),
					First: translate.instant(_('First')),
					Previous: translate.instant(_('Previous')),
					Next: translate.instant(_('Next')),
					Last: translate.instant(_('Last')),
					Show: translate.instant(_('Show')),
					Filter: translate.instant(_('Filter')),
					results: translate.instant(_('results')),
				};
			},
		},
	],
})
export class ExternalTemplateDeps {
	@ViewChild('checkbox') checkbox: TemplateRef<any>;
	@ViewChild('configBtn') configBtn: TemplateRef<any>;
	@ViewChild('toggleBtn') toggleBtn: TemplateRef<any>;
	@ViewChild('saveBtn') saveBtn: TemplateRef<any>;
	@ViewChild('actionMenuBtn') actionMenuBtn: TemplateRef<any>;
	public searchQuery: string;
	@ViewChild('searchInput') searchInput: TemplateRef<any>;
	@ViewChild('filterButton') filterButton: TemplateRef<any>;

	// TODO: hack, because this is called from a random span in the template, not sure how to do this better
	setQuery(query: string) {
		this.searchQuery = query;
	}
}
