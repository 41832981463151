import { Component, Host, Input, OnChanges, OnDestroy, OnInit, Optional, SimpleChanges } from '@angular/core';
import { Company, VATCode } from '#/models/company/company.model';
import { orderBy, sortBy } from 'lodash';
import { ControlContainer, NG_VALUE_ACCESSOR } from '@angular/forms';
import { FormElementComponent, ValueAccessorBase } from '@klippa/ngx-enhancy-forms';
import { Store } from '@ngrx/store';
import { AppState } from '~/app/reducers';
import { StatisticsService } from '~/app/pages/authenticated/statistics/statistics.service';
import { VATStat } from '~/app/pages/authenticated/statistics/models/stats';
import { ReceiptListAPIRequest } from '#/models/transaction/receipt';
import { isValueSet, stringIsSetAndFilled } from '#/util/values';
import { CompanyIntegrationService } from '#/services/company/company-integration.service';
import { convertDateToYMD } from '#/util/date';

@Component({
	selector: 'app-company-vat-code-picker',
	templateUrl: './company-vat-code-picker.component.html',
	providers: [{ provide: NG_VALUE_ACCESSOR, useExisting: CompanyVATCodePickerComponent, multi: true }],
})
export class CompanyVATCodePickerComponent extends ValueAccessorBase<string> implements OnInit, OnChanges, OnDestroy {
	@Input() public integration: string;
	@Input() public disabled = false;
	@Input() public division: string;
	@Input() public purchasedate: Date;
	@Input() public dropdownPosition: string;
	@Input() public financeType = '';
	@Input() showStats = false;
	@Input() statsFilters: ReceiptListAPIRequest = null;

	public vatOptionsPromise: Promise<{ name: string; id: string }[] | void>;
	private company: Company;
	private destroyCallbacks = [];

	constructor(
		@Optional() @Host() protected parent: FormElementComponent,
		@Optional() @Host() protected controlContainer: ControlContainer,
		private companyIntegrationService: CompanyIntegrationService,
		private statisticsService: StatisticsService,
		private store: Store<AppState>,
	) {
		super(parent, controlContainer);
	}
	ngOnInit(): void {
		super.ngOnInit();
		const companySubscription = this.store.select('company').subscribe((val) => {
			this.company = val.company;
		});

		this.destroyCallbacks.push(() => {
			companySubscription.unsubscribe();
		});

		if (this.showStats) {
			this.fetchVATStats();
		} else {
			this.fetchVATCodes();
		}
	}

	fetchVATStats() {
		this.vatOptionsPromise = this.statisticsService.getVATStats(this.statsFilters, 'vatpercentage').then((stats: VATStat[]) => {
			const vatCodeOptions = stats.map((vatStat: VATStat) => {
				return {
					id: vatStat.getID(),
					name: vatStat.getLabel(),
				};
			});
			return orderBy(vatCodeOptions, ['Label']);
		});
	}

	fetchVATCodes() {
		if (!stringIsSetAndFilled(this.integration)) {
			return;
		}
		if (!stringIsSetAndFilled(this.division)) {
			return;
		}
		let date = '';
		if (this.purchasedate) {
			date = convertDateToYMD(this.purchasedate);
		}

		this.vatOptionsPromise = this.companyIntegrationService
			.getAllVATCodesDeprecated(this.company, this.integration, this.division, date, this.financeType)
			.then((vatCodes: VATCode[]) => {
				if (isValueSet(this.innerValue) && !vatCodes.some((e) => e.Identifier === this.innerValue)) {
					// if the current value does not exist as an option, reset it to null
					this.setInnerValueAndNotify(null);
				}
				const sorted = sortBy(vatCodes, ['Description']);
				return sorted.map((vatCode) => ({
					id: vatCode.Identifier,
					name: vatCode.Description,
				}));
			});
	}

	ngOnChanges(changes: SimpleChanges) {
		if (changes.division || changes.purchasedate || changes.financeType || changes.company) {
			if (isValueSet(this.company)) {
				this.fetchVATCodes();
			}
		}
	}

	ngOnDestroy() {
		super.ngOnDestroy();
		this.destroyCallbacks.forEach((cb) => cb());
	}
}
