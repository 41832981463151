import { Injectable } from '@angular/core';
import { APIService } from '~/app/api/services/api.service';
import { MultipleFactorAuthService } from '~/app/shared/ui/multiple-factor-auth/multiple-factor-auth.service';

@Injectable({
	providedIn: 'root',
})
export class ValidateSudoService {
	constructor(private apiService: APIService, private mfaAuthService: MultipleFactorAuthService) {}

	getAuthMethod(): Promise<any> {
		const authMethodUrl = 'user/sudo/authMethod';
		const authMethod = this.apiService.getFromApi(authMethodUrl).then((res) => {
			return res.data.method;
		});
		return authMethod;
	}

	validateWithPassword(password: string): Promise<any> {
		const passAuthUrl = 'user/sudo/verifyPassword';
		const body = {
			password: password,
		};
		const passwordResult = this.apiService.postToApi(passAuthUrl, body);
		return passwordResult;
	}

	validateWithMfa(authCode: string): Promise<any> {
		const mfaResult = this.mfaAuthService.loginWithAuthCode(authCode);
		return mfaResult;
	}
}
