import { Injectable } from '@angular/core';
import { APIService } from '~/app/api/services/api.service';
import { Subscriber } from 'rxjs';

const totpUrl = 'user/mfa/totp/';

export interface EnableTOTPResponse {
	QRBytes: string;
	activationKey: string;
	accountName: string;
	setupKey: string;
}

@Injectable({
	providedIn: 'root',
})
export class MultipleFactorAuthService {
	constructor(private apiService: APIService) {}

	public getEnabledMFAOptions(): Promise<any> {
		return this.apiService
			.getFromApi('user/mfa/options')
			.then((res) => {
				const options = res.data.options;
				if (options) {
					return options;
				}
				return [];
			})
			.catch((e) => {
				if (e?.error?.code === 100019) {
					return [];
				} else {
					throw e;
				}
			});
	}

	public sendVerifyCodeToEnableTOTPViaEmail(): Promise<void> {
		return this.apiService.postToApi(`${totpUrl}email`);
	}

	public confirmTOTPViaEmail(validationCode: string): Promise<void> {
		return this.apiService.postToApi(`${totpUrl}email/confirm`, { validation_code: validationCode });
	}

	public enableTOTP(): Promise<EnableTOTPResponse> {
		return this.apiService.postToApi(`${totpUrl}enable`).then((res) => {
			return {
				QRBytes: res.data.qr_bytes,
				activationKey: res.data.activation_key,
				accountName: res.data.label,
				setupKey: res.data.pretty_secret,
			};
		});
	}

	public confirmTOTP(key: string, token: string): Promise<void> {
		return this.apiService.postToApi(totpUrl + 'enable/confirm', {
			key,
			token,
		});
	}

	public generateBackupCodes() {
		return this.apiService.postToApi('user/mfa/backupCodes/enable').then((res) => res.data.backup_codes);
	}

	public loginWithAuthCode(token: string) {
		return this.apiService.postToApi(totpUrl + 'verify', {
			token,
		});
	}

	public loginWithBackupCode(code: string) {
		return this.apiService.postToApi('user/mfa/backupCodes/verify', {
			token: code,
		});
	}

	disableTOTP(mfaKey: string) {
		return this.apiService.postToApi(totpUrl + 'disable', { token: mfaKey });
	}

	disableTOTPWithRecoveryKey(recoveryKey: string) {
		return this.apiService.postToApi(totpUrl + 'disableWithBackup', { token: recoveryKey });
	}
}
