import { apiToFrontend } from './transformer';
import { RegistrationFrontendModel } from './frontendModel';
import { RegistrationUnderlyingTypeModel } from '#/models/transaction/registrationUnderlyingType.model';

export class RegistrationApiModel {
	id?: string;
	description?: string;
	administration?: string;
	routing?: {
		route: string;
		googleMapsRouteDistanceMeters?: number;
		locations: Array<{
			title: string;
			address: string;
			lat?: number;
			lng?: number;
		}>;
	};
	money: {
		amount: number;
		currency: string;
	};
	category?: string;
	dates?: Array<string>; // YMD
	transportationType?: string;
	transactionInterface: string;
	user?: string;
	// this type is necessary to differentiate registrations from travel expenses on registrations dashboards
	underlyingType: RegistrationUnderlyingTypeModel;
	distanceTotalInKM: number;
	registrationDate: string; // YMD
	constructor(data?: any) {
		Object.assign(this, data);
	}

	public transformToFrontend(): RegistrationFrontendModel {
		return apiToFrontend(this, RegistrationFrontendModel);
	}
}
