<app-button
	*ngIf="duplicateMessages.length > 0"
	[ngbTooltip]="duplicateMessagesTemplate"
	[hasBorder]="false"
	size="small"
	title="{{ 'Open duplicate receipt' | translate }}"
	(click)="openFirstDuplicate()"
>
	<i class="ti-alert"></i>
</app-button>

<ng-template #duplicateMessagesTemplate>
	<div *ngFor="let duplicateMessage of duplicateMessages">
		{{ duplicateMessage.message }}
	</div>
</ng-template>
