import { NgModule } from '@angular/core';
import { UiModule } from '~/app/shared/ui/ui.module';
import { CommonModule } from '@angular/common';
import { ModalBodyDirective, ModalComponent, ModalFooterDirective, ModalHeaderDirective } from '~/app/shared/ui/modal/modal.component';
import { BaseModalComponent } from '~/app/shared/ui/modal/base-modal.component';
import { DismissChangesComponent } from './generic/dismiss-changes/dismiss-changes.component';

@NgModule({
	imports: [UiModule, CommonModule],
	declarations: [
		ModalComponent,
		ModalHeaderDirective,
		ModalBodyDirective,
		ModalFooterDirective,
		BaseModalComponent,
		DismissChangesComponent,
	],
	exports: [ModalComponent, ModalHeaderDirective, ModalBodyDirective, ModalFooterDirective, BaseModalComponent, DismissChangesComponent],
})
export class ModalModule {}
