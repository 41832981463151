import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { TagStat } from '~/app/pages/authenticated/statistics/models/stats';
import { Router } from '@angular/router';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { TagsEditModalComponent } from '~/app/modules/tag/components/tags-edit-modal/tags-edit-modal.component';
import { ModalService } from '~/app/services/modal.service';
import { User } from '#/models/user/user.model';
import { Store } from '@ngrx/store';
import { AppState } from '~/app/reducers';
import { Company } from '#/models/company/company.model';
import { bsDropdownToggle } from '~/app/helpers/bootstrapHelpers';
import { ReceiptListAPIRequest, ReceiptsExportRequest } from '#/models/transaction/receipt';
import { ReceiptService } from '#/services/transaction/receipt.service';
import { isValueSet } from '#/util/values';
import { ExportRequest } from '#/models/company/exporterInterfaces';

@Component({
	selector: 'app-tags-table',
	templateUrl: './tags-table.component.html',
})
export class TagsTableComponent implements OnInit, OnDestroy {
	@Input() tags: TagStat[];
	@Input() checkboxes: {};
	@Input() checkAll: { checked: boolean };

	@ViewChild('exportModal') exportModal: ElementRef;
	public exportIds: Array<string>;

	protected destroyCallbacks = [];
	protected user: User;
	public company: Company;
	public exportPromise: Promise<string>;

	protected previousClickedCheckbox = null;

	constructor(
		private store: Store<AppState>,
		private router: Router,
		private modalService: ModalService,
		private receiptAPIService: ReceiptService,
	) {}

	ngOnInit() {
		const subscription = this.store.select('user').subscribe((val) => {
			this.user = val.currentUser;
		});
		const companySubscription = this.store.select('company').subscribe((val) => {
			this.company = val.company;
		});
		this.destroyCallbacks.push(() => {
			subscription.unsubscribe();
			companySubscription.unsubscribe();
		});
	}

	ngOnDestroy(): void {
		this.destroyCallbacks.forEach((cb) => cb());
	}

	tableActionRename(event) {
		const tagId = Object.keys(this.checkboxes).filter((checkbox) => this.checkboxes[checkbox])[0];
		const tag = this.tags.filter((tg) => tg.getID() === tagId)[0];
		const modalRef = this.modalService.open(TagsEditModalComponent);
		modalRef.componentInstance.tag = tag;
		this.setupTagsModal(modalRef, true);
		return false;
	}

	async tableActionRetractExpenses(event) {
		const { ExpensesModalComponent } = await import('~/app/modules/generic/components/modals/expenses-modal/expenses-modal.component');

		const modalRef = this.modalService.open(ExpensesModalComponent);
		modalRef.componentInstance.type = 'tag';
		modalRef.componentInstance.action = 'retract';
		this.setupTagsModal(modalRef);
		return false;
	}

	async tableActionSubmitExpenses(event) {
		const { ExpensesModalComponent } = await import('~/app/modules/generic/components/modals/expenses-modal/expenses-modal.component');

		const modalRef = this.modalService.open(ExpensesModalComponent);
		modalRef.componentInstance.type = 'tag';
		modalRef.componentInstance.action = 'submit';
		this.setupTagsModal(modalRef);
		return false;
	}

	async tableActionDelete(event) {
		const { DeleteModalComponent } = await import('~/app/modules/generic/components/modals/delete-modal/delete-modal.component');

		const modalRef = this.modalService.open(DeleteModalComponent);
		modalRef.componentInstance.type = 'tag';
		this.setupTagsModal(modalRef, true);
		return false;
	}

	setupTagsModal(modalRef: NgbModalRef, resetCheckboxes = false) {
		modalRef.componentInstance.checkboxes = this.checkboxes;
		modalRef.componentInstance.user = this.user;

		let doneSubscription = modalRef.componentInstance.done.subscribe(() => {
			if (resetCheckboxes) {
				this.checkAll.checked = false;
				for (const checkboxesKey in this.checkboxes) {
					delete this.checkboxes[checkboxesKey];
				}
			}
		});

		modalRef.result
			.then((reason) => {
				doneSubscription.unsubscribe();
				doneSubscription = null;
			})
			.catch((reason) => {});

		this.destroyCallbacks.push(() => {
			if (doneSubscription) {
				doneSubscription.unsubscribe();
			}
		});
	}

	changedCheckAll(event) {
		this.tags.forEach((tag) => {
			this.checkboxes[tag.id] = this.checkAll.checked;
		});
		this.toggleActionDropDown();
	}

	getSelectedTagsCount(): number {
		return Object.keys(this.checkboxes).filter((checkbox) => this.checkboxes[checkbox]).length;
	}

	navigateToTag(event, tag: TagStat) {
		const el = event.target as Element;
		if (!!el.closest('td.no-click') || !!el.closest('div.no-click')) {
			return false;
		}

		const uri = (window as any).encodeURIComponent(tag.getTag());
		this.router.navigate([`/dashboard/tag/${uri}`]);
	}

	checkBoxClicked($event) {
		if ($event.event.ctrlKey && this.previousClickedCheckbox) {
			let seenFirstCheckbox = false;
			let seenLastCheckbox = false;
			this.tags.forEach((receipt) => {
				if (seenFirstCheckbox && !seenLastCheckbox) {
					this.checkboxes[receipt.id] = this.checkboxes[this.previousClickedCheckbox];
				}

				if (receipt.id === this.previousClickedCheckbox) {
					seenFirstCheckbox = true;
				}

				if (receipt.id === $event.id) {
					seenLastCheckbox = true;
				}
			});
		}
		this.previousClickedCheckbox = $event.id;
		this.toggleActionDropDown();
	}

	private toggleActionDropDown(): void {
		setTimeout(() => {
			const toggle = document.querySelector('.table-header-button.dropdown-toggle');
			if (toggle?.getAttribute('aria-expanded') === 'false' && Object.keys(this.checkboxes).length > 0) {
				bsDropdownToggle(toggle);
			}
		}, 100);
	}

	closeExportModal() {
		this.modalService.close(this.exportModal);
	}

	async openExportModal(tagId: string) {
		const receiptIds = await this.getReceiptsByTag(tagId);
		this.exportIds = receiptIds;
		this.modalService.open(this.exportModal);
	}

	async openExportModalBySelectedCheckboxes() {
		const tagIds: Array<string> = Object.entries(this.checkboxes)
			.map(([key, value]) => (value ? key : null))
			.filter((e) => isValueSet(e));
		const allTagsPromise: Array<Promise<Array<string>>> = tagIds.map((tagId) => this.getReceiptsByTag(tagId));
		const tagsWithIds: Array<Array<string>> = await Promise.all(allTagsPromise);
		// @ts-ignore typescript doesnt seem to know 'flat' exists for arrays?
		const allTagsItemIds = tagsWithIds.flat();
		this.exportIds = allTagsItemIds;
		this.modalService.open(this.exportModal);
	}

	private async getReceiptsByTag(tag: string): Promise<Array<string>> {
		const filter: ReceiptListAPIRequest = new ReceiptListAPIRequest();
		filter.tag = tag;
		return this.receiptAPIService.getAllReceipts(filter).then((receipts) => receipts.map((e) => e.id));
	}

	processExport(event: ExportRequest) {
		const exportRequest: ExportRequest = event;
		exportRequest.email = exportRequest.email.filter(Boolean).join(',');
		this.exportPromise = this.receiptAPIService.exportReceipts(exportRequest as ReceiptsExportRequest).then((res) => res.data.url);
	}
}
