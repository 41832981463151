<div class="modal-header">
	<h4 class="modal-title" translate>Book report</h4>
	<button (click)="closeModal()" aria-label="Close" class="close" type="button">
		<span aria-hidden="true">&times;</span>
	</button>
</div>

<klp-form [formGroup]="expenseReportBookForm">
	<div class="fs-scroll-wrapper">
		<div class="modal-body">
			<klp-grid-container [fluid]="true">
				<klp-form-element [caption]="'Provider' | translate" direction="vertical">
					<app-form-integration-picker
						formControlName="provider"
						[autoSelectOnSingleResult]="true"
						[includeAAv2Options]="shouldIncludeAAV2Options()"
						[accountingBookingType]="AccountingBookingTypeEnum.EXPENSE_REPORT"
						[showClearCacheButton]="true"
						(ngModelChange)="updateAAv2Fields()"
						[innerValueChangeInterceptor]="integrationChangeInterceptor"
					>
					</app-form-integration-picker>
				</klp-form-element>
				<div *ngIf="expenseReportBookForm.get('provider').value">
					<ng-container *ngIf="!getAAv2IntegrationId()">
						<klp-form-element [caption]="'Division' | translate" direction="vertical">
							<app-form-division-picker
								id="division"
								formControlName="division"
								[integrationId]="expenseReportBookForm.get('provider').value"
								[autoSelectOnSingleResult]="true"
							>
							</app-form-division-picker>
						</klp-form-element>
						<app-division-administration-warning
							[receiptDivisionId]="expenseReportBookForm.get('division').value"
							[klippaAdministrationId]="report.administration"
						></app-division-administration-warning>
						<klp-form-element *ngIf="expenseReportBookForm.get('division').value" [caption]="'Journal' | translate" direction="vertical">
							<app-form-journal-picker
								formControlName="journal"
								[integrationId]="expenseReportBookForm.get('provider').value"
								[divisionId]="expenseReportBookForm.get('division').value"
								[autoSelectOnSingleResult]="true"
							>
							</app-form-journal-picker>
						</klp-form-element>
						<klp-form-element
							[caption]="'Relation' | translate"
							*ngIf="company.preferences.dm_relation_selector && expenseReportBookForm.get('division').value"
							direction="vertical"
						>
							<app-form-integration-relation-picker
								formControlName="relation"
								[integrationId]="expenseReportBookForm.get('provider').value"
								[divisionId]="expenseReportBookForm.get('division').value"
							>
							</app-form-integration-relation-picker>
						</klp-form-element>

						<klp-form-element
							[caption]="'Payment condition' | translate"
							*ngIf="company.preferences.dm_relation_selector && expenseReportBookForm.get('division').value && aav1UiCapabilities?.PaymentConditions"
							direction="vertical"
						>
							<app-form-payment-condition-picker
								formControlName="paymentCondition"
								[multiple]="false"
								[integrationId]="expenseReportBookForm.get('provider').value"
								[divisionId]="expenseReportBookForm.get('division').value"
							></app-form-payment-condition-picker>
						</klp-form-element>

						<klp-form-element [caption]="'Booking date' | translate" direction="vertical">
							<klp-form-date-picker formControlName="bookingDate" [placeholder]="'Date of completing the booking' | translate">
							</klp-form-date-picker>
						</klp-form-element>
					</ng-container>

					<ng-container *ngIf="getAAv2IntegrationId()">
						<ngx-async-template [promise]="uiFieldForIntegrationPromise" [successStateOnReload]="true">
							<ng-template ngx-async-success let-uiFields="value">
								<klp-sub-form [injectInto]="expenseReportBookForm" at="accountingHeaders">
									<app-integration-input-fields
										[integrationId]="getAAv2IntegrationId()"
										[parameters]="uiFields"
										[onlyShowForBookingFieldLevel]="BookingFieldLevelEnum.COLLECTION"
										[accountingBookingType]="AccountingBookingTypeEnum.EXPENSE_REPORT"
										[getReportContextFn]="getReportContext"
										[getReceiptContextFn]="getReceiptContext"
										(onCreateButtonClicked)="createAAv2Entity($event)"
									>
									</app-integration-input-fields>
								</klp-sub-form>
							</ng-template>
						</ngx-async-template>
					</ng-container>
				</div>
				<app-warning-text *ngIf="isEdit" translate
					>Note: if you change the booking settings of a report, you will need to rebook all items again.</app-warning-text
				>
			</klp-grid-container>
		</div>
	</div>
	<div class="modal-footer">
		<app-button aria-haspopup="true" aria-expanded="false" (click)="closeModal()">
			<span translate>Cancel</span>
		</app-button>
		<klp-form-submit-button [submitCallback]="bookReport">
			<span translate>Save</span>
		</klp-form-submit-button>
	</div>
</klp-form>

<app-aav2-entity-creation-modal
	#aav2EntityCreationModal
	[integrationId]="getAAv2IntegrationId()"
	[accountingBookingType]="AccountingBookingTypeEnum.EXPENSE_REPORT"
></app-aav2-entity-creation-modal>
