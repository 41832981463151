import { NgModule } from '@angular/core';
import { CompanyGroupPickerComponent } from './company-group-picker/company-group-picker.component';
import { CompanyGeneralLedgerPickerComponent } from './company-general-ledger-picker/company-general-ledger-picker.component';
import { CompanyVatCodePickerDeprecatedComponent } from '~/app/modules/company/components/ui/company-vat-code-picker-deprecated/company-vat-code-picker-deprecated.component';
import { IntegrationCustomRelationField } from './integration-custom-relation-field/integration-custom-relation-field.component';
import { CompanyCategoryPickerComponent } from './company-category-picker/company-category-picker.component';
import { CompanyPaymentMethodPickerComponent } from './company-payment-method-picker/company-payment-method-picker.component';
import { CompanyUserSortDeprecatedComponent } from './company-user-sort-deprecated/company-user-sort-deprecated.component';
import { CompanyConfigurationFieldSelectOptionsPickerComponent } from './company-configuration-field-select-options-picker/company-configuration-field-select-options-picker.component';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { UiModule } from '~/app/shared/ui/ui.module';
import { NgbAlertModule, NgbDatepickerModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { TranslateModule } from '@ngx-translate/core';
import { DirectivesModule } from '~/app/directives/directives.module';
import { SortablejsModule } from 'ngx-sortablejs';
import { PipesModule } from '~/app/helpers/pipes/pipes.module';
import { CompanyLabelComponent } from '~/app/modules/company/components/ui/company-label/company-label.component';
import { CompanyTargetPickerComponent } from './company-target-picker/company-target-picker.component';

@NgModule({
	imports: [
		RouterModule,
		CommonModule,
		FormsModule,
		UiModule,
		NgbAlertModule,
		NgbDatepickerModule,
		NgbTooltipModule,
		NgSelectModule,
		TranslateModule,
		ReactiveFormsModule,
		DirectivesModule,
		SortablejsModule,
		CommonModule,
		PipesModule,
	],
	declarations: [
		CompanyPaymentMethodPickerComponent,
		CompanyUserSortDeprecatedComponent,
		CompanyCategoryPickerComponent,
		CompanyGeneralLedgerPickerComponent,
		CompanyVatCodePickerDeprecatedComponent,
		IntegrationCustomRelationField,
		CompanyGroupPickerComponent,
		CompanyConfigurationFieldSelectOptionsPickerComponent,
		CompanyLabelComponent,
		CompanyTargetPickerComponent,
	],
	exports: [
		CompanyGeneralLedgerPickerComponent,
		CompanyVatCodePickerDeprecatedComponent,
		IntegrationCustomRelationField,
		CompanyCategoryPickerComponent,
		CompanyPaymentMethodPickerComponent,
		CompanyUserSortDeprecatedComponent,
		CompanyGroupPickerComponent,
		CompanyConfigurationFieldSelectOptionsPickerComponent,
		CompanyLabelComponent,
		CompanyTargetPickerComponent,
	],
	providers: [],
})
export class CompanyUI {}
