<div class="row py-5">
	<div class="col-4 offset-4">
		<div class="card text-center">
			<h5 class="card-header" translate>Connecting provider</h5>
			<div class="card-body">
				<app-loader-deprecated></app-loader-deprecated>
			</div>
			<div class="card-footer">
				<img class="img-responsive inline-block" src="/assets/images/logo/logo-{{ theme }}.png" width="100" alt="" />
			</div>
		</div>
	</div>
</div>
