import { Component } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Company } from '#/models/company/company.model';
import { CompanyService } from '#/services/company/company.service';
import { CompanyApiService } from '#/services/company/company-api.service';
import { ImportCreditcardFileData } from '#/models/company/import-creditcard-file.model';
import { NotificationService } from '~/app/services/notification.service';
import { TranslateService } from '@ngx-translate/core';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';

@Component({
	selector: 'app-company-import-creditcard-files',
	templateUrl: './company-import-creditcard-files.component.html',
	styleUrls: ['./company-import-creditcard-files.component.scss'],
})
export class CompanyImportCreditcardFilesComponent {
	constructor(
		private formBuilder: UntypedFormBuilder,
		private companyService: CompanyService,
		private companyApiService: CompanyApiService,
		private notificationService: NotificationService,
		private translationService: TranslateService,
	) {}

	public company: Company;
	public importResultsPromise: Promise<ImportCreditcardFileData>;
	public importCreditcardFileForm: UntypedFormGroup = this.formBuilder.group({
		file: [null, Validators.required],
	});

	ngOnInit(): void {
		this.company = this.companyService.getCompanyOfLoggedUser();
	}

	public sendForm = (formData): Promise<void> => {
		this.importResultsPromise = this.companyApiService.uploadCreditcardFile(this.company.id, formData.file);
		return this.importResultsPromise
			.then(() => {
				this.notificationService.success(this.translationService.instant(_('Successfully imported creditcard file.')));
			})
			.catch((e) => {
				const message = this.translationService.instant(_('Could not import creditcard file.'));
				this.notificationService.error(message);
			});
	};

	public downloadExceptionReport(downloadUrl: string): void {
		window.open(downloadUrl);
	}

	public getTotalNumberOfLines(importCreditcardFileData: ImportCreditcardFileData): number {
		return importCreditcardFileData.successes.amount + importCreditcardFileData.failures.amount;
	}

	public getNumberOfLinesNotChanged(importCreditcardFileData: ImportCreditcardFileData): number {
		return importCreditcardFileData.successes.amount - importCreditcardFileData.created - importCreditcardFileData.updated;
	}
}
