import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { ColumnsInterface } from '~/app/shared/ui/table/table';
import { SelectActionEnum, SelectActionInterface } from '~/app/shared/ui/select-action/select-action';
import { Injectable } from '@angular/core';
import { ActionsMenuInterface } from '~/app/shared/ui/actions-menu/actions-menu';
import { FilterEnum } from '~/app/shared/ui/filters/filter';
import { TranslateService } from '@ngx-translate/core';
import { ExpenseReportsService } from '#/services/transaction/expense-reports.service';
import { ExpenseReport } from '#/models/transaction/expense-reports';

@Injectable({
	providedIn: 'root',
})
export class ColumnService {
	constructor(private translate: TranslateService) {}

	private columns: ColumnsInterface[] = [
		{
			name: this.translate.instant(_('Selection')),
			type: 'selector',
			enabled: true,
			required: true,
			position: 'pre',
			sortable: false,
			id: 'selection',
			sortingProperty: null,
		},
		{
			name: this.translate.instant(_('Description')),
			slug: 'description',
			type: 'default',
			link_prefix: 'reports',
			link_slug: '_id',
			enabled: true,
			sortable: true,
			id: 'description',
			sortingProperty: 'description',
		},
		{
			name: this.translate.instant(_('Date range')),
			slug_start: 'start_date',
			slug_end: 'end_date',
			type: 'date-range',
			enabled: true,
			sortable: true,
			id: 'date_range',
			sortingProperty: 'start_date',
		},
		{
			name: this.translate.instant(_('Administration')),
			slug: 'administration',
			type: 'administration',
			enabled: true,
			sortable: true,
			id: 'administration',
			sortingProperty: 'administration',
		},
		{
			name: this.translate.instant(_('Cost center')),
			slug: 'cost_center',
			type: 'cost_center',
			enabled: true,
			sortable: true,
			id: 'cost_center',
			sortingProperty: 'costcenter',
		},
		{
			name: this.translate.instant(_('Cost unit')),
			slug: 'cost_unit',
			type: 'cost_unit',
			enabled: true,
			sortable: true,
			id: 'cost_unit',
			sortingProperty: 'costunit',
		},
		{
			name: this.translate.instant(_('Project')),
			slug: 'project',
			type: 'project',
			enabled: true,
			sortable: true,
			id: 'project',
			sortingProperty: 'project',
		},
		{
			name: this.translate.instant(_('Amount')),
			slug: 'amount',
			currency_slug: 'currency',
			type: 'amount',
			enabled: true,
			sortable: true,
			id: 'amount',
			sortingProperty: 'amount',
		},
		{
			name: this.translate.instant(_('Items')),
			slug: 'receipt_count',
			type: 'default',
			enabled: true,
			sortable: true,
			id: 'receipt_count',
			sortingProperty: 'receipt_count',
		},
		{
			name: this.translate.instant(_('Expense status')),
			slug: 'status_to_show',
			type: 'status_to_show',
			enabled: true,
			sortable: true,
			id: 'status_to_show',
			sortingProperty: 'status',
		},
		{
			name: this.translate.instant(_('Create date')),
			slug: 'createdate',
			type: 'date',
			enabled: false,
			sortable: true,
			id: 'createdate',
			sortingProperty: 'createdate',
		},
		{
			name: this.translate.instant(_('Update date')),
			slug: 'updatedate',
			type: 'date',
			enabled: false,
			sortable: true,
			id: 'updatedate',
			sortingProperty: 'updatedate',
		},
		{
			name: this.translate.instant(_('Booking status')),
			booking_data_slug: 'booking_data',
			type: 'booking_status',
			declaration_status_slug: 'status',
			enabled: false,
			sortable: true,
			id: 'booking_status',
			sortingProperty: 'booked_on',
		},
		{
			name: this.translate.instant(_('Settings')),
			type: 'column_config',
			enabled: true,
			required: true,
			position: 'end',
			sortable: false,
			id: 'settings',
			sortingProperty: null,
		},
	];

	getColumns(): ColumnsInterface[] {
		return this.columns;
	}
}

@Injectable({
	providedIn: 'root',
})
export class ActionService {
	constructor(private translate: TranslateService, private expenseReportsService: ExpenseReportsService) {}

	getSelectActions(report: ExpenseReport): SelectActionInterface[] {
		const result = [];
		if (this.expenseReportsService.canEditReport(report)) {
			result.push({
				title: this.translate.instant(_('Edit')),
				action: SelectActionEnum.edit,
				multiple: false,
			});
		}

		result.push({
			title: this.translate.instant(_('Export')),
			action: SelectActionEnum.export,
			multiple: true,
		});
		return result;
	}

	getRowActions(report: ExpenseReport): ActionsMenuInterface[] {
		const result = [];
		if (this.expenseReportsService.canEditReport(report)) {
			result.push({
				title: this.translate.instant(_('Edit')),
				action: SelectActionEnum.edit,
				multiple: false,
			});
			result.push({
				title: this.translate.instant(_('Export')),
				action: SelectActionEnum.export,
				multiple: false,
			});
		}
		return result;
	}
}

@Injectable({
	providedIn: 'root',
})
export class FilterService {
	getFilterOptions(): FilterEnum[] {
		return [FilterEnum.search, FilterEnum.period, FilterEnum.since, FilterEnum.before, FilterEnum.statusses];
	}
}
