<div class="modal-header">
	<h4 class="modal-title" translate>Edit authorization flow</h4>
	<button (click)="closeModal()" aria-label="Close" class="close" type="button">
		<span aria-hidden="true">&times;</span>
	</button>
</div>

<div class="modal-body">
	<div class="modal-row" *ngIf="canSetAuthorizationFlow()">
		<label class="rowLabel" for="auth_flow" translate>Authorization flow</label>
		<app-company-authorization-flow-picker [(ngModel)]="authFlowId" [allowCustom]="true"> </app-company-authorization-flow-picker>
	</div>
	<ng-container *ngIf="authFlowId === emptyId">
		<div class="modal-row">
			<app-toggleable-setting
				[(ngModel)]="isAuthorizationFlowApproverOrderRequired"
				caption="{{ 'Fixed approval order' | translate }}"
				tooltip="{{ 'When this option is enabled the authorization flow will make sure approvers will approve in the selected order.' | translate }}"
			>
			</app-toggleable-setting>
		</div>

		<div class="modal-row">
			<label class="rowLabel" for="approvers" translate>Approvers</label>
			<div>
				<app-deprecated-company-user-picker
					[filterApproverRoles]="true"
					[(companyUsers)]="authorizationFlowApprovers"
					(companyUsersChange)="onAuthorizationFlowApproversChange()"
				></app-deprecated-company-user-picker>
			</div>
		</div>

		<div
			class="modal-row"
			*ngIf="!isAuthorizationFlowApproverOrderRequired && authorizationFlowApprovers && authorizationFlowApprovers.length > 0"
		>
			<label class="rowLabel" for="require_approver_count">
				<span translate>Required amount of approvers</span>
				<app-info-tooltip
					placement="right"
					title="{{'When this count is edited, only the configured amount of people have to approve instead of all configured approvers.' | translate }}"
				></app-info-tooltip>
			</label>
			<div>
				<klp-form-number-input name="approver_count" [(ngModel)]="authorizationFlowRequireApproverCount"> </klp-form-number-input>
			</div>
		</div>
		<div class="modal-row" *ngIf="authFlowId && expenseReport.authorization_flow">
			<strong class="rowLabel" translate>Warning!</strong>
			<div translate>
				It is possible that the selected users do not have access to all expenses and invoices, so be careful when applying this
				authorization flow.
			</div>
		</div>
	</ng-container>
</div>

<div class="modal-footer">
	<app-button aria-haspopup="true" aria-expanded="false" (click)="closeModal()">
		<span translate>Cancel</span>
	</app-button>
	<app-button variant="greenFilled" aria-haspopup="true" aria-expanded="false" (click)="saveAuthFlow()">
		<span translate>Save</span>
	</app-button>
</div>
