export enum LanguagesSupported {
	EN_GB = 'en-gb',
	EN_US = 'en-us',
	NL = 'nl-nl',
	DE = 'de-de',
	FR = 'fr-fr',
	ES = 'es-es',
	PT = 'pt-pt',
	GR = 'el-gr',
}
