import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CompanyUI } from '~/app/modules/company/components/ui/ui.module';
import { PipesModule } from '~/app/helpers/pipes/pipes.module';
import { UiModule } from '~/app/shared/ui/ui.module';
import { DataTablesModule } from 'angular-datatables';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
	imports: [CommonModule, CompanyUI, PipesModule, UiModule, DataTablesModule, FormsModule, TranslateModule],
})
export class ExpenseReportsModule {}
