<klp-grid-container [fluid]="true">
	<app-nav-and-cta>
		<klp-gretel-bar></klp-gretel-bar>
		<div class="headerActions">
			<app-button
				*ngIf="transactionInterfaces?.length === 1"
				variant="greenFilled"
				(click)="goToNewRegistration(transactionInterfaces[0].id)"
			>
				<app-icon variant="plus" color="white"></app-icon>
				{{ transactionInterfaces[0].menuCaption }}
			</app-button>
			<app-button
				*ngIf="transactionInterfaces === undefined || transactionInterfaces?.length > 1"
				data-cy="btnAddReceiptDropdown"
				variant="greenFilled"
				id="addDropdown"
				data-toggle="dropdown"
				aria-haspopup="true"
				aria-expanded="false"
			>
				<app-icon variant="plus" color="white"></app-icon>
				{{ 'Add' | translate }}
			</app-button>
			<div class="dropdown-menu line-height dropdown-menu-right dashboard-element-add-dropdown" aria-labelledby="addDropdown">
				<div *ngIf="transactionInterfaces !== undefined && transactionInterfaces?.length >= 0">
					<a
						*ngFor="let transactionInterface of transactionInterfaces"
						class="dropdown-item"
						(click)="goToNewRegistration(transactionInterface.id)"
					>
						{{ transactionInterface.menuCaption }}
					</a>
				</div>
			</div>
		</div>
	</app-nav-and-cta>
	<app-manage-registrations-data-table
		(onExportRegistrations)="exportRegistrations($event)"
		(onRowClicked)="goToTransaction($event)"
	></app-manage-registrations-data-table>
</klp-grid-container>

<app-export-form-modal #exportFormModal [exportType]="exportType" [exportIds]="exportIds"> </app-export-form-modal>
