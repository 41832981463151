<klp-form-select
	[options]="paymentMethods"
	[(ngModel)]="innerValue"
	(ngModelChange)="setInnerValueAndNotify($event)"
	[placeholder]="getPlaceholderTextFn()"
	[ngClass]="{ showErrors: isInErrorState() }"
	[multiple]="multiple"
	[clearable]="true"
	[disabled]="disabled"
>
</klp-form-select>
