import { Pipe, PipeTransform } from '@angular/core';
import { ExportType } from '#/models/company/exporterInterfaces';
import { CompanyService } from '#/services/company/company.service';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
	name: 'exportModuleLabel',
})
export class ExportModuleLabelPipe implements PipeTransform {
	constructor(private companyService: CompanyService, private translate: TranslateService) {}

	getReceiptName(): string {
		const isAdministrationOffice = this.companyService.getCompanyOfLoggedUser()?.isadministrationoffice;
		const usesExpenses = this.companyService.getCompanyOfLoggedUser()?.canUseExpenses();
		const usesInvoices = this.companyService.getCompanyOfLoggedUser()?.canUseInvoices();

		if (isAdministrationOffice) {
			return this.translate.instant(_('Documents'));
		} else if (usesExpenses && usesInvoices) {
			return this.translate.instant(_('Expenses')) + ' / ' + this.translate.instant(_('Invoices'));
		} else if (usesExpenses) {
			return this.translate.instant(_('Expenses'));
		} else if (usesInvoices) {
			return this.translate.instant(_('Invoices'));
		} else {
			// fallback (used for admins that have no company)
			return this.translate.instant(_('Expenses')) + ' / ' + this.translate.instant(_('Invoices'));
		}
	}

	transform(value: ExportType): string {
		switch (value) {
			case ExportType.RECEIPT:
				return this.getReceiptName();
			case ExportType.BOOKING:
				return this.translate.instant(_('Bookkeeping integration'));
			case ExportType.CREDITCARD:
				return this.translate.instant(_('Credit card'));
			case ExportType.REPORT:
				return this.translate.instant(_('Report'));
		}
		return value;
	}
}
