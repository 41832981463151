import { Component, OnInit } from '@angular/core';
import { AuthenticatedComponent } from '../authenticated.component';
import { select } from '@ngrx/store';
import * as fromTags from '../../../modules/tag/models/tag.reducer';
import { Observable } from 'rxjs';
import { TagStat } from '../statistics/models/stats';

@Component({
	templateUrl: 'tags.html',
})
export class TagsComponent extends AuthenticatedComponent implements OnInit {
	tags: Observable<TagStat[]>;
	checkboxes: {};
	checkAll: { checked: boolean };
	initialLoaded = false;

	constructor() {
		super();
		this.tags = this.store.select('tags').pipe(select(fromTags.selectAllTags));
		this.checkboxes = {};
		this.initialLoaded = true;
		this.checkAll = { checked: false };
	}

	ngOnInit(): void {
		super.ngOnInit();
	}

	changedCheckAll(event) {
		this.tags.forEach((tags) => {
			tags.forEach((tag) => {
				this.checkboxes[tag.id] = this.checkAll;
			});
		});
	}

	getSelectedTagsCount(): number {
		return Object.keys(this.checkboxes).filter((checkbox) => this.checkboxes[checkbox]).length;
	}
}
