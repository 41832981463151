import { Inject, Injectable } from '@angular/core';
import { NotificationService } from '../../services/notification.service';
import { DOCUMENT } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Errors } from '../errors.enum';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { TranslateService } from '@ngx-translate/core';
import { NotificationConfig } from '~/app/shared/ui/toast-notification/toast-notifications-manager/toast-notifications-manager.component';
import apiErrorCodes, { errorsAsInfo, errorsAsWarning } from '#/models/utils/api-error-codes';
import { stringIsSetAndFilled } from '#/util/values';

@Injectable({
	providedIn: 'root',
})
export class APINotificationsService {
	constructor(private notificationService: NotificationService, @Inject(DOCUMENT) private document, public translate: TranslateService) {}

	errorConfig(APIResult: any, timeout: number = 5000): NotificationConfig {
		return {
			bodyMaxLength: 400,
			timeout: timeout,
			buttons: [
				{
					text: this.translate.instant(_('Contact support')),
					action: (closeHandler) => {
						closeHandler();
						this.contactSupport(APIResult);
					},
				},
			],
		};
	}

	getValidationErrors(APIResult: any, html: boolean = false): string {
		const errors = APIResult?.error?.errors ?? [];
		const messages = [];
		errors.forEach((error) => {
			if (error.fieldNames) {
				error.fieldNames.forEach((fieldName) => {
					messages.push(
						error.classification === 'RequiredError' ? `Field ${fieldName} is required.` : `Field ${fieldName}: ${error.message}.`,
					);
				});
			} else {
				messages.push(error.classification + ': ' + error.message);
			}
		});

		return messages.join('\n');
	}

	private contactSupport(APIResult: HttpErrorResponse): void {
		const hideCrisp = !environment?.show_crisp_chat ?? false;
		if (hideCrisp) {
			return this.openHelpDesk();
		}
		return this.openCrisp(APIResult);
	}

	private openCrisp(APIResult: HttpErrorResponse): void {
		const newline = '\n';
		let mail_body = 'Request information:' + newline;
		const newLine = (data: string) => {
			mail_body += data + newline;
		};

		if (!APIResult.error || typeof APIResult.error !== 'object') {
			newLine('Error message: ' + JSON.stringify(APIResult));
		} else {
			newLine('URL: ' + APIResult.url);
			newLine('Response code: ' + APIResult.status);

			if (typeof APIResult.error === 'object') {
				newLine('Request ID: ' + APIResult.error.request_id);
				newLine('Error code: ' + APIResult.error.code);

				if (typeof APIResult.error.message === 'string') {
					newLine('Error message: ' + APIResult.error.message);
				}

				if (APIResult.error.code === Errors.IntegrationsCallErrorWithData) {
					newLine('Extra Error message: ' + APIResult.error.data.Message);
				}

				if (APIResult.error.code === Errors.InputValidationError) {
					newLine('Validation errors: ' + this.getValidationErrors(APIResult, true));
				}
			}
		}

		newLine(newline);
		newLine('Extra information:');

		if ((<any>window).$crisp) {
			(<any>window).$crisp.push(['do', 'chat:open']);
			(<any>window).$crisp.push(['set', 'message:text', [mail_body]]);
		}
	}

	private openHelpDesk(): void {
		const helpdeskUrl: string = environment.feature_flags.new_helpdesk_url;
		if (stringIsSetAndFilled(helpdeskUrl)) {
			window.open(helpdeskUrl, '_blank');
			return;
		}
		window.open('https://help.klippa.com', '_blank');
	}

	getTranslatedApiErrorMessage(errorCode: number) {
		const translationKey = apiErrorCodes[errorCode];
		if (translationKey) {
			return this.translate.instant(apiErrorCodes[errorCode]);
		}
		if (environment.production) {
			// we fall back to a standard 'something went wrong' error on production if we dont have a definition of the error
			const fallbackErrorCode = 0;
			return this.translate.instant(apiErrorCodes[fallbackErrorCode]);
		} else {
			throw new Error(`No translation found for errorCode ${errorCode}. You need to add it!`);
		}
	}

	handleAPIError(APIResult: any) {
		if (typeof APIResult.error !== 'object') {
			this.notificationService.error(this.translate.instant(_('Something went wrong, please try again.')), this.errorConfig(APIResult));
			return;
		}

		// When a code is mapped to null, ignore this error.
		if (apiErrorCodes[APIResult.error.code] === null) {
			return true;
		} else if (apiErrorCodes[APIResult.error.code]) {
			if (errorsAsInfo.includes(APIResult.error.code)) {
				return this.notificationService.info(this.translate.instant(apiErrorCodes[APIResult.error.code]));
			}
			if (errorsAsWarning.includes(APIResult.error.code)) {
				return this.notificationService.warning(this.translate.instant(apiErrorCodes[APIResult.error.code]));
			}
			return this.notificationService.error(this.translate.instant(apiErrorCodes[APIResult.error.code]), this.errorConfig(APIResult));
		}

		if (APIResult.error.code === Errors.ReceiptBookErrorWithDescription) {
			return this.notificationService.error(APIResult.error.message, this.errorConfig(APIResult));
		}
		if (APIResult.error.code === Errors.ReceiptBookAAv2ErrorWithDescription) {
			return this.notificationService.error(APIResult.error.message, this.errorConfig(APIResult));
		}

		if (APIResult.error.code === Errors.IntegrationsCallErrorWithData) {
			return this.notificationService.error(APIResult.error.data.Message, this.errorConfig(APIResult));
		}

		if (APIResult.error.code === Errors.InputValidationError) {
			return this.notificationService.error(
				this.translate.instant(_('Form validation error')) + ': ' + this.getValidationErrors(APIResult),
				this.errorConfig(APIResult),
			);
		}

		if (
			APIResult.error.code === Errors.ErrorCodeCreditcardStatementCreateError ||
			APIResult.error.code === Errors.CodeCreditcardStatementNoLinesError
		) {
			return this.notificationService.error(
				this.translate.instant(
					_(
						'We were not able to process your creditcard statement. Please contact support if you wish to have support for this creditcard statement added.',
					),
				),
				this.errorConfig(APIResult, 0),
			);
		}

		this.notificationService.error(this.translate.instant(_('Something went wrong, please try again.')), this.errorConfig(APIResult));
		return;
	}
}
