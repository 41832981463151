import { Component, Input, OnInit } from '@angular/core';
import { InboxItem, InboxItemType } from '../../models/inbox';
import { AuthenticatedComponent } from '~/app/pages/authenticated/authenticated.component';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { ConfirmModalComponent } from '~/app/shared/ui/confirm-modal/confirm-modal.component';
import { getExpenseLabel, Receipt } from '#/models/transaction/receipt';
import { importReceiptModalInBackground } from '~/app/modules/receipt/components/modals/lazy';
import { isValueSet, stringIsSetAndFilled } from '#/util/values';
import { CompanyFeatureFlagsService } from '#/services/company/company-feature-flags.service';
import { UserService } from '~/app/modules/user/user.service';
import { PossibleCompanyFeatureFlags } from '#/models/company/possible-feature-flags';
import { ExpenseReportsService } from '#/services/transaction/expense-reports.service';
import { ReportType } from '#/models/reportType.model';
import { TransactionEditorService } from '#/services/transaction/transaction-editor.service';

@Component({
	selector: 'app-inbox-table',
	templateUrl: './inbox-table.component.html',
})
export class InboxTableComponent extends AuthenticatedComponent implements OnInit {
	@Input()
	public inboxItems: InboxItem[];

	constructor(
		private userService: UserService,
		private companyFeatureFlagsService: CompanyFeatureFlagsService,
		private expenseReportService: ExpenseReportsService,
		private transactionEditorService: TransactionEditorService,
	) {
		super();
	}

	async navigateToReceipt(inboxItem: InboxItem) {
		/* Navigates to a receipt corresponding to an inbox item. */
		const receiptId = inboxItem.getMetaData().getReceipt();
		const flags = await this.companyFeatureFlagsService.getAllFeatureFlags(this.userService.getCurrentLoggedUser().company);
		if (flags.includes(PossibleCompanyFeatureFlags.TRANSACTION_INTERFACES)) {
			return this.navigateToTxiExpense(receiptId);
		}
		let receipt: Receipt;
		try {
			receipt = await this.receiptAPIService.getReceipt(receiptId);
		} catch (error) {
			return;
		}

		let tableType = 'personal';
		if (inboxItem.getType() === InboxItemType.AuthorizationFlowTurn) {
			tableType = 'expenses';
			if (inboxItem.getMetaData().receipt_is_invoice && inboxItem.getMetaData().receipt_is_invoice === '1') {
				tableType = 'invoices';
			}
		}

		if (tableType === 'personal') {
			this.receiptFlowService.initWithReceipt(receipt, this.user);
		} else {
			this.receiptFlowService.initWithPreset(null, this.user);
		}

		const { ReceiptEditModalComponent } = await import(
			'~/app/modules/receipt/components/modals/receipt-edit-modal/receipt-edit-modal.component'
		);
		const modalRef = this.modalService.open(ReceiptEditModalComponent, {
			size: 'lg',
			beforeDismiss: () => {
				if (modalRef.componentInstance.hasReceiptBeenChanged()) {
					return new Promise((resolve) => {
						const confirmModalRef = this.modalService.open(ConfirmModalComponent);
						confirmModalRef.componentInstance.type = 'text';
						confirmModalRef.componentInstance.title = this.translate.instant(_('Dismiss changes'));
						confirmModalRef.componentInstance.message = this.translate.instant(_('Are you sure that you want to dismiss your changes?'));
						const sub = confirmModalRef.componentInstance.result.subscribe((data) => {
							resolve(data.result);
							sub.unsubscribe();
						});
						this.destroyCallbacks.push(() => {
							sub.unsubscribe();
						});
					});
				}
			},
		});

		modalRef.componentInstance.receipt = receipt;
		modalRef.componentInstance.savedInReport = isValueSet(receipt.report);
		modalRef.componentInstance.user = this.user;
		modalRef.componentInstance.tableType = tableType;
	}

	ngOnInit() {
		super.ngOnInit();
		importReceiptModalInBackground();
	}

	navigateToGroup(inboxItem: InboxItem) {
		/* Navigates to a group corresponding to an inbox item. */
		const groupId = inboxItem.getMetaData().getGroup();
		this.router.navigate([`/dashboard/folder/${groupId}`]);
	}

	async openShareModal(inboxItem: InboxItem) {
		/* Open a share modal in which the user is able to accept / reject the shared item. */
		const { AcceptShareModalComponent } = await import('../modals/accept-share-modal/accept-share-modal.component');
		const modalRef = this.modalService.open(AcceptShareModalComponent);
		modalRef.componentInstance.inboxItem = inboxItem;
		return false;
	}

	public onClick(inboxItem: InboxItem): Promise<boolean> {
		/* Handles a clicked inbox item. */
		if (!inboxItem.isRead()) {
			this.inboxAPIService.markRead(inboxItem);
		}

		switch (inboxItem.getType()) {
			case InboxItemType.ReceiptOCRProcessed:
			case InboxItemType.ReceiptDeclarationUpdate:
			case InboxItemType.ReceiptMessage:
			case InboxItemType.ReceiptDeclined:
			case InboxItemType.AuthorizationFlowTurn:
				if (stringIsSetAndFilled(inboxItem.metadata.receipt)) {
					/* Notifications of processed OCR items or declaration update should navigate to the corresponding receipt. */
					this.navigateToReceipt(inboxItem);
				}
				break;
			case InboxItemType.ReportAuthorizationFlowTurn:
				if (stringIsSetAndFilled(inboxItem.metadata.report)) {
					this.navigateToManagedReport(inboxItem.metadata.report);
				}
				break;
			case InboxItemType.ReportMessage:
			case InboxItemType.ReportStatusChange:
			case InboxItemType.ReportCardSettledTransaction:
				if (stringIsSetAndFilled(inboxItem.metadata.report)) {
					this.navigateToMyReport(inboxItem.metadata.report);
				}
				break;
			case InboxItemType.ReceiptShare:
				/* Notifications of shared receipts should check the ACL of the receipt to check whether the user has accepted the share already. */
				this.receiptAPIService.getReceipt(inboxItem.getMetaData().getReceipt()).then((receipt) => {
					const accepted = receipt.getACL().some((a) => a.getEmail() === this.user.getEmail() && a.getAccepted());
					/* This share has not been accepted yet. Open the share modal. */
					if (!accepted) {
						this.openShareModal(inboxItem);
					} else {
						/* This receipt has already been accepted. Navigate to the receipt. */
						this.navigateToReceipt(inboxItem);
					}
				});
				break;
			case InboxItemType.GroupShare:
				/* Notifications of shared groups should check the ACL of the group to check whether the user has accepted the share already. */
				this.folderService.getFolder(inboxItem.getMetaData().getGroup()).then((group) => {
					const accepted = group.getACL().some((a) => a.getEmail() === this.user.getEmail() && a.getAccepted());
					/* This share has not been accepted yet. Open the share modal. */
					if (!accepted) {
						this.openShareModal(inboxItem);
					} else {
						/* This group has already been accepted. Navigate to the group. */
						this.navigateToGroup(inboxItem);
					}
				});
				break;
			case InboxItemType.ReportCardUnsettledTransaction:
				return this.navigateToCardTransaction(inboxItem.metadata.report, inboxItem.metadata.receipt);
			default:
				return this.router.navigate(['/dashboard']);
		}
	}

	private async navigateToMyReport(reportId: string): Promise<boolean> {
		if (this.company?.modules.cardReports.enabled) {
			const report = await this.expenseReportService.getExpenseReport(reportId);
			if (report.type === ReportType.CARD) {
				return this.router.navigate([`/reports/card/${reportId}`]);
			}
			return this.router.navigate([`/reports/out-of-pocket/${reportId}`]);
		}
		return this.router.navigate([`/reports/${reportId}`]);
	}

	private async navigateToCardTransaction(reportId: string, transactionId: string): Promise<boolean> {
		if (this.company?.modules.cardReports.enabled) {
			const report = await this.expenseReportService.getExpenseReport(reportId);
			if (report.type === ReportType.CARD) {
				return this.router.navigate([`/reports/card/${reportId}/tx/${transactionId}`]);
			}
			return this.router.navigate([`/reports/out-of-pocket/${reportId}/tx/${transactionId}`]);
		}
		return this.router.navigate([`/reports/${reportId}`]);
	}

	private async navigateToTxiExpense(transactionId: string): Promise<boolean> {
		const transaction = await this.transactionEditorService.getTransaction(transactionId);
		if (transaction.isInvoice) {
			// TODO navigating to the invoices using navigate was not working should find a way to use it here
			return this.router.navigateByUrl(`dm/invoices/all/tx/${transactionId}`);
		}
		return this.router.navigate([`/dashboard/tx/${transactionId}`]);
	}

	private async navigateToManagedReport(reportId: string): Promise<boolean> {
		if (this.company?.modules.cardReports.enabled) {
			const report = await this.expenseReportService.getExpenseReport(reportId);
			if (report.type === ReportType.CARD) {
				return this.router.navigate([`/manage-reports/card/todo/${reportId}`]);
			}
			return this.router.navigate([`/manage-reports/out-of-pocket/todo/${reportId}`]);
		}
		return this.router.navigate([`/manage-reports/todo/${reportId}`]);
	}

	statusToLabel(status) {
		const key = getExpenseLabel(status);
		return key ? this.translate.instant(key) : key;
	}
}
