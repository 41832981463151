import { Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { ReceiptListAPIRequest } from '#/models/transaction/receipt';
import { Period } from '#/models/period.model';
import { TranslateService } from '@ngx-translate/core';
import { FiltersService } from '~/app/shared/ui/filters/filters.service';
import { User } from '#/models/user/user.model';
import { AppState } from '~/app/reducers';
import { Store } from '@ngrx/store';
import { Company } from '#/models/company/company.model';
import { environment } from '~/environments/environment';
import { FilterEnum } from './filter';
import { isValidDateRange } from '#/util/dateRange';
import { LocalStorageService } from 'angular-2-local-storage';
import { isValueSet, stringIsSetAndFilled } from '#/util/values';
import { PaginatedExpenseReportsRequest } from '#/models/transaction/expense-reports';
import { convertDateToYMD } from '#/util/date';

@Component({
	selector: 'app-filters',
	templateUrl: './filters.component.html',
	styleUrls: ['./filters.component.scss'],
})
export class FiltersComponent implements OnInit {
	// Request classes that support the given filterOptions may be added to requestQuery and onSave.
	@Input() public filterOptions: FilterEnum[];
	@Input() public requestQuery: ReceiptListAPIRequest | PaginatedExpenseReportsRequest;
	@Input() public filtersLocalStorageKey: string;
	@Output() public onDismiss: EventEmitter<string> = new EventEmitter<string>();
	@Output()
	public onSave: EventEmitter<ReceiptListAPIRequest | PaginatedExpenseReportsRequest> = new EventEmitter<
		ReceiptListAPIRequest | PaginatedExpenseReportsRequest
	>();

	protected destroyCallbacks = [];
	public user: User;
	public company: Company;
	public showStats = false;
	public environment = environment;
	public userStatsPromise: Promise<{ id: string; name: string }[]>;
	public receiptTypesPromise: Promise<{ id: string; name: string }[]>;
	public accountNumberOptions: Promise<{ id: string; name: string }[]>;
	public tagOptions: Promise<{ id: string; name: string }[]>;
	public groupOptions: Promise<{ id: string; name: string }[]>;
	public exportedOptions: { id: string; name: string }[];
	public bookingStatusOptions: { id: string; name: string }[];
	public currentApproverOptions: { id: string; name: string }[];

	public filtersForm = new UntypedFormGroup({
		search: new UntypedFormControl(null),
		period: new UntypedFormControl(null),
		since: new UntypedFormControl(null),
		before: new UntypedFormControl(null),
		user: new UntypedFormControl(null),
		users: new UntypedFormControl(null),
		type: new UntypedFormControl(null), // only receipts
		category: new UntypedFormControl(''), // for reports
		companycategory: new UntypedFormControl(null), // for receipts
		administration: new UntypedFormControl(null), // for reports
		companyadministration: new UntypedFormControl(null), // for receipts
		cost_center: new UntypedFormControl(null), // for reports
		companycostcenter: new UntypedFormControl(null), // for receipts
		cost_unit: new UntypedFormControl(null), // for reports
		companycostunit: new UntypedFormControl(null), // for receipts
		project: new UntypedFormControl(null),
		companyproject: new UntypedFormControl(null),
		companygroup: new UntypedFormControl(null), // only receipts
		exported: new UntypedFormControl(null), // only receipts
		status: new UntypedFormControl(null), // declaration status
		statusses: new UntypedFormControl([]), // declaration status
		booked: new UntypedFormControl(null),
		authorization_flow: new UntypedFormControl(null),
		current_approver: new UntypedFormControl(null),
		currency: new UntypedFormControl(null), // only receipts
		merchant: new UntypedFormControl(null), // only receipts
		relation: new UntypedFormControl(null), // only receipts
		vat: new UntypedFormControl(null),
		payment_method_id: new UntypedFormControl(null),
		accountnumber: new UntypedFormControl(null), // only receipts
		group: new UntypedFormControl(null), // only receipts, labelled as "Folder" to the user.
		tag: new UntypedFormControl(null), // only receipts
	});

	private defaultFormValues = this.filtersForm.value;

	constructor(
		public translate: TranslateService,
		private filterService: FiltersService,
		private store: Store<AppState>,
		private localStorageService: LocalStorageService,
	) {}

	@HostListener('document:keydown.escape', ['$event']) onKeydownHandler(event: KeyboardEvent) {
		this.closeFilters();
	}

	closeFilters(): void {
		this.onDismiss.emit();
	}

	resetFilters() {
		this.filtersForm.reset(this.defaultFormValues);
	}

	saveFilters = async (value) => {
		delete value.period; // remove period from request, as this is emitted to since and before.
		if (isValueSet(this.filtersLocalStorageKey)) {
			this.localStorageService.set(this.filtersLocalStorageKey, value);
		}
		const valuesToEmit = value;
		if (valuesToEmit.since instanceof Date) {
			valuesToEmit.since = convertDateToYMD(valuesToEmit.since);
		}
		if (valuesToEmit.before instanceof Date) {
			valuesToEmit.before = convertDateToYMD(valuesToEmit.before);
		}
		this.onSave.emit(value);
		return true;
	};

	ngOnInit() {
		const valuesToPatch = this.requestQuery;
		if (stringIsSetAndFilled(valuesToPatch.since)) {
			valuesToPatch.since = new Date(valuesToPatch.since);
		}
		if (stringIsSetAndFilled(valuesToPatch.before)) {
			valuesToPatch.before = new Date(valuesToPatch.before);
		}
		this.filtersForm.patchValue(valuesToPatch);

		if (this.requestQuery instanceof ReceiptListAPIRequest) {
			this.showStats = true;
		}

		const companyStoreSubscription = this.store.select('company').subscribe((val) => {
			this.company = val.company;
		});

		const userStoreSubscription = this.store.select('user').subscribe((val) => {
			this.user = val.currentUser;
		});

		this.destroyCallbacks.push(() => {
			userStoreSubscription.unsubscribe();
			companyStoreSubscription.unsubscribe();
		});

		if (
			this.filterOptions.includes(FilterEnum.period) &&
			this.filterOptions.includes(FilterEnum.since) &&
			this.filterOptions.includes(FilterEnum.before)
		) {
			const changePeriodSubscription = this.filtersForm.get('period').valueChanges.subscribe((period: Period) => {
				if (period == null) {
					return;
				}

				this.requestQuery.since = period.start;
				this.requestQuery.before = period.end;
				this.filtersForm.patchValue({
					since: new Date(this.requestQuery.since),
					before: new Date(this.requestQuery.before),
				});
			});

			this.destroyCallbacks.push(() => {
				changePeriodSubscription.unsubscribe();
			});
		}

		this.fetchOptions();
	}

	isPeriodValid(sinceDate: Date, beforeDate: Date): boolean {
		return isValidDateRange(sinceDate, beforeDate);
	}

	fetchOptions() {
		this.filterOptions.forEach(async (filter: string) => {
			switch (filter) {
				case FilterEnum.user:
					this.userStatsPromise = this.filterService.getUserOptions(this.requestQuery);
					break;
				case FilterEnum.users:
					this.userStatsPromise = this.filterService.getUserOptions(this.requestQuery);
					break;
				case FilterEnum.type:
					this.receiptTypesPromise = this.filterService.getReceiptTypeOptions(this.requestQuery);
					break;
				case FilterEnum.accountnumber:
					this.accountNumberOptions = this.filterService.getAccountNumberOptions(this.requestQuery);
					break;
				case FilterEnum.tag:
					this.tagOptions = this.filterService.getTagOptions(this.requestQuery);
					break;
				case FilterEnum.group:
					this.groupOptions = this.filterService.getGroupOptions(this.requestQuery);
					break;
				case FilterEnum.exported:
					this.exportedOptions = this.filterService.getExportedOptions();
					break;
				case FilterEnum.booked:
					this.bookingStatusOptions = this.filterService.getBookingStatusOptions();
					break;
				case FilterEnum.current_approver:
					this.currentApproverOptions = await this.filterService.getApproverOptions(this.company.id, 'both');
					break;
				default:
					break; // Ignore for filters that do not require fetching of options.
			}
		});
	}

	public customSearchFnUser(term: string, item: { id: string; name: string; description: string }) {
		term = term.toLocaleLowerCase();
		// Allow to search on description as well as this contains the title.
		if (item.description) {
			return item.name.toLocaleLowerCase().indexOf(term) > -1 || item.description.toLocaleLowerCase().indexOf(term) > -1;
		} else {
			return item.name.toLocaleLowerCase().indexOf(term) > -1;
		}
	}

	ngOnDestroy(): void {
		this.destroyCallbacks.forEach((unsubscribe) => unsubscribe());
	}
}
