<div class="notificationContainer">
	<div
		class="leftSideContainer"
		[ngClass]="{ success: category === 'success', warning: category === 'warning', error: category === 'error', info: category === 'info' }"
	>
		<div class="iconContainer">
			<i
				class="fa"
				[ngClass]="{
					'fa-check': category === 'success',
					'fa-warning': category === 'warning',
					'fa-close': category === 'error',
					'fa-info': category === 'info'
				}"
			></i>
		</div>
	</div>
	<div class="descriptionAndCTA">
		<div class="description">{{ description }}</div>
		<div class="ctaContainer">
			<div *ngFor="let button of buttons" class="buttonWrapper">
				<app-button (click)="callButtonAction(button.action)">{{ button.text }}</app-button>
			</div>
		</div>
	</div>
	<div class="closeButtonContainer">
		<button class="closeButton" (click)="close()" data-cy="btnCloseNotification">×</button>
	</div>
</div>
