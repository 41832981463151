<div class="container text-center clearfix">
	<div class="col">
		<img src="assets/images/onboarding/onboarding-{{ theme }}-{{ gif }}.gif" class="img-fluid" />
		<br />
		<br />
		<h4 class="text-dark">{{ text }}</h4>
		<h4 class="text-dark" *ngIf="subtitle">
			{{ subtitle }}
		</h4>
	</div>
</div>
