import { roundToX } from '#/util/numbers';
import { stringIsSetAndFilled } from '#/util/values';
import { getLocaleForNumberLocalization } from '##/util/locale';

export function stringifyAmountWithCurrency(input: number, currencyCode: string, amountOfDecimals = 2): string {
	if (!Number.isFinite(input)) {
		return '';
	}
	const locale = getLocaleForNumberLocalization();
	const options = stringIsSetAndFilled(currencyCode)
		? { style: 'currency', currency: currencyCode, maximumFractionDigits: amountOfDecimals }
		: { maximumFractionDigits: amountOfDecimals };

	return new Intl.NumberFormat(locale, options).format(Number(roundToX(input / 100, amountOfDecimals)));
}

export function stringifyAmount(input: number, amountOfDecimals = 2): string {
	if (!Number.isFinite(input)) {
		return '';
	}
	const rounded = roundToX(input, amountOfDecimals);
	const [intPart, decimalPart] = String(rounded)?.split('.');
	if (!stringIsSetAndFilled(decimalPart)) {
		return intPart;
	}
	if (decimalPart.length < 2) {
		const missingZeroes = 2 - decimalPart.length;
		return [intPart, decimalPart].join(getDecimalSeparator()) + '0'.repeat(missingZeroes);
	}
	return [intPart, decimalPart].join(getDecimalSeparator());
}

export function getDecimalSeparator(): string {
	return (1.1).toLocaleString().replace(/\d/g, '');
}
