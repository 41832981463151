import { Component, OnInit } from '@angular/core';
import { InboxItem } from '../../../modules/inbox/models/inbox';
import { AuthenticatedComponent } from '../authenticated.component';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { needsInboxUpdate, WSMessage } from '#/models/websocket.model';

@Component({
	selector: 'app-inbox',
	templateUrl: './inbox.component.html',
})
export class InboxComponent extends AuthenticatedComponent implements OnInit {
	public inboxItems: InboxItem[];
	constructor() {
		super(); /* The inbox component loads all inbox messages of an user and renders it in a table. */
		this.init();
	}

	ngOnInit(): void {
		super.ngOnInit();

		/* Listen for inbox update messages. */
		const websocketSubscription = this.websocketsService.onMessage.subscribe((message: WSMessage) => {
			if (needsInboxUpdate(message.type)) {
				this.init();
			}
		});

		this.destroyCallbacks.push(() => {
			websocketSubscription.unsubscribe();
		});
	}

	init() {
		this.inboxAPIService
			.getInbox()
			.then((inboxItems) => {
				this.inboxItems = inboxItems;
			})
			.catch((e) => {});
	}

	get unreadItems(): InboxItem[] {
		if (this.inboxItems) {
			return this.inboxItems.filter((inboxItem) => !inboxItem.isRead());
		}
		return [];
	}

	hasUnreadItems(): boolean {
		return this.unreadItems.length > 0;
	}

	get unreadCounter(): number {
		return this.unreadItems.length;
	}

	markRead(quiet = false) {
		/* Mark all unread items in the inbox as read. */
		const promises = this.unreadItems.map((inboxItem) => this.inboxAPIService.markRead(inboxItem));
		Promise.all(promises)
			.then((r) => {
				if (!quiet) {
					this.notificationService.success(this.translate.instant(_('Inbox marked as read.')));
				}
				this.init();
			})
			.catch((e) => {
				if (!quiet) {
					this.notificationService.error(this.translate.instant(_('An error occurred while marking the inbox as read')));
				}
			});
	}
}
