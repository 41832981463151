import { APIService } from '~/app/api/services/api.service';
import { APINotificationsService } from '~/app/api/services/apinotifications.service';
import { User } from '#/models/user/user.model';
import { Injectable } from '@angular/core';

export enum UserType {
	Real = 'real',
	Partial = 'partial',
	System = 'system',
}

export interface MergeUsersFromData {
	sourceUsers: Array<string>;
	targetUser: string;
}

@Injectable({
	providedIn: 'root',
})
export class PartialUsersService {
	constructor(private apiService: APIService, private notifications: APINotificationsService) {}

	public mergeUsers(companyId: string, mergeUsersFromData: MergeUsersFromData): Promise<User> {
		return this.apiService
			.post(`/api/v1/company/${companyId}/user/merge`, mergeUsersFromData)
			.then((r) => new User(r.data))
			.catch((e) => {
				this.notifications.handleAPIError(e);
				throw e;
			});
	}
}
