import { Component, Host, Input, Optional, SimpleChanges } from '@angular/core';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { TranslateService } from '@ngx-translate/core';
import { ControlContainer, NG_VALUE_ACCESSOR } from '@angular/forms';
import { AppSelectOptions, FormElementComponent, ValueAccessorBase } from '@klippa/ngx-enhancy-forms';
import { isValueSet } from '#/util/values';

const allMethods = [
	{
		name: _('Bank'),
		id: 'bank',
	},
	{
		name: _('Cash'),
		id: 'cash',
	},
	{
		name: _('CreditCard'),
		id: 'creditcard',
	},
	{
		name: _('Not paid'),
		id: 'not_paid',
	},
	{
		name: _('Other'),
		id: 'other',
	},
	{
		name: _('PIN'),
		id: 'pin',
	},
	{
		name: _('Private'),
		id: 'private',
	},
];

@Component({
	selector: 'app-payment-method-picker',
	templateUrl: './payment-method-picker.component.html',
	styleUrls: ['./payment-method-picker.component.scss'],
	providers: [{ provide: NG_VALUE_ACCESSOR, useExisting: PaymentMethodPickerComponent, multi: true }],
})
export class PaymentMethodPickerComponent extends ValueAccessorBase<string> {
	protected defaultPlaceHolder: string;
	public paymentMethods: AppSelectOptions = [];
	@Input() public placeholder: string;
	@Input() public disabled = false;
	@Input() public multiple = false;
	@Input() public filterOnIds: Array<string>;

	constructor(
		@Optional() @Host() protected parent: FormElementComponent,
		@Optional() @Host() protected controlContainer: ControlContainer,
		protected translate: TranslateService,
	) {
		super(parent, controlContainer);
		this.paymentMethods = this.translateNames(allMethods);
		this.defaultPlaceHolder = _('Select payment method');
	}

	private translateNames(paymentMethods: AppSelectOptions) {
		return paymentMethods.map((e) => ({
			...e,
			name: this.translate.instant(e.name),
		}));
	}

	ngOnChanges(simpleChanges: SimpleChanges) {
		if (isValueSet(simpleChanges.filterOnIds?.currentValue)) {
			this.paymentMethods = this.translateNames(allMethods.filter((e) => simpleChanges.filterOnIds.currentValue.includes(e.id)));
		}
	}

	public getPlaceholderTextFn = (): string => {
		return this.placeholder ?? this.translate.instant(this.defaultPlaceHolder);
	};
}
