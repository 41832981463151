import { memoize } from 'lodash';

export function memoizeFull<T extends Function>(fn: T): T {
	return (memoize as any)(fn, (...args) => {
		return JSON.stringify(args);
	});
}

export function memoizeMax1Param<T extends Function>(fn: T): T {
	return (memoize as any)(fn);
}

export function memoizeFullDebounced<T extends Function>(fn: T): T {
	// TODO: actually debounce
	return (memoize as any)(fn, (...args) => {
		return JSON.stringify(args);
	});
}
