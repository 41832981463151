<klp-form [formGroup]="form" [warnings]="formWarnings" errorMessageLocation="rightOfCaption" (onInjected)="onInjected($event)">
	<klp-grid-row *ngIf="initiated">
		<klp-grid-column
			[width]="{ xs: screenDisplayOption === ScreenDisplayOption.SPACIOUS ? 12 : 4 }"
			*ngFor="let formControl of getControlsOfFormGroup(form)"
		>
			<klp-form-element [direction]="orientation" [spaceDistribution]="spaceDistribution">
				<klp-form-caption>
					<span class="caption">{{ getParamsOfFormControl(formControl).label }}</span>
					<app-info-tooltip
						*ngIf="getParamsOfFormControl(formControl).description"
						[title]="getParamsOfFormControl(formControl).description"
					></app-info-tooltip>
				</klp-form-caption>
				<app-dynamic-form-input
					[parameters]="getAsDynamicFormInputParameters(formControl)"
					[formControl]="formControl"
					[sideButton]="isCreatable(formControl) ? 'plus' : null"
					(onSideButtonClicked)="onCreateButtonClicked.emit({ topic: getParamsOfFormControl(formControl).key, ctrl: formControl })"
				>
					<ng-template klpSelectOptionTpl let-item="item">
						<ng-container [ngSwitch]="getTemplate(formControl)">
							<ng-container *ngSwitchCase="CustomStylingTemplate.LABEL_DESCRIPTION">
								<div>
									<header>{{ [item.code, item.name].filter(stringIsSetAndFilled).join(' - ') }}</header>
									<div class="description" *ngIf="item.description">{{ item.description }}</div>
								</div>
							</ng-container>
							<ng-container *ngSwitchCase="CustomStylingTemplate.LABEL_DESCRIPTION_CONTACT_INFO_WITHOUT_CODE">
								<div>
									<header>{{ item.name }}</header>
									<div class="description" *ngIf="item.description">{{ item.description }}</div>
								</div>
							</ng-container>
							<ng-container *ngSwitchDefault>
								<div>
									<header>{{ [item.code, item.name].filter(stringIsSetAndFilled).join(' - ') }}</header>
									<div class="description" *ngIf="item.description">{{ item.description }}</div>
								</div>
							</ng-container>
						</ng-container>
					</ng-template>
				</app-dynamic-form-input>
				<klp-form-error error="async"></klp-form-error>
			</klp-form-element>
		</klp-grid-column>
	</klp-grid-row>
</klp-form>
