import { Component, Input } from '@angular/core';
import { CompanyComponent } from '~/app/modules/company/components/company.component';

@Component({
	selector: 'app-company-user-label',
	templateUrl: './company-user-label.component.html',
})
export class CompanyUserLabelComponent extends CompanyComponent {
	@Input()
	public userID: string;

	get userObj() {
		if (this.users) {
			return this.users.find((u) => u.getID() === this.userID);
		}
		return null;
	}
}
