export class PaymentMethod {
	Code: string;
	Title: string;

	constructor(code: string, data = null) {
		this.Code = code;
		if (data) {
			Object.assign(this, data);
		}
	}
}
