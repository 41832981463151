import { User } from '../user/user.model';

export class CompanyUserData {
	users: Array<User> = [];
	count: number;
	moreresults: boolean;

	constructor(data = null) {
		if (data) {
			Object.assign(this, data);

			if (data.users) {
				this.users = User.fromData(data.users);
			}
		}
	}
}
