<ng-template #authorizationFlowTooltip>
	<app-authorization-flow-tooltip [parentItem]="parentItem" [parentType]="parentType"> </app-authorization-flow-tooltip>
</ng-template>
<div
	class="status-badge"
	[ngClass]="[statusFlag, authorizationFlowIsCompleted ? '' : 'invert']"
	[ngbTooltip]="authorizationFlowTooltip"
	[disableTooltip]="(!authorizationFlow || authorizationFlowIsCompleted) && status.status !== 'Approved'"
	[ngSwitch]="statusFlag"
>
	<ng-template [ngSwitchCase]="'NotSubmitted'">
		<span translate>Not submitted</span>
	</ng-template>
	<ng-template [ngSwitchCase]="'ToClaim'">
		<span translate>Pending</span>
	</ng-template>
	<ng-template [ngSwitchCase]="'NeedsInformation'">
		<span translate>Incomplete</span>
	</ng-template>
	<ng-template [ngSwitchCase]="'Accepted'">
		<span translate *ngIf="!(companyHasTransactionInterfaces() | async)">Accepted</span>
		<span translate *ngIf="companyHasTransactionInterfaces() | async">In workflow</span>
	</ng-template>
	<ng-template [ngSwitchCase]="'Approved'">
		<ng-container *ngIf="authorizationFlow">
			<ng-container *ngIf="!authorizationFlowIsCompleted"> {{ authorizationFlow.approvals.length }} / {{ approversLength }} </ng-container>
		</ng-container>
		<span translate>Approved</span>
	</ng-template>
	<ng-template [ngSwitchCase]="'Denied'">
		<span translate>Denied</span>
	</ng-template>
	<ng-template [ngSwitchCase]="'Claimed'">
		<span translate>Processed</span>
	</ng-template>
	<ng-template ngSwitchDefault>
		{{ status.status | translate }}
	</ng-template>
</div>
