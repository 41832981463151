import { Injectable } from '@angular/core';
import { ReceiptListAPIRequest } from '#/models/transaction/receipt';
import { debounceTime, map, Subject } from 'rxjs';

export const SEARCH_FILTER_LOCALSTORAGE_KEY = 'search-filter-localstorage-key';

@Injectable({
	providedIn: 'root',
})
export class ReceiptFiltersService {
	public filters: ReceiptListAPIRequest = new ReceiptListAPIRequest();
	public defaultFilters: ReceiptListAPIRequest = new ReceiptListAPIRequest();
	public querySubject: Subject<void> = new Subject<void>();
	public searchSubject: Subject<void> = new Subject<void>();

	constructor() {
		this.querySubject = new Subject();
		this.querySubject
			.pipe(
				debounceTime(300),
				map((value) => {
					this.searchSubject.next();
				}),
			)
			.subscribe();
	}

	queryChanged() {
		this.querySubject.next();
	}
}
