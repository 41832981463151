import { Component, Input, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { ModalComponent } from '~/app/shared/ui/modal/modal.component';
import { AccountingIntegrationV2Service, DynamicBookingField } from '#/services/integration/accounting-integration-v2.service';
import { memoize } from 'lodash';
import { DynamicFormElementParameters } from '#/models/dynamic-form-element';
import { UI_TYPE } from '#/models/uiType';
import { AppSelectOption, ItemsWithHasMoreResultsPromise } from '#/models/appSelectOption.model';
import { NotificationService } from '~/app/services/notification.service';
import { APINotificationsService } from '~/app/api/services/apinotifications.service';
import { firstValueFrom, Subject } from 'rxjs';
import { Attachment } from '#/models/transaction/attachment';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { UiModule } from '~/app/shared/ui/ui.module';
import { PipesModule } from '~/app/helpers/pipes/pipes.module';
import { ModalModule } from '~/app/shared/ui/modal/modal.module';
import { AccountingBookingType } from '#/models/transaction/bookingType';

@Component({
	selector: 'app-aav2-entity-creation-modal',
	templateUrl: './aav2-entity-creation.component.html',
	styleUrls: ['./aav2-entity-creation.component.scss'],
	standalone: true,
	imports: [CommonModule, FormsModule, TranslateModule, ReactiveFormsModule, UiModule, PipesModule, ModalModule],
})
export class Aav2EntityCreationComponent {
	@Input() attachments: Array<Attachment> = [];
	@Input() transactionId: string;
	@Input() integrationId: string;
	@Input() accountingBookingType: AccountingBookingType;
	@ViewChild('modal') modal: ModalComponent;

	private paramsByFormControl = new Map<FormControl, DynamicBookingField>();
	public creationForm = this.fb.group({});
	private creationTopic: string;
	private creationSubject: Subject<string>;

	constructor(
		private fb: FormBuilder,
		private accountingIntegrationServiceV2: AccountingIntegrationV2Service,
		private notificationService: NotificationService,
		private apiNotificationsService: APINotificationsService,
	) {}

	private setUpCreationFields(creationFields: Array<DynamicBookingField>) {
		Object.keys(this.creationForm.controls).forEach((key) => {
			this.creationForm.removeControl(key);
		});
		creationFields.forEach((field) => {
			const ctrl = new FormControl();
			if (field.options?.required) {
				ctrl.setValidators([Validators.required]);
			}
			this.creationForm.setControl(field.key, ctrl);
			this.paramsByFormControl.set(ctrl, field);
		});
	}

	public async openModal(creationTopic: string): Promise<string> {
		this.creationSubject = new Subject();
		this.creationTopic = creationTopic;
		const creationFields = await this.accountingIntegrationServiceV2.getCreationProperties(
			this.integrationId,
			creationTopic,
			this.accountingBookingType,
		);
		this.setUpCreationFields(creationFields);
		this.modal.openModal();
		return firstValueFrom(this.creationSubject);
	}

	public getParamsOfFormControl(f: FormControl): DynamicBookingField {
		return this.paramsByFormControl.get(f);
	}

	public getCreationFieldControls(): Array<FormControl> {
		return Object.values(this.creationForm.controls) as Array<FormControl>;
	}

	public createEntity = async (values) => {
		try {
			const newEntityId = await this.accountingIntegrationServiceV2.createNewFieldEntity(
				this.integrationId,
				this.creationTopic,
				values,
				this.accountingBookingType,
			);
			this.creationSubject.next(newEntityId);
			this.modal.closeModal();
		} catch (e) {
			this.notificationService.error(e.error.message, this.apiNotificationsService.errorConfig(e));
		}
	};

	/* tslint:disable:member-ordering */
	public getAsDynamicFormInputParametersForCreation = memoize((formControl: FormControl): DynamicFormElementParameters => {
		const inputParam = this.getParamsOfFormControl(formControl);
		if (inputParam.uiType === UI_TYPE.SELECT || inputParam.uiType === UI_TYPE.MULTI_SELECT) {
			return {
				uiType: inputParam.uiType,
				fetchItemsFn: async (start: number, searchQuery: string): ItemsWithHasMoreResultsPromise<AppSelectOption> => {
					if (!formControl.enabled) {
						return {
							items: [],
							hasMoreResults: false,
						};
					}
					const context = {};
					return this.accountingIntegrationServiceV2.getPickerValuesForCreate(
						this.integrationId,
						this.creationTopic,
						inputParam.key,
						start,
						searchQuery,
						context,
						this.accountingBookingType,
					);
				},
				fetchSelectedItemsFn: (ids: Array<string>): Promise<Array<AppSelectOption>> => {
					const context = {};
					return this.accountingIntegrationServiceV2.getPickerValuesForCreateByIds(
						this.integrationId,
						this.creationTopic,
						inputParam.key,
						ids,
						context,
						this.accountingBookingType,
					);
				},
			};
		}
		if (inputParam.uiType === UI_TYPE.MONEY) {
			return {
				uiType: inputParam.uiType,
				showCurrencyPicker: true,
			};
		}
		return {
			uiType: inputParam.uiType,
		};
	});
}
