<ngx-async-template [promise]="filePromise">
	<ng-template ngx-async-success let-file="value">
		<app-file-viewer-deprecated
			[filename]="getActiveAttachment().filename"
			[type]="getActiveAttachmentType()"
			[isOCR]="false"
			[blob]="file.blob"
			[viewerType]="getActiveAttachmentType()"
		>
		</app-file-viewer-deprecated>
	</ng-template>
</ngx-async-template>
