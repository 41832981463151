import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { ColumnsInterface } from '~/app/shared/ui/table/table';
import { FilterEnum } from '~/app/shared/ui/filters/filter';
import { Injectable } from '@angular/core';
import { ActionsMenuActionEnum, ActionsMenuInterface } from '~/app/shared/ui/actions-menu/actions-menu';
import { SelectActionEnum, SelectActionInterface } from '~/app/shared/ui/select-action/select-action';
import { TranslateService } from '@ngx-translate/core';
import { Receipt } from '#/models/transaction/receipt';
import { ExpenseReportsService } from '#/services/transaction/expense-reports.service';
import { ExpenseReport } from '#/models/transaction/expense-reports';
import { CompanyService } from '#/services/company/company.service';

@Injectable({
	providedIn: 'root',
})
export class ColumnService {
	constructor(protected companyAPIService: CompanyService, private translate: TranslateService) {}

	private setPaymentMethodColumn(paymentInfoEnabled: boolean): ColumnsInterface {
		if (this.companyAPIService.getCompanyOfLoggedUser()?.modulePaymentMethodsIsEnabled()) {
			return {
				name: this.translate.instant(_('Payment method')),
				slug: 'payment_method_id',
				type: 'payment_method_id',
				enabled: true,
				sortable: true,
				id: 'payment_method_id',
				sortingProperty: 'payment_method_id',
			};
		} else {
			return {
				name: this.translate.instant(_('Payment method')),
				slug: 'paymentmethod',
				type: 'paymentmethod',
				enabled: paymentInfoEnabled,
				sortable: true,
				id: 'paymentmethod',
				sortingProperty: 'paymentmethod',
			};
		}
	}

	getColumns(states): ColumnsInterface[] {
		return [
			{
				name: this.translate.instant(_('Selection')),
				type: 'selector',
				enabled: true,
				required: true,
				position: 'pre',
				sortable: false,
				id: 'selection',
				sortingProperty: null,
			},
			{
				name: this.translate.instant(_('Category')),
				slug: 'companycategory',
				type: 'category',
				enabled: states.categoriesEnabled,
				sortable: true,
				id: 'company_category',
				sortingProperty: 'companycategory',
			},
			this.setPaymentMethodColumn(states.paymentInfoEnabled),
			{
				name: this.translate.instant(_('Description')),
				type: 'description',
				slug: 'description',
				merchant_slug: 'merchant',
				compact_mode: states.compactModeEnabled,
				enabled: true,
				sortable: true,
				id: 'description',
				sortingProperty: 'description',
			},
			{
				name: this.translate.instant(_('Transaction date')),
				slug: 'purchasedate',
				type: 'date',
				enabled: true,
				sortable: true,
				id: 'purchase_date',
				sortingProperty: 'purchasedate',
			},
			{
				name: this.translate.instant(_('Cost center')),
				slug: 'companycostcenter',
				type: 'cost_center',
				enabled: true,
				sortable: true,
				id: 'cost_center',
				sortingProperty: 'companycostcenter',
			},
			{
				name: this.translate.instant(_('Cost unit')),
				slug: 'companycostunit',
				type: 'cost_unit',
				enabled: true,
				sortable: true,
				id: 'cost_unit',
				sortingProperty: 'companycostunit',
			},
			{
				name: this.translate.instant(_('Project')),
				slug: 'companyproject',
				type: 'project',
				enabled: true,
				sortable: true,
				id: 'project',
				sortingProperty: 'companyproject',
			},
			{
				name: this.translate.instant(_('Amount')),
				slug: 'amount',
				currency_slug: 'currency',
				type: 'amount',
				enabled: true,
				sortable: true,
				id: 'amount',
				sortingProperty: 'amount',
			},
			{
				name: this.translate.instant(_('VAT')),
				items_slug: 'vatitems',
				currency_slug: 'currency',
				type: 'vat',
				enabled: true,
				sortable: false,
				id: 'vat_amount',
				sortingProperty: null,
			},
			{
				name: this.translate.instant(_('Status')),
				slug: 'declarationstatus',
				type: 'status',
				enabled: true,
				sortable: true,
				id: 'declaration_status',
				sortingProperty: 'declarationstatus.status',
			},
			{
				name: this.translate.instant(_('Duplicate notice')),
				slug: 'duplicate_documents',
				type: 'duplicate_notifier',
				enabled: true,
				hide_header: true,
				required: true,
				position: 'end',
				sortable: false,
				id: 'duplicate_documents',
				sortingProperty: null,
			},
			{
				name: this.translate.instant(_('Create date')),
				slug: 'createdate',
				type: 'date',
				enabled: false,
				sortable: true,
				id: 'createdate',
				sortingProperty: 'createdate',
			},
			{
				name: this.translate.instant(_('Submit date')),
				slug: 'submit_date',
				type: 'date',
				enabled: false,
				sortable: true,
				id: 'submit_date',
				sortingProperty: 'submit_date',
			},
			{
				name: this.translate.instant(_('Update date')),
				slug: 'updatedate',
				type: 'date',
				enabled: false,
				sortable: true,
				id: 'updatedate',
				sortingProperty: 'updatedate',
			},
			{
				name: this.translate.instant(_('Booking status')),
				slug: 'bookingstatus',
				declaration_status_slug: 'declarationstatus',
				type: 'booking_status',
				enabled: false,
				sortable: true,
				id: 'booking_status',
				sortingProperty: 'bookingstatus.bookedon',
			},
			{
				name: this.translate.instant(_('Attachment count')),
				slug: 'attachments_count',
				type: 'default',
				enabled: false,
				sortable: true,
				id: 'attachments_count',
				sortingProperty: 'attachments_count',
			},
			{
				name: this.translate.instant(_('Settings')),
				type: 'column_config',
				enabled: true,
				required: true,
				position: 'end',
				sortable: false,
				id: 'settings',
				sortingProperty: null,
			},
		];
	}

	getTotalColumns(states): ColumnsInterface[] {
		return [
			{ type: 'empty', enabled: true, position: 'pre' },
			{ type: 'empty', enabled: states.paymentInfoEnabled },
			{ type: 'empty', enabled: states.categoriesEnabled },
			{ slug: 'receipt_count', type: 'total', enabled: true },
			{ slug_start: 'start_date', slug_end: 'end_date', type: 'date-range', enabled: true },
			{ slug: 'cost_center', type: 'cost_center', enabled: true },
			{ slug: 'cost_unit', type: 'cost_unit', enabled: true },
			{ slug: 'project', type: 'project', enabled: true },
			{ slug: 'amount', type: 'amount', enabled: true },
			{ type: 'empty', enabled: true },
			{ slug: 'status_to_show', type: 'status_to_show', enabled: true },
			{ type: 'empty', enabled: true, position: 'end' },
			{ type: 'empty', enabled: false },
			{ type: 'empty', enabled: false },
			{ type: 'empty', enabled: false },
			{ type: 'empty', enabled: false },
			{ type: 'empty', enabled: false },
			{ type: 'empty', enabled: true, position: 'end' },
		];
	}
}

@Injectable({
	providedIn: 'root',
})
export class ActionService {
	constructor(private translate: TranslateService, private reportsService: ExpenseReportsService, private companyService: CompanyService) {}

	private pageActions: ActionsMenuInterface[] = [
		{ title: this.translate.instant(_('Add receipt')), action: ActionsMenuActionEnum.add_receipt },
		{ title: this.translate.instant(_('Travel expense')), action: ActionsMenuActionEnum.travel_expense },
		{ title: this.translate.instant(_('Document splitter')), action: ActionsMenuActionEnum.split },
	];

	public getPageActions(): Array<ActionsMenuInterface> {
		const defaultActions = this.companyService.getCompanyOfLoggedUser()?.modules?.receipt_presets?.remove_default_add_options
			? []
			: this.pageActions;
		const presets = this.companyService.getCompanyOfLoggedUser()?.modules?.receipt_presets?.presets || [];
		return [
			...defaultActions,
			...presets
				.filter((e) => !e.hide_in_menu)
				.map((e) => {
					return { title: e.menu_label, action: ActionsMenuActionEnum.add_receipt_with_preset, context: e };
				}),
		];
	}

	getRowActions(report: ExpenseReport, receipt: Receipt): ActionsMenuInterface[] {
		const result = [];
		if (this.reportsService.canEditItemInReport(receipt)) {
			result.push({
				title: this.translate.instant(_('Edit')),
				action: ActionsMenuActionEnum.edit,
			});
		}
		if (this.reportsService.canExcludeItemInReport(report)) {
			result.push({
				title: this.translate.instant(_('Exclude from report')),
				action: ActionsMenuActionEnum.exclude,
			});
		}
		if (this.reportsService.canDeleteItemInReport(report)) {
			result.push({
				title: this.translate.instant(_('Delete receipt')),
				action: ActionsMenuActionEnum.delete,
			});
		}
		if (this.reportsService.canDuplicateItemInReport(report)) {
			result.push({
				title: this.translate.instant(_('Duplicate')),
				action: ActionsMenuActionEnum.duplicate,
			});
		}
		return result;
	}

	getSelectActions(report: ExpenseReport, receipts: Array<Receipt>): SelectActionInterface[] {
		const result = [];
		if (receipts.every((receipt) => this.reportsService.canEditItemInReport(receipt))) {
			result.push({
				title: this.translate.instant(_('Edit')),
				action: SelectActionEnum.edit,
				multiple: false,
			});
		}
		if (this.reportsService.canExcludeItemInReport(report)) {
			result.push({
				title: this.translate.instant(_('Exclude from report')),
				action: SelectActionEnum.retract,
				multiple: true,
			});
		}
		if (this.reportsService.canDuplicateItemInReport(report)) {
			result.push({
				title: this.translate.instant(_('Duplicate')),
				action: SelectActionEnum.duplicate,
				multiple: true,
			});
		}
		return result;
	}
}

@Injectable({
	providedIn: 'root',
})
export class FilterService {
	getFilterOptions(): FilterEnum[] {
		return [
			FilterEnum.search,
			FilterEnum.period,
			FilterEnum.since,
			FilterEnum.before,
			FilterEnum.type,
			FilterEnum.exported,
			FilterEnum.status,
			FilterEnum.currency,
			FilterEnum.relation,
			FilterEnum.vat,
			FilterEnum.payment_method_id,
			FilterEnum.accountnumber,
			FilterEnum.group,
			FilterEnum.tag,
		];
	}
}
