import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { Receipt, ReceiptListAPIRequest, ReceiptsExportRequest } from '#/models/transaction/receipt';
import { User } from '#/models/user/user.model';
import { Store } from '@ngrx/store';
import { AppState } from '~/app/reducers';
import { LocalStorageService } from 'angular-2-local-storage';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap/modal/modal-ref';
import { AccountingIntegrationV1, Company } from '#/models/company/company.model';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmModalComponent } from '~/app/shared/ui/confirm-modal/confirm-modal.component';
import { ModalService } from '~/app/services/modal.service';
import { DropdownItem } from '~/app/modules/generic/models/generic';
import { ReceiptService } from '#/services/transaction/receipt.service';
import { cloneDeep, memoize } from 'lodash';
import { TranslationReplaceService } from '~/app/services/translation-replace.service';
import { ReceiptFlowService } from '~/app/modules/receipt/receipt-flow.service';
import { importReceiptModalInBackground } from '~/app/modules/receipt/components/modals/lazy';
import { bsDropdownToggle } from '~/app/helpers/bootstrapHelpers';
import { ExpenseReport } from '#/models/transaction/expense-reports';
import { ExportRequest } from '#/models/company/exporterInterfaces';
import { isValueSet } from '#/util/values';
import { UserService } from '~/app/modules/user/user.service';
import { CompanyFeatureFlagsService } from '#/services/company/company-feature-flags.service';
import { PossibleCompanyFeatureFlags } from '#/models/company/possible-feature-flags';
import { ActivatedRoute, Router } from '@angular/router';
import { Order } from '#/models/utils/order';
import { TransactionEditorService } from '#/services/transaction/transaction-editor.service';
import { ViewportScroller } from '@angular/common';
import { PageStateService } from '#/services/util/page-state.service';
import { AccountingIntegrationV2Service } from '#/services/integration/accounting-integration-v2.service';

export enum SortingProperty {
	// columns ID = apiSortingRequestValue
	report = 'report',
	description = 'description',
	user = 'user',
	attachments_count = 'attachments_count',
	group = 'group',
	purchasedate = 'purchasedate',
	dueDate = 'dueDate',
	updatedate = 'updatedate',
	createdate = 'createdate',
	submit_date = 'submit_date',
	amount = 'amount',
	invoice_number = 'invoicenumber',
	companyadministration = 'companyadministration',
	lastexportdate = 'lastexportdate',
	lastcompanyexportdate = 'lastcompanyexportdate',
	finance_type = 'finance_type',
	booking_status = 'bookingstatus.bookedon',
	paymentmethod = 'paymentmethod', // payment methods module is disabled
	payment_method_id = 'payment_method_id', // payment methods module is enabled - CompanyPaymentMethod
	accountnumber = 'accountnumber',
	companycategory = 'companycategory',
	companycostcenter = 'companycostcenter',
	companycostunit = 'companycostunit',
	companyproject = 'companyproject',
	vat_amount = 'vat_amount', // not sortable yet
	expense_status = 'declarationstatus.status',
	current_approver = 'current_approver',
}

export class ReceiptsTableColumn {
	public sortable = false;
	constructor(
		public id: string,
		public label: string,
		public status: boolean,
		public index: number,
		public table_label: string = '',
		public table_class: string[] = [],
	) {
		if (this.table_label === '') {
			this.table_label = this.label;
		}
	}

	setSortable(val: boolean) {
		this.sortable = val;
		return this;
	}
}

@Component({
	selector: 'app-receipts-table',
	templateUrl: './receipts-table.component.html',
	styleUrls: ['./receipts-table.component.scss'],
})
export class ReceiptsTableComponent implements OnInit, OnDestroy {
	@Input() receipts: Receipt[];
	@Input() tableType = '';
	@Input() filters: ReceiptListAPIRequest;
	@Input() defaultFilters: ReceiptListAPIRequest;
	@Input() checkboxes: {};
	@Input() checkAll: { checked: boolean };
	@Input() reports: ExpenseReport[];
	@Output() filtersChanged = new EventEmitter<any>();
	@Output() onSortingChange: EventEmitter<{ sort: string; sortOrder: Order }> = new EventEmitter<any>();

	@Output() receiptsChanged = new EventEmitter<any>();
	@Output() changePage = new EventEmitter<any>();

	@ViewChild('exportModal') exportModal: ElementRef;
	public exportPromise: Promise<string>;
	public exportIds: Array<string>;

	protected newFilters: ReceiptListAPIRequest;

	public user: User;
	public company: Company;

	protected columns: ReceiptsTableColumn[] = [];
	protected sortableColumns: ReceiptsTableColumn[] = [];
	public integrations: Array<AccountingIntegrationV1>;

	@Input()
	protected filtered = false;
	protected destroyCallbacks = [];

	protected previousClickedCheckbox = null;

	protected dropdownItems: DropdownItem[] = [];

	private seenReceipts = new Map();

	private batchReceiptsPerPage = 2;
	private batchCurrentPage = 1;
	public changeDetection = 1;
	public reportDeclarationsModuleEnabled: boolean;
	public userIsConnectedToCompany: boolean;
	public sortingProperty = SortingProperty;

	constructor(
		private store: Store<AppState>,
		private localStorageService: LocalStorageService,
		private modalService: ModalService,
		protected translate: TranslateService,
		private receiptAPIService: ReceiptService,
		private translationReplaceService: TranslationReplaceService,
		protected receiptFlowService: ReceiptFlowService,
		private userService: UserService,
		private companyFeatureFlagsService: CompanyFeatureFlagsService,
		private router: Router,
		private activatedRoute: ActivatedRoute,
		private transactionEditorService: TransactionEditorService,
		private viewportScroller: ViewportScroller,
		private pageStateService: PageStateService,
		private accountingIntegrationV2Service: AccountingIntegrationV2Service,
	) {}

	ngOnInit() {
		const companySubscription = this.store.select('company').subscribe((val) => {
			this.company = val.company;
			this.integrations = val.integrations;
			this.reportDeclarationsModuleEnabled = val?.company?.modules?.report?.enabled === true;
		});
		const subscription = this.store.select('user').subscribe(async (val) => {
			this.user = val.currentUser;

			this.columns = [];

			if (this.tableType === 'personal') {
				if (this.reportDeclarationsModuleEnabled) {
					this.columns.push(new ReceiptsTableColumn('report', this.translate.instant(_('Report')), true, 0, '', []).setSortable(true));
				}
				this.columns.push(
					new ReceiptsTableColumn('description', this.translate.instant(_('Description')), true, 1, '', []).setSortable(true),
				);
				this.columns.push(
					new ReceiptsTableColumn('attachments_count', this.translate.instant(_('Amount of attachments')), true, 2, '#', [
						'text-center',
						'text-nowrap',
					]).setSortable(true),
				);
				if (this.user.getPreferences().groups) {
					this.columns.push(new ReceiptsTableColumn('group', this.translate.instant(_('Folder')), true, 3).setSortable(true));
				}
				this.columns.push(
					new ReceiptsTableColumn('purchasedate', this.translate.instant(_('Transaction Date')), true, 4, '', ['text-nowrap']).setSortable(
						true,
					),
				);
				this.columns.push(new ReceiptsTableColumn('createdate', this.translate.instant(_('Create date')), true, 5).setSortable(true));
				this.columns.push(new ReceiptsTableColumn('updatedate', this.translate.instant(_('Update date')), false, 6).setSortable(true));
				this.columns.push(
					new ReceiptsTableColumn('companyadministration', this.translationReplaceService.administration, false, 7).setSortable(true),
				);
				this.columns.push(new ReceiptsTableColumn('companycategory', this.translationReplaceService.category, true, 8).setSortable(true));
				this.columns.push(
					new ReceiptsTableColumn('companycostcenter', this.translationReplaceService.costCenter, false, 9).setSortable(true),
				);
				this.columns.push(new ReceiptsTableColumn('companycostunit', this.translationReplaceService.costUnit, false, 10).setSortable(true));
				this.columns.push(new ReceiptsTableColumn('companyproject', this.translationReplaceService.project, false, 11).setSortable(true));
				if (this.newPaymentMethods()) {
					this.columns.push(
						new ReceiptsTableColumn('payment_method_id', this.translate.instant(_('Payment method')), false, 12).setSortable(true),
					);
				} else {
					this.columns.push(
						new ReceiptsTableColumn('paymentmethod', this.translate.instant(_('Payment method')), false, 12).setSortable(true),
					);
				}
				this.columns.push(new ReceiptsTableColumn('amount', this.translate.instant(_('Amount')), true, 13).setSortable(true));
				if (this.user.canUseVAT() && this.user.getPreferences().vatmode) {
					this.columns.push(new ReceiptsTableColumn('vat_amount', this.translate.instant(_('VAT')), true, 14));
				}
				if (this.user.canUseFinanceType() && this.user.getPreferences().financetype) {
					this.columns.push(
						new ReceiptsTableColumn('finance_type', this.translate.instant(_('Finance Type')), true, 15, '+/-', [
							'text-center',
						]).setSortable(true),
					);
				}
				if (this.user.hasCompanyExpenseSubmitterRole()) {
					this.columns.push(
						new ReceiptsTableColumn('expense_status', this.translate.instant(_('Status')), true, 16, '', ['text-center']).setSortable(true),
					);
				}
				this.columns.push(
					new ReceiptsTableColumn(
						'lastexportdate',
						this.translate.instant(_('Last Export Date')),
						false,
						17,
						this.translate.instant(_('Last export')),
					).setSortable(true),
				);
			} else if (this.tableType === 'expenses') {
				if (this.isDemo()) {
					this.columns.push(new ReceiptsTableColumn('user', this.translate.instant(_('User')), true, 0).setSortable(true));
					this.columns.push(
						new ReceiptsTableColumn('companycostcenter', this.translationReplaceService.costCenter, true, 1).setSortable(true),
					);
					this.columns.push(
						new ReceiptsTableColumn('companycostunit', this.translationReplaceService.costUnit, false, 2).setSortable(true),
					);
					this.columns.push(new ReceiptsTableColumn('companyproject', this.translationReplaceService.project, false, 3).setSortable(true));
					this.columns.push(new ReceiptsTableColumn('companycategory', this.translationReplaceService.category, true, 4).setSortable(true));
					this.columns.push(
						new ReceiptsTableColumn('description', this.translate.instant(_('Description')), true, 5, '', []).setSortable(true),
					);
					this.columns.push(
						new ReceiptsTableColumn('purchasedate', this.translate.instant(_('Transaction Date')), true, 6, '', [
							'text-nowrap',
						]).setSortable(true),
					);
					this.columns.push(
						new ReceiptsTableColumn('companyadministration', this.translationReplaceService.administration, false, 7).setSortable(true),
					);
					this.columns.push(new ReceiptsTableColumn('amount', this.translate.instant(_('Amount')), true, 8).setSortable(true));
					this.columns.push(new ReceiptsTableColumn('vat_amount', this.translate.instant(_('VAT')), true, 9));
					this.columns.push(
						new ReceiptsTableColumn('expense_status', this.translate.instant(_('Status')), true, 10, '', ['text-center']).setSortable(true),
					);
					this.columns.push(
						new ReceiptsTableColumn('current_approver', this.translate.instant(_('Current approver')), true, 11).setSortable(true),
					);
					if ((this.user.hasCompanyFinanceRole() || this.user.hasCompanyAdminRole()) && (await this.getCompanyAuthorizationsCount()) > 0) {
						this.columns.push(
							new ReceiptsTableColumn('booking_status', this.translate.instant(_('Booking status')), true, 12).setSortable(true),
						);
					}
					this.columns.push(
						new ReceiptsTableColumn('attachments_count', this.translate.instant(_('Amount of attachments')), false, 13, '#', [
							'text-center',
							'text-nowrap',
						]).setSortable(true),
					);
					this.columns.push(
						new ReceiptsTableColumn('submit_date', this.translate.instant(_('Submit Date')), false, 14, '', ['text-nowrap']).setSortable(
							true,
						),
					);
					this.columns.push(
						new ReceiptsTableColumn('finance_type', this.translate.instant(_('Finance Type')), false, 15, '+/-', [
							'text-center',
						]).setSortable(true),
					);
					this.columns.push(
						new ReceiptsTableColumn(
							'lastcompanyexportdate',
							this.translate.instant(_('Last Export Date')),
							false,
							16,
							this.translate.instant(_('Last export')),
						).setSortable(true),
					);
					this.columns.push(new ReceiptsTableColumn('createdate', this.translate.instant(_('Create date')), false, 17).setSortable(true));
					this.columns.push(new ReceiptsTableColumn('updatedate', this.translate.instant(_('Update date')), false, 18).setSortable(true));
					if (this.newPaymentMethods()) {
						this.columns.push(
							new ReceiptsTableColumn('payment_method_id', this.translate.instant(_('Payment method')), false, 19).setSortable(true),
						);
					} else {
						this.columns.push(
							new ReceiptsTableColumn('paymentmethod', this.translate.instant(_('Payment method')), false, 19).setSortable(true),
						);
					}
					this.columns.push(
						new ReceiptsTableColumn(
							'accountnumber',
							this.translate.instant(_('Account number')),
							false,
							20,
							this.translate.instant(_('Account number')),
						).setSortable(true),
					);
				} else {
					this.columns.push(
						new ReceiptsTableColumn('description', this.translate.instant(_('Description')), true, 0, '', []).setSortable(true),
					);
					this.columns.push(new ReceiptsTableColumn('user', this.translate.instant(_('User')), true, 1).setSortable(true));
					this.columns.push(
						new ReceiptsTableColumn('attachments_count', this.translate.instant(_('Amount of attachments')), true, 2, '#', [
							'text-center',
							'text-nowrap',
						]).setSortable(true),
					);
					this.columns.push(
						new ReceiptsTableColumn('submit_date', this.translate.instant(_('Submit Date')), true, 3, '', ['text-nowrap']).setSortable(
							true,
						),
					);
					this.columns.push(
						new ReceiptsTableColumn('purchasedate', this.translate.instant(_('Transaction Date')), true, 4, '', [
							'text-nowrap',
						]).setSortable(true),
					);
					this.columns.push(
						new ReceiptsTableColumn('companyadministration', this.translationReplaceService.administration, false, 5).setSortable(true),
					);
					this.columns.push(new ReceiptsTableColumn('amount', this.translate.instant(_('Amount')), true, 6).setSortable(true));
					this.columns.push(new ReceiptsTableColumn('vat_amount', this.translate.instant(_('VAT')), true, 7));
					this.columns.push(
						new ReceiptsTableColumn('finance_type', this.translate.instant(_('Finance Type')), true, 8, '+/-', ['text-center']).setSortable(
							true,
						),
					);
					this.columns.push(
						new ReceiptsTableColumn('expense_status', this.translate.instant(_('Status')), true, 9, '', ['text-center']).setSortable(true),
					);
					this.columns.push(
						new ReceiptsTableColumn('current_approver', this.translate.instant(_('Current approver')), true, 10).setSortable(true),
					);
					if ((this.user.hasCompanyFinanceRole() || this.user.hasCompanyAdminRole()) && (await this.getCompanyAuthorizationsCount()) > 0) {
						this.columns.push(
							new ReceiptsTableColumn('booking_status', this.translate.instant(_('Booking status')), true, 11).setSortable(true),
						);
					}
					this.columns.push(
						new ReceiptsTableColumn(
							'lastcompanyexportdate',
							this.translate.instant(_('Last Export Date')),
							(this.user.hasCompanyFinanceRole() || this.user.hasCompanyAdminRole()) && this.company.authorizations.size === 0,
							12,
							this.translate.instant(_('Last export')),
						).setSortable(true),
					);
					this.columns.push(new ReceiptsTableColumn('createdate', this.translate.instant(_('Create date')), false, 13).setSortable(true));
					this.columns.push(new ReceiptsTableColumn('updatedate', this.translate.instant(_('Update date')), false, 14).setSortable(true));
					if (this.newPaymentMethods()) {
						this.columns.push(
							new ReceiptsTableColumn('payment_method_id', this.translate.instant(_('Payment method')), false, 15).setSortable(true),
						);
					} else {
						this.columns.push(
							new ReceiptsTableColumn('paymentmethod', this.translate.instant(_('Payment method')), false, 15).setSortable(true),
						);
					}
					this.columns.push(
						new ReceiptsTableColumn(
							'accountnumber',
							this.translate.instant(_('Account number')),
							false,
							16,
							this.translate.instant(_('Account number')),
						).setSortable(true),
					);
					this.columns.push(
						new ReceiptsTableColumn('companycategory', this.translationReplaceService.category, false, 17).setSortable(true),
					);
					this.columns.push(
						new ReceiptsTableColumn('companycostcenter', this.translationReplaceService.costCenter, false, 18).setSortable(true),
					);
					this.columns.push(
						new ReceiptsTableColumn('companycostunit', this.translationReplaceService.costUnit, false, 19).setSortable(true),
					);
					this.columns.push(new ReceiptsTableColumn('companyproject', this.translationReplaceService.project, false, 20).setSortable(true));
				}
			} else if (this.tableType === 'invoices') {
				if (this.isDemo()) {
					this.columns.push(
						new ReceiptsTableColumn('description', this.translate.instant(_('Description')), true, 0, '', []).setSortable(true),
					);
					this.columns.push(
						new ReceiptsTableColumn('invoice_number', this.translate.instant(_('Invoice Number')), true, 1, '').setSortable(true),
					);
					this.columns.push(
						new ReceiptsTableColumn('companyadministration', this.translate.instant(_('Administration')), true, 2, '').setSortable(true),
					);
					this.columns.push(
						new ReceiptsTableColumn('companycostcenter', this.translate.instant(_('Cost center')), true, 3).setSortable(true),
					);
					this.columns.push(
						new ReceiptsTableColumn('purchasedate', this.translate.instant(_('Invoice date')), true, 4, '', ['text-nowrap']).setSortable(
							true,
						),
					);
					this.columns.push(
						new ReceiptsTableColumn('dueDate', this.translate.instant(_('Due date')), true, 5, '', ['text-nowrap']).setSortable(true),
					);
					this.columns.push(new ReceiptsTableColumn('amount', this.translate.instant(_('Amount')), true, 6).setSortable(true));
					this.columns.push(new ReceiptsTableColumn('vat_amount', this.translate.instant(_('VAT')), true, 7));
					this.columns.push(
						new ReceiptsTableColumn('expense_status', this.translate.instant(_('Status')), true, 8, '', ['text-center']).setSortable(true),
					);
					this.columns.push(
						new ReceiptsTableColumn('current_approver', this.translate.instant(_('Current approver')), true, 9).setSortable(true),
					);
					if ((this.user.hasCompanyFinanceRole() || this.user.hasCompanyAdminRole()) && (await this.getCompanyAuthorizationsCount()) > 0) {
						this.columns.push(
							new ReceiptsTableColumn('booking_status', this.translate.instant(_('Booking status')), true, 10).setSortable(true),
						);
					}
					this.columns.push(
						new ReceiptsTableColumn('attachments_count', this.translate.instant(_('Amount of attachments')), false, 11, '#', [
							'text-center',
							'text-nowrap',
						]).setSortable(true),
					);
					this.columns.push(
						new ReceiptsTableColumn('finance_type', this.translate.instant(_('Finance Type')), false, 12, '+/-', [
							'text-center',
						]).setSortable(true),
					);
					this.columns.push(
						new ReceiptsTableColumn(
							'lastcompanyexportdate',
							this.translate.instant(_('Last Export Date')),
							false,
							13,
							this.translate.instant(_('Last export')),
						).setSortable(true),
					);
					this.columns.push(new ReceiptsTableColumn('createdate', this.translate.instant(_('Create date')), false, 14).setSortable(true));
					this.columns.push(new ReceiptsTableColumn('updatedate', this.translate.instant(_('Update date')), false, 15).setSortable(true));
					if (this.newPaymentMethods()) {
						this.columns.push(
							new ReceiptsTableColumn('payment_method_id', this.translate.instant(_('Payment method')), false, 16).setSortable(true),
						);
					} else {
						this.columns.push(
							new ReceiptsTableColumn('paymentmethod', this.translate.instant(_('Payment method')), false, 16).setSortable(true),
						);
					}
					this.columns.push(
						new ReceiptsTableColumn(
							'accountnumber',
							this.translate.instant(_('Account number')),
							false,
							17,
							this.translate.instant(_('Account number')),
						).setSortable(true),
					);
					this.columns.push(new ReceiptsTableColumn('companycategory', this.translate.instant(_('Category')), false, 18).setSortable(true));
					this.columns.push(
						new ReceiptsTableColumn('companycostunit', this.translate.instant(_('Cost unit')), false, 19).setSortable(true),
					);
					this.columns.push(new ReceiptsTableColumn('companyproject', this.translationReplaceService.project, false, 20).setSortable(true));
				} else {
					this.columns.push(
						new ReceiptsTableColumn('description', this.translate.instant(_('Description')), true, 0, '', []).setSortable(true),
					);
					this.columns.push(
						new ReceiptsTableColumn('invoice_number', this.translate.instant(_('Invoice Number')), true, 1, '').setSortable(true),
					);
					this.columns.push(
						new ReceiptsTableColumn('companyadministration', this.translate.instant(_('Administration')), true, 2, '').setSortable(true),
					);
					this.columns.push(
						new ReceiptsTableColumn('attachments_count', this.translate.instant(_('Amount of attachments')), true, 3, '#', [
							'text-center',
							'text-nowrap',
						]).setSortable(true),
					);
					this.columns.push(
						new ReceiptsTableColumn('purchasedate', this.translate.instant(_('Invoice date')), true, 4, '', ['text-nowrap']).setSortable(
							true,
						),
					);
					this.columns.push(
						new ReceiptsTableColumn('dueDate', this.translate.instant(_('Due date')), true, 5, '', ['text-nowrap']).setSortable(true),
					);
					this.columns.push(new ReceiptsTableColumn('amount', this.translate.instant(_('Amount')), true, 6).setSortable(true));
					this.columns.push(new ReceiptsTableColumn('vat_amount', this.translate.instant(_('VAT')), true, 7));
					this.columns.push(
						new ReceiptsTableColumn('finance_type', this.translate.instant(_('Finance Type')), true, 8, '+/-', ['text-center']).setSortable(
							true,
						),
					);

					this.columns.push(
						new ReceiptsTableColumn('expense_status', this.translate.instant(_('Status')), true, 9, '', ['text-center']).setSortable(true),
					);
					this.columns.push(
						new ReceiptsTableColumn('current_approver', this.translate.instant(_('Current approver')), true, 10).setSortable(true),
					);

					if ((this.user.hasCompanyFinanceRole() || this.user.hasCompanyAdminRole()) && (await this.getCompanyAuthorizationsCount()) > 0) {
						this.columns.push(
							new ReceiptsTableColumn('booking_status', this.translate.instant(_('Booking status')), true, 11).setSortable(true),
						);
					}
					this.columns.push(
						new ReceiptsTableColumn(
							'lastcompanyexportdate',
							this.translate.instant(_('Last Export Date')),
							(this.user.hasCompanyFinanceRole() || this.user.hasCompanyAdminRole()) && this.company.authorizations.size === 0,
							12,
							this.translate.instant(_('Last export')),
						).setSortable(true),
					);
					this.columns.push(new ReceiptsTableColumn('createdate', this.translate.instant(_('Create date')), false, 13).setSortable(true));
					this.columns.push(new ReceiptsTableColumn('updatedate', this.translate.instant(_('Update date')), false, 14).setSortable(true));
					if (this.newPaymentMethods()) {
						this.columns.push(
							new ReceiptsTableColumn('payment_method_id', this.translate.instant(_('Payment method')), false, 15).setSortable(true),
						);
					} else {
						this.columns.push(
							new ReceiptsTableColumn('paymentmethod', this.translate.instant(_('Payment method')), false, 15).setSortable(true),
						);
					}
					this.columns.push(
						new ReceiptsTableColumn(
							'accountnumber',
							this.translate.instant(_('Account number')),
							false,
							16,
							this.translate.instant(_('Account number')),
						).setSortable(true),
					);
					this.columns.push(new ReceiptsTableColumn('companycategory', this.translate.instant(_('Category')), false, 17).setSortable(true));
					this.columns.push(
						new ReceiptsTableColumn('companycostcenter', this.translate.instant(_('Cost center')), false, 18).setSortable(true),
					);
					this.columns.push(
						new ReceiptsTableColumn('companycostunit', this.translate.instant(_('Cost unit')), false, 19).setSortable(true),
					);
					this.columns.push(new ReceiptsTableColumn('companyproject', this.translationReplaceService.project, false, 20).setSortable(true));
				}

				this.userIsConnectedToCompany = this.userService.isUserConnectedToCurrentCompany(this.company.id);
			}

			// Copy locally saved order to list.
			const SavedOrder = this.localStorageService.get('receipts_table_column_configuration_' + this.tableType) as ReceiptsTableColumn[];
			if (SavedOrder != null) {
				const seen = [];
				const columns = this.columns;
				SavedOrder.forEach(function (value) {
					columns.forEach(function (column) {
						if (column.id === value.id) {
							column.index = value.index;
							column.status = value.status;
							seen.push(value.id);
						}
					});
				});

				// All columns that are new should be disabled and at the bottom.
				const notSeen = columns.filter((f) => !seen.includes(f.id));
				notSeen.forEach(function (value) {
					columns.forEach(function (column) {
						if (column.id === value.id) {
							column.index += 1000;
							column.status = false;
						}
					});
				});
			}

			// Make sure listed os sorted based on index.
			this.columns.sort(function (a, b) {
				return a.index > b.index ? 1 : b.index > a.index ? -1 : 0;
			});

			// Copy list to change order without affecting table until order is saved.
			this.sortableColumns = cloneDeep(this.columns);
			if (!isValueSet(this.company)) {
				this.sortableColumns = this.sortableColumns.filter((e) => {
					switch (e.id) {
						case 'companyadministration':
						case 'companycategory':
						case 'companycostcenter':
						case 'companycostunit':
						case 'companyproject':
							return false;
						default:
							return true;
					}
				});
			}

			// Filter out disabled columns.
			this.columns = this.columns.filter((f) => f.status);
			if (!isValueSet(this.company)) {
				// pro users should not have access to certain columns
				this.columns = this.columns.filter((e) => {
					switch (e.id) {
						case 'companyadministration':
						case 'companycategory':
						case 'companycostcenter':
						case 'companycostunit':
						case 'companyproject':
							return false;
						default:
							return true;
					}
				});
			}
		});

		this.newFilters = this.filters.clone();
		this.newFilters.start = null;

		this.destroyCallbacks.push(() => {
			companySubscription.unsubscribe();
			subscription.unsubscribe();
		});

		importReceiptModalInBackground();
	}

	ngAfterViewInit() {
		if (this.pageStateService.isPageStateSaved()) {
			const selectedIds = this.pageStateService.getSelectedIds();

			this.receipts.forEach((receipt) => {
				if (selectedIds.includes(receipt.id)) {
					this.checkboxes[receipt.id] = { selected: true };
				}
			});

			const scrollPosition = this.pageStateService.getScrollPosition();
			this.viewportScroller.scrollToPosition([0, scrollPosition]);
		}
	}

	saveColumnOrder() {
		let newIndex = 0;
		this.sortableColumns.forEach(function (column) {
			column.index = newIndex;
			newIndex++;
		});
		this.localStorageService.set('receipts_table_column_configuration_' + this.tableType, this.sortableColumns);
		this.columns = cloneDeep(this.sortableColumns);
		// Filter out disabled columns.
		this.columns = this.columns.filter((f) => f.status);
	}

	changedCheckAll(event) {
		this.receipts.forEach((receipt) => {
			this.checkboxes[receipt.id] = this.checkAll.checked;
		});
		this.toggleActionDropDown();
		/* Because Angular does not auto-detect the change in value (only in object), the CD won't trigger unless we change a dummy property. */
		this.changeDetection = 1 - this.changeDetection;
	}

	getCheckedReceipts(): Receipt[] {
		return this.receipts.filter((r) => typeof this.checkboxes !== 'undefined' && this.checkboxes[r.id]);
	}

	checkBookable(): boolean {
		return this.getCheckedReceipts().every((r) => r.isBookable());
	}

	checkForceOCR(): boolean {
		return (
			this.getCheckedReceipts().every(
				(r) => r.userCanEdit(this.user, this.tableType) && !r.hasOCRStatus() && (r.hasDocuments() || r.hasImages()),
			) &&
			this.user.getPreferences().ocr &&
			this.user.canUseOCR()
		);
	}

	checkReceiptType(): boolean {
		return this.getCheckedReceipts().every((r) => r.type === 'receipt');
	}

	toggleSort(columnID) {
		const propertyToSortOn = this.sortingProperty[columnID];
		if (this.filters.sort === propertyToSortOn) {
			this.filters.sortorder = this.filters.sortorder === Order.DESCENDING ? Order.ASCENDING : Order.DESCENDING;
		} else {
			this.filters.sort = propertyToSortOn;
			this.filters.sortorder = Order.DESCENDING;
		}
		this.onSortingChange.emit({ sort: this.filters.sort, sortOrder: this.filters.sortorder });
	}

	ngOnDestroy(): void {
		this.destroyCallbacks.forEach((cb) => cb());
	}

	getSelectedCount(): number {
		return Object.keys(this.checkboxes).filter((checkbox) => this.checkboxes[checkbox]).length;
	}

	public async openFilters(): Promise<boolean> {
		const { ReceiptsFilterModalComponent } = await import('../modals/receipts-filter-modal/receipts-filter-modal.component');

		const modalRef = this.modalService.open(ReceiptsFilterModalComponent, {
			windowClass: 'modalRight',
			confirmDismiss: false,
		});
		modalRef.componentInstance.user = this.user;
		modalRef.componentInstance.filters = this.filters;
		modalRef.componentInstance.defaultFilters = this.defaultFilters;
		modalRef.componentInstance.type = this.tableType === 'expenses' || this.tableType === 'invoices' ? 'company' : 'user';
		modalRef.componentInstance.tableType = this.tableType;
		modalRef.componentInstance.activeTab = this.activatedRoute.snapshot.params?.tab;

		let filterSubscription = modalRef.componentInstance.filtersChanged.subscribe(() => {
			this.onFiltersChanged();
		});

		modalRef.result
			.then(() => {
				filterSubscription.unsubscribe();
				filterSubscription = null;
			})
			.catch(() => {});

		this.destroyCallbacks.push(() => {
			if (filterSubscription) {
				filterSubscription.unsubscribe();
			}
		});

		return false;
	}

	onFiltersChanged() {
		this.filtersChanged.emit(true);
		this.checkboxes = {};
	}

	async tableActionDelete(event) {
		const { DeleteModalComponent } = await import('~/app/modules/generic/components/modals/delete-modal/delete-modal.component');

		const modalRef = this.modalService.open(DeleteModalComponent);
		this.setupReceiptsModal(modalRef, true);
		return false;
	}

	async tableActionDuplicate(event) {
		const { DuplicateModalComponent } = await import('~/app/modules/generic/components/modals/duplicate-modal/duplicate-modal.component');

		const modalRef = this.modalService.open(DuplicateModalComponent);
		if (this.financeCanDuplicate()) {
			modalRef.componentInstance.duplicateByFinance = true;
		}
		this.setupReceiptsModal(modalRef, true);
		return false;
	}

	async tableActionMerge(event) {
		const { ReceiptMergeModalComponent } = await import('../modals/receipt-merge-modal/receipt-merge-modal.component');
		const modalRef = this.modalService.open(ReceiptMergeModalComponent);
		modalRef.componentInstance.tableType = this.tableType;
		this.setupReceiptsModal(modalRef, true);
		return false;
	}

	async tableActionSplit(event) {
		const { ReceiptSplitModalComponent } = await import('../modals/receipt-split-modal/receipt-split-modal.component');

		const modalRef = this.modalService.open(ReceiptSplitModalComponent);
		this.setupReceiptsModal(modalRef, true);
		return false;
	}

	async tableActionDownloadImages(event) {
		const { DownloadImagesModalComponent } = await import(
			'~/app/modules/generic/components/modals/download-images-modal/download-images-modal.component'
		);

		const modalRef = this.modalService.open(DownloadImagesModalComponent);
		this.setupReceiptsModal(modalRef);
		return false;
	}

	async tableActionRetractExpenses(event) {
		const { ExpensesModalComponent } = await import('~/app/modules/generic/components/modals/expenses-modal/expenses-modal.component');

		const modalRef = this.modalService.open(ExpensesModalComponent);
		modalRef.componentInstance.action = 'retract';
		this.setupReceiptsModal(modalRef);
		return false;
	}

	async tableActionSubmitExpenses(event) {
		const { ExpensesModalComponent } = await import('~/app/modules/generic/components/modals/expenses-modal/expenses-modal.component');

		const modalRef = this.modalService.open(ExpensesModalComponent);
		modalRef.componentInstance.action = 'submit';
		this.setupReceiptsModal(modalRef);
		return false;
	}

	async tableActionShare(event) {
		const { ReceiptsShareModalComponent } = await import('../modals/receipts-share-modal/receipts-share-modal.component');

		const modalRef = this.modalService.open(ReceiptsShareModalComponent);
		this.setupReceiptsModal(modalRef);
		return false;
	}

	async tableActionMove(event) {
		const { ReceiptsMoveModalComponent } = await import('../modals/receipts-move-modal/receipts-move-modal.component');

		const modalRef = this.modalService.open(ReceiptsMoveModalComponent);
		this.setupReceiptsModal(modalRef);
		return false;
	}

	async tableActionBulk(event) {
		const { BulkEditComponent } = await import('../modals/bulk-edit/bulk-edit.component');

		const modalRef = this.modalService.open(BulkEditComponent);
		this.setupReceiptsModal(modalRef);
		return false;
	}

	async TableActionOpenEditModal(event, receipt) {
		const report = this.reports?.find((report) => report.id === receipt?.report);

		if (event) {
			const el = event.target as Element;
			if (!!el.closest('td.no-click') || !!el.closest('div.no-click')) {
				return false;
			}
		}

		const redirectedToTXI = await this.companyFeatureFlagsService
			.getAllFeatureFlags(this.userService.getCurrentLoggedUser().company)
			.then((res) => {
				const hasNewTxInterfacesFeature = res.includes(PossibleCompanyFeatureFlags.TRANSACTION_INTERFACES);
				if (hasNewTxInterfacesFeature) {
					let url: string = this.router.serializeUrl(this.router.createUrlTree([`tx/${receipt.id}`], { relativeTo: this.activatedRoute }));

					if (this.tableType === 'personal') {
						url = this.router.serializeUrl(
							this.router.createUrlTree([`tx/${receipt.id}`], { relativeTo: this.activatedRoute, queryParams: { 'view-mode': 'submit' } }),
						);
					}
					// Open the transaction in a new tab
					// ctrl key is available on Windows and Linux, but not Mac.
					// The metaKey is the command key on Mac and the Windows key on Window and Linux.
					if (event.ctrlKey || event.metaKey) {
						window.open(url, '_blank');
						return true;
					}

					this.router.navigateByUrl(url);
					return true;
				}
				return false;
			});
		if (redirectedToTXI) {
			return;
		}
		const initialReceipt = receipt.clone();

		if (this.tableType === 'personal') {
			this.receiptFlowService.initWithReceipt(receipt, this.user);
		} else {
			this.receiptFlowService.initWithPreset(null, this.user);
		}

		const { ReceiptEditModalComponent } = await import('../modals/receipt-edit-modal/receipt-edit-modal.component');

		const modalRef = this.modalService.open(ReceiptEditModalComponent, {
			size: 'lg',
			beforeDismiss: () => {
				if (modalRef.componentInstance.hasReceiptBeenChanged()) {
					return new Promise((resolve, reject) => {
						const confirmModalRef = this.modalService.open(ConfirmModalComponent);
						confirmModalRef.componentInstance.type = 'text';
						confirmModalRef.componentInstance.title = this.translate.instant(_('Dismiss changes'));
						confirmModalRef.componentInstance.message = this.translate.instant(_('Are you sure that you want to dismiss your changes?'));
						const sub = confirmModalRef.componentInstance.result.subscribe((data) => {
							if (data.result) {
								const index = this.receipts.findIndex((r) => r.getID() === initialReceipt.getID());
								this.receipts[index] = initialReceipt;
								if (modalRef.componentInstance.authorizationFlowChanged) {
									// If the authorization flow has been changed but the receipt hasn't been saved, we want to reload the receipts.
									// This makes sure the accurate data of the receipt is represented in the table.
									this.receiptsChanged.emit(true);
								}
							}
							resolve(data.result);
							sub.unsubscribe();
						});
						this.destroyCallbacks.push(() => {
							sub.unsubscribe();
						});
					});
				}
			},
		});
		modalRef.componentInstance.receipt = receipt;
		modalRef.componentInstance.savedInReport = isValueSet(receipt.report);
		modalRef.componentInstance.is_invoice = receipt.isinvoice;
		modalRef.componentInstance.user = this.user;
		modalRef.componentInstance.tableType = this.tableType;
		modalRef.componentInstance.report = report;

		this.setupReceiptsModal(modalRef);
		return false;
	}

	async tableActionBookReceipts(event, bookType) {
		const { BookModalComponent } = await import('~/app/modules/generic/components/modals/book-modal/book-modal.component');

		const modalRef = this.modalService.open(BookModalComponent);
		modalRef.componentInstance.type = 'receipt';
		modalRef.componentInstance.bookType = bookType;
		this.setupReceiptsModal(modalRef);
		return false;
	}

	async tableActionChangeReceiptsStatus(event) {
		const { ChangeStatusModalComponent } = await import(
			'~/app/modules/generic/components/modals/change-status-modal/change-status-modal.component'
		);

		const modalRef = this.modalService.open(ChangeStatusModalComponent);
		this.setupReceiptsModal(modalRef);
		return false;
	}

	async tableActionForceOCR(event) {
		const { ForceOcrModalComponent } = await import('~/app/modules/generic/components/modals/force-ocr-modal/force-ocr-modal.component');

		const modalRef = this.modalService.open(ForceOcrModalComponent);
		this.setupReceiptsModal(modalRef);
		return false;
	}

	setupReceiptsModal(modalRef: NgbModalRef, resetCheckboxes = false) {
		modalRef.componentInstance.checkboxes = this.checkboxes;
		modalRef.componentInstance.user = this.user;
		if (this.isAdministrationOffice()) {
			modalRef.componentInstance.type = 'document';
		} else if (this.tableType === 'invoices') {
			modalRef.componentInstance.type = 'invoice';
		} else {
			modalRef.componentInstance.type = 'receipt';
		}
		let doneSubscription = modalRef.componentInstance.done.subscribe(() => {
			this.receiptsChanged.emit(true);
			if (resetCheckboxes) {
				this.checkAll.checked = false;
				for (const checkboxesKey in this.checkboxes) {
					delete this.checkboxes[checkboxesKey];
				}
			}
		});

		let nextReceiptSubscription = null;
		if (modalRef.componentInstance.nextReceipt) {
			nextReceiptSubscription = modalRef.componentInstance.nextReceipt.subscribe(() => {
				this.seenReceipts.set(modalRef.componentInstance.receipt.getID(), true);
				this.receiptsChanged.emit(false);
				this.findNextReceipt()
					.then((nextReceipt) => {
						if (nextReceipt) {
							modalRef.componentInstance.receipt = nextReceipt;
							modalRef.componentInstance.setupReceipt();
						} else {
							modalRef.componentInstance.finishBatchManagement();
							this.finishBatchManagement();
						}
					})
					.catch((e) => {
						modalRef.componentInstance.finishBatchManagement();
						this.finishBatchManagement();
					});
			});
		}

		let arrowSubscription = null;
		if (modalRef.componentInstance.arrow) {
			arrowSubscription = modalRef.componentInstance.arrow.subscribe((response) => {
				this.getNeighbourReceipt(response[0], response[1]).then((newReceipt) => {
					// Also check if modalRef still exists since it might have been closed during retrieval of newReceipt
					if (modalRef && newReceipt) {
						modalRef.componentInstance.receipt = newReceipt;
						modalRef.componentInstance.setupReceipt();
					}
				});
			});
		}

		const component = modalRef.componentInstance;

		modalRef.result
			.then((reason) => {
				doneSubscription.unsubscribe();
				doneSubscription = null;
				if (nextReceiptSubscription) {
					nextReceiptSubscription.unsubscribe();
					nextReceiptSubscription = null;
				}
				if (arrowSubscription) {
					arrowSubscription.unsubscribe();
					arrowSubscription = null;
				}
				this.finishBatchManagement();
			})
			.catch((reason) => {
				doneSubscription.unsubscribe();
				doneSubscription = null;
				if (nextReceiptSubscription) {
					nextReceiptSubscription.unsubscribe();
					nextReceiptSubscription = null;
				}
				if (arrowSubscription) {
					arrowSubscription.unsubscribe();
					arrowSubscription = null;
				}
				this.finishBatchManagement();
			})
			.then((r) => {
				if (component && typeof component.onDismiss === 'function') {
					component.onDismiss();
				}
			});

		this.destroyCallbacks.push(() => {
			if (doneSubscription) {
				doneSubscription.unsubscribe();
			}
			if (nextReceiptSubscription) {
				nextReceiptSubscription.unsubscribe();
				nextReceiptSubscription = null;
			}
			if (arrowSubscription) {
				arrowSubscription.unsubscribe();
				arrowSubscription = null;
			}
		});
	}

	getNeighbourReceipt(code: string, currentReceiptID: string): Promise<Receipt> {
		if (!this.newFilters.start) {
			this.newFilters.start = this.receipts.indexOf(this.receipts.find((receipt) => receipt.getID() === currentReceiptID));
			this.newFilters.max = 1;
		}

		return new Promise((resolve, reject) => {
			if (code === 'ArrowRight') {
				this.newFilters.start += 1;
				this.receiptAPIService.getReceipts(this.newFilters).then((r) => {
					if (r['data']['receipts']) {
						const newReceipt = new Receipt(r['data']['receipts'][0]);
						if (newReceipt.getID() !== currentReceiptID) {
							return resolve(newReceipt);
						} else {
							this.newFilters.start -= 1;
							return resolve(null);
						}
					} else {
						this.newFilters.start -= 1;
						return resolve(null);
					}
				});
			} else if (code === 'ArrowLeft') {
				if (this.newFilters.start - 1 >= 0) {
					this.newFilters.start -= 1;
					this.receiptAPIService.getReceipts(this.newFilters).then((r) => {
						const newReceipt = new Receipt(r['data']['receipts'][0]);
						return resolve(newReceipt);
					});
				} else {
					return resolve(null);
				}
			}
		});
	}

	finishBatchManagement() {
		this.batchCurrentPage = 1;
		this.seenReceipts = new Map();
	}

	// Function to find next receipt for current batch processing.
	findNextReceipt(): Promise<Receipt> {
		return new Promise((resolve, reject) => {
			this.filters.max = this.batchReceiptsPerPage;
			this.filters.start = (this.batchCurrentPage - 1) * this.batchReceiptsPerPage;
			this.receiptAPIService
				.getReceipts(this.filters)
				.then((r) => {
					const Receipts: any[] = r['data']['receipts'];
					if (Receipts != null) {
						const nextReceipt = Receipts.find((receipt) => {
							return !this.seenReceipts.has(receipt.id);
						});

						if (nextReceipt) {
							return resolve(new Receipt(nextReceipt));
						}

						// Load next page when all receipts have been seen and we have another page.
						if (!nextReceipt && r['data']['moreresults']) {
							this.batchCurrentPage++;
							return resolve(this.findNextReceipt());
						}
					}

					return resolve(null);
				})
				.catch((e) => {
					reject(e);
				});
		});
	}

	checkBoxClicked($event) {
		if ($event.altKey && this.previousClickedCheckbox) {
			const index1 = this.receipts.map((receipt) => receipt.getID()).indexOf(this.previousClickedCheckbox);
			const index2 = this.receipts.map((receipt) => receipt.getID()).indexOf($event.id);
			if (index1 !== index2) {
				const between = index1 < index2 ? this.receipts.slice(index1, index2) : this.receipts.slice(index2, index1);
				between.forEach((receipt) => {
					this.checkboxes[receipt.id] = true;
				});
			}
			/* Because Angular does not auto-detect the change in value (only in object), the CD won't trigger unless we change a dummy property. */
			this.changeDetection = 1 - this.changeDetection;
		}
		if ($event.event.ctrlKey && this.previousClickedCheckbox) {
			let seenFirstCheckbox = false;
			let seenLastCheckbox = false;
			this.receipts.forEach((receipt) => {
				if (seenFirstCheckbox && !seenLastCheckbox) {
					this.checkboxes[receipt.id] = this.checkboxes[this.previousClickedCheckbox];
				}

				if (receipt.id === this.previousClickedCheckbox) {
					seenFirstCheckbox = true;
				}

				if (receipt.id === $event.id) {
					seenLastCheckbox = true;
				}
			});
		}
		this.previousClickedCheckbox = $event.id;
		this.toggleActionDropDown();
	}

	private toggleActionDropDown(): void {
		setTimeout(() => {
			this.updateDropdownItems();
			const toggle = document.querySelector('#table-actions-dropdown');
			if (toggle?.getAttribute('aria-expanded') === 'false' && Object.keys(this.checkboxes).length > 0) {
				bsDropdownToggle(toggle);
			}
		}, 100);
	}

	updateDropdownItems(): DropdownItem[] {
		const t = (key) => this.translate.instant(key);

		const items: DropdownItem[] = [];
		if (this.tableType === 'personal' && this.getSelectedCount() >= 2) {
			items.push({ click: ($event) => this.tableActionBulk($event), label: t(_('Edit')) });
		}

		if (this.isAdministrationOffice()) {
			if (this.tableType === 'personal' && this.user.canSubmitExpenses()) {
				items.push({ click: ($event) => this.tableActionSubmitExpenses($event), label: t(_('Submit documents')) });
				items.push({ click: ($event) => this.tableActionRetractExpenses($event), label: t(_('Retract documents')) });
			}
		} else {
			if (this.tableType === 'personal' && this.user.canSubmitExpenses() && !this.reportDeclarationsModuleEnabled) {
				items.push({ click: ($event) => this.tableActionSubmitExpenses($event), label: t(_('Submit expenses')) });
			}
			if (this.tableType === 'personal' && this.user.canSubmitExpenses() && !this.reportDeclarationsModuleEnabled) {
				items.push({ click: ($event) => this.tableActionRetractExpenses($event), label: t(_('Retract expenses')) });
			}
		}

		items.push({ click: ($event) => this.tableActionShare($event), label: t(_('Share')) });

		if (this.tableType === 'personal' && this.user.getPreferences().groups) {
			items.push({ click: ($event) => this.tableActionMove($event), label: t(_('Move')) });
		}
		if (this.tableType === 'personal' || this.tableType === 'expenses') {
			items.push({ click: ($event) => this.tableActionDownloadImages($event), label: t(_('Download images')) });
		}
		items.push({ click: ($event) => this.openExportModalBySelectedCheckboxes(), label: t(_('Export')) });

		if (this.isAdministrationOffice()) {
			if (this.tableType === 'expenses' && this.user.canBookCompanyReceipts() && this.checkBookable()) {
				items.push({ click: ($event) => this.tableActionBookReceipts($event, 'documents'), label: t(_('Book documents')) });
			}
			if (this.tableType === 'expenses') {
				items.push({ click: ($event) => this.tableActionChangeReceiptsStatus($event), label: t(_('Change status')) });
			}
		} else {
			if (this.tableType === 'expenses' && this.user.canBookCompanyReceipts() && this.checkBookable()) {
				items.push({ click: ($event) => this.tableActionBookReceipts($event, 'expenses'), label: t(_('Book expenses')) });
			}
			if (this.tableType === 'expenses' || this.tableType === 'invoices') {
				items.push({ click: ($event) => this.tableActionChangeReceiptsStatus($event), label: t(_('Change status')) });
			}
		}

		if (this.tableType === 'invoices' && this.user.canBookCompanyReceipts() && this.checkBookable()) {
			items.push({ click: ($event) => this.tableActionBookReceipts($event, 'invoices'), label: t(_('Book invoices')) });
		}

		if (this.tableType === 'personal' || this.tableType === 'invoices' || this.financeCanDuplicate()) {
			items.push({ click: ($event) => this.tableActionDuplicate($event), label: t(_('Duplicate')) });
		}
		if ((this.tableType === 'personal' || this.tableType === 'invoices') && this.getSelectedCount() >= 2 && this.checkReceiptType()) {
			items.push({ click: ($event) => this.tableActionMerge($event), label: t(_('Merge')) });
		}
		if (
			this.tableType === 'personal' ||
			(this.tableType === 'invoices' &&
				(this.userIsConnectedToCompany || isValueSet(this.company?.parentcompany)) &&
				(this.user.hasCompanyInvoiceSubmitterRole() || this.user.hasCompanyFinanceRole()))
		) {
			items.push({ click: ($event) => this.tableActionSplit($event), label: t(_('Split')) });
		}
		if ((this.tableType === 'personal' && !this.reportDeclarationsModuleEnabled) || this.tableType === 'invoices') {
			items.push({ click: ($event) => this.tableActionDelete($event), label: t(_('Delete')) });
		}
		if (this.checkForceOCR()) {
			items.push({ click: ($event) => this.tableActionForceOCR($event), label: t(_('Run OCR scan')) });
		}

		/* Sort the actions alphabetically. */
		this.dropdownItems = items.sort((a, b) => (a.label === b.label ? 0 : a.label < b.label ? -1 : 1));
		return this.dropdownItems;
	}

	getCheckedBoxes(): Array<string> {
		const checkedBoxes = [];
		for (const check in this.checkboxes) {
			if (this.checkboxes[check]) {
				checkedBoxes.push(check);
			}
		}
		return checkedBoxes;
	}

	financeCanDuplicate(): boolean {
		const receiptIds: Array<string> = this.getCheckedBoxes();
		const selectedReceipts = this.receipts.filter((receipt) => receiptIds.includes(receipt.id));

		if (selectedReceipts.some((receipt) => !receipt.isBooked())) {
			// If any of the selected receipts is not booked, a finance user can't duplicate it.
			return false;
		}

		return (
			this.tableType === 'expenses' && this.user.hasCompanyFinanceRole() && this.company?.modules?.finance_can_duplicate?.enabled === true
		);
	}

	isAdministrationOffice(): boolean {
		return this.company && this.company.isadministrationoffice;
	}

	isDemo() {
		return this.company && this.company.isDemo();
	}

	private newPaymentMethods(): boolean {
		return this.company?.modulePaymentMethodsIsEnabled();
	}

	trackReceipt(index, receipt) {
		return receipt.id;
	}

	closeExportModal() {
		this.modalService.close(this.exportModal);
	}

	openExportModal(id: string) {
		this.exportIds = [id];
		this.modalService.open(this.exportModal);
	}

	openExportModalBySelectedCheckboxes() {
		this.exportIds = Object.keys(this.checkboxes)
			.map((key) => (this.checkboxes[key] ? key : null))
			.filter((e) => isValueSet(e));
		this.modalService.open(this.exportModal);
	}

	processExport(event: ExportRequest) {
		const exportRequest: ExportRequest = event;
		exportRequest.email = exportRequest.email.filter(Boolean).join(',');
		this.exportPromise = this.receiptAPIService.exportReceipts(exportRequest as ReceiptsExportRequest).then((res) => res.data.url);
	}

	public getEmptyViewTitle(): string {
		if (this.filtered || this.tableType === 'expenses') {
			return this.translate.instant(_('There are no documents that meet the requirements.'));
		} else if (!this.filtered && this.tableType === 'personal' && !this.isAdministrationOffice()) {
			return this.translate.instant(_('Your account does not contain any receipts at the moment.'));
		} else if (!this.filtered && this.tableType === 'personal' && this.isAdministrationOffice()) {
			return this.translate.instant(_('Your account does not contain any documents at the moment.'));
		} else {
			return '';
		}
	}

	public getEmptyViewSubtitle(): string {
		if (!this.filtered && this.tableType === 'personal' && !this.isAdministrationOffice()) {
			return this.translate.instant(_('You can add receipts by clicking the button in the top right corner.'));
		} else if (!this.filtered && this.tableType === 'personal' && this.isAdministrationOffice()) {
			return this.translate.instant(_('You can add documents by clicking the button in the top right corner.'));
		} else {
			return '';
		}
	}

	/* tslint:disable:member-ordering */
	public getCompanyAuthorizationsCount = memoize(async (): Promise<number> => {
		if ((await this.companyFeatureFlagsService.getAllFeatureFlags(this.company.id))?.includes(PossibleCompanyFeatureFlags.AAV2)) {
			const aav2IntegrationCount: number = (
				await this.accountingIntegrationV2Service.getAllV2ConnectedAccountingIntegrations(this.company.id)
			).length;
			return aav2IntegrationCount + this.company.authorizations?.size;
		}
		return this.company.authorizations?.size;
	});
}
