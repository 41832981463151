import { environment } from '~/environments/environment';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { roundToX } from '#/util/numbers';
import { isValueSet, stringIsSetAndFilled } from '#/util/values';
import { Frequency } from '#/models/utils/frequency';
import { ExportTemplate, TrustedContact } from '#/models/user/user-properties';
import { Company } from '#/models/company/company.model';
import { arrayIsSetAndFilled, removeDuplicatesFromArray } from '#/util/arrays';
import { UserType } from '#/services/user/partial-users.service';
import { convertDateToYMD } from '#/util/date';
import { PossibleCompanyFeatureFlags } from '#/models/company/possible-feature-flags';
import { OcrTypeModel } from '#/models/ocrType.model';
import { ScreenDisplayOption } from '#/models/screenDisplayOption';

export enum UserRole {
	User = 1,
	Company = 2,
	CompanyAdmin = 4,
	CompanyManager = 8,
	Pro = 16,
	OCR = 32,
	CompanyFinance = 128,
	PartnerUser = 256,
	CompanyInvoiceSubmitter = 512,
	CompanyInvoiceApprover = 1024,
	CompanyExpenseSubmitter = 2048,
	CompanyRegistrationSubmitter = 262144,
	CompanyRegistrationsManager = 524288,
	ReadAdmin = 16384, // Can only read data
	ReadWriteAdmin = 32768, // Everything Admin can do except deleting companies
	Support = 8192, // Support roles can do everything a ReadWriteAdmin does except modify its company
	BetaTester = 65536,
	Admin = 131072, // Super Admin, can delete companies
}

export enum ApiAdminRole {
	ReadAdmin = 'readonly',
	ReadWriteAdmin = 'readwrite',
	GlobalAdmin = 'global',
	Support = 'support',
}

export class Agreement {
	type: string;
	version: string;
	sign_date: Date;
	agreementType: 'user' | 'company' = 'user';

	static fromData(data, agreementType): Agreement[] {
		if (data == null) {
			return [];
		}
		return data.map((item) => new Agreement({ ...item, agreementType: agreementType }));
	}

	constructor(data = null) {
		if (data) {
			Object.assign(this, data);
			if (data.sign_date) {
				this.sign_date = Date.parse(data.sign_date) > 0 ? new Date(data.sign_date) : null;
			}
		}
	}

	getTitle(): string {
		return 'title';
	}

	getType(): string {
		return this.type;
	}

	getVersion(): string {
		return this.version;
	}

	isSigned(): boolean {
		return this.getSignDate() && this.getSignDate() != null;
	}

	getSignDate(): Date {
		return this.sign_date;
	}

	getAgreementType(): string {
		return this.agreementType;
	}

	setAgreementType(agreementType) {
		this.agreementType = agreementType;
	}

	getDownloadURL(): string {
		const api = environment.api_url;
		return `${api}/api/v1/${this.getAgreementType()}/downloadAgreement?version=${this.getVersion()}&type=${this.getType()}`;
	}

	clone(): Agreement {
		return new Agreement(JSON.parse(JSON.stringify(this)));
	}
}

export class Trial {
	type = 'week';
	description: string;
	buttonText: string;
	trialCode: string = null;

	constructor(data = null) {
		if (data) {
			Object.assign(this, data);
		}

		if (this.getType() === 'month') {
			this.buttonText = _('Start 1 month trial');
			this.description = _(
				'Receive 1 month free trial of Klippa Pro. No payment required and after the trial your account automatically goes back to Klippa Basic. No strings attached.',
			);
		} else {
			this.buttonText = _('Start 7 day trial');
			this.description = _(
				'Receive a 7 day free trial of Klippa Pro. No payment required and after the trial your account automatically goes back to Klippa Basic. No strings attached.',
			);
		}
	}

	getDescription(): string {
		return this.description;
	}

	getButtonText(): string {
		return this.buttonText;
	}

	getTrialCode(): string {
		return this.trialCode;
	}

	getType(): string {
		return this.type;
	}
}

export class Subscription {
	id: string;
	external_type: string;
	account: string;
	type: string;
	start_date: Date;
	end_date: Date;
	recurring: boolean;
	status: string;
	plan: string;
	is_trial: boolean;
	sepa_debit?: any;

	static fromData(data): Subscription[] {
		if (data == null) {
			return [];
		}
		return data.map((item) => new Subscription(item));
	}

	constructor(data = null) {
		if (data) {
			Object.assign(this, data);
			if (data.start_date) {
				this.start_date = Date.parse(data.start_date) > 0 ? new Date(data.start_date) : null;
			}
			if (data.end_date) {
				this.end_date = Date.parse(data.end_date) > 0 ? new Date(data.end_date) : null;
			}
		}
	}

	getID(): string {
		return this.id;
	}

	getStartDate(): Date {
		return this.start_date;
	}

	getEndDate(): Date {
		return this.end_date;
	}

	shouldShowEndDate(): boolean {
		return !this.isAppStoreTrial();
	}

	getStatus() {
		return this.status;
	}

	isTrial() {
		return this.is_trial;
	}

	isAppStoreTrial(): boolean {
		return this.getStatus() === 'trialing';
	}

	isRecurring(): boolean {
		return this.recurring;
	}

	isCanceled(): boolean {
		return this.getStatus() === 'canceled';
	}

	canBeCanceled(): boolean {
		return this.isRecurring() && !this.isCanceled() && this.getExternalType() !== 'apple_inapp';
	}

	canBeUpdated(): boolean {
		return this.isRecurring() && !this.isCanceled() && this.getExternalType() === 'stripe_cc';
	}

	getType() {
		return this.type;
	}

	getExternalType() {
		return this.external_type;
	}

	isInApp(): boolean {
		return this.getExternalType() === 'apple_inapp' || this.getExternalType() === 'android_inapp';
	}

	getSEPADebit(): any {
		return this.sepa_debit;
	}

	getExternalTypeLabel(): string {
		switch (this.getExternalType()) {
			case 'stripe_cc':
				return _('Credit card');
			case 'stripe_ideal':
				return _('iDeal');
			case 'stripe_ideal_recurring':
				return _('iDeal (Recurring direct debit)');
			case 'apple_inapp':
				return _('Apple App Store');
			case 'android_inapp':
				return _('Google Play');
			default:
				return this.getExternalType();
		}
	}

	getAccount(): string {
		return this.account;
	}

	getAccountLabel(): string {
		switch (this.getAccount()) {
			case '':
				return _('Unknown');
			default:
				return this.getAccount();
		}
	}

	getPlan(): string {
		return this.plan;
	}

	getPlanLabel(): string {
		switch (this.getPlan()) {
			case 'year':
				return _('Year');
			case 'month':
				return _('Month');
			case 'week':
				return _('Week');
			default:
				return this.getPlan();
		}
	}

	getRecurringLabel(): string {
		if (!this.isCanceled()) {
			if (this.isRecurring()) {
				return _('Yes');
			} else {
				return _('No');
			}
		} else {
			return _("Won't be renewed");
		}
	}

	getStatusLabel(): string {
		switch (this.getStatus()) {
			case 'active':
				return _('Active');
			case 'canceled':
				return _('Canceled');
			case 'unpaid':
				return _('Unpaid');
			case 'past_due':
				return _('Payment Past Due');
			case 'ended':
				return _('Ended');
			case 'trialing':
				if (this.isAppStoreTrial() && (this.getExternalType() === 'apple_inapp' || this.getExternalType() === 'android_inapp')) {
					return _('In trial');
				}
				return _('Waiting');
			case 'incomplete':
				return _('Incomplete');
			default:
				return this.getStatus();
		}
	}

	getMandateURL(): string {
		if (this.getExternalType() === 'stripe_ideal_recurring' && this.getSEPADebit() != null) {
			return this.getSEPADebit().mandate_url;
		}
		return null;
	}

	isPro(): boolean {
		return this.getType() === 'pro';
	}
}

export class Profile {
	id: string;
	name: string;
	email: string;
	picture: string;

	constructor(data = null) {
		if (data) {
			Object.assign(this, data);
		}
	}
}

export class Authorization {
	connectiondate: Date;
	id: string;
	profile: Profile;
	networkName: string;

	constructor(networkName: string, data = null) {
		if (data) {
			Object.assign(this, data);

			if (data.connectiondate) {
				this.connectiondate = Date.parse(data.connectiondate) > 0 ? new Date(data.connectiondate) : null;
			}

			if (data.profile) {
				this.profile = new Profile(data.profile);
			}

			this.networkName = networkName;
		}
	}
}

export class Authorizations {
	Google?: Authorization;
	Facebook?: Authorization;
	Apple?: Authorization;
	authorizations: Authorization[];

	constructor(data = null) {
		if (data) {
			Object.assign(this, data);

			if (data.Google) {
				this.Google = new Authorization('google', data.Google);
			}

			if (data.Facebook) {
				this.Facebook = new Authorization('apple', data.Facebook);
			}

			if (data.Apple) {
				this.Apple = new Authorization('facebook', data.Apple);
			}
		}

		this.authorizations = [this.Google, this.Facebook, this.Apple];
	}
}

export class Preferences {
	language: string;
	currency: string;
	vatmode: boolean;
	ocr: boolean;
	paymentinfo: boolean;
	travel_expense_declaration_cents_per_km: number;
	financetype: boolean;
	receipt_type: boolean;
	groups: boolean;
	tags: boolean;
	show_miles_instead_of_km: boolean;
	ocr_mode: OcrTypeModel;
	display_mode = 'default';
	nextActionPreference: 'NEXT_TASK' | 'NEXT_TRANSACTION' = 'NEXT_TASK';
	notification_frequency = Frequency.WEEKLY;
	notification_moment = Frequency.PERIOD_START;
	transactionInterfaceDisplayMode: ScreenDisplayOption;

	constructor(data = null) {
		if (data) {
			Object.assign(this, data);
		}
	}

	getCurrency(): string {
		return this.currency;
	}

	getLanguage(): string {
		return this.language;
	}

	get travelExpenseDeclarationCentsPerKm(): string {
		return roundToX(this.travel_expense_declaration_cents_per_km, 2).toString(10);
	}

	set travelExpenseDeclarationCentsPerKm(cents: string) {
		if (!stringIsSetAndFilled(cents)) {
			cents = '0';
		}
		cents = cents.replace(/,/g, '.');
		const value = parseFloat(cents);
		this.travel_expense_declaration_cents_per_km = isNaN(value) ? 0 : value;
	}

	get travelExpenseDeclarationCentsPerMile(): string {
		return roundToX(this.travel_expense_declaration_cents_per_km / 0.621371192, 2).toString(10);
	}

	set travelExpenseDeclarationCentsPerMile(cents: string) {
		if (!stringIsSetAndFilled(cents)) {
			cents = '0';
		}
		cents = cents.replace(/,/g, '.');
		const value = parseFloat(cents) * 0.621371192;
		this.travel_expense_declaration_cents_per_km = isNaN(value) ? 0 : value;
	}
}

export class CompanyDetails {
	title: string;
	accountnumber: string;
	costcenter: string;
	module_leveled_travel_expense_compensation_per_km?: number;
	showPretransactionsMenu?: boolean;

	static fromData(data): CompanyDetails[] {
		if (data == null) {
			return [];
		}
		return data.map((item) => new CompanyDetails(item));
	}

	constructor(data = null) {
		if (data) {
			Object.assign(this, data);
		}
	}

	getTitle() {
		return this.title;
	}

	getAccountNumber() {
		return this.accountnumber;
	}

	getCostCenter() {
		return this.costcenter;
	}

	get moduleLeveledTravelExpenseCompensationPerKM(): string {
		if (isValueSet(this.module_leveled_travel_expense_compensation_per_km)) {
			return roundToX(this.module_leveled_travel_expense_compensation_per_km, 2).toString(10);
		}

		return null;
	}

	set moduleLeveledTravelExpenseCompensationPerKM(cents: string) {
		if (stringIsSetAndFilled(cents)) {
			cents = cents.replace(/,/g, '.');
			const value = parseFloat(cents);
			this.module_leveled_travel_expense_compensation_per_km = isNaN(value) ? 0 : value;
		} else {
			this.module_leveled_travel_expense_compensation_per_km = null;
		}
	}

	get moduleLeveledTravelExpenseCompensationPerMile(): string {
		if (isValueSet(this.module_leveled_travel_expense_compensation_per_km)) {
			return roundToX(this.module_leveled_travel_expense_compensation_per_km / 0.621371192, 2).toString(10);
		}

		return null;
	}

	set moduleLeveledTravelExpenseCompensationPerMile(cents: string) {
		if (stringIsSetAndFilled(cents)) {
			cents = cents.replace(/,/g, '.');
			const value = parseFloat(cents) * 0.621371192;
			this.module_leveled_travel_expense_compensation_per_km = isNaN(value) ? 0 : value;
		} else {
			this.module_leveled_travel_expense_compensation_per_km = null;
		}
	}
}
export class UserRequest {
	data: { user: User };
	request_id: string;
	result: string;
	constructor(data) {
		if (data) {
			Object.assign(this, data);
			if (data?.data?.user) {
				this.data.user = new User(data.data.user);
			}
		}
	}
}

export class User {
	id: string;
	email: string;
	name: string;
	status: boolean;
	type: UserType;
	emailvalidated: boolean;
	createdate: Date;
	updatedate: Date;
	userrole: UserRole;
	originaluserrole: UserRole;
	gender: string;
	dateofbirth: string | Date;
	preferences: Preferences;
	company: string;
	companytitle: string;
	companydetails: CompanyDetails;
	companygroups: string[];
	company_manager_of: string[];
	inboundmailkey: string;
	trustedcontacts?: TrustedContact[];
	authorizations: Authorizations;
	subscriptions: Subscription[] = [];
	export_template?: ExportTemplate;
	agreements?: Agreement[] = [];
	partner_key: string;
	is_partner_user: boolean;
	// Internal properties.
	manager: boolean;
	finance: boolean;
	invoice_submitter: boolean;
	invoice_approver: boolean;
	expense_submitter: boolean;
	registrationSubmitter: boolean;
	registrationManager: boolean;
	admin: boolean;
	avatarURL: string;
	password: string = null;
	currentpassword: string = null;
	allowImpersonation: { [p: string]: number };

	static fromData(data): User[] {
		if (data == null) {
			return [];
		}
		return data.map((item) => new User(item));
	}

	static getAllRoles(roleCombination: number) {
		return Object.values(UserRole).filter((e: any) => {
			// tslint:disable-next-line:no-bitwise
			return (roleCombination & e) === e;
		});
	}

	constructor(data = null) {
		if (data) {
			Object.assign(this, data);

			if (data.agreements) {
				this.agreements = Agreement.fromData(data.agreements, 'user');
			}

			if (data.preferences) {
				this.preferences = new Preferences(data.preferences);
			}

			if (data.subscriptions) {
				this.subscriptions = Subscription.fromData(data.subscriptions);
			}

			if (data.companydetails) {
				this.companydetails = new CompanyDetails(data.companydetails);
			}

			if (data.export_template) {
				this.export_template = new ExportTemplate(data.export_template);
			}

			this.manager = this.hasCompanyManagerRole();
			this.finance = this.hasCompanyFinanceRole();
			this.invoice_approver = this.hasCompanyInvoiceApproverRole();
			this.invoice_submitter = this.hasCompanyInvoiceSubmitterRole();
			this.expense_submitter = this.hasCompanyExpenseSubmitterRole();
			this.registrationSubmitter = this.hasCompanyRegistrationSubmitterRole();
			this.registrationManager = this.hasCompanyRegistrationManagerRole();
			this.admin = this.hasCompanyAdminRole();

			if (data.authorizations) {
				this.authorizations = new Authorizations(data.authorizations);
			} else {
				this.authorizations = new Authorizations();
			}

			if (data.createdate) {
				this.createdate = Date.parse(data.createdate) > 0 ? new Date(data.createdate) : null;
			}

			if (data.updatedate) {
				this.updatedate = Date.parse(data.updatedate) > 0 ? new Date(data.updatedate) : null;
			}

			if (this.dateofbirth === '0001-01-01') {
				this.dateofbirth = null;
			}
		}
	}

	getID() {
		return this.id;
	}

	setAvatarURL(avatarURL: string) {
		this.avatarURL = avatarURL;
	}

	getAvatarURL(): string | null {
		if (this.avatarURL) {
			return this.avatarURL;
		}

		if (this.authorizations) {
			if (this.authorizations.Facebook) {
				return this.authorizations.Facebook.profile.picture;
			}

			if (this.authorizations.Google) {
				return this.authorizations.Google.profile.picture;
			}
		}

		return null;
	}

	getDisplayName(): string {
		if (this.name !== '') {
			return this.name;
		}

		return this.email;
	}

	getName(): string {
		return this.name;
	}

	get Label(): string {
		return `${this.getName()} (${this.getEmail()})`;
	}

	getEmail(): string {
		return this.email;
	}

	isEmailValidated(): boolean {
		return this.emailvalidated;
	}

	getGender(): string {
		return this.gender;
	}

	getDateofBirth() {
		if (this.dateofbirth && this.dateofbirth instanceof Date) {
			return convertDateToYMD(this.dateofbirth);
		}
		return this.dateofbirth;
	}

	setDateofBirth(day: number, month: number, year: number) {
		this.dateofbirth = new Date(year, month - 1, day);
	}

	getPreferences(): Preferences {
		return this.preferences;
	}

	isPartnerUser(): boolean {
		return this.is_partner_user;
	}

	getPartnerKey(): string {
		return this.partner_key;
	}

	getSubscriptions(): Subscription[] {
		if (!this.subscriptions) {
			return [];
		}
		return this.subscriptions;
	}

	getProSubscriptions(): Subscription[] {
		return this.getSubscriptions().filter((s) => s.isPro());
	}

	getCompany(): string {
		return this.company;
	}

	getCompanyTitle(): string {
		return this.companytitle;
	}

	getCompanyDetails(): CompanyDetails {
		return this.companydetails;
	}

	getCompanyGroups(): string[] {
		return Array.from(new Set(this.companygroups));
	}

	getCompanyManagerOf(): string[] {
		return this.company_manager_of;
	}

	getStatus(): boolean {
		return this.status;
	}

	getCreateDate(): Date {
		return this.createdate;
	}

	getCompanyRoles(): string[] {
		const roles = [];

		if (this.hasCompanyExpenseSubmitterRole()) {
			roles.push(_('Expense submitter'));
		}

		if (this.hasCompanyManagerRole()) {
			roles.push(_('Manager'));
		}

		if (this.hasCompanyFinanceRole()) {
			roles.push(_('Finance'));
		}

		if (this.hasCompanyInvoiceSubmitterRole()) {
			roles.push(_('Invoice submitter'));
		}

		if (this.hasCompanyInvoiceApproverRole()) {
			roles.push(_('Invoice approver'));
		}

		if (this.hasCompanyAdminRole()) {
			roles.push(_('Admin'));
		}
		return roles;
	}

	getRoles(): string[] {
		const roles = [];

		if (this.hasProRole()) {
			roles.push(_('Pro'));
		}

		if (this.hasSuperAdminRole()) {
			roles.push(_('Super Admin'));
		}

		if (this.hasReadWriteAdminRole()) {
			roles.push(_('Read Write Admin'));
		}

		if (this.hasReadAdminRole()) {
			roles.push(_('Read Admin'));
		}

		if (this.hasSupportRole()) {
			roles.push(_('Support'));
		}

		return roles;
	}

	getHighestAdminRole(): UserRole {
		if (this.hasSuperAdminRole()) {
			return UserRole.Admin;
		}
		if (this.hasReadWriteAdminRole()) {
			return UserRole.ReadWriteAdmin;
		}
		if (this.hasReadAdminRole()) {
			return UserRole.ReadAdmin;
		}
	}

	/**
	 * Tests whether the user has a specific role.
	 * @param role
	 */
	hasRole(role: UserRole, checkOriginalRole: boolean = false): boolean {
		// We disable no-bitwise check of tslint here because it's a proper bitwise operator.
		// tslint:disable-next-line:no-bitwise
		if (checkOriginalRole) {
			return (this.userrole & role) === role || (this.originaluserrole & role) === role;
		}
		return (this.userrole & role) === role;
	}

	hasUserRole(): boolean {
		return this.hasRole(UserRole.User);
	}

	hasCompanyRole(): boolean {
		return this.hasRole(UserRole.Company);
	}

	hasCompanyAdminRole(): boolean {
		return this.hasRole(UserRole.CompanyAdmin);
	}

	hasCompanyManagerRole(): boolean {
		return this.hasRole(UserRole.CompanyManager);
	}

	hasProRole(): boolean {
		return this.hasRole(UserRole.Pro);
	}

	hasBetaTesterRoleRole(): boolean {
		return this.hasRole(UserRole.BetaTester);
	}

	hasOcrUserRole(): boolean {
		return this.hasRole(UserRole.OCR);
	}

	hasCompanyFinanceRole(): boolean {
		return this.hasRole(UserRole.CompanyFinance);
	}

	hasCompanyInvoiceSubmitterRole(): boolean {
		return this.hasRole(UserRole.CompanyInvoiceSubmitter);
	}

	hasCompanyRegistrationSubmitterRole(): boolean {
		return this.hasRole(UserRole.CompanyRegistrationSubmitter);
	}

	hasCompanyRegistrationManagerRole(): boolean {
		return this.hasRole(UserRole.CompanyRegistrationsManager);
	}

	hasCompanyInvoiceApproverRole(): boolean {
		return this.hasRole(UserRole.CompanyInvoiceApprover);
	}

	hasCompanyExpenseSubmitterRole(): boolean {
		return this.hasRole(UserRole.CompanyExpenseSubmitter);
	}

	public getHomePage(company?: Company, flags?: Array<PossibleCompanyFeatureFlags>): string {
		if (
			arrayIsSetAndFilled(flags) &&
			flags.includes(PossibleCompanyFeatureFlags.GALLERY_VIEW) &&
			this.companydetails?.showPretransactionsMenu
		) {
			return '/gallery-view';
		}
		if (isValueSet(company) && company.moduleReportDeclarationsIsEnabled()) {
			if (company?.modules?.cardReports.enabled) {
				if (this.hasRole(UserRole.CompanyExpenseSubmitter)) {
					return '/reports/mine/card';
				} else if (this.canManageExpenses()) {
					return '/reports/manage/card';
				}
			}
			if (this.hasRole(UserRole.CompanyExpenseSubmitter)) {
				return '/reports/mine';
			}
			if (this.canManageExpenses() && company.canUseExpenses()) {
				return '/reports/manage';
			}
		}
		if (isValueSet(company)) {
			/* Return the home page for this user */
			if (this.canUseDashboard() && company.canUseExpenses()) {
				return '/dashboard';
			} else if (this.canManageExpenses() && company.canUseExpenses()) {
				return '/company-expenses';
			} else if (this.canUseInvoices() && company.canUseInvoices()) {
				return '/company-invoices';
			}

			return '/settings';
		} else {
			return this.getHomePageRegardlessOfCompanySettings();
		}
	}

	getHomePageRegardlessOfCompanySettings() {
		if (this.canUseDashboard()) {
			return '/dashboard';
		} else if (this.canManageExpenses()) {
			return '/dm/dashboard';
		} else if (this.canUseInvoices()) {
			return '/dm/invoices';
		}
		return '/settings';
	}

	get managerRole(): boolean {
		return this.hasCompanyManagerRole();
	}

	set managerRole(val) {
		this.manager = val;
	}

	get financeRole(): boolean {
		return this.hasCompanyFinanceRole();
	}

	set financeRole(val) {
		this.finance = val;
	}

	get invoiceSubmitterRole(): boolean {
		return this.hasCompanyInvoiceSubmitterRole();
	}

	set invoiceSubmitterRole(val) {
		this.invoice_submitter = val;
	}

	get invoiceApproverRole(): boolean {
		return this.hasCompanyInvoiceApproverRole();
	}

	set invoiceApproverRole(val) {
		this.invoice_approver = val;
	}

	get expenseSubmitterRole(): boolean {
		return this.hasCompanyExpenseSubmitterRole();
	}

	set expenseSubmitterRole(val) {
		this.expense_submitter = val;
	}

	set registrationSubmitterRole(val) {
		this.registrationSubmitter = val;
	}

	set registrationManagerRole(val) {
		this.registrationManager = val;
	}

	get adminRole(): boolean {
		return this.hasCompanyAdminRole();
	}

	set adminRole(val) {
		this.admin = val;
	}

	/* Following checks are only for the user edit modal. No roles involved but properties that can be toggled. */
	canSubmitCompanyReceipts(): boolean {
		return this.expense_submitter || this.hasReadWriteAdminRoleOrHigher();
	}

	canSeeCompanyReceipts(): boolean {
		return this.manager || this.finance || this.admin || this.hasReadAdminRoleOrHigher();
	}

	canDeclineCompanyReceipts(): boolean {
		return this.manager || this.admin;
	}

	canAcceptCompanyReceipts(): boolean {
		return this.admin;
	}

	canApproveCompanyReceipts(): boolean {
		return this.manager || this.admin;
	}

	public canSubmitRegistrations(): boolean {
		return this.registrationSubmitter || this.registrationManager;
	}

	public canManageRegistrations(): boolean {
		return this.registrationManager;
	}

	public canSubmitPersonalRegistrations(): boolean {
		return this.registrationSubmitter;
	}

	canProcessCompanyReceipts(): boolean {
		return this.finance || this.admin;
	}

	canBookCompanyReceipts(): boolean {
		return this.finance || this.admin;
	}

	canSubmitCompanyInvoices(): boolean {
		return this.admin || this.invoice_submitter;
	}

	canApproveCompanyInvoices(): boolean {
		return this.admin || this.invoice_approver;
	}

	canManageCompany(): boolean {
		return this.admin;
	}

	canListCompanyAuthorizationFlows(): boolean {
		return this.admin || this.finance;
	}

	canSetCompanyAuthorizationFlow(): boolean {
		return this.admin || this.finance;
	}

	hasOCRRole(): boolean {
		return this.hasRole(UserRole.OCR);
	}

	hasSuperAdminRole(checkOriginalRole: boolean = false): boolean {
		return this.hasRole(UserRole.Admin, checkOriginalRole);
	}

	hasReadWriteAdminRole(checkOriginalRole: boolean = false): boolean {
		return this.hasRole(UserRole.ReadWriteAdmin, checkOriginalRole);
	}

	public hasReadWriteAdminRoleOrHigher(checkOriginalRole: boolean = false): boolean {
		return (
			this.hasRole(UserRole.ReadWriteAdmin, checkOriginalRole) ||
			this.hasSuperAdminRole(checkOriginalRole) ||
			this.hasSupportRole(checkOriginalRole)
		);
	}

	public hasReadAdminRole(checkOriginalRole: boolean = false): boolean {
		return this.hasRole(UserRole.ReadAdmin, checkOriginalRole);
	}

	public hasSupportRole(checkOriginalRole: boolean = false): boolean {
		return this.hasRole(UserRole.Support, checkOriginalRole);
	}

	public hasReadAdminRoleOrHigher(checkOriginalRole: boolean = false): boolean {
		return (
			this.hasRole(UserRole.ReadAdmin, checkOriginalRole) ||
			this.hasReadWriteAdminRoleOrHigher(checkOriginalRole) ||
			this.hasSupportRole(checkOriginalRole)
		);
	}

	canViewOCRTab(): boolean {
		return this.hasReadAdminRoleOrHigher() || this.hasOCRRole();
	}

	canUseOCR(): boolean {
		return this.hasProRole() || this.hasCompanyRole() || this.hasReadAdminRoleOrHigher();
	}

	canUseVAT(): boolean {
		return this.hasProRole() || this.hasCompanyRole() || this.hasReadAdminRoleOrHigher();
	}

	canUsePaymentInfo(): boolean {
		return this.hasProRole() || this.hasCompanyRole() || this.hasReadAdminRoleOrHigher();
	}

	canUseFinanceType(): boolean {
		return this.hasProRole() || this.hasCompanyRole() || this.hasReadAdminRoleOrHigher();
	}

	canUseTravelDeclaration(): boolean {
		return this.hasProRole() || this.hasCompanyRole() || this.hasReadAdminRoleOrHigher();
	}

	showTravelDeclaration(): boolean {
		return this.canUseTravelDeclaration();
	}

	canConfigureDashboardColumns(): boolean {
		return this.hasProRole() || this.hasCompanyRole() || this.hasReadAdminRoleOrHigher();
	}

	canConfigureNotifications(): boolean {
		return (
			this.hasCompanyManagerRole() || this.hasCompanyFinanceRole() || this.hasCompanyAdminRole() || this.hasCompanyInvoiceApproverRole()
		);
	}

	canSubmitExpenses(): boolean {
		return this.hasCompanyExpenseSubmitterRole();
	}

	canBulkUpload(): boolean {
		return this.hasProRole() || this.hasCompanyRole() || this.hasReadAdminRoleOrHigher();
	}

	canDocumentSplit(): boolean {
		return this.hasProRole() || this.hasCompanyRole() || this.hasReadAdminRoleOrHigher();
	}

	canDeleteAccount(): boolean {
		return !this.hasCompanyRole();
	}

	canManageAgreements(): boolean {
		return !this.hasCompanyRole();
	}

	canStartKlippaProTrial(): boolean {
		return !this.hasActiveSubscription() && !this.hadTrialSubscription();
	}

	canViewKlippaProTab(): boolean {
		if ((!this.hasCompanyRole() && !this.isPartnerUser()) || this.getSubscriptions().length > 0) {
			return true;
		}
		if (this.canBecomePro()) {
			return true;
		}
		return false;
	}

	canViewKlippaProSubscriptions(): boolean {
		return this.getSubscriptions().length > 0;
	}

	canGetKlippaProSubscription(): boolean {
		return !this.hasCompanyRole();
	}

	canViewCompanyTab(): boolean {
		return this.hasCompanyFinanceRole() || this.hasCompanyAdminRole();
	}

	canViewPersonalStatistics(): boolean {
		return this.canUseDashboard();
	}

	canViewCompanyStatistics(): boolean {
		return this.canViewCompanyTab();
	}

	canViewStatistics(): boolean {
		return this.canViewPersonalStatistics() || this.canViewCompanyStatistics();
	}

	canManageExpenses(): boolean {
		return this.hasCompanyManagerRole() || this.hasCompanyFinanceRole() || this.hasCompanyAdminRole();
	}

	canUseDashboard(): boolean {
		if (this.hasCompanyRole() && !this.hasCompanyExpenseSubmitterRole()) {
			/* Company users can only use the My Klippa dashboard when having the Expense submitter role. */
			return false;
		}
		return true;
	}

	public canUseGalleryView(flags: Array<PossibleCompanyFeatureFlags>): boolean {
		return (
			arrayIsSetAndFilled(flags) &&
			flags.includes(PossibleCompanyFeatureFlags.GALLERY_VIEW) &&
			this.getCompanyDetails()?.showPretransactionsMenu
		);
	}

	canUseInvoices(): boolean {
		return (
			this.hasCompanyFinanceRole() ||
			this.hasCompanyAdminRole() ||
			this.hasCompanyInvoiceApproverRole() ||
			this.hasCompanyInvoiceSubmitterRole()
		);
	}

	canManagePaymentMethods(): boolean {
		return this.hasCompanyFinanceRole() || this.hasCompanyAdminRole() || this.hasReadAdminRoleOrHigher();
	}

	canUseCreditcardStatements(): boolean {
		return this.hasCompanyFinanceRole() || this.hasCompanyAdminRole();
	}

	public canAccessSubcompanies(): boolean {
		return this.hasReadWriteAdminRoleOrHigher() || this.hasCompanyAdminRole() || this.hasCompanyFinanceRole();
	}

	hasActiveSubscription(): boolean {
		if (this.getSubscriptions() == null || this.getSubscriptions().length === 0) {
			return false;
		}
		const activeStatuses = ['active', 'canceled', 'trialing', 'incomplete'];
		for (const subscription of this.getSubscriptions()) {
			if (activeStatuses.includes(subscription.getStatus())) {
				return true;
			}
		}
		return false;
	}

	getActiveSubscription(): Subscription {
		if (this.getSubscriptions() == null || this.getSubscriptions().length === 0) {
			return null;
		}
		const activeStatuses = ['active', 'canceled', 'trialing', 'incomplete'];
		for (const subscription of this.getSubscriptions()) {
			if (activeStatuses.includes(subscription.getStatus())) {
				return subscription;
			}
		}
		return null;
	}

	canBecomePro(): boolean {
		return !this.hasProRole() && !this.hasCompanyRole();
	}

	hadTrialSubscription(): boolean {
		if (this.getSubscriptions() == null || this.getSubscriptions().length === 0) {
			return false;
		}
		for (const subscription of this.getSubscriptions()) {
			if (subscription.isTrial()) {
				return true;
			}
		}
		return false;
	}

	hasActiveRecurringSubscription() {
		if (this.getSubscriptions() == null || this.getSubscriptions().length === 0) {
			return false;
		}
		const activeStatuses = ['active', 'trialing', 'incomplete'];
		for (const subscription of this.getSubscriptions()) {
			if (activeStatuses.includes(subscription.getStatus()) && subscription.isRecurring()) {
				return true;
			}
		}
		return false;
	}

	shouldBeRemindedToActivateMail(): boolean {
		// We only want to remind non-validated users older than 1 hour.
		const timeDiff = Math.abs(this.createdate.getTime() - new Date().getTime()) / 1000;
		return !this.emailvalidated && timeDiff > 3600;
	}

	getViewableUserStates(tableType = 'expenses') {
		const allowedStatus = [];

		if (tableType === 'invoices') {
			allowedStatus.push('ToClaim');
			allowedStatus.push('Denied');
			allowedStatus.push('NeedsInformation');
			allowedStatus.push('Accepted');
			allowedStatus.push('Approved');
			allowedStatus.push('Claimed');
		}

		if (this.hasCompanyManagerRole() && tableType !== 'invoices') {
			allowedStatus.push('Denied');
			allowedStatus.push('Accepted');
			allowedStatus.push('Approved');
		}

		if (this.hasCompanyFinanceRole()) {
			allowedStatus.push('Approved');
			allowedStatus.push('Claimed');
		}

		if (this.hasCompanyAdminRole()) {
			allowedStatus.push('ToClaim');
			allowedStatus.push('Denied');
			allowedStatus.push('NeedsInformation');
			allowedStatus.push('Accepted');
			allowedStatus.push('Approved');
			allowedStatus.push('Claimed');
		}

		return allowedStatus.filter((v, i, a) => a.indexOf(v) === i);
	}

	getAllowedUserStates(tableType = 'expenses') {
		const allowedStatus = [];
		if (tableType === 'invoices' && this.hasCompanyInvoiceApproverRole()) {
			allowedStatus.push('ToClaim');
			allowedStatus.push('Denied');
			allowedStatus.push('NeedsInformation');
			allowedStatus.push('Accepted');
			allowedStatus.push('Approved');
		}

		if (this.hasCompanyManagerRole() && tableType !== 'invoices') {
			allowedStatus.push('ToClaim');
			allowedStatus.push('Denied');
			allowedStatus.push('NeedsInformation');
			allowedStatus.push('Accepted');
			allowedStatus.push('Approved');
		}

		if (this.hasCompanyFinanceRole()) {
			allowedStatus.push('Approved');
			allowedStatus.push('Claimed');
		}

		if (this.hasCompanyAdminRole()) {
			allowedStatus.push('ToClaim');
			allowedStatus.push('Denied');
			allowedStatus.push('NeedsInformation');
			allowedStatus.push('Accepted');
			allowedStatus.push('Approved');
			allowedStatus.push('Claimed');
		}

		return allowedStatus.filter((v, i, a) => a.indexOf(v) === i);
	}

	getStatusesToProcess(tableType = 'expenses') {
		/*
      Return a list of expense statuses that still have to be processed by the current user.
      Used for calculating the number of expenses / invoices that an user can process.
      E.g. a manager does not have to take action when a expense / invoice is Denied or NeedsInformation.
      Furthermore, managers don't have to perform any action when a expense / invoice is in the final status that they can set.
    */
		const statuses = [];

		if (tableType === 'invoices' && this.hasCompanyInvoiceApproverRole()) {
			statuses.push('ToClaim');
			statuses.push('Accepted');
		}

		if (tableType === 'invoices' && (this.hasCompanyInvoiceSubmitterRole() || this.hasCompanyFinanceRole() || this.hasCompanyAdminRole())) {
			statuses.push('NeedsInformation');
		}

		if (this.hasCompanyManagerRole() && tableType !== 'invoices') {
			statuses.push('Accepted');
		}

		if (this.hasCompanyFinanceRole()) {
			statuses.push('Approved');
			statuses.push('ToClaim');
		}

		if (this.hasCompanyAdminRole()) {
			statuses.push('ToClaim');
			statuses.push('Accepted');
		}

		return statuses.filter((v, i, a) => a.indexOf(v) === i);
	}

	getMobileStatusesToProcess(tableType = 'expenses') {
		const statuses = [];

		if (tableType === 'invoices' && this.hasCompanyInvoiceApproverRole()) {
			statuses.push('ToClaim');
			statuses.push('Accepted');
		}

		if (tableType === 'invoices' && (this.hasCompanyInvoiceSubmitterRole() || this.hasCompanyFinanceRole() || this.hasCompanyAdminRole())) {
			statuses.push('NeedsInformation');
		}

		if (this.hasCompanyManagerRole() && tableType !== 'invoices') {
			statuses.push('Accepted');
		}

		if (this.hasCompanyFinanceRole()) {
			statuses.push('ToClaim');
		}

		if (this.hasCompanyAdminRole()) {
			statuses.push('ToClaim');
			statuses.push('Accepted');
		}

		return removeDuplicatesFromArray(statuses);
	}

	getAllowedReceiptStates(current_status, tableType = 'expenses') {
		const allowedStatus = this.getAllowedUserStates(tableType);

		if (allowedStatus.includes(current_status)) {
			return allowedStatus;
		}

		return [];
	}

	userCanSetStatus(status, tableType = 'expenses'): boolean {
		return this.getAllowedUserStates(tableType).includes(status);
	}

	public userCanShare(): boolean {
		return !this.hasCompanyRole() && this.hasProRole();
	}

	matches(string: string): boolean {
		/** Returns whether this user matches a given search string. */
		return this.getName().toLowerCase().includes(string.toLowerCase()) || this.getEmail().toLowerCase().includes(string.toLowerCase());
	}

	clone(): User {
		return new User(JSON.parse(JSON.stringify(this)));
	}
}

export function getUserRoleLabel(role: UserRole | number | string): string {
	if (typeof role === 'string') {
		role = parseInt(role, 10);
	}

	switch (role) {
		case UserRole.User:
			return _('User');
		case UserRole.Company:
			return _('Company');
		case UserRole.CompanyAdmin:
			return _('Company Admin');
		case UserRole.CompanyManager:
			return _('Manager');
		case UserRole.Pro:
			return _('Pro');
		case UserRole.OCR:
			return _('OCR Review Role');
		case UserRole.CompanyFinance:
			return _('Finance');
		case UserRole.PartnerUser:
			return _('Partner User');
		case UserRole.CompanyInvoiceSubmitter:
			return _('Invoice Submitter');
		case UserRole.CompanyInvoiceApprover:
			return _('Invoice Approver');
		case UserRole.CompanyExpenseSubmitter:
			return _('Expense Submitter');
		case UserRole.CompanyRegistrationSubmitter:
			return _('Registrations Submitter');
		case UserRole.CompanyRegistrationsManager:
			return _('Registrations Manager');
		case UserRole.ReadAdmin:
			return _('Read Admin');
		case UserRole.ReadWriteAdmin:
			return _('Read Write Admin');
		case UserRole.Admin:
			return _('Super Admin');
		case UserRole.Support:
			return _('Support');
		default:
			return 'null';
	}
}
