import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ExpenseReportsService } from '#/services/transaction/expense-reports.service';
import { ExpenseReport, PaginatedExpenseReports, PaginatedExpenseReportsRequest } from '#/models/transaction/expense-reports';
import { AuthenticatedComponent } from '~/app/pages/authenticated/authenticated.component';
import { ActivatedRoute } from '@angular/router';
import { ColumnsInterface, DataTablesParameters } from '~/app/shared/ui/table/table';
import { ActionService, ColumnService, FilterService } from './report-declarations-list.service';
import { SelectActionEnum, SelectActionInterface } from '~/app/shared/ui/select-action/select-action';
import { ActionsMenuActionEnum, ActionsMenuInterface } from '~/app/shared/ui/actions-menu/actions-menu';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { isValueSet, stringIsSetAndFilled } from '#/util/values';
import { FilterEnum } from '~/app/shared/ui/filters/filter';
import { arrayIsSetAndFilled, getObjectFromKeyValuePairs } from '#/util/arrays';
import { ColumnSortingLocalStorageInterface, DashboardColumnSortingService } from '~/app/services/dashboard-column-sorting.service';
import { Order } from '#/models/utils/order';
import { ReportType } from '#/models/reportType.model';
import { ExportFormModal } from '~/app/modules/export/export-form-modal/export-form-modal.component';
import { SelectActionComponent } from '~/app/shared/ui/select-action/select-action.component';
import { PageStateService } from '#/services/util/page-state.service';
import { needsReportUpdate, WSMessage } from '#/models/websocket.model';
import { debounceTime, filter } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { SelectAction } from '#/models/utils/tables';
import { RouteUtilsService } from '#/services/util/route-utils.service';

const DEPRECATED_COLUMN_SORTING_LOCALSTORAGE_KEY = 'column_configuration_reports_list_v1';
const COLUMN_SORTING_LOCALSTORAGE_KEY = 'report-declarations-list_v2022-07-12';
const FILTERS_LOCALSTORAGE_KEY = 'filters-report-declarations-list-v2022-07';

@Component({
	templateUrl: './report-declarations-list.component.html',
	styleUrls: ['./report-declarations-list.component.scss'],
})
export class ReportDeclarationsListComponent extends AuthenticatedComponent implements OnInit, OnDestroy {
	constructor(
		private expenseReportsService: ExpenseReportsService,
		private actionService: ActionService,
		private route: ActivatedRoute,
		private columnService: ColumnService,
		private filterService: FilterService,
		private dashboardColumnSortingService: DashboardColumnSortingService,
		private pageStateService: PageStateService,
		private routeUtilsService: RouteUtilsService,
	) {
		super();
		this.pageStateService.initPageState();
	}

	public reportType: ReportType;
	public initialReportDeclarationsPromise: Promise<PaginatedExpenseReports>;
	public columns: ColumnsInterface[] = this.columnService.getColumns();
	public filterOptions: FilterEnum[] = this.filterService.getFilterOptions();
	public filtersLocalStorageKey: string = FILTERS_LOCALSTORAGE_KEY;
	private queryParamsSubscription: Subscription = new Subscription();

	public currentReport: ExpenseReport;
	public paginatedExpenseReportsRequest = new PaginatedExpenseReportsRequest(0, 25);
	public showSelectActions = false;
	public showFilters = false;
	public selected: {
		rows: Array<string>;
		all: boolean;
	};
	public reportDeclarations: PaginatedExpenseReports;

	private predefinedColumns: ColumnsInterface[];
	private prefixedColumns: ColumnsInterface[];
	private endColumns: ColumnsInterface[];
	private reports: ExpenseReport[];
	private websocketSubscription: Subscription;

	@ViewChild('createReportModal') createReportModal: ElementRef;
	@ViewChild('editReportModal') editReportModal: ElementRef;
	@ViewChild('exportFormModal') exportFormModal: ExportFormModal;
	@ViewChild(SelectActionComponent) selectActionComponent: SelectActionComponent;

	public exportIds: Array<string>;

	public async ngOnInit(): Promise<void> {
		super.ngOnInit();
		this.reportType = this.route.snapshot.data.variant;

		this.paginatedExpenseReportsRequest.sort = 'start_date';
		this.paginatedExpenseReportsRequest.sortorder = Order.DESCENDING;

		this.setPredefinedColumns();
		if (!this.dashboardColumnSortingService.hasColumnsSavedLocally(COLUMN_SORTING_LOCALSTORAGE_KEY)) {
			this.dashboardColumnSortingService.setFirstTimeLocalStorageColumnOrder(
				COLUMN_SORTING_LOCALSTORAGE_KEY,
				(this.localStorageService.get(DEPRECATED_COLUMN_SORTING_LOCALSTORAGE_KEY) as ColumnSortingLocalStorageInterface)?.sortedColumns ??
					this.columnService.getColumns(),
				DEPRECATED_COLUMN_SORTING_LOCALSTORAGE_KEY,
			);
		}
		this.columns = this.dashboardColumnSortingService.getColumnsBasedOnSortingOrder(
			COLUMN_SORTING_LOCALSTORAGE_KEY,
			this.predefinedColumns,
			this.prefixedColumns,
			this.endColumns,
		);
		await this.setFilteredReports();

		this.websocketSubscription = this.websocketsService.onMessage
			.pipe(
				filter((msg) => needsReportUpdate(msg.type)),
				debounceTime(300),
			)
			.subscribe((message: WSMessage) => this.getAndProcessReports());

		this.queryParamsSubscription = this.route.queryParams.subscribe(async (params) => {
			await this.updateAPIRequestBasedOnQueryParams();
			await this.getAndProcessReports();
		});
	}

	// This function obtains all the reports, requested and adds the currency
	private async getAndProcessReports(): Promise<PaginatedExpenseReports> {
		switch (this.reportType) {
			case ReportType.CARD:
				this.paginatedExpenseReportsRequest.type = ReportType.CARD;
				break;
			case ReportType.OUT_OF_POCKET:
				this.paginatedExpenseReportsRequest.type = ReportType.OUT_OF_POCKET;
				break;
		}

		return this.expenseReportsService
			.getExpenseReports(this.paginatedExpenseReportsRequest)
			.then((expenseReports: PaginatedExpenseReports) => {
				this.reportDeclarations = expenseReports;
				this.reports = expenseReports.reports;
				return expenseReports;
			});
	}

	private async setFilteredReports(): Promise<void> {
		const localStoragefilters: PaginatedExpenseReportsRequest = this.localStorageService.get(FILTERS_LOCALSTORAGE_KEY);
		if (isValueSet(localStoragefilters)) {
			const allowedFilterFields = Object.entries(localStoragefilters).filter(([key]) => this.filterOptions.includes(key as FilterEnum));
			Object.assign(this.paginatedExpenseReportsRequest, getObjectFromKeyValuePairs(allowedFilterFields));
		}
		await this.updateAPIRequestBasedOnQueryParams();
		this.initialReportDeclarationsPromise = this.getAndProcessReports();
	}

	private async updateAPIRequestBasedOnQueryParams(): Promise<void> {
		if (stringIsSetAndFilled(this.route.snapshot.queryParams.itemsPerPage)) {
			this.paginatedExpenseReportsRequest.max = Number(this.route.snapshot.queryParams.itemsPerPage);
		}
		if (stringIsSetAndFilled(this.route.snapshot.queryParams.page)) {
			this.paginatedExpenseReportsRequest.start =
				(Number(this.route.snapshot.queryParams.page) - 1) * this.paginatedExpenseReportsRequest.max;
		}
		if (stringIsSetAndFilled(this.route.snapshot.queryParams.sort)) {
			this.paginatedExpenseReportsRequest.sort = this.route.snapshot.queryParams.sort;
		}

		if (stringIsSetAndFilled(this.route.snapshot.queryParams.sortOrder)) {
			this.paginatedExpenseReportsRequest.sortorder = this.route.snapshot.queryParams.sortOrder;
		}
	}

	// Function that receives the callback from the select action.
	private executeRequestedSelectAction(action: SelectActionEnum): void {
		switch (action) {
			case SelectActionEnum.edit:
				// Current report is used to sent the data into edit the modal.
				this.currentReport = this.reports.find((x) => x.id === this.selected.rows[0]);
				this.modalService.open(this.editReportModal);
				break;
			case SelectActionEnum.export:
				this.setSelectedReportIds();
				this.exportFormModal.openExportModal();
				break;
			case SelectActionEnum.submit:
				this.submitReports();
				break;
			default:
				throw new Error(`Missing action to preform: '${action}'`);
		}
		if (!arrayIsSetAndFilled(this.selected.rows)) {
			this.closeSelectActions();
		}
	}

	public getSelectActions = (report: ExpenseReport): Array<SelectActionInterface> => {
		return this.actionService.getSelectActions(report);
	};

	public getRowActions = (report: ExpenseReport): Array<ActionsMenuInterface> => {
		return this.actionService.getRowActions(report);
	};

	public executeRequestedRowAction(rowActionObj: { action: ActionsMenuActionEnum; id: string }): void {
		// Current report is used to send the data into the edit, delete and retract modal.
		this.currentReport = this.reports.find((x) => x.id === rowActionObj.id);
		switch (rowActionObj.action) {
			case ActionsMenuActionEnum.edit:
				this.modalService.open(this.editReportModal);
				break;
			case ActionsMenuActionEnum.submit:
				this.expenseReportsService.submitExpenseReport(rowActionObj.id).then(() => {
					this.notificationService.success(this.translate.instant(_('Reports submitted')));
					this.getAndProcessReports();
				});
				break;
			case ActionsMenuActionEnum.export:
				this.exportIds = [rowActionObj.id];
				this.exportFormModal.openExportModal();
				break;
			default:
				throw new Error(`Missing action to preform: '${rowActionObj.action}'`);
		}
		this.closeSelectActions();
	}

	private submitReports(): void {
		const promiseArray = [];
		for (const i in this.selected.rows) {
			if (this.selected.rows.hasOwnProperty(i)) {
				promiseArray.push(Promise.resolve(this.expenseReportsService.submitExpenseReport(this.selected.rows[i])));
			}
		}
		Promise.all(promiseArray).then(() => {
			this.notificationService.success(this.getTranslation('Reports submitted'));
			this.getAndProcessReports();
		});
	}

	public navigateTable(dataTablesParameters: DataTablesParameters): void {
		this.paginatedExpenseReportsRequest.start = dataTablesParameters.start;
		this.paginatedExpenseReportsRequest.max = dataTablesParameters.length;
		this.initialReportDeclarationsPromise = this.getAndProcessReports();
	}

	public async updateRequestFilters(request: PaginatedExpenseReportsRequest): Promise<void> {
		const allowedFilterFields = Object.entries(request).filter(([key]) => this.filterOptions.includes(key as FilterEnum));
		Object.assign(this.paginatedExpenseReportsRequest, getObjectFromKeyValuePairs(allowedFilterFields));
		await this.getAndProcessReports();
		this.closeFilters();
	}

	public async fetchSortedColumns(event: { sortingProperty: string; sortingOrder: Order }): Promise<void> {
		this.paginatedExpenseReportsRequest.sort = event.sortingProperty;
		this.paginatedExpenseReportsRequest.sortorder = event.sortingOrder;
		await this.getAndProcessReports();
	}

	public updateRouteWithSortingParams(event: { sortingProperty: string; sortingOrder: Order }): void {
		const newUrl: string = this.routeUtilsService.getUpdateRouteWithNewParamValue(
			this.router.url.split('?')[0],
			this.route.snapshot.queryParams,
			{
				sort: event.sortingProperty,
				sortOrder: event.sortingOrder,
			},
		);
		this.router.navigateByUrl(newUrl);
	}

	public openSelectActions(event: any): void {
		this.selected = event;
		this.showSelectActions = true;
		setTimeout(() => {
			// timeout because the action component needs to be rendered first based on `this.showSelectActions = true;`
			this.selectActionComponent.openActionList();
		});
	}

	public selectAllReportsOnAllPages = async (selectAction: SelectAction): Promise<Array<string>> => {
		this.selected = await this.expenseReportsService.getSelectedForAllExpenseReports(selectAction, this.paginatedExpenseReportsRequest);
		return this.selected.rows;
	};

	private closeSelectActions(): void {
		this.showSelectActions = false;
	}

	public openCreateReportModal(): void {
		this.modalService.open(this.createReportModal);
	}

	public async closeCreateReportModal(): Promise<void> {
		this.modalService.close(this.createReportModal);
		await this.getAndProcessReports();
	}

	public async closeEditReportModal(): Promise<void> {
		this.modalService.close(this.editReportModal);
		await this.getAndProcessReports();
	}

	public openFilters(): void {
		this.showFilters = true;
	}

	public closeFilters(): void {
		this.showFilters = false;
	}

	public onRowClicked(id: string): void {
		this.router.navigate([`./${id}`], { relativeTo: this.route });
	}

	private setPredefinedColumns(): void {
		this.predefinedColumns = this.columnService.getColumns();
		this.prefixedColumns = this.predefinedColumns.filter((e) => e.position === 'pre');
		this.endColumns = this.predefinedColumns.filter((e) => e.position === 'end');
	}

	public async sortTable(sortedColumnsObj): Promise<void> {
		this.columns = sortedColumnsObj.sortedColumns;
		this.dashboardColumnSortingService.setColumnSortingToLocalStorage(sortedColumnsObj.sortedColumns, COLUMN_SORTING_LOCALSTORAGE_KEY);
		await this.getAndProcessReports();
	}

	public canAddNewReport(): boolean {
		switch (this.route.snapshot.data.variant?.toLowerCase()) {
			case 'card':
				return false;
			default:
				return true;
		}
	}

	public getEmptyListTitle(): string {
		switch (this.reportType) {
			case ReportType.OUT_OF_POCKET:
				return this.translate.instant(_('Add your first expense report with the button at the top right.'));
			case ReportType.CARD:
				return this.translate.instant(_('Card reports will appear here once your first transactions are processed.'));
			default:
				return this.translate.instant(_('Your account does not contain any expense reports at the moment.'));
		}
	}

	private setSelectedReportIds(): void {
		this.exportIds = this.selected.rows;
	}

	public ngOnDestroy(): void {
		super.ngOnDestroy();
		this.websocketSubscription.unsubscribe();
		this.queryParamsSubscription.unsubscribe();
	}
}
