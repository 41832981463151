import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RestrictInputDirective } from './restrict-input.directive';
import { DomChangeDirective } from '~/app/directives/dom-change.directive';

@NgModule({
	imports: [CommonModule],
	declarations: [RestrictInputDirective, DomChangeDirective],
	exports: [RestrictInputDirective, DomChangeDirective],
})
export class DirectivesModule {}
