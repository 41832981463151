import { Inject, Injectable } from '@angular/core';
import { APIService } from '../../api/services/api.service';
import { DOCUMENT } from '@angular/common';
import { APINotificationsService } from '../../api/services/apinotifications.service';
import { Store } from '@ngrx/store';
import { AppState } from '../../reducers';
import { SetCurrencies, SetGenders, SetLanguages, SetPaymentMethods } from './generic.actions';
import { PaymentMethod } from './models/payment-method';
import { Gender } from './models/gender';
import { Language } from './models/language';
import { Currency } from '#/models/currency';

@Injectable({
	providedIn: 'root',
})
export class GenericService {
	constructor(
		private apiService: APIService,
		@Inject(DOCUMENT) private document: any,
		private notifications: APINotificationsService,
		private store: Store<AppState>,
	) {}

	loadGenericValues() {
		return Promise.all([
			this.getCurrencies().then((r) => {
				const Currencies: any[] = r['data']['Currencies'];

				// Save currency in a map first so we can do easy lookups for the exchange list.
				const currencyMap: { [key: string]: Currency } = {};
				Currencies.forEach((currency) => {
					currencyMap[currency['Code']] = new Currency(currency);
				});

				return this.getExchangeCurrencies()
					.then((r2) => {
						const ExchangeCurrencies: any[] = r2['data'];

						// Mark exchange currencies as exchangeable.
						ExchangeCurrencies.forEach((exchangeCurrency) => {
							if (typeof currencyMap[exchangeCurrency] !== 'undefined') {
								currencyMap[exchangeCurrency].Exchangeable = true;
							}
						});

						// Convert currencies to array and push to store.
						this.store.dispatch(new SetCurrencies({ currencies: Object.keys(currencyMap).map((c) => currencyMap[c]) }));
						return Promise.resolve();
					})
					.catch(() => {
						// Fallback method when exchange currencies can't be loaded.
						// Convert currencies to array and push to store.
						this.store.dispatch(new SetCurrencies({ currencies: Object.keys(currencyMap).map((c) => currencyMap[c]) }));
						return Promise.resolve();
					});
			}),
			this.getGenders().then((r) => {
				const Genders: any = r['data']['Genders'];
				const newGenders: Gender[] = [];
				const genderCodes = Object.keys(Genders);
				for (const code of genderCodes) {
					newGenders.push(new Gender(code, Genders[code]));
				}
				this.store.dispatch(new SetGenders({ genders: newGenders }));
			}),
			this.getLanguages().then((r) => {
				const Languages: any = r['data']['Languages'];
				const newLanguages: Language[] = [];
				const languageCodes = Object.keys(Languages);
				for (const code of languageCodes) {
					newLanguages.push(new Language(code, Languages[code]));
				}
				this.store.dispatch(new SetLanguages({ languages: newLanguages }));
			}),
			this.getPaymentMethods().then((r) => {
				const PaymentMethods: any = r['data']['PaymentMethods'];
				const newPaymentMethods: PaymentMethod[] = [];
				const paymentMethodCodes = Object.keys(PaymentMethods);
				for (const code of paymentMethodCodes) {
					newPaymentMethods.push(new PaymentMethod(code, PaymentMethods[code]));
				}
				this.store.dispatch(new SetPaymentMethods({ paymentMethods: PaymentMethods }));
			}),
		]);
	}

	getCurrencies() {
		return this.apiService
			.get('/api/v1/currencies')
			.then((r) => {
				return Promise.resolve(r);
			})
			.catch((r) => {
				this.notifications.handleAPIError(r);
				return Promise.reject(r);
			});
	}

	getExchangeCurrencies(): Promise<any> {
		return this.apiService
			.get('/api/v1/receipt/exchangeCurrencies', 60 * 1000)
			.then((r) => {
				return Promise.resolve(r);
			})
			.catch((r) => {
				this.notifications.handleAPIError(r);
				return Promise.reject(r);
			});
	}

	getGenders() {
		return this.apiService
			.get('/api/v1/genders')
			.then((r) => {
				return Promise.resolve(r);
			})
			.catch((r) => {
				this.notifications.handleAPIError(r);
				return Promise.reject(r);
			});
	}

	getLanguages() {
		return this.apiService
			.get('/api/v1/languages')
			.then((r) => {
				return Promise.resolve(r);
			})
			.catch((r) => {
				this.notifications.handleAPIError(r);
				return Promise.reject(r);
			});
	}

	getPaymentMethods() {
		return this.apiService
			.get('/api/v1/payment_methods')
			.then((r) => {
				return Promise.resolve(r);
			})
			.catch((r) => {
				this.notifications.handleAPIError(r);
				return Promise.reject(r);
			});
	}
}
