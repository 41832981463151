import { Component, Input, OnInit } from '@angular/core';
import { stringIsSetAndFilled } from '#/util/values';
import { RegistrationService } from '#/services/transaction/registration.service';
import { TransportationType } from '#/models/transaction/transportationType';

@Component({
	selector: 'app-registration-type-label',
	templateUrl: './registration-type-label.component.html',
	styleUrls: ['./registration-type-label.component.scss'],
})
export class RegistrationTypeLabelComponent implements OnInit {
	@Input() public registrationTypeId: string;
	public name: string;

	constructor(private registrationService: RegistrationService) {}

	async ngOnInit(): Promise<void> {
		if (stringIsSetAndFilled(this.registrationTypeId)) {
			this.name = await this.registrationService
				.getRegistrationTypesById([this.registrationTypeId])
				.then((res: Array<TransportationType>) => {
					return [res[0]?.name, res[0].description].filter(stringIsSetAndFilled).join(' ');
				});
		}
	}
}
