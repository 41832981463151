import { Component, Input, SimpleChanges } from '@angular/core';
import { Attachment, AttachmentType } from '#/models/transaction/attachment';
import { FileService } from '#/services/file.service';
import { FileUtils } from '~/app/helpers/file-utils';
import { AttachmentActionsBarComponent } from '~/app/shared/ui/attachment-actions-bar/attachment-actions-bar.component';
import { Subscription } from 'rxjs';

@Component({
	selector: 'app-attachment-viewer',
	templateUrl: './attachment-viewer.component.html',
	styleUrls: ['attachment-viewer.component.scss'],
})
export class AttachmentViewerComponent {
	@Input() receiptId: string;
	@Input() attachments: Array<Attachment> = [];
	@Input() activeFileIndex: number = 0;
	@Input() actionsController: AttachmentActionsBarComponent;
	public filePromise: Promise<{ blob: Blob; type: AttachmentType }>;

	private subscriptions = new Subscription();
	private initialized: boolean = false;

	constructor(private fileService: FileService) {}

	public ngOnInit(): void {
		this.actionsController.setAmountOfAttachments(this.attachments.length);
		this.subscriptions.add(
			this.actionsController.prevAttachmentSubject.subscribe(async () => {
				this.activeFileIndex -= 1;
				await this.loadFile();
			}),
		);
		this.subscriptions.add(
			this.actionsController.nextAttachmentSubject.subscribe(async () => {
				this.activeFileIndex += 1;
				await this.loadFile();
			}),
		);
		this.initialized = true;
	}

	public async ngOnChanges(changes: SimpleChanges): Promise<void> {
		if (changes.attachments) {
			const prevLength = changes.attachments.previousValue?.length ?? 0;
			const curLength = changes.attachments.currentValue?.length ?? 0;
			if (prevLength !== curLength) {
				if (curLength > prevLength && this.initialized) {
					const newEntryIndex = (changes.attachments.currentValue as Array<Attachment>).findIndex(
						(cur) => !((changes.attachments.previousValue as Array<Attachment>) ?? []).some((prev) => prev.id === cur.id),
					);
					this.activeFileIndex = Math.max(newEntryIndex, 0);
				} else if (prevLength > curLength) {
					this.activeFileIndex = 0;
				}
				await this.loadFile();
			}
			this.actionsController.setAmountOfAttachments(this.attachments?.length ?? 0);
		}
	}

	private async loadFile(): Promise<void> {
		const attachment: Attachment = this.attachments[this.activeFileIndex];
		if (attachment.type === AttachmentType.OCR_ENHANCED_FILE) {
			attachment.filename = await this.fileService.getOcrEnhancedFileInformation(attachment.id).then((res) => res.file?.name);
		}
		this.filePromise = this.fileService.getFile(this.receiptId, attachment.id, attachment.type).then((res) => ({
			blob: res,
			type: FileUtils.isImage(res) ? AttachmentType.IMAGE : AttachmentType.DOCUMENT,
		}));
		this.actionsController.setCurrentActiveFile(this.activeFileIndex, attachment.filename);
		this.filePromise.then((res) => {
			this.actionsController.setCurrentActiveFileBlob(res.blob);
		});
	}

	public getActiveAttachment(): Attachment {
		return this.attachments[this.activeFileIndex];
	}

	public getActiveAttachmentType(): AttachmentType {
		return FileUtils.isImage(this.actionsController.getCurrentActiveFileBlob()) ? AttachmentType.IMAGE : AttachmentType.DOCUMENT;
	}

	public ngOnDestroy(): void {
		this.subscriptions.unsubscribe();
	}
}
