import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { TranslateModule } from '@ngx-translate/core';
import { PipesModule } from '~/app/helpers/pipes/pipes.module';
import { UiModule } from '~/app/shared/ui/ui.module';
import { CompanyUI } from '../ui/ui.module';
import { CompanySettingsExportModalComponent } from './export-modal/export-modal.component';
import { PaymentMethodEditComponent } from './payment-methods/payment-method-edit/payment-method-edit.component';

@NgModule({
	imports: [CommonModule, FormsModule, NgSelectModule, TranslateModule, ReactiveFormsModule, PipesModule, CompanyUI, UiModule],
	declarations: [CompanySettingsExportModalComponent, PaymentMethodEditComponent],
	exports: [CompanySettingsExportModalComponent, PaymentMethodEditComponent],
	providers: [],
})
export class SharedCompanySettingsModule {}
