<div class="modal-header">
	<h4 class="modal-title" translate>Change status</h4>
	<button (click)="closeModal()" aria-label="Close" class="close" type="button">
		<span aria-hidden="true">&times;</span>
	</button>
</div>

<div class="modal-body">
	<ng-container [ngTemplateOutlet]="template"> </ng-container>
	<div>
		<div class="modal-row">
			<div translate class="fieldLabel">Status</div>
			<app-status-picker [(ngModel)]="currentStatus" [filterOnStatuses]="filterOnStatuses"> </app-status-picker>
		</div>
		<div class="modal-row">
			<div translate class="fieldLabel">Remark</div>
			<klp-form-text-input [(ngModel)]="remark"> </klp-form-text-input>
		</div>
	</div>
</div>

<div class="modal-footer">
	<app-button aria-haspopup="true" aria-expanded="false" (click)="closeModal()">
		<span translate>Cancel</span>
	</app-button>
	<app-button variant="greenFilled" aria-haspopup="true" aria-expanded="false" (click)="saveStatus()">
		<span translate>Save</span>
	</app-button>
</div>
