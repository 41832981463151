import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Attachment } from '#/models/transaction/attachment';
import { CompanyService } from '#/services/company/company.service';
import {
	AccountingIntegrationV1,
	CreateCustomRelationCapability,
	CreateCustomRelationCapabilityField,
	IntegrationRelation,
} from '#/models/company/company.model';
import { CompanyIntegrationService } from '#/services/company/company-integration.service';
import { NotificationService } from '~/app/services/notification.service';
import { Orientation, RadioOptions } from '@klippa/ngx-enhancy-forms';
import { FormGroup, UntypedFormBuilder } from '@angular/forms';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { TranslateService } from '@ngx-translate/core';
import { memoize } from 'lodash';
import { isValueSet } from '#/util/values';
import { TransactionEditorService } from '#/services/transaction/transaction-editor.service';

export enum CustomIntegrationRelationOptions {
	PERSON = 'person',
	ORGANISATION = 'organisation',
}

@Component({
	selector: 'app-custom-add-integration-relation-modal',
	templateUrl: './add-custom-integration-relation-modal.component.html',
	styleUrls: ['./add-custom-integration-relation-modal.component.scss'],
})
export class AddCustomIntegrationRelationModalComponent implements OnInit {
	@Input() public attachments: Array<Attachment>;
	// TODO - TransactionId to be removed once REM is removed as it is only needed to determine if the current transaction is an invoice or not
	@Input() public transactionId: string;
	@Input() public integrationId: string;
	@Input() public divisionId: string;
	@Input() public newCustomRelationFieldName: string;
	@Input() public isInvoice: boolean;
	@Output() onDismissModal = new EventEmitter<void>();
	@Output() onNewIntegrationRelation = new EventEmitter<IntegrationRelation>();

	public orientation: Orientation = Orientation.ROW;
	public baseField: CreateCustomRelationCapabilityField;
	public typeOfRelations: RadioOptions;
	public customIntegrationRelationForm: FormGroup = this.fb.group({
		typeOfRelation: null,
	});

	constructor(
		private companyService: CompanyService,
		private companyIntegrationService: CompanyIntegrationService,
		private notificationService: NotificationService,
		private translate: TranslateService,
		private fb: UntypedFormBuilder,
		private transactionEditorService: TransactionEditorService,
	) {}

	public async ngOnInit(): Promise<void> {
		let isInvoice: boolean;
		if (isValueSet(this.isInvoice)) {
			isInvoice = this.isInvoice;
		} else {
			isInvoice = (await this.transactionEditorService.getTransaction(this.transactionId)).isInvoice;
		}
		const capabilities = await this.getCapabilities();
		this.baseField = this.getBaseField(capabilities);
		this.typeOfRelations = this.getTypeOfRelations(capabilities, isInvoice);
		this.patchFormValues(this.typeOfRelations);
	}

	/* tslint:disable:member-ordering */
	public getCapabilities = memoize(async (): Promise<CreateCustomRelationCapability> => {
		const companyId: string = this.companyService.getCompanyOfLoggedUser().id;
		const integration: AccountingIntegrationV1 = await this.companyIntegrationService.getIntegration(companyId, this.integrationId);
		return integration.getUIDefinition().Capabilities.CreateCustomRelation;
	});

	private patchFormValues(typeOfRelations: RadioOptions): void {
		if (typeOfRelations.length > 1) {
			this.customIntegrationRelationForm.patchValue({
				typeOfRelation: CustomIntegrationRelationOptions.ORGANISATION,
			});
		} else {
			this.customIntegrationRelationForm.patchValue({
				typeOfRelation: typeOfRelations[0]?.id,
			});
		}
	}

	public getTypeOfRelations(capabilities: CreateCustomRelationCapability, isInvoice: boolean): RadioOptions {
		if (isInvoice && capabilities?.CreateOrg) {
			return [{ id: CustomIntegrationRelationOptions.ORGANISATION, name: this.translate.instant(_('Organisation')) }];
		}

		if (!isInvoice) {
			const options = [];
			if (capabilities?.CreateOrg) {
				options.push({ id: CustomIntegrationRelationOptions.ORGANISATION, name: this.translate.instant(_('Organisation')) });
			}
			if (capabilities?.CreatePerson) {
				options.push({ id: CustomIntegrationRelationOptions.PERSON, name: this.translate.instant(_('Person')) });
			}
			return options;
		}
	}

	public isOrg(): boolean {
		return this.customIntegrationRelationForm.get('typeOfRelation').value === CustomIntegrationRelationOptions.ORGANISATION;
	}

	public getBaseField(capabilities): CreateCustomRelationCapabilityField {
		const baseField: CreateCustomRelationCapabilityField = new CreateCustomRelationCapabilityField({});
		baseField.Group = capabilities.Fields.map((field) => new CreateCustomRelationCapabilityField(field));
		return baseField;
	}

	public fieldValid(field: CreateCustomRelationCapabilityField): boolean {
		if (!field.isValid()) {
			return false;
		}
		if (field.Group?.some((groupField) => !this.fieldValid(groupField))) {
			return false;
		}
		if (field.GroupHorizontal?.some((groupField) => !this.fieldValid(groupField))) {
			return false;
		}

		return true;
	}

	public create(): void {
		const company = this.companyService.getCompanyOfLoggedUser();
		const isOrg = this.isOrg();
		const values = this.getValues(this.baseField);
		this.companyIntegrationService
			.createIntegrationRelation(company, this.integrationId, this.divisionId, { isOrg, values })
			.then((integrationRelation) => {
				this.onNewIntegrationRelation.emit(integrationRelation);
				this.onDismissModal.emit();
			})
			.catch((e) => {
				this.notificationService.error(e);
			});
	}

	private getValues(field: CreateCustomRelationCapabilityField): Record<string, string> {
		let response = {};

		if (isValueSet(field.Key) && isValueSet(field.Value)) {
			response[field.Key] = field.Value;
		}
		if (isValueSet(field.GroupHorizontal)) {
			field.GroupHorizontal.forEach((fieldColumn) => (response = { ...response, ...this.getValues(fieldColumn) }));
		}

		if (isValueSet(field.Group)) {
			field.Group.forEach((fieldColumn) => (response = { ...response, ...this.getValues(fieldColumn) }));
		}

		return response;
	}

	public closeModal(): void {
		this.onDismissModal.emit();
	}
}
