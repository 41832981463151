import { ChangeDetectorRef, Component, Input, OnChanges, OnDestroy } from '@angular/core';
import { Icon } from '~/app/modules/icon/models/icon';
import { select, Store } from '@ngrx/store';
import * as fromIcon from '~/app/modules/icon/models/icon.reducer';
import { Subscription } from 'rxjs';
import { AppState } from '~/app/reducers';

@Component({
	selector: 'table-row-icon',
	templateUrl: './table-row-icon.component.html',
})
export class TableRowIcon implements OnChanges, OnDestroy {
	@Input() description: string;
	@Input() isTravelDeclaration = false;
	@Input() merchant: string;

	icon: Icon;
	loadedMerchantLogo = false;
	private store: Store<AppState>;
	private iconSubscription: Subscription;

	constructor(private ref: ChangeDetectorRef, store: Store<AppState>) {
		this.store = store;
	}

	ngOnChanges() {
		this.iconSubscription = this.store
			.select('icon')
			.pipe(select(fromIcon.matchOnDescription(this.description)))
			.subscribe((v) => {
				this.icon = v;
			});
	}

	onLoadedMerchantLogo(loaded: boolean) {
		setTimeout(() => {
			this.loadedMerchantLogo = loaded;
			this.ref.markForCheck();
		});
	}

	ngOnDestroy(): void {
		this.iconSubscription.unsubscribe();
	}
}
