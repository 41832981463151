import { Component, ContentChild, EventEmitter, Host, Input, OnInit, Optional, Output, TemplateRef, ViewChild } from '@angular/core';
import {
	AppSelectOption,
	AppSelectOptions,
	FormElementComponent,
	KlpSelectOptionTemplateDirective,
	MultipleValueAccessorBase,
	SelectComponent,
} from '@klippa/ngx-enhancy-forms';
import { ControlContainer, NG_VALUE_ACCESSOR } from '@angular/forms';
import { isValueSet, stringIsSetAndFilled } from '#/util/values';
import { arrayIsSetAndFilled, arraysContainSameElements, asArray, removeDuplicatesFromArraysWithComparator } from '#/util/arrays';
import { throttle } from 'lodash';
import { TranslateService } from '@ngx-translate/core';
import { ItemsWithHasMoreResultsPromise } from '#/models/appSelectOption.model';

@Component({
	selector: 'app-dynamic-options-picker',
	templateUrl: './dynamic-options-picker.component.html',
	styleUrls: ['./dynamic-options-picker.component.scss'],
	providers: [{ provide: NG_VALUE_ACCESSOR, useExisting: DynamicOptionsPickerComponent, multi: true }],
})
export class DynamicOptionsPickerComponent<T> extends MultipleValueAccessorBase<string> implements OnInit {
	@Input() getPlaceholderTextFn: () => string;
	@Input() fetchItemsFn: (start: number, searchQuery: string) => ItemsWithHasMoreResultsPromise<T>;
	@Input() fetchSelectedItemsFn: (ids: Array<any>) => Promise<Array<T>>;
	@Input() selectableItems: Array<T>;
	@Input() mapToSelectOptionFn: (item: T) => AppSelectOption;
	@Input() sortValuesFn: (a: T, b: T) => number;
	@Input() dropdownPosition: 'top' | 'bottom' | 'left' | 'right' | 'auto' = null;
	@Input() orientation: 'vertical' | 'horizontal' = 'horizontal';
	@Input() clearable = true;
	@Input() truncateOptions = true;
	@Input() withSeparatingLine = false;
	@Input() autoSelectOnSingleResult = false;
	@Input() hasBorder = true;
	@Input() prefix: string;
	@Input() newOptionFooterPrefix: string;
	/* tslint:disable:member-ordering */
	@Input() customSearchFn: (term: string, item: { id: string; name: string; description?: string }) => boolean = () => true;
	@ContentChild(KlpSelectOptionTemplateDirective, { read: TemplateRef }) customOptionTpl: TemplateRef<any>;
	@Output() onFooterClick: EventEmitter<string> = new EventEmitter<string>();
	@Output() onOpened: EventEmitter<void> = new EventEmitter<void>();
	@Output() onClosed: EventEmitter<void> = new EventEmitter<void>();
	@ViewChild(SelectComponent) selectComponent: SelectComponent;

	private searchQuery: string;
	private lastItemIndexFetched: number = 0;
	private hasMoreResults = false;

	public optionsPromise: Promise<AppSelectOptions> = Promise.resolve([]);
	private optionsCache = new Map<string, AppSelectOption>();
	public disabledBecauseNoOptions: boolean = false;
	public hideOptionsPanel = false;

	constructor(
		@Optional() @Host() protected parent: FormElementComponent,
		@Optional() @Host() protected controlContainer: ControlContainer,
		private translate: TranslateService,
	) {
		super(parent, controlContainer);
	}

	private fetchItemsAndSelectedItems = throttle((nextPage = false) => {
		const oldPageResult = nextPage ? this.optionsPromise : Promise.resolve([]);
		const queriedOptionsPromise = Promise.all([oldPageResult, this.fetchItems(nextPage)]).then(([e1, e2]) => [...e1, ...e2]);
		this.optionsPromise = Promise.all([this.fetchSelectedItems(), queriedOptionsPromise, this.optionsPromise])
			.then(([selectedOptions, queriedCats, oldOptions]) => {
				const result: AppSelectOptions = removeDuplicatesFromArraysWithComparator(
					(el1, el2) => el1.id === el2.id,
					selectedOptions,
					queriedCats,
				);
				// we concatenate the values here into strings on which we decide if they are the same
				const containSameElements = arraysContainSameElements(
					oldOptions.map((e) => `${e.id}-${e.name}-${e.disabled}`),
					result.map((e) => `${e.id}-${e.name}-${e.disabled}`),
				);
				// if the old page contains the exact same entries, we keep the old results to remain the ids in the same order.
				// This is to prevent the options from jumping around
				if (containSameElements) {
					return oldOptions;
				}
				if (this.autoSelectOnSingleResult && result.length === 1) {
					this.setInnerValueAndNotify(result[0].id);
				}
				return result;
			})
			.catch(() => {
				return [];
			});
		this.optionsPromise.then((options) => {
			options?.forEach((e) => this.optionsCache.set(e.id, e));
		});
	}, 300);

	// fetched a batch of items, paginated
	private fetchItems(nextPage: boolean): Promise<AppSelectOptions> {
		if (!nextPage) {
			this.lastItemIndexFetched = 0;
		}
		let res: ItemsWithHasMoreResultsPromise<T>;
		if (arrayIsSetAndFilled(this.selectableItems)) {
			res = Promise.resolve({
				hasMoreResults: false,
				items: this.selectableItems,
			});
		} else {
			res = this.fetchItemsFn(this.lastItemIndexFetched, this.searchQuery ?? undefined);
		}
		if (!isValueSet(res)) {
			// if we get NULL when fetching items, apparently we are not allowed to show anything and the value is invalid.
			// Disable the control and reset to null
			this.disabledBecauseNoOptions = true;
			this.resetToNull();
			return Promise.resolve([]);
		}
		this.hideOptionsPanel = false;
		this.disabledBecauseNoOptions = false;
		res.then((e) => {
			this.hasMoreResults = e.hasMoreResults;
			if (e.items === null) {
				this.hideOptionsPanel = true;
				return;
			}
			this.lastItemIndexFetched += e.items.length;
		});
		return res.then((e) => this.mapAndSort(e.items));
	}

	// fetch all the items that are currently selected
	private fetchSelectedItems(): Promise<AppSelectOptions> {
		const innerValueBeforeRequest = this.innerValue;
		const ids: Array<string> = Array.isArray(this.innerValue) ? this.innerValue : [this.innerValue].filter(isValueSet);
		const itemsPromise: Promise<AppSelectOptions> =
			ids.length > 0
				? this.fetchSelectedItemsFn(ids)
						.then((res) => this.mapAndSort(res))
						.then((res) => this.sortToOriginalOrder(ids, res))
				: Promise.resolve([]);
		itemsPromise.then((items) => {
			// Only do the update if the innerValue did not change in the meantime
			if (innerValueBeforeRequest === this.innerValue && arrayIsSetAndFilled(this.innerValue)) {
				// update the value with only valid ids
				this.setInnerValueAndNotify(items.map((e) => e.id));
			}
		});
		return itemsPromise;
	}

	private sortToOriginalOrder(ids: string[], res: AppSelectOptions): AppSelectOptions {
		return res.sort((a, b) => (ids.indexOf(a.id) > ids.indexOf(b.id) ? 1 : -1));
	}

	private mapAndSort = (items: Array<T>): AppSelectOptions => {
		return this.renameAndSortByActiveProperty((items ?? []).sort(this.sortValuesFn).map(this.mapToSelectOptionFn));
	};

	ngOnInit() {
		super.ngOnInit();
		this.fetchItemsAndSelectedItems();
	}

	writeValue(value: Array<string> | string) {
		super.writeValue(value);

		// when we get new data in, we need to load the ids of the items we dont know about yet,
		// so you can actually see them when the picker is not opened yet
		this.optionsPromise?.then((knownOptions) => {
			// no need to get items that we already know about, so filter those out
			const ids = asArray(value).filter((id) => !knownOptions.map((e) => e.id).includes(id));
			if (arrayIsSetAndFilled(ids)) {
				this.fetchSelectedItemsFn(ids)
					.then((items) => this.mapAndSort(items))
					.then((res) => {
						this.optionsPromise = this.optionsPromise.then((options) => {
							return [...options, ...res];
						});
					});
			}
		});

		this._ext_deselectInvalidItems();
	}

	onSearch(searchQuery: string) {
		this.searchQuery = searchQuery;
		this.fetchItemsAndSelectedItems();
	}

	public pickerOpened(): void {
		this.onOpened.emit();
		this.onSearch(null);
	}

	public loadMore(): void {
		if (this.hasMoreResults) {
			this.fetchItemsAndSelectedItems(true);
		}
	}

	private renameAndSortByActiveProperty(options: AppSelectOptions): AppSelectOptions {
		const indexById = options.reduce((acc, cur, curIndex) => {
			return { ...acc, [cur.id]: curIndex };
		}, {});
		return options
			.map((e) => {
				let name = e.name;
				if (e.active === false) {
					name = `${this.translate.instant('Inactive')} - ${name}`;
				}
				return { ...e, name };
			})
			.sort((a, b) => {
				if (a.active > b.active) {
					return -1;
				}
				if (indexById[a.id] < indexById[b.id]) {
					return -1;
				}
				return 0;
			});
	}

	public async _ext_deselectInvalidItems() {
		const ids = asArray(this.innerValue);
		if (arrayIsSetAndFilled(ids)) {
			const items = await this.fetchSelectedItemsFn(asArray(this.innerValue)).then((res) => this.mapAndSort(res));
			if (!isValueSet(items)) {
				return;
			}
			const validIds = items.map((e) => e.id);
			if (validIds.length < ids.length) {
				this.setInnerValueAndNotify(validIds);
			}
		}
	}

	public _onFooterClick(searchQuery: string): void {
		this.onFooterClick.emit(searchQuery);
		this.close();
	}

	public searchItemsFn: (term: string, item: { id: string; name: string; description?: string }) => boolean = (term, item) => {
		const customSearchResult = this.customSearchFn(term, item);
		const option = this.optionsCache.get(item.id);
		const matchesSearch =
			option?.name?.toLowerCase().includes(term.toLowerCase()) || option?.description?.toLowerCase().includes(term.toLowerCase());
		if (asArray(this.innerValue).includes(item.id)) {
			return matchesSearch;
		}
		return customSearchResult;
	};

	// can be called from other components
	public _ext_refetchOptions(): void {
		// give angular a cycle to pass through the props (like selectableItems) to this comp
		setTimeout(() => {
			this.fetchItemsAndSelectedItems();
		});
	}

	public _ext_set_open(): void {
		this.selectComponent.open();
	}

	public showAddNewOptionFooter(): boolean {
		return stringIsSetAndFilled(this.newOptionFooterPrefix);
	}

	public close(): void {
		this.selectComponent.close();
	}

	public async enterPressed(search: string) {
		const options = await this.optionsPromise;
		const filtered = options.filter((e) => this.searchItemsFn(search, e));
		if (stringIsSetAndFilled(search) && filtered.length === 0 && stringIsSetAndFilled(this.newOptionFooterPrefix)) {
			this._onFooterClick(search);
			// it is opened again after this call. I think because of ng-select. Let's close it properly after 1 cycle
			setTimeout(() => {
				this.close();
			});
		}
	}
}
