import { Component, EventEmitter, Host, Input, Optional, Output, TemplateRef, ViewChild } from '@angular/core';
import { ExportRequest, ExportType } from '#/models/company/exporterInterfaces';
import { ControlContainer, NG_VALUE_ACCESSOR, UntypedFormBuilder } from '@angular/forms';
import { FormElementComponent, ValueAccessorBase } from '@klippa/ngx-enhancy-forms';
import { isValueSet, stringIsSetAndFilled } from '#/util/values';
import { ExportTemplate, ExportTemplateUserRole } from '~/app/modules/export-templates/models/export-template.model';
import { ExportTemplatesService } from '~/app/modules/export-templates/services/export-templates.service';
import { ExportFormConfigurationComponent } from '~/app/modules/export/components/export-form-configuration/export-form-configuration.component';
import { UserService } from '~/app/modules/user/user.service';
import { UserRole } from '#/models/user/user.model';
import { CompanyService } from '#/services/company/company.service';
import { TimezoneService } from '~/app/services/timezone.service';

@Component({
	selector: 'app-export-form',
	styleUrls: ['./export-form.component.scss'],
	templateUrl: './export-form.component.html',
	providers: [{ provide: NG_VALUE_ACCESSOR, useExisting: ExportFormComponent, multi: true }],
})
export class ExportFormComponent extends ValueAccessorBase<ExportRequest> {
	@Input() exportIds: Array<string>;
	@Input() exportType: ExportType;
	@Input() headerMessage: TemplateRef<any>;
	@Input() companyExport: boolean;
	@Input() exportPromise: Promise<string>;
	@Input() exportRequest: ExportRequest;
	@Input() isEmailRequired: boolean = false;
	@Input() showHeader: boolean = true;

	@Output() public onDismiss: EventEmitter<void> = new EventEmitter<void>();
	@Output() public onExport: EventEmitter<ExportRequest> = new EventEmitter<ExportRequest>();

	@ViewChild(ExportFormConfigurationComponent) public exportFormConfigurationComponent: ExportFormConfigurationComponent;

	public isProUser: boolean;
	public userHasCompany: boolean;
	public isCompanyAdmin: boolean;
	exportConfig: ExportRequest;

	private fb: UntypedFormBuilder = new UntypedFormBuilder();
	exportForm = this.fb.group({
		email: [],
		email_add_attachments: [],
	});
	exportTemplateForm = this.fb.group({
		exportTemplate: [],
	});
	saveForm = this.fb.group({
		save_to_user_settings: [false],
		save_as_new_template: [false],
		new_template_name: [''],
	});

	constructor(
		@Host() @Optional() protected parent: FormElementComponent,
		@Host() @Optional() protected controlContainer: ControlContainer,
		private exportTemplatesService: ExportTemplatesService,
		private userService: UserService,
		private companyService: CompanyService,
		private timezoneService: TimezoneService,
	) {
		super(parent, controlContainer);
		this.exportConfig = this.exportForm.value;
		this.isCompanyAdmin = userService.getCurrentLoggedUser().hasRole(UserRole.CompanyAdmin);
	}

	ngOnInit(): void {
		super.ngOnInit();
		const user = this.userService.getCurrentLoggedUser();
		this.isProUser = user.hasProRole();
		this.userHasCompany = isValueSet(user.company);

		this.exportPromise = null;
		this.exportForm.statusChanges.subscribe(() => {
			for (const key in this.exportForm.value) {
				if (this.exportForm.value.hasOwnProperty(key)) {
					this.exportConfig[key] = this.exportForm.value[key];
				}
			}
		});
	}

	public closeModal(): void {
		this.onDismiss.emit();
	}

	async emitExport() {
		const exportRequest: ExportRequest = this.exportConfig;
		exportRequest.save_template = this.saveForm.get('save_to_user_settings').value;
		exportRequest.email = this.exportForm.get('email').value;
		exportRequest.items = this.exportIds.map((e) => ({ id: e, type: this.exportType }));
		exportRequest.company_export = this.companyExport;
		const templateId = this.exportTemplateForm.get('exportTemplate').value;
		if (stringIsSetAndFilled(templateId)) {
			exportRequest.template = templateId;
		}
		if (this.saveForm.get('save_as_new_template').value === true) {
			this.saveAsNewTemplate();
		}

		const timeZone = await this.timezoneService.guessTimezone();
		exportRequest.timezone = timeZone;
		this.onExport.emit(exportRequest);
	}

	private saveAsNewTemplate() {
		const tpl: ExportTemplate = new ExportTemplate();
		tpl.active = true;
		tpl.description = this.saveForm.get('new_template_name').value;
		tpl.export_type = this.exportConfig.type;
		tpl.settings = {
			combine_travel_and_receipts: this.exportConfig.combine_travel_and_receipts,
			csv_separator: this.exportConfig.csv_separator,
			decimal_separator: this.exportConfig.decimal_separator,
			overview: this.exportConfig.overview,
			ubl_add_pdf: this.exportConfig.ubl_add_pdf,
			ubl_attachments: this.exportConfig.ubl_attachments,
			ubl_combine_attachments: this.exportConfig.ubl_combine_attachments,
			split_per_person: this.exportConfig.split_per_person,
			stats: this.exportConfig.stats,
			empty_footer: this.exportConfig.empty_footer,
			split_images_from_data: this.exportConfig.split_images_from_data,
		};
		tpl.template = {
			Columns: this.exportConfig.columns,
			TravelColumns: this.exportConfig.travel_columns,
			TravelAndReceiptColumns: this.exportConfig.travel_and_receipt_columns,
		};
		tpl.type = this.exportType;
		// The back-end wants the Columns as integrations when its a booking integration.
		if (tpl.type === ExportType.BOOKING) {
			tpl.template.integration = tpl.template.Columns;
			delete tpl.template.Columns;
			delete tpl.template.TravelAndReceiptColumns;
			delete tpl.template.TravelColumns;
		}
		// The back-end wants the Columns as CreditcardColumns when its a creditcard.
		if (tpl.type === ExportType.CREDITCARD) {
			tpl.template.CreditcardColumns = tpl.template.Columns;
			delete tpl.template.Columns;
			delete tpl.template.TravelAndReceiptColumns;
			delete tpl.template.TravelColumns;
		}

		if (this.userService.getCurrentLoggedUser().hasRole(UserRole.CompanyAdmin)) {
			this.exportTemplatesService.saveTemplate(tpl, ExportTemplateUserRole.company, this.companyService.getCompanyOfLoggedUser().id);
		} else {
			this.exportTemplatesService.saveTemplate(tpl, ExportTemplateUserRole.user);
		}
	}

	selectedTemplateChanged(templateID) {
		if (stringIsSetAndFilled(templateID)) {
			this.exportTemplatesService.getTemplate(templateID, ExportTemplateUserRole.user).then((data) => {
				this.exportConfig = {
					id: data.id,
					type: data.export_type,
					columns: data.template.Columns,
					travel_columns: data.template.TravelColumns,
					travel_and_receipt_columns: data.template.TravelAndReceiptColumns,
					...data.settings,
				};

				switch (data.type) {
					case ExportType.BOOKING:
						this.exportConfig.columns = data.template?.integration ?? [];
						break;
					case ExportType.CREDITCARD:
						this.exportConfig.columns = data.template?.CreditcardColumns ?? [];
						break;
					default:
						this.exportConfig.columns = data.template?.Columns;
						break;
				}
			});
		} else {
			this.resetSettings(false);
		}
	}

	resetSettings(showConformationMessage = true) {
		this.exportFormConfigurationComponent?.resetSettings(showConformationMessage);
		this.exportForm.patchValue({
			email: null,
			email_add_attachments: false,
		});
		// to prevent infinite calls to this function, we only patch the form value here if it was not already null.
		// patchValue actually calls ngModelChange, even when the value is exactly the same.
		// TODO: Have to figure something out in order to combat this...
		if (isValueSet(this.exportTemplateForm.get('exportTemplate').value)) {
			this.exportTemplateForm.patchValue({
				exportTemplate: null,
			});
		}
		this.saveForm.patchValue({
			save_to_user_settings: false,
			save_as_new_template: false,
			new_template_name: '',
		});
	}

	public getNumberOfExports(): number {
		return this.exportIds?.length;
	}

	public isExportFormat(...type: Array<string>) {
		return type.includes(this.exportConfig.type);
	}

	public exportFormatHasColumns(): boolean {
		if (this.exportType === ExportType.SUMMARY_REGISTRATION) {
			return false;
		}
		return true;
	}

	public hasSelectedTemplate() {
		return isValueSet(this.exportTemplateForm.get('exportTemplate').value);
	}
}
