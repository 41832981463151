import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { ExpenseReport } from '#/models/transaction/expense-reports';
import { DeclarationStatusFlag } from '#/models/transaction/receipt';
import { ExpenseReportsService } from '#/services/transaction/expense-reports.service';

@Injectable({
	providedIn: 'root',
})
export class CanAccessTodoListGuard implements CanActivate {
	constructor(private expenseReportsService: ExpenseReportsService, private router: Router) {}

	async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean | UrlTree> {
		return this.expenseReportsService.getExpenseReport(route.paramMap.get('id')).then((report: ExpenseReport) => {
			if (
				report.status_to_show === DeclarationStatusFlag.NeedsInformation ||
				report.status_to_show === DeclarationStatusFlag.NotSubmitted
			) {
				return true;
			}

			if (state.url.endsWith('/todo')) {
				return this.router.navigateByUrl(state.url.replace('/todo', '/all'));
			} else {
				return this.router.navigateByUrl(state.url + '/all');
			}
		});
	}
}
