import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { ColumnsInterface } from '~/app/shared/ui/table/table';
import { Injectable } from '@angular/core';
import { SelectActionEnum, SelectActionInterface } from '~/app/shared/ui/select-action/select-action';
import { FilterEnum } from '~/app/shared/ui/filters/filter';
import { User } from '#/models/user/user.model';
import { TranslateService } from '@ngx-translate/core';
import { ExpenseReport } from '#/models/transaction/expense-reports';
import { DeclarationStatusFlag } from '#/models/transaction/receipt';

@Injectable({
	providedIn: 'root',
})
export class ColumnService {
	constructor(private translate: TranslateService) {}
	private columns: ColumnsInterface[] = [
		{
			name: this.translate.instant(_('Selection')),
			type: 'selector',
			enabled: true,
			required: true,
			position: 'pre',
			sortable: false,
			id: 'selection',
			sortingProperty: null,
		},
		{
			name: this.translate.instant(_('User')),
			slug: 'user',
			type: 'user',
			enabled: true,
			sortable: true,
			id: 'user',
			sortingProperty: 'user',
		},
		{
			name: this.translate.instant(_('Description')),
			slug: 'description',
			type: 'default',
			enabled: true,
			sortable: true,
			id: 'description',
			sortingProperty: 'description',
		},
		{
			name: this.translate.instant(_('Date range')),
			slug_start: 'start_date',
			slug_end: 'end_date',
			type: 'date-range',
			enabled: true,
			sortable: true,
			id: 'date_range',
			sortingProperty: 'start_date',
		},
		{
			name: this.translate.instant(_('Administration')),
			slug: 'administration',
			type: 'administration',
			enabled: true,
			sortable: true,
			id: 'administration',
			sortingProperty: 'administration',
		},
		{
			name: this.translate.instant(_('Cost center')),
			slug: 'cost_center',
			type: 'cost_center',
			enabled: true,
			sortable: true,
			id: 'cost_center',
			sortingProperty: 'costcenter',
		},
		{
			name: this.translate.instant(_('Cost unit')),
			slug: 'cost_unit',
			type: 'cost_unit',
			enabled: true,
			sortable: true,
			id: 'cost_unit',
			sortingProperty: 'costunit',
		},
		{
			name: this.translate.instant(_('Project')),
			slug: 'project',
			type: 'project',
			enabled: true,
			sortable: true,
			id: 'project',
			sortingProperty: 'project',
		},
		{
			name: this.translate.instant(_('Amount')),
			slug: 'amount',
			type: 'amount',
			currency_slug: 'currency',
			enabled: true,
			sortable: true,
			id: 'amount',
			sortingProperty: 'amount',
		},
		{
			name: this.translate.instant(_('Items')),
			slug: 'receipt_count',
			type: 'default',
			enabled: true,
			sortable: true,
			id: 'receipt_count',
			sortingProperty: 'receipt_count',
		},
		{
			name: this.translate.instant(_('Expense status')),
			slug: 'status_to_show',
			type: 'status_to_show',
			enabled: true,
			sortable: true,
			id: 'status_to_show',
			sortingProperty: 'status',
		},
		{
			name: this.translate.instant(_('Current approver')),
			slug: 'current_approver',
			type: 'current_approver',
			enabled: true,
			sortable: true,
			id: 'current_approver',
			sortingProperty: 'current_approver',
		},
		{
			name: this.translate.instant(_('Booking status')),
			booking_data_slug: 'booking_data',
			type: 'booking_status',
			declaration_status_slug: 'status',
			enabled: false,
			sortable: true,
			id: 'booking_status',
			sortingProperty: 'booked_on',
		},
		{
			name: this.translate.instant(_('Create date')),
			slug: 'createdate',
			type: 'date',
			enabled: false,
			sortable: true,
			id: 'createdate',
			sortingProperty: 'createdate',
		},
		{
			name: this.translate.instant(_('Update date')),
			slug: 'updatedate',
			type: 'date',
			enabled: false,
			sortable: true,
			id: 'updatedate',
			sortingProperty: 'updatedate',
		},
		{
			name: this.translate.instant(_('Settings')),
			type: 'column_config',
			enabled: true,
			required: true,
			position: 'end',
			sortable: false,
			id: 'settings',
			sortingProperty: null,
		},
	];

	getColumns(): ColumnsInterface[] {
		return this.columns;
	}
}

@Injectable({
	providedIn: 'root',
})
export class ActionService {
	constructor(private translate: TranslateService) {}

	canSetAuthorizationFlow(report: ExpenseReport): boolean {
		return (
			report?.expense_status?.current_status?.status !== DeclarationStatusFlag.Claimed &&
			report?.expense_status?.current_status?.status !== DeclarationStatusFlag.Denied &&
			report?.expense_status?.current_status?.status !== DeclarationStatusFlag.NeedsInformation
		);
	}

	getSelectActions(user: User, selectedReports: ExpenseReport[]): SelectActionInterface[] {
		const selectActions: SelectActionInterface[] = [];
		if (selectedReports.every((e) => this.canSetAuthorizationFlow(e)) && user.canSetCompanyAuthorizationFlow()) {
			selectActions.push({ title: this.translate.instant(_('Edit authorization flow')), action: SelectActionEnum.edit, multiple: false });
		}
		selectActions.push({ title: this.translate.instant(_('Export')), action: SelectActionEnum.export, multiple: true });
		return selectActions;
	}
}

@Injectable({
	providedIn: 'root',
})
export class FilterService {
	getFilterOptions(): FilterEnum[] {
		return [
			FilterEnum.search,
			FilterEnum.period,
			FilterEnum.since,
			FilterEnum.before,
			FilterEnum.users,
			FilterEnum.administration,
			FilterEnum.cost_center,
			FilterEnum.cost_unit,
			FilterEnum.project,
			FilterEnum.statusses,
			FilterEnum.booked,
			FilterEnum.authorization_flow,
			FilterEnum.current_approver,
		];
	}
}
