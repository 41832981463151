<ng-container>
	<span *ngIf="unreadCounter > 0" class="counter">{{ getUnreadCounter() }}</span>
	<a href="" class="dropdown-toggle" data-toggle="dropdown">
		<i class="ti-bell"></i>
	</a>
	<ul class="dropdown-menu" (domChange)="domChange($event)">
		<li class="notice-header">
			<i class="ti-bell pdd-right-10"></i>
			<span translate>Notifications</span>
		</li>
		<ul class="list-info overflow-y-auto relative scrollable" app-inbox-notification-list [inboxItems]="inboxItems"></ul>
		<li class="notice-footer">
			<a routerLink="/inbox" class="text-gray cursor-pointer"
				>{{ 'Check all notifications' | translate }} <i class="ei-right-chevron pdd-left-5 font-size-10"></i
			></a>
		</li>
	</ul>
</ng-container>
