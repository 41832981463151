import { Component, ElementRef, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { DeclarationStatusFlag, Receipt } from '#/models/transaction/receipt';
import { ReceiptsTableColumn } from '../receipts-table/receipts-table.component';
import { User } from '#/models/user/user.model';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap/modal/modal-ref';
import { TranslateService } from '@ngx-translate/core';
import { ModalService } from '~/app/services/modal.service';
import { importReceiptModalInBackground } from '~/app/modules/receipt/components/modals/lazy';
import { AccountingIntegrationV1, Company } from '#/models/company/company.model';
import { ExpenseReport } from '#/models/transaction/expense-reports';
import { isValueSet, truncateString } from '#/util/values';
import { TransactionRoutingService } from '~/app/modules/company/components/transactions/services/transaction-routing.service';

@Component({
	selector: '[app-receipts-table-row]',
	templateUrl: './receipts-table-row.component.html',
	styleUrls: ['./receipts-table-row.component.scss'],
})
export class ReceiptsTableRowComponent implements OnInit, OnDestroy, OnChanges {
	@Input() receipt: Receipt;
	@Input() company: Company;
	@Input() public columns: ReceiptsTableColumn[];
	@Input() integrations: Array<AccountingIntegrationV1>;
	@Input() checkboxes: {};
	@Input() user: User;
	@Input() tableType: 'expenses' | 'invoices' | 'personal' = 'personal';
	@Output() filtersChanged = new EventEmitter<any>();
	@Output() checkboxClick = new EventEmitter<any>();
	@Output() openExport = new EventEmitter<string>();
	/* Trigger change detection */
	@Input() changeDetection = 1;
	@Input() reports: ExpenseReport[];

	receiptId: string[] = [];
	@ViewChild('exportModule') exportModule: ElementRef;
	exportPromise: Promise<string>;
	protected destroyCallbacks = [];
	protected duplicateReceipt;

	constructor(
		private modalService: ModalService,
		private translate: TranslateService,
		private transactionRoutingService: TransactionRoutingService,
	) {}

	public report: ExpenseReport = null;

	getReport(): ExpenseReport {
		if (this.receipt.report && this.reports) {
			return this.reports.find((report) => report.id === this.receipt.report);
		}
		return null;
	}

	ngOnInit() {
		importReceiptModalInBackground();
	}

	ngOnChanges() {
		if (this.receipt.report) {
			this.report = this.getReport();
			if (isValueSet(this.report)) {
				this.report.description = truncateString(this.report.description, 50);
			}
		}
	}

	ngOnDestroy(): void {
		this.destroyCallbacks.forEach((cb) => cb());
	}

	financeCanDuplicate(): boolean {
		return (
			this.tableType === 'expenses' &&
			this.user.hasCompanyFinanceRole() &&
			this.receipt.isBooked() &&
			this.company?.modules?.finance_can_duplicate?.enabled === true
		);
	}

	public openReceipt(receipt: Receipt): void {
		this.transactionRoutingService.openReceipt(receipt.id, this.tableType);
	}

	async openShareModal(event) {
		const { ReceiptsShareModalComponent } = await import('../modals/receipts-share-modal/receipts-share-modal.component');

		const modalRef = this.modalService.open(ReceiptsShareModalComponent);
		this.setupReceiptsModal(modalRef);
		return false;
	}

	async openDeleteModal(event) {
		const { DeleteModalComponent } = await import('../../../generic/components/modals/delete-modal/delete-modal.component');

		const modalRef = this.modalService.open(DeleteModalComponent);
		this.setupReceiptsModal(modalRef);
		return false;
	}

	async openDuplicateModal(event) {
		const { DuplicateModalComponent } = await import('../../../generic/components/modals/duplicate-modal/duplicate-modal.component');

		const modalRef = this.modalService.open(DuplicateModalComponent);

		if (this.financeCanDuplicate()) {
			modalRef.componentInstance.duplicateByFinance = true;
		}
		this.setupReceiptsModal(modalRef);
		return false;
	}

	async openRetractExpensesModal(event) {
		const { ExpensesModalComponent } = await import('../../../generic/components/modals/expenses-modal/expenses-modal.component');

		const modalRef = this.modalService.open(ExpensesModalComponent);
		modalRef.componentInstance.action = 'retract';
		this.setupReceiptsModal(modalRef);
		return false;
	}

	async openSubmitExpensesModal(event) {
		const { ExpensesModalComponent } = await import('../../../generic/components/modals/expenses-modal/expenses-modal.component');
		const modalRef = this.modalService.open(ExpensesModalComponent);
		modalRef.componentInstance.action = 'submit';
		this.setupReceiptsModal(modalRef);
		return false;
	}

	async openMoveModal(event) {
		const { ReceiptsMoveModalComponent } = await import('../modals/receipts-move-modal/receipts-move-modal.component');

		const modalRef = this.modalService.open(ReceiptsMoveModalComponent);
		modalRef.componentInstance.action = 'move';
		this.setupReceiptsModal(modalRef);
		return false;
	}

	async openDownloadImagesModal(event) {
		const { DownloadImagesModalComponent } = await import(
			'../../../generic/components/modals/download-images-modal/download-images-modal.component'
		);

		const modalRef = this.modalService.open(DownloadImagesModalComponent);
		this.setupReceiptsModal(modalRef);
		return false;
	}

	async openBookModal(event) {
		const { BookModalComponent } = await import('../../../generic/components/modals/book-modal/book-modal.component');

		const modalRef = this.modalService.open(BookModalComponent);
		this.setupReceiptsModal(modalRef);
		return false;
	}

	async openForceOCRModal(event) {
		const { ForceOcrModalComponent } = await import('~/app/modules/generic/components/modals/force-ocr-modal/force-ocr-modal.component');

		const modalRef = this.modalService.open(ForceOcrModalComponent);
		this.setupReceiptsModal(modalRef);
		return false;
	}

	setupReceiptsModal(modalRef: NgbModalRef) {
		modalRef.componentInstance.checkboxes = {};
		modalRef.componentInstance.checkboxes[this.receipt.id] = true;
		modalRef.componentInstance.user = this.user;
		if (this.isAdministrationOffice()) {
			modalRef.componentInstance.type = 'document';
		} else if (this.tableType === 'invoices') {
			modalRef.componentInstance.type = 'invoice';
		} else {
			modalRef.componentInstance.type = 'receipt';
		}
		let doneSubscription = modalRef.componentInstance.done.subscribe(() => {
			this.filtersChanged.emit(true);
		});

		modalRef.result
			.then((reason) => {
				doneSubscription.unsubscribe();
				doneSubscription = null;
			})
			.catch((reason) => {});

		this.destroyCallbacks.push(() => {
			if (doneSubscription) {
				doneSubscription.unsubscribe();
			}
		});
	}

	checkBoxClicked($event) {
		this.checkboxClick.emit({
			event: $event,
			id: this.receipt.id,
			altKey: $event.altKey,
		});
	}

	public isAdministrationOffice(): boolean {
		return this.company && this.company.isadministrationoffice;
	}

	public hasAcceptedStatus(): boolean {
		return (this.receipt.declarationstatus.status as string) === DeclarationStatusFlag.Accepted;
	}

	compactMode(): boolean {
		return this.user && this.user.preferences && this.user.preferences.display_mode === 'compact';
	}

	public isReportEnabled(): boolean {
		return this.company?.modules?.report?.enabled;
	}
}
