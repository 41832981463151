import { categoryDefaultsApiToFrontend } from '#/models/transaction/category/categoryDefaults/transformer';
import { CategoryDefaultsFrontendModel } from '#/models/transaction/category/categoryDefaults/frontendModel';

export class CategoryDefaultsApiModel {
	Provider: string;
	Division: string;
	GLAccount: string;
	Journal: string;
	VATCode: string;
	ledger: string; // This field is for non-integration companies.

	constructor(data?: any) {
		if (data) {
			Object.assign(this, data);
		}
	}

	apiToFrontend(): CategoryDefaultsFrontendModel {
		return categoryDefaultsApiToFrontend(this);
	}
}
