import { RegistrationApiModel } from '#/models/transaction/registration/apiModel';
import { RegistrationFrontendModel } from '#/models/transaction/registration/frontendModel';
import { TransactionFrontendModel } from '#/models/transaction/transaction/frontendModel';
import { stringIsSetAndFilled, useIfNumberIsSet, useIfStringIsSet } from '#/util/values';
import { arrayIsSetAndFilled } from '#/util/arrays';

export const personalRegistrationPrefix = 'personalRegistration-';
export const companyRegistrationPrefix = 'companyRegistration-';

export function registrationApiModelToTxFEModel(registrationApiModel: RegistrationApiModel): TransactionFrontendModel {
	const feModel = new TransactionFrontendModel();

	// company registrations do not have a user value
	feModel.id = stringIsSetAndFilled(registrationApiModel.user)
		? personalRegistrationPrefix + registrationApiModel.id
		: companyRegistrationPrefix + registrationApiModel.id;
	feModel.description = registrationApiModel.description;
	feModel.transactionInterfaceId = registrationApiModel.transactionInterface;
	feModel.transportationType = registrationApiModel.transportationType;
	feModel.dates = registrationApiModel.dates;
	feModel.purchaseDate = registrationApiModel.registrationDate;
	feModel.travelRoute = {
		route: registrationApiModel.routing?.route,
		distance: Number.isFinite(registrationApiModel.routing?.googleMapsRouteDistanceMeters)
			? registrationApiModel.routing.googleMapsRouteDistanceMeters / 1000
			: null,
		locations: registrationApiModel.routing?.locations?.map((e) => JSON.stringify(e)),
	};
	feModel.distanceKm = Number.isFinite(registrationApiModel.routing?.googleMapsRouteDistanceMeters)
		? registrationApiModel.routing.googleMapsRouteDistanceMeters / 1000
		: registrationApiModel.distanceTotalInKM;
	feModel.administration = registrationApiModel.administration;
	feModel.category = registrationApiModel.category;
	feModel.user = registrationApiModel.user;
	feModel.attachments = [];
	feModel.useExchangeCurrency = false;
	feModel.exchangeCurrency = null;
	feModel.amount = { amount: registrationApiModel.money?.amount, currency: registrationApiModel.money?.currency };
	return feModel;
}

export function txFEModelToRegistrationApiModel(transactionModel: TransactionFrontendModel): RegistrationApiModel {
	const apiModel = new RegistrationApiModel();
	if (stringIsSetAndFilled(transactionModel.id)) {
		apiModel.id = stringIsSetAndFilled(transactionModel.user)
			? transactionModel.id.substring(personalRegistrationPrefix.length)
			: transactionModel.id.substring(companyRegistrationPrefix.length);
	}
	apiModel.description = transactionModel.description;
	apiModel.transactionInterface = transactionModel.transactionInterfaceId;
	apiModel.transportationType = transactionModel.transportationType;
	const distanceToUse = transactionModel.distanceKm ?? transactionModel.travelRoute?.distance;
	apiModel.registrationDate = transactionModel.purchaseDate;
	apiModel.dates = transactionModel.dates;
	// if there is only one or no date the registration is a bulk registration therefore we should fill the distanceTotalInKM and not googleMapsRouteDistanceMeters as the be will not accept both
	if (arrayIsSetAndFilled(transactionModel.dates) && transactionModel.dates.length > 1) {
		apiModel.routing = {
			googleMapsRouteDistanceMeters: Number.isFinite(distanceToUse) ? distanceToUse * 1000 : null,
			route: transactionModel.travelRoute?.route,
			locations: transactionModel.travelRoute?.locations.map((e) => JSON.parse(e)),
		};
	} else {
		apiModel.distanceTotalInKM = distanceToUse;
	}

	apiModel.administration = transactionModel.administration;
	apiModel.category = transactionModel.category;
	apiModel.user = transactionModel.user;
	return apiModel;
}

export function apiToFrontend(
	apiModel: RegistrationApiModel,
	transactionClass: typeof RegistrationFrontendModel,
): RegistrationFrontendModel {
	const registrationFrontendModel = new transactionClass();
	registrationFrontendModel.id = apiModel.id;
	registrationFrontendModel.description = apiModel.description;
	registrationFrontendModel.administration = apiModel.administration;
	registrationFrontendModel.routing = apiModel.routing;
	registrationFrontendModel.category = apiModel.category;
	// this is a hack for the table visualization of the dates
	registrationFrontendModel.dates = arrayIsSetAndFilled(apiModel.dates) ? apiModel.dates : [apiModel.registrationDate];
	registrationFrontendModel.registrationDate = apiModel.registrationDate;
	registrationFrontendModel.transportationType = apiModel.transportationType;
	registrationFrontendModel.transactionInterface = apiModel.transactionInterface;
	registrationFrontendModel.user = apiModel.user;
	registrationFrontendModel.underlyingType = apiModel.underlyingType;
	registrationFrontendModel.amount = {
		amount: apiModel.money?.amount,
		currency: apiModel.money?.currency !== 'XXX' ? apiModel.money?.currency : null,
	};
	registrationFrontendModel.distanceTotalInKM = apiModel.distanceTotalInKM;
	return registrationFrontendModel;
}

export function frontendToApi(frontendModel: RegistrationFrontendModel): RegistrationApiModel {
	const registrationApiModel = new RegistrationApiModel();
	registrationApiModel.id = frontendModel.id;
	registrationApiModel.description = frontendModel.description;
	registrationApiModel.administration = frontendModel.administration;
	registrationApiModel.routing = frontendModel.routing;
	registrationApiModel.distanceTotalInKM = frontendModel.distanceTotalInKM;
	registrationApiModel.category = frontendModel.category;
	registrationApiModel.registrationDate = frontendModel.registrationDate;
	registrationApiModel.dates = frontendModel.dates;
	registrationApiModel.transportationType = frontendModel.transportationType;
	registrationApiModel.transactionInterface = frontendModel.transactionInterface;
	registrationApiModel.user = frontendModel.user;
	registrationApiModel.underlyingType = frontendModel.underlyingType;
	registrationApiModel.money = {
		amount: useIfNumberIsSet(frontendModel.amount?.amount),
		currency: useIfStringIsSet(frontendModel.amount?.currency),
	};
	return registrationApiModel;
}
