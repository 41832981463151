import { Component } from '@angular/core';
import { PreTransactionFrontendModel } from '#/models/transaction/pre-transaction/frontendModel';
import { ActivatedRoute, Router } from '@angular/router';
import { preTxPrefix } from '#/models/transaction/pre-transaction/transformer';
import { InterfaceFrontendModel as TransactionInterfaceModel } from '#/models/transaction/interface/frontendModel';
import { TransactionInterfaceService } from '#/services/transaction/transaction-interface.service';

@Component({
	selector: 'app-gallery-list',
	templateUrl: './gallery-list.component.html',
	styleUrls: ['./gallery-list.component.scss'],
})
export class GalleryListComponent {
	public transactionInterfaces: Array<TransactionInterfaceModel> = undefined;

	constructor(
		private transactionInterfaceService: TransactionInterfaceService,
		private router: Router,
		private activatedRoute: ActivatedRoute,
	) {}

	public ngOnInit(): void {
		this.transactionInterfaceService.getInterfaces(true, true, true, null, true).then((res) => {
			this.transactionInterfaces = res.filter((e) => e.usedForPreTransactions);
		});
	}

	public async goToTransaction(preTransaction: PreTransactionFrontendModel) {
		await this.router.navigate([`./tx/${preTxPrefix}${preTransaction.id}`], {
			relativeTo: this.activatedRoute,
		});
	}

	public async createPreTransaction(txiId: string): Promise<void> {
		await this.router.navigate([`./tx/new`], {
			relativeTo: this.activatedRoute,
			queryParams: { 'tx-interface-id': txiId },
		});
	}
}
