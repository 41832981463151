import { Component, EventEmitter, NgModule, OnInit, Output } from '@angular/core';
import { AuthenticatedComponent } from '~/app/pages/authenticated/authenticated.component';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { TranslateModule } from '@ngx-translate/core';
import { getUserRoleLabel, UserRole } from '#/models/user/user.model';
import { Update } from '~/app/modules/user/models/user.actions';
import { UiModule } from '~/app/shared/ui/ui.module';
import { AppSelectOptions } from '@klippa/ngx-enhancy-forms';

@Component({
	selector: 'app-user-role-switcher',
	templateUrl: './user-role-switcher.html',
	styleUrls: ['./user-role-switcher.scss'],
})
export class UserRoleSwitcherComponent extends AuthenticatedComponent implements OnInit {
	@Output()
	public userRoleChange = new EventEmitter<number>();
	public value: number;
	public selectOptions: AppSelectOptions = [];
	selectedOption: any;

	ngOnInit(): void {
		super.ngOnInit();
		const adminRoles = this.user.getHighestAdminRole();
		this.selectOptions = Object.keys(UserRole)
			.filter((key) => !isNaN(Number(key)))
			.filter((role) => adminRoles >= Number(role))
			.map((role) => ({ name: this.getTranslation(getUserRoleLabel(role)), id: parseInt(role, 10) }));
	}

	change(event) {
		if (this.user.originaluserrole === undefined) {
			this.user.originaluserrole = this.user.userrole;
		}
		const cumulativeValue = event.reduce((a, b) => a + b, 0);
		this.user.userrole = cumulativeValue;
		this.userAPIService.fakeuserrole = cumulativeValue;
		this.userRoleChange.emit(cumulativeValue);
		if (cumulativeValue === 0) {
			this.user.userrole = this.user.originaluserrole;
		}
		this.store.dispatch(new Update({ user: this.user }));
	}
}

@NgModule({
	declarations: [UserRoleSwitcherComponent],
	imports: [CommonModule, FormsModule, NgSelectModule, TranslateModule, UiModule],
	exports: [UserRoleSwitcherComponent],
})
export class UserRoleSwitcherModule {}
