import { Action } from '@ngrx/store';
import { Update } from '@ngrx/entity';

import { Receipt } from '#/models/transaction/receipt';

export enum ReceiptActionTypes {
	LOAD_RECEIPTS = '[Receipt] Load Receipts',
	ADD_RECEIPT = '[Receipt] Add Receipt',
	UPSERT_RECEIPT = '[Receipt] Upsert Receipt',
	ADD_RECEIPTS = '[Receipt] Add Receipts',
	UPSERT_RECEIPTS = '[Receipt] Upsert Receipts',
	UPDATE_RECEIPT = '[Receipt] Update Receipt',
	UPDATE_RECEIPTS = '[Receipt] Update Receipts',
	DELETE_RECEIPT = '[Receipt] Delete Receipt',
	DELETE_RECEIPTS = '[Receipt] Delete Receipts',
	CLEAR_RECEIPTS = '[Receipt] Clear Receipts',
}

export class LoadReceipts implements Action {
	readonly type = ReceiptActionTypes.LOAD_RECEIPTS;

	constructor(public payload: { receipts: Receipt[] }) {}
}

export class AddReceipt implements Action {
	readonly type = ReceiptActionTypes.ADD_RECEIPT;

	constructor(public payload: { receipt: Receipt }) {}
}

export class UpsertReceipt implements Action {
	readonly type = ReceiptActionTypes.UPSERT_RECEIPT;

	constructor(public payload: { receipt: Receipt }) {}
}

export class AddReceipts implements Action {
	readonly type = ReceiptActionTypes.ADD_RECEIPTS;

	constructor(public payload: { receipts: Receipt[] }) {}
}

export class UpsertReceipts implements Action {
	readonly type = ReceiptActionTypes.UPSERT_RECEIPTS;

	constructor(public payload: { receipts: Receipt[] }) {}
}

export class UpdateReceipt implements Action {
	readonly type = ReceiptActionTypes.UPDATE_RECEIPT;

	constructor(public payload: { receipt: Update<Receipt> }) {}
}

export class UpdateReceipts implements Action {
	readonly type = ReceiptActionTypes.UPDATE_RECEIPTS;

	constructor(public payload: { receipts: Update<Receipt>[] }) {}
}

export class DeleteReceipt implements Action {
	readonly type = ReceiptActionTypes.DELETE_RECEIPT;

	constructor(public payload: { id: string }) {}
}

export class DeleteReceipts implements Action {
	readonly type = ReceiptActionTypes.DELETE_RECEIPTS;

	constructor(public payload: { ids: string[] }) {}
}

export class ClearReceipts implements Action {
	readonly type = ReceiptActionTypes.CLEAR_RECEIPTS;
}

export type ReceiptActionsUnion =
	| LoadReceipts
	| AddReceipt
	| UpsertReceipt
	| AddReceipts
	| UpsertReceipts
	| UpdateReceipt
	| UpdateReceipts
	| DeleteReceipt
	| DeleteReceipts
	| ClearReceipts;
