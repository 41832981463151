import { Component, EventEmitter, Host, Input, Optional, Output } from '@angular/core';
import { AppSelectOption, AppSelectOptions, FormElementComponent } from '@klippa/ngx-enhancy-forms';
import { ControlContainer, NG_VALUE_ACCESSOR } from '@angular/forms';
import { DynamicOptionsValueAccessorBase } from '~/app/shared/ui/forms/composed/pickers/dynamic/dynamic-options-picker/dynamic-options-value-accessor-base';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { CompanyIntegrationService } from '#/services/company/company-integration.service';
import { isValueSet, stringIsSetAndFilled } from '#/util/values';
import { UserService } from '~/app/modules/user/user.service';
import { ItemsWithHasMoreResultsPromise } from '#/models/appSelectOption.model';
import { CompanyService } from '#/services/company/company.service';
import { AccountingIntegrationV2Service } from '#/services/integration/accounting-integration-v2.service';
import { AAV2_PREFIX } from '#/models/transaction/transaction/transformer';
import { AccountingIntegrationV2 } from '#/models/accounting-integrations/accounting-integration-v2';
import { memoize } from 'lodash';
import { AccountingBookingType } from '#/models/transaction/bookingType';

@Component({
	selector: 'app-form-integration-picker',
	templateUrl: './integration-picker.component.html',
	styleUrls: ['../dynamic-options-picker/dynamic-options-picker.template.scss', './integration-picker.component.scss'],
	providers: [{ provide: NG_VALUE_ACCESSOR, useExisting: IntegrationPickerComponent, multi: true }],
})
export class IntegrationPickerComponent extends DynamicOptionsValueAccessorBase<string, AppSelectOption> {
	@Input() showClearCacheButton = false;
	@Input() includeAAv2Options = false;
	@Input() accountingBookingType: AccountingBookingType;
	@Output() onIntegrationsRefreshed: EventEmitter<void> = new EventEmitter<void>();
	public clearIntegrationsCachePromise: Promise<void>;

	constructor(
		@Optional() @Host() protected parent: FormElementComponent,
		@Optional() @Host() protected controlContainer: ControlContainer,
		private companyIntegrationService: CompanyIntegrationService,
		private userService: UserService,
		private companyService: CompanyService,
		private accountingIntegrationServiceV2: AccountingIntegrationV2Service,
	) {
		super(parent, controlContainer);
		this.defaultPlaceHolder = _('Select integration');
	}

	fetchItemsFn = async (start: number, searchQuery: string = ''): ItemsWithHasMoreResultsPromise<AppSelectOption> => {
		// we ignore pagination and search, because this list will always be very small and backend has no support for it.
		const aav1Items = await this.companyIntegrationService
			.getActiveIntegrations(this.companyIntegrationService.getCompanyOfLoggedUser().id)
			.then((res) => {
				return res
					.filter((e) => e.getLabel()?.toLowerCase().includes(searchQuery.toLowerCase()))
					.map((e) => ({
						id: e.Key,
						name: e.Label,
					}));
			});
		let aav2Items: AppSelectOptions = [];
		if (this.includeAAv2Options) {
			const integrations = await this.getAllAccountingIntegrations();
			aav2Items = await this.accountingIntegrationServiceV2.getAllV2ConnectedAccountingIntegrations().then((res) =>
				res
					// TODO: dont filter on the frontend, we need BE support
					.filter((authorization) => {
						const integration = integrations.find((int) => authorization.integrationId === int.id);
						if (stringIsSetAndFilled(this.accountingBookingType)) {
							return integration.capabilities.supportsBookingTypes.some((bookType) => this.accountingBookingType === bookType);
						}
						return authorization.description?.toLowerCase().includes(searchQuery.toLowerCase());
					})
					.map((e) => ({
						id: AAV2_PREFIX + e.id,
						name: e.description,
					})),
			);
		}
		return {
			hasMoreResults: false,
			items: [...aav1Items, ...aav2Items],
		};
	};

	fetchSelectedItemsFn = async (ids: Array<string>): Promise<Array<AppSelectOption>> => {
		const aav2Ids = ids.filter((e) => e.startsWith(AAV2_PREFIX)).map((e) => e.replace(AAV2_PREFIX, ''));
		const aav2Items: Array<AppSelectOption> = (
			await Promise.all(
				aav2Ids.map((id) => this.accountingIntegrationServiceV2.getConnectedV2Integration(this.companyService.getCompanyId(), id)),
			)
		).map((e) => ({
			id: AAV2_PREFIX + e.id,
			name: e.description,
		}));
		return this.companyIntegrationService.getActiveIntegrations(this.companyIntegrationService.getCompanyOfLoggedUser().id).then((res) => {
			const aav1Items = res
				.filter((e) => ids.includes(e.id))
				.map((e) => ({
					id: e.id,
					name: e.Label,
				}));
			return [...aav1Items, ...aav2Items];
		});
	};

	/* tslint:disable:member-ordering */
	private getAllAccountingIntegrations = memoize((): Promise<Array<AccountingIntegrationV2>> => {
		return this.accountingIntegrationServiceV2.getAllAccountingIntegrations(this.userService.getCurrentLoggedUser().company);
	});

	mapToSelectOptionFn = (e: AppSelectOption): AppSelectOption => {
		return e;
	};

	public async refreshIntegrations(): Promise<void> {
		const selectedIntegration: string = (stringIsSetAndFilled(this.innerValue) ? this.innerValue : this.innerValue[0]) as string;

		if (stringIsSetAndFilled(selectedIntegration)) {
			this.companyIntegrationService.clearCache();

			if (selectedIntegration.startsWith(AAV2_PREFIX)) {
				this.clearIntegrationsCachePromise = this.accountingIntegrationServiceV2.clearCacheForConnectedIntegration(
					selectedIntegration.replace(AAV2_PREFIX, ''),
				);
				await this.clearIntegrationsCachePromise;
			} else {
				this.clearIntegrationsCachePromise = this.companyIntegrationService.clearIntegrationsCache(
					this.userService.getCurrentLoggedUser().company,
					selectedIntegration,
				);
				await this.clearIntegrationsCachePromise;
			}
		}

		this.onIntegrationsRefreshed.emit();
	}

	public hasSomethingSelected(): boolean {
		return isValueSet(this.innerValue);
	}
}
