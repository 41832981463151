import {
	apiToFrontend as bookingLineApiToFrontend,
	frontendToApi as bookingLineFrontendToApi,
} from '#/models/transaction/booking-line/transformer';
import { apiToFrontend as authFlowApiToFrontend } from '#/models/transaction/authorization-flow/transformer';
import { isValueSet, stringIsSetAndFilled, useIfStringIsSet } from '#/util/values';
import { Attachment, AttachmentType } from '../attachment';
import { DeclarationStatusFlag, ReceiptTravelDeclaration } from '#/models/transaction/receipt';
import { arrayIsSetAndFilled } from '#/util/arrays';
import { TransactionApiModel } from '#/models/transaction/transaction/apiModel';
import { TransactionFrontendModel } from '#/models/transaction/transaction/frontendModel';
import { convertDateToYMD, getEarliestStringDateFromArray } from '#/util/date';
import { FinanceType } from '#/models/transaction/financeType';
import { filterUnsetValues } from '#/util/objects';
import { AccountingStatus } from '#/models/accounting-integrations/accounting-integration-v2';

export const AAV2_PREFIX = '__VERSION2__';

export function apiToFrontend(
	apiModel: TransactionApiModel,
	loggedUserId: string,
	transactionClass: typeof TransactionFrontendModel,
): TransactionFrontendModel {
	const result = new transactionClass();
	result.id = apiModel.id;
	result.user = apiModel.user;
	result.report = apiModel.report;
	result.description = apiModel.description;
	result.transactionInterfaceId = apiModel.transaction_interface;
	result.transactionStatus = apiModel.transactionStatus;
	result.isInvoice = apiModel.isinvoice;
	if (apiModel.currency_exchange?.is_exchanged) {
		result.useExchangeCurrency = true;
		result.exchangeCurrency = {
			fromAmountWithCurrency: {
				amount: apiModel.currency_exchange.original_amount,
				currency: apiModel.currency_exchange.from_currency,
			},
			exchangeRate: apiModel.currency_exchange.exchange_rate,
			toAmountWithCurrency: {
				amount: apiModel.amount,
				currency: apiModel.currency_exchange.to_currency,
			},
		};
	} else {
		result.useExchangeCurrency = false;
		result.amount = {
			amount: apiModel.amount,
			currency: apiModel.currency !== 'XXX' ? apiModel.currency : null,
		};
	}
	result.tipAmount = {
		amount: apiModel.tipAmount,
		currency: result.amount?.currency ?? result.exchangeCurrency?.fromAmountWithCurrency?.currency,
	};
	result.amountWithoutTip = {
		amount: apiModel.purchaseAmount,
		currency: result.amount?.currency ?? result.exchangeCurrency?.fromAmountWithCurrency?.currency,
	};

	if (arrayIsSetAndFilled(apiModel.vatitems)) {
		result.vatLines = apiModel.vatitems.map((e) => ({
			percentage: e.percentage / 100,
			amountWithCurrency: {
				amount: e.amount,
				currency: useIfStringIsSet(e.currency) ?? useIfStringIsSet(apiModel.currency_exchange?.from_currency) ?? apiModel.currency,
			},
		}));
	} else {
		result.vatLines = [];
	}
	if (stringIsSetAndFilled(apiModel.purchase_date_ymd)) {
		result.purchaseDate = apiModel.purchase_date_ymd;
	} else if (stringIsSetAndFilled(apiModel.purchasedate)) {
		result.purchaseDate = convertDateToYMD(new Date(apiModel.purchasedate));
	}
	result.dates = apiModel.dates;
	result.amountOfMinutes = apiModel.amount_of_minutes;
	result.transportationType = apiModel.transportationType;
	result.invoiceNumber = apiModel.invoice_number;
	result.merchant = useIfStringIsSet(apiModel.merchant);
	result.project = useIfStringIsSet(apiModel.companyproject);
	result.folderId = apiModel.group;
	result.financeType = apiModel.finance_type;
	result.tags = apiModel.tags;
	result.costUnit = useIfStringIsSet(apiModel.companycostunit);
	result.costCenter = useIfStringIsSet(apiModel.companycostcenter);
	result.administration = useIfStringIsSet(apiModel.companyadministration);
	result.category = useIfStringIsSet(apiModel.companycategory);
	result.country = useIfStringIsSet(apiModel.country);
	result.paymentMethod = apiModel.paymentmethod;
	result.customPaymentMethod = apiModel.payment_method_id;
	result.accountNumber = apiModel.accountnumber;
	result.attachments = [
		...(apiModel.documents ?? []).map((e) => Attachment.fromDocument(e)),
		...(apiModel.images ?? []).map((e) => Attachment.fromImage(e)),
		...(apiModel.ocrEnhancedFiles ?? []).map((e) => Attachment.fromOcrEnhancedFile(e)),
	];
	result.noAttachmentAvailable = apiModel.noDocumentAvailable;
	result.duplicateDocuments = apiModel.duplicate_documents ?? [];
	result.ocrChecks = {
		merchantBankAccountNumbers: apiModel.ocr_checks?.merchant_bank_account_numbers ?? [],
	};
	result.needsOcrReview = apiModel.needs_ocr_review;
	result.ocrStatus = apiModel.ocr_status;
	result.receiptDataManuallyValidated = apiModel.receipt_data_manually_validated;
	result.dueDate = apiModel.dueDate;
	result.conversation = apiModel.declarationstatushistory
		?.filter((e) => stringIsSetAndFilled(e.comment) || e.status !== e.previousstatus)
		.flatMap((e) => {
			return {
				author: isValueSet(e.by) ? { name: e.by.name, id: e.by.userid, actingUserId: e.by.actingUserId } : null,
				message: e.comment,
				date: e.date,
				status: e.status ?? DeclarationStatusFlag.NotSubmitted,
			};
		});

	// approving
	if (isValueSet(apiModel.declarationstatus)) {
		result.status = DeclarationStatusFlag[apiModel.declarationstatus.status];
	} else {
		result.status = DeclarationStatusFlag.NotSubmitted;
	}
	if (!isValueSet(result.status)) {
		throw new Error(`Invalid status: ${apiModel.declarationstatus.status}`);
	}
	if (stringIsSetAndFilled(apiModel.authorization_flow?.flow_id)) {
		result.authorizationFlow = authFlowApiToFrontend(apiModel.authorization_flow, result.status, loggedUserId);
	}

	result.bookingDate = isValueSet(apiModel.booking_date) ? convertDateToYMD(new Date(apiModel.booking_date)) : null;

	// booking
	if (!stringIsSetAndFilled(apiModel.accounting?.authorization)) {
		result.integration = useIfStringIsSet(apiModel.provider);
		result.division = useIfStringIsSet(apiModel.division);
		result.journal = useIfStringIsSet(apiModel.journal);
		result.integrationRelation = useIfStringIsSet(apiModel.relation_number);
		result.paymentCondition = useIfStringIsSet(apiModel.payment_condition);
		result.integrationPaymentMethod = useIfStringIsSet(apiModel.integration_payment_method);
	}

	result.isBooked =
		apiModel.bookingstatus?.isbooked === true ||
		apiModel.accounting?.status === AccountingStatus.BOOKED ||
		apiModel.accounting?.status === AccountingStatus.PROCESSED;
	result.bookingStatus = apiModel.bookingstatus;
	result.bookingLines =
		apiModel.bookings?.map((e) =>
			bookingLineApiToFrontend(
				e,
				apiModel.currency_exchange?.is_exchanged ? result.exchangeCurrency?.toAmountWithCurrency?.currency : result.amount?.currency,
			),
		) ?? [];
	result.validatedBookingLines = apiModel.validated_bookings;

	result.gAccount = apiModel.gAccount;

	if (isValueSet(apiModel.traveldeclaration)) {
		result.travelRoute = {
			distance: apiModel.traveldeclaration.distance,
			route: apiModel.traveldeclaration.route,
			locations: (apiModel.traveldeclaration.locations ?? []).map((location) => JSON.stringify(location)),
		};
	}

	if (stringIsSetAndFilled(apiModel.accounting?.authorization)) {
		result.integration = AAV2_PREFIX + apiModel.accounting.authorization;
	}
	result.accountingHeaders = apiModel.accounting?.headers;
	result.accountingBookingLines = {
		lines: apiModel.accounting?.lines,
	};

	result.accountingStatus = apiModel.accounting?.status;

	return result;
}

export function frontendToApi(frontendModel: TransactionFrontendModel): TransactionApiModel {
	// TODO dont set values if they are undefined. Null is fine though
	const result = new TransactionApiModel();
	result.id = frontendModel.id;
	result.user = frontendModel.user;
	result.report = frontendModel.report;
	result.description = frontendModel.description;
	result.transaction_interface = frontendModel.transactionInterfaceId;
	result.transactionStatus = frontendModel.transactionStatus;
	if (isValueSet(frontendModel.merchant) && frontendModel.merchant.startsWith('new-')) {
		result.custommerchant = frontendModel.merchant.slice(4);
		result.merchant = '';
	} else {
		result.merchant = frontendModel.merchant;
	}
	result.companyproject = frontendModel.project;
	result.companycostunit = frontendModel.costUnit;
	result.companycostcenter = frontendModel.costCenter;
	result.companyadministration = frontendModel.administration;
	result.country = frontendModel.country;
	result.companycategory = frontendModel.category;
	if (arrayIsSetAndFilled(frontendModel.attachments)) {
		result.ocrEnhancedFiles = frontendModel.attachments
			.filter((attachment: Attachment) => attachment.type === AttachmentType.OCR_ENHANCED_FILE)
			.map((attachment: Attachment) => {
				return attachment.id;
			});
		result.documents = frontendModel.attachments
			.filter((attachment: Attachment) => attachment.type !== AttachmentType.OCR_ENHANCED_FILE)
			.map((attachment: Attachment) => {
				return attachment;
			});
	}

	result.noDocumentAvailable = frontendModel.noAttachmentAvailable;
	result.receipt_data_manually_validated = frontendModel.receiptDataManuallyValidated;
	result.invoice_number = frontendModel.invoiceNumber;
	result.paymentmethod = frontendModel.paymentMethod;
	result.payment_method_id = frontendModel.customPaymentMethod;
	result.accountnumber = frontendModel.accountNumber;
	result.transportationType = frontendModel.transportationType;
	if (isValueSet(frontendModel.travelRoute)) {
		result.traveldeclaration = new ReceiptTravelDeclaration();
		result.traveldeclaration.distance = frontendModel.travelRoute.distance;
		result.traveldeclaration.route = frontendModel.travelRoute.route;
		result.traveldeclaration.locations = (frontendModel.travelRoute.locations ?? []).map((location) => JSON.parse(location));
	}
	result.needs_ocr_review = frontendModel.needsOcrReview;
	result.dates = frontendModel.dates;
	result.dueDate = frontendModel.dueDate;

	if (isValueSet(frontendModel.purchaseDate)) {
		result.purchase_date_ymd = frontendModel.purchaseDate;
	} else if (arrayIsSetAndFilled(frontendModel.dates)) {
		result.purchase_date_ymd = getEarliestStringDateFromArray(frontendModel.dates);
	}

	if (frontendModel.useExchangeCurrency) {
		result.amount = Math.round(frontendModel.exchangeCurrency.toAmountWithCurrency?.amount);
		result.currency = frontendModel.exchangeCurrency.toAmountWithCurrency?.currency;
		result.currency_exchange = {
			is_exchanged: true,
			exchange_rate: Number(frontendModel.exchangeCurrency.exchangeRate),
			from_currency: frontendModel.exchangeCurrency.fromAmountWithCurrency?.currency,
			original_amount: frontendModel.exchangeCurrency.fromAmountWithCurrency?.amount,
			to_currency: frontendModel.exchangeCurrency.toAmountWithCurrency?.currency,
		};
	} else {
		result.amount = frontendModel.amount?.amount;
		result.currency = frontendModel.amount?.currency ?? frontendModel.amountWithoutTip?.currency;
		result.currency_exchange = { is_exchanged: false };
	}
	result.tipAmount = frontendModel.tipAmount?.amount;
	result.purchaseAmount = frontendModel.amountWithoutTip?.amount;

	result.vatitems = (frontendModel.vatLines ?? [])
		.filter((e) => {
			return Number.isFinite(e.percentage) || Number.isFinite(e.amountWithCurrency.amount);
		})
		.map((e) => ({
			amount: e.amountWithCurrency?.amount,
			original_amount: 0, // TODO: whats this for?
			percentage: e.percentage * 100, // bug in BE, it wants it multiplied by 100
			currency: useIfStringIsSet(frontendModel.exchangeCurrency?.toAmountWithCurrency?.currency) ?? frontendModel.amount?.currency,
		}));

	// approving (or setting any other status) and sending remarks doesn't happen via this model
	// same goes for auth flows

	result.group = frontendModel.folderId;
	result.tags = frontendModel.tags;
	result.finance_type = frontendModel.financeType ?? FinanceType.PURCHASE;

	// booking aav1
	if (stringIsSetAndFilled(frontendModel.integration) && !frontendModel.integration?.startsWith(AAV2_PREFIX)) {
		result.provider = frontendModel.integration;
		result.division = frontendModel.division;
		result.journal = frontendModel.journal;
		result.relation_number = frontendModel.integrationRelation;
		result.payment_condition = frontendModel.paymentCondition;
		result.integration_payment_method = frontendModel.integrationPaymentMethod;
		if (stringIsSetAndFilled(frontendModel.bookingDate)) {
			result.booking_date = new Date(frontendModel.bookingDate);
		}

		if (
			arrayIsSetAndFilled(frontendModel.bookingLines) &&
			stringIsSetAndFilled(frontendModel.integration) &&
			stringIsSetAndFilled(frontendModel.report)
		) {
			result.validated_bookings = true;
		}

		result.bookings = frontendModel.bookingLines?.map((e) => bookingLineFrontendToApi(e));
	}

	// booking aav2
	if (frontendModel.integration?.startsWith(AAV2_PREFIX) && Object.entries(frontendModel.accountingHeaders ?? {}).length > 0) {
		result.accounting = {
			authorization: frontendModel.integration?.replace(AAV2_PREFIX, ''),
			lines: frontendModel.accountingBookingLines?.lines?.map(filterUnsetValues) ?? [],
		};
		if (!stringIsSetAndFilled(frontendModel.report)) {
			result.accounting.headers = filterUnsetValues(frontendModel.accountingHeaders);
		}
		result.validated_bookings = true;
	} else {
		result.accounting = null;
	}

	result.gAccount = frontendModel.gAccount;
	return result;
}
