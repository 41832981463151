<div class="passwordStrengthMeterContainer">
	<div class="bar">
		<div
			class="scoreBar"
			[ngClass]="{
				zeroPercent: score === 0,
				twentyFivePercent: score === 1,
				fiftyPercent: score === 2,
				seventyFivePercent: score === 3,
				hundredPercent: score === 4
			}"
		></div>
	</div>
	<div class="scoreDescription">{{ getPasswordStrengthDescription() }}</div>
</div>
