<klp-grid-container [fluid]="true">
	<app-nav-and-cta>
		<klp-gretel-bar></klp-gretel-bar>
		<div>
			<div *ngIf="showAddDropDown()" class="d-flex">
				<app-button
					variant="greenFilled"
					id="openFilters"
					aria-haspopup="true"
					aria-expanded="false"
					(click)="receiptsTable.openFilters($event)"
				>
					{{'Filters' | translate}}
					<span class="badge badge-white mb-0 ml-2" *ngIf="filters.activeFilters > 0">{{filters.activeFilters}}</span>
				</app-button>
				<app-button
					variant="greenFilled"
					(click)="addReceipt(presetActions[0].receipt_type, presetActions[0])"
					*ngIf="presetActions.length === 1 && company?.modules.receipt_presets.enabled && company.modules.receipt_presets.remove_default_add_options; else toggleDropdown"
				>
					<i class="ti-plus"></i> {{'Add' | translate}}
				</app-button>
				<ng-template #toggleDropdown>
					<app-button
						*ngIf="transactionInterfaces === undefined || transactionInterfaces?.length > 1"
						data-cy="btnAddReceiptDropdown"
						variant="greenFilled"
						id="addDropdown"
						data-toggle="dropdown"
						aria-haspopup="true"
						aria-expanded="false"
					>
						<i class="ti-plus"></i>&nbsp;{{'Add' | translate}}
					</app-button>
					<app-button
						*ngIf="transactionInterfaces?.length === 1"
						variant="greenFilled"
						routerLink="tx/new"
						[queryParams]="{'tx-interface-id': transactionInterfaces[0].id, 'view-mode': 'submit'}"
					>
						<i class="ti-plus"></i>&nbsp;<span>{{transactionInterfaces[0].menuCaption}}</span>
					</app-button>

					<div class="dropdown-menu line-height dropdown-menu-right dashboard-element-add-dropdown" aria-labelledby="addDropdown">
						<ng-container *ngIf="transactionInterfaces === undefined">
							<ng-container
								*ngIf="!company || (company?.modules.receipt_presets.enabled && !company.modules.receipt_presets.remove_default_add_options) || !company?.modules.receipt_presets.enabled"
							>
								<button
									data-cy="btnAddReceipt"
									class="dropdown-item"
									href="javascript:void(0);"
									(click)="addReceipt('receipt')"
									*ngIf="!isAdministrationOffice()"
								>
									{{'Add receipt' | translate}}
								</button>
								<button class="dropdown-item" href="javascript:void(0);" (click)="addReceipt('receipt')" *ngIf="isAdministrationOffice()">
									{{'Add document' | translate}}
								</button>
								<button
									data-cy="btnTravelExpense"
									class="dropdown-item"
									href="javascript:void(0);"
									(click)="addReceipt('travel')"
									*ngIf="(user.canUseTravelDeclaration() && user.getPreferences().receipt_type && company?.defaultpreferences?.receipt_type && !isAdministrationOffice()) || user.hasProRole()"
								>
									{{'Travel expense' | translate}}
								</button>
								<button
									class="dropdown-item"
									href="javascript:void(0);"
									(click)="addReceipt('travel')"
									*ngIf="user.canUseTravelDeclaration() && user.getPreferences().receipt_type && isAdministrationOffice()"
								>
									{{'Journey registration' | translate}}
								</button>
								<button class="dropdown-item" href="javascript:void(0);" (click)="documentSplit()" *ngIf="user.canDocumentSplit()">
									{{'Document splitter' | translate}}
								</button>
								<button class="dropdown-item" href="javascript:void(0);" (click)="bulkUpload()" *ngIf="user.canBulkUpload()">
									{{'Bulk upload' | translate}}
								</button>
							</ng-container>
							<button
								class="dropdown-item"
								href="javascript:void(0);"
								(click)="addReceipt(preset.receipt_type, preset)"
								*ngFor="let preset of presetActions"
							>
								{{ preset.getLabel() }}
							</button>
						</ng-container>
						<div *ngIf="transactionInterfaces !== undefined && transactionInterfaces?.length >= 0">
							<a
								*ngFor="let transactionInterface of transactionInterfaces"
								class="dropdown-item"
								routerLink="tx/new"
								[queryParams]="{'tx-interface-id': transactionInterface.id, 'view-mode': 'submit'}"
							>
								{{ transactionInterface.menuCaption }}
							</a>
						</div>
					</div>
				</ng-template>
			</div>
			<div *ngIf="!showAddDropDown()">
				<app-button
					variant="greenFilled"
					id="openFilters1"
					data-toggle="dropdown"
					aria-haspopup="true"
					aria-expanded="false"
					(click)="receiptsTable.openFilters($event)"
				>
					{{'Filters' | translate}}
				</app-button>
				<app-button variant="greenFilled" id="addButton" href="javascript:void(0);" (click)="addReceipt('receipt')">
					<i class="ti-plus"></i> {{'Add' | translate}}
				</app-button>
			</div>
		</div>
	</app-nav-and-cta>
	<div class="row" *ngIf="user.shouldBeRemindedToActivateMail()">
		<div class="col-12">
			<div class="alert alert-warning" role="alert">
				<span translate>Warning! You haven't validated your e-mail address yet. Didn't receive an e-mail? Click the following link:</span
				>&nbsp;<a href="javascript:void(0)" (click)="resendActivationMail()" translate>resend activation email</a>.
			</div>
		</div>
	</div>
	<div class="row">
		<div class="col-md-12">
			<div class="card">
				<div class="card-block">
					<app-loader-deprecated *ngIf="!initialLoaded"></app-loader-deprecated>
					<div *ngIf="initialLoaded" id="DataTables_Table_1_wrapper" class="dataTables_wrapper no-footer">
						<div class="dataTables_length">
							<div class="float-left table-header-action-row" *ngIf="receiptCount > 0">
								<label
									>{{'Display' | translate}}<select
										name="DataTables_Table_1_length"
										aria-controls="DataTables_Table_1"
										class=""
										[(ngModel)]="receiptsPerPage"
										(ngModelChange)="updateReceiptsPerPage()"
									>
										<option value="10">10</option>
										<option value="25">25</option>
										<option value="50">50</option>
										<option value="100">100</option>
									</select>
									{{'transactions' | translate}}</label
								>
							</div>

							<div class="float-left table-header-action-row mrg-left-25" *ngIf="getSelectedCount() > 0">
								<label>
									{{getSelectedCount()}} <span translate>transactions selected</span>
									<a
										class="text-primary mrg-left-15 table-header-button px-3"
										href="javascript:void(0)"
										(click)="selectedAllReceipts()"
										*ngIf="getSelectedCount() === receipts.length && getSelectedCount() != receiptCount"
										>{{ 'Select all transactions on all pages (%receiptCount%)' | translate:{receiptCount: receiptCount} }}</a
									>
									<a
										class="text-dark mrg-left-15 table-header-button px-3"
										href="javascript:void(0)"
										(click)="deselectAllSelected()"
										translate
										*ngIf="getSelectedCount() > receipts.length || getSelectedCount() == receiptCount"
										>Deselect all selected transactions</a
									>
								</label>
							</div>
						</div>
						<ngx-async-template [promise]="reportDeclarationsPromise">
							<ng-template ngx-async-inactive ngx-async-success let-reports="value">
								<app-receipts-table
									#receiptsTable
									[receipts]="receipts"
									[tableType]="'personal'"
									[filters]="filters"
									[defaultFilters]="defaultFilters"
									[(checkboxes)]="checkboxes"
									[(checkAll)]="checkAll"
									(filtersChanged)="updateFilters()"
									(onSortingChange)="updateFilters(); updateRouteWithSortingParams($event)"
									[filtered]="filtered"
									(receiptsChanged)="loadReceipts()"
									[reports]="reports?.reports"
								></app-receipts-table>
							</ng-template>
						</ngx-async-template>
						<div
							class="dataTables_info"
							role="status"
							aria-live="polite"
							[innerHTML]="'Showing %countmin% to %countmax% of %counttotal% transactions' | translate:{countmin: ((currentPage-1) * receiptsPerPage) + 1, countmax: currentPageReceipts(), counttotal: receiptCount}"
							*ngIf="receiptCount > 0"
						></div>
						<app-pager
							[currentPage]="currentPage"
							[totalItems]="receiptCount"
							[itemsPerPage]="receiptsPerPage"
							[maxSize]="7"
							(pageChange)="pageChanged($event); updateQueryParams({ page: $event })"
							*ngIf="receiptCount > 0"
						></app-pager>
					</div>
				</div>
			</div>
		</div>
	</div>
</klp-grid-container>
