<div class="modal modalRight fade show" (click)="closeFilters()">
	<div class="modal-dialog" role="document" (click)="$event.stopPropagation()">
		<div class="modal-content">
			<div class="side-modal-wrapper">
				<div class="filters">
					<h4 translate>Filters</h4>
					<klp-form [formGroup]="filtersForm">
						<div class="filter-options">
							<div *ngFor="let filterOption of filterOptions" [ngSwitch]="filterOption">
								<ng-template [ngSwitchCase]="'search'">
									<klp-form-element direction="vertical" [caption]="'Description' | translate">
										<klp-form-text-input
											id="search"
											[placeholder]="
												user.hasProRole() || user.hasCompanyRole()
													? ('Description or invoice number' | translate)
													: ('Description' | translate)
											"
											formControlName="search"
										>
										</klp-form-text-input>
									</klp-form-element>
								</ng-template>
								<ng-template [ngSwitchCase]="'period'">
									<klp-form-element direction="vertical" [caption]="'Period' | translate">
										<app-deprecated-period-picker id="period" formControlName="period"></app-deprecated-period-picker>
									</klp-form-element>
								</ng-template>
								<ng-template [ngSwitchCase]="'since'">
									<klp-form-element direction="vertical" [caption]="'From date' | translate">
										<klp-form-date-time-picker [placeholder]="'Select date' | translate" [showTimeInput]="false" formControlName="since">
										</klp-form-date-time-picker>
									</klp-form-element>
								</ng-template>
								<ng-template [ngSwitchCase]="'before'">
									<klp-form-element direction="vertical" [caption]="'To date' | translate">
										<klp-form-date-time-picker [placeholder]="'Select date' | translate" [showTimeInput]="false" formControlName="before">
										</klp-form-date-time-picker>
									</klp-form-element>
									<app-warning-text *ngIf="!isPeriodValid(filtersForm.get('since').value, filtersForm.get('before').value)" translate>
										'From date' cannot be a later date than 'to date'
									</app-warning-text>
								</ng-template>
								<ng-template [ngSwitchCase]="'users'">
									<ngx-async-template [promise]="userStatsPromise">
										<ng-template ngx-async-success let-users="value">
											<klp-form-element direction="vertical" [caption]="'Users' | translate">
												<klp-form-select
													[options]="users"
													[placeholder]="'All' | translate"
													formControlName="users"
													[multiple]="true"
													[customSearchFn]="customSearchFnUser"
												>
												</klp-form-select>
											</klp-form-element>
										</ng-template>
									</ngx-async-template>
								</ng-template>
								<ng-template [ngSwitchCase]="'user'">
									<ngx-async-template [promise]="userStatsPromise">
										<ng-template ngx-async-success let-users="value">
											<klp-form-element direction="vertical" [caption]="'User' | translate">
												<klp-form-select
													[options]="users"
													[placeholder]="'All' | translate"
													formControlName="user"
													[customSearchFn]="customSearchFnUser"
												>
												</klp-form-select>
											</klp-form-element>
										</ng-template>
									</ngx-async-template>
								</ng-template>
								<ng-template [ngSwitchCase]="'type'">
									<ngx-async-template
										[promise]="receiptTypesPromise"
										*ngIf="company?.canUseInvoices() && company?.canUseExpenses() && user.canUseInvoices()"
									>
										<ng-template ngx-async-success let-receiptTypeOptions="value">
											<klp-form-element direction="vertical" [caption]="'Receipt type' | translate">
												<klp-form-select [options]="receiptTypeOptions" [placeholder]="'All' | translate" formControlName="type">
												</klp-form-select>
											</klp-form-element>
										</ng-template>
										<ng-template ngx-async-pending>
											<klp-form-loading-indicator variant="picker"></klp-form-loading-indicator>
										</ng-template>
									</ngx-async-template>
								</ng-template>
								<ng-template [ngSwitchCase]="'category'">
									<klp-form-element direction="vertical" [caption]="'Category' | translate">
										<app-category-picker [multiple]="false" formControlName="category"> > </app-category-picker>
									</klp-form-element>
								</ng-template>
								<ng-template [ngSwitchCase]="'companycategory'">
									<klp-form-element direction="vertical" [caption]="'Category' | translate">
										<app-category-picker [multiple]="false" formControlName="companycategory"> > </app-category-picker>
									</klp-form-element>
								</ng-template>
								<ng-template [ngSwitchCase]="'administration'">
									<klp-form-element direction="vertical" [caption]="'Administration' | translate">
										<app-administration-picker
											[multiple]="false"
											[showStats]="showStats"
											[statsFilters]="requestQuery"
											[filterOnCompanyGroups]="false"
											[filterOnActives]="false"
											formControlName="administration"
										></app-administration-picker>
									</klp-form-element>
								</ng-template>
								<ng-template [ngSwitchCase]="'companyadministration'">
									<klp-form-element direction="vertical" [caption]="'Administration' | translate">
										<app-administration-picker
											[multiple]="false"
											[showStats]="showStats"
											[statsFilters]="requestQuery"
											[filterOnCompanyGroups]="false"
											[filterOnActives]="false"
											formControlName="companyadministration"
										></app-administration-picker>
									</klp-form-element>
								</ng-template>
								<ng-template [ngSwitchCase]="'cost_center'">
									<klp-form-element direction="vertical" [caption]="'Cost center' | translate">
										<!--Stats total count could be added here, in similar way as introduced in administration -->
										<app-cost-center-picker
											[multiple]="false"
											[filterOnCompanyGroups]="false"
											[filterOnActives]="false"
											formControlName="cost_center"
										>
										</app-cost-center-picker>
									</klp-form-element>
								</ng-template>
								<ng-template [ngSwitchCase]="'companycostcenter'">
									<klp-form-element direction="vertical" [caption]="'Cost center' | translate">
										<!--Stats total count could be added here, in similar way as introduced in administration -->
										<app-cost-center-picker
											[multiple]="false"
											[filterOnCompanyGroups]="false"
											[filterOnActives]="false"
											formControlName="companycostcenter"
										>
										</app-cost-center-picker>
									</klp-form-element>
								</ng-template>
								<ng-template [ngSwitchCase]="'cost_unit'">
									<klp-form-element direction="vertical" [caption]="'Cost unit' | translate">
										<!--Stats total count could be added here, in similar way as introduced in administration -->
										<app-cost-unit-picker
											[multiple]="false"
											[filterOnCompanyGroups]="false"
											[filterOnActives]="false"
											formControlName="cost_unit"
										></app-cost-unit-picker>
									</klp-form-element>
								</ng-template>
								<ng-template [ngSwitchCase]="'companycostunit'">
									<klp-form-element direction="vertical" [caption]="'Cost unit' | translate">
										<!--Stats total count could be added here, in similar way as introduced in administration -->
										<app-cost-unit-picker [multiple]="false" formControlName="companycostunit"></app-cost-unit-picker>
									</klp-form-element>
								</ng-template>
								<ng-template [ngSwitchCase]="'project'">
									<klp-form-element direction="vertical" [caption]="'Project' | translate">
										<!--Stats total count could be added here, in similar way as introduced in administration -->
										<app-project-picker [multiple]="false" formControlName="project"></app-project-picker>
									</klp-form-element>
								</ng-template>
								<ng-template [ngSwitchCase]="'companyproject'">
									<klp-form-element direction="vertical" [caption]="'Project' | translate">
										<!--Stats total count could be added here, in similar way as introduced in administration -->
										<app-project-picker [multiple]="false" formControlName="companyproject"></app-project-picker>
									</klp-form-element>
								</ng-template>
								<ng-template [ngSwitchCase]="'exported'">
									<klp-form-element direction="vertical" [caption]="'Exported' | translate">
										<klp-form-select [options]="exportedOptions" [placeholder]="'All' | translate" formControlName="exported">
										</klp-form-select>
									</klp-form-element>
								</ng-template>
								<ng-template [ngSwitchCase]="'status'">
									<klp-form-element direction="vertical" [caption]="'Status' | translate">
										<app-status-picker formControlName="status"> </app-status-picker>
									</klp-form-element>
								</ng-template>
								<ng-template [ngSwitchCase]="'statusses'">
									<klp-form-element direction="vertical" [caption]="'Status' | translate">
										<app-status-picker formControlName="statusses" [multiple]="true"> </app-status-picker>
									</klp-form-element>
								</ng-template>
								<ng-template [ngSwitchCase]="'booked'">
									<klp-form-element direction="vertical" [caption]="'Booking status' | translate">
										<klp-form-select [options]="bookingStatusOptions" [placeholder]="'All' | translate" formControlName="booked">
										</klp-form-select>
									</klp-form-element>
								</ng-template>
								<ng-template [ngSwitchCase]="'authorization_flow'">
									<klp-form-element direction="vertical" [caption]="'Authorization flow' | translate">
										<app-authorization-flow-status-picker formControlName="authorization_flow"> </app-authorization-flow-status-picker>
									</klp-form-element>
								</ng-template>
								<ng-template [ngSwitchCase]="'current_approver'">
									<klp-form-element direction="vertical" [caption]="'Current approver' | translate">
										<klp-form-select
											[options]="currentApproverOptions"
											[placeholder]="'Current approver' | translate"
											formControlName="current_approver"
										>
										</klp-form-select>
									</klp-form-element>
								</ng-template>
								<ng-template [ngSwitchCase]="'currency'">
									<klp-form-element direction="vertical" [caption]="'Currency' | translate">
										<app-currency-picker formControlName="currency"> </app-currency-picker>
									</klp-form-element>
								</ng-template>
								<ng-template [ngSwitchCase]="'merchant'">
									<klp-form-element direction="vertical" [caption]="'Merchant' | translate">
										<app-form-merchant-picker formControlName="merchant"> </app-form-merchant-picker>
									</klp-form-element>
								</ng-template>
								<ng-template [ngSwitchCase]="'vat'">
									<klp-form-element
										direction="vertical"
										[caption]="'VAT' | translate"
										*ngIf="user?.canUseVAT() && user?.getPreferences().vatmode"
									>
										<app-company-vat-code-picker formControlName="vat" [showStats]="showStats" [statsFilters]="requestQuery">
										</app-company-vat-code-picker>
									</klp-form-element>
								</ng-template>
								<ng-template [ngSwitchCase]="'payment_method_id'">
									<klp-form-element
										direction="vertical"
										[caption]="'Payment method' | translate"
										*ngIf="user?.canUsePaymentInfo() && user?.getPreferences()?.paymentinfo && company?.modulePaymentMethodsIsEnabled()"
									>
										<app-payment-method-picker formControlName="payment_method_id"> </app-payment-method-picker>
									</klp-form-element>
								</ng-template>
								<ng-template [ngSwitchCase]="'accountnumber'">
									<ngx-async-template [promise]="accountNumberOptions">
										<ng-template ngx-async-success let-accountNumberOptions="value">
											<klp-form-element
												direction="vertical"
												[caption]="'Account number' | translate"
												*ngIf="user.canUsePaymentInfo() && user.getPreferences().paymentinfo"
											>
												<klp-form-select [options]="accountNumberOptions" [placeholder]="'All' | translate" formControlName="accountnumber">
												</klp-form-select>
											</klp-form-element>
										</ng-template>
										<ng-template ngx-async-pending>
											<klp-form-loading-indicator variant="picker"></klp-form-loading-indicator>
										</ng-template>
									</ngx-async-template>
								</ng-template>
								<ng-template [ngSwitchCase]="'group'">
									<ngx-async-template [promise]="groupOptions">
										<ng-template ngx-async-success let-groupOptions="value">
											<klp-form-element
												direction="vertical"
												[caption]="'Folder' | translate"
												*ngIf="user.getPreferences().groups && !environment.feature_flags.disable_folders"
											>
												<klp-form-select [options]="groupOptions" [placeholder]="'All' | translate" formControlName="group">
												</klp-form-select>
											</klp-form-element>
										</ng-template>
										<ng-template ngx-async-pending>
											<klp-form-loading-indicator variant="picker"></klp-form-loading-indicator>
										</ng-template>
									</ngx-async-template>
								</ng-template>
								<ng-template [ngSwitchCase]="'tag'">
									<ngx-async-template [promise]="tagOptions">
										<ng-template ngx-async-success let-tagOptions="value">
											<klp-form-element direction="vertical" [caption]="'Tag' | translate" *ngIf="user.getPreferences().tags">
												<klp-form-select [options]="tagOptions" [placeholder]="'All' | translate" formControlName="tag"> </klp-form-select>
											</klp-form-element>
										</ng-template>
										<ng-template ngx-async-pending>
											<klp-form-loading-indicator variant="picker"></klp-form-loading-indicator>
										</ng-template>
									</ngx-async-template>
								</ng-template>
							</div>
						</div>
						<app-button [fullWidth]="true" (click)="resetFilters()" translate> Reset </app-button>
						<klp-form-submit-button [fullWidth]="true" [submitCallback]="saveFilters" translate> Save </klp-form-submit-button>
					</klp-form>
				</div>
			</div>
		</div>
	</div>
</div>
