import { Component, EventEmitter, Output, Input } from '@angular/core';
import { ReceiptService } from '#/services/transaction/receipt.service';
import { Receipt } from '#/models/transaction/receipt';
import { FileService } from '#/services/file.service';

@Component({
	selector: 'app-receipt-duplicate-modal',
	templateUrl: './receipt-duplicate-modal.component.html',
	styleUrls: ['./receipt-duplicate-modal.component.scss'],
})
export class ReceiptDuplicateModal {
	@Input() public receiptIds: [string];
	public duplicateAttachments = false;

	constructor(private receiptService: ReceiptService, private fileService: FileService) {}

	@Output() public onDismiss: EventEmitter<void> = new EventEmitter<void>();

	duplicateReceipts() {
		const duplicatePromises = this.receiptIds.map((receiptId: string) => this.duplicateReceiptById(receiptId));
		Promise.all(duplicatePromises).then(() => {
			this.closeModal();
		});
	}

	duplicateReceiptById(receiptId: string) {
		return this.receiptService.getReceipt(receiptId).then((receipt: Receipt) => {
			this.receiptService.createReceipt(receipt).then((duplicatedReceipt) => {
				if (this.duplicateAttachments) {
					const imagePromises = receipt.images.map((image) => {
						this.fileService.getImage(receipt.id, image.id).then((imageData) => {
							return this.fileService.addImage(duplicatedReceipt.id, new File([imageData], image.filename), true);
						});
					});
					Promise.all(imagePromises);
					const documentPromises = receipt.documents.map((document) => {
						this.fileService.getDocument(receipt.id, document.id).then((documentData) => {
							return this.fileService.addDocument(duplicatedReceipt.id, new File([documentData], document.filename), true);
						});
					});
					Promise.all(documentPromises);
				}
			});
		});
	}

	closeModal(): void {
		this.onDismiss.emit();
	}
}
