import { InvoiceApiModel } from '#/models/transaction/invoice/apiModel';
import { InvoiceFrontendModel } from '#/models/transaction/invoice/frontendModel';
import {
	apiToFrontend as transactionApiToFrontend,
	frontendToApi as transactionFrontendToApi,
} from '#/models/transaction/transaction/transformer';

export function apiToFrontend(apiModel: InvoiceApiModel, loggedUserId: string): InvoiceFrontendModel {
	const result: InvoiceFrontendModel = transactionApiToFrontend(apiModel, loggedUserId, InvoiceFrontendModel);

	return result;
}

export function frontendToApi(frontendModel: InvoiceFrontendModel): InvoiceApiModel {
	const result: InvoiceApiModel = transactionFrontendToApi(frontendModel);

	result.isinvoice = true;

	return result;
}
