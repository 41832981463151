import { ExportType } from '#/models/company/exporterInterfaces';
import { OpenClosed } from '~/app/shared/ui/forms/composed/pickers/open-closed-picker/open-closed-picker.component';

export interface ResponseBody {
	data: any;
	result: string;
	request_id: string;
}

export interface ScheduledExportsResponseBody extends ResponseBody {
	data: ScheduledExport[];
}

export interface ScheduledExportResponseBody extends ResponseBody {
	data: ScheduledExport;
}

export interface ScheduledExport {
	active: boolean;
	company: string;
	description: string;
	splitPerItem: boolean;
	splitPerUser: boolean;
	filters: ScheduledExportFilters;
	id?: string; // the id of the ScheduledExport
	lastExport?: string;
	settings: ScheduledExportSettings;
	time: string; // what hour of the day should the export run
	when?: string; // what day of the week should the export run
	type: string;
	frequency: string;
}

export interface ScheduledExportSettings {
	template: string;
	type: ExportType;
	set_status_to: ExportStatus;
	email: string;
	email_add_attachments: string;
	timezone: string;
}

export interface ScheduledExportFilters {
	declarationStatuses: ExportStatus;
	statuses?: Array<OpenClosed>; // for credit cards
	booked?: boolean;

	since: string; // date represented as string
	administrations: Array<string>;
	divisions: Array<string>;
	integrationPaymentMethodTypes: Array<string>;
	isInvoice?: boolean;
	bookAsInvoice?: boolean;
	receiptTypes: Array<ReceiptType>; // What type of expense it is, receipt or travel
	categories: Array<string>;
	paymentMethods: Array<string>; // standard payment methods, not bound to a company
	paymentMethodIds: Array<string>; // payment methods defined by a company, which is a UID
	currencies: Array<string>;
	users: Array<string>;
}

export enum ExportStatus {
	TO_CLAIM = 'ToClaim',
	CLAIMED = 'Claimed',
	APPROVED = 'Approved',
	INCOMPLETE = 'NeedsInformation',
	DENIED = 'Denied',
}

export enum BookingStatus {
	UNBOOKED = 'unbooked',
	BOOKED = 'booked',
}

export enum TypeOfDocument {
	INVOICE = 'invoice',
	EXPENSE = 'expense',
}

export enum ReceiptType {
	RECEIPT = 'receipt',
	TRAVEL = 'travel_expense',
}
