import { Component, Input } from '@angular/core';
import { CompanyPickerComponent } from '../../../modules/company/components/company-picker.component';
import { PaymentMethodService } from '#/services/company/payment-method.service';
import { memoize } from 'lodash';

@Component({
	selector: 'app-company-payment-method-label',
	templateUrl: './company-payment-method-label.component.html',
})
export class CompanyPaymentMethodLabelComponent extends CompanyPickerComponent {
	constructor(private paymentMethodService: PaymentMethodService) {
		super();
	}

	@Input() public payment_method_id: string = '';

	public getCompanyPaymentMethodLabel = memoize(async (paymentMethodId: string): Promise<string> => {
		return this.paymentMethodService.getPaymentMethod(paymentMethodId).then((res) => res.name);
	});
}
