import { Component } from '@angular/core';

/**
 * Used for a trick to force reloading a path. Check route-utils.service.ts
 */

@Component({
	selector: 'app-empty',
	template: '',
})
export class EmptyComponent {}
