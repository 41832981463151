import { Component, Host, Input, Optional, SimpleChanges } from '@angular/core';
import { FormElementComponent } from '@klippa/ngx-enhancy-forms';
import { User } from '#/models/user/user.model';
import { UserService } from '~/app/modules/user/user.service';
import { ControlContainer } from '@angular/forms';
import { CompanyService } from '#/services/company/company.service';
import { DynamicOptionsValueAccessorBase } from '~/app/shared/ui/forms/composed/pickers/dynamic/dynamic-options-picker/dynamic-options-value-accessor-base';
import {
	FilterOnGroupEnum,
	TransactionOwnerDependantPickerService,
	TransactionOwnerDependentPicker,
} from '#/services/transaction/transaction-owner-dependant-picker.service';
import { areArraysEqual } from '#/util/arrays';
import { arrayIsSetAndEmpty } from '#/util/arrays';

interface WithAdministration {
	administration: string;
}

@Component({
	selector: '',
	template: '',
})
export abstract class AdministrationDependantPickerComponent<S>
	extends DynamicOptionsValueAccessorBase<string, S>
	implements TransactionOwnerDependentPicker
{
	@Input() public shouldBelongToAdministrationIds: Array<string> = undefined;
	@Input() public multiple = true;
	@Input() public filterOnCompanyGroups = true;
	@Input() public filterOnActives = true;
	@Input() public txId: string = null;
	@Input() public filterOnGroupsOf: keyof typeof FilterOnGroupEnum = FilterOnGroupEnum.LoggedInUser;

	protected user: User;
	protected readonly companyId: string;
	protected max = 1000;
	protected groups: Array<string>;

	constructor(
		@Optional() @Host() protected parent: FormElementComponent,
		@Optional() @Host() protected controlContainer: ControlContainer,
		protected userService: UserService,
		protected companyApiService: CompanyService,
		protected transactionOwnerDependantPickerService: TransactionOwnerDependantPickerService,
	) {
		super(parent, controlContainer);
		this.companyId = companyApiService.getCompanyOfLoggedUser().id;
		this.user = this.userService.getCurrentLoggedUser();
	}

	public async ngOnInit() {
		super.ngOnInit();

		if (this.filterOnCompanyGroups) {
			this.groups = await this.getGroups();
		}
	}

	public ngOnChanges(simpleChanges: SimpleChanges): void {
		if (this.initiated && simpleChanges.shouldBelongToAdministrationIds) {
			if (
				!areArraysEqual(
					simpleChanges.shouldBelongToAdministrationIds.previousValue,
					simpleChanges.shouldBelongToAdministrationIds.currentValue,
				)
			) {
				this.picker._ext_refetchOptions();
				// if the provided admin ids change, we need to check if the selected items are still valid to select
				if (arrayIsSetAndEmpty(simpleChanges.shouldBelongToAdministrationIds.currentValue)) {
					this.innerValue = null;
				}
				this.picker._ext_deselectInvalidItems();
			}
		}
	}

	public getGroups(): Promise<string[]> {
		return this.transactionOwnerDependantPickerService.getGroups(this.txId, this.filterOnGroupsOf);
	}
}
