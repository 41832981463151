<ng-content></ng-content>

<!-- Wutu-data-tables -->
<ng-template #checkbox let-isSelected="isSelected">
	<klp-form-checkbox [ngModel]="isSelected" [renderUndefinedAsIndeterminate]="true"></klp-form-checkbox>
</ng-template>
<ng-template #configBtn let-clickHandler="clickHandler">
	<app-button variant="icon" (click)="clickHandler()" [hasPadding]="false" [hasBorder]="false" size="medium"
		><app-icon variant="settings" size="large"></app-icon
	></app-button>
</ng-template>
<ng-template #toggleBtn let-isSelected="isSelected">
	<klp-form-toggle [ngModel]="isSelected"></klp-form-toggle>
</ng-template>
<ng-template #saveBtn let-clickHandler="clickHandler">
	<app-button variant="greenFilled" [fullWidth]="true" (click)="clickHandler()">Save</app-button>
</ng-template>
<ng-template #actionMenuBtn let-clickHandler="clickHandler">
	<app-button variant="icon" (click)="clickHandler($event.target)" [hasPadding]="true" [hasBorder]="false" size="medium"
		><app-icon variant="dotsVertical" size="large"></app-icon
	></app-button>
</ng-template>
<ng-template #searchInput let-changeHandler="changeHandler" let-query="searchQuery">
	<span>{{setQuery(query)}}</span>
	<app-form-search-input [(ngModel)]="searchQuery" (ngModelChange)="changeHandler($event)"></app-form-search-input>
</ng-template>
<ng-template #filterButton let-clickHandler="clickHandler">
	<app-button variant="white" (click)="clickHandler($event.target)" size="medium">
		<div style="display: flex; align-items: center; gap: 0.5rem">
			<app-svg-icon icon="filter-lines" size="small"></app-svg-icon>
			Filter
		</div>
	</app-button>
</ng-template>
