import { Component, Input } from '@angular/core';
import { ValueAccessorBase } from '@klippa/ngx-enhancy-forms';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { IconSize } from '~/app/shared/ui/icon/icon.component';

@Component({
	selector: 'app-toggleable-setting',
	templateUrl: './toggleable-setting.component.html',
	styleUrls: ['./toggleable-setting.component.scss'],
	providers: [{ provide: NG_VALUE_ACCESSOR, useExisting: ToggleableSettingComponent, multi: true }],
})
export class ToggleableSettingComponent extends ValueAccessorBase<boolean> {
	@Input() caption: string;
	@Input() tooltip: string;
	@Input() menuIcon: boolean = false;
	@Input() showToggle: boolean = true;
	public iconSize = IconSize;

	public toggle(): void {
		if (this.disabled) {
			return;
		}
		this.innerValue = !this.innerValue;
		this.setInnerValueAndNotify(this.innerValue);
	}
}
