import { Injectable } from '@angular/core';
import { ExportColumnValues, ExportFormat } from '#/models/company/exporterInterfaces';
import { IntegrationBookingsExportRequest } from '#/models/company/company.model';
import { CsvSeparator, DecimalSeparator } from '#/models/company/export';
import { CompanyService } from '#/services/company/company.service';
import { CompanyCustomIntegrationService } from '#/services/company/company-custom-integration.service';

@Injectable({
	providedIn: 'root',
})
export class ExportCustomIntegrationService {
	constructor(protected companyCustomIntegrationService: CompanyCustomIntegrationService, private companyService: CompanyService) {}

	public export(
		exportFormat: ExportFormat,
		items: Array<string>,
		columns: Array<ExportColumnValues>,
		saveColumnsTemplate: boolean,
		addAsAttachmentsToEmail: boolean,
		selectedSeparator: CsvSeparator,
		selectedDecimal: DecimalSeparator,
		emailAddresses: Array<string>,
		ublAttachments: boolean,
		ublCombineAttachments: boolean,
		ublAddPdf: boolean,
		timezone: string,
		template?: string,
	): Promise<string> {
		const exportRequest: IntegrationBookingsExportRequest = {
			items,
			columns: columns.map((c) => ({
				type: 'value',
				label: c.label,
				custom_label: c.custom_label,
				value: c.value,
				enabled: c.enabled,
				add_currency_symbol: c.add_currency_symbol,
				date_format: c.date_format,
			})),
			email: emailAddresses.filter(Boolean).join(','),
			type: exportFormat,
			csv_separator: selectedSeparator,
			decimal_separator: selectedDecimal,
			email_add_attachments: addAsAttachmentsToEmail,
			save_template: saveColumnsTemplate,
			ubl_attachments: ublAttachments,
			ubl_combine_attachments: ublCombineAttachments,
			ubl_add_pdf: ublAddPdf,
			timezone,
			template,
		};

		return this.companyCustomIntegrationService.customIntegrationBookingsExport(
			this.companyService.getCompanyOfLoggedUser().id,
			exportRequest,
		);
	}

	public exportForXml(columns: Array<ExportColumnValues>, items: Array<string>, relayUrl: string, exportTemplate: string, timezone: string): Promise<string> {
		const exportRequest = {
			columns: columns || [],
			type: 'xml',
			items,
			url: relayUrl,
			save_template: false,
			email_add_attachments: false,
			template: exportTemplate,
			timezone,
		};

		return this.companyCustomIntegrationService.customIntegrationBookingsExportForXml(
			this.companyService.getCompanyOfLoggedUser().id,
			exportRequest,
		);
	}
}
