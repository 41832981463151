import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ExpenseReport } from '#/models/transaction/expense-reports';
import { ExpenseReportsService } from '#/services/transaction/expense-reports.service';
import { UntypedFormGroup, Validators } from '@angular/forms';
import { Company } from '#/models/company/company.model';
import { AuthenticatedComponent } from '~/app/pages/authenticated/authenticated.component';
import { User } from '#/models/user/user.model';
import { stringIsSetAndFilled } from '#/util/values';
import { CompanyAdministrationService } from '#/services/company/company-administration.service';
import { CompanyAdministrationListAPIRequest } from '#/models/company/administration.model';
import { DimensionSettings } from '#/models/company/dimension/dimenstionSettings.model';
import { UserService } from '~/app/modules/user/user.service';

@Component({
	selector: 'app-expense-report-edit-modal',
	templateUrl: './expense-report-edit-modal.component.html',
	styleUrls: ['./expense-report-edit-modal.component.scss'],
})
export class ExpenseReportEditModalComponent extends AuthenticatedComponent implements OnInit {
	@Input() public expenseReport: ExpenseReport = new ExpenseReport({});
	@Output() public onDismiss: EventEmitter<string> = new EventEmitter<string>();
	@Output() public onSave: EventEmitter<ExpenseReport> = new EventEmitter<ExpenseReport>();
	@Input() public user: User;
	@Input() public company: Company;
	public isInEditMode = false;
	public isCompleted = false;
	public showAdministrationPicker = false;
	public showCurrencyPicker = false;
	expenseReportForm: UntypedFormGroup = this.formBuilder.group({
		description: [null, [Validators.required]],
		administration: [null, [Validators.required]],
		cost_center: null,
		cost_unit: null,
		project: null,
		currency: [null, [Validators.required]],
	});
	public dimensions: DimensionSettings;

	constructor(
		private userService: UserService,
		private expenseReportsService: ExpenseReportsService,
		private companyAdministration: CompanyAdministrationService,
	) {
		super();
	}

	ngOnInit(): void {
		const companySubscription = this.store.select('company').subscribe((val) => {
			this.company = val.company;
		});
		companySubscription.unsubscribe();
		const filters = new CompanyAdministrationListAPIRequest();
		filters.company = this.company?.id ?? this.userService.getCurrentLoggedUser().company;
		filters.groups = this.user?.getCompanyGroups() ?? this.userService.getCurrentLoggedUser().getCompanyGroups();
		filters.active = true;
		filters.max = 1;
		filters.start = 0;

		// Initialise the administration, to prevent undefined exemption.
		this.expenseReportForm.patchValue({
			administration: null,
		});

		if (this.expenseReport.id) {
			this.isInEditMode = true;
			this.expenseReportForm.patchValue(this.expenseReport);
			this.setDimensions(this.expenseReport.administration);
		}

		if (!this.isInEditMode) {
			this.companyAdministration.getCompanyAdministrations(filters).then((data) => {
				/* The administration picker will only be shown when the user has access to more than one administration
				since every company has one default administration. */
				if (data.count === 1) {
					this.expenseReport.administration = data.administrations[0].id;
					this.expenseReportForm.patchValue({
						administration: data.administrations[0].id,
					});
					this.setDimensions(this.expenseReport.administration);
				} else {
					this.showAdministrationPicker = true;
				}
			});
		}

		// Search query from report picker.
		if (stringIsSetAndFilled(this.expenseReport.description)) {
			this.expenseReportForm.patchValue({
				description: this.expenseReport.description,
			});
		}
	}

	saveReport = (values) => {
		const body = { ...this.expenseReport, ...values };
		if (this.isInEditMode) {
			return this.expenseReportsService.updateExpenseReport(body).then(() => {
				this.onSave.emit(this.expenseReport);
			});
		} else {
			return this.expenseReportsService.createExpenseReport(body).then((report: ExpenseReport) => {
				this.expenseReport = report;
				this.onSave.emit(this.expenseReport);
			});
		}
	};

	closeModal(): void {
		this.onDismiss.emit();
	}

	private async setDimensions(administrationID: string) {
		if (administrationID) {
			const administration = await this.companyAdministration.getCompanyAdministration(this.company.id, administrationID);
			this.showCurrencyPicker = !stringIsSetAndFilled(administration.currency) || administration.currency === 'XXX';
			this.dimensions = administration.dimension_settings;
		} else {
			this.dimensions = null;
			this.showCurrencyPicker = false;
		}

		this.setRequiredValidator('cost_center', this.dimensions?.costcenter.required || false);
		this.setRequiredValidator('cost_unit', this.dimensions?.costunit.required || false);
		this.setRequiredValidator('project', this.dimensions?.project.required || false);
	}
	private setRequiredValidator(formControlName: string, isRequired: boolean) {
		if (isRequired) {
			this.expenseReportForm.get(formControlName).setValidators([Validators.required]);
		} else {
			this.expenseReportForm.get(formControlName).setValidators(null);
		}
	}

	administrationChanged(administrationID: string) {
		this.setDimensions(administrationID);
	}
}
