import { TransactionApiModel } from '#/models/transaction/transaction/apiModel';
import { apiToFrontend } from './transformer';

export class ReceiptApiModel extends TransactionApiModel {
	constructor(data?: any) {
		super(data);
	}

	transformToFrontend(loggedUserId: string) {
		return apiToFrontend(this, loggedUserId);
	}
}
