import { Language } from './models/language';
import { PaymentMethod } from './models/payment-method';
import { Gender } from './models/gender';
import { Currency } from '#/models/currency';
import { GenericActions, GenericActionTypes } from './generic.actions';
import { createSelector } from '@ngrx/store';

export interface GenericState {
	currencies: Currency[];
	documentTypes: DocumentType[];
	genders: Gender[];
	languages: Language[];
	paymentMethods: PaymentMethod[];
}

export const initialGenericState: GenericState = {
	currencies: [],
	documentTypes: [],
	genders: [],
	languages: [],
	paymentMethods: [],
};

export function genericReducer(state = initialGenericState, action: GenericActions): GenericState {
	switch (action.type) {
		case GenericActionTypes.SetCurrenciesAction:
			return {
				...state,
				currencies: action.payload.currencies,
			};
		case GenericActionTypes.SetGendersAction:
			return {
				...state,
				genders: action.payload.genders,
			};
		case GenericActionTypes.SetLanguagesAction:
			return {
				...state,
				languages: action.payload.languages,
			};
		case GenericActionTypes.SetPaymentMethodsAction:
			return {
				...state,
				paymentMethods: action.payload.paymentMethods,
			};
		default:
			return state;
	}
}

export const selectAllCurrencies = (state: GenericState) => state.currencies;
export const selectAllGenders = (state: GenericState) => state.genders;
export const selectAllLanguages = (state: GenericState) => state.languages;
export const selectAllPaymentMethods = (state: GenericState) => state.paymentMethods;

export const getCurrencyByCode = (code) =>
	createSelector(selectAllCurrencies, (allCurrencies) =>
		allCurrencies ? allCurrencies.filter((currency: Currency) => currency.Code === code).pop() : null,
	);

export const getGenderByCode = (code) =>
	createSelector(selectAllGenders, (allGenders) => (allGenders ? allGenders.filter((gender: Gender) => gender.Code === code).pop() : null));

export const getLanguageByCode = (code) =>
	createSelector(selectAllLanguages, (allLanguages) =>
		allLanguages ? allLanguages.filter((language: Language) => language.Code === code).pop() : null,
	);

export const getPaymentMethodByCode = (code) =>
	createSelector(selectAllPaymentMethods, (allPaymentMethods) =>
		allPaymentMethods ? allPaymentMethods.filter((paymentMethod: PaymentMethod) => paymentMethod.Code === code).pop() : null,
	);
