import { Injectable } from '@angular/core';
import { APIService } from '~/app/api/services/api.service';
import { CreditcardExportFormat, CsvSeparator, DecimalSeparator } from '#/models/company/export';
import { ExportColumnValues } from '#/models/company/exporterInterfaces';

@Injectable({
	providedIn: 'root',
})
export class ExportCreditcardStatementsService {
	constructor(private apiService: APIService) {}

	public exportStatements(
		exportFormat: CreditcardExportFormat,
		statementIds: Array<string>,
		creditCardColumns: Array<ExportColumnValues>,
		saveColumnsTemplate: boolean,
		addAsAttachmentsToEmail: boolean,
		selectedSeparator: CsvSeparator,
		selectedDecimal: DecimalSeparator,
		emailAddresses: Array<string>,
		timezone: string,
		template?: string
	): Promise<string> {
		const requestBody = {
			type: exportFormat,
			items: statementIds.map((id) => ({
				type: 'creditcardStatement',
				id,
			})),
			creditcard_statement_columns: creditCardColumns,
			email: emailAddresses.filter(Boolean).join(','),
			email_add_attachments: addAsAttachmentsToEmail,
			csv_separator: selectedSeparator,
			decimal_separator: selectedDecimal,
			save_template: saveColumnsTemplate,
			timezone,
			template,
		};

		return this.apiService.postToApi('creditcardStatement/export', requestBody).then((res) => res.data.url);
	}
}
