import { Injectable } from '@angular/core';
import { CompanyCostCenterData, CompanyCostCenterListAPIRequest, CostCenter } from '#/models/company/dimension/cost-center.model';
import { ImportResultCount } from '#/models/import-result.model';
import { CompanyDimensionsService } from '#/services/company/dimension/company-dimensions.service';
import { arrayIsSetAndFilled } from '#/util/arrays';

@Injectable({
	providedIn: 'root',
})
export class CompanyCostCenterService extends CompanyDimensionsService {
	public async getCompanyCostCenters(companyCostCenterListAPIRequest: CompanyCostCenterListAPIRequest): Promise<CompanyCostCenterData> {
		companyCostCenterListAPIRequest.list = true;
		if (arrayIsSetAndFilled(companyCostCenterListAPIRequest.ids)) {
			companyCostCenterListAPIRequest.max = 9999;
		}
		const results = await this.apiService
			.post(companyCostCenterListAPIRequest.getRequestURL(), this.getRequestBody(companyCostCenterListAPIRequest))
			.then((res) => new CompanyCostCenterData(res.data))
			.catch((e) => {
				this.notifications.handleAPIError(e);
				throw e;
			});
		return results;
	}

	getCompanyCostCenterFilteredOnPresetsAndAdministration(
		companyCostCenterListAPIRequest: CompanyCostCenterListAPIRequest,
		presetOptions: string[],
		administration,
	): Promise<CompanyCostCenterData> {
		return this.getCompanyCostCenters(companyCostCenterListAPIRequest).then((costCenters) => {
			const tempCostCenters = costCenters;
			tempCostCenters.company_costcenters = this.filterBasedOnPresetsAndAdministration(
				costCenters.company_costcenters,
				presetOptions,
				administration,
			);
			return tempCostCenters;
		});
	}

	importCompanyCostCenters(companyId: string, administration: string, file: File, skipFirstRow = true): Promise<ImportResultCount> {
		const formData = new FormData();
		formData.append('file', file);

		if (skipFirstRow) {
			formData.append('skip_first_row', '1');
		}
		if (administration) {
			formData.append('administration', administration);
		}

		let url = '/api/v1/companyCostCenter/import';
		if (companyId !== '') {
			url = '/api/v1/company/' + companyId + '/companyCostCenter/import';
		}

		return this.apiService
			.post(url, formData)
			.then((r) => {
				return Promise.resolve(new ImportResultCount(r.data));
			})
			.catch((e) => {
				this.notifications.handleAPIError(e);
				return Promise.reject(e);
			});
	}

	bulkCompanyCostCenters(companyId: string, type: string, costcenters: CostCenter[]): Promise<ImportResultCount> {
		let url = '/api/v1/companyCostCenter/bulk';
		if (companyId !== '') {
			url = '/api/v1/company/' + companyId + '/companyCostCenter/bulk';
		}

		return this.apiService
			.post(url, {
				type,
				costcenters,
			})
			.then((r) => {
				return Promise.resolve(new ImportResultCount(r.data));
			})
			.catch((e) => {
				this.notifications.handleAPIError(e);
				return Promise.reject(e);
			});
	}

	createCompanyCostCenter(companyId: string, costcenter: CostCenter): Promise<CostCenter> {
		let url = '/api/v1/companyCostCenter';
		if (companyId !== '') {
			url = '/api/v1/company/' + companyId + '/companyCostCenter';
		}

		return this.apiService
			.post(url, costcenter)
			.then((r) => {
				return Promise.resolve(new CostCenter(r.data));
			})
			.catch((e) => {
				this.notifications.handleAPIError(e);
				return Promise.reject(e);
			});
	}

	updateCompanyCostCenter(companyId: string, costcenter: CostCenter): Promise<CostCenter> {
		let url = '/api/v1/companyCostCenter/' + costcenter.id;
		if (companyId !== '') {
			url = '/api/v1/company/' + companyId + '/companyCostCenter/' + costcenter.id;
		}

		return this.apiService
			.patch(url, costcenter)
			.then((r) => {
				return Promise.resolve(new CostCenter(r.data));
			})
			.catch((e) => {
				this.notifications.handleAPIError(e);
				return Promise.reject(e);
			});
	}

	getCompanyCostCenter(companyId: string, id: string): Promise<CostCenter> {
		let url = '/api/v1/companyCostCenter/' + id;
		if (companyId !== '') {
			url = '/api/v1/company/' + companyId + '/companyCostCenter/' + id;
		}
		if (CompanyDimensionsService.requestCache.has(url)) {
			return CompanyDimensionsService.requestCache.get(url);
		}
		const promise = this.apiService
			.get(url)
			.then((r) => {
				CompanyDimensionsService.requestCache.delete(url);
				return Promise.resolve(new CostCenter(r.data));
			})
			.catch((e) => {
				this.notifications.handleAPIError(e);
				CompanyDimensionsService.requestCache.delete(url);
				return Promise.reject(e);
			});
		CompanyDimensionsService.requestCache.set(url, promise);
		return promise;
	}

	deleteCompanyCostCenter(companyId: string, id: string): Promise<any> {
		let url = '/api/v1/companyCostCenter/' + id;
		if (companyId !== '') {
			url = '/api/v1/company/' + companyId + '/companyCostCenter/' + id;
		}

		return this.apiService
			.delete(url)
			.then((r) => {
				return Promise.resolve(r);
			})
			.catch((e) => {
				this.notifications.handleAPIError(e);
				return Promise.reject(e);
			});
	}
}
