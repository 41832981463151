<app-modal #exportModalRef>
	<ng-template app-modal-body>
		<app-export-form
			[showHeader]="true"
			[exportIds]="exportIds"
			[exportType]="exportType"
			[companyExport]="isCompanyExport"
			[exportPromise]="exportUrlPromise"
			(onDismiss)="exportModalRef.closeModal()"
			(onExport)="processExport($event)"
			[headerMessage]="headerMessageRef"
		>
		</app-export-form>

		<ng-template #headerMessageRef>
			<span *ngIf="exportIds.length === 1" translate [translateParams]="{ docSingular: getExportTextForSingular() }"
				>You are going to export 1 %docSingular%.</span
			>
			<span *ngIf="exportIds.length > 1" translate [translateParams]="{ count: exportIds.length, docPlural: getExportTextForPlural() }"
				>You are going to export %count% %docPlural%.</span
			>
		</ng-template>
	</ng-template>
</app-modal>
