import { isValueSet, stringIsSetAndFilled, useIfNumberIsSet, useIfStringIsSet } from '#/util/values';
import { arrayIsSetAndFilled } from '#/util/arrays';
import { PreTransactionFrontendModel } from './frontendModel';
import { PreTransactionApiModel } from './apiModel';
import { Attachment, AttachmentType } from '#/models/transaction/attachment';
import { DeclarationStatusFlag, OCRStatus } from '#/models/transaction/receipt';
import { TransactionFrontendModel } from '#/models/transaction/transaction/frontendModel';

export const preTxPrefix = 'pre-tx-';

export function apiToTxFrontend(preTxApiModel: PreTransactionApiModel): TransactionFrontendModel {
	const feModel = new TransactionFrontendModel();
	feModel.id = preTxPrefix + preTxApiModel.id;
	feModel.user = preTxApiModel.user;
	feModel.description = preTxApiModel.description;
	feModel.transactionInterfaceId = preTxApiModel.transactionInterfaceId;
	feModel.status = DeclarationStatusFlag.NotSubmitted;

	if (isValueSet(preTxApiModel.moneyExchangedFrom?.amount)) {
		feModel.useExchangeCurrency = true;
		feModel.exchangeCurrency = {
			fromAmountWithCurrency: {
				amount: preTxApiModel.moneyExchangedFrom.amount,
				currency: preTxApiModel.moneyExchangedFrom.currency,
			},
			exchangeRate: preTxApiModel.exchangeRate,
			toAmountWithCurrency: {
				amount: preTxApiModel.money?.amount,
				currency: preTxApiModel.money?.currency,
			},
		};
	} else {
		feModel.useExchangeCurrency = false;
		feModel.exchangeCurrency = null;
		feModel.amount = { amount: preTxApiModel.money?.amount, currency: preTxApiModel.money?.currency };
	}

	feModel.vatLines = preTxApiModel.vatItems?.map((e) => ({
		percentage: e.vatPercentage / 100,
		amountWithCurrency: { amount: e.amount, currency: e.currency },
	}));

	feModel.purchaseDate = useIfStringIsSet(preTxApiModel.purchaseDate);
	feModel.merchant = useIfStringIsSet(preTxApiModel.merchant);
	feModel.project = useIfStringIsSet(preTxApiModel.project);
	feModel.costUnit = useIfStringIsSet(preTxApiModel.costUnit);
	feModel.costCenter = useIfStringIsSet(preTxApiModel.costCenter);
	feModel.administration = useIfStringIsSet(preTxApiModel.administration);
	feModel.customPaymentMethod = useIfStringIsSet(preTxApiModel.paymentMethod);
	feModel.category = useIfStringIsSet(preTxApiModel.category);
	if (arrayIsSetAndFilled(preTxApiModel.ocrEnhancedFiles)) {
		feModel.attachments = preTxApiModel.ocrEnhancedFiles.map((e) => {
			return Attachment.fromOcrEnhancedFile(e);
		});
		// for the BE there is no ocr Status as the ocr is made when the ocr file is created
		feModel.ocrStatus = OCRStatus.Processed;
	} else {
		feModel.attachments = [];
	}
	return feModel;
}

export function txFEModelToPreTransactionApiModel(transactionModel: TransactionFrontendModel): PreTransactionApiModel {
	const apiModel = new PreTransactionApiModel();
	if (stringIsSetAndFilled(transactionModel.id)) {
		apiModel.id = transactionModel.id.substring(preTxPrefix.length);
	}
	apiModel.user = transactionModel.user;
	apiModel.description = transactionModel.description;
	apiModel.transactionInterfaceId = transactionModel.transactionInterfaceId;
	if (transactionModel.useExchangeCurrency) {
		apiModel.moneyExchangedFrom = {
			amount: transactionModel.exchangeCurrency.fromAmountWithCurrency.amount,
			currency: transactionModel.exchangeCurrency.fromAmountWithCurrency.currency,
		};
		apiModel.exchangeRate = transactionModel.exchangeCurrency.exchangeRate;
		apiModel.money = {
			amount: transactionModel.exchangeCurrency.toAmountWithCurrency.amount,
			currency: transactionModel.exchangeCurrency.toAmountWithCurrency.currency,
		};
	} else {
		apiModel.money = { amount: transactionModel.amount?.amount, currency: transactionModel.amount?.currency };
		apiModel.moneyExchangedFrom = null;
		apiModel.exchangeRate = null;
	}
	apiModel.vatItems = transactionModel.vatLines?.map((e) => ({
		amount: e.amountWithCurrency.amount,
		currency: e.amountWithCurrency.currency,
		vatPercentage: e.percentage * 100,
	}));
	apiModel.purchaseDate = transactionModel.purchaseDate;
	apiModel.merchant = transactionModel.merchant;
	apiModel.project = transactionModel.project;
	apiModel.costUnit = transactionModel.costUnit;
	apiModel.costCenter = transactionModel.costCenter;
	apiModel.administration = transactionModel.administration;
	apiModel.category = transactionModel.category;
	apiModel.paymentMethod = transactionModel.customPaymentMethod;
	if (arrayIsSetAndFilled(transactionModel.attachments)) {
		apiModel.ocrEnhancedFiles = transactionModel.attachments.filter((e) => e.type === AttachmentType.OCR_ENHANCED_FILE).map((e) => e.id);
	}
	return apiModel;
}

export function apiToFrontend(
	apiModel: PreTransactionApiModel,
	loggedUserId: string,
	transactionClass: typeof PreTransactionFrontendModel,
): PreTransactionFrontendModel {
	const preTransactionFrontendModel = new transactionClass();
	preTransactionFrontendModel.id = apiModel.id;
	preTransactionFrontendModel.user = apiModel.user;
	preTransactionFrontendModel.description = apiModel.description;
	preTransactionFrontendModel.transactionInterfaceId = apiModel.transactionInterfaceId;
	preTransactionFrontendModel.status = DeclarationStatusFlag.NotSubmitted;

	if (isValueSet(apiModel.moneyExchangedFrom?.amount)) {
		preTransactionFrontendModel.useExchangeCurrency = true;
		preTransactionFrontendModel.exchangeCurrency = {
			fromAmountWithCurrency: {
				amount: apiModel.moneyExchangedFrom.amount,
				currency: apiModel.moneyExchangedFrom.currency,
			},
			exchangeRate: apiModel.exchangeRate,
			toAmountWithCurrency: {
				amount: apiModel.money.amount,
				currency: apiModel.money.currency,
			},
		};
	} else {
		preTransactionFrontendModel.useExchangeCurrency = false;
		preTransactionFrontendModel.amount = {
			amount: apiModel.money?.amount,
			currency: apiModel.money?.currency !== 'XXX' ? apiModel.money?.currency : null,
		};
	}

	if (arrayIsSetAndFilled(apiModel.vatItems)) {
		preTransactionFrontendModel.vatLines = apiModel.vatItems.map((e) => ({
			percentage: e.vatPercentage / 100,
			amountWithCurrency: {
				amount: e.amount,
				currency: useIfStringIsSet(e.currency) ?? useIfStringIsSet(apiModel.moneyExchangedFrom?.currency) ?? apiModel.money?.currency,
			},
		}));
	} else {
		preTransactionFrontendModel.vatLines = [];
	}

	preTransactionFrontendModel.purchaseDate = apiModel.purchaseDate;

	preTransactionFrontendModel.merchant = useIfStringIsSet(apiModel.merchant);
	preTransactionFrontendModel.project = useIfStringIsSet(apiModel.project);
	preTransactionFrontendModel.costUnit = useIfStringIsSet(apiModel.costUnit);
	preTransactionFrontendModel.costCenter = useIfStringIsSet(apiModel.costCenter);
	preTransactionFrontendModel.administration = useIfStringIsSet(apiModel.administration);
	preTransactionFrontendModel.category = useIfStringIsSet(apiModel.category);
	preTransactionFrontendModel.customPaymentMethod = useIfStringIsSet(apiModel.paymentMethod);
	if (arrayIsSetAndFilled(apiModel.ocrEnhancedFiles)) {
		preTransactionFrontendModel.attachments = apiModel.ocrEnhancedFiles.map((e) => {
			return Attachment.fromOcrEnhancedFile(e);
		});
		// for the BE there is no ocr Status as the ocr is made when the ocr file is created
		preTransactionFrontendModel.ocrStatus = OCRStatus.Processed;
	}
	preTransactionFrontendModel.createDate = apiModel.createDate;
	preTransactionFrontendModel.updateDate = apiModel.updateDate;

	return preTransactionFrontendModel;
}

export function frontendToApi(frontendModel: PreTransactionFrontendModel): PreTransactionApiModel {
	const preTransactionApiModel = new PreTransactionApiModel();
	preTransactionApiModel.id = frontendModel.id;
	preTransactionApiModel.user = frontendModel.user;
	preTransactionApiModel.description = frontendModel.description;
	preTransactionApiModel.transactionInterfaceId = frontendModel.transactionInterfaceId;

	preTransactionApiModel.merchant = frontendModel.merchant;
	preTransactionApiModel.project = frontendModel.project;
	preTransactionApiModel.costUnit = frontendModel.costUnit;
	preTransactionApiModel.costCenter = frontendModel.costCenter;
	preTransactionApiModel.administration = frontendModel.administration;
	preTransactionApiModel.category = frontendModel.category;
	preTransactionApiModel.paymentMethod = frontendModel.customPaymentMethod;

	preTransactionApiModel.purchaseDate = frontendModel.purchaseDate;

	if (frontendModel.useExchangeCurrency) {
		preTransactionApiModel.money = {
			amount: Math.round(frontendModel.exchangeCurrency.toAmountWithCurrency?.amount),
			currency: frontendModel.exchangeCurrency.toAmountWithCurrency?.currency,
		};
		preTransactionApiModel.exchangeRate = frontendModel.exchangeCurrency.exchangeRate;
		preTransactionApiModel.moneyExchangedFrom = {
			amount: useIfNumberIsSet(frontendModel.exchangeCurrency.fromAmountWithCurrency?.amount),
			currency: useIfStringIsSet(frontendModel.exchangeCurrency.fromAmountWithCurrency?.currency),
		};
	} else {
		preTransactionApiModel.money = {
			amount: useIfNumberIsSet(frontendModel.amount?.amount),
			currency: useIfStringIsSet(frontendModel.amount?.currency),
		};
		preTransactionApiModel.exchangeRate = null;
		preTransactionApiModel.moneyExchangedFrom = null;
	}

	if (arrayIsSetAndFilled(frontendModel.vatLines)) {
		preTransactionApiModel.vatItems = frontendModel.vatLines
			.filter((e) => {
				return Number.isFinite(e.percentage) || Number.isFinite(e.amountWithCurrency.amount);
			})
			.map((e) => ({
				amount: e.amountWithCurrency?.amount,
				vatPercentage: e.percentage * 100, // bug in BE, it wants it multiplied by 100
				currency: useIfStringIsSet(frontendModel.exchangeCurrency?.toAmountWithCurrency?.currency) ?? frontendModel.amount?.currency,
			}));
	}
	if (arrayIsSetAndFilled(frontendModel.attachments)) {
		preTransactionApiModel.ocrEnhancedFiles = frontendModel.attachments.map((attachment: Attachment) => attachment.id);
	}

	return preTransactionApiModel;
}
