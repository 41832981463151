import { getLocale } from '##/util/locale';
import { TranslateDefaultParser } from '@ngx-translate/core';
import { MissingTranslationHandler, MissingTranslationHandlerParams } from '@ngx-translate/core/lib/missing-translation-handler';
import { format as dateFnsFormat } from 'date-fns';

export class CustomParser extends TranslateDefaultParser {
	/* Custom parser in order to change the RegEx used for string interpolation. */
	/* Change from {{placeholder}} to %placeholder% */
	/* Author: Mark */
	templateMatcher: RegExp = /%\s?([^%\s]*)\s?%/g;
}

export class CustomHandler implements MissingTranslationHandler {
	/* Custom handler for missing translations in order to also perform string interpolation on non-translated keys. */
	/* Author: Mark */
	handle(params: MissingTranslationHandlerParams) {
		const translateService = params.translateService;
		return translateService.parser.interpolate(params.key, params.interpolateParams);
	}
}

export const format = (date: Date, formatStr: string) =>
	dateFnsFormat(date, formatStr, {
		locale: getLocale(),
	});
