import { User } from '#/models/user/user.model';
import { UserActions, UserActionTypes } from './user.actions';
import { setUser } from '~/app/util/sentry';

export interface UserState {
	currentUser: User | null;
	currentKey: string | null;
	currentSecret: string | null;
}

export const initialUserState: UserState = {
	currentUser: null,
	currentKey: null,
	currentSecret: null,
};

function setSentryUser(user: User) {
	setUser({
		name: user.getName(),
		user: user.getID(),
		email: user.getEmail(),
		email_validated: user.isEmailValidated(),
		roles: {
			pro: user.hasProRole(),
			company: user.hasCompanyRole(),
			company_manager: user.hasCompanyManagerRole(),
			company_finance: user.hasCompanyFinanceRole(),
			company_admin: user.hasCompanyAdminRole(),
		},
		preferences: user.getPreferences(),
		company: user.getCompany(),
		subscriptions: user.getSubscriptions(),
		authorizations: user.authorizations,
		partner_user: user.isPartnerUser(),
	});
}

export function userReducer(state = initialUserState, action: UserActions): UserState {
	switch (action.type) {
		case UserActionTypes.LoginAction:
			setSentryUser(action.payload.user);
			return {
				...state,
				currentUser: action.payload.user,
			};

		case UserActionTypes.UpdateAction:
			setSentryUser(action.payload.user);
			return {
				...state,
				currentUser: action.payload.user,
			};

		case UserActionTypes.UpdateKey:
			return {
				...state,
				currentKey: action.payload.key,
				currentSecret: action.payload.secret,
			};

		case UserActionTypes.LogoutAction:
			setUser({});
			return initialUserState;

		default:
			return state;
	}
}
