import { Injectable } from '@angular/core';
import { Company, AccountingIntegrationV1 } from '#/models/company/company.model';
import { take } from 'rxjs/operators';
import { SetIntegrations } from '#/models/company/company.actions';
import { AppState } from '~/app/reducers';
import { Store } from '@ngrx/store';
import { CompanyService } from '#/services/company/company.service';
import { APIService } from '~/app/api/services/api.service';
import { APINotificationsService } from '~/app/api/services/apinotifications.service';
import { CompanyIntegrationService } from '#/services/company/company-integration.service';

@Injectable({
	providedIn: 'root',
})
export class IntegrationService {
	integrations: Array<AccountingIntegrationV1>;

	constructor(
		private store: Store<AppState>,
		private companyAPIService: CompanyService,
		private apiService: APIService,
		private notifications: APINotificationsService,
		private companyIntegrationService: CompanyIntegrationService,
	) {
		this.store.select('company').subscribe((company) => {
			this.integrations = company.integrations;
		});
	}

	downloadCustomIntegrationTemplate(companyID: string, template: string): Promise<Blob> {
		const url = `/api/v1/company/${companyID}/customIntegration/${template}/import/template`;
		return this.apiService.getBlob(url).catch((e) => {
			this.notifications.handleAPIError(e);
			return Promise.reject(e);
		});
	}

	getIntegrationsForCompany(company: Company): Promise<Array<AccountingIntegrationV1>> {
		return this.companyIntegrationService.getAllIntegrations(company.getID());
	}

	public getCompanyIntegrations(): Array<AccountingIntegrationV1> {
		return this.integrations;
	}

	setIntegrationForCompany(company: Company) {
		return new Promise<Array<AccountingIntegrationV1>>((res, rej) => {
			/* We first check the store. Is there already a company set? Then resolve right away. */
			return this.store
				.select('company')
				.pipe(take(1))
				.subscribe((companyStore) => {
					/* Resolve with the value of the current company. */
					if (companyStore.integrations != null) {
						return res(companyStore.integrations);
					} else {
						if (company != null) {
							return this.companyIntegrationService
								.getAllIntegrations(company.getID())
								.then((integrations) => {
									this.store.dispatch(new SetIntegrations({ integrations: integrations }));
									res(integrations);
								})
								.catch((e) => {
									rej(e);
								});
						} else {
							return res([]);
						}
					}
				});
		});
	}

	public initializeIntegrations() {
		this.store.select('company').subscribe((value) => {
			this.setIntegrationForCompany(value.company);
		});
	}
}
