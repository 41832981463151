import { emptyID } from '#/util/mongoDB';

export class AuthorizationFlowFrontendModel {
	id: string;
	isInFlow: boolean;
	completedApprovals: Array<string>;
	possibleApprovers: Array<string>;
	possibleApproversOrder: Array<string>;
	requireApproverAmount: number;
	requireApproverOrder: boolean;
	awaitingApprovers: Array<string>;
	myTurnToApprove: boolean;
	otherPersonTurnToApprove: boolean;
	myApprovalIsFinalApproval: boolean;
	requiredApprovalsUntilFullyApproved: number;
	constructor(data?: any) {
		Object.assign(this, data);
	}

	public getValuesForForm() {
		return {
			id: this.id,
			isCustom: this.id === emptyID,
			possibleApprovers: this.possibleApprovers,
			possibleApproversOrder: this.possibleApproversOrder,
			requireApproverAmount: this.requireApproverAmount,
			requireApproverOrder: this.requireApproverOrder,
		};
	}
}
