import { isValueSet } from '#/util/values';

export interface BookingSuggestionsModel {
	value: string;
	score: number;
}

export class BookingSuggestion {
	relation: Array<BookingSuggestionsModel>;
	journal: Array<BookingSuggestionsModel>;
	lines: Array<{
		generalLedger: Array<BookingSuggestionsModel>;
		vatCode: Array<BookingSuggestionsModel>;
	}>;

	constructor(data = null) {
		if (isValueSet(data)) {
			Object.assign(this, data);
		}
	}
}
