import { Pipe, PipeTransform } from '@angular/core';
import { formatDistance } from 'date-fns';

@Pipe({
	name: 'timeSince',
})
export class TimeSince implements PipeTransform {
	transform(input: Date | undefined | false | null) {
		if (!input) {
			input = new Date();
		}
		const timeSince = formatDistance(input, new Date());
		return timeSince;
	}
}
