<div class="treeNodeContent" [ngClass]="{ disabledTreeNode: disabled }">
	<div class="treeNodeNameSpace" [ngClass]="{ treeNodeNameSpace: isNode, treeLeafNameSpace: !isNode }">
		<button *ngIf="isNode" class="btn btn-icon downArrowButton" (click)="toggleNode()">
			<i [ngClass]="{ 'ti-angle-down': isExpanded, 'ti-angle-right': !isExpanded }"></i>
		</button>
		<p class="xmlElementName">
			{{ node.name }}
			<span *ngIf="nodeHasSettings()" class="settingsText">
				<span *ngIf="node.settings.custom_label"> | {{ 'custom label' | translate }} : {{ node.settings.custom_label }}</span>
				<span *ngIf="getContextNameArray().length > 0"> | {{ 'context' | translate }} : {{ getContextNameArray().join(', ') }}</span>
				<span *ngIf="node.settings.value"> | {{ 'value' | translate }}: {{ node.settings.value }}</span>
				<span *ngIf="node.settings.dateFormat"> | {{ 'date format' | translate }} : {{ node.settings.dateFormat }}</span>
				<span *ngFor="let attribute of node.settings.attributes; let first = first">
					<span *ngIf="first"> | {{ 'attribute' | translate }}: </span>
					<span *ngIf="attribute.isDynamicValue">{{ attribute.name }}="{{ attribute.value.name }}"</span>
					<span *ngIf="!attribute.isDynamicValue">{{ attribute.name }}="{{ attribute.value }}"</span>
				</span>
				<span *ngIf="node.settings.condition">
					| {{ 'Condition' | translate }}: {{ getConditionText(node.settings.condition) | translate }}</span
				>
			</span>
		</p>
	</div>
	<app-button variant="contextMenuItem" *ngIf="showNodeSettingsButtons" (click)="openNodeSettingsModal()">
		<i class="ti-pencil-alt"></i>
	</app-button>
</div>
