import { Injectable } from '@angular/core';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { TranslateService } from '@ngx-translate/core';
import { User } from '#/models/user/user.model';
import { AccountNumberStat, FolderStat, ReceiptTypeOption, TagStat, UserStat } from '~/app/pages/authenticated/statistics/models/stats';
import { orderBy } from 'lodash';
import { StatisticsService } from '~/app/pages/authenticated/statistics/statistics.service';
import { ReceiptListAPIRequest } from '#/models/transaction/receipt';
import { CompanyApiService } from '#/services/company/company-api.service';
import { CompanyService } from '#/services/company/company.service';

@Injectable({
	providedIn: 'root',
})
export class FiltersService {
	constructor(
		protected translate: TranslateService,
		private companyService: CompanyService,
		private companyApiService: CompanyApiService,
		protected statisticsService: StatisticsService,
	) {}

	getBookingStatusOptions(): { id: string; name: string }[] {
		return [
			{
				id: '1',
				name: this.translate.instant(_('Booked')),
			},
			{
				id: '0',
				name: this.translate.instant(_('Unbooked')),
			},
		];
	}

	getExportedOptions(): { id: string; name: string }[] {
		return [
			{
				id: '1',
				name: this.translate.instant(_('Exported')),
			},
			{
				id: '0',
				name: this.translate.instant(_('Not exported')),
			},
		];
	}

	getReceiptTypeOptions(filters): Promise<{ id: string; name: string }[]> {
		return this.statisticsService.getReceiptOptionTypes(filters, 'type').then((stats: ReceiptTypeOption[]) => {
			const receiptTypeOptions = stats.map((receiptTypeOption: ReceiptTypeOption) => {
				return {
					id: receiptTypeOption.id,
					name: `${this.translate.instant(_(receiptTypeOption.Type))} (${receiptTypeOption.totalReceipts})`,
				};
			});
			return orderBy(receiptTypeOptions, ['Label']);
		});
	}

	getAccountNumberOptions(filters): Promise<{ id: string; name: string }[]> {
		return this.statisticsService.getAccountNumberStats(filters, 'type').then((stats: AccountNumberStat[]) => {
			const receiptTypeOptions = stats.map((accountNumberStat: AccountNumberStat) => {
				return {
					id: accountNumberStat.getID(),
					name: accountNumberStat.getLabel(),
				};
			});
			return orderBy(receiptTypeOptions, ['Label']);
		});
	}

	getTagOptions(filters): Promise<{ id: string; name: string }[]> {
		return this.statisticsService.getTagStats(filters, 'type').then((stats: TagStat[]) => {
			const receiptTypeOptions = stats.map((tagStat: TagStat) => {
				return {
					id: tagStat.getID(),
					name: tagStat.getLabel(),
				};
			});
			return orderBy(receiptTypeOptions, ['Label']);
		});
	}

	getGroupOptions(filters): Promise<{ id: string; name: string }[]> {
		return this.statisticsService.getGroupStats(filters, 'type').then((stats: FolderStat[]) => {
			const receiptTypeOptions = stats.map((groupStat: FolderStat) => {
				return {
					id: groupStat.getID(),
					name: groupStat.getLabel(),
				};
			});
			return orderBy(receiptTypeOptions, ['Label']);
		});
	}

	getUserOptions(filters): Promise<{ id: string; name: string }[]> {
		const company = this.companyService.getCompanyOfLoggedUser();
		return this.companyApiService
			.getCompanyUsers(company.id)
			.then((r) => r.users)
			.then((users: User[]) => {
				// Stats are currently only supported for receipts.
				if (filters instanceof ReceiptListAPIRequest) {
					return this.statisticsService.getUserStats(filters, 'user').then((stats: UserStat[]) => {
						stats.forEach((s: UserStat) => s.addUserObjectToStats(users));
						const userOptions = stats.map((userStat: UserStat) => {
							if (userStat.UserObject.companydetails.title) {
								return {
									id: userStat.UserObject.id,
									name: `${userStat.UserObject.name} (${userStat.totalReceipts})`,
									description: `${company.getTitleLabel()}: ${userStat.UserObject.companydetails.title}`,
								};
							} else {
								return { id: userStat.UserObject.id, name: `${userStat.UserObject.name} (${userStat.totalReceipts})` };
							}
						});
						return orderBy(userOptions, ['Label']);
					});
				} else {
					const userOptions = users.map((user: User) => {
						if (user.companydetails.title) {
							return {
								id: user.id,
								name: user.name,
								description: `${company.getTitleLabel()}: ${user.companydetails.title}`,
							};
						} else {
							return { id: user.id, name: user.name };
						}
					});
					return orderBy(userOptions, ['Label']);
				}
			});
	}

	public getApproverOptions(
		companyId: string,
		expensesOrInvoices: 'expenses' | 'invoices' | 'both',
	): Promise<{ id: string; name: string }[]> {
		return this.companyApiService.getCompanyApproversWithPendingTasks(companyId, expensesOrInvoices).then((e) => {
			return e.userStats.map((user) => {
				return { id: user.user, name: user.name };
			});
		});
	}
}
