import { ExportType } from '#/models/company/exporterInterfaces';

export class RegistrationExportColumn {
	type: string;
	label: string;
	value: string;
	enabled: boolean;
	add_currency_symbol?: boolean;
	date_format?: string;
	custom_label?: string;

	constructor(data?: any) {
		if (!data) {
			return;
		}

		Object.assign(this, data);
	}
}

export class RegistrationExportItem {
	id: string;
	type: ExportType.REGISTRATION;

	constructor(id: string) {
		this.id = id;
		this.type = ExportType.REGISTRATION;
	}
}

export class RegistrationExportRequest {
	mapColumns: {
		registrationColumns: Array<RegistrationExportColumn>;
	};
	csv_separator: string;
	decimal_separator: string;
	email: string;
	items: Array<RegistrationExportItem>;
	type: string;
	overview: boolean;
	combine_travel_and_receipts: boolean;
	save_template: boolean;
	company_export: boolean;
	split_per_person: boolean;
	ubl_attachments: boolean;
	ubl_combine_attachments: boolean;
	ubl_add_pdf: boolean;
	email_add_attachments: boolean;
	stats: boolean;
	timezone: string;

	constructor(data?: any) {
		this.mapColumns = { registrationColumns: [] };
		this.items = [];
		if (!data) {
			return;
		}
		Object.assign(this, data);
		if (data.registrationColumns) {
			this.mapColumns.registrationColumns = data.registrationColumns.map((column) => new RegistrationExportColumn(column));
		}
		if (data.items) {
			this.items = data.items.map((item) => new RegistrationExportItem(item.id));
		}
	}
}

export class RegistrationGovernmentSummaryExportRequest {
	csv_separator: string;
	decimal_separator: string;
	email: string;
	items: Array<RegistrationExportItem>;
	type: string;
	company_export: boolean;
	timezone: string;

	constructor(data?: any) {
		this.items = [];
		if (!data) {
			return;
		}
		Object.assign(this, data);
		if (data.items) {
			this.items = data.items.map((item) => new RegistrationExportItem(item.id));
		}
	}
}

export class ExportRegistrationResponse {
	data: {
		url: string;
	};
	request_id: string;
	result: string;
}
