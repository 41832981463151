<div class="sharePageMainContent">
	<klp-grid-container [fluid]="true">
		<klp-grid-row>
			<klp-grid-column>
				<klp-grid-row>
					<klp-grid-column class="logoContainer" [width]="{ lg: 4, md: 5, sm: 6 }">
						<img
							src="/assets/images/logo/logo-{{ theme }}@3x.png"
							[alt]="environment.share_page.title"
							[title]="environment.share_page.title"
						/>
					</klp-grid-column>
				</klp-grid-row>
				<klp-grid-row>
					<klp-grid-column class="exporterContainer" [width]="{ sm: 12 }">
						<klp-form-loading-indicator *ngIf="showLoading()" [size]="'large'" [variant]="'3dots'"></klp-form-loading-indicator>
						<p *ngIf="isInQueue()" translate>Your export is in the queue, please wait.</p>
						<p *ngIf="isInProgress()" translate>Your export is in progress, please wait.</p>
						<p *ngIf="errorOccurred()" translate>An error occurred. Please contact support.</p>
					</klp-grid-column>
				</klp-grid-row>
				<klp-grid-row>
					<klp-grid-column class="countdownContainer" [width]="{ md: 8 }">
						<p *ngIf="error" translate [translateParams]="{ errorMsg: error_msg }">
							An error occurred while loading the export: %errorMsg%
						</p>
						<div *ngIf="isFinished()">
							<p translate>Your export is ready for download using the button below.</p>
							<p *ngIf="showCountdown()">
								<span translate>The download will be available for: </span>
								<span *ngIf="oneDayRemaining()">{{ daysRemaining }} {{ 'day' | translate }} </span>
								<span *ngIf="moreThanOneDayRemaining()">{{ daysRemaining }} {{ 'days' | translate }} </span>
								<span>{{ hoursRemaining }}:{{ minutesRemaining }}:{{ secondsRemaining }}</span>
							</p>
						</div>
					</klp-grid-column>
				</klp-grid-row>
				<klp-grid-row *ngIf="isFinished()">
					<klp-grid-column class="downloadAndCopyButtonsContainer" [width]="{ sm: 12, md: 8 }">
						<app-button
							class="mainButtons"
							[fullWidth]="true"
							[variant]="'greenFilled'"
							[size]="'extraLarge'"
							(click)="downloadExport()"
							translate
							>Download export</app-button
						>
						<app-button
							class="mainButtons"
							*ngIf="!showCopiedText"
							[fullWidth]="true"
							[variant]="'greenOutlined'"
							[size]="'extraLarge'"
							(click)="copiedDownloadLink()"
							translate
							>Copy download link</app-button
						>
						<app-button
							class="mainButtons"
							*ngIf="showCopiedText"
							[fullWidth]="true"
							[variant]="'greenOutlined'"
							[size]="'extraLarge'"
							(click)="copiedDownloadLink()"
							translate
							>Copied!</app-button
						>
					</klp-grid-column>
				</klp-grid-row>

				<klp-grid-row *ngIf="hasGetInTouchInfo()">
					<klp-grid-column class="getInTouchContainer" [width]="{ md: 8, lg: 6 }">
						<p>
							<span translate [translateParams]="{ title: environment.share_page.title }"
								>Not yet using %title% to manage and process receipts, invoices and expenses?
							</span>
							<a translate [href]="environment.share_page.contact_us" target="_blank">Contact us</a>
							<span translate> or visit </span>
							<a translate [href]="environment.share_page.website" target="_blank">the website.</a>
						</p>
					</klp-grid-column>
				</klp-grid-row>
				<klp-grid-row>
					<klp-grid-column class="storeIconsContainer" [width]="{ lg: 4, md: 6 }">
						<a *ngIf="hasItunesLink()" [href]="environment.share_page.itunes_link" target="_blank">
							<img class="storeAssets" [src]="'/assets/images/visuals/download-on-the-app-store.png'" />
						</a>
						<a *ngIf="hasGooglePlayLink()" [href]="environment.share_page.google_play_link" target="_blank">
							<img class="storeAssets" [src]="'/assets/images/visuals/google-play-badge.png'" />
						</a>
					</klp-grid-column>
				</klp-grid-row>
			</klp-grid-column>
		</klp-grid-row>
	</klp-grid-container>
</div>

<div class="socialMediaContainer" *ngIf="hasAnySocialMedia()">
	<klp-grid-container>
		<klp-grid-row>
			<klp-grid-column [width]="{ lg: 6, md: 8 }" [offset]="{ lg: 3, md: 2 }">
				<div *ngIf="hasSupportContactInfo()">
					<h2 translate>Questions?</h2>
					<p>
						<span translate>Contact us at </span>
						<a [href]="'mailto:' + environment.support_email">{{ environment.support_email }}</a>
						<span translate> or visit our </span>
						<a [href]="environment.share_page.knowledge_base" target="_blank" translate>knowledge base.</a>
						<span translate [translateParams]="{ supportPhone: environment.share_page.support_phone }">
							Live chat and phone support (%supportPhone%) is available during office hours.</span
						>
					</p>
				</div>

				<ul class="social-icons">
					<li *ngIf="hasFacebookPage()">
						<a [href]="environment.share_page.facebook" target="_blank"><i class="ti-facebook"></i></a>
					</li>
					<li *ngIf="hasTwitterPage()">
						<a [href]="environment.share_page.twitter" target="_blank"><i class="ti-twitter"></i></a>
					</li>
					<li *ngIf="hasLinkedinPage()">
						<a [href]="environment.share_page.linkedin" target="_blank"><i class="ti-linkedin"></i></a>
					</li>
				</ul>
			</klp-grid-column>
		</klp-grid-row>
	</klp-grid-container>
</div>
