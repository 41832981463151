import { Injectable } from '@angular/core';
import {
	InputField,
	InterfaceFrontendModel,
	InterfaceFrontendModel as TransactionInterfaceModel,
	TransactionViewMode,
	UIField,
	UIFieldKey,
} from '#/models/transaction/interface/frontendModel';
import { isNullOrUndefined, isValueSet, stringIsSetAndFilled } from '#/util/values';
import { mapObjectValues } from '#/util/objects';
import { Suggestions, TransactionSuggestionsService } from '#/services/transaction/transaction-suggestions.service';
import { APIService } from '~/app/api/services/api.service';
import { CompanyService } from '#/services/company/company.service';
import { apiToFrontend, frontendToApi } from '#/models/transaction/interface/transformer';
import { InterfaceApiModel } from '#/models/transaction/interface/apiModel';
import { UserService } from '~/app/modules/user/user.service';
import { CompanyIntegrationService } from '#/services/company/company-integration.service';
import { UIDefinitionCapabilities } from '#/models/company/company.model';
import { ExpenseReportsService } from '#/services/transaction/expense-reports.service';
import { ActivatedRouteSnapshot } from '@angular/router';
import { TransactionEditorService } from '#/services/transaction/transaction-editor.service';
import { arrayIsSetAndFilled, removeDuplicatesFromArray } from '#/util/arrays';
import { InvoiceFrontendModel } from '#/models/transaction/invoice/frontendModel';
import { ReceiptFrontendModel } from '#/models/transaction/receipt/frontendModel';
import { TransactionType } from '#/models/transaction/transactionType';
import { User } from '#/models/user/user.model';
import { FixedCompensationFrontendModel } from '#/models/transaction/fixedCompensation/frontendModel';
import { DeclarationStatusFlag } from '#/models/transaction/receipt';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { TranslateService } from '@ngx-translate/core';
import { capitalizeFirstLetter } from '#/util/strings';
import { AAV2_PREFIX } from '#/models/transaction/transaction/transformer';

export const bookingHeaderFields: Array<UIFieldKey> = [
	'integration',
	'division',
	'journal',
	'integrationRelation',
	'integrationPaymentMethod',
	'paymentCondition',
	'bookingDate',
];

@Injectable({
	providedIn: 'root',
})
export class TransactionInterfaceService {
	constructor(
		private transactionSuggestionsService: TransactionSuggestionsService,
		private companyIntegrationService: CompanyIntegrationService,
		private apiService: APIService,
		private userService: UserService,
		private companyService: CompanyService,
		private transactionEditorService: TransactionEditorService,
		private expenseReportsService: ExpenseReportsService,
		private translate: TranslateService,
	) {}

	public saveInterface(values: TransactionInterfaceModel, companyId: string): Promise<TransactionInterfaceModel> {
		this.deleteInterfacesFromCache();
		if (stringIsSetAndFilled(values.id)) {
			return this.apiService.patchToApi(`interfaces/company/${companyId}/${values.id}`, frontendToApi(values)).then((res) => {
				return apiToFrontend(res.data);
			});
		} else {
			return this.apiService.postToApi(`interfaces/company/${companyId}`, frontendToApi(values)).then((res) => {
				return apiToFrontend(res.data);
			});
		}
	}

	public getInterfaces(
		firstLetterCapitalized = false,
		includeInactive = true,
		filterOnUserGroup = true,
		companyId: string = null,
		translateCaption = false,
	): Promise<Array<TransactionInterfaceModel>> {
		const compId = stringIsSetAndFilled(companyId) ? companyId : this.companyService.getCompanyOfLoggedUser().id;
		const user = this.userService.getCurrentLoggedUser();

		return this.apiService.getFromApi(`interfaces/company/${compId}`, 60 * 1000).then((res) =>
			res.data
				.map((e) => {
					const txi = apiToFrontend(new InterfaceApiModel(e));
					txi.menuCaption = translateCaption ? this.translate.instant(txi.menuCaption) : txi.menuCaption;
					if (firstLetterCapitalized) {
						txi.menuCaption = capitalizeFirstLetter(txi.menuCaption);
					}
					return txi;
				})
				.filter((e: InterfaceFrontendModel) => {
					if (filterOnUserGroup) {
						if (!arrayIsSetAndFilled(e.allowedGroups)) {
							return false;
						}
						if (!e.allowedGroups.some((g) => user.getCompanyGroups().includes(g))) {
							return false;
						}
					}
					return includeInactive || e.active;
				})
				.sort((a, b) => a.displayOrder - b.displayOrder),
		);
	}

	public getUserInterfaces(
		withAddPrefix: boolean = false,
		includeInactive: boolean = true,
		translateCaption: boolean = false,
	): Promise<Array<TransactionInterfaceModel>> {
		const companyId: string = this.companyService.getCompanyOfLoggedUser().id;
		const userId: string = this.userService.getCurrentLoggedUser().id;
		const cacheTime: number = 60 * 1000;
		return this.apiService.getFromApi(`interfaces/company/${companyId}/users/${userId}`, cacheTime).then((res) =>
			res.data.map((e) => {
				const txi = apiToFrontend(new InterfaceApiModel(e));
				txi.menuCaption = translateCaption ? this.translate.instant(txi.menuCaption) : txi.menuCaption;
				if (withAddPrefix) {
					txi.menuCaption = capitalizeFirstLetter(this.translate.instant(_('Add %name%'), { name: txi.menuCaption }));
				}
				return txi;
			}),
		);
	}

	public async getDefaultReceiptInterfaceId(): Promise<string> {
		return this.getDefaultInterfaceId(TransactionType.Receipt);
	}

	public async getDefaultTravelExpenseInterfaceId(): Promise<string> {
		return this.getDefaultInterfaceId(TransactionType.FixedCompensation, ['travelRoute']);
	}

	private async getDefaultInterfaceId(transactionType: TransactionType, hasToIncludeFields: Array<UIFieldKey> = []): Promise<string> {
		let allInterfaces = await this.getInterfaces(true, true);
		if (arrayIsSetAndFilled(hasToIncludeFields)) {
			allInterfaces = allInterfaces.filter((_interface) => {
				return hasToIncludeFields.every((field) => _interface.inputFields[field].isVisibleOnSubmit);
			});
		}
		const firstActive = allInterfaces.find((e) => e.active && e.transactionType === transactionType)?.id;
		if (stringIsSetAndFilled(firstActive)) {
			return firstActive;
		}
		const firstInactive = allInterfaces.find((e) => e.transactionType === transactionType)?.id;
		if (stringIsSetAndFilled(firstInactive)) {
			return firstInactive;
		}
		throw new Error(`No possible interface found for type ${transactionType}`);
	}

	public getInterfaceById(id: string, translateCaption = false): Promise<TransactionInterfaceModel> {
		return this.getInterfaces(false, true, false, null, translateCaption).then((res) => res.filter((e) => e.id === id)[0]);
	}
	public getInterfaceByIds(ids: Array<string>): Promise<Array<TransactionInterfaceModel>> {
		// TODO: Remove this when the API is improved and supports it.
		return this.getInterfaces(false, true, false).then((res) => res.filter((e) => ids.includes(e.id)));
	}

	public async getInterfaceOfTransaction(transactionId: string, translateCaption = false): Promise<InterfaceFrontendModel> {
		const tx = await this.transactionEditorService.getTransaction(transactionId, false, true);
		let interfaceId: string = tx.transactionInterfaceId;
		if (!stringIsSetAndFilled(interfaceId)) {
			// fall back to the first interface of the correct type we have which is active
			interfaceId = (await this.getInterfaces(false, false, false, null, true)).find((e) => {
				let typeToMatch;
				if (tx instanceof InvoiceFrontendModel) {
					typeToMatch = TransactionType.Invoice;
				} else if (stringIsSetAndFilled(tx.travelRoute?.route) || tx instanceof FixedCompensationFrontendModel) {
					typeToMatch = TransactionType.FixedCompensation;
				} else if (tx instanceof ReceiptFrontendModel) {
					typeToMatch = TransactionType.Receipt;
				}
				return e.transactionType === typeToMatch;
			})?.id;
		}
		return this.getInterfaceById(interfaceId, translateCaption);
	}

	private deleteInterfacesFromCache() {
		const user = this.userService.getCurrentLoggedUser();
		this.apiService.deleteFromCacheWhereUrlStartsWith(`interfaces/company/${user.company}`);
	}

	public async getEditableFields(
		viewModes: Array<TransactionViewMode>,
		interfaceDefinition: TransactionInterfaceModel,
		dynamicOptionsPromises: { [k in UIFieldKey]?: Promise<Array<any>> },
		txId: string = null,
		reportId: string = null,
	): Promise<Array<UIFieldKey>> {
		const onlyFinanceAllowedActions: Array<UIFieldKey> = await this.transactionEditorService.getOnlyFinanceAllowedActions(txId);
		if (arrayIsSetAndFilled(onlyFinanceAllowedActions)) {
			return onlyFinanceAllowedActions;
		}

		const visibleFields = (
			await this.getOrderedVisibleFields(
				viewModes,
				interfaceDefinition,
				dynamicOptionsPromises,
				await this.transactionEditorService.getStatusOfTransaction(txId),
			)
		).flat();

		const forcedVisible = Object.entries(interfaceDefinition.inputFields)
			.filter(([key, val]) => {
				return viewModes.every((viewMode) => {
					if (!visibleFields.includes(key as any)) {
						return false;
					}
					const uiField: UIField<any> = val;
					if (viewMode === TransactionViewMode.PRE_TRANSACTION) {
						return !uiField?.isVisibleOnPreTransaction;
					}
					if (viewMode === TransactionViewMode.SUBMIT) {
						return !uiField?.isVisibleOnSubmit;
					}
					if (viewMode === TransactionViewMode.APPROVE) {
						return !uiField?.isVisibleOnApprove;
					}
					if (viewMode === TransactionViewMode.FINANCE) {
						return !uiField?.isVisibleOnFinance;
					}
				});
			})
			.map(([key]) => key);

		let editableFields: Array<string> = Object.entries(interfaceDefinition.inputFields)
			// we also make fields editable if they are forced to be visible because of multiple preset options
			.filter(([key, value]) => {
				return viewModes.some((viewMode) => {
					if (forcedVisible.includes(key)) {
						return true;
					}
					if (viewMode === TransactionViewMode.PRE_TRANSACTION && (value as UIField<any>)?.isEditableOnPreTransaction) {
						return true;
					}
					if (viewMode === TransactionViewMode.SUBMIT && (value as UIField<any>)?.isEditableOnSubmit) {
						return true;
					}
					if (viewMode === TransactionViewMode.APPROVE && (value as UIField<any>)?.isEditableOnApprove) {
						return true;
					}
					if (viewMode === TransactionViewMode.FINANCE && (value as UIField<any>)?.isEditableOnFinance) {
						return true;
					}
					return false;
				});
			})
			.map(([key]) => key);
		if (interfaceDefinition.allowAttachments) {
			editableFields.push('file');
		}
		if (interfaceDefinition.markNoAttachmentAvailable) {
			editableFields.push('noAttachmentAvailable');
		}
		if (stringIsSetAndFilled(txId) && !viewModes.includes(TransactionViewMode.PRE_TRANSACTION)) {
			const tx = await this.transactionEditorService.getTransaction(txId);
			const report = stringIsSetAndFilled(tx.report) ? await this.expenseReportsService.getExpenseReport(tx.report) : null;
			const reportPickerEditable = isValueSet(report) ? this.expenseReportsService.canExcludeItemInReport(report) : true;
			if (!reportPickerEditable) {
				editableFields = editableFields.filter((e) => e !== 'report');
			}
		}
		if (this.hasBookingLinesBeforeApprovalModule() && !this.canEditBookingLinesAccordingToBookingLinesBeforeApprovalTable()) {
			editableFields = editableFields.filter((e) => !bookingHeaderFields.includes(e as UIFieldKey));
		}
		if (stringIsSetAndFilled(reportId)) {
			const report = await this.expenseReportsService.getExpenseReport(reportId);
			editableFields = editableFields.filter(
				(e) => (e as UIFieldKey) !== 'costCenter' || !report.dimension_settings?.costcenter?.locked_on_receipt,
			);
			editableFields = editableFields.filter(
				(e) => (e as UIFieldKey) !== 'costUnit' || !report.dimension_settings?.costunit?.locked_on_receipt,
			);
			editableFields = editableFields.filter(
				(e) => (e as UIFieldKey) !== 'project' || !report.dimension_settings?.project?.locked_on_receipt,
			);
		}
		editableFields.push('accountingHeaders');
		editableFields.push('accountingBookingLines');
		return editableFields as Array<UIFieldKey>;
	}

	// get the fields which are visible in the correct order
	public async getOrderedVisibleFields(
		viewModes: Array<TransactionViewMode>,
		interfaceDefinition: TransactionInterfaceModel,
		dynamicOptionsPromises: { [k in UIFieldKey]?: Promise<Array<any>> },
		txStatus: DeclarationStatusFlag,
		integrationId?: string,
		reportId?: string,
	): Promise<Array<Array<UIFieldKey>>> {
		const optionsAmountPromises: Record<UIFieldKey, Promise<number>> = mapObjectValues(dynamicOptionsPromises, (e) =>
			e.then((options) => options?.length),
		);
		const shouldShowPromises: Array<Array<Promise<{ fieldName: UIFieldKey; show: boolean }>>> = viewModes.map((viewMode) => {
			return interfaceDefinition.ordering.flat().map((fieldName) => {
				const currentInputField: UIField<any> = interfaceDefinition.inputFields[fieldName];
				if (!isValueSet(currentInputField)) {
					return Promise.resolve({ fieldName: fieldName, show: false });
				}
				if (viewMode === TransactionViewMode.PRE_TRANSACTION && currentInputField.isVisibleOnPreTransaction) {
					return Promise.resolve({ fieldName: fieldName, show: true });
				}
				if (viewMode === TransactionViewMode.SUBMIT && currentInputField.isVisibleOnSubmit) {
					return Promise.resolve({ fieldName: fieldName, show: true });
				}
				if (viewMode === TransactionViewMode.APPROVE && currentInputField.isVisibleOnApprove) {
					return Promise.resolve({ fieldName: fieldName, show: true });
				}
				if (viewMode === TransactionViewMode.FINANCE && currentInputField.isVisibleOnFinance) {
					return Promise.resolve({ fieldName: fieldName, show: true });
				}
				if (isValueSet(optionsAmountPromises[fieldName])) {
					return optionsAmountPromises[fieldName].then((amountOfOptions) => {
						// still show the field when you have multiple options to choose from
						return { fieldName: fieldName, show: amountOfOptions > 1 };
					});
				}
				return Promise.resolve({ fieldName: fieldName, show: false });
			});
		});

		const shouldShow = removeDuplicatesFromArray(
			(await Promise.all(shouldShowPromises.flat())).filter((e) => e.show).map((e) => e.fieldName),
		);
		const filteredAndOrdered = interfaceDefinition.ordering.map((e) => {
			return e.filter((key) => shouldShow.includes(key));
		});
		filteredAndOrdered.push([]);
		if (viewModes.some((e) => this.hasVisibleStatusBar(interfaceDefinition, e))) {
			filteredAndOrdered[filteredAndOrdered.length - 1].push('status');
		}
		if (viewModes.some((e) => this.hasVisibleBookingTable(interfaceDefinition, e))) {
			filteredAndOrdered[filteredAndOrdered.length - 1].push('bookingTable');
		}
		let integrationCapabilities: Array<keyof UIDefinitionCapabilities> = [];
		if (stringIsSetAndFilled(integrationId) && !integrationId.startsWith(AAV2_PREFIX)) {
			integrationCapabilities = await this.getIntegrationCapabilities(integrationId).catch(() => []);
		} else {
			integrationCapabilities = [];
		}
		let result = this.filterFieldsOnIntegrationCapabilities(filteredAndOrdered, integrationCapabilities);
		result = await this.filterFieldsOnReportSettings(result, reportId);
		if (txStatus === DeclarationStatusFlag.NotSubmitted) {
			result = result.map((row) => row.filter((col) => col !== 'addRemark'));
		}
		return result;
	}

	private hasVisibleStatusBar(interfaceDefinition: TransactionInterfaceModel, viewMode: TransactionViewMode) {
		const status = interfaceDefinition.inputFields.status;
		if (isNullOrUndefined(status)) {
			return false;
		}
		if (viewMode === TransactionViewMode.SUBMIT && status.isVisibleOnSubmit) {
			return true;
		}
		if (viewMode === TransactionViewMode.APPROVE && status.isVisibleOnApprove) {
			return true;
		}
		if (viewMode === TransactionViewMode.FINANCE && status.isVisibleOnFinance) {
			return true;
		}
	}

	private hasVisibleBookingTable(interfaceDefinition: TransactionInterfaceModel, viewMode: TransactionViewMode) {
		const bookingTable = interfaceDefinition.inputFields.bookingTable;
		if (isNullOrUndefined(bookingTable)) {
			return false;
		}
		if (viewMode === TransactionViewMode.SUBMIT && bookingTable.isVisibleOnSubmit) {
			return true;
		}
		if (viewMode === TransactionViewMode.APPROVE && bookingTable.isVisibleOnApprove) {
			return true;
		}
		if (viewMode === TransactionViewMode.FINANCE && bookingTable.isVisibleOnFinance) {
			return true;
		}
	}

	private filterFieldsOnIntegrationCapabilities(
		result: UIFieldKey[][],
		integrationCapabilities: Array<keyof UIDefinitionCapabilities>,
	): Array<Array<UIFieldKey>> {
		return result.map((row) => {
			return row.filter((e) => {
				switch (e) {
					case 'journal':
						return integrationCapabilities.includes('Journals');
					case 'paymentCondition':
						return integrationCapabilities.includes('PaymentConditions');
					case 'bookingDate':
						return integrationCapabilities.includes('BookingDate');
					case 'integrationPaymentMethod':
						return integrationCapabilities.includes('PaymentMethods');
					default:
						return true;
				}
			});
		});
	}

	private async filterFieldsOnReportSettings(fields: UIFieldKey[][], reportId: string): Promise<Array<Array<UIFieldKey>>> {
		let result = fields.map((row) => row.map((e) => e));
		if (this.companyService.getCompanyOfLoggedUser()?.modules?.report?.enabled !== true) {
			// if we dont have the reports module, remove the report field entirely
			return result.map((row) => row.filter((e) => e !== 'report'));
		}
		if (!stringIsSetAndFilled(reportId)) {
			return result;
		}
		const report = await this.expenseReportsService.getExpenseReport(reportId);
		// we dont want to hide all the fields when the report is booked. It will turn into readonly instead
		if (!report.canEditBooking() && !report.booking_data?.bookingstatus?.is_booked) {
			result = result.map((row) =>
				row.filter((e) => {
					switch (e) {
						case 'bookingTable':
						case 'integration':
						case 'division':
						case 'journal':
						case 'integrationRelation':
						case 'paymentCondition':
						case 'integrationPaymentMethod':
						case 'bookingDate':
							return false;
						default:
							return true;
					}
				}),
			);
		}

		return result;
	}

	private getIntegrationCapabilities(integrationId: string) {
		return this.companyIntegrationService.getIntegration(this.companyService.getCompanyOfLoggedUser().id, integrationId).then(
			(res) =>
				Object.entries(res.UIDefinition.Capabilities).reduce((acc, cur) => {
					if (cur[1] === true) {
						return [...acc, cur[0]];
					}
					return acc;
				}, []) as Array<keyof UIDefinitionCapabilities>,
		);
	}

	// gets the values of presets in combination with suggestions
	// if we have a promise with the preset value provided, we use that. Otherwise, we fall back to the preset that is in the input field
	// The promise is relevant since we dont know otherwise which values a user has access to (maybe he has no access to a value that was in a preset)
	// If we dont have a preset, we will check if we have a history suggestion instead
	public async getAutofillValuesForTx(
		interfaceDefinition: TransactionInterfaceModel,
		presetValuePromises: { [k in UIFieldKey]?: Promise<Array<string>> },
		routeSnapshot: ActivatedRouteSnapshot,
		txId: string = null,
		existingValues: { [k in UIFieldKey]?: string } = {},
		getBookingSuggestions: boolean,
	): Promise<Suggestions> {
		const inputFields = interfaceDefinition.inputFields;
		const txSuggestions = await this.transactionSuggestionsService.getTxSuggestions(
			txId,
			interfaceDefinition,
			routeSnapshot,
			existingValues,
			getBookingSuggestions,
		);

		const allPresetPromises: Array<Promise<any>> = (Object.entries(inputFields) as Array<[UIFieldKey, InputField[UIFieldKey]]>).map(
			([fieldName, value]) => {
				if (isValueSet(presetValuePromises[fieldName])) {
					return presetValuePromises[fieldName].then((selectableOptions) => {
						if (selectableOptions?.length === 1) {
							return selectableOptions[0];
						}
						return null;
					});
				} else {
					const presetValue = inputFields[fieldName] && inputFields[fieldName].preset;
					if (Array.isArray(presetValue)) {
						if (presetValue.length === 1) {
							return Promise.resolve(presetValue[0]);
						}
						// currently we do not support multiple value presets
						return Promise.resolve(null);
					}
					return Promise.resolve(presetValue);
				}
			},
		);
		const allPresetValues = await Promise.all(allPresetPromises);
		const result = {};
		const allInputFields = [...new Set([...Object.keys(inputFields), ...Object.keys(txSuggestions)])];
		allInputFields.forEach((e, i) => {
			// fallback to suggestion if we don't have a preset
			if (isValueSet(allPresetValues[i])) {
				result[e] = allPresetValues[i];
				// specifically check for undefined, as that means we have no suggestion.
				// null means: we have a suggestion and that suggestion is that it should be empty
			} else if (txSuggestions[e] !== undefined) {
				result[e] = txSuggestions[e];
			} else {
				// don't prefill anything if we have no preset or suggestion
			}
		});
		return result;
	}

	public hasBookingLinesBeforeApprovalModule(): boolean {
		return this.companyService.getCompanyOfLoggedUser()?.modules?.booking_lines_before_approved?.enabled === true;
	}

	public canEditBookingLines(viewMode: TransactionViewMode, interfaceDefinition: InterfaceFrontendModel): boolean {
		const bookingTable = interfaceDefinition.inputFields.bookingTable;
		if (viewMode === TransactionViewMode.SUBMIT && !bookingTable.isEditableOnSubmit) {
			return false;
		}
		if (viewMode === TransactionViewMode.APPROVE && !bookingTable.isEditableOnApprove) {
			return false;
		}
		if (viewMode === TransactionViewMode.FINANCE && !bookingTable.isEditableOnFinance) {
			return false;
		}

		if (viewMode === TransactionViewMode.SUBMIT || viewMode === TransactionViewMode.APPROVE) {
			if (this.hasBookingLinesBeforeApprovalModule()) {
				return this.canEditBookingLinesAccordingToBookingLinesBeforeApprovalTable();
			}
		}

		return true;
	}

	public canSeeBookingLinesAccordingToBookingLinesBeforeApprovalTable(): boolean {
		const mod = this.companyService.getCompanyOfLoggedUser()?.modules?.booking_lines_before_approved;
		if (this.canEditBookingLinesAccordingToBookingLinesBeforeApprovalTable()) {
			return true;
		}
		const user = this.userService.getCurrentLoggedUser();
		return User.getAllRoles(mod.can_see_roles).some((e: any) => user.hasRole(e));
	}

	public canEditBookingLinesAccordingToBookingLinesBeforeApprovalTable(): boolean {
		const mod = this.companyService.getCompanyOfLoggedUser()?.modules?.booking_lines_before_approved;
		const user = this.userService.getCurrentLoggedUser();
		return User.getAllRoles(mod.can_edit_roles).some((e: any) => user.hasRole(e));
	}

	public async getOverlappingStatusesToChooseFrom(interfaceIds: Array<string>): Promise<Array<DeclarationStatusFlag>> {
		const allInterfaces = (await Promise.all(removeDuplicatesFromArray(interfaceIds).map((e) => this.getInterfaceById(e)))).filter(
			isValueSet,
		);
		const allowedActions = allInterfaces
			.map((e) => e.inputFields.status?.preset)
			.filter(isValueSet)
			.reduce((acc, cur) => {
				if (acc === null) {
					return cur;
				}
				return removeDuplicatesFromArray([...acc, ...cur]);
			}, null);
		return allowedActions;
	}
}
