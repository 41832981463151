import { Injectable } from '@angular/core';
import { UserService } from '~/app/modules/user/user.service';
import { APIService } from '~/app/api/services/api.service';
import { CompanyService } from '#/services/company/company.service';
import { ConversationMessageAuthor } from '#/models/transaction/conversationItem';
import { stringIsSetAndFilled } from '#/util/values';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
	providedIn: 'root',
})
export class ImpersonationService {
	constructor(
		private userService: UserService,
		private companyService: CompanyService,
		private apiService: APIService,
		protected translate: TranslateService,
	) {}

	public hasImpersonationSettings(): boolean {
		const company = this.companyService.getCompanyOfLoggedUser();

		return company?.modules?.impersonation?.enabled;
	}

	public isCompanyAdminAllowedToImpersonate(): boolean {
		const company = this.companyService.getCompanyOfLoggedUser();
		const user = this.userService.getCurrentLoggedUser();

		return user.hasCompanyAdminRole() && company?.modules?.impersonation?.adminsCanImpersonateUsers;
	}

	public async getImpersonationSettings(): Promise<{ [k in string]: number }> {
		const myUser = await this.userService.getCurrentUserParsed();
		return myUser.allowImpersonation;
	}

	public async saveImpersonationSettings(impersonators: { [k in string]: number }): Promise<{ [k in string]: number }> {
		const myUser = await this.userService.getCurrentUserParsed();
		myUser.allowImpersonation = impersonators;
		return this.userService.updateUser(myUser).then((res) => res.allowImpersonation);
	}

	public async getListOfPeopleICanImpersonate(): Promise<Array<string>> {
		return this.apiService.getFromApi(`user/impersonate`).then((res) => res.data.users);
	}

	public async impersonate(userId: string): Promise<void> {
		return this.apiService.postToApi(`company/${this.userService.getCurrentLoggedUser().company}/user/${userId}/impersonate`);
	}

	public async getAuthorOfConversationMessage(author: ConversationMessageAuthor): Promise<string> {
		if (!stringIsSetAndFilled(author.actingUserId)) {
			return author.name;
		}

		const text: string = _('%impersonator% on behalf of %impersonated%');
		const impersonatorName = await this.companyService
			.getCompanyUserById(author.actingUserId)
			.then((u) => u.name)
			.catch(() => this.translate.instant(_('Deleted user')));

		const options = { impersonator: impersonatorName, impersonated: author.name };
		return this.translate.instant(text, options);
	}
}
