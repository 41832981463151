import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TagStat } from '../../../../pages/authenticated/statistics/models/stats';
import { AddTags, ClearTags } from '../../models/tag.actions';
import { Receipt, ReceiptListAPIRequest } from '#/models/transaction/receipt';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { ModalBaseComponent } from '~/app/modules/generic/components/modals/modal-base/modal-base';
import { StatisticsService } from '~/app/pages/authenticated/statistics/statistics.service';

@Component({
	selector: 'app-tags-edit-modal',
	templateUrl: './tags-edit-modal.component.html',
})
export class TagsEditModalComponent extends ModalBaseComponent implements OnInit {
	@Input() tag: TagStat | null;

	editForm: UntypedFormGroup;

	public submitted = false;
	public progress = false;

	constructor(public activeModal: NgbActiveModal, private statsAPIService: StatisticsService) {
		super(activeModal);
	}

	ngOnInit() {
		this.editForm = this.formBuilder.group({
			tag: [this.tag.Tag, [Validators.required]],
		});
	}

	get f() {
		return this.editForm.controls;
	}

	close() {
		this.activeModal.close();
	}

	changeName() {
		this.submitted = true;

		if (this.editForm.invalid) {
			return;
		}
		const newTag = this.editForm.value.tag;
		return this.editTag(this.tag.Tag, newTag);
	}

	editTag(oldTag: string, newTag: string) {
		const query = new ReceiptListAPIRequest();
		query.tag = oldTag;

		const promises = [];
		this.progress = true;

		return this.receiptAPIService.getAllReceipts(query).then((receipts) => {
			receipts.forEach((receipt) => {
				receipt.renameTag(oldTag, newTag);
				promises.push(this.receiptAPIService.updateReceipt(receipt, false));
			});

			return Promise.all(promises.map((p) => p.catch((e) => e)))
				.then((results) => {
					/* Array of results (Receipts or Rejections) */
					const nSuccess = results.filter((r) => r instanceof Receipt).length;
					const nFailure = results.length - nSuccess;

					if (nFailure > 0) {
						let message;
						message =
							this.getCurrentCountSentenceTranslated('tag', 'change-status', 'success', nSuccess, results.length) +
							this.getCurrentCountSentenceTranslated('tag', 'change-status', 'failed', nFailure, undefined);
						this.notificationService.success(message);
					} else {
						let message;
						message = this.getCurrentCountSentenceTranslated('tag', 'change-status', 'success', nSuccess, results.length);
						this.notificationService.success(message);
					}

					this.statsAPIService
						.getTagStats(new ReceiptListAPIRequest())
						.then((tags) => {
							this.store.dispatch(new ClearTags());
							this.store.dispatch(new AddTags({ tags: tags }));
							this.done.emit(true);
							this.close();
						})
						.catch((e) => {
							this.done.emit(true);
							this.close();
							return Promise.reject(e);
						});
				})
				.catch((e) => {
					// Should not be called. All catched errors should be resolved.
				});
		});
	}
}
