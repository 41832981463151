import { Component, ContentChild, EventEmitter, Input, Output, TemplateRef } from '@angular/core';
import { DeclarationStatusFlag } from '#/models/transaction/receipt';

@Component({
	selector: 'app-change-status-modal',
	templateUrl: './change-status-modal.component.html',
	styleUrls: ['./change-status-modal.component.scss'],
})
export class ChangeStatusModalComponent {
	@Input() public currentStatus: DeclarationStatusFlag;
	@Input() type: string | 'group' | 'receipt' | 'tag' = 'receipt';
	@Input() filterOnStatuses: Array<DeclarationStatusFlag> = [
		DeclarationStatusFlag.Approved,
		DeclarationStatusFlag.NeedsInformation,
		DeclarationStatusFlag.Denied,
		DeclarationStatusFlag.Claimed,
		DeclarationStatusFlag.NotSubmitted,
	];
	@Output() public onDismiss: EventEmitter<void> = new EventEmitter<void>();
	@Output() public onSave: EventEmitter<{ status: string; remark: string }> = new EventEmitter<{ status: string; remark: string }>();

	@ContentChild(TemplateRef) template;
	public remark: string;

	saveStatus() {
		this.onSave.emit({ status: this.currentStatus, remark: this.remark });
	}

	closeModal() {
		this.onDismiss.emit();
	}
}
