import { Action } from '@ngrx/store';

import { AuthorizationFlow, Company, AccountingIntegrationV1 } from '#/models/company/company.model';
import { User } from '#/models/user/user.model';
import { CategoryStat, CostCenterStat, CostUnitStat, ProjectStat } from '#/models/statistic';
import { CompanyPaymentMethod, PaymentMethodTransaction } from '#/models/company/payment-method.model';

export enum CompanyActionTypes {
	SET_COMPANY = '[Company] Set Company',
	SET_SUB_COMPANY = '[Company] Set Sub Company',
	UPDATE_COMPANY = '[Company] Update Company',
	SET_INVOICE_INBOUND_MAIL_KEY = '[Company] Set Invoice Inbound Mail Key',
	SET_COMPANY_USERS = '[Company] Set Company Users',
	SET_INTEGRATIONS = '[Company] Set Integrations',
	SET_COST_CENTER_STATS = '[Company] Set Cost Center stats',
	SET_COMPANY_PAYMENT_METHODS = '[Company] Set Company Payment Methods',
	UPSERT_COMPANY_PAYMENT_METHOD = '[Company] Upsert Company Payment Method',
	REMOVE_COMPANY_PAYMENT_METHOD = '[Company] Remove Company Payment Method',
	SET_COMPANY_AUTHORIZATION_FLOWS = '[Company] Set Company Authorization Flow',
	UPSERT_COMPANY_AUTHORIZATION_FLOW = '[Company] Upsert Company Authorization Flow',
	REMOVE_COMPANY_AUTHORIZATION_FLOW = '[Company] Remove Company Authorization Flow',
	SET_TRANSACTIONS = '[Company] Set Transactions',
	UPSERT_TRANSACTION = '[Company] Upsert Transaction',
	REMOVE_TRANSACTION = '[Company] Remove Transaction',
	SET_COST_UNIT_STATS = '[Company] Set Cost Unit stats',
	SET_PROJECT_STATS = '[Company] Set Project stats',
	SET_CATEGORY_STATS = '[Company] Set Category stats',
	RESET_COMPANY = '[Company] Reset company',
	UPDATE_COMPANY_USER = '[Company] Update Company user',
	DELETE_COMPANY_USER = '[Company] Delete Company user',
	ADD_COMPANY_USER = '[Company] Add Company user',
	ADD_SUB_COMPANY = '[Company] Add Sub Company',
}

export class SetCompany implements Action {
	readonly type = CompanyActionTypes.SET_COMPANY;
	constructor(public payload: { company: Company }) {}
}

export class SetSubCompany implements Action {
	readonly type = CompanyActionTypes.SET_SUB_COMPANY;
	constructor(public payload: { company: Company }) {}
}

export class UpdateCompany implements Action {
	readonly type = CompanyActionTypes.UPDATE_COMPANY;
	constructor(public payload: { company: Company }) {}
}

export class SetInvoiceInboundMailKey implements Action {
	readonly type = CompanyActionTypes.SET_INVOICE_INBOUND_MAIL_KEY;
	constructor(public payload: { key: string }) {}
}

export class SetCompanyUsers implements Action {
	readonly type = CompanyActionTypes.SET_COMPANY_USERS;
	constructor(public payload: { users: User[] }) {}
}

export class SetIntegrations implements Action {
	readonly type = CompanyActionTypes.SET_INTEGRATIONS;
	constructor(public payload: { integrations: Array<AccountingIntegrationV1> }) {}
}

export class SetCompanyPaymentMethods implements Action {
	readonly type = CompanyActionTypes.SET_COMPANY_PAYMENT_METHODS;
	constructor(public payload: { companyPaymentMethods: CompanyPaymentMethod[] }) {}
}

export class UpsertCompanyPaymentMethod implements Action {
	readonly type = CompanyActionTypes.UPSERT_COMPANY_PAYMENT_METHOD;
	constructor(public payload: { companyPaymentMethod: CompanyPaymentMethod }) {}
}

export class RemoveCompanyPaymentMethod implements Action {
	readonly type = CompanyActionTypes.REMOVE_COMPANY_PAYMENT_METHOD;
	constructor(public payload: { companyPaymentMethodID: string }) {}
}

export class SetCompanyAuthorizationFlows implements Action {
	readonly type = CompanyActionTypes.SET_COMPANY_AUTHORIZATION_FLOWS;
	constructor(public payload: { companyAuthorizationFlows: AuthorizationFlow[] }) {}
}

export class UpsertCompanyAuthorizationFlow implements Action {
	readonly type = CompanyActionTypes.UPSERT_COMPANY_AUTHORIZATION_FLOW;
	constructor(public payload: { companyAuthorizationFlow: AuthorizationFlow }) {}
}

export class RemoveCompanyAuthorizationFlow implements Action {
	readonly type = CompanyActionTypes.REMOVE_COMPANY_AUTHORIZATION_FLOW;
	constructor(public payload: { companyAuthorizationFlowID: string }) {}
}

export class SetTransactions implements Action {
	readonly type = CompanyActionTypes.SET_TRANSACTIONS;
	constructor(public payload: { transactions: PaymentMethodTransaction[] }) {}
}

export class UpsertTransaction implements Action {
	readonly type = CompanyActionTypes.UPSERT_TRANSACTION;
	constructor(public payload: { transaction: PaymentMethodTransaction }) {}
}

export class RemoveTransaction implements Action {
	readonly type = CompanyActionTypes.REMOVE_TRANSACTION;
	constructor(public payload: { transactionID: string }) {}
}

export class SetCostCenterStats implements Action {
	readonly type = CompanyActionTypes.SET_COST_CENTER_STATS;
	constructor(public payload: { costCenterStats: CostCenterStat[] }) {}
}

export class SetCostUnitStats implements Action {
	readonly type = CompanyActionTypes.SET_COST_UNIT_STATS;
	constructor(public payload: { costUnitStats: CostUnitStat[] }) {}
}

export class SetProjectStats implements Action {
	readonly type = CompanyActionTypes.SET_PROJECT_STATS;
	constructor(public payload: { projectStats: ProjectStat[] }) {}
}

export class SetCategoryStats implements Action {
	readonly type = CompanyActionTypes.SET_CATEGORY_STATS;
	constructor(public payload: { categoryStats: CategoryStat[] }) {}
}

export class UpdateCompanyUser implements Action {
	readonly type = CompanyActionTypes.UPDATE_COMPANY_USER;
	constructor(public payload: User) {}
}

export class DeleteCompanyUser implements Action {
	readonly type = CompanyActionTypes.DELETE_COMPANY_USER;
	constructor(public payload: User) {}
}

export class ResetCompany implements Action {
	readonly type = CompanyActionTypes.RESET_COMPANY;
}

export class AddCompanyUser implements Action {
	readonly type = CompanyActionTypes.ADD_COMPANY_USER;
	constructor(public payload: User) {}
}

export class AddSubCompany implements Action {
	readonly type = CompanyActionTypes.ADD_SUB_COMPANY;
	constructor(public payload: { company: Company }) {}
}

export type CompanyActionsUnion =
	| SetCompany
	| SetSubCompany
	| UpdateCompany
	| SetInvoiceInboundMailKey
	| SetCompanyUsers
	| SetIntegrations
	| SetCostCenterStats
	| SetCostUnitStats
	| SetProjectStats
	| ResetCompany
	| SetCategoryStats
	| UpdateCompanyUser
	| AddCompanyUser
	| DeleteCompanyUser
	| SetCompanyPaymentMethods
	| UpsertCompanyPaymentMethod
	| RemoveCompanyPaymentMethod
	| SetCompanyAuthorizationFlows
	| UpsertCompanyAuthorizationFlow
	| RemoveCompanyAuthorizationFlow
	| SetTransactions
	| UpsertTransaction
	| RemoveTransaction;
