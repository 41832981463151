import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Subject } from 'rxjs';
import { FileUtils } from '~/app/helpers/file-utils';

@Component({
	selector: 'app-attachment-actions-bar',
	templateUrl: './attachment-actions-bar.component.html',
	styleUrls: ['./attachment-actions-bar.component.scss'],
})
export class AttachmentActionsBarComponent {
	@Output() onUploadFileSelected = new EventEmitter<File>();
	@Output() onDeleteFile = new EventEmitter<number>();
	@Output() onGoToPrefixedEntry = new EventEmitter<void>();
	@Input() isUploading = false;
	@Input() showUploadButton: boolean = true;
	@Input() showDeleteButton: boolean = true;
	@Input() hasPrefixedEntry: boolean = false;

	public prevAttachmentSubject = new Subject<void>();
	public nextAttachmentSubject = new Subject<void>();
	public currentActiveFileIndex: number;
	public currentActiveFileName: string;
	public attachmentsAmount: number;
	public selectedFile: File;
	public currentActiveFileBlob: Blob;

	public hasPrev(): boolean {
		return this.currentActiveFileIndex > 0 || this.hasPrefixedEntry;
	}
	public hasNext(): boolean {
		return this.currentActiveFileIndex < this.attachmentsAmount - 1;
	}

	public goToPrevAttachment(): void {
		if (this.hasPrev()) {
			if (this.hasPrefixedEntry && this.currentActiveFileIndex === 0) {
				this.onGoToPrefixedEntry.emit();
			} else {
				this.prevAttachmentSubject.next();
			}
		}
	}
	public goToNextAttachment(): void {
		if (this.hasNext()) {
			this.nextAttachmentSubject.next();
		}
	}

	public setCurrentActiveFile(index: number, fileName: string): void {
		this.currentActiveFileIndex = index;
		this.currentActiveFileName = fileName;
		this.currentActiveFileBlob = null;
	}
	public setCurrentActiveFileBlob(blob: Blob) {
		this.currentActiveFileBlob = blob;
	}
	public setAmountOfAttachments(amount: number): void {
		this.attachmentsAmount = amount;
	}

	public getCurrentActiveFileBlob(): File {
		return this.currentActiveFileBlob as File;
	}

	public downloadFile(): void {
		FileUtils.downloadFile(window, this.currentActiveFileBlob, this.currentActiveFileName);
	}
}
