import { Injectable } from '@angular/core';
import { APIService } from '~/app/api/services/api.service';
import { InvoiceSuggestionApiModel } from '#/models/transaction/invoice-suggestion/apiModel';
import { apiToFrontend } from '#/models/transaction/invoice-suggestion/transformer';
import { CompanyIntegrationService } from '#/services/company/company-integration.service';
import { CompanyService } from '#/services/company/company.service';
import { isValueSet, stringIsSetAndFilled, useIfStringIsSet } from '#/util/values';
import {
	InterfaceFrontendModel,
	InterfaceFrontendModel as TransactionInterfaceModel,
	UIFieldKey,
} from '#/models/transaction/interface/frontendModel';
import { CompanyCostCenterService } from '#/services/company/dimension/company-cost-center.service';
import { CompanyCostUnitService } from '../company/dimension/company-cost-unit.service';
import { CompanyProjectService } from '../company/dimension/company-project.service';
import { BookingLineField } from '#/models/transaction/booking-line/booking-line';
import { CompanyCategoryService } from '#/services/company/company-category.service';
import { CompanyAdministrationService } from '#/services/company/company-administration.service';
import { ExpenseReportsService } from '#/services/transaction/expense-reports.service';
import { ActivatedRouteSnapshot } from '@angular/router';
import { TransactionEditorService } from '#/services/transaction/transaction-editor.service';
import { arrayIsSetAndFilled } from '#/util/arrays';
import { TransactionType } from '#/models/transaction/transactionType';
import { filterObjectOnCondition, objectToQueryParamString } from '#/util/objects';
import { CompensationRulesService } from '#/services/transaction/compensation-rules.service';
import { DeclarationStatusFlag } from '#/models/transaction/receipt';
import { PercentageAndAmount } from '#/models/currency';
import { FinanceType } from '#/models/transaction/financeType';
import { BookingSuggestion } from '#/models/company/bookingSuggestions.model';
import { BookingSuggestionsService } from '#/services/transaction/booking-suggestions.service';
import { AAV2_PREFIX } from '#/models/transaction/transaction/transformer';
import { bookingHeaderFields } from '#/services/transaction/transaction-interface.service';
import { AmountWithCurrency } from '#/models/transaction/amountWithCurrency';
import { CurrencyService } from '#/services/currency.service';
import { UserService } from '~/app/modules/user/user.service';
import { AccountingBookingType } from '#/models/transaction/bookingType';
import { InterfaceObjectType } from '#/models/transaction/InterfaceObjectType';

type BookingLineFields = BookingLineField | 'costCenter' | 'costUnit' | 'project';
export type Suggestions = { [k in UIFieldKey]?: any };

function useIfDefined(key: keyof Suggestions, val: any, result: Suggestions) {
	if (val !== undefined) {
		result[key] = val;
	}
}
function useIfDefinedBookLine(key: BookingLineFields, val: any, result: { [k in BookingLineFields]?: string }) {
	if (val !== undefined) {
		result[key] = val;
	}
}

@Injectable({
	providedIn: 'root',
})
export class TransactionSuggestionsService {
	constructor(
		private apiService: APIService,
		private userService: UserService,
		private companyService: CompanyService,
		private administrationService: CompanyAdministrationService,
		private companyIntegrationService: CompanyIntegrationService,
		private categoryService: CompanyCategoryService,
		private costCenterService: CompanyCostCenterService,
		private costUnitService: CompanyCostUnitService,
		private projectService: CompanyProjectService,
		private expenseReportsService: ExpenseReportsService,
		private transactionEditorService: TransactionEditorService,
		private compensationRulesService: CompensationRulesService,
		private bookingSuggestionsService: BookingSuggestionsService,
		private currencyService: CurrencyService,
	) {}

	private async getHistorySuggestions(
		txId: string,
		interfaceDefinition: TransactionInterfaceModel,
		existingValues: { [k in UIFieldKey]?: any },
	): Promise<{ [k in UIFieldKey]?: string }> {
		const r: { [k in UIFieldKey]?: string } = {};
		const params = objectToQueryParamString({
			type: this.getSuggestionType(interfaceDefinition),
			finance_type: 'purchase',
			is_invoice: interfaceDefinition.transactionType === TransactionType.Invoice ? '1' : '0',
			administration: isValueSet(existingValues.administration) ? existingValues.administration : undefined,
			merchant: isValueSet(existingValues.merchant) ? existingValues.merchant : undefined,
			txi: interfaceDefinition?.id,
		});

		let url = `/api/v1/receipt/suggestions?${params}`;

		if (stringIsSetAndFilled(txId) && this.transactionEditorService.getInterfaceObjectType(txId) === InterfaceObjectType.TRANSACTION) {
			url = `/api/v1/receipt/${txId}/suggestions?${params}`;
		}

		try {
			const s = await this.apiService.get(url, 60 * 1000).then((res) => apiToFrontend(new InvoiceSuggestionApiModel(res['data'])));
			useIfDefined('paymentMethod', s.paymentMethod, r);
			useIfDefined('customPaymentMethod', s.customPaymentMethod, r);
			useIfDefined('project', s.project, r);
			useIfDefined('costUnit', s.costUnit, r);
			useIfDefined('costCenter', s.costCenter, r);
			useIfDefined('category', s.category, r);
			useIfDefined('administration', s.administration, r);
			useIfDefined('transportationType', s.transportationType, r);
			return r;
		} catch (e) {
			return {};
		}
	}

	private getSuggestionType(interfaceDefinition: TransactionInterfaceModel): 'receipt' | 'travel' {
		switch (interfaceDefinition.transactionType) {
			case TransactionType.Invoice:
			// intentional fallthrough. There is no such thing as an invoice suggestion type
			case TransactionType.Receipt:
				return 'receipt';
			case TransactionType.FixedCompensation:
				return 'travel';
		}
		return 'receipt';
	}

	private async getReportSuggestions(
		existingValues: { [k in UIFieldKey]?: any },
		interfaceDefinition: InterfaceFrontendModel,
		reportId: string,
	): Promise<{ [k in UIFieldKey]?: string }> {
		const r: { [k in UIFieldKey]?: string } = {};
		const report = await this.expenseReportsService.getExpenseReport(reportId);
		useIfDefined('administration', report.administration, r);
		useIfDefined('project', report.project, r);
		useIfDefined('costUnit', report.cost_unit, r);
		useIfDefined('costCenter', report.cost_center, r);
		useIfDefined('integration', useIfStringIsSet(report.booking_data?.provider), r);
		useIfDefined('division', useIfStringIsSet(report.booking_data?.division), r);
		useIfDefined('journal', useIfStringIsSet(report.booking_data?.journal), r);
		useIfDefined('integrationRelation', useIfStringIsSet(report.booking_data?.relation), r);
		this.handleCurrencyExchangeToggle(existingValues, interfaceDefinition, report.currency, r);
		return r;
	}

	private getAccountingBookingType(
		interfaceDefinition: TransactionInterfaceModel,
		existingValues: { [k in UIFieldKey]?: any },
	): AccountingBookingType {
		if (stringIsSetAndFilled(existingValues.report)) {
			return AccountingBookingType.EXPENSE_REPORT;
		}
		if (interfaceDefinition.transactionType === TransactionType.Invoice) {
			return AccountingBookingType.INVOICE;
		}
		return AccountingBookingType.EXPENSE;
	}

	public async getTxSuggestions(
		txId: string,
		interfaceDefinition: TransactionInterfaceModel,
		routeSnapshot: ActivatedRouteSnapshot,
		existingValues: { [k in UIFieldKey]?: any },
		includeBookingSuggestions: boolean,
	): Promise<Suggestions> {
		let r: Suggestions = {};
		const company = this.companyService.getCompanyOfLoggedUser();
		const companyId = company?.id;

		if (await this.shouldGetHistorySuggestions(txId, interfaceDefinition)) {
			const historySuggestions = await this.getHistorySuggestions(txId, interfaceDefinition, existingValues);
			r = { ...r, ...historySuggestions };
		}

		if (includeBookingSuggestions) {
			if (stringIsSetAndFilled(existingValues.administration)) {
				const administration = await this.administrationService.getCompanyAdministration(companyId, existingValues.administration);
				useIfDefined('integration', useIfStringIsSet(administration.provider), r);
			}
			const suggestedIntegration = await this.companyIntegrationService.getSuggestedIntegration(
				existingValues.administration,
				existingValues.category,
				this.getAccountingBookingType(interfaceDefinition, existingValues),
			);
			if (stringIsSetAndFilled(suggestedIntegration?.id)) {
				if (!suggestedIntegration.isAAv2) {
					useIfDefined('integration', suggestedIntegration.id, r);
				} else {
					useIfDefined('integration', AAV2_PREFIX + suggestedIntegration.id, r);
				}
			}
		}

		if (
			!stringIsSetAndFilled(existingValues.amount?.currency) &&
			!stringIsSetAndFilled(existingValues.amountWithoutTip?.currency) &&
			stringIsSetAndFilled(this.userService.getCurrentLoggedUser().preferences?.currency)
		) {
			const defaultAmountWithCurrency: AmountWithCurrency = {
				amount: existingValues.amount?.amount,
				currency: this.userService.getCurrentLoggedUser().preferences.currency,
			};
			r.amount = defaultAmountWithCurrency;
			r.amountWithoutTip = defaultAmountWithCurrency;
		}

		if (stringIsSetAndFilled(existingValues.administration)) {
			const administration = await this.administrationService.getCompanyAdministration(companyId, existingValues.administration);
			this.handleCurrencyExchangeToggle(existingValues, interfaceDefinition, administration.currency, r);
		}

		if (existingValues.useExchangeCurrency === true) {
			let toCurrency;
			if (stringIsSetAndFilled(existingValues.administration)) {
				const administration = await this.administrationService.getCompanyAdministration(companyId, existingValues.administration);
				toCurrency = administration.currency;
			}
			if (stringIsSetAndFilled(existingValues.report)) {
				const report = await this.expenseReportsService.getExpenseReport(existingValues.report);
				toCurrency = report.currency;
			}

			if (stringIsSetAndFilled(toCurrency)) {
				const fromAmountWithCurrency = existingValues.exchangeCurrency?.fromAmountWithCurrency ?? existingValues.amount;
				if (stringIsSetAndFilled(fromAmountWithCurrency?.currency) && Number.isFinite(fromAmountWithCurrency?.amount)) {
					const exchangeDate = isValueSet(existingValues.purchaseDate) ? new Date(existingValues.purchaseDate) : new Date();
					const exchangeRate = (
						await this.currencyService.getCurrencyExchangeRate(fromAmountWithCurrency.currency, toCurrency, exchangeDate)
					).exchange_rate;
					r.exchangeCurrency = {
						fromAmountWithCurrency,
						exchangeRate,
						toAmountWithCurrency: { amount: fromAmountWithCurrency.amount * exchangeRate, currency: toCurrency },
					};
				}
			}
		}

		if (includeBookingSuggestions && stringIsSetAndFilled(existingValues.integration)) {
			const integrationDefaults = company?.getAuthorizationByKey(existingValues.integration)?.defaults;
			let division: string = integrationDefaults?.Division;
			if (stringIsSetAndFilled(existingValues.administration)) {
				const administration = await this.administrationService.getCompanyAdministration(companyId, existingValues.administration);
				if (administration.provider === existingValues.integration) {
					division = administration.remote_id;
				}
			}
			useIfDefined('division', useIfStringIsSet(division), r);
			useIfDefined('journal', useIfStringIsSet(integrationDefaults?.Journal), r);
		}

		if (includeBookingSuggestions && stringIsSetAndFilled(companyId) && stringIsSetAndFilled(existingValues.category)) {
			const category = await this.categoryService.getCompanyCategory(companyId, existingValues.category);
			// only suggest these when integration was empty or the integration filled in matches the one in category.
			// Otherwise you have the risk of suggesting an integration that will not be filled in, but a division
			// from a different integration will be filled in, because the division was empty
			if (!stringIsSetAndFilled(existingValues.integration) || existingValues.integration === category.defaults.Provider) {
				useIfDefined('integration', useIfStringIsSet(category.defaults.Provider), r);
				useIfDefined('division', useIfStringIsSet(category.defaults.Division), r);
				useIfDefined('journal', useIfStringIsSet(category.defaults.Journal), r);
			}
		}

		if (
			includeBookingSuggestions &&
			stringIsSetAndFilled(txId) &&
			stringIsSetAndFilled(existingValues.integration) &&
			stringIsSetAndFilled(existingValues.division)
		) {
			if (
				!stringIsSetAndFilled(existingValues.report) &&
				isValueSet(existingValues.status) &&
				existingValues.status !== DeclarationStatusFlag.NotSubmitted
			) {
				try {
					if (
						stringIsSetAndFilled(existingValues.integration) &&
						!existingValues.integration.startsWith(AAV2_PREFIX) &&
						stringIsSetAndFilled(existingValues.division) &&
						stringIsSetAndFilled(existingValues.merchant)
					) {
						const vatPercentages: string = existingValues.vatLines?.map((vatLine) => vatLine.percentage?.toString()).join(',');
						const bookingSuggestion: BookingSuggestion = await this.bookingSuggestionsService.getBookingSuggestions(
							txId,
							existingValues.integration,
							existingValues.division,
							existingValues.merchant,
							stringIsSetAndFilled(existingValues.financeType) ? existingValues.financeType : FinanceType.PURCHASE,
							vatPercentages,
						);
						useIfDefined('journal', useIfStringIsSet(bookingSuggestion.journal[0]?.value), r);
						useIfDefined('integrationRelation', useIfStringIsSet(bookingSuggestion.relation[0]?.value), r);
					}
				} catch (e) {}
			}
		}

		if (
			includeBookingSuggestions &&
			stringIsSetAndFilled(existingValues.integration) &&
			stringIsSetAndFilled(existingValues.division) &&
			stringIsSetAndFilled(existingValues.integrationRelation)
		) {
			const relation = await this.companyIntegrationService
				.getIntegrationRelation(
					this.companyService.getCompanyOfLoggedUser(),
					existingValues.integration,
					existingValues.division,
					existingValues.integrationRelation,
				)
				.catch((err) => {
					console.error(`When getting tx suggestions, the relation was not found:`);
					console.error(err);
					return null;
				});
			if (stringIsSetAndFilled(relation?.PaymentCondition)) {
				useIfDefined('paymentCondition', useIfStringIsSet(relation.PaymentCondition), r);
			} else if (relation?.PaymentCondition === '') {
				r.paymentCondition = null;
			}
			useIfDefined('integrationPaymentMethod', useIfStringIsSet(relation?.payment_method), r);
		}

		const reportIdFromUrl = routeSnapshot.queryParamMap.get('report-id');
		if (stringIsSetAndFilled(reportIdFromUrl)) {
			useIfDefined('report', reportIdFromUrl, r);
			// we are resetting our suggestions here, in case those were filled in with a lower priority
			delete r.administration;
			delete r.project;
			delete r.costUnit;
			delete r.costCenter;
			delete r.useExchangeCurrency;
			delete r.integration;
			delete r.division;
			delete r.journal;
			delete r.integrationRelation;
		}

		if (stringIsSetAndFilled(existingValues.report)) {
			const reportSuggestions = await this.getReportSuggestions(existingValues, interfaceDefinition, existingValues.report);
			r = { ...r, ...reportSuggestions };
		}

		if (interfaceDefinition.inputFields.tipAmount?.isVisibleOnSubmit) {
			const total = (existingValues.amountWithoutTip?.amount ?? 0) + (existingValues.tipAmount?.amount ?? 0);
			useIfDefined(
				'tipAmount',
				{ currency: existingValues.amountWithoutTip?.currency, amount: existingValues.tipAmount?.amount } as AmountWithCurrency,
				r,
			);
			useIfDefined('amount', { currency: existingValues.amountWithoutTip?.currency, amount: total } as AmountWithCurrency, r);
		}

		if (interfaceDefinition.transactionType === TransactionType.FixedCompensation) {
			const toCurrency =
				r.exchangeCurrency?.fromAmountWithCurrency?.currency ??
				r.amount?.currency ??
				existingValues.exchangeCurrency?.fromAmountWithCurrency?.currency ??
				existingValues.amount?.currency;
			if (isValueSet(existingValues.travelRoute)) {
				if (existingValues.travelRoute.distance > 0) {
					const newAmount = await this.compensationRulesService.calculateCompensationForDistance(existingValues, toCurrency);
					useIfDefined('amount', newAmount.amountWithCurrency, r);
					useIfDefined('datesPerAppliedCompensationRule', newAmount.datesPerAppliedCompensationRule, r);
				} else {
					useIfDefined(
						'amount',
						{ amount: null, currency: stringIsSetAndFilled(toCurrency) ? toCurrency : existingValues.amount?.currency },
						r,
					);
				}
			}
			if (
				(arrayIsSetAndFilled(existingValues.dates) && !isValueSet(existingValues.travelRoute)) ||
				(isValueSet(existingValues.purchaseDate) && !isValueSet(existingValues.travelRoute))
			) {
				const newAmount = await this.compensationRulesService.calculateCompensationForTime(existingValues, toCurrency);
				useIfDefined('amount', newAmount.amountWithCurrency, r);
				useIfDefined('datesPerAppliedCompensationRule', newAmount.datesPerAppliedCompensationRule, r);
			}
			if (isValueSet(existingValues.amountOfMinutes)) {
				const newAmount = await this.compensationRulesService.calculateCompensationForTime(existingValues, toCurrency);
				useIfDefined('amount', newAmount.amountWithCurrency, r);
				useIfDefined('datesPerAppliedCompensationRule', newAmount.datesPerAppliedCompensationRule, r);
			}
		}
		useIfDefined('distanceKm', existingValues.travelRoute?.distance, r);
		if (!includeBookingSuggestions) {
			r = filterObjectOnCondition(r, ([key, val]) => !bookingHeaderFields.includes(key));
		}
		return r;
	}

	private handleCurrencyExchangeToggle(
		existingValues: { [k in UIFieldKey]?: any },
		interfaceDefinition: InterfaceFrontendModel,
		toCurrency: string,
		r: Suggestions,
	) {
		// Remove the current suggestions we collected, there are now outdated
		delete r.useExchangeCurrency;
		delete r.amount;
		delete r.amountWithoutTip;

		if (existingValues.useExchangeCurrency === true) {
			return;
		}
		if (isValueSet(existingValues.report) || interfaceDefinition.transactionType === TransactionType.Receipt) {
			if (isValueSet(toCurrency) && toCurrency !== existingValues.amount?.currency) {
				if (
					stringIsSetAndFilled(existingValues.amount?.currency) &&
					Number.isFinite(existingValues.amount?.amount) &&
					existingValues.amount.amount !== 0
				) {
					r.useExchangeCurrency = true;
				} else {
					const amountToUse = { amount: existingValues.amount?.amount, currency: toCurrency ?? existingValues.amount?.currency };
					r.amount = amountToUse;
					r.amountWithoutTip = amountToUse;
				}
			}
		}
	}

	public async getBookingLinePrefills(
		txId: string,
		existingValues: { [k in UIFieldKey]?: string },
		vat: PercentageAndAmount,
	): Promise<{ [k in BookingLineFields]?: string }> {
		const r: { [k in BookingLineFields]?: string } = {};
		const company = this.companyService.getCompanyOfLoggedUser();
		const integrationDefaults = company?.getAuthorizationByKey(existingValues.integration)?.defaults;
		useIfDefinedBookLine('ledger', integrationDefaults?.GLAccount, r);
		useIfDefinedBookLine('vatCode', integrationDefaults?.VATCode, r);

		if (
			!stringIsSetAndFilled(existingValues.report) &&
			isValueSet(existingValues.status) &&
			existingValues.status !== DeclarationStatusFlag.NotSubmitted
		) {
			if (
				stringIsSetAndFilled(existingValues.integration) &&
				stringIsSetAndFilled(existingValues.division) &&
				stringIsSetAndFilled(existingValues.merchant)
			) {
				const vatPercentage: string = vat?.percentage?.toString();
				const bookingSuggestion: BookingSuggestion = await this.bookingSuggestionsService.getBookingSuggestions(
					txId,
					existingValues.integration,
					existingValues.division,
					existingValues.merchant,
					stringIsSetAndFilled(existingValues.financeType) ? existingValues.financeType : FinanceType.PURCHASE,
					vatPercentage,
				);
				useIfDefinedBookLine('vatCode', bookingSuggestion.lines[0]?.vatCode[0]?.value, r);
				useIfDefinedBookLine('ledger', bookingSuggestion.lines[0]?.generalLedger[0]?.value, r);
			}
		}

		if (stringIsSetAndFilled(company?.id) && stringIsSetAndFilled(existingValues.category)) {
			const category = await this.categoryService.getCompanyCategory(company.id, existingValues.category);
			useIfDefinedBookLine('ledger', category.defaults.GLAccount, r);
			useIfDefinedBookLine('vatCode', category.defaults.VATCode, r);
		}

		const costCenter = stringIsSetAndFilled(existingValues.costCenter)
			? await this.costCenterService.getCompanyCostCenter(this.companyService.getCompanyId(), existingValues.costCenter)
			: null;
		const costUnit = stringIsSetAndFilled(existingValues.costUnit)
			? await this.costUnitService.getCompanyCostUnit(this.companyService.getCompanyId(), existingValues.costUnit)
			: null;
		const project = stringIsSetAndFilled(existingValues.project)
			? await this.projectService.getCompanyProject(this.companyService.getCompanyId(), existingValues.project)
			: null;
		useIfDefinedBookLine('costCenter', costCenter?.integration_identifier, r);
		useIfDefinedBookLine('costUnit', costUnit?.integration_identifier, r);
		useIfDefinedBookLine('project', project?.integration_identifier, r);
		return r;
	}

	private async shouldGetHistorySuggestions(txId: string, interfaceDefinition: TransactionInterfaceModel): Promise<boolean> {
		switch (interfaceDefinition.transactionType) {
			case TransactionType.Receipt:
			case TransactionType.FixedCompensation:
			case TransactionType.Registration:
				return true;

			// History suggestions are irrelevant before invoices have an attachment. Therefore, we only get them if we have at least one attachment
			case TransactionType.Invoice:
				if (stringIsSetAndFilled(txId)) {
					const tx = await this.transactionEditorService.getTransaction(txId);
					return arrayIsSetAndFilled(tx.attachments);
				}
				return false;

			default:
				return false;
		}
	}
}
