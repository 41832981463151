import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { TranslateModule } from '@ngx-translate/core';
import { SortablejsModule } from 'ngx-sortablejs';
import { RouterModule } from '@angular/router';
import { ModalModule } from '~/app/shared/ui/modal/modal.module';
import { UiModule } from '~/app/shared/ui/ui.module';
import { AddCustomIntegrationRelationModalComponent } from '~/app/modules/receipt/components/modals/add-custom-integration-relation-modal/add-custom-integration-relation-modal.component';
import { NewRelationModule } from '~/app/modules/receipt/components/modals/new-relation-modal/new-relation-modal.component';
import { CompanyUI } from '~/app/modules/company/components/ui/ui.module';

@NgModule({
	imports: [
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		NgSelectModule,
		TranslateModule,
		SortablejsModule,
		RouterModule,
		UiModule,
		ModalModule,
		NewRelationModule,
		CompanyUI,
	],
	declarations: [AddCustomIntegrationRelationModalComponent],
	providers: [],
	exports: [AddCustomIntegrationRelationModalComponent],
})
export class AddCustomIntegrationRelationModalModule {}
