<div class="componentContainer">
	<div class="amountContainer">
		<div class="currencySymbol" [ngClass]="{ showErrors: isInErrorState(), disabled: disabled }">
			{{ getCurrencySymbol(currency) }}
		</div>
		<klp-form-text-input
			*ngIf="!showPer100"
			[(ngModel)]="amount"
			(ngModelChange)="onChange()"
			[inErrorState]="isInErrorState()"
			[disabled]="disabled"
			[innerValueChangeInterceptor]="changeInterceptor"
			[hasBorderLeft]="false"
			[hasBorderRight]="hideCurrencyPicker"
			[getTailTplFn]="getTailTpl"
		></klp-form-text-input>
		<klp-form-text-input
			*ngIf="showPer100"
			[(ngModel)]="amountPer100"
			(ngModelChange)="onChange()"
			[inErrorState]="isInErrorState()"
			[disabled]="disabled"
			[innerValueChangeInterceptor]="changeInterceptor"
			[hasBorderLeft]="false"
			[hasBorderRight]="hideCurrencyPicker"
			[getTailTplFn]="getTailTpl"
		></klp-form-text-input>
	</div>
	<app-currency-picker
		*ngIf="!hideCurrencyPicker"
		[(ngModel)]="currency"
		(ngModelChange)="onChange()"
		[inErrorState]="isInErrorState()"
		[disabled]="disabled"
		[clearable]="false"
		[hasBorderLeft]="false"
	></app-currency-picker>
	<div *ngIf="info" class="info">{{ info }}</div>
</div>
