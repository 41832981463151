import { Component, HostBinding, Input } from '@angular/core';

// generated by scripts/generate-svg-icons
type SvgIcon =
	| 'activity-heart'
	| 'activity'
	| 'anchor'
	| 'archive'
	| 'asterisk-01'
	| 'asterisk-02'
	| 'at-sign'
	| 'bookmark-add'
	| 'bookmark-check'
	| 'bookmark-minus'
	| 'bookmark-x'
	| 'bookmark'
	| 'building-01'
	| 'building-02'
	| 'building-03'
	| 'building-04'
	| 'building-05'
	| 'building-06'
	| 'building-07'
	| 'building-08'
	| 'check-circle-broken'
	| 'check-circle'
	| 'check-done-01'
	| 'check-done-02'
	| 'check-heart'
	| 'check-square-broken'
	| 'check-square'
	| 'check-verified-01'
	| 'check-verified-02'
	| 'check-verified-03'
	| 'check'
	| 'cloud-blank-01'
	| 'cloud-blank-02'
	| 'copy-01'
	| 'copy-02'
	| 'copy-03'
	| 'copy-04'
	| 'copy-05'
	| 'copy-06'
	| 'copy-07'
	| 'divide-01'
	| 'divide-02'
	| 'divide-03'
	| 'dots-grid'
	| 'dots-horizontal'
	| 'dots-vertical'
	| 'download-01'
	| 'download-02'
	| 'download-03'
	| 'download-04'
	| 'download-cloud-01'
	| 'download-cloud-02'
	| 'edit-01'
	| 'edit-02'
	| 'edit-03'
	| 'edit-04'
	| 'edit-05'
	| 'equal-not'
	| 'equal'
	| 'eye-off'
	| 'eye'
	| 'filter-funnel-01'
	| 'filter-funnel-02'
	| 'filter-lines'
	| 'google-chrome'
	| 'hash-01'
	| 'hash-02'
	| 'heart-circle'
	| 'heart-hand'
	| 'heart-hexagon'
	| 'heart-octagon'
	| 'heart-rounded'
	| 'heart-square'
	| 'heart'
	| 'hearts'
	| 'help-circle'
	| 'help-hexagon'
	| 'help-octagon'
	| 'help-square'
	| 'home-01'
	| 'home-02'
	| 'home-03'
	| 'home-04'
	| 'home-05'
	| 'home-line'
	| 'home-smile'
	| 'info-circle'
	| 'info-hexagon'
	| 'info-octagon'
	| 'info-square'
	| 'life-buoy-01'
	| 'life-buoy-02'
	| 'link-01'
	| 'link-02'
	| 'link-03'
	| 'link-04'
	| 'link-05'
	| 'link-broken-01'
	| 'link-broken-02'
	| 'link-external-01'
	| 'link-external-02'
	| 'loading-01'
	| 'loading-02'
	| 'loading-03'
	| 'log-in-01'
	| 'log-in-02'
	| 'log-in-03'
	| 'log-in-04'
	| 'log-out-01'
	| 'log-out-02'
	| 'log-out-03'
	| 'log-out-04'
	| 'medical-circle'
	| 'medical-cross'
	| 'medical-square'
	| 'menu-01'
	| 'menu-02'
	| 'menu-03'
	| 'menu-04'
	| 'menu-05'
	| 'minus-circle'
	| 'minus-square'
	| 'minus'
	| 'percent-01'
	| 'percent-02'
	| 'percent-03'
	| 'pin-01'
	| 'pin-02'
	| 'placeholder'
	| 'plus-circle'
	| 'plus-square'
	| 'plus'
	| 'save-01'
	| 'save-02'
	| 'save-03'
	| 'search-lg'
	| 'search-md'
	| 'search-refraction'
	| 'search-sm'
	| 'settings-01'
	| 'settings-02'
	| 'settings-03'
	| 'settings-04'
	| 'share-01'
	| 'share-02'
	| 'share-03'
	| 'share-04'
	| 'share-05'
	| 'share-06'
	| 'share-07'
	| 'slash-circle-01'
	| 'slash-circle-02'
	| 'slash-divider'
	| 'slash-octagon'
	| 'speedometer-01'
	| 'speedometer-02'
	| 'speedometer-03'
	| 'speedometer-04'
	| 'target-01'
	| 'target-02'
	| 'target-03'
	| 'target-04'
	| 'target-05'
	| 'toggle-01-left'
	| 'toggle-01-right'
	| 'toggle-02-left'
	| 'toggle-02-right'
	| 'toggle-03-left'
	| 'toggle-03-right'
	| 'tool-01'
	| 'tool-02'
	| 'translate-01'
	| 'translate-02'
	| 'trash-01'
	| 'trash-02'
	| 'trash-03'
	| 'trash-04'
	| 'upload-01'
	| 'upload-02'
	| 'upload-03'
	| 'upload-04'
	| 'upload-cloud-01'
	| 'upload-cloud-02'
	| 'virus'
	| 'x-circle'
	| 'x-close'
	| 'x-square'
	| 'x'
	| 'zap-circle'
	| 'zap-fast'
	| 'zap-off'
	| 'zap-square'
	| 'zap';

@Component({
	selector: 'app-svg-icon',
	templateUrl: './svg-icon.component.html',
	styleUrls: ['./svg-icon.component.scss'],
})
export class SvgIconComponent {
	@Input() icon: SvgIcon;
	@Input() display: 'inline-block' | 'block' = 'block';
	@Input() size: 'verySmall' | 'small' | 'medium' | 'large' = 'medium';

	@HostBinding('style.-webkit-mask-image') private _path!: string;
	@HostBinding('style.height') private height!: string;
	@HostBinding('style.width') private width!: string;
	@HostBinding('style.display') private _display!: string;

	public setPath(filePath: string) {
		this._path = `url("${filePath}")`;
	}

	ngOnChanges(): void {
		this.setPath(`assets/images/icons/klippa-icons/${this.icon}.svg`);
		let size = '2rem';
		switch (this.size) {
			case 'verySmall':
				size = '1rem';
				break;
			case 'small':
				size = '1.2rem';
				break;
			case 'medium':
				size = '2rem';
				break;
			case 'large':
				size = '3rem';
				break;
		}
		this.height = this.width = size;
		this._display = this.display;
	}
}
