<klp-grid-container [fluid]="true">
	<div class="page-title d-flex justify-content-between">
		<h4 translate>Inbox</h4>
		<button class="btn btn-primary" *ngIf="inboxItems && hasUnreadItems()" (click)="markRead()" translate>Mark read</button>
	</div>

	<div class="row">
		<div class="col-md-12">
			<div class="card">
				<div class="card-block">
					<app-inbox-table [inboxItems]="inboxItems"> </app-inbox-table>
				</div>
			</div>
		</div>
	</div>
</klp-grid-container>
