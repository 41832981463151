<ng-container *ngIf="bindLabel">
	<ng-select
		id="subcompanies"
		name="subcompanies"
		placeholder="{{ 'Select %item%' | translate: { item: 'Sub company' | translate | lowercase } }}"
		[items]="selectOptions"
		[disabled]="disabled"
		[ngModel]="company"
		[multiple]="multiple"
		[bindLabel]="bindLabel"
		[selectOnTab]="true"
		[clearable]="false"
		[loading]="loading"
		[trackByFn]="trackSelectOption"
		[compareWith]="compareWith"
		(change)="subCompanyServiceDeprecated.setCompany($event)"
		*ngIf="!useTypeAhead"
	>
		<ng-template let-index="index" let-item="item" let-search="searchTerm" ng-option-tmp>
			<div>
				{{ item[bindLabel] }}
			</div>
		</ng-template>
	</ng-select>

	<ng-select
		id="subcompanies"
		name="subcompanies"
		placeholder="{{ 'Start typing to search for a %item%' | translate: { item: 'Sub company' | translate | lowercase } }}"
		[items]="selectOptions"
		[disabled]="disabled"
		[typeahead]="selectOptionsTypeahead"
		[ngModel]="company"
		[multiple]="multiple"
		[bindLabel]="bindLabel"
		[selectOnTab]="true"
		[clearable]="false"
		[loading]="loading"
		[trackByFn]="trackSelectOption"
		[compareWith]="compareWith"
		(change)="subCompanyServiceDeprecated.setCompany($event)"
		*ngIf="useTypeAhead"
	>
		<ng-template let-index="index" let-item="item" let-search="searchTerm" ng-option-tmp>
			<div>
				{{ item[bindLabel] }}
			</div>
		</ng-template>
	</ng-select>
</ng-container>
