export enum AccountingCustomStylingTemplate {
	LABEL_DESCRIPTION = 'LABEL_DESCRIPTION',
	LABEL_DESCRIPTION_CONTACT_INFO = 'LABEL_DESCRIPTION_CONTACT_INFO',
	LABEL_DESCRIPTION_WITH_PAYMENT_INFO = 'LABEL_DESCRIPTION_WITH_PAYMENT_INFO',
	LABEL_DESCRIPTION_WITH_COMPANY_INFO = 'LABEL_DESCRIPTION_WITH_COMPANY_INFO',
	LABEL_DESCRIPTION_WITH_VAT_PERCENTAGE = 'LABEL_DESCRIPTION_WITH_VAT_PERCENTAGE',
	LABEL_DESCRIPTION_WITHOUT_CODE = 'LABEL_DESCRIPTION_WITHOUT_CODE',
	LABEL_DESCRIPTION_CONTACT_INFO_WITHOUT_CODE = 'LABEL_DESCRIPTION_CONTACT_INFO_WITHOUT_CODE',
	LABEL_DESCRIPTION_WITH_PAYMENT_INFO_WITHOUT_CODE = 'LABEL_DESCRIPTION_WITH_PAYMENT_INFO_WITHOUT_CODE',
	LABEL_DESCRIPTION_WITH_COMPANY_INFO_WITHOUT_CODE = 'LABEL_DESCRIPTION_WITH_COMPANY_INFO_WITHOUT_CODE',
	LABEL_DESCRIPTION_WITH_VAT_PERCENTAGE_WITHOUT_CODE = 'LABEL_DESCRIPTION_WITH_VAT_PERCENTAGE_WITHOUT_CODE',
}
