<ng-select
	id="users"
	name="users"
	placeholder="{{ multiple ? ('Select users' | translate) : ('Select user' | translate) }} "
	[items]="users"
	[(ngModel)]="companyUsers"
	[multiple]="multiple"
	bindLabel="Label"
	[bindValue]="bindToPropery"
	[selectOnTab]="true"
	[clearable]="true"
	[ngClass]="{ 'is-invalid': invalidApprovers }"
></ng-select>
<div class="invalid-feedback" *ngIf="invalidApprovers">
	<div translate>At least one approver is required</div>
</div>
