import { Injectable } from '@angular/core';
import { APIService } from '../../../api/services/api.service';
import { ReceiptListAPIRequest } from '#/models/transaction/receipt';
import {
	AccountNumberStat,
	AdministrationStat,
	CategoryStat,
	CompanyGroupStat,
	CostCenterStat,
	CostUnitStat,
	FolderStat,
	MerchantStat,
	PaymentMethodIDStat,
	PaymentMethodStat,
	ProjectStat,
	ReceiptTypeOption,
	TagStat,
	TravelLocationStat,
	UserStat,
	UserTitleStat,
	VATStat,
} from './models/stats';
import { orderBy } from 'lodash';
import { AddTags, ClearTags } from '~/app/modules/tag/models/tag.actions';
import { Store } from '@ngrx/store';

@Injectable({
	providedIn: 'root',
})
export class StatisticsService {
	constructor(private apiService: APIService, private store: Store) {}

	async getStats(path, onSuccess, filters, skip) {
		const res = await this.apiService.get(filters.getStatisticsURL() + path + filters.getFilterQuery('?', skip));
		return await onSuccess(res);
	}

	getMerchantStats(filters: ReceiptListAPIRequest, skip?: string): Promise<MerchantStat[]> {
		return this.getStats('merchants', (r) => MerchantStat.fromData(r.data.MerchantStats), filters, skip);
	}

	getVATStats(filters: ReceiptListAPIRequest, skip?: string): Promise<VATStat[]> {
		return this.getStats('vat', (r) => VATStat.fromData(r.data.VatStats), filters, skip);
	}

	getPaymentMethodStats(filters: ReceiptListAPIRequest, skip?: string): Promise<PaymentMethodStat[]> {
		return this.getStats('paymentmethods', (r) => PaymentMethodStat.fromData(r.data.PaymentMethodStats), filters, skip);
	}

	getPaymentMethodIDStats(filters: ReceiptListAPIRequest, skip?: string): Promise<PaymentMethodIDStat[]> {
		const onSuccess = (r) => {
			return PaymentMethodIDStat.fromData(r.data.PaymentMethodIDStats);
		};
		return this.getStats('paymentmethodids', onSuccess, filters, skip);
	}

	getAccountNumberStats(filters: ReceiptListAPIRequest, skip?: string): Promise<AccountNumberStat[]> {
		return this.getStats('accountnumbers', (r) => AccountNumberStat.fromData(r.data.AccountNumberStats), filters, skip);
	}

	getTravelLocationStats(filters: ReceiptListAPIRequest, skip?: string): Promise<TravelLocationStat[]> {
		return this.getStats('travelLocations', (r) => TravelLocationStat.fromData(r.data.TravelLocationStats), filters, skip);
	}

	getGroupStats(filters: ReceiptListAPIRequest, skip?: string): Promise<FolderStat[]> {
		filters.company = false;
		return this.getStats('groups', (r) => FolderStat.fromData(r.data.GroupStats), filters, skip);
	}

	getTagStats(filters: ReceiptListAPIRequest, skip?: string): Promise<TagStat[]> {
		const onSuccess = (r) => {
			const tagStats = TagStat.fromData(r.data.TagStats);
			return orderBy(tagStats, [(tag) => tag.getTag().toLowerCase()]);
		};
		return this.getStats('tags', onSuccess, filters, skip);
	}

	getCostCenterStats(filters: ReceiptListAPIRequest, skip?: string): Promise<CostCenterStat[]> {
		return this.getStats('costcenters', (r) => CostCenterStat.fromData(r.data.CostCenterStats), filters, skip);
	}

	getCostUnitStats(filters: ReceiptListAPIRequest, skip?: string): Promise<CostUnitStat[]> {
		return this.getStats('costunits', (r) => CostUnitStat.fromData(r.data.CostUnitStats), filters, skip);
	}

	getProjectStats(filters: ReceiptListAPIRequest, skip?: string): Promise<ProjectStat[]> {
		return this.getStats('projects', (r) => ProjectStat.fromData(r.data.ProjectStats), filters, skip);
	}

	getCategoryStats(filters: ReceiptListAPIRequest, skip?: string): Promise<CategoryStat[]> {
		filters.company = true;
		return this.getStats('categories', (r) => CategoryStat.fromData(r.data.CategoryStats), filters, skip);
	}

	getAdministrationStats(filters: ReceiptListAPIRequest, skip?: string): Promise<AdministrationStat[]> {
		filters.company = true;
		return this.getStats('administrations', (r) => AdministrationStat.fromData(r.data.AdministrationStats), filters, skip);
	}

	getCompanyGroupStats(filters: ReceiptListAPIRequest, skip?: string): Promise<CompanyGroupStat[]> {
		filters.company = true;
		return this.getStats('groups', (r) => CompanyGroupStat.fromData(r.data.GroupStats), filters, skip);
	}

	getUserStats(filters: ReceiptListAPIRequest, skip?: string): Promise<UserStat[]> {
		filters.company = true;
		return this.getStats('users', (r) => UserStat.fromData(r.data.UserStats), filters, skip);
	}

	getCompanyMerchantStats(filters: ReceiptListAPIRequest, skip?: string): Promise<MerchantStat[]> {
		filters.company = true;
		return this.getStats('merchants', (r) => MerchantStat.fromData(r.data.MerchantStats), filters, skip);
	}

	getCompanyTagStats(filters: ReceiptListAPIRequest, skip?: string): Promise<TagStat[]> {
		filters.company = true;
		return this.getStats('tags', (r) => TagStat.fromData(r.data.TagStats), filters, skip);
	}

	getCompanyVATStats(filters: ReceiptListAPIRequest, skip?: string): Promise<VATStat[]> {
		filters.company = true;
		return this.getStats('vat', (r) => VATStat.fromData(r.data.VatStats), filters, skip);
	}

	getUserTitleStats(filters: ReceiptListAPIRequest, skip?: string): Promise<UserTitleStat[]> {
		filters.company = true;
		return this.getStats('userTitles', (r) => UserTitleStat.fromData(r.data.UserTitleStats), filters, skip);
	}

	getReceiptOptionTypes(filters: ReceiptListAPIRequest, skip?: string): Promise<ReceiptTypeOption[]> {
		filters.company = true;
		return this.getStats('types', (r) => ReceiptTypeOption.fromData(r.data.TypeStats), filters, skip);
	}

	public async initializeTags(): Promise<void> {
		return this.getTagStats(new ReceiptListAPIRequest()).then((tagStats) => {
			this.store.dispatch(new ClearTags());
			this.store.dispatch(new AddTags({ tags: tagStats }));
		});
	}
}
