import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Company } from '#/models/company/company.model';
import { CompanyService } from '#/services/company/company.service';
import { Category } from '#/models/company/category.model';
import { CompanyCategoryService } from '#/services/company/company-category.service';

@Component({
	selector: 'app-company-category-label',
	templateUrl: './company-category-label.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CompanyCategoryLabelComponent implements OnInit, OnDestroy {
	private static objectCache = new Map<string, Category>();
	public value: string;

	@Input()
	public category = '';

	@Input()
	public company: Company;

	public categoryObject?: Category;
	destroyed = false;

	constructor(
		private ref: ChangeDetectorRef,
		protected companyAPIService: CompanyService,
		private companyCategory: CompanyCategoryService,
	) {}

	ngOnInit() {
		this.loadCategory();
	}

	ngOnDestroy() {
		this.destroyed = true;
	}

	updateView() {
		if (!this.destroyed) {
			this.ref.markForCheck();
		}
	}

	loadCategory() {
		this.categoryObject = null;
		this.updateView();
		if (this.category && this.category !== '') {
			if (CompanyCategoryLabelComponent.objectCache.has(this.category)) {
				this.categoryObject = CompanyCategoryLabelComponent.objectCache.get(this.category);
				this.updateView();
			} else {
				this.companyCategory.getCompanyCategory(this.company.id, this.category).then((category) => {
					CompanyCategoryLabelComponent.objectCache.set(this.category, category);
					this.categoryObject = category;

					this.updateView();
				});
			}
		} else {
			this.updateView();
		}
	}
}
