<ng-template #authorizationFlowTooltip>
	<app-authorization-flow-tooltip data-cy="tooltipAuthFlow" [parentItem]="receipt"></app-authorization-flow-tooltip>
</ng-template>
<span *ngIf="(statusValue | isValueSet) && statusValue !== 'Approved' && !inAuthorizationFlow" [ngClass]="expenseClasses">
	{{ getExpenseLabel() }}
</span>
<span
	*ngIf="(statusValue | isValueSet) && statusValue === 'Approved' && !inAuthorizationFlow"
	[ngClass]="expenseClasses"
	[placement]="tooltipPlacement"
	[ngbTooltip]="authorizationFlowTooltip"
>
	{{ getExpenseLabel() }}
</span>
<span
	*ngIf="(statusValue | isValueSet) && inAuthorizationFlow"
	[ngClass]="expenseClasses"
	[placement]="tooltipPlacement"
	[ngbTooltip]="authorizationFlowTooltip"
>
	{{ authorizationFlowApprovedCount }} / {{ authorizationFlowApproverCount }} {{ getExpenseLabel() }}
</span>
