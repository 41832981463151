import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { TagActionTypes, TagActionsUnion } from './tag.actions';
import { createSelector } from '@ngrx/store';
import { TagStat } from '../../../pages/authenticated/statistics/models/stats';

export interface TagState extends EntityState<TagStat> {
	currentPage: number | null;
	totalPages: number | null;
}

export const adapter: EntityAdapter<TagStat> = createEntityAdapter<TagStat>();

export const initialState: TagState = adapter.getInitialState({
	currentPage: null,
	totalPages: null,
});

export function tagsReducer(state = initialState, action: TagActionsUnion): TagState {
	switch (action.type) {
		case TagActionTypes.ADD_TAG: {
			return adapter.addOne(action.payload.tag, state);
		}

		case TagActionTypes.UPSERT_TAG: {
			return adapter.upsertOne(action.payload.tag, state);
		}

		case TagActionTypes.ADD_TAGS: {
			return adapter.addMany(action.payload.tags, state);
		}

		case TagActionTypes.UPSERT_TAGS: {
			return adapter.upsertMany(action.payload.tags, state);
		}

		case TagActionTypes.UPDATE_TAG: {
			return adapter.updateOne(action.payload.tag, state);
		}

		case TagActionTypes.UPDATE_TAGS: {
			return adapter.updateMany(action.payload.tags, state);
		}

		case TagActionTypes.DELETE_TAG: {
			return adapter.removeOne(action.payload.id, state);
		}

		case TagActionTypes.DELETE_TAGS: {
			return adapter.removeMany(action.payload.ids, state);
		}

		case TagActionTypes.LOAD_TAGS: {
			return adapter.addAll(action.payload.tags, state);
		}

		case TagActionTypes.CLEAR_TAGS: {
			return adapter.removeAll({ ...state });
		}

		default: {
			return state;
		}
	}
}

const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors();

// select the array of tag ids
export const selecedTagIds = selectIds;

// select the dictionary of tag entities
export const selectTagEntities = selectEntities;

// select the array of tags
export const selectAllTags = selectAll;

// select the total group count
export const tag = selectTotal;

export const getItemById = (id) => createSelector(selectEntities, (allItems) => allItems[id] || null);
