import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { InterfaceFrontendModel as TransactionInterfaceModel } from '#/models/transaction/interface/frontendModel';
import { TransactionInterfaceService } from '#/services/transaction/transaction-interface.service';
import { TransactionType } from '#/models/transaction/transactionType';
import { personalRegistrationPrefix } from '#/models/transaction/registration/transformer';
import { RegistrationFrontendModel } from '#/models/transaction/registration/frontendModel';

@Component({
	selector: 'app-registrations-list',
	templateUrl: './registrations-list.component.html',
	styleUrls: ['./registrations-list.component.scss'],
})
export class RegistrationsListComponent {
	public transactionInterfaces: Array<TransactionInterfaceModel> = undefined;

	constructor(
		private transactionInterfaceService: TransactionInterfaceService,
		private router: Router,
		private activatedRoute: ActivatedRoute,
	) {}

	public ngOnInit(): void {
		this.transactionInterfaceService.getInterfaces(true, false, true, null, true).then((res) => {
			this.transactionInterfaces = res.filter((e) => e.transactionType === TransactionType.Registration);
		});
	}

	public async goToTransaction(registration: RegistrationFrontendModel) {
		await this.router.navigate([`./tx/${personalRegistrationPrefix}${registration.id}`], {
			relativeTo: this.activatedRoute,
		});
	}

	public async goToNewRegistration(txiId: string): Promise<void> {
		await this.router.navigate([`./tx/new`], {
			relativeTo: this.activatedRoute,
			queryParams: { 'tx-interface-id': txiId, 'view-mode': 'submit' },
		});
	}
}
