import { Injectable } from '@angular/core';
import { DynamicRouteNameResolver } from '~/app/modules/company/resolvers/dynamicRouteNameResolvers/dynamic-route-name-resolver';
import { ExpenseReportsService } from '#/services/transaction/expense-reports.service';

@Injectable({
	providedIn: 'root',
})
export class ReportEntityRouteNameResolverService implements DynamicRouteNameResolver {
	constructor(private expenseReportsService: ExpenseReportsService) {}

	resolve(reportId: string): Promise<string> {
		return this.expenseReportsService.getExpenseReport(reportId).then((tpl) => tpl.description);
	}
}
