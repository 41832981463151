import { DistanceUnit, KilometerToMileRatio } from '#/models/distance-unit';

export function calculateTotalDistance(distanceKm: number, numberOfTravelDays: number, distanceUnit: DistanceUnit): number {
	return this.calculateDistance(distanceKm, distanceUnit) * numberOfTravelDays;
}

export function calculateDistance(distanceKm: number, distanceUnit: DistanceUnit): number {
	if (distanceUnit === DistanceUnit.KILOMETER) {
		return distanceKm;
	}
	return distanceKm * KilometerToMileRatio.KM_TO_MILE;
}
