import { Injectable } from '@angular/core';
import { APIService } from '~/app/api/services/api.service';
import { APINotificationsService } from '~/app/api/services/apinotifications.service';
import { CompanyService } from '#/services/company/company.service';
import { stringIsSetAndFilled } from '#/util/values';

@Injectable({
	providedIn: 'root',
})
export class CompanyCardStatementService {
	constructor(private apiService: APIService, private notifications: APINotificationsService, private companyService: CompanyService) {}

	public uploadCardStatement(file: File, administration: string, transactionInterface: string, companyId: string = null): Promise<Blob> {
		const company = stringIsSetAndFilled(companyId) ? companyId : this.companyService.getCompanyId();
		const formData = new FormData();
		formData.append('file', file);
		formData.append('administration', administration);
		// transaction interface is not always mandatory if already defined for the card module
		if (stringIsSetAndFilled(transactionInterface)) {
			formData.append('transactionInterface', transactionInterface);
		}

		return this.apiService.postBlob(`/api/v1/company/${company}/import/cardreport`, formData).catch((e) => {
			this.notifications.handleAPIError(e);
			throw e;
		});
	}
}
