import { Action } from '@ngrx/store';
import { Currency } from '#/models/currency';
import { Language } from './models/language';
import { PaymentMethod } from './models/payment-method';
import { Gender } from './models/gender';

export enum GenericActionTypes {
	SetCurrenciesAction = '[SetCurrencies] Action',
	SetDocumentTypesAction = '[SetDocumentTypes] Action',
	SetGendersAction = '[SetGenders] Action',
	SetLanguagesAction = '[SetLanguages] Action',
	SetPaymentMethodsAction = '[SetPaymentMethods] Action',
}

export class SetCurrencies implements Action {
	readonly type = GenericActionTypes.SetCurrenciesAction;
	constructor(public payload: { currencies: Currency[] }) {}
}

export class SetDocumentTypes implements Action {
	readonly type = GenericActionTypes.SetDocumentTypesAction;
	constructor(public payload: { documentTypes: DocumentType[] }) {}
}

export class SetGenders implements Action {
	readonly type = GenericActionTypes.SetGendersAction;
	constructor(public payload: { genders: Gender[] }) {}
}

export class SetLanguages implements Action {
	readonly type = GenericActionTypes.SetLanguagesAction;
	constructor(public payload: { languages: Language[] }) {}
}

export class SetPaymentMethods implements Action {
	readonly type = GenericActionTypes.SetPaymentMethodsAction;
	constructor(public payload: { paymentMethods: PaymentMethod[] }) {}
}

export type GenericActions = SetCurrencies | SetDocumentTypes | SetGenders | SetLanguages | SetPaymentMethods;
