export class StatValue {
	constructor(private ID: string, private Label: string, private Count: number, private OriginalObject?: any) {}

	getLabel(): string {
		return this.Label;
	}

	getCount(): number {
		return this.Count;
	}

	get getFilterLabel(): string {
		return this.Label + ' (' + this.Count + ')';
	}

	originalObject(): any {
		return this.OriginalObject;
	}
}
