import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DuplicateMessage, Receipt } from '#/models/transaction/receipt';
import { ReceiptService } from '#/services/transaction/receipt.service';
import { format } from '~/app/util/i18n';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { TranslateService } from '@ngx-translate/core';
import { BaseComponent } from '~/app/base/base.component';

@Component({
	selector: 'app-duplicate-notifier',
	templateUrl: './duplicate-notifier.component.html',
	styleUrls: ['./duplicate-notifier.component.scss'],
})
export class DuplicateNotifierComponent extends BaseComponent implements OnInit {
	@Input() protected duplicateItems: [string];
	@Output() onDuplicateClick: EventEmitter<string> = new EventEmitter();
	public duplicateMessages: DuplicateMessage[] = [];

	constructor(public translate: TranslateService, protected receiptAPIService: ReceiptService) {
		super();
	}

	ngOnInit() {
		this.duplicateItems.map((receiptId: string) => {
			return this.receiptAPIService.getReceipt(receiptId, false).then((receipt: Receipt) => {
				this.duplicateMessages.push(
					new DuplicateMessage(
						this.translate.instant(_('Possible duplicate of %description% on %date%.'), {
							description: receipt.description,
							date: format(receipt.purchasedate, 'd MMM yyyy'),
						})
					)
				);
			});
		});
	}

	openFirstDuplicate() {
		this.onDuplicateClick.emit(this.duplicateItems[0]);
	}
}
