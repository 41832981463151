<div class="table-overflow" *ngIf="inboxItems && inboxItems.length > 0">
	<table class="table table-striped table-hover table-xl">
		<thead>
			<tr>
				<th></th>
				<th translate>Title</th>
				<th translate>Message</th>
				<th translate>From</th>
				<th translate>Date</th>
			</tr>
		</thead>
		<tbody>
			<tr
				app-inbox-table-row
				*ngFor="let inboxItem of inboxItems"
				[inboxItem]="inboxItem"
				(click)="onClick(inboxItem)"
				[class]="inboxItem.isRead() ? 'cursor-pointer' : 'text-bold cursor-pointer'"
			></tr>
		</tbody>
	</table>
</div>

<app-empty-view
	*ngIf="inboxItems?.length == 0"
	gif="4"
	[text]="'Your inbox seems to be empty.' | translate"
	[subtitle]="
		user.hasCompanyRole()
			? ('Messages on document status updates and shared documents and folders can be found here.' | translate)
			: ('Messages on shared receipts and folders can be found here.' | translate)
	"
>
</app-empty-view>
