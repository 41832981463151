import { Component, Host, Input, OnInit, Optional, SimpleChanges } from '@angular/core';
import { AllDeclarationStatusFlags, DeclarationStatusFlag, getExpenseLabel } from '#/models/transaction/receipt';
import { TranslateService } from '@ngx-translate/core';
import { ControlContainer, NG_VALUE_ACCESSOR } from '@angular/forms';
import { FormElementComponent, MultipleValueAccessorBase } from '@klippa/ngx-enhancy-forms';
import { UserService } from '~/app/modules/user/user.service';
import { UserRole } from '#/models/user/user.model';
import { arrayIsSetAndFilled } from '#/util/arrays';
import { memoize } from 'lodash';
import { PossibleCompanyFeatureFlags } from '#/models/company/possible-feature-flags';
import { CompanyFeatureFlagsService } from '#/services/company/company-feature-flags.service';

@Component({
	selector: 'app-status-picker',
	templateUrl: './status-picker.component.html',
	styleUrls: ['./status-picker.component.scss'],
	providers: [{ provide: NG_VALUE_ACCESSOR, useExisting: StatusPickerComponent, multi: true }],
})
export class StatusPickerComponent extends MultipleValueAccessorBase<DeclarationStatusFlag> implements OnInit {
	@Input() multiple = false;
	@Input() filterOnStatuses: Array<DeclarationStatusFlag> = [];
	@Input() placeholder: string;

	public statusOptions: Array<{ id: string; name: string }> = [];
	private statuses = AllDeclarationStatusFlags;
	private companyHasTransactionInterfaces = memoize((): Promise<boolean> => {
		return this.companyFeatureFlagsService.companyHasSpecificFeatureFlagEnabled(PossibleCompanyFeatureFlags.TRANSACTION_INTERFACES);
	});

	constructor(
		private translate: TranslateService,
		private userService: UserService,
		private companyFeatureFlagsService: CompanyFeatureFlagsService,
		@Optional() @Host() protected parent: FormElementComponent,
		@Optional() @Host() protected controlContainer: ControlContainer,
	) {
		super(parent, controlContainer);
	}

	ngOnInit() {
		super.ngOnInit();
		this.setStatusOptions();
	}

	ngOnChanges(simpleChanges: SimpleChanges) {
		if (simpleChanges.filterOnStatuses) {
			this.setStatusOptions();
		}
	}

	private async setStatusOptions(): Promise<void> {
		const hasTxi = await this.companyHasTransactionInterfaces();
		this.statusOptions = this.statuses
			.filter((e) => {
				if (e === DeclarationStatusFlag.Claimed) {
					if (this.userService.getCurrentLoggedUser().hasRole(UserRole.CompanyFinance)) {
						return true;
					}
					return false;
				}

				if (arrayIsSetAndFilled(this.filterOnStatuses) && !this.filterOnStatuses.includes(e)) {
					return false;
				}

				return true;
			})
			.map((status) => ({
				id: status,
				name: this.translate.instant(getExpenseLabel(status, hasTxi)),
			}));
	}
}
