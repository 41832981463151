<mat-tree
	cdkDropList
	[dataSource]="dataSource"
	[treeControl]="treeControl"
	(cdkDropListDropped)="drop($event)"
	[cdkDropListSortingDisabled]="disableSorting || disabled"
>
	<mat-tree-node *matTreeNodeDef="let node" cdkDrag [cdkDragData]="node" [cdkDragDisabled]="disabled" matTreeNodePadding class="treeNode">
		<app-tree-node
			class="treeNodeContent"
			[node]="node"
			[isNode]="false"
			[showNodeSettingsButtons]="showNodeSettingsButtons && !disabled"
			[isExpanded]="treeControl.isExpanded(node)"
			[disabled]="disabled"
			(onToggleNode)="treeControl.toggle($event)"
			(onOpenNodeSettingsModal)="openNodeSettingsModal($event)"
		></app-tree-node>
	</mat-tree-node>

	<mat-tree-node
		*matTreeNodeDef="let node; when: hasChild"
		[cdkDragDisabled]="disabled"
		cdkDrag
		[cdkDragData]="node"
		matTreeNodePadding
		class="treeNode"
	>
		<app-tree-node
			class="treeNodeContent"
			[node]="node"
			[isNode]="true"
			[showNodeSettingsButtons]="showNodeSettingsButtons && !disabled"
			[isExpanded]="treeControl.isExpanded(node)"
			[disabled]="disabled"
			(onToggleNode)="treeControl.toggle($event)"
			(onOpenNodeSettingsModal)="openNodeSettingsModal($event)"
		></app-tree-node>
	</mat-tree-node>

	<mat-tree-node matTreeNodePadding cdkDragDisabled *matTreeNodeDef="let node; when: isNodeToPreventNodeBecomingLeaf" cdkDrag>
		<div class="nodeToPreventNodeBecomingLeaf"></div>
	</mat-tree-node>
</mat-tree>

<ng-template #nodeSettings>
	<app-tree-node-settings
		[node]="editSettingsNode"
		(onSave)="saveNodeSettings($event)"
		(onDismiss)="dismissSettingsModal()"
	></app-tree-node-settings>
</ng-template>
