<ngx-async-template [promise]="supportsCreateCustomRelationPromise">
	<ng-template ngx-async-success let-supportsCustomRelationCreation="value">
		<div class="componentContainer">
			<app-dynamic-options-picker
				[getPlaceholderTextFn]="getPlaceholderTextFn"
				[fetchItemsFn]="fetchItemsFn"
				[fetchSelectedItemsFn]="fetchSelectedItemsFn"
				[mapToSelectOptionFn]="mapToSelectOptionFn"
				[sortValuesFn]="sortValuesFn"
				[selectableItems]="selectableItems"
				[ngClass]="{ showErrors: isInErrorState() }"
				[(ngModel)]="innerValue"
				(ngModelChange)="setInnerValueAndNotify($event)"
				[multiple]="multiple"
				[disabled]="disabled || !canCreateCustomRelation()"
				[clearable]="clearable"
				[dropdownPosition]="dropdownPosition"
				[truncateOptions]="false"
				[withSeparatingLine]="true"
				[newOptionFooterPrefix]="supportsCustomRelationCreation ? ('Add new relation' | translate) : ''"
				(onFooterClick)="openCreateCustomRelationModal(); setNewCustomRelationFieldName($event)"
			>
				<ng-template klpSelectOptionTpl let-item="item">
					<div class="entry">
						<header class="relationName">{{ item.name }}</header>
						<address class="relationInfo">
							<span *ngIf="item.Address.AddressLine1">{{ item.Address.AddressLine1 }} <br /></span>
							<span *ngIf="item.Address.AddressLine2">{{ item.Address.AddressLine2 }} <br /></span>
							<span *ngIf="item.Address.AddressLine3">{{ item.Address.AddressLine3 }} <br /></span>
							<div *ngIf="item.Address.City || item.Address.Country">
								<span *ngIf="item.Address.City">{{ item.Address.City }},</span>
								<span *ngIf="item.Address.Country">{{ item.Address.Country }}</span>
								<br />
							</div>
						</address>
						<div *ngIf="item.Phone" class="phoneNr">
							{{ item.Phone }}
						</div>
						<div *ngIf="item.Email" class="email">
							{{ item.Email }}
						</div>
						<div *ngIf="item.Website" class="website">
							{{ item.Website }}
						</div>
					</div>
				</ng-template>
			</app-dynamic-options-picker>
			<app-button
				variant="icon"
				*ngIf="supportsCustomRelationCreation"
				[disabled]="disabled || !canCreateCustomRelation()"
				[hasPadding]="false"
				[hasBorder]="false"
				[fullWidth]="true"
				[title]="'Create a new relation.' | translate"
				(click)="openCreateCustomRelationModal()"
			>
				<app-icon variant="plus"></app-icon>
			</app-button>
		</div>
	</ng-template>
</ngx-async-template>

<app-modal #addCustomRelation variant="modalAlmostFullScreen">
	<ng-template app-modal-body>
		<app-custom-add-integration-relation-modal
			[attachments]="attachments"
			[transactionId]="transactionId"
			[isInvoice]="isInvoice"
			[divisionId]="divisionId"
			[integrationId]="integrationId"
			[newCustomRelationFieldName]="newCustomRelationFieldName"
			(onNewIntegrationRelation)="preselectNewRelation($event)"
			(onDismissModal)="closeCreateCustomRelationModal()"
		>
		</app-custom-add-integration-relation-modal>
	</ng-template>
</app-modal>
