import { CategoryDefaultsFrontendModel } from '#/models/transaction/category/categoryDefaults/frontendModel';
import { CategoryDefaultsApiModel } from '#/models/transaction/category/categoryDefaults/apiModel';

export function categoryDefaultsApiToFrontend(categoryDefaultsApiModel: CategoryDefaultsApiModel): CategoryDefaultsFrontendModel {
	const categoryDefaultsFrontendModel: CategoryDefaultsFrontendModel = new CategoryDefaultsFrontendModel();

	categoryDefaultsFrontendModel.division = categoryDefaultsApiModel?.Division;
	categoryDefaultsFrontendModel.ledger = categoryDefaultsApiModel?.ledger;
	categoryDefaultsFrontendModel.glAccount = categoryDefaultsApiModel?.GLAccount;
	categoryDefaultsFrontendModel.journal = categoryDefaultsApiModel?.Journal;
	categoryDefaultsFrontendModel.vatCode = categoryDefaultsApiModel?.VATCode;

	return categoryDefaultsFrontendModel;
}

export function categoryDefaultsFrontendToApi(categoryDefaultsFrontendModel: CategoryDefaultsFrontendModel): CategoryDefaultsApiModel {
	const categoryDefaultsApiModel: CategoryDefaultsApiModel = new CategoryDefaultsApiModel();

	categoryDefaultsApiModel.Division = categoryDefaultsFrontendModel?.division;
	categoryDefaultsApiModel.ledger = categoryDefaultsFrontendModel?.ledger;
	categoryDefaultsApiModel.GLAccount = categoryDefaultsFrontendModel?.glAccount;
	categoryDefaultsApiModel.Journal = categoryDefaultsFrontendModel?.journal;
	categoryDefaultsApiModel.VATCode = categoryDefaultsFrontendModel?.vatCode;

	return categoryDefaultsApiModel;
}
