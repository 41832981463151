import { NgModule } from '@angular/core';
import { MerchantLabelComponent } from './components/merchant-label/merchant-label.component';
import { MerchantLogoComponent } from './components/merchant-logo/merchant-logo.component';
import { CommonModule } from '@angular/common';

@NgModule({
	imports: [CommonModule],
	declarations: [MerchantLabelComponent, MerchantLogoComponent],
	providers: [],
	exports: [MerchantLabelComponent, MerchantLogoComponent],
})
export class MerchantModule {}
