import { Routes } from '@angular/router';
import { ReportDeclarationsListComponent } from './components/list/report-declarations-list.component';
import { ReportDeclarationsSingleComponent } from './components/single/report-declarations-single.component';
import { ReportDeclarationsManageListComponent } from './components/manage-list/report-declarations-manage-list.component';
import { ReportDeclarationsManageSingleComponent } from '~/app/pages/authenticated/report-declarations/components/manage-single/report-declarations-manage-single.component';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { ReportEntityRouteNameResolverService } from '~/app/modules/company/resolvers/dynamicRouteNameResolvers/report-entity-route-name-resolver.service';
import { ReportListTabVisibilityResolver } from '~/app/modules/company/resolvers/ReportListTabVisibiltityResolver/report-list-tab-visibility-resolver';
import { CanAccessTodoListGuard } from '~/app/guards/can-access-todo-list.guard';
import { ReportRedirectionGuard } from '~/app/guards/report-redirection-guard';
import { EmptyComponent } from '~/app/emptyComponent';
import { ReportType } from '#/models/reportType.model';

const reportChildren = [
	{
		path: '',
		component: ReportDeclarationsListComponent,
	},
	{
		path: ':id',
		data: { dynamicRouteName: ':id', dynamicRouteNameResolver: ReportEntityRouteNameResolverService },
		children: [
			{
				path: 'todo',
				data: { routeName: _('To do'), tab: 'todo' },
				resolve: { showTabs: ReportListTabVisibilityResolver },
				canActivate: [CanAccessTodoListGuard],
				children: [
					{
						path: '',
						component: ReportDeclarationsSingleComponent,
					},
					{
						path: 'tx',
						loadChildren: () => import('../transaction/transaction.module').then((m) => m.TransactionModule),
					},
				],
			},
			{
				path: 'all',
				data: { routeName: _('All'), tab: 'all' },
				resolve: { showTabs: ReportListTabVisibilityResolver },
				children: [
					{
						path: '',
						component: ReportDeclarationsSingleComponent,
					},
					{
						path: 'tx',
						loadChildren: () => import('../transaction/transaction.module').then((m) => m.TransactionModule),
					},
				],
			},
			{
				path: '', // I want to do a redirect here, but it doesnt compile. I dont know why
				data: { routeName: _('To do'), tab: 'todo' },
				resolve: { showTabs: ReportListTabVisibilityResolver },
				canActivate: [CanAccessTodoListGuard],
				children: [
					{
						path: '',
						component: ReportDeclarationsSingleComponent,
					},
					{
						path: 'tx',
						loadChildren: () => import('../transaction/transaction.module').then((m) => m.TransactionModule),
					},
				],
			},
		],
	},
];
const manageReportChildren = [
	{
		path: 'todo',
		data: { routeName: _('To do'), tab: 'todo' },
		children: [
			{
				path: '',
				component: ReportDeclarationsManageListComponent,
			},
			{
				path: ':id',
				data: { dynamicRouteName: ':id', dynamicRouteNameResolver: ReportEntityRouteNameResolverService },
				children: [
					{
						path: '',
						component: ReportDeclarationsManageSingleComponent,
					},
					{
						path: 'tx',
						loadChildren: () => import('../transaction/transaction.module').then((m) => m.TransactionModule),
					},
				],
			},
		],
	},
	{
		path: 'all',
		data: { routeName: _('All'), tab: 'all' },
		children: [
			{
				path: '',
				component: ReportDeclarationsManageListComponent,
			},
			{
				path: ':id',
				data: { dynamicRouteName: ':id', dynamicRouteNameResolver: ReportEntityRouteNameResolverService },
				children: [
					{
						path: '',
						component: ReportDeclarationsManageSingleComponent,
					},
					{
						path: 'tx',
						loadChildren: () => import('../transaction/transaction.module').then((m) => m.TransactionModule),
					},
				],
			},
		],
	},
];

export const ReportsRouting: Routes = [
	{
		path: 'card',
		data: { routeName: _('Card'), variant: ReportType.CARD },
		children: [
			{
				path: '',
				redirectTo: '/reports/card',
				pathMatch: 'full',
			},
			...reportChildren,
		],
	},
	{
		path: 'out-of-pocket',
		data: { routeName: _('Out of pocket'), variant: ReportType.OUT_OF_POCKET },
		children: [
			{
				path: '',
				component: ReportDeclarationsListComponent,
			},
			{
				path: ':id',
				data: {
					dynamicRouteName: ':id',
					dynamicRouteNameResolver: ReportEntityRouteNameResolverService,
					showTabs: false,
				},
				children: [
					{
						path: '',
						component: ReportDeclarationsSingleComponent,
					},
					{
						path: 'tx',
						loadChildren: () => import('../transaction/transaction.module').then((m) => m.TransactionModule),
					},
				],
			},
		],
	},
	{
		path: '',
		canActivate: [ReportRedirectionGuard],
		component: EmptyComponent,
	},
	{
		path: 'manage/:id',
		redirectTo: '/manage-reports/all/:id',
		pathMatch: 'full',
	},
	{
		path: ':id',
		data: { dynamicRouteName: ':id', dynamicRouteNameResolver: ReportEntityRouteNameResolverService, showTabs: false },
		children: [
			{
				path: '',
				component: ReportDeclarationsSingleComponent,
			},
			{
				path: 'tx',
				loadChildren: () => import('../transaction/transaction.module').then((m) => m.TransactionModule),
			},
		],
	},
];

export const ManageReportsRouting: Routes = [
	{
		path: 'card',
		data: { routeName: _('Card'), variant: ReportType.CARD },
		children: [
			{
				path: '',
				redirectTo: '/manage-reports/card/todo',
				pathMatch: 'full',
			},
			...manageReportChildren,
		],
	},
	{
		path: 'out-of-pocket',
		data: { routeName: _('Out of pocket'), variant: ReportType.OUT_OF_POCKET },
		children: [
			{
				path: '',
				redirectTo: '/manage-reports/out-of-pocket/todo',
				pathMatch: 'full',
			},
			...manageReportChildren,
		],
	},
	{
		path: '',
		canActivate: [ReportRedirectionGuard],
		component: EmptyComponent,
	},
	...manageReportChildren,
];
