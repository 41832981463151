<ng-template #mfaModal>
	<div class="modal-header">
		<h4 class="modal-title" translate>Enable two-factor authentication to revalidate your session</h4>
		<button type="button" class="close" aria-label="Close" (click)="dismiss()">
			<span aria-hidden="true">&times;</span>
		</button>
	</div>
	<div class="modal-body">
		<div class="message" translate>
			This action can only be performed by an admin user. For security reasons, please revalidate your session with two-factor
			authentication. To be able to do this, you first need to enable two-factor authentication.
		</div>
		<app-multiple-factor-auth variant="modal" (onMFAConfirmed)="onMFAConfigure()"></app-multiple-factor-auth>
	</div>
</ng-template>
