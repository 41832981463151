import { Injectable } from '@angular/core';
import { isNullOrUndefined, isValueSet, stringOrArrayIsSetAndEmpty } from '#/util/values';
import { LocalStorageService } from 'angular-2-local-storage';
import { ColumnsInterface, ColumnSortingOrderInterface } from '~/app/shared/ui/table/table';

export interface ColumnSortingLocalStorageInterface {
	sortedColumns: ColumnSortingOrderInterface[];
	sortedTotalColumns: ColumnsInterface[];
}

@Injectable({
	providedIn: 'root',
})
export class DashboardColumnSortingService {
	constructor(private localStorageService: LocalStorageService) {}

	private isColumnPositionSavedInLocalStorage(sortedColumn: ColumnSortingOrderInterface, predefinedColumn: ColumnsInterface): boolean {
		if (isValueSet(sortedColumn.slug)) {
			return sortedColumn.slug === predefinedColumn.slug;
		}
		if (isValueSet(sortedColumn.items_slug)) {
			return sortedColumn.items_slug === predefinedColumn.items_slug;
		}
		if (isValueSet(sortedColumn.slug_start)) {
			return sortedColumn.slug_start === predefinedColumn.slug_start;
		}
		if (isValueSet(sortedColumn.booking_data_slug)) {
			return sortedColumn.booking_data_slug === predefinedColumn.booking_data_slug;
		}
		return false;
	}

	private getColumnSortingBasedOnSlugType(e: ColumnsInterface): ColumnSortingOrderInterface {
		return {
			slug: e.slug,
			slug_start: e.slug_start,
			items_slug: e.items_slug,
			booking_data_slug: e.booking_data_slug,
			enabled: e.enabled,
		};
	}

	public setColumnSortingToLocalStorage(columns: ColumnsInterface[], localStorageKey: string): void {
		const sortedColumns: ColumnSortingOrderInterface[] = columns
			.filter((column) => !isValueSet(column.position))
			.map(this.getColumnSortingBasedOnSlugType);
		this.updateLocalStorage(sortedColumns, localStorageKey);
	}

	private getNewColumns(
		predefinedColumns: ColumnsInterface[],
		sortedColumns: ColumnSortingOrderInterface[],
	): ColumnSortingOrderInterface[] {
		const newLocalStorageColumns: ColumnSortingOrderInterface[] = predefinedColumns
			.filter((predefinedColumn) => isNullOrUndefined(predefinedColumn.position))
			.filter((predefinedColumn) =>
				isNullOrUndefined(
					sortedColumns.find((localStorageColumn) => this.isColumnPositionSavedInLocalStorage(localStorageColumn, predefinedColumn)),
				),
			)
			.map(this.getColumnSortingBasedOnSlugType);
		return newLocalStorageColumns;
	}

	private hasNewColumns(sortedColumns: ColumnSortingOrderInterface[], predefinedColumns: ColumnsInterface[]): boolean {
		return !stringOrArrayIsSetAndEmpty(this.getNewColumns(predefinedColumns, sortedColumns));
	}

	private hasDeletedColumns(sortedColumns: ColumnSortingOrderInterface[], predefinedColumns: ColumnsInterface[]): boolean {
		const deletedColumns: ColumnsInterface[] = sortedColumns.filter((sortedColumn) =>
			isNullOrUndefined(
				predefinedColumns.find((predefinedColumn) => this.isColumnPositionSavedInLocalStorage(sortedColumn, predefinedColumn)),
			),
		);
		return !stringOrArrayIsSetAndEmpty(deletedColumns);
	}

	public getColumnsBasedOnSortingOrder(
		localStorageKey: string,
		predefinedColumns: ColumnsInterface[],
		prefixedColumns: ColumnsInterface[],
		endColumns: ColumnsInterface[],
	): ColumnsInterface[] {
		let sortedColumns: ColumnSortingOrderInterface[] = (this.localStorageService.get(localStorageKey) as ColumnSortingLocalStorageInterface)
			.sortedColumns;

		if (this.hasNewColumns(sortedColumns, predefinedColumns)) {
			const newColumns = this.getNewColumns(predefinedColumns, sortedColumns);
			sortedColumns = [...sortedColumns, ...newColumns];
			this.updateLocalStorage(sortedColumns, localStorageKey);
		}

		if (this.hasDeletedColumns(sortedColumns, predefinedColumns)) {
			sortedColumns = this.filterOutDeletedColumns(sortedColumns, predefinedColumns);
			this.updateLocalStorage(sortedColumns, localStorageKey);
		}

		const configurableColumns = predefinedColumns
			.filter((predefinedColumn) => isNullOrUndefined(predefinedColumn.position))
			.map((predefinedColumn) => {
				return {
					...predefinedColumn,
					enabled: sortedColumns.find((localStorageColumn) =>
						this.isColumnPositionSavedInLocalStorage(localStorageColumn, predefinedColumn),
					).enabled,
					originIndex:
						prefixedColumns.length +
						sortedColumns.findIndex((localStorageColumn) => this.isColumnPositionSavedInLocalStorage(localStorageColumn, predefinedColumn)),
				};
			})
			.sort((a, b) => a.originIndex - b.originIndex);
		return [...prefixedColumns, ...configurableColumns, ...endColumns];
	}

	public getBottomRowColumns(tableColumns: Array<ColumnsInterface>): Array<ColumnsInterface> {
		return [
			...tableColumns
				.filter((e) => e.enabled)
				.map((e) => {
					switch (e.id) {
						case 'selection':
							return { type: 'empty', enabled: true, position: 'pre' as 'pre' };
						case 'description':
							return { slug: 'receipt_count', type: 'total', enabled: true };
						case 'purchase_date':
							return { slug_start: 'start_date', slug_end: 'end_date', type: 'date-range', enabled: true };
						case 'cost_center':
							return { name: 'Cost center', slug: 'cost_center', type: 'cost_center', enabled: true };
						case 'cost_unit':
							return { name: 'Cost unit', slug: 'cost_unit', type: 'cost_unit', enabled: true };
						case 'project':
							return { name: 'Project', slug: 'project', type: 'project', enabled: true };
						case 'amount':
							return { slug: 'amount', type: 'amount', enabled: true };
						case 'declaration_status':
							return { slug: 'status_to_show', type: 'status_to_show', enabled: true };
						case 'settings':
							return { type: 'empty', enabled: true, position: 'end' as 'end' };
						default:
							return { type: 'empty', enabled: true };
					}
				}),
		];
	}

	private filterOutDeletedColumns(columnSortingOrder: ColumnSortingOrderInterface[], predefinedColumns: ColumnsInterface[]) {
		return columnSortingOrder.filter((e) =>
			predefinedColumns.find((predefinedColumn) => this.isColumnPositionSavedInLocalStorage(e, predefinedColumn)),
		);
	}

	private deleteDeprecatedLocalStorageColumnOrder(deprecatedLocalStorageKey: string): void {
		this.localStorageService.remove(deprecatedLocalStorageKey);
	}

	private updateLocalStorage(sortedColumns: ColumnSortingOrderInterface[], localStorageKey: string): void {
		this.localStorageService.set(localStorageKey, {
			sortedColumns: sortedColumns,
		});
	}

	public setFirstTimeLocalStorageColumnOrder(
		localStorageKey: string,
		sortedColumns: ColumnsInterface[],
		deprecatedLocalStorageKey?: string,
	): void {
		this.setColumnSortingToLocalStorage(sortedColumns, localStorageKey);
		this.deleteDeprecatedLocalStorageColumnOrder(deprecatedLocalStorageKey);
	}

	public hasColumnsSavedLocally(localStorageKey: string): boolean {
		return isValueSet(this.localStorageService.get(localStorageKey));
	}
}
