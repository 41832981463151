import { BrowserOptions } from '@sentry/browser';
import { environment } from '~/environments/environment';

/*

This file makes sure @sentry/browser isn't loaded until we actually want to report something to sentry

*/

const initialized = 'sentry_initialized';
const user = 'sentry_user';

const getSentry = async () => {
	const sentry = await import('@sentry/browser');
	if (!window[initialized]) {
		window[initialized] = true;
		const sentryOptions: BrowserOptions = {
			release: environment.release,
			environment: environment.name,
			dsn: environment.raven,
		};

		if (sentryOptions.release === '' || sentryOptions.release === '{BUILD_VERSION}') {
			sentryOptions.release = undefined;
		}
		sentry.init(sentryOptions);
	}

	sentry.setUser(window[user] || {});
	return sentry;
};

export const reportError = async (err: any) => {
	console.error(err);
	const sentry = await getSentry();
	sentry.captureException(err);
};

export const captureMessage = async (message: string) => {
	const sentry = await getSentry();
	sentry.captureMessage(message);
};

export const setUser = async (userData: any = {}) => {
	// Do not directly set the sentry user here!
	// If we would do that we would always load sentry on the application start even if we don't want to report any errors
	window[user] = userData;
};
