import { Component, Input } from '@angular/core';
import { isValueSet } from '#/util/values';
import { APINotificationsService } from '~/app/api/services/apinotifications.service';
import { arrayIsSetAndFilled } from '#/util/arrays';

interface ApiResponse {
	error: {
		code: number;
	};
}

@Component({
	selector: 'app-error-message',
	templateUrl: './error-message.component.html',
	styleUrls: ['./error-message.component.scss'],
})
export class ErrorMessageComponent {
	@Input() errorMessages: ApiResponse | Array<string> | string;
	public _errorMessages: Array<string>;

	constructor(private apiNotificationsService: APINotificationsService) {}

	ngOnInit() {
		const casted: any = this.errorMessages;
		const errorCode = casted?.error?.code;
		if (isValueSet(errorCode)) {
			this._errorMessages = [this.apiNotificationsService.getTranslatedApiErrorMessage(errorCode)];
			return;
		}

		if (typeof casted === 'string') {
			this._errorMessages = [casted];
			return;
		}

		if (arrayIsSetAndFilled(casted)) {
			this._errorMessages = casted;
			return;
		}

		this._errorMessages = [];
	}
}
