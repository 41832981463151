<ng-template [ngIf]="capabilities">
	<div class="modal-header">
		<h4 class="modal-title">{{ isOrg ? ('Create an organisation' | translate) : ('Create a person' | translate) }}</h4>
		<button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Close')">
			<span aria-hidden="true">&times;</span>
		</button>
	</div>
	<div class="modal-body" #modalBody>
		<div class="row">
			<div [ngClass]="files?.length > 0 ? 'col-md-6' : 'col-md-12'">
				<div class="form-group row" *ngIf="capabilities.CreateOrg && capabilities.CreatePerson">
					<div class="col-md-12">
						<div class="btn-group btn-group-toggle" ngbRadioGroup name="isOrg" [(ngModel)]="isOrg">
							<label ngbButtonLabel class="btn-outline-primary">
								<input ngbButton type="radio" [value]="false" />
								{{ 'Person' | translate }}
							</label>
							<label ngbButtonLabel class="btn-outline-primary">
								<input ngbButton type="radio" [value]="true" />
								{{ 'Organisation' | translate }}
							</label>
						</div>
					</div>
				</div>
				<app-integration-custom-relation-field
					style="margin-top: 10px"
					[field]="baseField"
					[isOrg]="isOrg"
					[integration]="integration"
					[division]="division"
				></app-integration-custom-relation-field>
			</div>
			<div class="col-md-6" *ngIf="files?.length > 0">
				<div style="display: flex; flex-direction: column; height: 100%">
					<div style="display: flex; justify-content: center">
						<button class="btn btn-sm" [disabled]="activeFileIndex == null || activeFileIndex <= 0" (click)="previousFile()">
							<span class="ti-angle-double-left"></span>
						</button>
						<button class="btn btn-sm" [disabled]="activeFileIndex == null || activeFileIndex >= files.length - 1" (click)="nextFile()">
							<span class="ti-angle-double-right"></span>
						</button>
					</div>
					<app-file-viewer-deprecated
						style="flex-grow: 1"
						*ngIf="activeFileIndex !== undefined && activeFileIndex !== null && files[activeFileIndex]"
						[blob]="files[activeFileIndex].blob"
						[filename]="files[activeFileIndex].filename"
						[type]="files[activeFileIndex].internal_type"
						[fileCount]="files.length"
						[currentFile]="activeFileIndex + 1"
						[isOCR]="false"
						[isUploader]="user.id === files[activeFileIndex].user"
						[virusScanResult]="files[activeFileIndex].virus_scan_result"
					>
					</app-file-viewer-deprecated>
				</div>
			</div>
		</div>
	</div>
	<div class="modal-footer">
		<button class="btn btn-default btn-sm" (click)="activeModal.dismiss('Close')" translate>Cancel</button>
		<button [disabled]="!fieldValid(baseField)" class="btn btn-primary btn-sm" (click)="create()" translate>Create</button>
	</div>
</ng-template>
