import { Pipe, PipeTransform } from '@angular/core';
import { AmountWithCurrency } from '#/models/transaction/amountWithCurrency';
import { isValueSet } from '#/util/values';
import { stringifyAmountWithCurrency } from '##/util/numbers';

@Pipe({
	name: 'amountWithCurrency',
})
export class AmountWithCurrencyPipe implements PipeTransform {
	transform(input: AmountWithCurrency, amountOfDecimals: number = 2): string {
		if (!isValueSet(input)) {
			return '';
		}

		return stringifyAmountWithCurrency(input.amount, input.currency, amountOfDecimals);
	}
}
