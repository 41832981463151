import { Component, EventEmitter, Output, ViewChild } from '@angular/core';
import { DataTableBase } from '~/app/shared/ui/data-tables/data-table-base';
import { MerchantService } from '~/app/modules/merchant/merchant.service';
import { TranslateService } from '@ngx-translate/core';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { RegistrationFrontendModel } from '#/models/transaction/registration/frontendModel';
import { RegistrationService, RegistrationsFilters } from '#/services/transaction/registration.service';
import { DataTableComponent } from 'wutu-data-table';
import { isValueSet, stringIsSetAndFilled } from '#/util/values';
import { CompanyService } from '#/services/company/company.service';
import { Company } from '#/models/company/company.model';
import { calculateDistance } from '#/util/distances';
import { DistanceUnit } from '#/models/distance-unit';
import { TranslationReplaceService } from '~/app/services/translation-replace.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { CompanyCategoryService } from '#/services/company/company-category.service';
import { RegistrationApiModel } from '#/models/transaction/registration/apiModel';

@Component({
	selector: 'app-personal-registrations-data-table',
	templateUrl: './registrations-data-table.component.html',
	styleUrls: ['./registrations-data-table.component.scss'],
})
export class PersonalRegistrationsDataTable extends DataTableBase<Record<string, any>> {
	@Output() onRowClicked = new EventEmitter<RegistrationFrontendModel>();
	@Output() onRegistrationDeleted = new EventEmitter<RegistrationFrontendModel>();
	@Output() onExportRegistrations = new EventEmitter<Array<string>>();
	@ViewChild(DataTableComponent) dataTable: DataTableComponent;

	public company: Company;
	public filtersForm: FormGroup = this.fb.group({
		type: null,
		user: null,
		fromDate: null,
		toDate: null,
		transportationTypes: null,
		administrations: null,
		categories: null,
		registrationTypes: null,
	});
	public isUserRegistration = (userId: string): boolean => {
		return isValueSet(userId);
	};

	constructor(
		private registrationService: RegistrationService,
		private merchantService: MerchantService,
		private translate: TranslateService,
		private companyService: CompanyService,
		private companyCategoryService: CompanyCategoryService,
		private translationReplaceService: TranslationReplaceService,
		private fb: FormBuilder,
	) {
		super();
	}

	public ngOnInit(): void {
		this.company = this.companyService.getCompanyOfLoggedUser();
	}
	public fetchItemsFn = async (
		start: number,
		searchQuery: string,
		itemsPerPage: number,
		sortField: string,
		sortOrder: 'ASC' | 'DESC',
		_filters: Record<string, any>,
	): Promise<{ totalAmount: number; data: Array<Record<string, any>> }> => {
		const filters = new RegistrationsFilters(_filters);
		const result = await this.registrationService.getPersonalMobilityRegistrations(
			start,
			searchQuery,
			itemsPerPage,
			sortField,
			sortOrder,
			filters,
		);
		const tableData = await this.getTableData(result.results);
		return {
			totalAmount: result.count,
			data: tableData,
		};
	};

	public _onRowClicked(row: RegistrationFrontendModel): void {
		this.onRowClicked.emit(row);
	}

	public async getTableData(result: Array<RegistrationApiModel>): Promise<Array<{ id: string; description: string }>> {
		const tableData = Promise.all(
			result.map(async (e: Record<string, any>) => {
				const registration = e.transformToFrontend();
				if (stringIsSetAndFilled(registration.category)) {
					registration.registrationType = await this.getRegistrationType(registration.category);
				}
				return registration;
			}),
		);
		return tableData;
	}

	public async getRegistrationType(categoryId: string): Promise<string> {
		return this.companyCategoryService.getCompanyCategoriesByIds([categoryId]).then((categories) => {
			return categories[0].registrationType;
		});
	}

	public displayDistanceInCompanyUnit(distance: number): number {
		if (this.companyService.getCompanyOfLoggedUser()?.defaultpreferences?.show_miles_instead_of_km) {
			return calculateDistance(distance, DistanceUnit.MILE);
		}
		return distance;
	}

	public getActionsForRowFn = (
		row: RegistrationFrontendModel,
	): Array<{
		caption: string;
		action: () => void;
	}> => {
		return [
			{
				caption: this.translate.instant(_('Delete')),
				action: async () => {
					await this.registrationService.deletePersonalRegistration(row.id);
					this.refreshTable();
				},
			},
		];
	};

	public getActionsForMultipleRowsFn = (
		rows: Array<RegistrationFrontendModel>,
	): Array<{
		caption: string;
		action: () => void;
	}> => {
		return [
			{
				caption: this.translate.instant(_('Delete')),
				action: () => {
					const promises = rows.map((row) => this.registrationService.deletePersonalRegistration(row.id));
					Promise.allSettled(promises).then(() => this.refreshTable());
				},
			},
		];
	};

	private refreshTable(): void {
		this.dataTable._ext_refreshTable();
	}

	public resetFilters(): void {
		this.filtersForm.reset();
		this.dataTable._ext_setFilters(this.filtersForm.getRawValue());
	}

	public onSubmit = async (values) => {
		this.dataTable._ext_setFilters(values);
	};
}
