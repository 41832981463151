import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AuthenticatedComponent } from '../authenticated.component';
import { needsReceiptListUpdate, WSMessage } from '#/models/websocket.model';
import { AddReceipts, ClearReceipts } from '~/app/modules/receipt/models/receipt.actions';
import { Receipt, ReceiptListAPIRequest } from '#/models/transaction/receipt';
import { ActivatedRoute, Params } from '@angular/router';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { ConfirmModalComponent } from '~/app/shared/ui/confirm-modal/confirm-modal.component';
import { ReceiptFlowService } from '~/app/modules/receipt/receipt-flow.service';
import { CompanyModuleReceiptPresetsPreset } from '#/models/company/company.model';
import { cloneDeep } from 'lodash';
import { importReceiptModalInBackground } from '~/app/modules/receipt/components/modals/lazy';
import { ReceiptFiltersService, SEARCH_FILTER_LOCALSTORAGE_KEY } from '~/app/modules/receipt/receipt-filters.service';
import { ExpenseReportsService } from '#/services/transaction/expense-reports.service';
import { InterfaceFrontendModel as TransactionInterfaceModel } from '#/models/transaction/interface/frontendModel';
import { TransactionInterfaceService } from '#/services/transaction/transaction-interface.service';
import { PossibleCompanyFeatureFlags } from '#/models/company/possible-feature-flags';
import { CompanyFeatureFlagsService } from '#/services/company/company-feature-flags.service';
import { Order } from '#/models/utils/order';
import { isValueSet, stringIsSetAndFilled } from '#/util/values';
import { TransactionEditorService } from '#/services/transaction/transaction-editor.service';
import { PaginatedExpenseReports, PaginatedExpenseReportsRequest } from '#/models/transaction/expense-reports';
import { TransactionType } from '#/models/transaction/transactionType';
import { PageStateService } from '#/services/util/page-state.service';
import { debounceTime, filter } from 'rxjs/operators';
import { combineLatest, Subscription } from 'rxjs';
import { RouteUtilsService } from '#/services/util/route-utils.service';

@Component({
	templateUrl: 'dashboard.html',
	styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent extends AuthenticatedComponent implements OnInit, OnDestroy {
	@ViewChild('receiptsTable') receiptsTable;
	public receipts: Array<Receipt> = [];
	public receiptsPerPage = 25;
	public currentPage = 1;
	public receiptCount = 0;
	public initialLoaded = false;
	public checkboxes: {};
	public checkAll: { checked: boolean };
	public filtered = false;
	public presetActions: CompanyModuleReceiptPresetsPreset[] = [];

	public reportDeclarationsPromise: Promise<PaginatedExpenseReports>;
	public reportDeclarationsModuleEnabled: boolean;
	public transactionInterfaces: Array<TransactionInterfaceModel> = undefined;
	private combinedRoutesSubscription: Subscription;

	constructor(
		protected route: ActivatedRoute,
		protected receiptFlowService: ReceiptFlowService,
		protected receiptFiltersService: ReceiptFiltersService,
		private expenseReportsService: ExpenseReportsService,
		private transactionInterfaceService: TransactionInterfaceService,
		private companyFeatureFlagsService: CompanyFeatureFlagsService,
		private transactionEditorService: TransactionEditorService,
		private pageStateService: PageStateService,
		private routeUtilsService: RouteUtilsService,
	) {
		super();
		this.checkboxes = {};
		this.checkAll = { checked: false };
	}

	ngOnInit(): void {
		super.ngOnInit();
		const companySubscription = this.store.select('company').subscribe((val) => {
			this.company = val.company;
			this.reportDeclarationsModuleEnabled = val?.company?.modules?.report?.enabled === true;
			if (isValueSet(this.company)) {
				this.companyFeatureFlagsService.getAllFeatureFlags(this.company.id).then((flags) => {
					if (flags.includes(PossibleCompanyFeatureFlags.TRANSACTION_INTERFACES)) {
						this.transactionInterfaceService.getInterfaces(true, false, true, null, true).then((res) => {
							this.transactionInterfaces = res.filter((e) => {
								switch (e.transactionType) {
									case TransactionType.Receipt:
									case TransactionType.FixedCompensation:
										return true;
									default:
										return false;
								}
							});
						});
					}
				});
			}
		});

		if (this.reportDeclarationsModuleEnabled) {
			this.reportDeclarationsPromise = this.expenseReportsService.getExpenseReports(new PaginatedExpenseReportsRequest());
		}

		this.destroyCallbacks.push(() => {
			companySubscription.unsubscribe();
		});

		const receiptsPerPage = this.localStorageService.get('dashboard_receipts_per_page') as number;
		if (receiptsPerPage != null) {
			this.receiptsPerPage = Number(receiptsPerPage);
		}

		this.filters = new ReceiptListAPIRequest();
		this.filters.sortorder = [Order.DESCENDING, Order.DESCENDING];
		this.filters.sort = 'needs_ocr_review,purchasedate';
		this.defaultFilters = this.filters.clone();

		this.filters = Object.assign(this.filters, new ReceiptListAPIRequest(JSON.parse(this.localStorageService.get('filters-personal'))));
		if (!stringIsSetAndFilled(this.filters.search) && stringIsSetAndFilled(this.localStorageService.get(SEARCH_FILTER_LOCALSTORAGE_KEY))) {
			this.filters.search = this.localStorageService.get(SEARCH_FILTER_LOCALSTORAGE_KEY);
		}

		this.pageStateService.initPageState();
		this.addPresetActions();

		this.combinedRoutesSubscription = combineLatest([this.route.params, this.route.queryParams], (params: Params, queryParams: Params) => ({
			params,
			queryParams,
		})).subscribe(async (res: { params: Params; queryParams: Params }) => {
			if (res.params.folder) {
				this.filters.group = res.params.folder;
				this.filtered = true;
			}

			if (res.params.tag) {
				this.filters.tag = (window as any).decodeURIComponent(res.params.tag);
				this.filtered = true;
			}
			await this.updateStateBasedOnQueryParams();
			this.loadReceipts();
		});

		const websocketSubscription = this.websocketsService.onMessage
			.pipe(
				filter((msg) => needsReceiptListUpdate(msg.type)),
				debounceTime(300),
			)
			.subscribe((message: WSMessage) => {
				// only reload the table if we have no modals open
				if (this.pageStateService.isPageStateSaved()) {
					this.currentPage = this.pageStateService.getPageNumber();
				}
				this.loadReceipts();
			});

		this.destroyCallbacks.push(() => {
			websocketSubscription.unsubscribe();
		});

		this.on(
			this.receiptFiltersService.searchSubject.subscribe(() => {
				this.updateFilters();
			}),
		);

		importReceiptModalInBackground();
	}

	private async updateStateBasedOnQueryParams(): Promise<void> {
		if (stringIsSetAndFilled(this.route.snapshot.queryParams.itemsPerPage)) {
			this.filters.max = Number(this.route.snapshot.queryParams.itemsPerPage);
			this.receiptsPerPage = Number(this.route.snapshot.queryParams.itemsPerPage);
		}
		if (stringIsSetAndFilled(this.route.snapshot.queryParams.page)) {
			this.filters.start = (Number(this.route.snapshot.queryParams.page) - 1) * (this.filters.max ?? this.receiptsPerPage);
			this.currentPage = Number(this.route.snapshot.queryParams.page);
		}
		if (stringIsSetAndFilled(this.route.snapshot.queryParams.sort)) {
			this.filters.sort = this.route.snapshot.queryParams.sort;
		}

		if (stringIsSetAndFilled(this.route.snapshot.queryParams.sortOrder)) {
			this.filters.sortorder = this.route.snapshot.queryParams.sortOrder;
		}
	}

	get filters() {
		return this.receiptFiltersService.filters;
	}

	get defaultFilters() {
		return this.receiptFiltersService.defaultFilters;
	}

	set filters(filters) {
		this.receiptFiltersService.filters = filters;
	}

	set defaultFilters(filters) {
		this.receiptFiltersService.defaultFilters = filters;
	}

	addPresetActions() {
		this.presetActions = CompanyModuleReceiptPresetsPreset.fromData(null);
		if (
			this.company &&
			this.company.modules.receipt_presets &&
			this.company.modules.receipt_presets.enabled &&
			this.company.modules.receipt_presets.presets &&
			this.company.modules.receipt_presets.presets.length > 0
		) {
			this.company.modules.receipt_presets.presets.forEach((preset) => {
				if (preset.hide_in_menu) {
					return;
				}
				this.presetActions.push(preset);
			});
		}
	}

	public updateReceiptsPerPage(): void {
		this.localStorageService.set('dashboard_receipts_per_page', this.receiptsPerPage);
		this.currentPage = 1;
		this.updateQueryParams({ itemsPerPage: this.receiptsPerPage, page: this.currentPage });
		this.checkAll = { checked: false };
		this.checkboxes = {};
		this.loadReceipts();
	}

	public updateFilters(): void {
		this.currentPage = 1;
		this.updateQueryParams({ page: this.currentPage });
		this.filtered = true;
		this.checkAll = { checked: false };
		this.checkboxes = {};
		this.loadReceipts();
	}

	public loadReceipts(): void {
		this.filters.max = this.receiptsPerPage;
		this.filters.start = (this.currentPage - 1) * this.receiptsPerPage;
		this.filters.countActiveFilters('user', this.defaultFilters);
		this.transactionEditorService.setLatestDashboardFilters(this.filters);
		this.receiptAPIService.getReceipts(this.filters).then((res) => {
			this.receiptCount = res.data?.count;
			const receipts = res.data?.receipts?.map((r) => new Receipt(r)) ?? [];

			this.receipts = receipts;
			this.store.dispatch(new ClearReceipts());
			this.store.dispatch(new AddReceipts({ receipts: receipts }));
			if (this.initialLoaded === false) {
				this.initialLoaded = true;
			}
		});
	}

	pageChanged(newPage: number) {
		this.currentPage = newPage;
		this.checkAll = { checked: false };
		this.pageStateService.setCurrentPage(newPage);
		this.loadReceipts();
	}

	currentPageReceipts(): number {
		return Math.min(this.currentPage * this.receiptsPerPage, this.receiptCount);
	}

	selectAllReceiptsPage(filters: ReceiptListAPIRequest, per_page: number = 100, page: number = 1) {
		filters.max = per_page;
		filters.start = (page - 1) * per_page;
		this.receiptAPIService.getReceipts(filters).then((r) => {
			const Receipts: any[] = r['data']['receipts'];
			const newReceipts: Receipt[] = [];
			if (Receipts != null) {
				Receipts.forEach((receipt) => {
					this.checkboxes[receipt.id] = true;
				});
			}
			if (r['data']['moreresults']) {
				return this.selectAllReceiptsPage(filters, per_page, page + 1);
			}
		});
	}

	getSelectedCount(): number {
		return Object.keys(this.checkboxes).filter((checkbox) => this.checkboxes[checkbox]).length;
	}

	deselectAllSelected() {
		this.checkboxes = {};
		this.checkAll = { checked: false };
	}

	selectedAllReceipts() {
		this.checkAll = { checked: true };
		const filters = cloneDeep(this.filters);
		this.selectAllReceiptsPage(filters, 50);
	}

	async addReceipt(new_receipt_type: string, preset: CompanyModuleReceiptPresetsPreset = null) {
		this.receiptFlowService.initWithPreset(preset, this.user);

		const { ReceiptEditModalComponent } = await import(
			'~/app/modules/receipt/components/modals/receipt-edit-modal/receipt-edit-modal.component'
		);

		const modalRef = this.modalService.open(ReceiptEditModalComponent, {
			size: 'lg',
			beforeDismiss: () => {
				if (modalRef.componentInstance.hasReceiptBeenChanged()) {
					return new Promise((resolve, reject) => {
						const confirmModalRef = this.modalService.open(ConfirmModalComponent);
						confirmModalRef.componentInstance.type = 'text';
						confirmModalRef.componentInstance.title = this.translate.instant(_('Dismiss changes'));
						confirmModalRef.componentInstance.message = this.translate.instant(_('Are you sure that you want to dismiss your changes?'));
						const sub = confirmModalRef.componentInstance.result.subscribe((data) => {
							resolve(data.result);
							sub.unsubscribe();
						});
						this.destroyCallbacks.push(() => {
							sub.unsubscribe();
						});
					});
				}
			},
		});
		/* If this receipt is being added from a preset, we need to bind on the receipt flow service receipt object. */
		modalRef.componentInstance.receipt = preset ? this.receiptFlowService.currentReceipt : null;
		modalRef.componentInstance.user = this.user;
		modalRef.componentInstance.new_receipt_type = new_receipt_type;
		modalRef.componentInstance.tableType = 'personal';
		modalRef.componentInstance.isNew = true;

		if (this.route.snapshot.paramMap.has('folder') && this.route.snapshot.paramMap.get('folder') !== '') {
			modalRef.componentInstance.new_receipt_folder = this.route.snapshot.paramMap.get('folder');
		}

		try {
			await modalRef.result;
		} finally {
			if (this.reportDeclarationsModuleEnabled) {
				this.reportDeclarationsPromise = this.expenseReportsService.getExpenseReports(new PaginatedExpenseReportsRequest());
			}
			this.loadReceipts();
		}
	}

	async bulkUpload() {
		const { BulkUploadModalComponent } = await import(
			'../../../modules/receipt/components/modals/bulk-upload-modal/bulk-upload-modal.component'
		);

		const modalRef = this.modalService.open(BulkUploadModalComponent);
		modalRef.componentInstance.user = this.user;
		if (this.route.snapshot.paramMap.has('folder') && this.route.snapshot.paramMap.get('folder') !== '') {
			modalRef.componentInstance.new_receipt_folder = this.route.snapshot.paramMap.get('folder');
		}

		modalRef.result
			.then(() => {
				this.loadReceipts();
			})
			.catch(() => {
				this.loadReceipts();
			});

		const doneSubscription = modalRef.componentInstance.done.subscribe(() => {
			this.loadReceipts();
		});

		this.destroyCallbacks.push(() => {
			if (doneSubscription) {
				doneSubscription.unsubscribe();
			}
		});
	}

	async documentSplit() {
		const { DocumentSplitModalComponent } = await import(
			'../../../modules/receipt/components/modals/document-split-modal/document-split-modal.component'
		);

		const modalRef = this.modalService.open(DocumentSplitModalComponent);
		modalRef.componentInstance.user = this.user;
		if (this.route.snapshot.paramMap.has('folder') && this.route.snapshot.paramMap.get('folder') !== '') {
			modalRef.componentInstance.new_receipt_folder = this.route.snapshot.paramMap.get('folder');
		}

		modalRef.result
			.then(() => {
				this.loadReceipts();
			})
			.catch(() => {
				this.loadReceipts();
			});

		const doneSubscription = modalRef.componentInstance.done.subscribe(() => {
			this.loadReceipts();
		});

		this.destroyCallbacks.push(() => {
			if (doneSubscription) {
				doneSubscription.unsubscribe();
			}
		});
	}

	resendActivationMail() {
		this.userAPIService
			.requestValidationMail(this.user.getEmail())
			.then((r) => {
				const message = this.getTranslation(_('The activation email has successfully been resent.'));
				this.notificationService.success(message);
			})
			.catch((e) => {});
	}

	showAddDropDown(): boolean {
		if (this.user.canUseTravelDeclaration() && this.user.getPreferences().receipt_type) {
			return true;
		}

		if (this.user.canDocumentSplit()) {
			return true;
		}

		if (this.user.canBulkUpload()) {
			return true;
		}

		return false;
	}

	public updateQueryParams(newParams: Record<string, any>): void {
		const newUrl: string = this.routeUtilsService.getUpdateRouteWithNewParamValue(
			this.router.url.split('?')[0],
			this.route.snapshot.queryParams,
			newParams,
		);
		this.router.navigateByUrl(newUrl);
	}

	public updateRouteWithSortingParams(event: { sort: string; sortOrder: Order }): void {
		const newUrl: string = this.routeUtilsService.getUpdateRouteWithNewParamValue(
			this.router.url.split('?')[0],
			this.route.snapshot.queryParams,
			{
				sort: event.sort,
				sortOrder: event.sortOrder,
				page: 1,
			},
		);
		this.router.navigateByUrl(newUrl);
	}

	public ngOnDestroy(): void {
		super.ngOnDestroy();
		this.combinedRoutesSubscription.unsubscribe();
	}
}
