import { Icon } from './icon';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { IconActionsUnion, IconActionTypes } from './icon.actions';
import { createSelector } from '@ngrx/store';
import { Receipt } from '#/models/transaction/receipt';

export interface IconState extends EntityState<Icon> {
	currentPage: number | null;
	totalPages: number | null;
}

export const adapter: EntityAdapter<Icon> = createEntityAdapter<Icon>();

export const initialState: IconState = adapter.getInitialState({
	currentPage: null,
	totalPages: null,
});

export function iconReducer(state = initialState, action: IconActionsUnion): IconState {
	switch (action.type) {
		case IconActionTypes.ADD_ICON: {
			return adapter.addOne(action.payload.icon, state);
		}

		case IconActionTypes.UPSERT_ICON: {
			return adapter.upsertOne(action.payload.icon, state);
		}

		case IconActionTypes.ADD_ICONS: {
			return adapter.addMany(action.payload.icons, state);
		}

		case IconActionTypes.UPSERT_ICONS: {
			return adapter.upsertMany(action.payload.icons, state);
		}

		case IconActionTypes.UPDATE_ICON: {
			return adapter.updateOne(action.payload.icon, state);
		}

		case IconActionTypes.UPDATE_ICONS: {
			return adapter.updateMany(action.payload.icons, state);
		}

		case IconActionTypes.DELETE_ICON: {
			return adapter.removeOne(action.payload.id, state);
		}

		case IconActionTypes.DELETE_ICONS: {
			return adapter.removeMany(action.payload.ids, state);
		}

		case IconActionTypes.LOAD_ICONS: {
			return adapter.addAll(action.payload.icons, state);
		}

		case IconActionTypes.CLEAR_ICONS: {
			return adapter.removeAll({ ...state, selectedIconId: null });
		}

		default: {
			return state;
		}
	}
}

const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors();

// select the array of icon ids
export const selectIconIds = selectIds;

// select the dictionary of icon entities
export const selectIconEntities = selectEntities;

// select the array of icons
export const selectAllIcons = selectAll;

// select the total icon count
export const selectIconTotal = selectTotal;

export const getItemById = (id: string) => createSelector(selectEntities, (allItems) => allItems[id] || null);

export const matchOnDescription = (description: string) =>
	createSelector(selectAll, (allItems) => {
		if (description !== '') {
			// Split up the description of the receipt into separate words.
			const receipt_keywords = description.trim().toLowerCase().split(' ');
			if (receipt_keywords.length > 0) {
				// Loop through the receipt description words.
				for (const keyword of receipt_keywords) {
					// Loop through all available icons.
					for (const icon of allItems) {
						// Loop through all keywords of this item.
						for (const icon_keyword of icon.keywords) {
							// Match icon keyword on description keyword, return first match.
							if (icon_keyword.toLowerCase() === keyword) {
								return icon;
							}
						}
					}
				}
			}
		}

		return null;
	});
