import { Injector, NgModule } from '@angular/core';
import { ReceiptsTableComponent } from './components/receipts-table/receipts-table.component';
import { ReceiptsTableRowComponent } from './components/receipts-table-row/receipts-table-row.component';
import { CommonModule } from '@angular/common';
import { FolderModule } from '../folder/folder.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SortablejsModule } from 'ngx-sortablejs';
import { TranslateModule } from '@ngx-translate/core';
import { ReceiptBookingStatusComponent } from './components/receipt-booking-status/receipt-booking-status.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { UiModule } from '../../shared/ui/ui.module';
import { CompanyUI } from '../company/components/ui/ui.module';
import { PipesModule } from '../../helpers/pipes/pipes.module';
import { ReceiptsTableReportComponent } from '~/app/modules/receipt/components/receipts-table-report/receipts-table-report.component';
import { DirectivesModule } from '~/app/directives/directives.module';
import { ReceiptsModalBaseComponent } from './components/modals/receipts-modal-base/receipts-modal-base';
import { MerchantModule } from '~/app/modules/merchant/merchant.module';
import { DuplicateDocumentWarningDeprecatedComponent } from './components/duplicate-document-warning-deprecated/duplicate-document-warning-deprecated.component';
import { ExportModule } from '~/app/modules/export/export.module';

@NgModule({
	imports: [
		CommonModule,
		FolderModule,
		FormsModule,
		ReactiveFormsModule,
		SortablejsModule,
		TranslateModule,
		NgSelectModule,
		NgbModule,
		UiModule,
		CompanyUI,
		PipesModule,
		DirectivesModule,
		MerchantModule,
		ExportModule,
	],
	declarations: [
		ReceiptsTableRowComponent,
		ReceiptsTableReportComponent,
		ReceiptsTableComponent,
		ReceiptBookingStatusComponent,
		DuplicateDocumentWarningDeprecatedComponent,
		ReceiptsModalBaseComponent,
	],
	exports: [
		ReceiptsTableRowComponent,
		ReceiptsTableReportComponent,
		ReceiptsTableComponent,
		ReceiptBookingStatusComponent,
		DuplicateDocumentWarningDeprecatedComponent,
		ReceiptsModalBaseComponent,
	],
})
export class ReceiptModule {
	constructor(private injector: Injector) {
		ReceiptsModalBaseComponent.injector = this.injector;
	}
}
