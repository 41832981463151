import { frontendToApi } from './transformer';
import { CategoryApiModel } from '#/models/transaction/category/apiModel';
import { CategoryAccountingDefaults, CategoryTransactionType } from '#/models/company/category.model';
import { CategoryDefaultsFrontendModel } from '#/models/transaction/category/categoryDefaults/frontendModel';
import { DistanceUnit } from '#/models/distance-unit';
import { AmountWithCurrency } from '#/models/transaction/amountWithCurrency';

export class CategoryFrontendModel {
	id: string;
	code?: string;
	name?: string;
	types?: Array<CategoryTransactionType>;
	registrationType?: string;
	createDate?: Date;
	updateDate?: Date;
	groups?: Array<string>;
	active?: boolean;
	description?: string;
	merchants?: Array<string>;
	authorizationId: string;
	aav1Defaults?: CategoryDefaultsFrontendModel;
	aav2Defaults?: CategoryAccountingDefaults;
	vatDeductible?: boolean;
	travelExpenseCompensationPerKmInCents?: AmountWithCurrency;
	travelExpenseCompensationPerMileInCents?: AmountWithCurrency;
	fromDate?: Date;
	toDate?: Date;

	constructor(data?: any) {
		if (data) {
			Object.assign(this, data);
		}
	}

	transformToApi(distanceUnit: DistanceUnit): CategoryApiModel {
		return frontendToApi(this, distanceUnit);
	}
}
