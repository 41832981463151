import { ActivatedRouteSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
import { ExpenseReportsService } from '#/services/transaction/expense-reports.service';
import { DeclarationStatusFlag } from '#/models/transaction/receipt';
import { ExpenseReport } from '#/models/transaction/expense-reports';

@Injectable({ providedIn: 'root' })
export class ReportListTabVisibilityResolver {
	constructor(private expenseReportsService: ExpenseReportsService) {}

	resolve(route: ActivatedRouteSnapshot): Promise<boolean> {
		return this.expenseReportsService.getExpenseReport(route.paramMap.get('id')).then((report: ExpenseReport) => {
			if (
				report.status_to_show === DeclarationStatusFlag.NeedsInformation ||
				report.status_to_show === DeclarationStatusFlag.NotSubmitted
			) {
				return true;
			}

			return false;
		});
	}
}
