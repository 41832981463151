<div>
	<ngx-async-template [promise]="enabledTOTPOptionsPromise">
		<ng-template ngx-async-inactive ngx-async-pending>
			<klp-form-loading-indicator></klp-form-loading-indicator>
		</ng-template>

		<ng-template ngx-async-error>
			<app-error-message
				[errorMessages]="
					'Something went wrong while fetching the two-factor authentication options, please reload the page and try again.' | translate
				"
			></app-error-message>
		</ng-template>

		<ng-template ngx-async-success let-value="value">
			<section class="enableMFASection" *ngIf="value.length === 0">
				<ngx-async-template [promise]="sendVerifyCodeToEnableTOTPViaEmailPromise">
					<ng-template ngx-async-inactive ngx-async-error let-status="status">
						<!-- Section where user enables 2fa by first sending a verification code to their email -->
						<section class="mfaSection">
							<header translate>Two-factor authentication is not enabled yet.</header>
							<div class="description" translate>
								Two-factor authentication adds an additional layer of security to your account by requiring more than just a password to log
								in.
							</div>
							<div class="formButtonContainer">
								<app-button class="enableButton" (click)="sendVerifyCodeToEnableTOTPViaEmail()" variant="greenFilled">
									<span translate>Enable two-factor authentication</span>
								</app-button>
							</div>
							<ng-container *ngIf="status === 'error'">
								<app-error-message
									[errorMessages]="'Something went wrong while enabling two-factor authentication, please try again.' | translate"
								></app-error-message>
							</ng-container>
						</section>
					</ng-template>

					<ng-template ngx-async-success>
						<ngx-async-template [promise]="confirmTOTPViaEmailPromise">
							<ng-template ngx-async-inactive ngx-async-error let-status="status">
								<!-- The verification code has been sent, this section is a form where the user enters this code -->
								<section class="emailConfirmSection">
									<header translate>Enter code sent by email</header>
									<div class="description" translate>
										For security reasons, we have sent you a code via email. This code is valid for 5 minutes. Please enter the code below
										to continue the configuration of two-factor authentication.
									</div>
									<klp-form [formGroup]="emailConfirmTOTPViaEmailForm">
										<klp-form-element class="emailVerificationCodeInput" [caption]="'Verification code' | translate" direction="vertical">
											<klp-form-text-input
												formControlName="emailVerificationCode"
												[placeholder]="'Enter the verification code' | translate"
											></klp-form-text-input>
										</klp-form-element>
										<klp-form-submit-button [submitCallback]="confirmTOTPViaEmailAndEnableTOTP">{{
											'Confirm two-factor authentication' | translate
										}}</klp-form-submit-button>
									</klp-form>
									<ng-container *ngIf="status === 'error'">
										<app-error-message
											[errorMessages]="
												'Something went wrong while enabling two-factor authentication, did you enter the correct verification code?'
													| translate
											"
										></app-error-message>
									</ng-container>
								</section>
							</ng-template>

							<ng-template ngx-async-success>
								<ngx-async-template [promise]="totpEnablePromise">
									<ng-template ngx-async-success let-value="value">
										<!-- The verification code was correct, the user can now enable 2fa -->
										<section class="qrCodeSection">
											<div class="description" translate>
												Scan the image below with a two-factor authentication app (e.g. Google Authenticator) on your phone.
											</div>
											<div class="qrCodeContainer">
												<div class="qrImageContainer">
													<img [src]="QRImgUrl" />
												</div>
												<section class="qrCodeAlternative">
													<div translate>Can't scan the code?</div>
													<div translate>To add the entry manually, provide the following details to the application on your phone.</div>
													<div><span translate>Account</span>: {{ value.accountName }}</div>
													<div><span translate>Key</span>: {{ value.setupKey }}</div>
													<div><span translate>Time based</span>: Yes</div>
												</section>
											</div>
											<div class="authenticatorResponse">
												<header translate>Enter the six-digit code from the application</header>
												<div class="description" translate>
													After scanning the barcode image, the app will display a six-digit code that you can enter below.
												</div>
												<div class="authenticatorInputContainer">
													<klp-form-text-input class="authenticatorInput" [(ngModel)]="token"></klp-form-text-input>
													<app-button class="confirmButton" variant="greenFilled" (click)="confirmTOTP()">
														<span translate>Confirm</span>
													</app-button>
												</div>
											</div>
										</section>
									</ng-template>
								</ngx-async-template>
							</ng-template>
						</ngx-async-template>
					</ng-template>
				</ngx-async-template>

				<ngx-async-template [promise]="totpConfirmPromise">
					<ng-template ngx-async-error let-errorMessages="errorMessages">
						<app-error-message [errorMessages]="errorMessages"></app-error-message>
					</ng-template>
				</ngx-async-template>
			</section>

			<section *ngIf="value.length > 0">
				<section>
					<div translate>Two-factor authentication is currently on</div>
					<klp-form [formGroup]="disableTOTPForm">
						<klp-form-element direction="vertical" [caption]="'Enter your 2fa code' | translate">
							<klp-form-text-input formControlName="mfaKey"></klp-form-text-input>
						</klp-form-element>
						<klp-form-element direction="vertical" [caption]="'Use recovery code' | translate">
							<klp-form-checkbox formControlName="useRecoveryKey"></klp-form-checkbox>
						</klp-form-element>
						<klp-form-submit-button class="disable2faBtn" variant="redFilled" [submitCallback]="disableTOTP">
							<span translate>Disable 2FA</span>
						</klp-form-submit-button>
					</klp-form>
				</section>

				<div class="backupCodesContainer" *ngIf="!backupCodesAlreadyGenerated">
					<header translate>Two-factor recovery codes</header>
					<div class="description" translate>
						Recovery codes can be used to access your account in the event you lose access to your device and cannot receive two-factor
						authentication codes.
					</div>
					<ngx-async-template [promise]="backupCodesPromise">
						<ng-template ngx-async-inactive>
							<app-button class="generateBackupCodeButton" variant="white" (click)="generateBackupCodes()" translate
								>Generate recovery codes</app-button
							>
						</ng-template>
						<ng-template ngx-async-success let-value="value">
							<div class="backupCodesListContainer">
								<div translate class="recoveryWarning">
									Treat your recovery codes with the same level of attention as you would your password! Put these in a safe spot. If you
									lose your device and don’t have the recovery codes you will lose access to your account. This is the only time we show you
									these codes.
								</div>
								<ul class="codesList">
									<li *ngFor="let code of value">{{ code }}</li>
								</ul>
							</div>
						</ng-template>
					</ngx-async-template>
				</div>
			</section>
		</ng-template>
	</ngx-async-template>
</div>
