<ng-template #modal>
	<div class="modalHeader" *ngIf="header?.toArray().length > 0">
		<h4 class="modalTitle">
			<ng-container *ngFor="let tpl of header?.toArray()" [ngTemplateOutlet]="tpl"></ng-container>
		</h4>
		<button class="closeButton" (click)="dismissModal()" data-cy="btnCloseModal">&times;</button>
	</div>

	<div class="modalBody" [ngClass]="modalBodyFullHeight ? 'fullHeight' : ''">
		<ng-container *ngFor="let tpl of body?.toArray()" [ngTemplateOutlet]="tpl"></ng-container>
	</div>

	<div class="modalFooter" *ngIf="footer?.toArray().length > 0">
		<ng-container *ngFor="let tpl of footer?.toArray()" [ngTemplateOutlet]="tpl"></ng-container>
	</div>
</ng-template>
