export enum AttachmentType {
	IMAGE = 'image',
	DOCUMENT = 'document',
	OCR_ENHANCED_FILE = 'ocrEnhancedImages',
}
export class Attachment {
	content_hash?: string;
	filename: string;
	hash?: string;
	id: string;
	user: string;
	type: AttachmentType;

	constructor(data = null) {
		if (data) {
			Object.assign(this, data);
		}
	}

	static fromDocument(data) {
		const obj = new Attachment(data);
		obj.type = AttachmentType.DOCUMENT;
		return obj;
	}
	static fromImage(data) {
		const obj = new Attachment(data);
		obj.type = AttachmentType.IMAGE;
		return obj;
	}

	static fromOcrEnhancedFile(data): Attachment {
		//TODO there is a ticket for be to add fileName to the ocr enhanced images object
		const obj = new Attachment();
		obj.type = AttachmentType.OCR_ENHANCED_FILE;
		obj.id = data;
		return obj;
	}
}
