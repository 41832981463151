import { CategoryDefaultsApiModel } from '#/models/transaction/category/categoryDefaults/apiModel';
import { categoryDefaultsFrontendToApi } from '#/models/transaction/category/categoryDefaults/transformer';

export class CategoryDefaultsFrontendModel {
	division: string;
	ledger: string;
	glAccount: string;
	journal: string;
	vatCode: string;

	constructor(data?: any) {
		if (data) {
			Object.assign(this, data);
		}
	}

	transformToApi(): CategoryDefaultsApiModel {
		return categoryDefaultsFrontendToApi(this);
	}
}
