import { Component, EventEmitter, Injector, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ReceiptService } from '#/services/transaction/receipt.service';
import { User } from '#/models/user/user.model';
import { NotificationService } from '~/app/services/notification.service';
import { TranslateService } from '@ngx-translate/core';
import { UntypedFormBuilder } from '@angular/forms';
import { Store } from '@ngrx/store';
import { AppState } from '~/app/reducers';
import { StatisticsService } from '~/app/pages/authenticated/statistics/statistics.service';
import { Folder } from '#/models/user/folder';
import { FolderService } from '../../../../folder/folder.service';
import { DomSanitizer } from '@angular/platform-browser';
import { ModalMessages } from '~/app/helpers/strings';
import { AddFolder } from '../../../../folder/models/folder.actions';
import { MerchantService } from '../../../../merchant/merchant.service';
import { Company } from '#/models/company/company.model';
import { OnDismissHandler } from '#/models/utils/generic';
import { FileService } from '#/services/file.service';
import { CompanyApiService } from '#/services/company/company-api.service';

@Component({
	selector: 'app-receipts-modal-base-component',
	template: '',
})
export class ReceiptsModalBaseComponent implements OnInit, OnDestroy, OnDismissHandler {
	static injector: Injector;

	@Input() checkboxes: {};
	@Input() user: User;
	@Output() done = new EventEmitter<any>();

	protected notificationService: NotificationService;
	protected receiptAPIService: ReceiptService;
	protected fileService: FileService;
	protected formBuilder: UntypedFormBuilder;
	protected store: Store<AppState>;
	protected translate: TranslateService;
	protected statsService: StatisticsService;
	protected folderService: FolderService;
	protected domSanitizer: DomSanitizer;
	protected companyAPIService: CompanyApiService;
	protected merchantAPIService: MerchantService;

	protected company: Company;

	public inProgress = false;
	protected currentReceipt = 0;
	protected failedReceipts = 0;
	protected succeededReceipts = 0;

	protected destroyCallbacks = [];

	constructor(public activeModal: NgbActiveModal) {
		this.notificationService = ReceiptsModalBaseComponent.injector.get(NotificationService);
		this.receiptAPIService = ReceiptsModalBaseComponent.injector.get(ReceiptService);
		this.fileService = ReceiptsModalBaseComponent.injector.get(FileService);
		this.companyAPIService = ReceiptsModalBaseComponent.injector.get(CompanyApiService);
		this.merchantAPIService = ReceiptsModalBaseComponent.injector.get(MerchantService);
		this.formBuilder = ReceiptsModalBaseComponent.injector.get(UntypedFormBuilder);
		this.store = ReceiptsModalBaseComponent.injector.get(Store);
		this.translate = ReceiptsModalBaseComponent.injector.get(TranslateService);
		this.statsService = ReceiptsModalBaseComponent.injector.get(StatisticsService);
		this.folderService = ReceiptsModalBaseComponent.injector.get(FolderService);
		this.domSanitizer = ReceiptsModalBaseComponent.injector.get(DomSanitizer);
	}

	ngOnInit(): void {
		const companySubscription = this.store.select('company').subscribe((val) => {
			this.company = val.company;
		});
		this.destroyCallbacks.push(() => {
			companySubscription.unsubscribe();
		});
	}

	ngOnDestroy(): void {
		this.destroyCallbacks.forEach((cb) => cb());
	}

	isAdministrationOffice(): boolean {
		return this.company && this.company.isadministrationoffice;
	}

	getSelectedCount(): number {
		return Object.keys(this.checkboxes).filter((checkbox) => this.checkboxes[checkbox]).length;
	}

	getTranslation(str: string) {
		return this.translate.instant(str);
	}

	addGroup = (term: string) => {
		const new_group = new Folder();
		new_group.title = term;
		return this.folderService.createFolder(new_group).then((group) => {
			this.store.dispatch(new AddFolder({ group: group }));
			return Promise.resolve({
				id: group.getID(),
				title: group.getTitle(),
			});
		});
	};

	getUserNamedType(str: string) {
		return ModalMessages.getUserNamedType(str);
	}

	getCurrentCountSentence(type: string, action: string, success: string, count: number, totalCount: number, am?: boolean): string {
		return ModalMessages.getCurrentCountSentence(type, action, success, count, totalCount, am);
	}

	getCurrentCountSentenceTranslated(type: string, action: string, success: string, count: number, totalCount: number, am?: boolean) {
		const results = ModalMessages.getCurrentCountSentenceTranslated(type, action, success, count, totalCount, am);
		return this.translate.instant(results.message, results.params);
	}

	onDismiss(): void {}
}
