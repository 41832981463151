import { Component, Host, Input, Optional, SimpleChanges } from '@angular/core';
import { AppSelectOption, FormElementComponent } from '@klippa/ngx-enhancy-forms';
import { ControlContainer, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Journal } from '#/models/company/company.model';
import { stringIsSetAndFilled } from '#/util/values';
import { DynamicOptionsValueAccessorBase } from '~/app/shared/ui/forms/composed/pickers/dynamic/dynamic-options-picker/dynamic-options-value-accessor-base';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { CompanyIntegrationService } from '#/services/company/company-integration.service';
import { ItemsWithHasMoreResultsPromise } from '#/models/appSelectOption.model';

@Component({
	selector: 'app-form-journal-picker',
	templateUrl: './dynamic-options-picker/dynamic-options-picker.template.html',
	styleUrls: ['./dynamic-options-picker/dynamic-options-picker.template.scss'],
	providers: [{ provide: NG_VALUE_ACCESSOR, useExisting: JournalPickerComponent, multi: true }],
})
export class JournalPickerComponent extends DynamicOptionsValueAccessorBase<string, Journal> {
	@Input() integrationId: string;
	@Input() divisionId: string;

	constructor(
		@Optional() @Host() protected parent: FormElementComponent,
		@Optional() @Host() protected controlContainer: ControlContainer,
		private companyIntegrationService: CompanyIntegrationService,
	) {
		super(parent, controlContainer);
		this.defaultPlaceHolder = _('Select journal');
	}

	ngOnChanges(simpleChanges: SimpleChanges) {
		if (this.initiated) {
			if (simpleChanges.integrationId || simpleChanges.divisionId || simpleChanges.selectableItems) {
				this.picker._ext_refetchOptions();
				this.picker._ext_deselectInvalidItems();
			}
		}
	}

	private hasRequirements(): boolean {
		return stringIsSetAndFilled(this.integrationId) && stringIsSetAndFilled(this.divisionId);
	}

	fetchItemsFn = (start: number, searchQuery: string): ItemsWithHasMoreResultsPromise<Journal> => {
		if (!this.hasRequirements()) {
			return;
		}
		return this.companyIntegrationService
			.getJournals(this.companyIntegrationService.getCompanyOfLoggedUser(), this.integrationId, this.divisionId, start, searchQuery)
			.then((res) => {
				return {
					hasMoreResults: res.moreresults,
					items: res.journals,
				};
			});
	};

	fetchSelectedItemsFn = (ids: Array<string>): Promise<Array<Journal>> => {
		if (!this.hasRequirements()) {
			return Promise.resolve([]);
		}
		return this.companyIntegrationService.getJournalsByIds(
			this.companyIntegrationService.getCompanyOfLoggedUser(),
			this.integrationId,
			this.divisionId,
			ids,
		);
	};

	mapToSelectOptionFn = (e: Journal): AppSelectOption => {
		return {
			id: e.Identifier,
			name: e.Description,
		};
	};
}
