import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class RequestInterceptor implements HttpInterceptor {
	private skipRegex: RegExp;

	constructor() {
		this.skipRegex = new RegExp(
			'\\/api\\/v1\\/((creditcardStatement)|(icon)|(merchant)|(ocr-review)|(receipt))\\/(.*)\\/((image)|(document)|(logo)|(file))'
		);
	}

	intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		// Re-use nonce as cache-buster for IE11.
		const cache_buster = req.headers.get('X-Auth-Nonce');
		let observable: Observable<HttpEvent<any>>;
		if (cache_buster != null && !req.url.match(this.skipRegex)) {
			const httpRequest = req.clone({
				setParams: {
					cache_buster: cache_buster,
				},
			});
			observable = next.handle(httpRequest);
		} else {
			observable = next.handle(req);
		}

		return observable;
	}
}
