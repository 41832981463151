import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { TranslateService } from '@ngx-translate/core';
import { zxcvbn } from 'zxcvbn3';

@Component({
	selector: 'app-password-strength-meter',
	templateUrl: './password-strength-meter.component.html',
	styleUrls: ['./password-strength-meter.component.scss'],
})
export class PasswordStrengthMeterComponent implements OnChanges {
	@Input() password: string;

	private passwordStrengthMeter = zxcvbn;
	score: number;

	constructor(private translate: TranslateService) {}

	getPasswordStrengthDescription() {
		switch (this.score) {
			case 0:
				return this.translate.instant(_('Bad'));
			case 1:
				return this.translate.instant(_('Weak'));
			case 2:
				return this.translate.instant(_('Moderate'));
			case 3:
				return this.translate.instant(_('Strong'));
			case 4:
				return this.translate.instant(_('Perfect'));
			default:
				return;
		}
	}

	ngOnChanges(changes: SimpleChanges) {
		if (changes.password) {
			this.score = this.passwordStrengthMeter(this.password).score;
		}
	}
}
