import { Component, EventEmitter, Input, OnDestroy, Output, OnChanges } from '@angular/core';
import { select, Store } from '@ngrx/store';
import * as fromMerchant from '../../models/merchant.reducer';
import { Subscription } from 'rxjs';
import { AppState } from '~/app/reducers';
import { environment } from '~/environments/environment';

@Component({
	selector: 'app-merchant-logo',
	templateUrl: './merchant-logo.component.html',
})
export class MerchantLogoComponent implements OnChanges, OnDestroy {
	@Input() MerchantID: string;
	@Output() loaded = new EventEmitter<boolean>();
	public logo: string;
	public logoURL: string;

	private store: Store<AppState>;
	private merchantSub: Subscription;

	constructor(store: Store<AppState>) {
		this.store = store;
	}

	ngOnChanges() {
		this.merchantSub = this.store
			.select('merchant')
			.pipe(select(fromMerchant.getItemById(this.MerchantID)))
			.subscribe((val) => {
				if (val && val.hasLogo() && val.getID() !== this.logo) {
					this.logo = val.getID();
					this.logoURL = `${environment.api_url}/api/v1/merchant/${this.logo}/logo?w=48&h=48`;
					this.loaded.emit(true);
				} else {
					this.logo = null;
					this.loaded.emit(false);
				}
			});
	}

	ngOnDestroy(): void {
		this.merchantSub.unsubscribe();
	}
}
