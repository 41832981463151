import { Receipt } from '#/models/transaction/receipt';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { ReceiptActionsUnion, ReceiptActionTypes } from './receipt.actions';
import { createSelector } from '@ngrx/store';

export interface ReceiptState extends EntityState<Receipt> {
	currentPage: number | null;
	totalPages: number | null;
}

export const adapter: EntityAdapter<Receipt> = createEntityAdapter<Receipt>();

export const initialState: ReceiptState = adapter.getInitialState({
	currentPage: null,
	totalPages: null,
});

export function receiptReducer(state = initialState, action: ReceiptActionsUnion): ReceiptState {
	switch (action.type) {
		case ReceiptActionTypes.ADD_RECEIPT: {
			return adapter.addOne(action.payload.receipt, state);
		}

		case ReceiptActionTypes.UPSERT_RECEIPT: {
			return adapter.upsertOne(action.payload.receipt, state);
		}

		case ReceiptActionTypes.ADD_RECEIPTS: {
			return adapter.addMany(action.payload.receipts, state);
		}

		case ReceiptActionTypes.UPSERT_RECEIPTS: {
			return adapter.upsertMany(action.payload.receipts, state);
		}

		case ReceiptActionTypes.UPDATE_RECEIPT: {
			return adapter.updateOne(action.payload.receipt, state);
		}

		case ReceiptActionTypes.UPDATE_RECEIPTS: {
			return adapter.updateMany(action.payload.receipts, state);
		}

		case ReceiptActionTypes.DELETE_RECEIPT: {
			return adapter.removeOne(action.payload.id, state);
		}

		case ReceiptActionTypes.DELETE_RECEIPTS: {
			return adapter.removeMany(action.payload.ids, state);
		}

		case ReceiptActionTypes.LOAD_RECEIPTS: {
			return adapter.addAll(action.payload.receipts, state);
		}

		case ReceiptActionTypes.CLEAR_RECEIPTS: {
			return adapter.removeAll({ ...state });
		}

		default: {
			return state;
		}
	}
}

const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors();

// select the array of receipt ids
export const selectReceiptIds = selectIds;

// select the dictionary of receipt entities
export const selectReceiptEntities = selectEntities;

// select the array of receipts
export const selectAllReceipts = selectAll;

// select the total receipt count
export const selectReceiptTotal = selectTotal;

export const getItemById = (id) => createSelector(selectEntities, (allItems) => allItems[id] || null);
