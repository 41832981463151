import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ExpenseReport } from '#/models/transaction/expense-reports';
import { ExpenseReportsService } from '#/services/transaction/expense-reports.service';
import { AuthenticatedComponent } from '~/app/pages/authenticated/authenticated.component';
import { CompanyService } from '#/services/company/company.service';
import { User } from '#/models/user/user.model';
import { DeclarationStatusFlag } from '#/models/transaction/receipt';
import { emptyID } from '#/util/mongoDB';

@Component({
	selector: 'app-expense-report-edit-auth-flow-modal',
	templateUrl: './expense-report-edit-auth-flow-modal.html',
	styleUrls: ['./expense-report-edit-auth-flow-modal.scss'],
})
export class ExpenseReportEditAuthFlowModal extends AuthenticatedComponent implements OnInit {
	@Input() public user: User;
	@Input() public expenseReport: ExpenseReport = new ExpenseReport({});
	@Output() public onDismiss: EventEmitter<ExpenseReport> = new EventEmitter<ExpenseReport>();

	authorizationFlowApprovers: string[] = [];
	isAuthorizationFlowApproverOrderRequired: boolean;
	authorizationFlowRequireApproverCount: number;
	public authFlowId: string = null;
	public emptyId = emptyID;

	constructor(private expenseReportsService: ExpenseReportsService, private companyAPIService: CompanyService) {
		super();
	}

	ngOnInit(): void {
		if (this.expenseReport?.authorization_flow?.flow_id) {
			this.authFlowId = this.expenseReport.authorization_flow.flow_id;
		}
	}

	onAuthorizationFlowApproversChange() {
		this.authorizationFlowRequireApproverCount = this.authorizationFlowApprovers.length;
	}

	saveAuthFlow() {
		this.expenseReportsService.updateReportAuthorizationFlowStatus(
			this.expenseReport.id,
			this.authFlowId,
			this.authorizationFlowApprovers,
			this.isAuthorizationFlowApproverOrderRequired,
			this.authorizationFlowRequireApproverCount,
		);
		this.closeModal();
	}

	canSetAuthorizationFlow(): boolean {
		return (
			this.user.canSetCompanyAuthorizationFlow() &&
			this.expenseReport.expense_status?.current_status?.status !== DeclarationStatusFlag.Claimed &&
			this.expenseReport.expense_status?.current_status?.status !== DeclarationStatusFlag.Denied &&
			this.expenseReport.expense_status?.current_status?.status !== DeclarationStatusFlag.NeedsInformation
		);
	}

	closeModal(): void {
		this.onDismiss.emit(this.expenseReport);
	}
}
