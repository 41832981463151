import { Component, Input } from '@angular/core';

@Component({
	selector: 'app-boolean-badge',
	templateUrl: './boolean-badge.component.html',
})
export class BooleanBadgeComponent {
	@Input()
	public boolean: boolean;
	@Input()
	public label: string;
}
