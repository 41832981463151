import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PipesModule } from '~/app/helpers/pipes/pipes.module';
import { UiModule } from '~/app/shared/ui/ui.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { ModalModule } from '~/app/shared/ui/modal/modal.module';
import { IntegrationRelationModule } from '~/app/shared/ui/forms/composed/pickers/dynamic/integration-relation-picker/integration-relation.module';
import { ExportModule } from '~/app/modules/export/export.module';
import { GalleryListComponent } from '~/app/pages/authenticated/gallery-list/gallery-list.component';
import { PreTransactionsDataTable } from '~/app/shared/ui/data-tables/pre-transactions-data-table/pre-transactions-data-table.component';
import { WutuDataTableModule } from 'wutu-data-table';
import { MerchantModule } from '~/app/modules/merchant/merchant.module';

@NgModule({
	imports: [
		CommonModule,
		PipesModule,
		UiModule,
		FormsModule,
		TranslateModule,
		ReactiveFormsModule,
		ModalModule,
		IntegrationRelationModule,
		ExportModule,
		WutuDataTableModule,
		MerchantModule,
	],
	declarations: [GalleryListComponent, PreTransactionsDataTable],
	exports: [],
	providers: [],
})
export class GalleryListModule {}
