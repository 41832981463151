export interface ExportResponse {
	expire: number;
	key: string;
	sharer: string;
	status: ExportStatusType;
	type: string;
}

export enum ExportStatusType {
	IN_QUEUE = 'in-queue',
	IN_PROGRESS = 'in-progress',
	ERROR = 'error',
	FINISHED = 'finished',
}
