import { Component, Input } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { ExportColumnValues } from '#/models/company/exporterInterfaces';
import { ValueAccessorBase } from '@klippa/ngx-enhancy-forms';

@Component({
	selector: 'app-export-column',
	templateUrl: './export-column.component.html',
	styleUrls: ['./export-column.component.scss'],
	providers: [{ provide: NG_VALUE_ACCESSOR, useExisting: ExportColumnComponent, multi: true }],
})
export class ExportColumnComponent extends ValueAccessorBase<ExportColumnValues> {
	@Input() hideDateFormatPicker = false;
	showCustomExtraSettings = false;

	toggleCustomExtraSettings() {
		this.showCustomExtraSettings = !this.showCustomExtraSettings;
	}
}
