import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { ColumnsInterface } from '~/app/shared/ui/table/table';
import { Injectable } from '@angular/core';
import { ActionsMenuActionEnum, ActionsMenuInterface } from '~/app/shared/ui/actions-menu/actions-menu';
import { SelectActionEnum, SelectActionInterface } from '~/app/shared/ui/select-action/select-action';
import { CompanyService } from '#/services/company/company.service';
import { FilterEnum } from '~/app/shared/ui/filters/filter';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
	providedIn: 'root',
})
export class ColumnService {
	constructor(protected companyAPIService: CompanyService, private translate: TranslateService) {}

	private setPaymentMethodColumn(paymentInfoEnabled: boolean): ColumnsInterface {
		if (this.companyAPIService.getCompanyOfLoggedUser()?.modulePaymentMethodsIsEnabled()) {
			return {
				name: this.translate.instant(_('Payment method')),
				slug: 'payment_method_id',
				type: 'payment_method_id',
				enabled: true,
				sortable: true,
				id: 'payment_method_id',
				sortingProperty: 'payment_method_id',
			};
		} else {
			return {
				name: this.translate.instant(_('Payment method')),
				slug: 'paymentmethod',
				type: 'paymentmethod',
				enabled: paymentInfoEnabled,
				sortable: true,
				id: 'paymentmethod',
				sortingProperty: 'paymentmethod',
			};
		}
	}

	getColumns(states): ColumnsInterface[] {
		return [
			{
				name: this.translate.instant(_('Selection')),
				type: 'selector',
				enabled: true,
				required: true,
				position: 'pre',
				sortable: false,
				id: 'selection',
				sortingProperty: null,
			},
			{
				name: this.translate.instant(_('User')),
				slug: 'user',
				type: 'user',
				enabled: true,
				sortable: true,
				id: 'user',
				sortingProperty: 'user',
			},
			{
				name: this.translate.instant(_('Category')),
				slug: 'companycategory',
				type: 'category',
				enabled: states.categoriesEnabled,
				sortable: true,
				id: 'company_category',
				sortingProperty: 'companycategory',
			},
			this.setPaymentMethodColumn(states.paymentInfoEnabled),
			{
				name: this.translate.instant(_('Description')),
				type: 'description',
				slug: 'description',
				merchant_slug: 'merchant',
				compact_mode: states.compactModeEnabled,
				enabled: true,
				sortable: true,
				id: 'description',
				sortingProperty: 'description',
			},
			{
				name: this.translate.instant(_('Transaction date')),
				slug: 'purchasedate',
				type: 'date',
				enabled: true,
				sortable: true,
				id: 'purchase_date',
				sortingProperty: 'purchasedate',
			},
			{
				name: this.translate.instant(_('Administration')),
				slug: 'companyadministration',
				type: 'administration',
				enabled: true,
				sortable: true,
				id: 'administration',
				sortingProperty: 'companyadministration',
			},
			{
				name: this.translate.instant(_('Cost center')),
				slug: 'companycostcenter',
				type: 'cost_center',
				enabled: true,
				sortable: true,
				id: 'cost_center',
				sortingProperty: 'companycostcenter',
			},
			{
				name: this.translate.instant(_('Cost unit')),
				slug: 'companycostunit',
				type: 'cost_unit',
				enabled: true,
				sortable: true,
				id: 'cost_unit',
				sortingProperty: 'companycostunit',
			},
			{
				name: this.translate.instant(_('Project')),
				slug: 'companyproject',
				type: 'project',
				enabled: true,
				sortable: true,
				id: 'project',
				sortingProperty: 'companyproject',
			},
			{
				name: this.translate.instant(_('Amount')),
				slug: 'amount',
				currency_slug: 'currency',
				type: 'amount',
				enabled: true,
				sortable: true,
				id: 'amount',
				sortingProperty: 'amount',
			},
			{
				name: this.translate.instant(_('VAT')),
				items_slug: 'vatitems',
				currency_slug: 'currency',
				type: 'vat',
				enabled: true,
				sortable: false,
				id: 'vat_amount',
				sortingProperty: null,
			}, // not sortable yet
			{
				name: this.translate.instant(_('Status')),
				slug: 'declarationstatus',
				type: 'status',
				enabled: true,
				sortable: true,
				id: 'declaration_status',
				sortingProperty: 'declarationstatus.status',
			},
			{
				name: this.translate.instant(_('Current approver')),
				slug: 'current_approver',
				type: 'current_approver',
				enabled: true,
				sortable: true,
				id: 'current_approver',
				sortingProperty: 'current_approver',
			},
			{
				name: this.translate.instant(_('Booking status')),
				slug: 'bookingstatus',
				declaration_status_slug: 'declarationstatus',
				type: 'booking_status',
				enabled: false,
				sortable: true,
				id: 'booking_status',
				sortingProperty: 'bookingstatus.bookedon',
			},
			{
				name: this.translate.instant(_('Create date')),
				slug: 'createdate',
				type: 'date',
				enabled: false,
				sortable: true,
				id: 'createdate',
				sortingProperty: 'createdate',
			},
			{
				name: this.translate.instant(_('Submit date')),
				slug: 'submit_date',
				type: 'date',
				enabled: false,
				sortable: true,
				id: 'submit_date',
				sortingProperty: 'submit_date',
			},
			{
				name: this.translate.instant(_('Update date')),
				slug: 'updatedate',
				type: 'date',
				enabled: false,
				sortable: true,
				id: 'updatedate',
				sortingProperty: 'updatedate',
			},
			{
				name: this.translate.instant(_('Attachment count')),
				slug: 'attachments_count',
				type: 'default',
				enabled: false,
				sortable: true,
				id: 'attachments_count',
				sortingProperty: 'attachments_count',
			},
			{
				name: this.translate.instant(_('Settings')),
				type: 'column_config',
				enabled: true,
				required: true,
				position: 'end',
				sortable: false,
				id: 'settings',
				sortingProperty: null,
			},
		];
	}

	getTotalColumns(states: { paymentInfoEnabled: boolean; categoriesEnabled: boolean }): ColumnsInterface[] {
		return [
			{ type: 'empty', enabled: true, position: 'pre' },
			{ type: 'empty', enabled: true },
			{ type: 'empty', enabled: states.categoriesEnabled },
			{ type: 'empty', enabled: states.paymentInfoEnabled },
			{ slug: 'receipt_count', type: 'total', enabled: true },
			{ slug_start: 'start_date', slug_end: 'end_date', type: 'date-range', enabled: true },
			{ name: this.translate.instant(_('Cost center')), slug: 'cost_center', type: 'cost_center', enabled: true },
			{ name: this.translate.instant(_('Cost unit')), slug: 'cost_unit', type: 'cost_unit', enabled: true },
			{ name: this.translate.instant(_('Project')), slug: 'project', type: 'project', enabled: true },
			{ slug: 'amount', type: 'amount', enabled: true },
			{ type: 'empty', enabled: true },
			{ slug: 'status_to_show', type: 'status_to_show', enabled: true },
			{ type: 'empty', enabled: false },
			{ type: 'empty', enabled: false },
			{ type: 'empty', enabled: false },
			{ type: 'empty', enabled: false },
			{ type: 'empty', enabled: false },
			{ type: 'empty', enabled: true, position: 'end' },
		];
	}
}

@Injectable({
	providedIn: 'root',
})
export class ActionService {
	constructor(private translate: TranslateService) {}
	private pageActions: ActionsMenuInterface[] = [
		{ title: this.translate.instant(_('Add receipt')), action: ActionsMenuActionEnum.add_receipt },
		{ title: this.translate.instant(_('Travel expense')), action: ActionsMenuActionEnum.travel_expense },
	];

	private rowActions: ActionsMenuInterface[] = [{ title: this.translate.instant(_('Edit')), action: ActionsMenuActionEnum.edit }];

	private selectActions: SelectActionInterface[] = [
		{ title: this.translate.instant(_('Change status')), action: SelectActionEnum.change_status, multiple: true },
	];

	getPageActions(): ActionsMenuInterface[] {
		return this.pageActions;
	}

	getRowActions(): ActionsMenuInterface[] {
		return this.rowActions;
	}

	getSelectActions(): SelectActionInterface[] {
		return this.selectActions;
	}
}

@Injectable({
	providedIn: 'root',
})
export class FilterService {
	getFilterOptions(): FilterEnum[] {
		return [
			FilterEnum.search,
			FilterEnum.period,
			FilterEnum.since,
			FilterEnum.before,
			FilterEnum.type,
			FilterEnum.companycategory,
			FilterEnum.companyadministration,
			FilterEnum.companycostcenter,
			FilterEnum.companycostunit,
			FilterEnum.companyproject,
			FilterEnum.companygroup,
			FilterEnum.exported,
			FilterEnum.status,
			FilterEnum.authorization_flow,
			FilterEnum.currency,
			FilterEnum.merchant,
			FilterEnum.relation,
			FilterEnum.vat,
			FilterEnum.payment_method_id,
			FilterEnum.accountnumber,
			FilterEnum.group,
			FilterEnum.tag,
			FilterEnum.current_approver,
		];
	}
}
