import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { Folder } from '#/models/user/folder';
import { Store } from '@ngrx/store';
import { AppState } from '~/app/reducers';
import { LocalStorageService } from 'angular-2-local-storage';
import { StatisticsService } from '~/app/pages/authenticated/statistics/statistics.service';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap/modal/modal-ref';
import { User } from '#/models/user/user.model';
import { Router } from '@angular/router';
import { Company } from '#/models/company/company.model';
import { ModalService } from '~/app/services/modal.service';
import { bsDropdownToggle } from '~/app/helpers/bootstrapHelpers';
import { ReceiptListAPIRequest, ReceiptsExportRequest } from '#/models/transaction/receipt';
import { ReceiptService } from '#/services/transaction/receipt.service';
import { ExportRequest } from '#/models/company/exporterInterfaces';
import { isValueSet } from '#/util/values';

@Component({
	selector: 'app-folders-table',
	templateUrl: './folders-table.component.html',
})
export class FoldersTableComponent implements OnInit, OnDestroy {
	@Input() folders: Folder[];
	@Input() checkboxes: {};
	@Input() checkAll: { checked: boolean };

	@Output() groupsChanged = new EventEmitter<any>();

	@ViewChild('exportModal') exportModal: ElementRef;

	protected previousClickedCheckbox = null;
	protected destroyCallbacks = [];
	protected user: User;

	public company: Company;
	public exportIds: Array<string>;
	public exportPromise: Promise<string>;

	constructor(
		private store: Store<AppState>,
		private localStorageService: LocalStorageService,
		private statsService: StatisticsService,
		private modalService: ModalService,
		private router: Router,
		private receiptAPIService: ReceiptService,
	) {}

	ngOnInit(): void {
		const subscription = this.store.select('user').subscribe((val) => {
			this.user = val.currentUser;
		});

		const companySubscription = this.store.select('company').subscribe((val) => {
			this.company = val.company;
		});

		this.destroyCallbacks.push(() => {
			subscription.unsubscribe();
			companySubscription.unsubscribe();
		});
	}

	ngOnDestroy(): void {
		this.destroyCallbacks.forEach((cb) => cb());
	}

	private setupGroupsModal(modalRef: NgbModalRef, resetCheckboxes = false): void {
		modalRef.componentInstance.checkboxes = this.checkboxes;
		modalRef.componentInstance.user = this.user;

		let doneSubscription = modalRef.componentInstance.done.subscribe(() => {
			this.groupsChanged.emit(true);
			if (resetCheckboxes) {
				this.checkAll.checked = false;
				for (let checkboxesKey in this.checkboxes) {
					delete this.checkboxes[checkboxesKey];
				}
			}
		});

		modalRef.result
			.then((reason) => {
				doneSubscription.unsubscribe();
				doneSubscription = null;
			})
			.catch((reason) => {});

		this.destroyCallbacks.push(() => {
			if (doneSubscription) {
				doneSubscription.unsubscribe();
			}
		});
	}

	public updateGroups(): void {
		this.groupsChanged.emit(true);
	}

	public changedCheckAll(event): void {
		this.folders.forEach((folder) => {
			this.checkboxes[folder.id] = this.checkAll.checked;
		});
		this.toggleActionDropDown();
	}

	public getSelectedGroupsCount(): number {
		return Object.keys(this.checkboxes).filter((checkbox) => this.checkboxes[checkbox]).length;
	}

	public async tableActionSubmitExpenses(event): Promise<boolean> {
		const { ExpensesModalComponent } = await import('~/app/modules/generic/components/modals/expenses-modal/expenses-modal.component');
		const modalRef = this.modalService.open(ExpensesModalComponent);
		modalRef.componentInstance.type = 'group';
		modalRef.componentInstance.action = 'submit';
		this.setupGroupsModal(modalRef);
		return false;
	}

	public async tableActionRename(event): Promise<boolean> {
		const groupId = Object.keys(this.checkboxes).filter((checkbox) => this.checkboxes[checkbox])[0];
		const group = this.folders.filter((gr) => gr.getID() === groupId)[0];
		const { FoldersEditModalComponent } = await import(
			'~/app/modules/folder/components/modals/folders-edit-modal/folders-edit-modal.component'
		);
		const modalRef = this.modalService.open(FoldersEditModalComponent);
		modalRef.componentInstance.group = group;
		this.setupGroupsModal(modalRef);
		return false;
	}

	public async tableActionRetractExpenses(event): Promise<boolean> {
		const { ExpensesModalComponent } = await import('~/app/modules/generic/components/modals/expenses-modal/expenses-modal.component');
		const modalRef = this.modalService.open(ExpensesModalComponent);
		modalRef.componentInstance.type = 'group';
		modalRef.componentInstance.action = 'retract';
		this.setupGroupsModal(modalRef);
		return false;
	}

	public async tableActionDelete(event): Promise<boolean> {
		const { DeleteModalComponent } = await import('~/app/modules/generic/components/modals/delete-modal/delete-modal.component');
		const modalRef = this.modalService.open(DeleteModalComponent);
		modalRef.componentInstance.type = 'group';
		this.setupGroupsModal(modalRef, true);
		return false;
	}

	public async tableActionDownloadImages(event): Promise<boolean> {
		const { DownloadImagesModalComponent } = await import(
			'~/app/modules/generic/components/modals/download-images-modal/download-images-modal.component'
		);
		const modalRef = this.modalService.open(DownloadImagesModalComponent);
		modalRef.componentInstance.type = 'group';
		this.setupGroupsModal(modalRef);
		return false;
	}

	public async tableActionShare(event): Promise<boolean> {
		const { FoldersShareModalComponent } = await import('../modals/folders-share-modal/folders-share-modal.component');
		const modalRef = this.modalService.open(FoldersShareModalComponent);
		modalRef.componentInstance.group = this.folders;
		this.setupGroupsModal(modalRef);
		return false;
	}

	public navigateToGroup(event, group: Folder): boolean {
		const el = event.target as Element;
		if (!!el.closest('td.no-click') || !!el.closest('div.no-click')) {
			return false;
		}
		this.router.navigate([`/dashboard/folder/${group.getID()}`]);
	}

	public checkBoxClicked($event): void {
		if ($event.event.ctrlKey && this.previousClickedCheckbox) {
			let seenFirstCheckbox = false;
			let seenLastCheckbox = false;
			this.folders.forEach((receipt) => {
				if (seenFirstCheckbox && !seenLastCheckbox) {
					this.checkboxes[receipt.id] = this.checkboxes[this.previousClickedCheckbox];
				}

				if (receipt.id === this.previousClickedCheckbox) {
					seenFirstCheckbox = true;
				}

				if (receipt.id === $event.id) {
					seenLastCheckbox = true;
				}
			});
		}
		this.previousClickedCheckbox = $event.id;
		this.toggleActionDropDown();
	}

	private toggleActionDropDown(): void {
		setTimeout(() => {
			const toggle = document.querySelector('.table-header-button.dropdown-toggle');
			if (toggle?.getAttribute('aria-expanded') === 'false' && Object.keys(this.checkboxes).length > 0) {
				bsDropdownToggle(toggle);
			}
		}, 100);
	}

	public closeExportModal(): void {
		this.modalService.close(this.exportModal);
	}

	private getReceiptsFromGroup(groupId: string): Promise<Array<string>> {
		const filter: ReceiptListAPIRequest = new ReceiptListAPIRequest();
		filter.group = groupId;
		return this.receiptAPIService.getAllReceipts(filter).then((result) => result.map((receipt) => receipt.id));
	}

	public async openExportModal(id: string): Promise<void> {
		this.exportIds = await this.getReceiptsFromGroup(id);
		this.modalService.open(this.exportModal);
	}

	public async openExportModalBySelectedCheckboxes(): Promise<void> {
		const groupIds: Array<string> = Object.entries(this.checkboxes)
			.map(([key, value]) => (value ? key : null))
			.filter((e) => isValueSet(e));
		const allGroupsPromise: Array<Promise<Array<string>>> = groupIds.map((groupId) => this.getReceiptsFromGroup(groupId));
		const GroupsWithIds: Array<Array<string>> = await Promise.all(allGroupsPromise);
		// @ts-ignore typescript doesnt seem to know 'flat' exists for arrays?
		const allGroupItemIds = GroupsWithIds.flat();
		this.exportIds = allGroupItemIds;
		this.modalService.open(this.exportModal);
	}

	public processExport(event: ExportRequest): void {
		const exportRequest: ExportRequest = event;
		exportRequest.email = exportRequest.email.filter(Boolean).join(',');
		this.exportPromise = this.receiptAPIService.exportReceipts(exportRequest as ReceiptsExportRequest).then((res) => res.data.url);
	}
}
