import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
	selector: 'app-active-state-picker-deprecated',
	templateUrl: './active-state-picker.component.html',
})
export class DeprecatedActiveStatePickerComponent {
	@Output() activeChange = new EventEmitter<boolean>();

	activeValue: boolean;

	activeItems = [
		{ id: true, Label: this.translate.instant('Yes') },
		{ id: false, Label: this.translate.instant('No') },
	];

	constructor(private translate: TranslateService) {}

	@Input()
	get active() {
		return this.activeValue;
	}

	set active(val) {
		this.activeValue = val;
		this.activeChange.emit(this.activeValue);
	}
}
