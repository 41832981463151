<div class="modal-header">
	<ng-template [ngIf]="target === 'report'">
		<h4 *ngIf="!multiple" class="modal-title" translate>Delete report</h4>
		<h4 *ngIf="multiple" class="modal-title" translate>Delete reports</h4>
	</ng-template>
	<ng-template [ngIf]="target === 'expenses'">
		<h4 *ngIf="!multiple" class="modal-title" translate>Retract report</h4>
		<h4 *ngIf="multiple" class="modal-title" translate>Retract reports</h4>
	</ng-template>
	<button (click)="closeModal()" aria-label="Close" class="close" type="button">
		<span aria-hidden="true">&times;</span>
	</button>
</div>

<div class="modal-body">
	<ng-template [ngIf]="target === 'report'">
		<div *ngIf="!multiple" translate>
			Are you sure that you want to delete this report? Note: Be advised all items in the report will also be deleted by means of this
			action. This cannot be undone.
		</div>
		<div *ngIf="multiple" [translateParams]="{ reportCount: reportIds.length }" translate>
			Are you sure that you want to delete %reportCount% reports? Note: Be advised all items in the reports will also be deleted by means of
			this action. This cannot be undone.
		</div>
	</ng-template>
	<ng-template [ngIf]="target == 'expenses'">
		<div *ngIf="!multiple" translate>Are you sure that you want to retract the entire report?</div>
		<div *ngIf="multiple" [translateParams]="{ reportCount: reportIds.length }" translate>
			Are you sure that you want to retract %reportCount% reports?
		</div>
	</ng-template>
</div>

<div class="modal-footer">
	<ng-template [ngIf]="target == 'report'">
		<app-button variant="redFilled" aria-haspopup="true" aria-expanded="false" (click)="deleteReports()">
			<span translate>Delete</span>
		</app-button>
	</ng-template>
	<ng-template [ngIf]="target == 'expenses'">
		<app-button variant="greenFilled" aria-haspopup="true" aria-expanded="false" (click)="deleteReports()">
			<span translate>Retract</span>
		</app-button>
	</ng-template>
</div>
