import { Component, Input, OnInit } from '@angular/core';
import { DeclarationStatus, DeclarationStatusFlag, Receipt, ReceiptAuthorizationFlowStatus } from '#/models/transaction/receipt';
import { ExpenseReport } from '#/models/transaction/expense-reports';
import { isValueSet } from '#/util/values';
import { CompanyFeatureFlagsService } from '#/services/company/company-feature-flags.service';
import { memoize } from 'lodash';
import { PossibleCompanyFeatureFlags } from '#/models/company/possible-feature-flags';

@Component({
	selector: 'app-status-badge',
	templateUrl: './status-badge.component.html',
	styleUrls: ['./status-badge.component.scss'],
})
export class StatusBadgeComponent implements OnInit {
	@Input() public status: DeclarationStatus;
	@Input() public authorizationFlow: ReceiptAuthorizationFlowStatus = null;
	@Input() public parentItem: Receipt | ExpenseReport;
	@Input() public parentType: 'receipt' | 'report' = 'receipt';
	public approversLength: number;
	statusFlag: DeclarationStatusFlag;
	public authorizationFlowIsCompleted: boolean = false;
	public companyHasTransactionInterfaces = memoize((): Promise<boolean> => {
		return this.companyFeatureFlagsService.companyHasSpecificFeatureFlagEnabled(PossibleCompanyFeatureFlags.TRANSACTION_INTERFACES);
	});

	constructor(private companyFeatureFlagsService: CompanyFeatureFlagsService) {}

	setApproversLength() {
		if (isValueSet(this.authorizationFlow)) {
			if (!this.authorizationFlow.require_approver_order && isValueSet(this.authorizationFlow.require_approver_count)) {
				this.approversLength = this.authorizationFlow.require_approver_count;
			} else {
				this.approversLength = this.authorizationFlow.approvers.length;
			}
			if (this.authorizationFlow.approvals.length >= this.approversLength) {
				this.authorizationFlowIsCompleted = true;
			}
		}
	}

	ngOnInit(): void {
		this.statusFlag = this.status.status;
		if (this.authorizationFlow?.in_flow && this.status.status === DeclarationStatusFlag.Accepted) {
			this.statusFlag = this.statusFlag = DeclarationStatusFlag.Approved;
			this.setApproversLength();
		} else {
			this.authorizationFlowIsCompleted = true;
		}
	}
}
