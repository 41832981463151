import { Inject, Injectable } from '@angular/core';
import { APIService } from '../../api/services/api.service';
import { DOCUMENT } from '@angular/common';
import { APINotificationsService } from '../../api/services/apinotifications.service';
import { NotificationService } from '../../services/notification.service';
import { Icon } from './models/icon';
import { AddIcons, ClearIcons } from './models/icon.actions';
import { Store } from '@ngrx/store';

@Injectable({
	providedIn: 'root',
})
export class IconService {
	constructor(
		private apiService: APIService,
		@Inject(DOCUMENT) private document: any,
		private notifications: APINotificationsService,
		private notificationService: NotificationService,
		private store: Store,
	) {}

	getAllIcons(): Promise<Icon[]> {
		return this.getAllIconsPage();
	}

	getAllIconsPage(per_page: number = 100, page: number = 1, icons: Icon[] = []): Promise<Icon[]> {
		return this.getIconsPage(per_page, page)
			.then((r) => {
				if (r['data']['icons']) {
					const loadedIcons: any[] = r['data']['icons'];
					loadedIcons.map((icon) => icons.push(new Icon(icon)));
				}

				// Load next page.
				if (r['data']['moreresults']) {
					return this.getAllIconsPage(per_page, page + 1, icons);
				}

				return Promise.resolve(icons);
			})
			.catch((r) => {
				return Promise.reject(r);
			});
	}

	getIconsPage(per_page: number = 100, page: number = 1): Promise<any> {
		let url = '/api/v1/icon?';
		url += 'max=' + per_page + '&';
		url += 'start=' + per_page * (page - 1);
		return this.apiService
			.get('/api/v1/icon')
			.then((r) => {
				return Promise.resolve(r);
			})
			.catch((r) => {
				this.notifications.handleAPIError(r);
				return Promise.reject(r);
			});
	}

	getImage(id: string): Promise<any> {
		return this.apiService
			.getBlob('/api/v1/icon/' + id + '/image?w=45&h=45')
			.then((r) => {
				return Promise.resolve(r);
			})
			.catch((r) => {
				this.notifications.handleAPIError(r);
				return Promise.reject(r);
			});
	}

	patchIcon(icon: Icon): Promise<Icon> {
		const payload = {
			title: icon.getTitle(),
			keywords: icon.getKeywords(),
		};
		return this.apiService
			.patch(`/api/v1/icon/${icon.getID()}`, payload)
			.then((r) => {
				return Promise.resolve(new Icon(r.data));
			})
			.catch((r) => {
				this.notifications.handleAPIError(r);
				return Promise.reject(r);
			});
	}

	createIcon(icon: Icon): Promise<Icon> {
		const payload = {
			title: icon.getTitle(),
			keywords: icon.getKeywords(),
		};
		return this.apiService
			.post(`/api/v1/icon`, payload)
			.then((r) => {
				return Promise.resolve(new Icon(r.data));
			})
			.catch((r) => {
				this.notifications.handleAPIError(r);
				return Promise.reject(r);
			});
	}

	uploadIconImage(icon: Icon, file: File): Promise<Icon> {
		const formData = new FormData();
		formData.append('image', file);
		return this.apiService
			.post(`/api/v1/icon/${icon.getID()}/image`, formData)
			.then((r) => {
				return Promise.resolve(new Icon(r.data));
			})
			.catch((e) => {
				this.notifications.handleAPIError(e);
				return Promise.reject(e);
			});
	}

	deleteIcon(icon: Icon): Promise<any> {
		return this.apiService
			.delete(`/api/v1/icon/${icon.getID()}`)
			.then((r) => {
				return Promise.resolve(r);
			})
			.catch((e) => {
				this.notifications.handleAPIError(e);
				return Promise.reject(e);
			});
	}

	public async initializeIcons(): Promise<void> {
		return this.getAllIcons()
			.then((r) => {
				this.store.dispatch(new ClearIcons);
				this.store.dispatch(new AddIcons({ icons: r }));
			})
	}
}
