import { Injectable, OnDestroy, OnInit } from '@angular/core';
import { MessageTypes, WSMessage } from '#/models/websocket.model';
import { $WebSocket, WebSocketSendMode } from './websocket';
import { environment } from '../../../environments/environment';
import { Store } from '@ngrx/store';
import { AppState } from '../../reducers';
import { Random } from '../../helpers/random';
import { Subject } from 'rxjs';
import { hmac_sha512 } from '~/app/util/crypto';

@Injectable({
	providedIn: 'root',
})
export class WebsocketsService implements OnInit, OnDestroy {
	private socket: $WebSocket;
	private key: string | null;
	private secret: string | null;
	private destroyCallbacks = [];
	public onMessage: Subject<WSMessage> = new Subject<WSMessage>();
	public onMessageMyselfIncluded: Subject<WSMessage> = new Subject<WSMessage>();

	constructor(private store: Store<AppState>) {
		let tookFirst = false;
		const userStoreSubscription = this.store.select('user').subscribe((val) => {
			if (!tookFirst) {
				// Initialize the key

				tookFirst = true;
				let wsUri = environment.websockets_uri;
				// TODO: fix this properly in radius config file
				if (!wsUri.endsWith('/api/v1/ws')) {
					wsUri += '/api/v1/ws';
				}
				this.socket = new $WebSocket(wsUri, null, {
					reconnectIfNotNormalClose: true,
				});

				this.socket.onMessage(
					(msg: MessageEvent) => {
						const MsgObject = JSON.parse(msg.data);
						this.onMessageMyselfIncluded.next({ type: MsgObject.type, data: MsgObject.data });
						// Only send messages when someone else triggered it.
						if (!MsgObject.data['acting-key'] || MsgObject.data['acting-key'] !== this.key) {
							this.onMessage.next({ type: MsgObject.type, data: MsgObject.data });
						}
					},
					{ autoApply: false },
				);

				this.socket.setSend4Mode(WebSocketSendMode.Direct);
				this.key = val.currentKey;
				this.secret = val.currentSecret;

				// Re-authenticate after reconnect.
				this.socket.onOpen(() => {
					if (this.key != null && this.secret != null) {
						this.authenticate();
					}
				});
			} else {
				// On key update.
				if (this.key !== val.currentKey || this.secret !== val.currentSecret) {
					this.key = val.currentKey;
					this.secret = val.currentSecret;

					// Reconnect with new key.
					this.socket.reconnect();
				}
			}
		});

		this.destroyCallbacks.push(() => {
			userStoreSubscription.unsubscribe();
		});
		this.destroyCallbacks.push(() => {
			this.socket.close(true);
		});
	}

	async authenticate(): Promise<void> {
		const timeStamp = Math.round(new Date().getTime() / 1000).toString();
		const nonce = Random.randomStringLowerUpper();
		const signature = await hmac_sha512(this.secret, timeStamp + nonce);

		this.socket.send({
			type: MessageTypes.Authenticate,
			data: {
				key: this.key,
				timestamp: timeStamp,
				nonce: nonce,
				signature: signature,
			},
		});
	}

	ngOnInit(): void {}

	ngOnDestroy(): void {
		this.destroyCallbacks.forEach((cb) => cb());
	}

	public send(message: WSMessage): void {
		this.socket.send(message);
	}

	subscribeCurrentPushToken(): void {
		// Empty, is for NativeScript.
	}
}
