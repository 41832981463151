import { Folder } from '#/models/user/folder';
import { Merchant } from '#/models/merchant';
import { getPaymentMethodLabel, ReceiptTravelDeclarationLocation } from '#/models/transaction/receipt';
import { CompanyGroup } from '#/models/company/company.model';
import { User } from '#/models/user/user.model';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { StatValue } from './stats.value';
import { CostCenter } from '#/models/company/dimension/cost-center.model';
import { CostUnit } from '#/models/company/dimension/cost-unit.model';
import { Project } from '#/models/company/dimension/project.model';
import { Category } from '#/models/company/category.model';
import { Administration } from '#/models/company/administration.model';
import { CompanyPaymentMethod } from '#/models/company/payment-method.model';

export class BaseStat {
	TotalAmount: number;
	TotalReceipts: number;
	TotalVATAmount: number;

	constructor(data = null) {
		if (data) {
			Object.assign(this, data);
		}
	}

	getTotalReceipts(): number {
		return this.TotalReceipts;
	}

	getTotalAmount(): number {
		return this.TotalAmount;
	}

	getTotalVATAmount(): number {
		return this.TotalVATAmount;
	}

	getTotalExVATAmount(): number {
		return this.getTotalAmount() - this.getTotalVATAmount();
	}

	getLabel(): string {
		throw new Error('getLabel() not implemented');
	}

	getValue(): number {
		return this.getTotalAmount();
	}

	getExtraColumn(): string {
		return null;
	}

	toStatValue(): StatValue {
		return new StatValue(this.getID(), this.getLabel(), this.getCount(), this);
	}

	getID(): string {
		throw new Error('getID() not implemented');
	}

	getCount(): number {
		return this.getTotalReceipts();
	}

	get totalReceipts(): number {
		return this.getTotalReceipts();
	}

	get totalAmount(): number {
		return this.getTotalAmount();
	}

	get totalVATAmount(): number {
		return this.getTotalVATAmount();
	}

	get totalExVATAmount(): number {
		return this.getTotalExVATAmount();
	}

	get label(): string {
		return this.getLabel();
	}

	matches(string: string): boolean {
		/** Returns whether this Stat matches a given search string. */
		return this.getLabel().toLowerCase().includes(string.toLowerCase());
	}
}

export class FolderStat extends BaseStat {
	folder: Folder;

	constructor(data = null) {
		super(data);
		if (data) {
			this.folder = new Folder(data.Group);
		}
	}

	static fromData(data): FolderStat[] {
		return data.map((item) => new FolderStat(item));
	}

	getFolder(): Folder {
		return this.folder;
	}

	getLabel(): string {
		return this.getFolder().getTitle();
	}

	getID() {
		return this.getFolder().getID();
	}
}

export class MerchantStat extends BaseStat {
	Merchant: Merchant;

	constructor(data = null) {
		super(data);
		if (data) {
			this.Merchant = new Merchant(data.Merchant);
		}
	}

	static fromData(data): MerchantStat[] {
		return data.map((item) => new MerchantStat(item));
	}

	getMerchant(): Merchant {
		return this.Merchant;
	}

	getLabel(): string {
		return this.getMerchant().getTitle();
	}

	getID(): string {
		return this.getMerchant().getID();
	}
}

export class VATStat extends BaseStat {
	Vat: number;
	VatDeductible: boolean;

	static fromData(data): VATStat[] {
		return data.map((item) => new VATStat(item));
	}

	getVAT(): number {
		return this.Vat;
	}

	getVATString(): string {
		return this.getVAT().toString(10);
	}

	isVATDeductible(): boolean {
		return this.VatDeductible;
	}

	getExtraColumn() {
		return this.isVATDeductible() ? _('Yes') : _('No');
	}

	getPercentage(): string {
		const percentage = this.getVAT() / 100;
		return percentage.toFixed(2) + '%';
	}

	getLabel(): string {
		return this.getPercentage();
	}

	getID() {
		return this.getVATString();
	}
}

export class PaymentMethodStat extends BaseStat {
	PaymentMethod: string;

	static fromData(data): PaymentMethodStat[] {
		return data.map((item) => new PaymentMethodStat(item));
	}

	getPaymentMethod(): string {
		return this.PaymentMethod;
	}

	getPaymentMethodLabel(): string {
		return getPaymentMethodLabel(this.getPaymentMethod());
	}

	getLabel(): string {
		return this.getPaymentMethodLabel();
	}

	getID() {
		return this.getPaymentMethod();
	}
}

export class PaymentMethodIDStat extends BaseStat {
	PaymentMethod: string;
	PaymentMethodObject: CompanyPaymentMethod;

	static fromData(data): PaymentMethodIDStat[] {
		return data.map((item) => new PaymentMethodIDStat(item));
	}

	constructor(data = null) {
		super(data);
		if (data && data.PaymentMethodObject) {
			this.PaymentMethodObject = new CompanyPaymentMethod(data.PaymentMethodObject);
		}
	}

	getPaymentMethod(): string {
		return this.PaymentMethod;
	}

	getTag(): string {
		if (this.PaymentMethodObject) {
			return this.PaymentMethodObject.getName();
		}
		return '';
	}

	getLabel(): string {
		return this.getTag();
	}

	getPaymentMethodObject(): CompanyPaymentMethod {
		return this.PaymentMethodObject;
	}

	getID() {
		return this.getPaymentMethodObject().getID();
	}
}

export class UserTitleStat extends BaseStat {
	Title: string;

	static fromData(data): UserTitleStat[] {
		return data.map((item) => new UserTitleStat(item));
	}

	getTitle(): string {
		return this.Title;
	}

	getLabel(): string {
		return this.getTitle();
	}

	getID() {
		return this.getTitle();
	}
}

export class AccountNumberStat extends BaseStat {
	AccountNumber: string;

	static fromData(data): AccountNumberStat[] {
		return data.map((item) => new AccountNumberStat(item));
	}

	getAccountNumber(): string {
		return this.AccountNumber;
	}

	getLabel(): string {
		return this.getAccountNumber();
	}

	getID() {
		return this.getAccountNumber();
	}
}

export class TravelLocationStat extends BaseStat {
	TravelLocationTitle: string;
	TravelLocationObject: ReceiptTravelDeclarationLocation;

	static fromData(data): TravelLocationStat[] {
		return data.map((item) => new TravelLocationStat(item));
	}

	constructor(data = null) {
		super(data);

		if (data.TravelLocationObject) {
			this.TravelLocationObject = new ReceiptTravelDeclarationLocation(data.TravelLocationObject);
		}
	}

	getTravelLocationTitle(): string {
		return this.TravelLocationTitle;
	}

	getTravelLocationObject(): ReceiptTravelDeclarationLocation {
		return this.TravelLocationObject;
	}

	getLabel(): string {
		return this.getTravelLocationTitle();
	}

	getID() {
		return this.getTravelLocationTitle();
	}
}

export class TagStat extends BaseStat {
	Tag: string;

	static fromData(data): TagStat[] {
		if (data == null || data.length === 0) {
			return [];
		}
		return data.map((item) => new TagStat(item));
	}

	get id() {
		return this.getID();
	}

	getTag(): string {
		return this.Tag;
	}

	getLabel(): string {
		return this.getTag();
	}

	getID() {
		return this.getTag();
	}
}

export class CostCenterStat extends BaseStat {
	CostCenter: string;
	CostCenterObject: CostCenter;

	static fromData(data): CostCenterStat[] {
		return data.map((item) => new CostCenterStat(item));
	}

	constructor(data = null) {
		super(data);
		if (data && data.CostCenterObject) {
			this.CostCenterObject = new CostCenter(data.CostCenterObject);
		}
	}

	getCostCenter(): string {
		return this.CostCenter;
	}

	getTag(): string {
		if (this.CostCenterObject) {
			return this.CostCenterObject.getName();
		}
		return '';
	}

	getLabel(): string {
		return this.getTag();
	}

	getCostCenterObject(): CostCenter {
		return this.CostCenterObject;
	}

	getID() {
		return this.getCostCenterObject().getID();
	}
}

export class CostUnitStat extends BaseStat {
	CostUnit: string;
	CostUnitObject: CostUnit;

	static fromData(data): CostUnitStat[] {
		return data.map((item) => new CostUnitStat(item));
	}

	constructor(data = null) {
		super(data);
		if (data && data.CostUnitObject) {
			this.CostUnitObject = new CostUnit(data.CostUnitObject);
		}
	}

	getCostUnit(): string {
		return this.CostUnit;
	}

	getTag(): string {
		if (this.CostUnitObject) {
			return this.CostUnitObject.getName();
		}
		return '';
	}

	getLabel(): string {
		return this.getTag();
	}

	getCostUnitObject(): CostCenter {
		return this.CostUnitObject;
	}

	getID() {
		return this.getCostUnitObject().getID();
	}
}

export class ProjectStat extends BaseStat {
	Project: string;
	ProjectObject: Project;

	static fromData(data): ProjectStat[] {
		return data.map((item) => new ProjectStat(item));
	}

	constructor(data = null) {
		super(data);
		if (data && data.ProjectObject) {
			this.ProjectObject = new Project(data.ProjectObject);
		}
	}

	getProject(): string {
		return this.Project;
	}

	getTag(): string {
		if (this.ProjectObject) {
			return this.ProjectObject.getName();
		}
		return '';
	}

	getLabel(): string {
		return this.getTag();
	}

	getProjectObject(): Project {
		return this.ProjectObject;
	}

	getID() {
		return this.getProjectObject().getID();
	}
}

export class CategoryStat extends BaseStat {
	Category: string;
	CategoryObject: Category;

	static fromData(data): CategoryStat[] {
		return data.map((item) => new CategoryStat(item));
	}

	constructor(data = null) {
		super(data);
		if (data && data.CategoryObject) {
			this.CategoryObject = new Category(data.CategoryObject);
		}
	}

	getCategory(): string {
		return this.Category;
	}

	getCategoryObject(): Category {
		return this.CategoryObject;
	}

	getTag(): string {
		if (this.CategoryObject) {
			return this.CategoryObject.getName();
		}
		return '';
	}

	getLabel(): string {
		return this.getTag();
	}

	getID() {
		return this.getCategoryObject().getID();
	}
}

export class AdministrationStat extends BaseStat {
	Administration: string;
	AdministrationObject: Administration;

	static fromData(data): AdministrationStat[] {
		return data.map((item) => new AdministrationStat(item));
	}

	constructor(data = null) {
		super(data);
		if (data && data.AdministrationObject) {
			this.AdministrationObject = new Administration(data.AdministrationObject);
		}
	}

	getAdministration(): string {
		return this.Administration;
	}

	getAdministrationObject(): Administration {
		return this.AdministrationObject;
	}

	getTag(): string {
		if (this.AdministrationObject) {
			return this.AdministrationObject.name;
		}
		return '';
	}

	getLabel(): string {
		return this.getTag();
	}

	getID() {
		return this.getAdministrationObject().id;
	}
}

export class CompanyGroupStat extends BaseStat {
	Group: string;
	GroupObject: CompanyGroup;

	static fromData(data): CompanyGroupStat[] {
		return data.map((item) => new CompanyGroupStat(item));
	}

	constructor(data = null) {
		super(data);
		if (data && data.GroupObject) {
			this.GroupObject = new CompanyGroup(data.GroupObject);
		}
	}

	getTag(): string {
		if (this.GroupObject) {
			return this.GroupObject.getName();
		}
		return '';
	}

	getLabel(): string {
		return this.getTag();
	}

	getCompanyGroup(): CompanyGroup {
		return this.GroupObject;
	}

	getID() {
		return this.getCompanyGroup().getID();
	}
}

export class UserStat extends BaseStat {
	User: string;
	UserObject: User;

	static fromData(data): UserStat[] {
		return data.map((item) => new UserStat(item));
	}

	addUserObjectToStats(users: User[]) {
		if (users) {
			this.UserObject = users.find((g) => g.getID() === this.User);
		}
	}

	getTag(): string {
		if (this.UserObject) {
			return this.UserObject.getName();
		}
		return '';
	}

	getLabel(): string {
		return this.getTag();
	}

	getID() {
		return this.User;
	}
}

export class ReceiptTypeOption extends BaseStat {
	Type: string;

	static fromData(data): ReceiptTypeOption[] {
		if (data == null || data.length === 0) {
			return [];
		}
		return data.map((item) => new ReceiptTypeOption(item));
	}

	get id() {
		return this.getID();
	}

	getReceiptType(): string {
		return this.Type;
	}

	getLabel(): string {
		return this.getReceiptType();
	}

	getID() {
		return this.getReceiptType();
	}
}
