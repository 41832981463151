import { Component, Input } from '@angular/core';

@Component({
	selector: 'app-info-tooltip',
	templateUrl: './info-tooltip.component.html',
})
export class InfoTooltipComponent {
	@Input()
	public title: string;
	@Input()
	public placement: string = 'top';
	@Input()
	public showIcon: boolean = true;
}
