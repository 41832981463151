import { AmountWithCurrency } from '#/models/transaction/amountWithCurrency';
import { Attachment } from '../attachment';
import { PercentageAndAmount } from '#/models/currency';
import { frontendToApi } from './transformer';
import { PreTransactionApiModel } from './apiModel';
import { DeclarationStatusFlag, OCRStatus } from '#/models/transaction/receipt';

export class PreTransactionFrontendModel {
	id: string;
	user: string;
	description: string;
	transactionInterfaceId: string;
	purchaseDate: string; // YYYY-MM-DD
	amount: AmountWithCurrency;
	useExchangeCurrency: boolean;
	exchangeCurrency: {
		fromAmountWithCurrency: AmountWithCurrency;
		exchangeRate: number;
		toAmountWithCurrency: AmountWithCurrency;
	};
	vatLines: Array<PercentageAndAmount>;
	administration: string;
	project: string;
	costUnit: string;
	costCenter: string;
	merchant: string;
	category: string;
	attachments: Array<Attachment>;
	status: DeclarationStatusFlag;
	ocrStatus: OCRStatus;
	customPaymentMethod: string;
	createDate: string; // YYYY-MM-DD
	updateDate: string; // YYYY-MM-DD

	constructor(data?: any) {
		Object.assign(this, data);
	}

	public transformToApi(): PreTransactionApiModel {
		return frontendToApi(this);
	}
}
