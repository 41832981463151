import { Pipe, PipeTransform } from '@angular/core';
import { format } from '~/app/util/i18n';
import { emptyDate } from '#/util/mongoDB';

@Pipe({
	name: 'dateFormat',
})
export class DateFormat implements PipeTransform {
	transform(input: string | Date | undefined | false | null, withTimeOrFormat?: boolean | string) {
		if (!input) {
			input = new Date();
			// Do not show invalid date values from database.
		} else if (input === emptyDate) {
			return null;
		} else if (typeof input === 'string') {
			input = new Date(input);
		}

		const dateFormat =
			withTimeOrFormat === undefined || typeof withTimeOrFormat === 'boolean' ? 'PP' + (withTimeOrFormat ? ' pp' : '') : withTimeOrFormat;

		return format(input, dateFormat);
	}
}
