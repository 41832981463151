import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Receipt } from '#/models/transaction/receipt';
import { ReceiptsTableColumn } from '~/app/modules/receipt/components/receipts-table/receipts-table.component';

@Component({
	selector: '[app-receipts-table-report]',
	templateUrl: './receipts-table-report.component.html',
})
export class ReceiptsTableReportComponent implements OnChanges {
	@Input() receipts: Receipt[];
	@Input() public columns: ReceiptsTableColumn[];
	@Input() tableType?: string;
	receiptCount: number;
	userCount: number;
	totalAmount: number;
	totalVATAmount: number;
	oneCurrency: boolean;
	currency: string;
	public translateUserCount = { userCount: 0 };
	public translateReceiptCount = { receiptCount: 0 };

	updateReport() {
		this.receiptCount = this.receipts.length;
		this.userCount = this.countUsers();
		this.oneCurrency = this.checkCurrencies();
		if (this.oneCurrency) {
			this.totalAmount = this.getTotalAmount();
			this.totalVATAmount = this.getTotalVATAmount();
			this.currency = this.receipts[0].getCurrency();
		}
		this.translateUserCount = { userCount: this.userCount };
		this.translateReceiptCount = { receiptCount: this.receiptCount };
	}

	countUsers() {
		const unique = this.receipts.reduce(
			(count, receipt) => ((count[receipt.getUserID()] = (count[receipt.getUserID()] || 0) + 1), count),
			{},
		);
		return Object.keys(unique).length;
	}

	getTotalAmount() {
		return this.receipts.reduce((amount, receipt) => amount + receipt.getAmount(), 0);
	}

	getTotalVATAmount() {
		return this.receipts.reduce((amount, receipt) => amount + receipt.getVATAmount(), 0);
	}

	checkCurrencies() {
		const unique = this.receipts.reduce(
			(count, receipt) => ((count[receipt.getCurrency()] = (count[receipt.getCurrency()] || 0) + 1), count),
			{},
		);
		return Object.keys(unique).length < 2;
	}

	ngOnChanges(changes: SimpleChanges) {
		if (changes.receipts) {
			this.updateReport();
		}
	}
}
