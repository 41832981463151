import { Injectable } from '@angular/core';
import { Company, CompanyModuleCompensationRulesRule } from '#/models/company/company.model';
import { APIService } from '~/app/api/services/api.service';
import { APINotificationsService } from '~/app/api/services/apinotifications.service';
import { PaginatedCompanyRequest } from '#/models/company/paginatedCompanyRequest';
import { LicensingInformation } from '#/models/company/licensing-information';
import { Agreement, User } from '#/models/user/user.model';
import { ImportResult } from '#/models/import-result.model';

const cacheTimingMs = 60 * 1000;

@Injectable({
	providedIn: 'root',
})
export class CompanyMiscService {
	constructor(private apiService: APIService, private notifications: APINotificationsService) {}

	getCompanyLicensingInfoList(companyIDs: string[]) {
		const url = `/api/v1/company/licensing/list`;
		const body = {
			ids: companyIDs,
		};
		return this.apiService
			.post(url, body)
			.then((r) => {
				return r.data;
			})
			.catch((e) => {
				this.notifications.handleAPIError(e);
				throw e;
			});
	}

	getFilteredCompanies(paginatedCompanyRequest: PaginatedCompanyRequest): Promise<Company[]> {
		const requestURL = paginatedCompanyRequest.getRequestURL(`/api/v1/company`);
		return this.apiService
			.get(requestURL)
			.then((r) => {
				const companies = Company.fromData(r.data.companies);
				return companies;
			})
			.catch((e) => {
				this.notifications.handleAPIError(e);
				throw e;
			});
	}

	public getAllCompaniesCount(): Promise<number> {
		return this.apiService
			.get(`/api/v1/company`)
			.then((r) => {
				const companiesCount = r.data.count;
				return companiesCount;
			})
			.catch((e) => {
				this.notifications.handleAPIError(e);
				throw e;
			});
	}

	getFilteredCompaniesCount(paginatedCompanyRequest: PaginatedCompanyRequest) {
		const requestURL = paginatedCompanyRequest.getRequestURL(`/api/v1/company`);
		return this.apiService
			.get(requestURL)
			.then((r) => {
				const companiesCount = r.data.count;
				return companiesCount;
			})
			.catch((e) => {
				this.notifications.handleAPIError(e);
				throw e;
			});
	}

	patchCompanyCompensationRules(company: Company, rules: CompanyModuleCompensationRulesRule[]): Promise<Company> {
		return this.apiService
			.patch(`/api/v1/company/${company.getID()}/modules/CompensationRules`, {
				rules,
			})
			.then((r) => new Company(r.data))
			.catch((e) => {
				this.notifications.handleAPIError(e);
				throw e;
			});
	}

	patchCompanyDefaultPreferences(company: Company): Promise<Company> {
		return this.apiService
			.patch(`/api/v1/company/${company.getID()}/defaultPreferences`, company.defaultpreferences)
			.then((r) => new Company(r.data))
			.catch((e) => {
				this.notifications.handleAPIError(e);
				throw e;
			});
	}

	patchCompanyLicensingInformation(companyID: string, licensingInfo: LicensingInformation): Promise<Company | void> {
		return this.apiService
			.patch(`/api/v1/company/${companyID}/licensing`, licensingInfo)
			.then((r) => {
				return new Company(r.data);
			})
			.catch((e) => {
				this.notifications.handleAPIError(e);
				throw e;
			});
	}

	patchCompanyModuleCustomLogos(
		company: Company,
		enabled: boolean,
		logos: { SmallLight?: File; SmallDark?: File; LargeLight?: File; LargeDark?: File },
	): Promise<Company> {
		const formData = new FormData();
		formData.append('enable', enabled.toString());
		if (logos.SmallDark) {
			formData.append('small_logo_dark', logos.SmallDark);
		}
		if (logos.LargeDark) {
			formData.append('large_logo_dark', logos.LargeDark);
		}
		if (logos.SmallLight) {
			formData.append('small_logo_light', logos.SmallLight);
		}
		if (logos.LargeLight) {
			formData.append('large_logo_light', logos.LargeLight);
		}

		return this.apiService
			.patch(`/api/v1/company/${company.getID()}/modules/CustomLogo`, formData)
			.then((r) => new Company(r.data))
			.catch((e) => {
				this.notifications.handleAPIError(e);
				throw e;
			});
	}

	companySettingsExport(
		company: Company,
		administration: string,
		toExport: 'users' | 'groups' | 'categories' | 'cost_centers' | 'cost_units' | 'projects' | 'administrations',
		kind: 'csv' | 'xlsx',
	): Promise<Blob> {
		const body = { kind };
		if (administration && administration !== '') {
			body['administration'] = administration;
		}
		return this.apiService.postBlob(`/api/v1/company/${company.getID()}/export/${toExport}`, body);
	}

	reactivateUser(company: Company, user: User): Promise<User> {
		return this.apiService
			.post(`/api/v1/company/${company.getID()}/user/${user.getID()}/reactivate`, {})
			.then((r) => new User(r.data))
			.catch((e) => {
				this.notifications.handleAPIError(e);
				throw e;
			});
	}

	createUser(company: Company, user: User): Promise<User> {
		return this.apiService
			.post(`/api/v1/company/${company.getID()}/user`, user)
			.then((r) => new User(r.data))
			.catch((e) => {
				this.notifications.handleAPIError(e);
				throw e;
			});
	}

	inviteUser(company: Company, user: User) {
		return this.apiService
			.post(`/api/v1/company/${company.getID()}/user/invite`, user)
			.then((r) => r)
			.catch((e) => {
				this.notifications.handleAPIError(e);
				throw e;
			});
	}

	patchUser(company: Company, user: User): Promise<User> {
		return this.apiService
			.patch(`/api/v1/company/${company.getID()}/user/${user.getID()}`, user)
			.then((r) => new User(r.data))
			.catch((e) => {
				this.notifications.handleAPIError(e);
				throw e;
			});
	}

	deleteUser(company: Company, user: User): Promise<any> {
		return this.apiService
			.delete(`/api/v1/company/${company.getID()}/user/${user.getID()}`)
			.then((r) => r)
			.catch((e) => {
				this.notifications.handleAPIError(e);
				throw e;
			});
	}

	importUsers(company: Company, file: File, updateExistingUsers = false, skipFirstRow = true): Promise<ImportResult> {
		const formData = new FormData();
		formData.append('file', file);
		if (updateExistingUsers) {
			formData.append('update_existing_users', '1');
		}

		if (skipFirstRow) {
			formData.append('skip_first_row', '1');
		}

		return this.apiService
			.post(`/api/v1/company/${company.getID()}/importusers`, formData)
			.then((r) => new ImportResult(r.data))
			.catch((e) => {
				this.notifications.handleAPIError(e);
				throw e;
			});
	}

	getAvailableAgreements() {
		return this.apiService
			.get('/api/v1/company/availableAgreements')
			.then((r) => r.data)
			.catch((e) => {
				this.notifications.handleAPIError(e);
				throw e;
			});
	}

	acceptAgreement(company: Company, agreement: Agreement): Promise<Company> {
		const payload = {
			type: agreement.getType(),
			version: agreement.getVersion(),
		};
		return this.apiService
			.post(`/api/v1/company/${company.getID()}/acceptAgreement`, payload)
			.then((r) => new Company(r.data))
			.catch((e) => {
				this.notifications.handleAPIError(e);
				throw e;
			});
	}

	startTrial(name: string, company_name: string, phone_number: string, email: string, language?: string): Promise<any> {
		const body = {
			name: name,
			companyname: company_name,
			phonenumber: phone_number,
			email: email,
			language: language,
		};

		return this.apiService
			.post('/api/v1/dm_demo', body, cacheTimingMs, null, false)
			.then((r) => r)
			.catch((e) => {
				this.notifications.handleAPIError(e);
				throw e;
			});
	}

	getModuleCorrectionFieldsCountersGetCounter(
		company: Company,
		user: string,
		category: string,
		receipt: string,
		currency: string,
		purchasedate: string,
	): Promise<any> {
		return this.apiService
			.get(
				`/api/v1/company/${company.getID()}/modules/CorrectionFieldsCounters/GetCounter?user=${user}&category=${category}&receipt=${receipt}&currency=${currency}&purchasedate=${purchasedate}`,
			)
			.then((r) => r)
			.catch((e) => {
				this.notifications.handleAPIError(e);
				throw e;
			});
	}
}
