import { CompensationType } from '#/models/compensation-type';
import { DistanceUnit } from '#/models/distance-unit';
import { TimeUnit } from '../time-unit';
import { AmountWithCurrency } from '#/models/transaction/amountWithCurrency';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';

export class CompensationRule {
	type: CompensationType;
	active: boolean;
	id: string;
	name: string;
	amount: number;
	untaxedAmount: number;
	currency: string;
	// unit type none is used for fixed compensation
	unit: DistanceUnit | TimeUnit | 'none';
	scope: {
		groups: Array<string>;
		categories: Array<string>;
		administrations: Array<string>;
		costCenters: Array<string>;
		costUnits: Array<string>;
		projects: Array<string>;
		countries: Array<string>;
	};
	startDate: string; // DateYMD
	endDate: string; // DateYMD

	constructor(data = null) {
		if (data) {
			Object.assign(this, data);
		}
	}

	public static getUnitTypeForDistanceCompensationRule(unit: DistanceUnit | TimeUnit | 'none'): DistanceUnit {
		if (unit === DistanceUnit.MILE || unit === DistanceUnit.KILOMETER) {
			return unit;
		}
		throw new Error(`Unit type is is ${unit} which not a distance type unit`);
	}

	public getAmountWithCurrency(): AmountWithCurrency {
		return {
			amount: this.amount,
			currency: this.currency,
		};
	}

	public getUnitCaption(): string {
		switch (this.unit) {
			case DistanceUnit.KILOMETER:
				return _('kilometer');
			case DistanceUnit.MILE:
				return _('mile');
			case TimeUnit.DAY:
				return _('day');
			case TimeUnit.HOUR:
				return _('hour');
		}
	}
}
