<klp-grid-container [fluid]="true">
	<app-nav-and-cta>
		<klp-gretel-bar></klp-gretel-bar>
		<div class="header-actions">
			<app-button variant="greenFilled" (click)="openFilters()">
				<span translate>Filters</span>
				<app-filters-count [requestQuery]="receiptListAPIRequest"> </app-filters-count>
			</app-button>
			<app-button *ngIf="canAddToReport()" variant="greenFilled" (click)="openPageActions()">
				<app-icon variant="plus" color="white"></app-icon>&nbsp;<span translate>Add</span>
			</app-button>
		</div>
	</app-nav-and-cta>
	<div class="header-tabs" *ngIf="showTabs()">
		<app-button
			[variant]="getActiveTab() === 'todo' ? 'greenFilled' : 'white'"
			aria-haspopup="true"
			aria-expanded="false"
			(click)="onTabTodoClicked()"
			translate
		>
			To do
		</app-button>
		<app-button
			[variant]="getActiveTab() === 'all' ? 'greenFilled' : 'white'"
			aria-haspopup="true"
			aria-expanded="false"
			(click)="onTabAllClicked()"
			translate
		>
			All
		</app-button>
	</div>
	<ng-container *ngIf="showPageActions">
		<app-action-menu
			[actions]="isSetTransactionInterfaces() ? [] : pageActions"
			(onAction)="executeRequestedPageAction($event)"
			(onDismiss)="closePageActions()"
		>
			<div *ngIf="transactionInterfaces?.length > 0">
				<a
					class="transactionInterfaceItem"
					*ngFor="let transactionInterface of transactionInterfaces"
					routerLink="tx/new"
					[queryParams]="{ 'tx-interface-id': transactionInterface.id, 'report-id': reportId, 'view-mode': 'submit' }"
				>
					{{ transactionInterface.menuCaption }}
				</a>
			</div>
		</app-action-menu>
	</ng-container>
	<section>
		<div class="card">
			<div class="card-block">
				<ngx-async-template [promise]="reportPromise">
					<ng-template ngx-async-error let-errorMessages="errorMessages">
						<app-error-message [errorMessages]="errorMessages"></app-error-message>
					</ng-template>
					<ng-template ngx-async-pending>
						<klp-form-loading-indicator variant="3dots"></klp-form-loading-indicator>
					</ng-template>
					<ng-template ngx-async-success let-report="value">
						<ngx-async-template [promise]="reportReceiptsPromise" [successStateOnReload]="true">
							<ng-template ngx-async-success let-reportReceipts="value">
								<div *ngIf="showSelectActions" class="actionMenu">
									<app-select-action
										[actions]="getSelectActions"
										[selected]="selected"
										(onAction)="executeRequestedSelectAction($event, report.id)"
									>
									</app-select-action>
								</div>
								<app-table
									[total]="reportReceipts.data.count"
									[start]="receiptListAPIRequest.start"
									[max]="receiptListAPIRequest.max"
									[sortingParameters]="{ sort: receiptListAPIRequest.sort, sortorder: receiptListAPIRequest.sortorder }"
									[rows]="reportReceipts.data.receipts"
									[columns]="columns"
									[totalRow]="report"
									[totalColumns]="totalColumns"
									typeName="transaction"
									[itemName]="'transaction' | translate"
									[customizedEmptyTableMessage]="getEmptyTableText()"
									[itemsName]="'transactions' | translate"
									[hasClickableRows]="true"
									[selectAllItemFn]="selectAllTransactionsOnAllPages"
									(clickRow)="openEditModalWithReceiptId($event)"
									[rowActions]="getRowActions"
									(onRowAction)="executeRequestedRowAction($event)"
									(navigateTable)="navigateTable($event)"
									(sortTable)="sortTable($event)"
									(openSelectActions)="openSelectActions($event)"
									(onSortingArrowClicked)="fetchSortedColumns($event); updateRouteWithSortingParams($event)"
								>
								</app-table>
								<div class="table-action-footer">
									<div class="leftAligned">
										<app-button *ngIf="canDeleteReport()" variant="redFilled" (click)="openDeleteReportModal()">
											<span translate>Delete report</span>
										</app-button>
									</div>
									<div class="rightAligned">
										<app-button *ngIf="canRetractReport()" variant="greenFilled" (click)="openRetractReportModal()">
											<span translate>Retract report</span>
										</app-button>

										<app-button
											*ngIf="showSubmitButton()"
											[disabled]="!canSubmitReport()"
											variant="greenFilled"
											(click)="submitReport()"
											[ngbTooltip]="getTooltipText()"
										>
											<span translate>Submit report</span>
										</app-button>
									</div>
								</div>
							</ng-template>
						</ngx-async-template>
					</ng-template>
				</ngx-async-template>
			</div>
		</div>
	</section>
</klp-grid-container>

<ng-template #deleteReportModal>
	<app-expense-report-delete-modal
		(onDismiss)="closeDeleteReportModal()"
		(onSuccess)="closeDeleteReportModal(); goToParentRoute()"
		[reportIds]="[report.id]"
		target="report"
	>
	</app-expense-report-delete-modal>
</ng-template>

<ng-template #retractReportModal>
	<app-expense-report-delete-modal
		(onDismiss)="closeRetractReportModal()"
		(onSuccess)="this.fetchReport(); this.fetchReceipts(); closeRetractReportModal()"
		[reportIds]="[report.id]"
		target="expenses"
	>
	</app-expense-report-delete-modal>
</ng-template>

<ng-template #duplicateReceiptModal>
	<app-receipt-duplicate-modal (onDismiss)="closeDuplicateModal()" [receiptIds]="toDuplicateIds"> </app-receipt-duplicate-modal>
</ng-template>

<div *ngIf="showFilters">
	<app-filters
		[requestQuery]="receiptListAPIRequest"
		[filterOptions]="filterOptions"
		[filtersLocalStorageKey]="filtersLocalStorageKey"
		(onDismiss)="closeFilters()"
		(onSave)="updateRequestFilters($event)"
	>
	</app-filters>
</div>
