import { CompanyUnitBase } from '#/models/company/dimension/company-unit-base.model';
import { DimensionLimitation } from '#/models/company/company.model';
import { Injectable } from '@angular/core';
import { CompanyAdministrationService } from '#/services/company/company-administration.service';
import { CompanyCategoryService } from '#/services/company/company-category.service';
import { CompanyCostCenterService } from '#/services/company/dimension/company-cost-center.service';
import { CompanyCostUnitService } from '#/services/company/dimension/company-cost-unit.service';
import { CompanyProjectService } from '#/services/company/dimension/company-project.service';
import { uniqWith } from 'lodash';

@Injectable({
	providedIn: 'root',
})
export class DimensionLimitationsService {
	constructor(
		private companyAdministration: CompanyAdministrationService,
		private companyCategory: CompanyCategoryService,
		private companyCostCenter: CompanyCostCenterService,
		private companyCostUnit: CompanyCostUnitService,
		private companyProject: CompanyProjectService
	) {
	}

	filterWithDimensionLimitations(
		companyID: string,
		costcenter: string,
		costunit: string,
		project: string,
		selectOptions: CompanyUnitBase[],
		dimensionLimitations: DimensionLimitation[]
	): Promise<any> {
		if (dimensionLimitations && dimensionLimitations.length > 0) {
			const checkPromises: Promise<DimensionLimitation>[] = [];
			dimensionLimitations.forEach((dimensionLimitation) => {
				let dimensionMatched = false;
				dimensionLimitation.matches.forEach((match) => {
					const doCheck = (checkValue: string): Promise<DimensionLimitation> => {
						if (checkValue && match.valueMatches(checkValue)) {
							dimensionMatched = true;
							return Promise.resolve(dimensionLimitation);
						}
						return Promise.resolve(null);
					};

					let id = null;
					let unitFetcher: (companyID: string, id: string) => Promise<CompanyUnitBase> = null;
					if (match.dimensionType === 'CostCenter' && costcenter) {
						id = costcenter;
						unitFetcher = this.companyCostCenter.getCompanyCostCenter;
					} else if (match.dimensionType === 'CostUnit' && costunit) {
						id = costunit;
						unitFetcher = this.companyCostUnit.getCompanyCostUnit;
					} else if (match.dimensionType === 'Project' && project) {
						id = project;
						unitFetcher = this.companyProject.getCompanyProject;
					}

					if (id && unitFetcher) {
						checkPromises.push(
							new Promise<DimensionLimitation>((resolve, reject) => {
								if (dimensionMatched) {
									return resolve(null);
								}
								if (match.field === 'ID') {
									return doCheck(id);
								}
								return unitFetcher(companyID, id)
									.then((dimensionValue) => {
										if (dimensionMatched) {
											return resolve(null);
										}
										return doCheck(dimensionValue.getValueForField(match.field)).then(resolve, reject);
									})
									.catch((e) => {
										reject(e);
									});
							})
						);
					}
				});
			});

			return Promise.all(checkPromises).then((limitations) => {
				let matchedLimitations = limitations.filter((item) => item !== null);
				if (matchedLimitations && matchedLimitations.length > 0) {
					const matchedItems = [];
					// Make sure every limitation is only checked once (when multiple rules matched).
					matchedLimitations = uniqWith(matchedLimitations, (a, b) => {
						return a.id === b.id;
					});
					matchedLimitations.forEach((allowedItem) => {
						allowedItem.allowed.forEach((allowed) => {
							selectOptions.forEach((selectOption) => {
								if (allowed.valueMatches(selectOption.getValueForField(allowedItem.targetField))) {
									matchedItems.push(selectOption);
								}
							});
						});
					});

					return Promise.resolve(matchedItems);
				}

				// No limitations matched, just resolve original options.
				return Promise.resolve(selectOptions);
			});
		}

		return Promise.resolve(selectOptions);
	}
}

