import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { Company } from '#/models/company/company.model';
import { Store } from '@ngrx/store';
import { AppState } from '~/app/reducers';
import { User } from '#/models/user/user.model';
import { CompanyAdministrationData, CompanyAdministrationListAPIRequest } from '#/models/company/administration.model';
import { CompanyAdministrationService } from '#/services/company/company-administration.service';

@Component({
	selector: 'app-division-administration-warning',
	templateUrl: './division-administration-warning.component.html',
	styleUrls: ['./division-administration-warning.component.scss'],
})
export class DivisionAdministrationWarningComponent implements OnInit {
	@Input() receiptDivisionId: string;
	@Input() klippaAdministrationId: string;
	user: User;
	company: Company;
	companyAdministrationsPromise: Promise<CompanyAdministrationData>;
	showWarning = false;

	constructor(private store: Store<AppState>, private companyAdministration: CompanyAdministrationService) {
		store
			.select('company')
			.subscribe((value) => {
				this.company = value.company;
			})
			.unsubscribe();
		store
			.select('user')
			.subscribe((value) => {
				this.user = value.currentUser;
			})
			.unsubscribe();
	}

	ngOnInit(): void {
		const filters = new CompanyAdministrationListAPIRequest();
		filters.company = this.company.id;
		filters.active = true;

		this.companyAdministrationsPromise = this.companyAdministration.getCompanyAdministrations(filters);
		this.determineToShowWarning();
	}

	ngOnChanges(changes: SimpleChanges) {
		if (changes.receiptDivisionId || changes.klippaAdministrationId) {
			this.determineToShowWarning();
		}
	}

	determineToShowWarning() {
		if (this.companyAdministrationsPromise) {
			this.companyAdministrationsPromise.then((data) => {
				const klippaAdministration = data.administrations.find((e) => this.klippaAdministrationId === e.id);
				this.showWarning = this.company?.preferences?.syncadministrations && klippaAdministration?.remote_id !== this.receiptDivisionId;
			});
		}
	}
}
