import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
	selector: 'app-redirect',
	template: '',
})
export class RedirectComponent implements OnInit {
	constructor(private route: ActivatedRoute, private router: Router) {}

	ngOnInit() {
		this.route.queryParams.subscribe((params) => {
			const url = params.url ? decodeURIComponent(params.url) : '';
			this.router.navigateByUrl(url);
		});
	}
}
