<klp-grid-container [fluid]="true">
	<app-nav-and-cta>
		<klp-gretel-bar></klp-gretel-bar>
		<div class="header-actions">
			<app-button *ngIf="getCardModalToShow() !== null" variant="greenFilled" (click)="openUploadModal()">
				<span translate>Import transactions</span>
			</app-button>
			<app-button *ngIf="getPliantTransactionGenerationButtonToShow() | async" variant="greenFilled" (click)="generatePliantTransaction()">
				<span translate>Import pliant transactions</span>
			</app-button>
			<app-button variant="greenFilled" (click)="openFilters()">
				<span translate>Filters</span>
				<app-filters-count [requestQuery]="paginatedExpenseReportsRequest"> </app-filters-count>
			</app-button>
		</div>
	</app-nav-and-cta>
	<div class="header-tabs">
		<app-button
			[variant]="paginatedExpenseReportsRequest.statusses.indexOf('Todo') > -1 ? 'greenFilled' : 'white'"
			aria-haspopup="true"
			aria-expanded="false"
			(click)="onTabTodoClicked()"
			translate
		>
			To do
		</app-button>
		<app-button
			[variant]="paginatedExpenseReportsRequest.statusses.indexOf('Todo') > -1 ? 'white' : 'greenFilled'"
			aria-haspopup="true"
			aria-expanded="false"
			(click)="onTabAllClicked()"
			translate
		>
			All
		</app-button>
	</div>
	<section>
		<div class="card">
			<div class="card-block">
				<ngx-async-template [promise]="reportDeclarationsPromise">
					<ng-template ngx-async-error let-errorMessages="errorMessages">
						<app-error-message [errorMessages]="errorMessages"></app-error-message>
					</ng-template>
					<ng-template ngx-async-pending>
						<app-loader-deprecated></app-loader-deprecated>
					</ng-template>
					<ng-template ngx-async-success let-reports="value">
						<div *ngIf="showSelectActions" class="actionMenu">
							<app-select-action [actions]="getSelectActions" [selected]="selected" (onAction)="executeRequestedSelectAction($event)">
							</app-select-action>
						</div>
						<app-empty-view
							*ngIf="reports.count === 0"
							[text]="'Expense reports will appear here once they are assigned to you.' | translate"
						></app-empty-view>
						<app-table
							*ngIf="reports.count > 0"
							[rows]="reports.reports"
							[total]="reports.count"
							[start]="paginatedExpenseReportsRequest.start"
							[max]="paginatedExpenseReportsRequest.max"
							[sortingParameters]="{ sort: paginatedExpenseReportsRequest.sort, sortorder: paginatedExpenseReportsRequest.sortorder }"
							[columns]="columns"
							typeName="report"
							[itemName]="'report' | translate"
							[itemsName]="'reports' | translate"
							[hasClickableRows]="true"
							[rowActions]="getRowActions"
							[selectAllItemFn]="selectAllReportsOnAllPages"
							(clickRow)="onRowClicked($event)"
							(navigateTable)="navigateTable($event)"
							(sortTable)="sortTable($event)"
							(openSelectActions)="openSelectActions($event)"
							(onSortingArrowClicked)="fetchSortedColumns($event); updateRouteWithSortingParams($event)"
						>
						</app-table>
					</ng-template>
				</ngx-async-template>
			</div>
		</div>
	</section>
</klp-grid-container>
<ng-template #EditAuthFlowModal>
	<app-expense-report-edit-auth-flow-modal (onDismiss)="closeEditAuthFlowModal()" [expenseReport]="currentReport" [user]="user">
	</app-expense-report-edit-auth-flow-modal>
</ng-template>

<app-export-form-modal #exportFormModal [exportIds]="exportIds"> </app-export-form-modal>

<div *ngIf="showFilters">
	<app-filters
		[requestQuery]="paginatedExpenseReportsRequest"
		[filtersLocalStorageKey]="filtersLocalStorageKey"
		[filterOptions]="filterOptions"
		(onDismiss)="closeFilters()"
		(onSave)="updateRequestFilters($event)"
	>
	</app-filters>
</div>

<app-modal #importCreditcardFilesModal (onDismiss)="closeImportCreditcardFilesModal()">
	<ng-template app-modal-header>
		<span translate>Import transactions</span>
	</ng-template>
	<ng-template app-modal-body>
		<app-company-import-creditcard-files></app-company-import-creditcard-files>
	</ng-template>
</app-modal>

<app-manual-card-upload-modal #manualCardUploadModal></app-manual-card-upload-modal>
