import { Injectable } from '@angular/core';
import { environment } from '~/environments/environment';
import { Company } from '#/models/company/company.model';

enum Action {
	HIDE = 'hide',
	SHOW = 'show',
}

@Injectable({
	providedIn: 'root',
})
export class CrispChatService {
	public showOrHideCrispChat(company?: Company): void {
		const shouldHideCrispForEnvironment: boolean = !environment?.show_crisp_chat ?? false;
		const shouldHideCrispForCompany: boolean = company?.modules?.hide_crisp_chat?.enabled;
		const shouldHideCrisp: boolean = shouldHideCrispForEnvironment || shouldHideCrispForCompany;
		const action: Action = shouldHideCrisp ? Action.HIDE : Action.SHOW;
		(<any>window).$crisp?.push(['do', `chat:${action}`]);
	}
}
