<klp-grid-container [fluid]="true">
	<app-nav-and-cta>
		<klp-gretel-bar></klp-gretel-bar>
	</app-nav-and-cta>
	<div class="row">
		<div class="col-md-12">
			<div class="card">
				<div class="card-block">
					<app-loader-deprecated *ngIf="!initialLoaded"></app-loader-deprecated>
					<div *ngIf="initialLoaded" id="DataTables_Table_1_wrapper" class="dataTables_wrapper no-footer">
						<div class="dataTables_length">
							<div class="float-left table-header-action-row" *ngIf="(tags | async).length > 0">
								<label *ngIf="getSelectedTagsCount() > 0"> {{getSelectedTagsCount()}} <span translate>tags selected</span> </label>
								<label *ngIf="getSelectedTagsCount() == 0"> &nbsp; </label>
							</div>
						</div>

						<app-tags-table [tags]="tags | async" [(checkboxes)]="checkboxes" [(checkAll)]="checkAll"></app-tags-table>
					</div>
				</div>
			</div>
		</div>
	</div>
</klp-grid-container>
