<ng-container>
	<li class="pointer" *ngFor="let inboxItem of inboxItems" [ngClass]="{ unread: !inboxItem.isRead() }">
		<a (click)="onClick(inboxItem)">
			<span class="ei" [ngClass]="inboxItem.isRead() ? 'ei-email-open-alt' : 'ei-email'"></span>
			<div class="info">
				<span class="title">
					<span class="description front-size-14" [ngClass]="inboxItem.isRead() ? '' : 'text-semibold'">{{ inboxItem.getTitle() }}</span>
				</span>
				<span class="text-gray sub-title mrg-btm-5">{{
					inboxItem.getMessage() | translate: { status: (statusToLabel(inboxItem.getMetaData().declaration_new_status) | lowercase) }
				}}</span>
				<div class="list-item-footer">
					<span class="sub-title" [attr.title]="inboxItem.metadata.getEmail()">{{ inboxItem.getFrom() }}</span>
					<span class="sub-title" [title]="inboxItem.date">{{ inboxItem.date | timeSince }} ago</span>
				</div>
			</div>
		</a>
	</li>
</ng-container>
