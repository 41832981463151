import { Component, EventEmitter, Host, Input, OnChanges, OnInit, Optional, Output, SimpleChanges } from '@angular/core';
import { ColumnsService } from '~/app/modules/company/services/exporters/columns.service';
import { ExportColumnValues, ExportFormat, ExportRequest, ExportType } from '#/models/company/exporterInterfaces';
import { CsvSeparator, DecimalSeparator } from '#/models/company/export';
import { ControlContainer, NG_VALUE_ACCESSOR, UntypedFormBuilder, Validators } from '@angular/forms';
import { ExportCreditcardStatementsService } from '~/app/modules/company/services/exporters/export-creditcard-statements.service';
import { ReceiptService } from '#/services/transaction/receipt.service';
import { TimezoneService } from '~/app/services/timezone.service';
import { NotificationService } from '~/app/services/notification.service';
import { TranslateService } from '@ngx-translate/core';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { FormElementComponent, ValueAccessorBase } from '@klippa/ngx-enhancy-forms';
import { CompanyService } from '#/services/company/company.service';
import { UserService } from '~/app/modules/user/user.service';
import { ExportTemplateUserRole } from '~/app/modules/export-templates/models/export-template.model';
import { arrayIsSetAndFilled } from '#/util/arrays';
import { User } from '#/models/user/user.model';
import { isNullOrUndefined } from '#/util/values';

@Component({
	selector: 'app-export-form-configuration',
	templateUrl: './export-form-configuration.component.html',
	styleUrls: ['./export-form-configuration.component.scss'],
	providers: [{ provide: NG_VALUE_ACCESSOR, useExisting: ExportFormConfigurationComponent, multi: true }],
})
export class ExportFormConfigurationComponent extends ValueAccessorBase<ExportRequest> implements OnInit, OnChanges {
	@Input() variant: 'createTemplate' | 'automaticExport' | 'normalExport';
	@Input() exportIds: Array<string>;
	@Input() exportType: ExportType;
	@Input() companyExport: boolean;
	@Input() editingExistingTemplate: boolean;
	@Input() exportPromise: Promise<string>;
	@Input() exportRequest: ExportRequest;
	@Input() isEmailRequired: boolean = false;
	@Input() userRole: ExportTemplateUserRole = null;
	@Output() public onDismiss: EventEmitter<void> = new EventEmitter<void>();

	private initialized = false;
	timezone: string;
	showExportColumns = false;
	showExportTravelColumns = false;
	showExportRegistrationColumns = false;
	showExportTravelButton: boolean;
	showExportTravelAndColumns = false;
	exportFormatOptions: Array<{ id: ExportFormat; name: string; disabled?: boolean }>;
	csvSeparatorOptions: Array<{ id: CsvSeparator; name: string }> = [
		{ id: ';', name: ';' },
		{ id: ',', name: ',' },
	];
	decimalOptions: Array<{ id: DecimalSeparator; name: string }> = [
		{ id: '.', name: '.' },
		{ id: ',', name: ',' },
	];

	private fb: UntypedFormBuilder = new UntypedFormBuilder();
	formGroupExportForm = this.fb.group({
		columns: [[]],
		URL: [''],
		travel_columns: [],
		travel_and_receipt_columns: [],
		registrationColumns: [],
		sorted_export_columns: [],
		csv_separator: [],
		decimal_separator: [],
		items: [[]],
		type: [],
		stats: [false],
		overview: [false],
		combine_travel_and_receipts: [false],
		save_template: [false],
		save_as_new_template: [false],
		new_template_name: ['', [Validators.required]],
		split_per_person: [],
		ubl_attachments: [],
		ubl_combine_attachments: [],
		ubl_add_pdf: [],
		empty_footer: [false],
		split_images_from_data: [false],
	});
	public columnLoaderPromise: Promise<any>;
	private user: User;
	public readonly ExportType = ExportType;

	constructor(
		private userService: UserService,
		private companyService: CompanyService,
		private exportCreditcardStatementsService: ExportCreditcardStatementsService,
		private receiptService: ReceiptService,
		private timezoneService: TimezoneService,
		private notificationService: NotificationService,
		private translateService: TranslateService,
		private columnsService: ColumnsService,
		@Host() @Optional() protected parent: FormElementComponent,
		@Host() @Optional() protected controlContainer: ControlContainer,
	) {
		super(parent, controlContainer);
		this.user = this.userService.getCurrentLoggedUser();
	}

	ngOnInit(): void {
		super.ngOnInit();
		this.initialized = true;

		this.formGroupExportForm.valueChanges.subscribe(() => {
			this.setInnerValueAndNotify(this.formGroupExportForm.value);
		});

		this.exportPromise = null;

		this.determineDisabledStateForForm();
	}

	private determineDisabledStateForForm() {
		if (this.disabled) {
			this.formGroupExportForm.disable();
		} else {
			this.formGroupExportForm.enable();
		}
	}

	ngOnChanges(changes: SimpleChanges) {
		if (!this.initialized) {
			return;
		}
		if (changes.exportType) {
			this.initDefaultSettings(changes.exportType.currentValue);
			this.initFormForNew(changes.exportType.currentValue, true);
		}
		this.determineDisabledStateForForm();
	}

	writeValue(value: ExportRequest) {
		super.writeValue(value);
		if (value) {
			if (
				value.type === ExportFormat.XML ||
				arrayIsSetAndFilled(value.columns) ||
				arrayIsSetAndFilled(value.travel_columns) ||
				arrayIsSetAndFilled(value.travel_and_receipt_columns) ||
				arrayIsSetAndFilled(value.registrationColumns)
			) {
				this.initFormWithExportRequest(value);
			} else {
				this.initFormForNew(this.exportType, true);
			}
			this.initDefaultSettings(this.exportType);
		}
	}

	public async initDefaultSettings(exportType: ExportType) {
		if (!this.user.hasProRole() && isNullOrUndefined(this.user.company) && !this.user.hasReadAdminRoleOrHigher()) {
			// if a user is not a pro, and doesn't belong to a company. We just provide the exporting as pdf
			this.exportFormatOptions = [{ id: ExportFormat.PDF, name: 'PDF' }];
			return;
		}
		// the XML option is only enabled when creating a new template when the module is enabled.
		const showXmlOptionForCreatingTemplate = this.hasXmlDragDropOption();
		switch (exportType) {
			case ExportType.RECEIPT:
				this.showExportTravelButton = true;
				this.exportFormatOptions = [
					{ id: ExportFormat.PDF, name: 'PDF' },
					{ id: ExportFormat.UBL, name: 'UBL' },
					{ id: ExportFormat.CSV, name: 'CSV' },
					{ id: ExportFormat.XLSX, name: 'XLSX' },
					{ id: ExportFormat.XML, name: 'XML', disabled: !showXmlOptionForCreatingTemplate },
				];
				break;
			case ExportType.BOOKING:
				this.showExportTravelButton = false;
				this.exportFormatOptions = [
					{ id: ExportFormat.CSV, name: 'CSV' },
					{ id: ExportFormat.XLSX, name: 'XLSX' },
					{ id: ExportFormat.UBL, name: 'UBL' },
					{ id: ExportFormat.XML, name: 'XML', disabled: !showXmlOptionForCreatingTemplate },
				];
				if (this.companyService.getCompanyOfLoggedUser()?.modules.datev_export_type.enabled) {
					this.exportFormatOptions = [...this.exportFormatOptions, { id: ExportFormat.DATEV, name: 'Datev' }];
				}
				break;
			case ExportType.CREDITCARD:
				this.showExportTravelButton = false;
				this.exportFormatOptions = [
					{ id: ExportFormat.CSV, name: 'CSV' },
					{ id: ExportFormat.XLSX, name: 'XLSX' },
					{ id: ExportFormat.PDF, name: 'PDF' },
					{ id: ExportFormat.XML, name: 'XML', disabled: !showXmlOptionForCreatingTemplate },
				];
				break;
			case ExportType.REPORT:
				this.showExportTravelButton = false;
				this.exportFormatOptions = [
					{ id: ExportFormat.CSV, name: 'CSV' },
					{ id: ExportFormat.PDF, name: 'PDF' },
					{ id: ExportFormat.XLSX, name: 'XLSX' },
				];
				break;
			case ExportType.REGISTRATION:
			case ExportType.SUMMARY_REGISTRATION:
				this.showExportTravelButton = false;
				this.exportFormatOptions = [
					{ id: ExportFormat.CSV, name: 'CSV' },
					{ id: ExportFormat.XLSX, name: 'XLSX' },
				];
				break;
		}
	}

	private hasXmlDragDropOption(): boolean {
		if (this.variant !== 'createTemplate') {
			return false;
		}
		if (this.companyService.getCompanyOfLoggedUser()?.modules.show_xml_drag_drop.enabled) {
			return true;
		}
		if (this.userService.getCurrentLoggedUser().hasReadWriteAdminRoleOrHigher()) {
			return true;
		}
		return false;
	}

	public onExportFormatChange() {
		const exportFormat = this.getFormValue('type');
		if (!this.editingExistingTemplate && this.variant === 'createTemplate' && this.exportType === ExportType.RECEIPT) {
			if (exportFormat === 'xlsx' || exportFormat === 'csv') {
				this.formGroupExportForm.patchValue({ combine_travel_and_receipts: true });
			} else {
				this.formGroupExportForm.patchValue({ combine_travel_and_receipts: false });
			}
		} else if (this.exportType === ExportType.REPORT) {
			this.formGroupExportForm.patchValue({ combine_travel_and_receipts: false });
		} else if (this.exportType === ExportType.REGISTRATION) {
			this.formGroupExportForm.patchValue({ combine_travel_and_receipts: false });
		} else if (this.exportType === ExportType.SUMMARY_REGISTRATION) {
			this.formGroupExportForm.patchValue({ combine_travel_and_receipts: false });
		}
		if (exportFormat === 'xml') {
			// reset the columns to an empty list, we dont want anything prefilled when building new xml nodes
			this.formGroupExportForm.patchValue({ columns: [] });
			return;
		}
		this.loadColumnsWithUserSettings();
	}

	public getNumberOfExports(): number {
		return this.exportIds?.length;
	}

	public resetSettings(showConformationMessage = true) {
		if (showConformationMessage) {
			const alertMessage = this.translateService.instant(_('Are you sure you want to reset the settings?'));
			if (!confirm(alertMessage)) {
				return;
			}
		}

		this.showExportColumns = false;
		this.showExportTravelColumns = false;
		this.showExportTravelButton = true;
		this.showExportTravelAndColumns = false;
		this.showExportRegistrationColumns = false;

		this.initDefaultSettings(this.exportType);
		this.initFormForNew(this.exportType, false);

		if (showConformationMessage) {
			const message = this.translateService.instant(_('Export settings reset successfully'));
			this.notificationService.success(message);
		}
	}

	getFormValue(key: string) {
		return this.formGroupExportForm.get(key).value;
	}

	private async initFormForNew(exportType: ExportType, withUserSettings: boolean) {
		let exportFormat: ExportFormat;
		if (exportType === ExportType.CREDITCARD) {
			exportFormat = ExportFormat.CSV;
		} else if (exportType === ExportType.REGISTRATION) {
			exportFormat = ExportFormat.CSV;
		} else if (exportType === ExportType.SUMMARY_REGISTRATION) {
			exportFormat = ExportFormat.CSV;
		} else if (exportType === ExportType.BOOKING) {
			exportFormat = ExportFormat.CSV;
		} else {
			exportFormat = ExportFormat.PDF;
		}

		this.formGroupExportForm.patchValue({
			type: exportFormat,
			overview: null,
			split_per_person: null,
			stats: null,
		});
		if (exportType === ExportType.SUMMARY_REGISTRATION) {
			// this export has no collumns
			return;
		}
		if (withUserSettings) {
			this.loadColumnsWithUserSettings();
		} else {
			this.loadColumnsWithoutUserSettings();
		}
	}

	private loadColumnsWithoutUserSettings() {
		const exportType = this.exportType;
		const exportFormat = this.formGroupExportForm.get('type').value;

		if (exportType === ExportType.CREDITCARD) {
			this.columnLoaderPromise = this.loadCreditCardColumns(exportFormat, []);
		} else if (exportType === ExportType.BOOKING) {
			this.columnLoaderPromise = this.loadCustomIntegrationColumns(exportFormat, []);
		} else if (exportType === ExportType.REGISTRATION) {
			this.columnLoaderPromise = this.loadRegistrationColumns(exportFormat);
		} else {
			this.columnLoaderPromise = this.loadReceiptColumns(exportType, exportFormat, [], [], []);
		}
	}

	private loadColumnsWithUserSettings() {
		const exportType = this.exportType;
		const exportFormat = this.formGroupExportForm.get('type').value;

		if (exportType === ExportType.CREDITCARD) {
			this.columnLoaderPromise = this.loadCreditCardColumns(exportFormat, this.user.export_template?.creditcard_statement_columns);
		} else if (exportType === ExportType.BOOKING) {
			this.columnLoaderPromise = this.loadCustomIntegrationColumns(exportFormat, this.user.export_template?.user_integration_columns);
		} else if (exportType === ExportType.REGISTRATION) {
			this.columnLoaderPromise = this.loadRegistrationColumns(exportFormat);
		} else {
			this.columnLoaderPromise = this.loadReceiptColumns(
				exportType,
				exportFormat,
				this.user.export_template?.columns,
				this.user.export_template?.travel_columns,
				this.user.export_template?.travel_and_receipt_columns,
			);
		}
	}

	private loadColumnsWithExportRequest(exportRequest: ExportRequest) {
		const exportType = this.exportType;
		const exportFormat = this.formGroupExportForm.get('type').value;
		if (exportType === ExportType.CREDITCARD) {
			this.columnLoaderPromise = this.loadCreditCardColumns(exportFormat, exportRequest.columns);
		} else if (exportType === ExportType.BOOKING) {
			this.columnLoaderPromise = this.loadCustomIntegrationColumns(exportFormat, exportRequest.columns);
		} else if (exportType === ExportType.REGISTRATION) {
			this.columnLoaderPromise = this.loadRegistrationColumns(exportFormat);
		} else {
			this.columnLoaderPromise = this.loadReceiptColumns(
				exportType,
				exportFormat,
				exportRequest.columns,
				exportRequest.travel_columns,
				exportRequest.travel_and_receipt_columns,
			);
		}
	}

	private loadCreditCardColumns(exportFormat: ExportFormat, ccColumns) {
		// Resolving this because the XML format is loaded differently and otherwise this will overwrite it.
		if (exportFormat === ExportFormat.XML) {
			return Promise.resolve();
		}
		let creditCardColsPromise;
		if (this.userRole === ExportTemplateUserRole.admin) {
			creditCardColsPromise = this.columnsService.getCreditCardColumnsAsGlobalAdminPatchedBy(exportFormat, ccColumns);
		} else {
			creditCardColsPromise = this.columnsService.getCreditCardColumnsPatchedBy(exportFormat, ccColumns);
		}
		creditCardColsPromise.then((cols) => {
			this.formGroupExportForm.patchValue({
				columns: cols,
			});
		});
		return Promise.all([creditCardColsPromise]);
	}

	private loadCustomIntegrationColumns(exportFormat: ExportFormat, bookingColumns): Promise<any> {
		// Resolving this because the XML format is loaded differently and otherwise this will overwrite it.
		if (exportFormat === ExportFormat.XML) {
			return Promise.resolve();
		}
		let customIntegrationColsPromise;
		if (this.userRole === ExportTemplateUserRole.admin) {
			customIntegrationColsPromise = this.columnsService.getCustomIntegrationAsGlobalAdminPatchedBy(exportFormat, bookingColumns);
		} else {
			customIntegrationColsPromise = this.columnsService.getCustomIntegrationColumnsPatchedBy(exportFormat, bookingColumns);
		}
		customIntegrationColsPromise.then((cols) => {
			this.formGroupExportForm.patchValue({
				columns: cols,
			});
		});
		return Promise.all([customIntegrationColsPromise]);
	}

	private loadRegistrationColumns(exportFormat: ExportFormat): Promise<Array<Array<ExportColumnValues>>> {
		const registrationColsPromise = this.columnsService.getRegistrationColumnsFromApi(exportFormat);
		registrationColsPromise.then((exportColumnValues: Array<ExportColumnValues>) => {
			this.formGroupExportForm.patchValue({
				registrationColumns: exportColumnValues,
			});
		});
		return Promise.all([registrationColsPromise]);
	}

	private loadReceiptColumns(exportType: ExportType, exportFormat: ExportFormat, receiptCols, travelCols, receiptTravelMixCols) {
		// Resolving this because the XML format is loaded differently and otherwise this will overwrite it.
		if (exportFormat === ExportFormat.XML) {
			return Promise.resolve();
		}
		let regularColsPromise, travelColsPromise, receiptAndTravelColsPromise;
		if (this.userRole === ExportTemplateUserRole.admin && exportType !== ExportType.REPORT) {
			regularColsPromise = this.columnsService.getRegularColumnsAsGlobalAdminPatchedBy(exportType, exportFormat, receiptCols);
			travelColsPromise = this.columnsService.getTravelColumnsAsGlobalAdminPatchedBy(exportType, exportFormat, travelCols);
			receiptAndTravelColsPromise = this.columnsService.getReceiptAndTravelColumnsAsGlobalAdminPatchedBy(
				exportType,
				exportFormat,
				receiptTravelMixCols,
			);
		} else if (exportType === ExportType.REPORT) {
			regularColsPromise = this.columnsService.getReportColumnsPatchedBy(exportType, exportFormat, receiptCols);
			travelColsPromise = Promise.resolve([]);
			receiptAndTravelColsPromise = Promise.resolve([]);
		} else {
			regularColsPromise = this.columnsService.getRegularColumnsPatchedBy(exportType, exportFormat, receiptCols);
			travelColsPromise = this.columnsService.getTravelColumnsPatchedBy(exportType, exportFormat, travelCols);
			receiptAndTravelColsPromise = this.columnsService.getReceiptAndTravelColumnMixedPatchedBy(
				exportType,
				exportFormat,
				receiptTravelMixCols,
			);
		}

		const loaderPromises = [regularColsPromise, travelColsPromise, receiptAndTravelColsPromise];
		Promise.all(loaderPromises).then((colsCollection) => {
			this.formGroupExportForm.patchValue({
				columns: colsCollection[0],
				travel_columns: colsCollection[1],
				travel_and_receipt_columns: colsCollection[2],
			});
		});
		return Promise.all(loaderPromises);
	}

	private initFormWithExportRequest(exportRequest: ExportRequest) {
		this.formGroupExportForm.patchValue(exportRequest);
		if (exportRequest.type !== 'xml' || this.exportType !== ExportType.SUMMARY_REGISTRATION) {
			this.loadColumnsWithExportRequest(exportRequest);
		}
	}

	public isExportFormat(...type: Array<string>) {
		return type.includes(this.getFormValue('type'));
	}

	public exportUsesCombineTravelAndReceipt(): boolean {
		if (
			this.exportType === ExportType.RECEIPT ||
			this.exportType === ExportType.REPORT ||
			this.exportType === ExportType.CREDITCARD ||
			this.exportType === ExportType.BOOKING
		) {
			return true;
		}
		return false;
	}
}
