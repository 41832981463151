import { Injectable } from '@angular/core';
import { memoize } from 'lodash';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { StatisticsService } from '~/app/pages/authenticated/statistics/statistics.service';
import { FolderService } from '../modules/folder/folder.service';
import { IconService } from '../modules/icon/icon.service';
import { MerchantService } from '../modules/merchant/merchant.service';
import { UserService } from '../modules/user/user.service';
import { GenericService } from '../modules/generic/generic.service';
import { IntegrationService } from '#/services/integrationService';
import { WebsocketsService } from '../websockets/websockets/websockets.service';
import { CurrencyService } from '#/services/currency.service';
import { PaymentMethodService } from '#/services/company/payment-method.service';
import { CompanyAuthorizationService } from '#/services/company/company-authorization.service';
import { CompanyService } from '#/services/company/company.service';
import { AnnouncementsService } from '../services/announcements.service';
import { CompanyFeatureFlagsService } from '#/services/company/company-feature-flags.service';
import { stringIsSetAndFilled } from '#/util/values';

@Injectable()
export class InitializeAppResolver implements Resolve<any> {
	constructor(
		private userService: UserService,
		private companyService: CompanyService,
		private genericService: GenericService,
		private currencyService: CurrencyService,
		private folderService: FolderService,
		private iconService: IconService,
		private merchantService: MerchantService,
		private statisticsService: StatisticsService,
		private integrationService: IntegrationService,
		private websocketService: WebsocketsService,
		private paymentMethodService: PaymentMethodService,
		private companyAuthorizationService: CompanyAuthorizationService,
		private announcementService: AnnouncementsService,
		private companyFeatureFlagsService: CompanyFeatureFlagsService,
	) {}

	resolve = memoize(async (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
		await this.userService.initializeUser(state);
		await this.companyService.initializeCompany();
		await this.genericService.loadGenericValues();

		await Promise.all([
			this.companyService.initializeCompanyUsers(),
			this.integrationService.initializeIntegrations(),
			this.folderService.initializeFolders(),
			this.iconService.initializeIcons(),
			this.merchantService.initializeMerchants(),
			this.statisticsService.initializeTags(),
			this.paymentMethodService.initializePaymentMethods(),
			this.companyAuthorizationService.initializeAuthorizationFlows(),
			this.currencyService.preloadCurrencies(),
		]);
		if (stringIsSetAndFilled(this.userService.getCurrentLoggedUser()?.company)) {
			await this.companyFeatureFlagsService.getAllFeatureFlags(this.userService.getCurrentLoggedUser()?.company);
		}

		this.announcementService.showAnnouncements();

		this.websocketService.setWebsockets();
	});
}
