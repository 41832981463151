<div class="modal-header">
	<h4 *ngIf="isInEditMode" class="modal-title" translate>Update report</h4>
	<h4 *ngIf="!isInEditMode" class="modal-title" translate>New report</h4>
	<button (click)="closeModal()" aria-label="Close" class="close" type="button">
		<span aria-hidden="true">&times;</span>
	</button>
</div>

<klp-form class="modal-form" [formGroup]="expenseReportForm">
	<div class="modal-body">
		<klp-form-element direction="vertical" [caption]="'Description' | translate">
			<klp-form-text-input formControlName="description"> </klp-form-text-input>
		</klp-form-element>
		<klp-form-element direction="vertical" [caption]="'Administration' | translate" *ngIf="showAdministrationPicker">
			<app-administration-picker
				formControlName="administration"
				(ngModelChange)="administrationChanged($event)"
				[multiple]="false"
			></app-administration-picker>
		</klp-form-element>
		<klp-form-element direction="vertical" [caption]="'Cost center' | translate" *ngIf="dimensions?.costcenter.visible">
			<app-cost-center-picker
				formControlName="cost_center"
				[multiple]="false"
				[shouldBelongToAdministrationIds]="[expenseReportForm.get('administration').value]"
			></app-cost-center-picker>
		</klp-form-element>
		<klp-form-element direction="vertical" [caption]="'Cost unit' | translate" *ngIf="dimensions?.costunit.visible">
			<app-cost-unit-picker
				formControlName="cost_unit"
				[shouldBelongToAdministrationIds]="[expenseReportForm.get('administration').value]"
				[multiple]="false"
			></app-cost-unit-picker>
		</klp-form-element>
		<klp-form-element direction="vertical" [caption]="'Project' | translate" *ngIf="dimensions?.project.visible">
			<app-project-picker
				formControlName="project"
				[multiple]="false"
				[shouldBelongToAdministrationIds]="[expenseReportForm.get('administration').value]"
			>
			</app-project-picker>
		</klp-form-element>
		<klp-form-element direction="vertical" [caption]="'Currency' | translate" *ngIf="showCurrencyPicker">
			<app-currency-picker formControlName="currency"></app-currency-picker>
		</klp-form-element>
	</div>
	<div class="modal-footer">
		<klp-form-submit-button aria-haspopup="true" aria-expanded="false" [submitCallback]="saveReport">
			<span *ngIf="isInEditMode" translate>Save</span>
			<span *ngIf="!isInEditMode" translate>Create</span>
		</klp-form-submit-button>
	</div>
</klp-form>
