import { Component, Input } from '@angular/core';
import { PaymentMethod, staticPaymentMethods } from '#/services/company/payment-method.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
	selector: 'app-payment-method-label',
	templateUrl: './payment-method-label.component.html',
})
export class PaymentMethodLabelComponent {
	protected paymentMethods = [];
	@Input()
	public paymentMethod: PaymentMethod = PaymentMethod.None;

	constructor(protected translate: TranslateService) {
		this.paymentMethods = staticPaymentMethods.map((e) => ({
			id: e.id,
			name: this.translate.instant(e.name),
		}));
	}

	public getPaymentMethodLabel(): string {
		const paymentMethod: { id: string; name: string } = this.paymentMethods.find((p) => p.id === this.paymentMethod);
		if (paymentMethod?.id !== PaymentMethod.None) {
			return paymentMethod.name;
		}
		return '';
	}
}
