import { Injectable } from '@angular/core';
import { APIService } from '~/app/api/services/api.service';
import { APINotificationsService } from '~/app/api/services/apinotifications.service';
import { CompanyService } from '#/services/company/company.service';
import {
	AccountingIntegrationV1,
	AMBookingSuggestion,
	Company,
	ConfigurationFieldSelectOption,
	Division,
	DivisionData,
	DMBookingSuggestion,
	GeneralLedger,
	GeneralLedgerData,
	IntegrationDimensionData,
	IntegrationDimensionDataCount,
	IntegrationDimensionDataPaginated,
	IntegrationDimensionInfo,
	IntegrationDimensionInfoData,
	IntegrationPurchaseOrder,
	IntegrationPurchaseOrderData,
	IntegrationRelation,
	IntegrationRelationData,
	Journal,
	JournalData,
	VATCode,
	VATCodeData,
} from '#/models/company/company.model';
import { PaymentCondition, PaymentConditionData } from '#/models/company/payment-condition.model';
import { IntegrationPaymentMethod, IntegrationPaymentMethodData } from '../../models/company/integration-payment-method.model';
import { isValueSet, stringIsSetAndFilled } from '../../util/values';
import { Order } from '#/models/utils/order';
import { objectToQueryParamString } from '#/util/objects';
import { AccountingBookingType } from '#/models/transaction/bookingType';

const cacheTimingMs = 60 * 1000;

@Injectable({
	providedIn: 'root',
})
export class CompanyIntegrationService {
	constructor(private apiService: APIService, private notifications: APINotificationsService, private companyService: CompanyService) {}

	public clearCache(): void {
		this.apiService.clearCache();
	}

	public getCompanyOfLoggedUser(): Company {
		return this.companyService.getCompanyOfLoggedUser();
	}

	private async exhaustEndpointDeprecated(fn: (start: number) => Promise<any>, itemsName: string): Promise<any> {
		let start = 0;
		let hasMoreResults = true;
		const result = [];
		while (hasMoreResults) {
			const res = await fn(start);
			start += res[itemsName].length;
			hasMoreResults = res.moreresults;
			result.push(...res[itemsName]);
		}
		return result;
	}

	getDivisions(
		company: Company,
		integration: string,
		start: number = 0,
		search: string = '',
		max: number = 100,
		sort: string = 'Code',
		sortOrder: Order = Order.ASCENDING,
	): Promise<DivisionData> {
		return this.apiService
			.get(
				`/api/v1/company/${company.getID()}/integrations/${integration}/divisions?start=${start}&max=${max}&sort=${sort}&sortorder=${sortOrder}&search=${encodeURIComponent(
					search,
				)}`,
			)
			.then((r) => new DivisionData(r.data))
			.catch((e) => {
				this.notifications.handleAPIError(e);
				throw e;
			});
	}

	async getAllDivisionsDeprecated(company: Company, integration: string, search: string = ''): Promise<Division[]> {
		return this.exhaustEndpointDeprecated((start) => this.getDivisions(company, integration, start, search, 999), 'divisions');
	}

	getDivisionsByIds(company: Company, integration: string, ids: Array<string>): Promise<Division[]> {
		return this.apiService
			.postToApi(`company/${company.getID()}/integrations/${integration}/divisions/list`, {
				ids,
			})
			.then((res) => Division.fromData(res.data.divisions));
	}

	getDivisionsForCustomIntegration(company: Company): Promise<Array<any>> {
		return this.apiService.post(`/api/v1/company/${company.getID()}/customIntegration/Division/list`, {}).then((r) => r.data.results);
	}

	getGeneralLedgers(
		company: Company,
		integration: string,
		division: string,
		start: number = 0,
		search: string = '',
		max: number = 100,
	): Promise<GeneralLedgerData> {
		const url = `/api/v1/company/${company.getID()}/integrations/${integration}/generalledgers?division=${division}&start=${start}&max=${max}&sort=Code&sortorder=desc&search=${encodeURIComponent(
			search,
		)}`;
		return this.apiService
			.get(url, cacheTimingMs)
			.then((r) => new GeneralLedgerData(r.data))
			.catch((e) => {
				this.notifications.handleAPIError(e);
				throw e;
			});
	}

	getAllGeneralLedgersDeprecated(company: Company, integration: string, division: string): Promise<Array<GeneralLedger>> {
		return this.exhaustEndpointDeprecated(
			(start) => this.getGeneralLedgers(company, integration, division, start, undefined, 999),
			'general_ledgers',
		);
	}

	getGeneralLedgersByIds(company: Company, integration: string, division: string, ids: Array<string>): Promise<Array<GeneralLedger>> {
		return this.apiService
			.postToApi(`company/${company.getID()}/integrations/${integration}/generalledgers/list?division=${division}`, {
				ids,
			})
			.then((res) => GeneralLedger.fromData(res.data.general_ledgers));
	}

	getJournals(
		company: Company,
		integration: string,
		division: string,
		start: number = 0,
		search: string = '',
		sort: string = 'Code',
		sortOrder: Order = Order.ASCENDING,
		max: number = 100,
	): Promise<JournalData> {
		return this.apiService
			.get(
				`/api/v1/company/${company.getID()}/integrations/${integration}/journals?division=${division}&start=${start}&max=${max}&sort=${sort}&sortorder=${sortOrder}&search=${encodeURIComponent(
					search,
				)}`,
			)
			.then((r) => new JournalData(r.data))
			.catch((e) => {
				this.notifications.handleAPIError(e);
				throw e;
			});
	}

	getAllJournalsDeprecated(company: Company, integration: string, division: string): Promise<Journal[]> {
		return this.exhaustEndpointDeprecated(
			(start) => this.getJournals(company, integration, division, start, undefined, undefined, undefined, 999),
			'journals',
		);
	}

	getJournalsByIds(company: Company, integration: string, division: string, ids: Array<string>): Promise<Journal[]> {
		return this.apiService
			.postToApi(`company/${company.getID()}/integrations/${integration}/journals/list?division=${division}`, {
				ids,
			})
			.then((res) => Journal.fromData(res.data.journals));
	}

	getIntegrationPurchaseOrders(
		company: Company,
		integration: string,
		division?: string,
		supplier?: string,
		start: number = 0,
		search: string = '',
		max: number = 100,
	): Promise<IntegrationPurchaseOrderData> {
		const url = `/api/v1/company/${company.getID()}/integrations/${integration}/purchaseorders?division=${division || ''}&supplier=${
			supplier || ''
		}&start=${start}&max=${max}&search=${encodeURIComponent(search)}`;
		return this.apiService
			.get(url, cacheTimingMs)
			.then((r) => new IntegrationPurchaseOrderData(r.data))
			.catch((e) => {
				this.notifications.handleAPIError(e);
				throw e;
			});
	}

	getAllIntegrationPurchaseOrdersDeprecated(
		company: Company,
		integration: string,
		division?: string,
		supplier?: string,
	): Promise<IntegrationPurchaseOrder[]> {
		return this.exhaustEndpointDeprecated(
			(start) => this.getIntegrationPurchaseOrders(company, integration, division, supplier, start, undefined, 999),
			'purchase_orders',
		);
	}

	async getAllIntegrationRelationsDeprecated(
		company: Company,
		integration: string,
		division: string,
		search: string = '',
	): Promise<IntegrationRelation[]> {
		return this.exhaustEndpointDeprecated(
			(start) => this.getIntegrationRelations(company, integration, division, start, search, 999),
			'relations',
		);
	}

	getIntegrationRelations(
		company: Company,
		integration: string,
		division: string,
		start: number = 0,
		search: string = '',
		max: number = 100,
		sort: string = 'Code',
		sortOrder: Order = Order.ASCENDING,
	): Promise<IntegrationRelationData> {
		return this.apiService
			.get(
				`/api/v1/company/${company.getID()}/integrations/${integration}/relations?division=${division}&start=${start}&max=${max}&sort=${sort}&sortorder=${sortOrder}&search=${encodeURIComponent(
					search,
				)}`,
			)
			.then((r) => {
				return new IntegrationRelationData(r.data);
			})
			.catch((e) => {
				this.notifications.handleAPIError(e);
				throw e;
			});
	}

	getIntegrationRelationsByIds(
		companyId: string,
		integration: string,
		division: string,
		ids: Array<string>,
	): Promise<IntegrationRelation[]> {
		return this.apiService
			.postToApi(`company/${companyId}/integrations/${integration}/relations/list?division=${division}`, {
				ids,
			})
			.then((r) => IntegrationRelation.fromData(r.data.relations))
			.catch((e) => {
				this.notifications.handleAPIError(e);
				throw e;
			});
	}

	getIntegrationRelation(company: Company, integration: string, division: string, identifier: string): Promise<IntegrationRelation> {
		return this.apiService
			.get(`/api/v1/company/${company.getID()}/integrations/${integration}/relation/${identifier}?division=${division}`)
			.then((r) => {
				return new IntegrationRelation(r.data);
			});
	}

	getIntegrationRelationDropdownOptions(
		company: Company,
		integration: string,
		division: string,
		dropdownKey: string,
	): Promise<{ Options?: { Key: string; Name: string }[]; Selected?: string }> {
		return this.apiService
			.get(`/api/v1/company/${company.getID()}/integrations/${integration}/dropdownData/${dropdownKey}?division=${division}`)
			.then((r) => r.data)
			.catch((e) => {
				this.notifications.handleAPIError(e);
				throw e;
			});
	}

	getIntegrationPaymentConditions(
		companyId: string,
		integration: string,
		division: string,
		start: number = 0,
		search: string = '',
		max: number = 100,
		sort: string = 'Code',
		sortOrder: Order = Order.ASCENDING,
	): Promise<PaymentConditionData> {
		return this.apiService
			.get(
				`/api/v1/company/${companyId}/integrations/${integration}/paymentconditions/?division=${division}&start=${start}&max=${max}&sort=${sort}&sortorder=${sortOrder}&search=${encodeURIComponent(
					search,
				)}`,
			)
			.then((r) => {
				return new PaymentConditionData(r.data);
			})
			.catch((e) => {
				this.notifications.handleAPIError(e);
				throw e;
			});
	}

	getIntegrationPaymentConditionsByIds(
		companyId: string,
		integration: string,
		division: string,
		ids: Array<string>,
	): Promise<Array<PaymentCondition>> {
		return this.apiService
			.postToApi(`company/${companyId}/integrations/${integration}/paymentconditions/list?division=${division}`, {
				ids,
			})
			.then((r) => {
				return PaymentCondition.fromData(r.data.payment_conditions);
			})
			.catch((e) => {
				this.notifications.handleAPIError(e);
				throw e;
			});
	}

	getIntegrationPaymentMethods(
		companyId: string,
		integration: string,
		division: string,
		start: number = 0,
		search: string = '',
		ids: Array<string> = null,
		max: number = 100,
	): Promise<IntegrationPaymentMethodData> {
		const postBody = { ids };
		return this.apiService
			.postToApi(
				`company/${companyId}/integrations/${integration}/paymentmethods/list?start=${start}&max=${max}&search=${search}&division=${division}`,
				postBody,
			)
			.then((r) => {
				return new IntegrationPaymentMethodData(r.data);
			})
			.catch((e) => {
				this.notifications.handleAPIError(e);
				throw e;
			});
	}

	public getIntegrationPaymentMethodsByIds(
		companyId: string,
		integration: string,
		division: string,
		ids: Array<string>,
	): Promise<Array<IntegrationPaymentMethod>> {
		return this.apiService
			.postToApi(`company/${companyId}/integrations/${integration}/paymentmethods/list?division=${division}`, { ids })
			.then((r) => IntegrationPaymentMethod.fromData(r.data.payment_methods))
			.catch((e) => {
				this.notifications.handleAPIError(e);
				throw e;
			});
	}

	getIntegrationDimensions(
		company: Company,
		integration: string,
		division: string,
		start: number = 0,
		search: string = '',
		max: number = 100,
	): Promise<IntegrationDimensionInfoData> {
		return this.apiService
			.get(
				`/api/v1/company/${company.getID()}/integrations/${integration}/dimensions?division=${division}&start=${start}&max=${max}&search=${encodeURIComponent(
					search,
				)}`,
				cacheTimingMs,
			)
			.then((res) => new IntegrationDimensionInfoData(res.data))
			.catch((e) => {
				this.notifications.handleAPIError(e);
				throw e;
			});
	}

	getAllIntegrationDimensionsDeprecated(company: Company, integration: string, division: string): Promise<IntegrationDimensionInfo[]> {
		return this.exhaustEndpointDeprecated(
			(start) => this.getIntegrationDimensions(company, integration, division, start, undefined, 999),
			'dimensions',
		);
	}

	getIntegrationDimensionData(
		company: Company,
		integration: string,
		division: string,
		dimension: string,
		active = true,
		search_field?: string,
		start: number = 0,
		search: string = '',
		max: number = 100,
		sort: string = 'code',
		sortOrder: Order = Order.ASCENDING,
		ids: Array<string> = null,
	): Promise<IntegrationDimensionDataPaginated> {
		const url = `/api/v1/company/${company.getID()}/integrations/${integration}/dimensionData/${dimension}?division=${division}&start=${start}&max=${max}&sort=${sort}&sortorder=${sortOrder}&search=${encodeURIComponent(
			search,
		)}`;
		const body = {
			active,
			search_field,
			ids,
		};
		return this.apiService
			.post(url, body, cacheTimingMs)
			.then((res) => new IntegrationDimensionDataPaginated(res.data))
			.catch((e) => {
				this.notifications.handleAPIError(e);
				throw e;
			});
	}

	getAllIntegrationDimensionDataDeprecated(
		company: Company,
		integration: string,
		division: string,
		dimension: string,
		active = true,
		search_field?: string,
		search?: string,
	): Promise<IntegrationDimensionData[]> {
		return this.exhaustEndpointDeprecated(
			(start) => this.getIntegrationDimensionData(company, integration, division, dimension, active, search_field, start, search, 999),
			'dimension_data',
		);
	}

	getIntegrationDimensionDataByIds(
		company: Company,
		integration: string,
		division: string,
		dimension: string,
		ids: Array<string>,
	): Promise<IntegrationDimensionData[]> {
		return this.getIntegrationDimensionData(
			company,
			integration,
			division,
			dimension,
			undefined,
			undefined,
			undefined,
			undefined,
			100,
			undefined,
			undefined,
			ids,
		).then((res) => res.dimension_data);
	}

	getIntegrationDimensionDataCount(
		company: Company,
		integration: string,
		division: string,
		dimension: string,
		active = true,
	): Promise<IntegrationDimensionDataCount> {
		const url = `/api/v1/company/${company.getID()}/integrations/${integration}/dimensionDataCount/${dimension}?division=${division}`;
		const body = { active };
		return this.apiService
			.post(url, body)
			.then((r) => new IntegrationDimensionDataCount(r.data))
			.catch((e) => {
				this.notifications.handleAPIError(e);
				throw e;
			});
	}

	createIntegrationRelation(
		company: Company,
		integration: string,
		division: string,
		data: { isOrg: boolean; values: { [key: string]: string } },
	) {
		return this.apiService
			.post(`/api/v1/company/${company.getID()}/integrations/${integration}/relation?division=${division}`, data)
			.then((r) => {
				const relation = new IntegrationRelation(r.data);
				return Promise.resolve(relation);
			})
			.catch((e) => {
				this.notifications.handleAPIError(e);
				throw e;
			});
	}

	getVATCodes(
		company: Company,
		integration: string,
		division: string,
		date: string = '',
		transaction_type = '',
		start: number = 0,
		search: string = '',
		max: number = 100,
		sort: string = 'id',
		sortOrder: Order = Order.ASCENDING,
	): Promise<VATCodeData> {
		const url = `/api/v1/company/${company.getID()}/integrations/${integration}/vats?division=${division}&date=${date}&transaction_type=${transaction_type}&start=${start}&max=${max}&sort=${sort}&sortorder=${sortOrder}&search=${encodeURIComponent(
			search,
		)}`;
		return this.apiService
			.get(url, cacheTimingMs)
			.then((r) => new VATCodeData(r.data))
			.catch((e) => {
				this.notifications.handleAPIError(e);
				throw e;
			});
	}

	getAllVATCodesDeprecated(
		company: Company,
		integration: string,
		division: string,
		date: string = '',
		transaction_type = '',
	): Promise<VATCode[]> {
		return this.exhaustEndpointDeprecated(
			(start) => this.getVATCodes(company, integration, division, date, transaction_type, start),
			'vats',
		);
	}

	async isActiveIntegration(companyId: string, integrationId: string): Promise<boolean> {
		const activeIntegrations = await this.getActiveIntegrations(companyId);
		return activeIntegrations.some((integration) => integration.id === integrationId);
	}

	getVATCodesByIds(
		company: Company,
		integration: string,
		division: string,
		date: string = '',
		transaction_type = '',
		ids: Array<string>,
	): Promise<VATCode[]> {
		return this.apiService
			.postToApi(
				`company/${company.getID()}/integrations/${integration}/vats/list?division=${division}&date=${date}&transaction_type=${transaction_type}`,
				{
					ids,
				},
			)
			.then((res) => VATCode.fromData(res.data.vats));
	}

	IntegrationOAuth1RegisterStart(company: Company, integration: AccountingIntegrationV1, redirecturi: string): Promise<string> {
		const body = {
			redirecturi: redirecturi,
		};

		return this.apiService
			.post('/api/v1/company/' + company.getID() + '/integrations/' + integration.getKey() + '/oauth1/dialog', body)
			.then((r) => {
				return Promise.resolve(r.data.url);
			})
			.catch((e) => {
				this.notifications.handleAPIError(e);
				return Promise.reject(e);
			});
	}

	IntegrationOAuth1RegisterComplete(
		company: Company,
		integration: AccountingIntegrationV1,
		oauth_token: string,
		oauth_verifier: string,
		configuration?: any,
		extra?: any,
	): Promise<Company> {
		const body = {
			oauth_token: oauth_token,
			oauth_verifier: oauth_verifier,
			configuration: {},
			extra: extra,
		};

		if (configuration) {
			body['configuration'] = configuration;
		}

		return this.apiService
			.post('/api/v1/company/' + company.getID() + '/integrations/' + integration.getKey() + '/oauth1/authorize', body)
			.then((r) => {
				const updated_company = new Company(r.data);
				return Promise.resolve(updated_company);
			})
			.catch((e) => {
				this.notifications.handleAPIError(e);
				return Promise.reject(e);
			});
	}

	IntegrationOAuth2RegisterStart(
		company: Company,
		integration: AccountingIntegrationV1,
		redirecturi: string,
		baseURL = '',
	): Promise<string> {
		const body = {
			redirecturi: redirecturi,
			base_url: baseURL,
		};

		return this.apiService
			.post('/api/v1/company/' + company.getID() + '/integrations/' + integration.getKey() + '/oauth2/dialog', body)
			.then((r) => {
				return Promise.resolve(r.data.url);
			})
			.catch((e) => {
				this.notifications.handleAPIError(e);
				return Promise.reject(e);
			});
	}

	clearIntegrationsCache(companyId: string, integration: string): Promise<any> {
		return this.apiService.get(`/api/v1/company/${companyId}/integrations/${integration}/clearCache`).catch((e) => {
			this.notifications.handleAPIError(e);
			throw e;
		});
	}

	getActiveIntegrations(companyId: string): Promise<Array<AccountingIntegrationV1>> {
		return this.apiService
			.get(`/api/v1/company/${companyId}/integrations`, cacheTimingMs)
			.then((r) => {
				return AccountingIntegrationV1.fromData(r.data).filter((e) =>
					this.companyService.getCompanyOfLoggedUser().authorizations.has(e.Key),
				);
			})
			.catch((e) => {
				this.notifications.handleAPIError(e);
				throw e;
			});
	}

	getAllIntegrations(id: string): Promise<Array<AccountingIntegrationV1>> {
		return this.apiService
			.get(`/api/v1/company/${id}/integrations`, cacheTimingMs)
			.then((r) => AccountingIntegrationV1.fromData(r.data))
			.catch((e) => {
				this.notifications.handleAPIError(e);
				throw e;
			});
	}

	getIntegration(companyId: string, integrationId: string): Promise<AccountingIntegrationV1> {
		return this.apiService
			.get(`/api/v1/company/${companyId}/integrations/${integrationId}`)
			.then((r) => {
				return new AccountingIntegrationV1(r.data);
			})
			.catch((e) => {
				this.notifications.handleAPIError(e);
				throw e;
			});
	}

	getConfigurationFieldSelectOptions(company: Company, integration: string, field: string): Promise<ConfigurationFieldSelectOption[]> {
		return this.apiService
			.get(`/api/v1/company/${company.getID()}/integrations/${integration}/ConfigurationFieldSelectOptions?field=${field}`)
			.then((r) => {
				const configurationFieldSelectOptions = ConfigurationFieldSelectOption.fromData(r.data);
				return Promise.resolve(configurationFieldSelectOptions);
			})
			.catch((e) => {
				this.notifications.handleAPIError(e);
				return Promise.reject(e);
			});
	}

	IntegrationOAuth2RegisterComplete(
		company: Company,
		integration: AccountingIntegrationV1,
		code: string,
		state: string,
		configuration?: any,
		extra?: any,
	): Promise<Company> {
		const body = {
			code: code,
			state: state,
			configuration: {},
			extra: extra,
		};

		if (configuration) {
			body['configuration'] = configuration;
		}

		return this.apiService
			.post('/api/v1/company/' + company.getID() + '/integrations/' + integration.getKey() + '/oauth2/authorize', body)
			.then((r) => {
				const updated_company = new Company(r.data);
				return Promise.resolve(updated_company);
			})
			.catch((e) => {
				this.notifications.handleAPIError(e);
				return Promise.reject(e);
			});
	}

	IntegrationDisconnect(company: Company, integration: AccountingIntegrationV1): Promise<Company> {
		return this.apiService
			.delete('/api/v1/company/' + company.getID() + '/integrations/' + integration.getKey())
			.then((r) => {
				const updated_company = new Company(r.data);
				return Promise.resolve(updated_company);
			})
			.catch((e) => {
				this.notifications.handleAPIError(e);
				return Promise.reject(e);
			});
	}

	ConnectKeySecurityCode(
		company: Company,
		integration: AccountingIntegrationV1,
		key: string,
		security_code: string,
		configuration?: any,
	): Promise<Company> {
		const body = {
			key: key,
			security_code: security_code,
			configuration: configuration,
		};

		return this.apiService
			.post('/api/v1/company/' + company.getID() + '/integrations/' + integration.getKey() + '/key_security_code', body)
			.then((r) => {
				const updated_company = new Company(r.data);
				return Promise.resolve(updated_company);
			})
			.catch((e) => {
				this.notifications.handleAPIError(e);
				return Promise.reject(e);
			});
	}

	ConnectKey(company: Company, integration: AccountingIntegrationV1, key: string, configuration: any): Promise<Company> {
		const body = {
			key: key,
			configuration: configuration,
		};

		return this.apiService
			.post('/api/v1/company/' + company.getID() + '/integrations/' + integration.getKey() + '/key', body)
			.then((r) => {
				const updated_company = new Company(r.data);
				return Promise.resolve(updated_company);
			})
			.catch((e) => {
				this.notifications.handleAPIError(e);
				return Promise.reject(e);
			});
	}

	ConnectCustom(company: Company, integration: AccountingIntegrationV1, configuration: any): Promise<Company> {
		const body = {
			configuration: configuration,
		};

		return this.apiService
			.post('/api/v1/company/' + company.getID() + '/integrations/' + integration.getKey() + '/custom', body)
			.then((r) => {
				const updated_company = new Company(r.data);
				return Promise.resolve(updated_company);
			})
			.catch((e) => {
				this.notifications.handleAPIError(e);
				return Promise.reject(e);
			});
	}

	ConnectInternal(company: Company, integration: AccountingIntegrationV1, configuration: any): Promise<Company> {
		const body = {
			configuration: configuration,
		};

		return this.apiService
			.post('/api/v1/company/' + company.getID() + '/integrations/' + integration.getKey() + '/internal', body)
			.then((r) => {
				const updated_company = new Company(r.data);
				return Promise.resolve(updated_company);
			})
			.catch((e) => {
				this.notifications.handleAPIError(e);
				return Promise.reject(e);
			});
	}

	ConnectUsernamePassword(
		company: Company,
		integration: AccountingIntegrationV1,
		username: string,
		password: string,
		configuration: any,
	): Promise<Company> {
		const body = {
			username: username,
			password: password,
			configuration: configuration,
		};

		return this.apiService
			.post('/api/v1/company/' + company.getID() + '/integrations/' + integration.getKey() + '/username_password', body)
			.then((r) => {
				const updated_company = new Company(r.data);
				return Promise.resolve(updated_company);
			})
			.catch((e) => {
				this.notifications.handleAPIError(e);
				return Promise.reject(e);
			});
	}

	ConnectExactGlobe(
		company: Company,
		integration: AccountingIntegrationV1,
		base_url: string,
		internal_url: string,
		username: string,
		password: string,
		server_name: string,
		database_name: string,
	): Promise<Company> {
		const body = {
			base_url: base_url,
			internal_url: internal_url,
			username: username,
			password: password,
			server_name: server_name,
			database_name: database_name,
		};

		return this.apiService
			.post('/api/v1/company/' + company.getID() + '/integrations/' + integration.getKey() + '/exact_globe', body)
			.then((r) => {
				const updated_company = new Company(r.data);
				return Promise.resolve(updated_company);
			})
			.catch((e) => {
				this.notifications.handleAPIError(e);
				return Promise.reject(e);
			});
	}

	setIntegrationDefaults(company: Company, integration: AccountingIntegrationV1): Promise<Company> {
		const authorization = company.authorizations.get(integration.getKey());
		const body = {
			division: authorization.defaults.Division,
			glaccount: authorization.defaults.GLAccount,
			journal: authorization.defaults.Journal,
			vatcode: authorization.defaults.VATCode,
			combine_bookings: authorization.combine_bookings,
			book_expenses_with_purchase_date: authorization.book_expenses_with_purchase_date,
			match_expense_relation_on_email: authorization.match_expense_relation_on_email,
			match_expense_relation_on_account_code: authorization.match_expense_relation_on_account_code,
			integration_configuration: authorization.integration_configuration,
			allow_deferred: authorization.allow_deferred,
			book_in_background: authorization.book_in_background,
			combine_attachments: authorization.combine_attachments,
		};

		return this.apiService
			.post('/api/v1/company/' + company.getID() + '/integrations/' + integration.getKey() + '/defaults', body)
			.then((r) => {
				const updated_company = new Company(r.data);
				return Promise.resolve(updated_company);
			})
			.catch((e) => {
				this.notifications.handleAPIError(e);
				return Promise.reject(e);
			});
	}

	public getSuggestedIntegration(
		administrationId: string,
		categoryId: string,
		bookingType: AccountingBookingType,
	): Promise<{ isAAv2: boolean; id: string }> {
		let txType;
		switch (bookingType) {
			case AccountingBookingType.EXPENSE:
				txType = 'expense';
				break;
			case AccountingBookingType.INVOICE:
				txType = 'invoice';
				break;
			case AccountingBookingType.EXPENSE_REPORT:
				txType = 'report';
				break;
			default:
				throw new Error('Unknown booking type');
		}
		const queryParams = objectToQueryParamString({
			administration: administrationId,
			category: categoryId,
			transactionType: txType,
		});
		return this.apiService
			.getFromApi(`company/${this.companyService.getCompanyId()}/integrations/suggestion?${queryParams}`)
			.then((res) => res.data)
			.then((res) => {
				if (stringIsSetAndFilled(res.provider)) {
					return {
						isAAv2: false,
						id: res.provider,
					};
				} else if (stringIsSetAndFilled(res.authorization)) {
					return {
						isAAv2: true,
						id: res.authorization,
					};
				}
			})
			.catch(() => {
				return { id: null, isAAv2: false };
			});
	}

	getAMSuggestions(company: Company, user: string): Promise<AMBookingSuggestion[]> {
		user = user || '';
		return this.apiService
			.get(`/api/v1/company/${company.getID()}/integrations/am/booksuggestions?user=${user}`)
			.then((r) => {
				const suggestions = AMBookingSuggestion.fromData(r.data);
				return Promise.resolve(suggestions);
			})
			.catch((e) => {
				this.notifications.handleAPIError(e);
				return Promise.reject(e);
			});
	}

	public getSuggestionsDeprecated(
		receiptId: string,
		provider: string,
		division: string,
		relation_suggestion: boolean = true,
	): Promise<DMBookingSuggestion[]> {
		let group_by_relation = '0';
		if (relation_suggestion) {
			group_by_relation = '1';
		}

		return this.apiService
			.get(`/api/v1/receipt/${receiptId}/booksuggestions?provider=${provider}&division=${division}&group_by_relation=${group_by_relation}`)
			.then((r) => {
				if (isValueSet(r.data?.suggestion)) {
					return [new DMBookingSuggestion(r.data)];
				}
				return [];
			});
	}
}
