import { Merchant } from '#/models/merchant';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { MerchantActionsUnion, MerchantActionTypes } from './merchant.actions';
import { createSelector } from '@ngrx/store';

export interface MerchantState extends EntityState<Merchant> {
	currentPage: number | null;
	totalPages: number | null;
}

export const adapter: EntityAdapter<Merchant> = createEntityAdapter<Merchant>();

export const initialState: MerchantState = adapter.getInitialState({
	currentPage: null,
	totalPages: null,
});

export function merchantReducer(state = initialState, action: MerchantActionsUnion): MerchantState {
	switch (action.type) {
		case MerchantActionTypes.ADD_MERCHANT: {
			return adapter.addOne(action.payload.merchant, state);
		}

		case MerchantActionTypes.UPSERT_MERCHANT: {
			return adapter.upsertOne(action.payload.merchant, state);
		}

		case MerchantActionTypes.ADD_MERCHANTS: {
			return adapter.addMany(action.payload.merchants, state);
		}

		case MerchantActionTypes.UPSERT_MERCHANTS: {
			return adapter.upsertMany(action.payload.merchants, state);
		}

		case MerchantActionTypes.UPDATE_MERCHANT: {
			return adapter.updateOne(action.payload.merchant, state);
		}

		case MerchantActionTypes.UPDATE_MERCHANTS: {
			return adapter.updateMany(action.payload.merchants, state);
		}

		case MerchantActionTypes.DELETE_MERCHANT: {
			return adapter.removeOne(action.payload.id, state);
		}

		case MerchantActionTypes.DELETE_MERCHANTS: {
			return adapter.removeMany(action.payload.ids, state);
		}

		case MerchantActionTypes.LOAD_MERCHANTS: {
			return adapter.addAll(action.payload.merchants, state);
		}

		case MerchantActionTypes.CLEAR_MERCHANTS: {
			return adapter.removeAll({ ...state, selectedMerchantId: null });
		}

		default: {
			return state;
		}
	}
}

export const {
	selectIds: selectMerchantIds,
	selectEntities: selectMerchantEntities,
	selectAll: selectAllMerchants,
	selectTotal,
} = adapter.getSelectors();

// select the total merchant count
export const selectMerchantTotal = selectTotal;

export const getItemById = (id: string) => createSelector(selectMerchantEntities, (allItems) => allItems[id] || null);
