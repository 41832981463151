import { stringIsSetAndFilled } from '#/util/values';
import { ElementRef, Injectable, Renderer2 } from '@angular/core';

export enum Theme {
	KLIPPA = 'klippa',
	RADIUS = 'radius',
}

export enum ThemeProduct {
	RADIUS = 'Velos Expense',
	KLIPPA = 'Klippa',
}

@Injectable({ providedIn: 'any' })
export class Themed {
	constructor(private renderer: Renderer2, private el: ElementRef) {
		if (stringIsSetAndFilled(document.documentElement.dataset.theme)) {
			renderer.setAttribute(el.nativeElement, 'data-theme', document.documentElement.dataset.theme);
		}
	}

	public getTheme(): Theme {
		return (document.documentElement.dataset.theme as Theme) ?? Theme.KLIPPA;
	}

	public getThemeProductTitle(): ThemeProduct {
		switch (this.getTheme()) {
			case Theme.RADIUS:
				return ThemeProduct.RADIUS;
			default:
				return ThemeProduct.KLIPPA;
		}
	}
}
