import { Action } from '@ngrx/store';

export enum SidePanelActionTypes {
	SetSidePanelOpen = '[SetSidePanelState] Action',
	ToggleSidePanel = '[ToggleSidePanel] Action',
}

export class SetSidePanelOpen implements Action {
	readonly type = SidePanelActionTypes.SetSidePanelOpen;
	constructor(public payload: { open: boolean }) {}
}

export class ToggleSidePanel implements Action {
	readonly type = SidePanelActionTypes.ToggleSidePanel;
}

export type SidePanelActions = SetSidePanelOpen | ToggleSidePanel;
