import { Injectable } from '@angular/core';
import { Folder } from '#/models/user/folder';
import { SharingResponse } from '#/models/sharing';
import { APIService } from '~/app/api/services/api.service';
import { APINotificationsService } from '~/app/api/services/apinotifications.service';

export interface FoldersApiResponse<T> {
	result: string;
	request_id: string;
	data: T;
}

export interface FoldersSubmitRetractResponse {
	FailResults: Array<{ Error: string; Receipt: any }>;
	SuccessResults: Array<any>;
}

@Injectable({
	providedIn: 'root',
})
export class FolderService {
	constructor(protected apiService: APIService, protected notifications: APINotificationsService) {}

	public getAllFolders(): Promise<Array<Folder>> {
		return this.getFolders().then((r) => r?.data?.groups?.map((g) => new Folder(g)) ?? []);
	}

	/**
	 * Note: groups are not paged.
	 */
	public getFolders(): Promise<FoldersApiResponse<{ groups: Array<Folder> }>> {
		return this.apiService.get('/api/v1/group').catch((r) => {
			this.notifications.handleAPIError(r);
			throw r;
		});
	}

	public getFolder(id: string): Promise<Folder> {
		return this.apiService
			.get('/api/v1/group/' + id)
			.then((r) => new Folder(r.data))
			.catch((r) => {
				this.notifications.handleAPIError(r);
				throw r;
			});
	}

	public acceptShare(id: string, trustedContact: boolean): Promise<SharingResponse> {
		const payload = {
			trustedcontact: trustedContact,
		};
		return this.apiService.post(`/api/v1/group/${id}/acceptshare`, payload).catch((r) => {
			this.notifications.handleAPIError(r);
			throw r;
		});
	}

	public declineShare(id: string): Promise<SharingResponse> {
		return this.apiService.post(`/api/v1/group/${id}/declineshare`, null).catch((r) => {
			this.notifications.handleAPIError(r);
			throw r;
		});
	}

	public nameFolder(id: string, name: string): Promise<FoldersApiResponse<Folder>> {
		const payload = {
			title: name,
		};
		return this.apiService.patch('/api/v1/group/' + id, payload);
	}

	public createFolder(new_group: Folder): Promise<Folder> {
		return this.apiService
			.post(`/api/v1/group`, new_group)
			.then((r) => new Folder(r.data))
			.catch((r) => {
				this.notifications.handleAPIError(r);
				throw r;
			});
	}

	public submitExpense(id: string): Promise<FoldersApiResponse<FoldersSubmitRetractResponse>> {
		return this.apiService.post('/api/v1/group/' + id + '/declaration', null).catch((r) => {
			this.notifications.handleAPIError(r);
			throw r;
		});
	}

	public retractExpense(id: string): Promise<FoldersApiResponse<FoldersSubmitRetractResponse>> {
		return this.apiService.delete('/api/v1/group/' + id + '/declaration').catch((r) => {
			this.notifications.handleAPIError(r);
			throw r;
		});
	}

	public deleteFolder(id: string): Promise<FoldersApiResponse<{}>> {
		return this.apiService.delete('/api/v1/group/' + id).catch((r) => {
			this.notifications.handleAPIError(r);
			throw r;
		});
	}

	public updateFolder(group: Folder): Promise<Folder> {
		return this.apiService.patch('/api/v1/group/' + group.id, group).then((r) => new Folder(r.data));
	}

	public inviteUser(email: string): Promise<any> {
		const payload = {
			email: email,
		};
		return this.apiService.post('/api/v1/user/invite', payload);
	}
}
