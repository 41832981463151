import { TransactionApiModel } from '#/models/transaction/transaction/apiModel';
import { apiToFrontend } from './transformer';

export class FixedCompensationApiModel extends TransactionApiModel {
	compensationRules: { [k in string]: Array<string> };
	amount_of_minutes: number;
	constructor(data?: any) {
		super(data);
	}

	transformToFrontend(loggedUserId: string) {
		return apiToFrontend(this, loggedUserId);
	}
}
