import { Component, Host, Input, Optional } from '@angular/core';
import { ControlContainer, NG_VALUE_ACCESSOR } from '@angular/forms';
import { FormElementComponent, ValueAccessorBase } from '@klippa/ngx-enhancy-forms';
import { ExportTemplatesService } from '~/app/modules/export-templates/services/export-templates.service';
import { ExportType } from '#/models/company/exporterInterfaces';
import { ExportTemplateUserRole } from '~/app/modules/export-templates/models/export-template.model';
import { CompanyService } from '#/services/company/company.service';
import { isNullOrUndefined, isValueSet } from '#/util/values';
import { UserService } from '~/app/modules/user/user.service';

@Component({
	selector: 'app-form-export-template-picker',
	templateUrl: './export-template-picker.component.html',
	styleUrls: ['./export-template-picker.component.scss'],
	providers: [{ provide: NG_VALUE_ACCESSOR, useExisting: ExportTemplatePickerComponent, multi: true }],
})
export class ExportTemplatePickerComponent extends ValueAccessorBase<string> {
	@Input() public clearable: boolean = true;
	@Input() public exportType: ExportType;
	@Input() public exportUser: ExportTemplateUserRole = null;
	@Input() public includeReports: boolean = true;
	public optionsPromise: Promise<Array<{ id: any; name: string; description?: string }>>;

	constructor(
		@Optional() @Host() protected parent: FormElementComponent,
		@Optional() @Host() protected controlContainer: ControlContainer,
		private exportTemplatesService: ExportTemplatesService,
		private companyService: CompanyService,
		private userService: UserService,
	) {
		super(parent, controlContainer);
	}

	ngOnInit(): void {
		super.ngOnInit();
		this.setExportUser();

		this.optionsPromise = this.exportTemplatesService
			.getTemplates(this.exportType, this.exportUser, this.companyService.getCompanyId())
			.then((data) => {
				if (data.data.export_templates?.length > 0) {
					if (!this.includeReports) {
						// no support for scheduled report exports yet, filtered option for now
						data.data.export_templates = data.data.export_templates.filter((e) => e.type !== ExportType.REPORT);
					}
					return data.data.export_templates.map((template) => {
						return { id: template.id, name: template.description, description: template.export_type };
					});
				} else {
					return [];
				}
			});
	}

	private setExportUser(): void {
		// if the export user is already set, don't overwrite it.
		if (isValueSet(this.exportUser)) {
			return;
		}

		const user = this.userService.getCurrentLoggedUser();
		const company = this.companyService.getCompanyOfLoggedUser();
		if (!isValueSet(company)) {
			this.exportUser = ExportTemplateUserRole.user;
			return;
		}
		// If the current selected company is different from the user.company and the user's company is the parent company of the selected company.
		// Set the export user to company.
		if (company.id !== user.company && company?.parentcompany === user.company) {
			this.exportUser = ExportTemplateUserRole.company;
			return;
		}

		// At last, set the export user if it's still not set.
		if (isNullOrUndefined(this.exportUser)) {
			this.exportUser = ExportTemplateUserRole.user;
			return;
		}
	}
}
