<ng-container *ngIf="receipt && loading">
	<ng-container *ngIf="render === 'label'">
		<span translate>Loading...</span>
	</ng-container>
</ng-container>
<ng-container *ngIf="receipt && !loading">
	<ng-container *ngIf="render === 'label'">
		<div *ngFor="let duplicateMessage of duplicateMessages" class="mb-1">
			<i class="ti-alert text-warning mr-3" *ngIf="showIcon"></i>
			<span [innerHTML]="duplicateMessage.message"></span>
			<span>&nbsp;</span>
			<a
				(click)="ignoreDuplicate(duplicateMessage.receiptId)"
				href="javascript:void(0)"
				*ngIf="showIgnore && duplicateMessage.receiptId && user.hasProRole() && !user.hasCompanyRole()"
				translate
				>Ignore</a
			>
		</div>
	</ng-container>
</ng-container>
