import { Injectable } from '@angular/core';
import { CompanyCostUnitData, CompanyCostUnitListAPIRequest, CostUnit } from '#/models/company/dimension/cost-unit.model';
import { ImportResultCount } from '#/models/import-result.model';
import { CompanyDimensionsService } from '#/services/company/dimension/company-dimensions.service';
import { arrayIsSetAndFilled } from '#/util/arrays';

@Injectable({
	providedIn: 'root',
})
export class CompanyCostUnitService extends CompanyDimensionsService {
	getCompanyCostUnits(companyCostUnitListAPIRequest: CompanyCostUnitListAPIRequest): Promise<CompanyCostUnitData> {
		companyCostUnitListAPIRequest.list = true;
		if (arrayIsSetAndFilled(companyCostUnitListAPIRequest.ids)) {
			companyCostUnitListAPIRequest.max = 9999;
		}
		return this.apiService
			.post(companyCostUnitListAPIRequest.getRequestURL(), this.getRequestBody(companyCostUnitListAPIRequest))
			.then((r) => {
				return new CompanyCostUnitData(r.data);
			})
			.catch((e) => {
				this.notifications.handleAPIError(e);
				throw e;
			});
	}

	getCompanyCostUnitsFilteredOnPresetsAndAdministration(
		ListAPIRequest: CompanyCostUnitListAPIRequest,
		presetOptions: string[],
		administration,
	): Promise<CompanyCostUnitData> {
		return this.getCompanyCostUnits(ListAPIRequest).then((data) => {
			const result = data;
			result.company_costunits = this.filterBasedOnPresetsAndAdministration(data.company_costunits, presetOptions, administration);
			return result;
		});
	}

	importCompanyCostUnits(companyId: string, administration: string, file: File, skipFirstRow = true): Promise<ImportResultCount> {
		const formData = new FormData();
		formData.append('file', file);

		if (skipFirstRow) {
			formData.append('skip_first_row', '1');
		}
		if (administration) {
			formData.append('administration', administration);
		}

		let url = '/api/v1/companyCostUnit/import';
		if (companyId !== '') {
			url = '/api/v1/company/' + companyId + '/companyCostUnit/import';
		}

		return this.apiService
			.post(url, formData)
			.then((r) => {
				return Promise.resolve(new ImportResultCount(r.data));
			})
			.catch((e) => {
				this.notifications.handleAPIError(e);
				return Promise.reject(e);
			});
	}

	bulkCompanyCostUnits(companyId: string, type: string, costunits: CostUnit[]): Promise<ImportResultCount> {
		let url = '/api/v1/companyCostUnit/bulk';
		if (companyId !== '') {
			url = '/api/v1/company/' + companyId + '/companyCostUnit/bulk';
		}

		return this.apiService
			.post(url, {
				type,
				costunits,
			})
			.then((r) => {
				return Promise.resolve(new ImportResultCount(r.data));
			})
			.catch((e) => {
				this.notifications.handleAPIError(e);
				return Promise.reject(e);
			});
	}

	createCompanyCostUnit(companyId: string, costunit: CostUnit): Promise<CostUnit> {
		let url = '/api/v1/companyCostUnit';
		if (companyId !== '') {
			url = '/api/v1/company/' + companyId + '/companyCostUnit';
		}

		return this.apiService
			.post(url, costunit)
			.then((r) => {
				return Promise.resolve(new CostUnit(r.data));
			})
			.catch((e) => {
				this.notifications.handleAPIError(e);
				return Promise.reject(e);
			});
	}

	updateCompanyCostUnit(companyId: string, costunit: CostUnit): Promise<CostUnit> {
		let url = '/api/v1/companyCostUnit/' + costunit.id;
		if (companyId !== '') {
			url = '/api/v1/company/' + companyId + '/companyCostUnit/' + costunit.id;
		}

		return this.apiService
			.patch(url, costunit)
			.then((r) => {
				return Promise.resolve(new CostUnit(r.data));
			})
			.catch((e) => {
				this.notifications.handleAPIError(e);
				return Promise.reject(e);
			});
	}

	getCompanyCostUnit(companyId: string, id: string): Promise<CostUnit> {
		let url = '/api/v1/companyCostUnit/' + id;
		if (companyId !== '') {
			url = '/api/v1/company/' + companyId + '/companyCostUnit/' + id;
		}
		if (CompanyDimensionsService.requestCache.has(url)) {
			return CompanyDimensionsService.requestCache.get(url);
		}
		const promise = this.apiService
			.get(url)
			.then((r) => {
				CompanyDimensionsService.requestCache.delete(url);
				return Promise.resolve(new CostUnit(r.data));
			})
			.catch((e) => {
				this.notifications.handleAPIError(e);
				CompanyDimensionsService.requestCache.delete(url);
				return Promise.reject(e);
			});
		CompanyDimensionsService.requestCache.set(url, promise);
		return promise;
	}

	deleteCompanyCostUnit(companyId: string, id: string): Promise<any> {
		let url = '/api/v1/companyCostUnit/' + id;
		if (companyId !== '') {
			url = '/api/v1/company/' + companyId + '/companyCostUnit/' + id;
		}

		return this.apiService
			.delete(url)
			.then((r) => {
				return Promise.resolve(r);
			})
			.catch((e) => {
				this.notifications.handleAPIError(e);
				return Promise.reject(e);
			});
	}
}
