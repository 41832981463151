import { Action } from '@ngrx/store';
import { Update } from '@ngrx/entity';
import { TagStat } from '../../../pages/authenticated/statistics/models/stats';

export enum TagActionTypes {
	LOAD_TAGS = '[Tag] Load Tags',
	ADD_TAG = '[Tag] Add Tag',
	UPSERT_TAG = '[Tag] Upsert Tag',
	ADD_TAGS = '[Tag] Add Tags',
	UPSERT_TAGS = '[Tag] Upsert Tags',
	UPDATE_TAG = '[Tag] Update Tag',
	UPDATE_TAGS = '[Tag] Update Tags',
	DELETE_TAG = '[Tag] Delete Tag',
	DELETE_TAGS = '[Tag] Delete Tags',
	CLEAR_TAGS = '[Tag] Clear Tags',
}

export class LoadTags implements Action {
	readonly type = TagActionTypes.LOAD_TAGS;

	constructor(public payload: { tags: TagStat[] }) {}
}

export class AddTag implements Action {
	readonly type = TagActionTypes.ADD_TAG;

	constructor(public payload: { tag: TagStat }) {}
}

export class UpsertTag implements Action {
	readonly type = TagActionTypes.UPSERT_TAG;

	constructor(public payload: { tag: TagStat }) {}
}

export class AddTags implements Action {
	readonly type = TagActionTypes.ADD_TAGS;

	constructor(public payload: { tags: TagStat[] }) {}
}

export class UpsertTags implements Action {
	readonly type = TagActionTypes.UPSERT_TAGS;

	constructor(public payload: { tags: TagStat[] }) {}
}

export class UpdateTag implements Action {
	readonly type = TagActionTypes.UPDATE_TAG;

	constructor(public payload: { tag: Update<TagStat> }) {}
}

export class UpdateTags implements Action {
	readonly type = TagActionTypes.UPDATE_TAGS;

	constructor(public payload: { tags: Update<TagStat>[] }) {}
}

export class DeleteTag implements Action {
	readonly type = TagActionTypes.DELETE_TAG;

	constructor(public payload: { id: string }) {}
}

export class DeleteTags implements Action {
	readonly type = TagActionTypes.DELETE_TAGS;

	constructor(public payload: { ids: string[] }) {}
}

export class ClearTags implements Action {
	readonly type = TagActionTypes.CLEAR_TAGS;
}

export type TagActionsUnion =
	| LoadTags
	| AddTag
	| UpsertTag
	| AddTags
	| UpsertTags
	| UpdateTag
	| UpdateTags
	| DeleteTag
	| DeleteTags
	| ClearTags;
