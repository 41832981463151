import { Injectable } from '@angular/core';
import { Subscriber } from 'rxjs';
import { ModalOptions } from '~/app/services/modal.service';
import { Router } from '@angular/router';

@Injectable({
	providedIn: 'root',
})
export class LoginModalService {
	showModal: Subscriber<ModalOptions>;
	onSuccessfulLogin: Array<() => void> = [];
	onRedirectToDashBoardOnDismissAndNoLogin: Array<() => void> = [];
	private successfulLogin: boolean = false;

	constructor(private router: Router) {}

	registerShowModal(loginModal: Subscriber<ModalOptions>) {
		this.showModal = loginModal;
	}

	addSuccessfulLoginListener(callback: () => void) {
		this.onSuccessfulLogin.push(callback);
	}

	addRedirectToDashBoardOnDismissAndNoLoginListener(callback: () => void) {
		this.onRedirectToDashBoardOnDismissAndNoLogin.push(callback);
	}

	show(options?: ModalOptions) {
		options = options || {};
		this.showModal.next(options);
	}

	resolve() {
		this.successfulLogin = true;
		this.onSuccessfulLogin.forEach((cb) => cb());
		this.onSuccessfulLogin = [];
	}

	// This function can be put in the modal options as the callback for the "beforeDismiss" key
	// Because it is used for that key it needs to return a boolean. If the function returns false, the modal won't close.
	// We DO want the modal to close, so we return TRUE
	redirectToDashBoardOnDismissAndNoLogin(): boolean {
		if (!this.successfulLogin) {
			this.router.navigate(['/dashboard']);
			this.onRedirectToDashBoardOnDismissAndNoLogin.forEach((cb) => cb());
			this.onRedirectToDashBoardOnDismissAndNoLogin = [];
		} else {
			this.successfulLogin = false;
		}
		return true;
	}
}
