import { Component, Input, OnChanges } from '@angular/core';
import { Merchant } from '#/models/merchant';
import { select } from '@ngrx/store';
import * as fromMerchant from '../../models/merchant.reducer';
import { Observable } from 'rxjs';
import { BaseComponent } from '../../../../base/base.component';
import { AddMerchant } from '~/app/modules/merchant/models/merchant.actions';
import { stringIsSetAndFilled } from '#/util/values';

@Component({
	selector: 'app-merchant-label',
	templateUrl: './merchant-label.component.html',
})
export class MerchantLabelComponent extends BaseComponent implements OnChanges {
	@Input() MerchantID: string;
	merchant: Observable<Merchant>;

	ngOnChanges() {
		this.merchant = this.store.select('merchant').pipe(select(fromMerchant.getItemById(this.MerchantID)));
		const merchantSub = this.merchant.subscribe((val) => {
			if (!val) {
				if (stringIsSetAndFilled(this.MerchantID) && this.MerchantID.startsWith('new-')) {
					return;
				}
				this.merchantAPIService.getMerchant(this.MerchantID).then((merchant) => {
					this.store.dispatch(new AddMerchant({ merchant: merchant }));
				});
			}
		});

		this.destroyCallbacks.push(() => merchantSub.unsubscribe());
	}
}
