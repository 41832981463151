import { Injectable } from '@angular/core';
import { CompanyService } from '#/services/company/company.service';
import { CompanyModuleSimplifyCompanyUnits } from '#/models/company/company.model';

@Injectable({
	providedIn: 'root',
})
export class CompanyUnitService<T extends {name: string, code: string, description: string}> {
	constructor(private companyService: CompanyService) {
	}

	public getCompanySimplifiedUnits(): CompanyModuleSimplifyCompanyUnits {
		return this.companyService.getCompanyOfLoggedUser()?.modules?.simplify_company_units || new CompanyModuleSimplifyCompanyUnits();
	}

	public potentiallySimplifyUnitData(data: T): T {
		const companyUnit = data;
		const simplifiedUnits = this.getCompanySimplifiedUnits();

		if (!simplifiedUnits.enabled) {
			return data;
		}

		companyUnit.name = simplifiedUnits.hide_name ? null : data.name;
		companyUnit.code = simplifiedUnits.hide_code ? null : data.code;
		companyUnit.description = simplifiedUnits.hide_description ? null : data.description;
		return companyUnit;
	}
}
