import { Injectable } from '@angular/core';
import { APIService } from '../../api/services/api.service';
import { APINotificationsService } from '../../api/services/apinotifications.service';
import { OAuthApp, ScopesGroup } from '../oauth-app/models/app';

@Injectable({
	providedIn: 'root',
})
export class OAuthService {
	constructor(private apiService: APIService, private notifications: APINotificationsService) {}

	async scopeGroups() {
		const res = await this.apiService.get('/api/v1/oauth/avaiableScopes');
		return res.data as ScopesGroup[];
	}

	async allowApp(query: string) {
		return await this.apiService.get(`/api/v1/oauth/authorize${query}`, 0, this.apiService.authManagerService, true, [
			{ key: 'X-Redirect-As-JSON', value: '1' },
		]);
	}

	async getOauthApp(clientID: string): Promise<OAuthApp> {
		try {
			const r = await this.apiService.get(`/api/v1/oauth/app/info/${clientID}`);
			return new OAuthApp(r.data);
		} catch (e) {
			this.notifications.handleAPIError(e);
			throw e;
		}
	}

	async getOAuthApps(): Promise<OAuthApp[]> {
		try {
			const r = await this.apiService.get(`/api/v1/admin/oauthApps`);
			return r.data.map((app) => new OAuthApp(app));
		} catch (e) {
			this.notifications.handleAPIError(e);
			throw e;
		}
	}

	async getDisabledOAuthApps(): Promise<OAuthApp[]> {
		try {
			const r = await this.apiService.get(`/api/v1/admin/oauthApps/disabled`);
			return r.data.map((app) => new OAuthApp(app));
		} catch (e) {
			this.notifications.handleAPIError(e);
			throw e;
		}
	}

	async addOAuthApp(data: { name: string; description: string; scopes: string[]; allowAllScopes: boolean }): Promise<OAuthApp> {
		try {
			const r = await this.apiService.post(`/api/v1/admin/oauthApps`, data);
			return new OAuthApp(r.data);
		} catch (e) {
			this.notifications.handleAPIError(e);
			throw e;
		}
	}

	async updateOAuthApp(
		appID: string,
		updatedData: { name: string; description: string; redirectURL: string[]; scopes?: string[]; allowAllScopes: boolean },
	): Promise<OAuthApp> {
		try {
			const r = await this.apiService.patch(`/api/v1/admin/oauthApps/${appID}`, updatedData);
			return new OAuthApp(r.data);
		} catch (e) {
			this.notifications.handleAPIError(e);
			throw e;
		}
	}

	async disableOAuthApp(appID: string) {
		try {
			await this.apiService.patch(`/api/v1/admin/oauthApps/${appID}/disable`, {});
			return true;
		} catch (e) {
			this.notifications.handleAPIError(e);
			throw e;
		}
	}

	async enableOAuthApp(appID: string) {
		try {
			await this.apiService.patch(`/api/v1/admin/oauthApps/${appID}/enable`, {});
			return true;
		} catch (e) {
			this.notifications.handleAPIError(e);
			throw e;
		}
	}

	async deleteOAuthApp(appID: string) {
		try {
			await this.apiService.delete(`/api/v1/admin/oauthApps/${appID}`);
			return true;
		} catch (e) {
			this.notifications.handleAPIError(e);
			throw e;
		}
	}
}
