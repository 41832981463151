import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ExpenseReportsService } from '#/services/transaction/expense-reports.service';
import { APINotificationsService } from '~/app/api/services/apinotifications.service';

@Component({
	selector: 'app-expense-report-delete-modal',
	templateUrl: './expense-report-delete-modal.component.html',
	styleUrls: ['./expense-report-delete-modal.component.scss'],
})
export class ExpenseReportDeleteModalComponent implements OnInit {
	@Input() public reportIds: [string];
	@Input() public target: 'expenses' | 'report' = 'report';
	public multiple = false;
	constructor(private expenseReportsService: ExpenseReportsService, private notifications: APINotificationsService) {}

	@Output() public onDismiss: EventEmitter<void> = new EventEmitter<void>();
	@Output() public onSuccess: EventEmitter<void> = new EventEmitter<void>();

	ngOnInit(): void {
		if (this?.reportIds?.length > 1) {
			this.multiple = true;
		}
	}

	deleteReports() {
		const promiseArray = [];
		if (this.reportIds) {
			for (const i in this.reportIds) {
				if (this.reportIds.hasOwnProperty(i)) {
					promiseArray.push(Promise.resolve(this.expenseReportsService.deleteExpenseReport(this.reportIds[i], this.target)));
				}
			}
		}

		Promise.all(promiseArray)
			.then(() => {
				this.onSuccess.emit();
			})
			.catch((r) => {
				this.notifications.handleAPIError(r);
				throw r;
			});
	}

	closeModal(): void {
		this.onDismiss.emit();
	}
}
