import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IconImageComponent } from './components/icon-image/icon-image.component';

@NgModule({
	imports: [CommonModule],
	declarations: [IconImageComponent],
	providers: [],
	exports: [IconImageComponent],
})
export class IconModule {}
