import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
	selector: 'app-confirm-modal',
	styleUrls: ['./confirm-modal.component.css'],
	templateUrl: './confirm-modal.component.html',
})
export class ConfirmModalComponent implements OnInit {
	@Input()
	public title = '';
	@Input()
	public message = '';
	public value: any;
	@Input()
	public type: 'text' | 'password' = 'text';
	@Input()
	public showCancel = true;
	@Input()
	public confirmLabel;

	@Output()
	public result = new EventEmitter<{ result: boolean; value: any }>();

	constructor(public activeModal: NgbActiveModal, private sanitized: DomSanitizer) {}

	ngOnInit() {}

	dismiss() {
		this.result.emit({ result: false, value: null });
		this.activeModal.close('Dismiss');
	}

	confirm() {
		this.result.emit({ result: true, value: this.value });
		this.activeModal.close('Confirm');
	}

	getMessage() {
		const message = this.message.replace(/(?:\r\n|\r|\n)/g, '<br>');
		return this.sanitized.bypassSecurityTrustHtml(message);
	}
}
