<span *ngIf="receipt.isBooked()" class="font-size-14 text-dark">
	<i class="ti-check bookedStatus mrg-right-5"></i>
	<span [attr.title]="getBookingInformation()" class="booking-status-information" translate>Booked!</span>
</span>

<span *ngIf="receipt.isQueuedForBooking()" class="font-size-14 text-dark">
	<i class="ti-time bookedStatus mrg-right-5"></i>
	<span class="booking-status-information" translate>Queued</span>
</span>

<span *ngIf="receipt.waitingToBebooked()" class="font-size-14 text-dark">
	<i class="ti-time pendingStatus mrg-right-5"></i>
	<span
		[ngbTooltip]="'This receipt/invoice will be booked soon, the booking might take up to a few hours to complete' | translate"
		translate
		>Booked soon</span
	>
</span>
<span *ngIf="receipt.hasBackgroundError()" class="font-size-14 text-danger">
	<i class="ti-alert errorStatus mrg-right-5"></i>
	<span [ngbTooltip]="('Background booking error:' | translate) + '\n' + receipt.bookingstatus.backgroundQueueError" translate
		>Booking error</span
	>
</span>
<a
	*ngIf="!receipt.isBooked() && receipt.isBookable() && !receipt.hasBackgroundError() && company.authorizations.size > 0"
	(click)="bookReceipt()"
	class="bookActionButton"
>
	<i class="ti-cloud-up mrg-right-5"></i>
	<span *ngIf="!receipt.isinvoice" translate>Book receipt</span>
	<span *ngIf="receipt.isinvoice" translate>Book invoice</span>
</a>
