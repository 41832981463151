import { Component, OnInit } from '@angular/core';
import { AuthenticatedComponent } from '../authenticated.component';
import { ActivatedRoute } from '@angular/router';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { User } from '#/models/user/user.model';
import { Update } from '../../../modules/user/models/user.actions';
import { Themed } from '#/providers/themed';

@Component({
	selector: 'app-oauth-authorized-connect',
	templateUrl: './oauth-authorized-connect.component.html',
	providers: [Themed],
})
export class OAuthAuthorizedConnectComponent extends AuthenticatedComponent implements OnInit {
	private provider = '';
	private code = '';
	private state = '';
	private email = '';
	public loading = false;
	public theme: string = 'klippa';

	constructor(protected route: ActivatedRoute, private themed: Themed) {
		super();
		this.theme = themed.getTheme();
	}

	ngOnInit(): void {
		super.ngOnInit();

		const params = this.route.snapshot.params;
		this.provider = params.provider;

		this.route.queryParams.subscribe((queryParams) => {
			this.code = queryParams['code'];
			this.state = queryParams['state'];
			this.connectOAuth();
		});
	}

	connectOAuth() {
		this.userAPIService
			.initOAuthConnectAuthorized(this.provider, this.code, this.state)
			.then((r) => {
				this.loading = false;
				this.finalizeConnect(r);
			})
			.catch((r) => {
				this.APINotifications.handleAPIError(r);
				this.router.navigate(['/settings']);
			});
	}

	finalizeConnect(r) {
		const userPayload = new User(r['data']['user']);
		this.store.dispatch(new Update({ user: userPayload }));
		this.notificationService.success(this.getTranslation(_('Account successfully connected')));
		this.router.navigate(['/settings']);
	}
}
