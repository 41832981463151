import { stringIsSetAndFilled } from '#/util/values';

export function capitalizeFirstLetter(str: string): string {
	if (!stringIsSetAndFilled(str)) {
		return null;
	}
	return (str.charAt(0)?.toUpperCase() ?? '') + (str.slice(1) ?? '');
}

export function getIndicesOf(fullString, searchFor): Array<number> {
	const searchStrLen = searchFor.length;
	if (searchStrLen === 0) {
		return [];
	}
	let startIndex = 0;
	let index = 0;
	const indices = [];
	while ((index = fullString.indexOf(searchFor, startIndex)) > -1) {
		indices.push(index);
		startIndex = index + searchStrLen;
	}
	return indices;
}
