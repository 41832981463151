import { Component, Input } from '@angular/core';
import { FormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { CompanyService } from '#/services/company/company.service';
import { stringIsSetAndFilled, useIfStringIsSet } from '#/util/values';
import { isPdfValidator } from '#/validators/file.validator';
import { memoizeMax1Param } from '#/util/functions';

@Component({
	selector: 'app-manual-card-upload-form',
	templateUrl: './manual-card-upload-form.component.html',
	styleUrls: ['manual-card-upload-form.component.scss'],
})
export class ManualCardUploadFormComponent {
	@Input() manualCardUploadForm: FormGroup;

	public creditCardModuleHasTransactionInterfaceSet = memoizeMax1Param((): boolean => {
		return stringIsSetAndFilled(this.companyService.getCompanyOfLoggedUser()?.modules.creditcardManualUpload.transactionInterface);
	});

	constructor(private companyService: CompanyService, private fb: UntypedFormBuilder) {}

	ngOnInit(): void {
		const { administration, transactionInterface } = this.companyService.getCompanyOfLoggedUser()?.modules.creditcardManualUpload;
		if (this.creditCardModuleHasTransactionInterfaceSet()) {
			this.manualCardUploadForm = this.fb.group({
				administration: [useIfStringIsSet(administration), [Validators.required]],
				cardStatement: [null, [Validators.required, isPdfValidator]],
			});
		} else {
			this.manualCardUploadForm = this.fb.group({
				administration: [useIfStringIsSet(administration), [Validators.required]],
				transactionInterface: [useIfStringIsSet(transactionInterface), [Validators.required]],
				cardStatement: [null, [Validators.required, isPdfValidator]],
			});
		}
	}
}
