import { Component } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { AppSelectOptions } from '@klippa/ngx-enhancy-forms';
import { StaticOptionsValueAccessorBase } from './base/static-options-value-accessor-base';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { XmlConditionType } from '#/models/company/exporterInterfaces';

export const conditionOptions: AppSelectOptions = [{ id: XmlConditionType.IS_CREDIT_LINE, name: _('Is credit line') }];

@Component({
	selector: 'app-xml-condition-picker',
	templateUrl: './base/static-options-picker.template.html',
	styleUrls: ['./base/static-options-picker.template.scss'],
	providers: [{ provide: NG_VALUE_ACCESSOR, useExisting: XmlConditionPicker, multi: true }],
})
export class XmlConditionPicker extends StaticOptionsValueAccessorBase<XmlConditionType> {
	clearable = true;
	defaultPlaceHolder = _('Select condition');

	fetchStaticItemsFn(): AppSelectOptions {
		return conditionOptions;
	}
}
