import { Injectable } from '@angular/core';

export enum ApplicationName {
	KLIPPA = 'klippa',
	RADIUS = 'radius',
}

@Injectable({
	providedIn: 'root',
})
export class ComplianceUrlsService {
	public getApplicationName(applicationUrl: string): ApplicationName {
		switch (applicationUrl) {
			case 'https://app.veloscard.com':
			case 'https://app.test.radius.whitelabel.klippa.com':
				return ApplicationName.RADIUS;
			case 'https://app.klippa.com':
			case 'https://app-test.klippa.com':
			default:
				return ApplicationName.KLIPPA;
		}
	}

	public getPrivacyStatement(applicationName: ApplicationName): string {
		switch (applicationName) {
			case ApplicationName.KLIPPA:
				return 'https://www.klippa.com/en/privacy-statement/';
			case ApplicationName.RADIUS:
				return 'https://www.radius.com/legal/en/privacy-policy-en';
		}

		return 'https://www.klippa.com/en/terms-and-conditions/';
	}

	public getTermsAndConditionsStatement(applicationName: ApplicationName): string {
		switch (applicationName) {
			case ApplicationName.KLIPPA:
				return 'https://www.klippa.com/en/terms-and-conditions/';
			case ApplicationName.RADIUS:
				return 'https://res.cloudinary.com/dwao3b1us/image/upload/v1688986992/velos/docs/Velos_Unregulated_Credit_Agreement.pdf';
		}

		return 'https://www.klippa.com/en/terms-and-conditions/';
	}

	public getDataProcessingAgreementStatement(applicationName: ApplicationName): string {
		switch (applicationName) {
			case ApplicationName.KLIPPA:
				return 'https://www.klippa.com/en/data-processing-agreement/';
			case ApplicationName.RADIUS:
				return 'https://www.radius.com/data-protection/';
		}

		return 'https://www.klippa.com/en/data-processing-agreement/';
	}
}
