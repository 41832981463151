import { Component, Input, ViewChild } from '@angular/core';
import { ExportRequest, ExportType } from '#/models/company/exporterInterfaces';
import { ExpenseReportExportRequest } from '#/models/transaction/expense-reports';
import { RegistrationExportRequest, RegistrationGovernmentSummaryExportRequest } from '#/models/transaction/registration-export';
import { ExpenseReportsService } from '#/services/transaction/expense-reports.service';
import { ModalComponent } from '~/app/shared/ui/modal/modal.component';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { TranslateService } from '@ngx-translate/core';
import { stringIsSetAndFilled } from '#/util/values';
import { RegistrationExportService } from '#/services/export/registration-export.service';
import { CompanyService } from '#/services/company/company.service';

@Component({
	selector: 'app-export-form-modal',
	templateUrl: './export-form-modal.component.html',
	styleUrls: ['./export-form-modal.component.scss'],
})
export class ExportFormModal {
	@Input() exportIds: Array<string>;
	@Input() exportType: ExportType = ExportType.REPORT; // TODO: also support the other types of ExportType, currently we support report and registrations
	@Input() isCompanyExport: boolean = true;
	@Input() isEmailRequired: boolean = false;
	@ViewChild('exportModalRef') exportModalRef: ModalComponent;

	public exportUrlPromise: Promise<string>;

	constructor(
		private expenseReportsService: ExpenseReportsService,
		private registrationExportService: RegistrationExportService,
		private translateService: TranslateService,
		private companyService: CompanyService,
	) {}

	public openExportModal(): void {
		this.exportModalRef.openModal();
	}

	public processExport(event: ExportRequest): void {
		if (this.exportType === ExportType.REPORT) {
			this.processReportExport(event);
		}
		if (this.exportType === ExportType.REGISTRATION) {
			this.processRegistrationExport(event);
		}
		if (this.exportType === ExportType.SUMMARY_REGISTRATION) {
			this.processSummaryRegistrationExport(event);
		}
	}

	private processReportExport(event: ExportRequest): void {
		const exportRequest: ExportRequest = event;
		exportRequest.email = exportRequest.email.filter(stringIsSetAndFilled).join(',');
		const expenseReportExportRequest: ExpenseReportExportRequest = new ExpenseReportExportRequest(exportRequest);
		this.exportUrlPromise = this.expenseReportsService.exportExpenseReports(expenseReportExportRequest).then((res) => res.data.url);
	}

	private processRegistrationExport(event: ExportRequest): void {
		const exportRequest: ExportRequest = event;
		exportRequest.email = exportRequest.email.filter(stringIsSetAndFilled).join(',');
		const registrationExportRequest: RegistrationExportRequest = new RegistrationExportRequest(exportRequest);
		this.exportUrlPromise = this.registrationExportService
			.exportRegistrations(this.companyService.getCompanyId(), registrationExportRequest)
			.then((res) => res.data.url);
	}

	private processSummaryRegistrationExport(event: ExportRequest): void {
		const exportRequest: ExportRequest = event;
		exportRequest.email = exportRequest.email.filter(stringIsSetAndFilled).join(',');
		const registrationExportRequest: RegistrationGovernmentSummaryExportRequest = new RegistrationGovernmentSummaryExportRequest(
			exportRequest,
		);
		this.exportUrlPromise = this.registrationExportService
			.exportGovernmentSummaryRegistrations(this.companyService.getCompanyId(), registrationExportRequest)
			.then((res) => res.data.url);
	}

	public getExportTextForSingular(): string {
		switch (this.exportType) {
			case ExportType.REPORT:
				return this.translateService.instant(_('report'));
			case ExportType.REGISTRATION:
			case ExportType.SUMMARY_REGISTRATION:
				return this.translateService.instant(_('registration'));
		}
	}
	public getExportTextForPlural(): string {
		switch (this.exportType) {
			case ExportType.REPORT:
				return this.translateService.instant(_('reports'));
			case ExportType.REGISTRATION:
			case ExportType.SUMMARY_REGISTRATION:
				return this.translateService.instant(_('registrations'));
		}
	}
}
