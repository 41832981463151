export enum UI_TYPE {
	SELECT = 'SELECT',
	MULTI_SELECT = 'MULTI_SELECT',
	TEXT = 'TEXT',
	SECRET = 'SECRET',
	MONEY = 'MONEY',
	DATE = 'DATE',
	NUMBER = 'NUMBER',
	BOOLEAN = 'BOOLEAN',
}
