import { Component, EventEmitter, Input, NgModule, OnInit, Output } from '@angular/core';
import { CompanyComponent } from '../../company.component';
import { Company, CompanySubCompaniesListAPIRequest } from '#/models/company/company.model';
import { sortBy, uniqWith } from 'lodash';
import { debounceTime, Subject, switchMap } from 'rxjs';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { TranslateModule } from '@ngx-translate/core';
import { ActionsSubject } from '@ngrx/store';
import { CompanyState } from '#/models/company/company.reducer';
import { minimumStyle } from '~/app/util/component';
import { SubCompanyServiceDeprecated } from '~/app/modules/company/services/sub-company-deprecated.service';

@Component({
	selector: 'app-company-sub-company-picker',
	styles: minimumStyle,
	templateUrl: './company-sub-company-picker.component.html',
})
export class CompanySubCompanyPickerComponent extends CompanyComponent implements OnInit {
	public value: Company | Company[];
	@Output()
	public subCompanyChange = new EventEmitter<Company | Company[]>();

	@Input() public disabled = false;
	@Input() public multiple = false;

	public loading = true;
	public useTypeAhead = false;

	public selectOptions: Company[] = [];
	public selectOptionsTypeahead = new Subject<string>();
	public selectedOptions: Company[] = [];

	public bindLabel = 'name';

	constructor(public subCompanyServiceDeprecated: SubCompanyServiceDeprecated, private actionsSubject: ActionsSubject) {
		super();

		this.on(
			this.store.select('company').subscribe((companyState) => {
				this.updateSelectOptions(companyState);
			}),
		);
	}

	ngOnInit(): void {
		super.ngOnInit();

		const filters = new CompanySubCompaniesListAPIRequest();
		filters.company = this.company.id;

		this.selectOptionsTypeahead
			.pipe(
				debounceTime(100),
				switchMap((term) => {
					this.loading = true;
					return new Promise((resolve, reject) => {
						const companies = [];

						if (this.parentCompany.getName().match(new RegExp(term, 'i'))) {
							companies.push(this.parentCompany);
						}

						if (term === '' || term === null) {
							resolve(companies);
							return;
						}

						filters.name = term;
						filters.max = 100;
						filters.start = 0;

						this.companyAPIService
							.getCompanySubCompanies(filters)
							.then((companySubCompaniesData) => {
								companies.push(...companySubCompaniesData.companies);
								resolve(companies);
							})
							.catch((e) => {
								reject(e);
							});
					});
				}),
			)
			.subscribe(
				(items) => {
					// @ts-ignore
					this.selectOptions = items;
					this.loadCurrentValue();
				},
				(err) => {
					this.selectOptions = [this.parentCompany];
					this.loadCurrentValue();
				},
			);

		this.loadItems(filters);
	}

	updateSelectOptions(companyState: CompanyState) {
		const changedCompanyId = companyState.company?.id;
		const indexOfChangedCompany = this.selectOptions.findIndex((company) => {
			return company.id === changedCompanyId;
		});

		if (indexOfChangedCompany >= 0) {
			const tempArr = [...this.selectOptions];
			tempArr[indexOfChangedCompany] = companyState.company;
			this.selectOptions = tempArr;
		}
	}

	compareWith(item: Company, selected: Company) {
		return item.id === selected.id;
	}

	loadItems(filters: CompanySubCompaniesListAPIRequest) {
		this.selectOptions = [this.parentCompany];
		this.getItemsPage(filters, 100);
	}

	getItemsPage(filters: CompanySubCompaniesListAPIRequest, per_page: number = 100, page: number = 1) {
		filters.max = per_page;
		filters.start = (page - 1) * per_page;
		this.companyAPIService
			.getCompanySubCompanies(filters)
			.then((companySubCompaniesData) => {
				if (companySubCompaniesData && companySubCompaniesData.companies) {
					companySubCompaniesData.companies.forEach((item) => {
						this.selectOptions.push(item);
					});
				}
				if (companySubCompaniesData && companySubCompaniesData.moreresults) {
					// More than 100 items, change to typeahead.
					this.useTypeAhead = true;
				}
				this.loadCurrentValue();
			})
			.catch((e) => {
				this.loading = false;
			});
	}

	loadCurrentValue() {
		const currentValueCheck = (currentValue) => {
			return new Promise<void>((resolve, reject) => {
				// Check whether already in selectedOptions.
				if (this.selectedOptions && this.selectedOptions.filter((o) => o.id === currentValue).length === 0) {
					// Check if value is in options.
					const currentValueInOptions = this.selectOptions.filter((o) => o.getID() === currentValue);
					if (currentValueInOptions.length === 0) {
						// Current value is not in available list, load it.
						this.companyAPIService
							.getCompany(currentValue)
							.then((company) => {
								this.selectedOptions.push(company);
								resolve();
							})
							.catch((e) => {
								reject(e);
							});
					} else {
						this.selectedOptions.push(currentValueInOptions[0]);
						resolve();
					}
				} else {
					resolve();
				}
			});
		};

		if (this.value && typeof this.value === 'string' && this.value !== '') {
			currentValueCheck(this.value)
				.then((res) => {
					this.finalizeLoading();
				})
				.catch((e) => {
					this.finalizeLoading();
				});
		} else if (this.value && typeof this.value === 'object' && Array.isArray(this.value) && this.value.length > 0) {
			const currentValuePromises = [];
			this.value.forEach((val) => {
				currentValuePromises.push(currentValueCheck(val));
			});

			Promise.all(currentValuePromises)
				.then((res) => {
					this.finalizeLoading();
				})
				.catch((e) => {
					this.finalizeLoading();
				});
		} else {
			this.selectedOptions = [];
			this.finalizeLoading();
		}
	}

	finalizeLoading() {
		// Add selected options to the array.
		if (this.selectedOptions && this.selectedOptions.length > 0) {
			this.selectOptions.push(...this.selectedOptions);
		}

		// Remove duplicate values.
		this.selectOptions = uniqWith(this.selectOptions, (a, b) => {
			return a.id === b.id;
		});

		// Sort by title.
		this.selectOptions = sortBy(this.selectOptions, [this.bindLabel]);
		this.loading = false;
	}

	trackSelectOption(item: any): any {
		return item.id;
	}

	@Input()
	get subCompany() {
		return this.value;
	}

	set subCompany(val) {
		this.value = val;
		this.subCompanyChange.emit(this.value);
	}
}

@NgModule({
	declarations: [CompanySubCompanyPickerComponent],
	imports: [CommonModule, FormsModule, NgSelectModule, TranslateModule],
	exports: [CompanySubCompanyPickerComponent],
})
export class CompanySubCompanyPickerModule {}
