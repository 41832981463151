import { Injectable } from '@angular/core';
import { APIService } from '~/app/api/services/api.service';
import { CompanyService } from '#/services/company/company.service';
import { stringIsSetAndFilled } from '#/util/values';
import { PossibleCompanyPlugins } from '#/models/company/plugins.model';
import { arrayIsSetAndFilled } from '#/util/arrays';

export interface PluginTypes {
	[key: string]: PluginType;
}
export interface Plugins {
	[key: string]: Plugin;
}

export interface PluginType {
	AuthenticationType: 'username_password' | 'none';
	Key: string;
	Label: string;
	UIDefinition: {
		SetupConfigurationFields: Array<{
			Key: string;
			Label: string;
			Description: string;
			Type: 'string' | 'select';
		}>;
		ConfigurationFields: Array<{
			Key: string;
			Label: string;
			Description: string;
			Type: 'string' | 'select';
		}>;
	};
}

export interface Plugin {
	type: string;
	username: string;
	description: string;
	[key: string]: any; // Not sure if there are move fields possible.
	config: {
		default_relation_code?: string;
		default_static_payment_method?: string;
		txi_id?: string;
		[key: string]: any; // Not sure if there are move fields possible.
	};
}

@Injectable({
	providedIn: 'root',
})
export class PluginsService {
	constructor(private apiService: APIService, private companyService: CompanyService) {}

	public getActivePluginsAsMap(): Promise<Plugins> {
		const companyId = this.companyService.getCompanyOfLoggedUser()?.id;
		if (stringIsSetAndFilled(companyId)) {
			return this.apiService.getFromApi(`company/${companyId}/plugins`).then((r) => r.data);
		}
		throw new Error('Company id is not defined');
	}

	public getActivePluginsAsArray(): Promise<Array<Plugin & { id: string }>> {
		const companyId = this.companyService.getCompanyOfLoggedUser()?.id;
		if (stringIsSetAndFilled(companyId)) {
			return this.getActivePluginsAsMap().then((r) => {
				return Object.entries(r).map((e) => {
					const key: string = e[0];
					const value: Plugin = e[1];
					return { ...value, id: key };
				});
			});
		}
		throw new Error('Company id is not defined');
	}

	public async getAvailablePlugins(): Promise<PluginTypes> {
		const companyId = this.companyService.getCompanyOfLoggedUser()?.id;
		if (stringIsSetAndFilled(companyId)) {
			const pluginTypes = await this.apiService.getFromApi(`company/${companyId}/plugins/types`);
			const pluginTypesObject = this.pluginTypesArrayToMap(pluginTypes.data);
			return pluginTypesObject;
		}
		throw new Error('Company id is not defined');
	}

	public async createNewAuthLessPlugin(pluginType: string, description, config: Record<string, string> = {}) {
		const companyId = this.companyService.getCompanyOfLoggedUser()?.id;
		if (stringIsSetAndFilled(companyId)) {
			const newPlugin = await this.apiService.postToApi(`company/${companyId}/plugins/${pluginType}/none`, {
				description,
				config,
			});
			return newPlugin.data;
		}
		throw new Error('Company id is not defined');
	}

	public async createNewPlugin(
		pluginType: string,
		username: string,
		password: string,
		description: string,
		config: Record<string, string> = {},
	) {
		const companyId = this.companyService.getCompanyOfLoggedUser()?.id;
		if (stringIsSetAndFilled(companyId)) {
			const newPlugin = await this.apiService.postToApi(`company/${companyId}/plugins/${pluginType}/username_password`, {
				username,
				password,
				description,
				config,
			});
			return newPlugin.data;
		}
		throw new Error('Company id is not defined');
	}

	private pluginTypesArrayToMap(pluginTypes: Array<PluginType>) {
		const result = {};
		// plugin types are unique per Key. Therefore, we create a hashmap here where the Key is the unique plugin type key
		pluginTypes.forEach((e) => {
			result[e.Key] = e;
		});
		return result;
	}

	public async getSelectOptionsForField(pluginId: string, field: string) {
		const companyId = this.companyService.getCompanyOfLoggedUser().id;
		return this.apiService
			.getFromApi(`company/${companyId}/plugins/${pluginId}/ConfigurationFieldSelectOptions?field=${field}`)
			.then((value) => {
				return value.data.map((e) => ({
					id: e.Key,
					name: e.Value,
				}));
			});
	}

	public savePluginConfig(pluginId: string, config) {
		const companyId = this.companyService.getCompanyOfLoggedUser().id;
		return this.apiService.postToApi(`company/${companyId}/plugins/${pluginId}/config`, {
			plugin_configuration: config,
		});
	}

	deletePlugin(pluginId: string) {
		const companyId = this.companyService.getCompanyOfLoggedUser().id;
		return this.apiService.deleteToApi(`company/${companyId}/plugins/${pluginId}`);
	}

	public async companyHasSpecificPluginTypeEnabled(pluginType: PossibleCompanyPlugins): Promise<boolean> {
		const availablePlugins = await this.getActivePluginsAsArray();
		if (arrayIsSetAndFilled(availablePlugins)) {
			return availablePlugins.some((plugin) => plugin.type === pluginType);
		}
		return false;
	}
}
