<div [ngStyle]="containerCSS">
	<ng-template ngFor let-i="index" let-input [ngForOf]="fields">
		<div
			*ngIf="canShow(input) && !isGroup(input)"
			[class]="'form-group' + (isHorizontal && i !== 0 ? ' ml-2' : '')"
			[ngStyle]="{ width: input.Width ? input.Width + 'px' : '100%' }"
		>
			<label [for]="input.Key">
				{{ input.Validation?.MinLen === 1 ? input.Label + '*' : input.Label }}
			</label>
			<ng-select
				*ngIf="isDropdown(input)"
				[id]="input.Key"
				[items]="(dropDownInfo[input.Key] && dropDownInfo[input.Key]) || []"
				bindLabel="Name"
				bindValue="Key"
				[clearable]="true"
				[loading]="!dropDownInfo[input.Key]"
				[(ngModel)]="input.Value"
			></ng-select>
			<input
				*ngIf="!isDropdown(input)"
				type="text"
				#inputField="ngModel"
				[id]="input.Key"
				[name]="input.Key"
				[(ngModel)]="input.Value"
				class="form-control"
				[ngClass]="!isValid(input) && !inputField.pristine ? ' is-invalid' : ''"
			/>
			<div *ngIf="!isValid(input)" class="invalid-feedback">
				{{
					input.Validation.MinLen && (!input.Value || field.Value.length < field.Validation.MinLen)
						? input.Validation.MinLen === 1
							? ('Field required' | translate)
							: ('Field need to have a minimum of %chars% characters' | translate: { chars: input.Validation.MinLen })
						: input.Validation.MaxLen && input.Value && input.Value.length >= input.Validation.MaxLen
						? ("Field can't have more than %chars% characters" | translate: { chars: input.Validation.MaxLen })
						: ('Unknown error' | translate)
				}}
			</div>
		</div>
		<app-integration-custom-relation-field
			*ngIf="canShow(input) && isGroup(input)"
			[field]="input"
			[isOrg]="isOrg"
			[integration]="integration"
			[division]="division"
			[newCustomRelationFieldName]="newCustomRelationFieldName"
		></app-integration-custom-relation-field>
	</ng-template>
</div>
