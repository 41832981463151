import { Component, Host, Optional } from '@angular/core';
import { ControlContainer, NG_VALUE_ACCESSOR } from '@angular/forms';
import { AdministrationDependantPickerComponent } from '~/app/shared/ui/forms/composed/pickers/dynamic/administration-dependant/administration-dependant-picker.component';
import { arrayIsSetAndEmpty } from '#/util/arrays';
import { AppSelectOption, FormElementComponent } from '@klippa/ngx-enhancy-forms';
import { UserService } from '~/app/modules/user/user.service';
import { CompanyService } from '#/services/company/company.service';
import { CompanyCostUnitService } from '#/services/company/dimension/company-cost-unit.service';
import { CompanyCostUnitListAPIRequest, CostUnit } from '#/models/company/dimension/cost-unit.model';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { Order } from '#/models/utils/order';
import { CompanyUnitService } from '#/services/company/company-unit.service';
import { stringIsSetAndFilled } from '#/util/values';
import { TransactionOwnerDependantPickerService } from '#/services/transaction/transaction-owner-dependant-picker.service';
import { ItemsWithHasMoreResultsPromise } from '#/models/appSelectOption.model';

@Component({
	selector: 'app-cost-unit-picker',
	templateUrl: '../../dynamic-options-picker/dynamic-options-picker.template.html',
	styleUrls: ['../../dynamic-options-picker/dynamic-options-picker.template.scss'],
	providers: [{ provide: NG_VALUE_ACCESSOR, useExisting: CostUnitPickerComponent, multi: true }],
})
export class CostUnitPickerComponent extends AdministrationDependantPickerComponent<CostUnit> {
	constructor(
		@Optional() @Host() protected parent: FormElementComponent,
		@Optional() @Host() protected controlContainer: ControlContainer,
		protected userService: UserService,
		protected companyApiService: CompanyService,
		protected companyCostUnitService: CompanyCostUnitService,
		private companyUnitService: CompanyUnitService<CostUnit>,
		protected transactionOwnerDependantPickerService: TransactionOwnerDependantPickerService,
	) {
		super(parent, controlContainer, userService, companyApiService, transactionOwnerDependantPickerService);
		this.defaultPlaceHolder = _('Select cost unit');
	}

	fetchItemsFn = async (start: number, searchQuery: string): ItemsWithHasMoreResultsPromise<CostUnit> => {
		if (arrayIsSetAndEmpty(this.shouldBelongToAdministrationIds)) {
			return {
				hasMoreResults: false,
				items: [],
			};
		}
		const filters = new CompanyCostUnitListAPIRequest();
		filters.company = this.companyId;
		if (this.filterOnCompanyGroups) {
			filters.groups = await this.getGroups();
		}
		filters.start = start;
		filters.search = searchQuery;
		filters.sort = 'name';
		filters.sortorder = Order.ASCENDING;
		filters.administrations = this.shouldBelongToAdministrationIds;

		return this.companyCostUnitService.getCompanyCostUnits(filters).then((res) => {
			const items = res.company_costunits.filter((e) => this.filterOnActives === false || e.active);
			return {
				hasMoreResults: res.moreresults,
				items,
			};
		});
	};

	fetchSelectedItemsFn = async (ids: Array<string>): Promise<Array<CostUnit>> => {
		if (arrayIsSetAndEmpty(this.shouldBelongToAdministrationIds)) {
			return [];
		}

		const filters = new CompanyCostUnitListAPIRequest();
		filters.company = this.companyId;
		filters.ids = ids;
		filters.administrations = this.shouldBelongToAdministrationIds;

		return this.companyCostUnitService.getCompanyCostUnits(filters).then((res) => res.company_costunits);
	};

	mapToSelectOptionFn = (e: CostUnit): AppSelectOption => {
		const data = this.companyUnitService.potentiallySimplifyUnitData(e);

		return {
			id: data.id,
			name: [data.code, data.name].filter(stringIsSetAndFilled).join(' - '),
			description: data.description,
			active: data.active,
		};
	};
}
