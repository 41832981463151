<div class="overlay">
	<div class="container min-full-height d-flex justify-content-center align-items-center">
		<app-loader-deprecated *ngIf="loading"></app-loader-deprecated>
		<div *ngIf="!loading" style="width: 450px" class="p-2 pb-5 pt-5">
			<div *ngIf="loadingError" class="card">
				<div class="card-body">
					<p translate>We were unable to load this page</p>
					<div class="btn-group btn-group-lg w-100" role="group" aria-label="AproveOrReject">
						<button type="button" (click)="backToKlippa()" class="btn btn-secondary w-100">Back to klippa</button>
					</div>
				</div>
			</div>
			<div *ngIf="!loadingError" class="d-flex flex-column justify-content-center align-items-center">
				<h3>{{ 'Authorize %appName%' | translate: { appName: app.name } }}</h3>
			</div>
			<div *ngIf="!loadingError" class="card">
				<div class="card-body">
					<p class="card-text" translate>Wants access to the following data:</p>
					<scope-view [(scope)]="app.allowedScopes"></scope-view>
				</div>
				<div class="card-body">
					<div *ngIf="loadingAuthorize" class="d-flex justify-content-center align-items-center">
						<app-loader-deprecated></app-loader-deprecated>
					</div>
					<div *ngIf="!loadingAuthorize" class="btn-group btn-group-lg w-100" role="group" aria-label="AproveOrReject">
						<button type="button" (click)="authorize()" class="btn btn-primary w-100">Authorize</button>
						<button type="button" (click)="backToKlippa()" class="btn btn-secondary">Back to Klippa</button>
					</div>
					<p *ngIf="redirectsTo" class="card-text text-muted text-center">
						<span translate>If you authorize this app, you will be redirected to:</span>
						<br />
						<b>{{ redirectsTo }}</b>
					</p>
					<pre>{{ error }}</pre>
				</div>
			</div>
		</div>
	</div>
</div>
