import { stringIsSetAndFilled } from '#/util/values';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';

export class Strings {
	static capitalize(token: string): string {
		return !stringIsSetAndFilled(token) ? '' : token.charAt(0).toUpperCase() + token.slice(1);
	}

	static copy(document, text: string) {
		const textArea = document.createElement('textarea');
		textArea.style.position = 'fixed';
		textArea.style.left = '0';
		textArea.style.top = '0';
		textArea.style.opacity = '0';
		textArea.value = text;
		document.body.appendChild(textArea);
		textArea.focus();
		textArea.select();
		document.execCommand('copy');
		document.body.removeChild(textArea);
	}
}

export class ModalMessages {
	static modalActionMessages = {
		expense: {
			'change-status': {
				success: [_('Updated %count% expense of %totalCount%.'), _('Updated %count% expenses of %totalCount%.')],
				failed: [_('Note: %count% expense could not be updated.'), _('Note: %count% expenses could not be updated.')],
				alert: [_('Updated %count% expense!'), _('Updated %count% expenses!')],
				confirmation: [_('You are going to update %count% expense.'), _('You are going to update %count% expenses.')],
			},
		},
		receipt: {
			'change-status': {
				success: [_('Updated %count% receipt of %totalCount%.'), _('Updated %count% receipts of %totalCount%.')],
				failed: [_('Note: %count% receipt could not be updated.'), _('Note: %count% receipts could not be updated.')],
				alert: [_('Updated %count% receipt!'), _('Updated %count% receipts!')],
				confirmation: [_('You are going to update %count% receipt.'), _('You are going to update %count% receipts.')],
				note: [
					_("Note: It's possible that the receipt can't be processed."),
					_("Note: It's possible that not all receipts can be processed."),
				],
			},
			'force-ocr': {
				alert: [_('The receipt will be processed using OCR.'), _('%count% receipts will be processed using OCR.')],
				confirmation: [
					_('Are you sure you want to run an OCR scan on this receipt?'),
					_('Are you sure you want to run OCR scans on %count% receipts?'),
				],
			},
			submit: {
				success: [_('Submitted %count% of %totalCount% receipt.'), _('Submitted %count% of %totalCount% receipts.')],
				failed: [_('Note: %count% receipt could not be submitted.'), _('Note: %count% receipts could not be submitted.')],
				alert: [_('Submitted %count% receipt!'), _('Submitted %count% receipts!')],
				confirmation: [
					_('Are you sure that you want to submit %count% expense?'),
					_('Are you sure that you want to submit %count% expenses?'),
				],
				note: [
					_("Note: It's possible that the receipt can't be submitted."),
					_("Note: It's possible that not all receipts can be submitted."),
				],
				alreadySubmitted: [_('Note: %count% receipt was already submitted.'), _('Note: %count% receipts were already submitted.')],
			},
			retract: {
				success: [_('Retracted %count% of %totalCount% receipt.'), _('Retracted %count% of %totalCount% receipts.')],
				failed: [_('Note: %count% receipt could not be retracted.'), _('Note: %count% receipts could not be retracted.')],
				alert: [_('Retracted %count% receipt!'), _('Retracted %count% receipts!')],
				confirmation: [
					_('Are you sure that you want to retract %count% expense?'),
					_('Are you sure that you want to retract %count% expenses?'),
				],
				note: [
					_("Note: It's possible that the receipt can't be retracted."),
					_("Note: It's possible that not all receipts can be retracted."),
				],
				notSubmitted: [
					_('Note: %count% receipt was not submitted and could therefore not be retracted.'),
					_('Note: %count% receipts were not submitted and could therefore not be retracted.'),
				],
			},
			delete: {
				success: [_('Deleted %count% of %totalCount% receipt.'), _('Deleted %count% of %totalCount% receipts.')],
				failed: [_('Note: %count% receipt could not be deleted.'), _('Note: %count% receipts could not be deleted.')],
				alert: [_('Deleted %count% receipt!'), _('Deleted %count% receipts!')],
				confirmation: [
					_('Are you sure that you want to delete %count% receipt?'),
					_('Are you sure that you want to delete %count% receipts?'),
				],
				note: [_("Note: It's possible that the receipt can't be deleted."), _("Note: It's possible that not all receipts can be deleted.")],
			},
			copy: {
				success: [_('Duplicated %count% of %totalCount% receipt.'), _('Duplicated %count% of %totalCount% receipts.')],
				failed: [_('Note: %count% receipt could not be duplicated.'), _('Note: %count% receipts could not be duplicated.')],
				alert: [_('Duplicated %count% receipt!'), _('Duplicated %count% receipts!')],
				confirmation: [
					_('Are you sure that you want to duplicate %count% receipt?'),
					_('Are you sure that you want to duplicate %count% receipts?'),
				],
			},
			download: {
				success: [_('Exporting the images of %totalCount% receipt.'), _('Exporting the images of %totalCount% receipts.')],
				confirmation: [
					_('You are going to export the images of %count% receipt.'),
					_('You are going to export the images of %count% receipts.'),
				],
			},
			move: {
				success: [_('Moved %count% receipt of %totalCount%.'), _('Moved %count% receipts of %totalCount%.')],
				failed: [_('Note: %count% receipt could not be moved.'), _('Note: %count% receipts could not be moved.')],
				alert: [_('Moved %count% receipt!'), _('Moved %count% receipts!')],
				confirmation: [_('You are going to move %count% receipt.'), _('You are going to move %count% receipts.')],
			},
			share: {
				success: [_('Shared %count% receipt of %totalCount%.'), _('Shared %count% receipts of %totalCount%.')],
				failed: [_('Note: %count% receipt could not be shared.'), _('Note: %count% receipts could not be shared.')],
				alert: [_('Shared %count% receipt!'), _('Shared %count% receipts!')],
				confirmation: [_('Are you sure you want to share %count% receipt?'), _('Are you sure you want to share %count% receipts?')],
			},
			book: {
				success: [_('Booked %count% of %totalCount% receipt.'), _('Booked %count% of %totalCount% receipts.')],
				failed: [_('Note: %count% receipt could not be booked.'), _('Note: %count% receipts could not be booked.')],
				alert: [_('Booked %count% receipt!'), _('Booked %count% receipts!')],
				confirmation: [_('Are you sure that you want to book %count% expense?'), _('Are you sure that you want to book %count% expenses?')],
				note: [_("Note: It's possible that the receipt can't be booked."), _("Note: It's possible that not all receipts can be booked.")],
			},
			book_expenses: {
				success: [_('Booked %count% of %totalCount% expense.'), _('Booked %count% of %totalCount% expenses.')],
				failed: [_('Note: %count% expense could not be booked.'), _('Note: %count% expenses could not be booked.')],
				alert: [_('Booked %count% expense!'), _('Booked %count% expenses!')],
				confirmation: [_('Are you sure that you want to book %count% expense?'), _('Are you sure that you want to book %count% expenses?')],
				note: [_("Note: It's possible that the expense can't be booked."), _("Note: It's possible that not all expenses can be booked.")],
			},
			book_invoices: {
				success: [_('Booked %count% of %totalCount% invoice.'), _('Booked %count% of %totalCount% invoices.')],
				failed: [_('Note: %count% invoice could not be booked.'), _('Note: %count% invoices could not be booked.')],
				alert: [_('Booked %count% invoice!'), _('Booked %count% invoices!')],
				confirmation: [_('Are you sure that you want to book %count% invoice?'), _('Are you sure that you want to book %count% invoices?')],
				note: [_("Note: It's possible that the invoice can't be booked."), _("Note: It's possible that not all invoices can be booked.")],
			},
			book_documents: {
				success: [_('Booked %count% of %totalCount% document.'), _('Booked %count% of %totalCount% documents.')],
				failed: [_('Note: %count% document could not be booked.'), _('Note: %count% documents could not be booked.')],
				alert: [_('Booked %count% document!'), _('Booked %count% documents!')],
				confirmation: [
					_('Are you sure that you want to book %count% document?'),
					_('Are you sure that you want to book %count% documents?'),
				],
				note: [_("Note: It's possible that the document can't be booked."), _("Note: It's possible that not all documents can be booked.")],
			},
			removeShare: {
				success: [_('Stopped sharing with %count% user.'), _('Stopped sharing with %count% users.')],
				failed: [_('Could not stop sharing with %count% user.'), _('Could not stop sharing with %count% users.')],
			},
		},
		invoice: {
			'change-status': {
				success: [_('Updated %count% invoice of %totalCount%.'), _('Updated %count% invoices of %totalCount%.')],
				failed: [_('Note: %count% invoice could not be updated.'), _('Note: %count% invoices could not be updated.')],
				alert: [_('Updated %count% invoice!'), _('Updated %count% invoices!')],
				confirmation: [_('You are going to update %count% invoice.'), _('You are going to update %count% invoices.')],
				note: [
					_("Note: It's possible that the invoice can't be processed."),
					_("Note: It's possible that not all invoices can be processed."),
				],
			},
			'force-ocr': {
				alert: [_('The invoice will be processed using OCR.'), _('%count% invoices will be processed using OCR.')],
				confirmation: [
					_('Are you sure you want to run an OCR scan on this invoice?'),
					_('Are you sure you want to run OCR scans on %count% invoices?'),
				],
			},
			submit: {
				success: [_('Submitted %count% of %totalCount% invoice.'), _('Submitted %count% of %totalCount% invoices.')],
				failed: [_('Note: %count% invoice could not be submitted.'), _('Note: %count% invoices could not be submitted.')],
				alert: [_('Submitted %count% invoice!'), _('Submitted %count% invoices!')],
				confirmation: [
					_('Are you sure that you want to submit %count% invoice?'),
					_('Are you sure that you want to submit %count% invoices?'),
				],
				note: [
					_("Note: It's possible that the invoice can't be submitted."),
					_("Note: It's possible that not all invoices can be submitted."),
				],
				alreadySubmitted: [_('Note: %count% invoice was already submitted.'), _('Note: %count% invoices were already submitted.')],
			},
			retract: {
				success: [_('Retracted %count% of %totalCount% invoice.'), _('Retracted %count% of %totalCount% invoices.')],
				failed: [_('Note: %count% invoice could not be retracted.'), _('Note: %count% invoices could not be retracted.')],
				alert: [_('Retracted %count% invoice!'), _('Retracted %count% invoices!')],
				confirmation: [
					_('Are you sure that you want to retract %count% invoice?'),
					_('Are you sure that you want to retract %count% invoices?'),
				],
				note: [
					_("Note: It's possible that the invoice can't be retracted."),
					_("Note: It's possible that not all invoices can be retracted."),
				],
				notSubmitted: [
					_('Note: %count% invoice was not submitted and could therefore not be retracted.'),
					_('Note: %count% invoices were not submitted and could therefore not be retracted.'),
				],
			},
			delete: {
				success: [_('Deleted %count% of %totalCount% invoice.'), _('Deleted %count% of %totalCount% invoices.')],
				failed: [_('Note: %count% invoice could not be deleted.'), _('Note: %count% invoices could not be deleted.')],
				alert: [_('Deleted %count% invoice!'), _('Deleted %count% invoices!')],
				confirmation: [
					_('Are you sure that you want to delete %count% invoice?'),
					_('Are you sure that you want to delete %count% invoices?'),
				],
				note: [_("Note: It's possible that the invoice can't be deleted."), _("Note: It's possible that not all invoices can be deleted.")],
			},
			copy: {
				success: [_('Duplicated %count% of %totalCount% invoice.'), _('Duplicated %count% of %totalCount% invoices.')],
				failed: [_('Note: %count% invoice could not be duplicated.'), _('Note: %count% invoices could not be duplicated.')],
				alert: [_('Duplicated %count% invoice!'), _('Duplicated %count% invoices!')],
				confirmation: [
					_('Are you sure that you want to duplicate %count% invoice?'),
					_('Are you sure that you want to duplicate %count% invoices?'),
				],
			},
			download: {
				success: [_('Exporting the images of %totalCount% invoice.'), _('Exporting the images of %totalCount% invoices.')],
				confirmation: [
					_('You are going to export the images of %count% invoice.'),
					_('You are going to export the images of %count% invoices.'),
				],
			},
			export: {
				success: [_('Exporting %totalCount% invoice.'), _('Exporting %totalCount% invoices.')],
				confirmation: [_('You are going to export %count% invoice.'), _('You are going to export %count% invoices.')],
			},
			move: {
				success: [_('Moved %count% invoice of %totalCount%.'), _('Moved %count% invoices of %totalCount%.')],
				failed: [_('Note: %count% invoice could not be moved.'), _('Note: %count% invoices could not be moved.')],
				alert: [_('Moved %count% invoice!'), _('Moved %count% invoices!')],
				confirmation: [_('You are going to move %count% invoice.'), _('You are going to move %count% invoices.')],
			},
			share: {
				success: [_('Shared %count% invoice of %totalCount%.'), _('Shared %count% invoices of %totalCount%.')],
				failed: [_('Note: %count% invoice could not be shared.'), _('Note: %count% invoices could not be shared.')],
				alert: [_('Shared %count% invoice!'), _('Shared %count% invoices!')],
				confirmation: [_('Are you sure you want to share %count% invoice?'), _('Are you sure you want to share %count% invoices?')],
			},
			book: {
				success: [_('Booked %count% of %totalCount% invoice.'), _('Booked %count% of %totalCount% invoices.')],
				failed: [_('Note: %count% invoice could not be booked.'), _('Note: %count% invoices could not be booked.')],
				alert: [_('Booked %count% invoice!'), _('Booked %count% invoices!')],
				confirmation: [_('Are you sure that you want to book %count% invoice?'), _('Are you sure that you want to book %count% invoices?')],
				note: [_("Note: It's possible that the invoice can't be booked."), _("Note: It's possible that not all invoices can be booked.")],
			},
			book_expenses: {
				success: [_('Booked %count% of %totalCount% expense.'), _('Booked %count% of %totalCount% expenses.')],
				failed: [_('Note: %count% expense could not be booked.'), _('Note: %count% expenses could not be booked.')],
				alert: [_('Booked %count% expense!'), _('Booked %count% expenses!')],
				confirmation: [_('Are you sure that you want to book %count% expense?'), _('Are you sure that you want to book %count% expenses?')],
				note: [_("Note: It's possible that the expense can't be booked."), _("Note: It's possible that not all expenses can be booked.")],
			},
			book_invoices: {
				success: [_('Booked %count% of %totalCount% invoice.'), _('Booked %count% of %totalCount% invoices.')],
				failed: [_('Note: %count% invoice could not be booked.'), _('Note: %count% invoices could not be booked.')],
				alert: [_('Booked %count% invoice!'), _('Booked %count% invoices!')],
				confirmation: [_('Are you sure that you want to book %count% invoice?'), _('Are you sure that you want to book %count% invoices?')],
				note: [_("Note: It's possible that the invoice can't be booked."), _("Note: It's possible that not all invoices can be booked.")],
			},
			book_documents: {
				success: [_('Booked %count% of %totalCount% document.'), _('Booked %count% of %totalCount% documents.')],
				failed: [_('Note: %count% document could not be booked.'), _('Note: %count% documents could not be booked.')],
				alert: [_('Booked %count% document!'), _('Booked %count% documents!')],
				confirmation: [
					_('Are you sure that you want to book %count% document?'),
					_('Are you sure that you want to book %count% documents?'),
				],
				note: [_("Note: It's possible that the document can't be booked."), _("Note: It's possible that not all documents can be booked.")],
			},
			removeShare: {
				success: [_('Stopped sharing with %count% user.'), _('Stopped sharing with %count% users.')],
				failed: [_('Could not stop sharing with %count% user.'), _('Could not stop sharing with %count% users.')],
			},
		},
		group: {
			submit: {
				success: [_('Submitted %count% of %totalCount% folder.'), _('Submitted %count% of %totalCount% folders.')],
				failed: [_('Note: %count% folder could not be submitted.'), _('Note: %count% folders could not be submitted.')],
				alert: [_('Submitted %count% folder!'), _('Submitted %count% folders!')],
				confirmation: [
					_('Are you sure that you want to submit %count% expense?'),
					_('Are you sure that you want to submit %count% expenses?'),
				],
				note: [
					_("Note: It's possible that the folder can't be submitted."),
					_("Note: It's possible that not all folders can be submitted."),
				],
				alreadySubmitted: [_('Note: %count% receipt was already submitted.'), _('Note: %count% receipts were already submitted.')],
			},
			retract: {
				success: [_('Retracted %count% of %totalCount% folder.'), _('Retracted %count% of %totalCount% folders.')],
				failed: [_('Note: %count% folder could not be retracted.'), _('Note: %count% folders could not be retracted.')],
				alert: [_('Retracted %count% folder!'), _('Retracted %count% folders!')],
				confirmation: [
					_('Are you sure that you want to retract %count% expense?'),
					_('Are you sure that you want to retract %count% expenses?'),
				],
				note: [
					_("Note: It's possible that the folder can't be retracted."),
					_("Note: It's possible that not all folders can be retracted."),
				],
				notSubmitted: [
					_('Note: %count% receipt was not submitted and could therefore not be retracted.'),
					_('Note: %count% receipts were not submitted and could therefore not be retracted.'),
				],
			},
			delete: {
				success: [_('Deleted %count% of %totalCount% folder.'), _('Deleted %count% of %totalCount% folders.')],
				failed: [_('Note: %count% folder could not be deleted.'), _('Note: %count% folders could not be deleted.')],
				alert: [_('Deleted %count% folder!'), _('Deleted %count% folders!')],
				confirmation: [
					_('Are you sure that you want to delete %count% folder?'),
					_('Are you sure that you want to delete %count% folders?'),
				],
				note: [_("Note: It's possible that the folder can't be deleted."), _("Note: It's possible that not all folders can be deleted.")],
			},
			download: {
				success: [_('Exporting the images of %totalCount% folder.'), _('Exporting the images of %totalCount% folders.')],
				confirmation: [
					_('You are going to export the images of %count% folder.'),
					_('You are going to export the images of %count% folders.'),
				],
			},
			export: {
				success: [_('Exporting %totalCount% folder.'), _('Exporting %totalCount% folders.')],
				confirmation: [_('You are going to export %count% folder.'), _('You are going to export %count% folders.')],
			},
			move: {
				success: [_('Moved %count% folder of %totalCount%.'), _('Moved %count% folders of %totalCount%.')],
				failed: [_('Note: %count% folder could not be moved.'), _('Note: %count% folders could not be moved.')],
				alert: [_('Moved %count% folder!'), _('Moved %count% folders!')],
				confirmation: [_('You are going to move %count% folder.'), _('You are going to move %count% folders.')],
			},
			share: {
				success: [_('Shared %count% folder of %totalCount%.'), _('Shared %count% folders of %totalCount%.')],
				failed: [_('Note: %count% folder could not be shared.'), _('Note: %count% folders could not be shared.')],
				alert: [_('Shared %count% folder!'), _('Shared %count% folders!')],
				confirmation: [_('Are you sure you want to share %count% folder.'), _('Are you sure you want to share %count% folders.')],
			},
			'change-status': {
				success: [_('Updated %count% expense of %totalCount%.'), _('Updated %count% expenses of %totalCount%.')],
				failed: [_('Note: %count% expense could not be updated.'), _('Note: %count% expenses could not be updated.')],
				alert: [_('Updated %count% expense!'), _('Updated %count% expenses!')],
				confirmation: [_('You are going to update %count% expense.'), _('You are going to update %count% expenses.')],
			},
			removeShare: {
				success: [_('Stopped sharing with %count% user.'), _('Stopped sharing with %count% users.')],
				failed: [_('Could not stop sharing with %count% user.'), _('Could not stop sharing with %count% users.')],
			},
		},
		undefined: {
			undefined: {
				success: [_('Processed %count% of %totalCount% item.'), _('Processed %count% of %totalCount% items.')],
				failed: [_('Note: %count% item could not be processed.'), _('Note: %count% items could not be processed.')],
				alert: [_('%count% item processed!'), _('%count% items processed!')],
				confirmation: [_('Are you sure you want to process %count% item?'), _('Are you sure you want to process %count% items?')],
				note: [_("Note: It's possible that the item can't be processed."), _("Note: It's possible that not all items can be processed.")],
			},
		},
		tag: {
			submit: {
				success: [_('Submitted %count% of %totalCount% tag.'), _('Submitted %count% of %totalCount% tags.')],
				failed: [_('Note: %count% tag could not be submitted.'), _('Note: %count% tags could not be submitted.')],
				alert: [_('Submitted %count% tag!'), _('Submitted %count% tags!')],
				confirmation: [_('Are you sure that you want to submit %count% tag?'), _('Are you sure that you want to submit %count% tags?')],
				alreadySubmitted: [_('Note: %count% receipt was already submitted.'), _('Note: %count% receipts were already submitted.')],
			},
			retract: {
				success: [_('Retracted %count% of %totalCount% tag.'), _('Retracted %count% of %totalCount% tags.')],
				failed: [_('Note: %count% tag could not be retracted.'), _('Note: %count% tags could not be retracted.')],
				alert: [_('Retracted %count% tag!'), _('Retracted %count% tags!')],
				confirmation: [_('Are you sure that you want to retract %count% tag?'), _('Are you sure that you want to retract %count% tags?')],
				notSubmitted: [
					_('Note: %count% receipt was not submitted and could therefore not be retracted.'),
					_('Note: %count% receipts were not submitted and could therefore not be retracted.'),
				],
			},
			delete: {
				success: [_('Deleted %count% of %totalCount% tag.'), _('Deleted %count% of %totalCount% tags.')],
				failed: [_('Note: %count% tag could not be deleted.'), _('Note: %count% tags could not be deleted.')],
				alert: [_('Deleted %count% tag!'), _('Deleted %count% tags!')],
				confirmation: [_('Are you sure that you want to delete %count% tag?'), _('Are you sure that you want to delete %count% tags?')],
				note: [_("Note: It's possible that the tag can't be deleted."), _("Note: It's possible that not all tags can be deleted.")],
			},
			'change-status': {
				success: [_('Updated %count% tag.'), _('Updated %count% tags of %totalCount% tags.')],
				failed: [
					_('Note: %count% tag could not be updated due to status or permissions.'),
					_('Note: %count% tags could not be updated due to status or permissions.'),
				],
			},
		},
	};

	static modalActionMessagesAM = {
		receipt: {
			'force-ocr': {
				alert: [_('The document will be processed using OCR.'), _('%count% documents will be processed using OCR.')],
				confirmation: [
					_('Are you sure you want to run an OCR scan on this document?'),
					_('Are you sure you want to run OCR scans on %count% documents?'),
				],
			},
			submit: {
				success: [_('Submitted %count% of %totalCount% document.'), _('Submitted %count% of %totalCount% documents.')],
				failed: [_('Note: %count% document could not be submitted.'), _('Note: %count% documents could not be submitted.')],
				alert: [_('Submitted %count% document!'), _('Submitted %count% documents!')],
				confirmation: [
					_('Are you sure that you want to submit %count% document?'),
					_('Are you sure that you want to submit %count% documents?'),
				],
				note: [
					_("Note: It's possible that the document can't be submitted."),
					_("Note: It's possible that not all documents can be submitted."),
				],
				alreadySubmitted: [_('Note: %count% document was already submitted.'), _('Note: %count% documents were already submitted.')],
			},
			retract: {
				success: [_('Retracted %count% of %totalCount% document.'), _('Retracted %count% of %totalCount% documents.')],
				failed: [_('Note: %count% document could not be retracted.'), _('Note: %count% documents could not be retracted.')],
				alert: [_('Retracted %count% document!'), _('Retracted %count% documents!')],
				confirmation: [
					_('Are you sure that you want to retract %count% document?'),
					_('Are you sure that you want to retract %count% documents?'),
				],
				note: [
					_("Note: It's possible that the document can't be retracted."),
					_("Note: It's possible that not all documents can be retracted."),
				],
				notSubmitted: [
					_('Note: %count% document was not submitted and could therefore not be retracted.'),
					_('Note: %count% documents were not submitted and could therefore not be retracted.'),
				],
			},
			delete: {
				success: [_('Deleted %count% of %totalCount% document.'), _('Deleted %count% of %totalCount% documents.')],
				failed: [_('Note: %count% document could not be deleted.'), _('Note: %count% documents could not be deleted.')],
				alert: [_('Deleted %count% document!'), _('Deleted %count% documents!')],
				confirmation: [
					_('Are you sure that you want to delete %count% document?'),
					_('Are you sure that you want to delete %count% documents?'),
				],
				note: [
					_("Note: It's possible that the document can't be deleted."),
					_("Note: It's possible that not all documents can be deleted."),
				],
			},
			copy: {
				success: [_('Duplicated %count% of %totalCount% document.'), _('Duplicated %count% of %totalCount% documents.')],
				failed: [_('Note: %count% document could not be duplicated.'), _('Note: %count% documents could not be duplicated.')],
				alert: [_('Duplicated %count% document!'), _('Duplicated %count% documents!')],
				confirmation: [
					_('Are you sure that you want to duplicate %count% document?'),
					_('Are you sure that you want to duplicate %count% documents?'),
				],
			},
			download: {
				success: [_('Exporting the images of %totalCount% document.'), _('Exporting the images of %totalCount% documents.')],
				confirmation: [
					_('You are going to export the images of %count% document.'),
					_('You are going to export the images of %count% documents.'),
				],
			},
			export: {
				success: [_('Exporting %totalCount% document.'), _('Exporting %totalCount% documents.')],
				confirmation: [_('You are going to export %count% document.'), _('You are going to export %count% document.')],
			},
			move: {
				success: [_('Moved %count% document of %totalCount%.'), _('Moved %count% documents of %totalCount%.')],
				failed: [_('Note: %count% document could not be moved.'), _('Note: %count% documents could not be moved.')],
				alert: [_('Moved %count% document!'), _('Moved %count% documents!')],
				confirmation: [_('You are going to move %count% document.'), _('You are going to move %count% documents.')],
			},
			share: {
				success: [_('Shared %count% document of %totalCount%.'), _('Shared %count% documents of %totalCount%.')],
				failed: [_('Note: %count% document could not be shared.'), _('Note: %count% documents could not be shared.')],
				alert: [_('Shared %count% document!'), _('Shared %count% documents!')],
				confirmation: [_('Are you sure you want to share %count% document?'), _('Are you sure you want to share %count% documents?')],
			},
			book_documents: {
				success: [_('Booked %count% of %totalCount% document.'), _('Booked %count% of %totalCount% documents.')],
				failed: [_('Note: %count% document could not be booked.'), _('Note: %count% documents could not be booked.')],
				alert: [_('Booked %count% document!'), _('Booked %count% documents!')],
				confirmation: [
					_('Are you sure that you want to book %count% document?'),
					_('Are you sure that you want to book %count% documents?'),
				],
				note: [_("Note: It's possible that the document can't be booked."), _("Note: It's possible that not all documents can be booked.")],
			},
		},
		group: {
			submit: {
				confirmation: [
					_('Are you sure that you want to submit %count% document?'),
					_('Are you sure that you want to submit %count% documents?'),
				],
				alreadySubmitted: [_('Note: %count% document was already submitted.'), _('Note: %count% documents were already submitted.')],
			},
			retract: {
				confirmation: [
					_('Are you sure that you want to retract %count% document?'),
					_('Are you sure that you want to retract %count% documents?'),
				],
				notSubmitted: [
					_('Note: %count% document was not submitted and could therefore not be retracted.'),
					_('Note: %count% documents were not submitted and could therefore not be retracted.'),
				],
			},
			'change-status': {
				success: [_('Updated %count% document of %totalCount%.'), _('Updated %count% documents of %totalCount%.')],
				failed: [_('Note: %count% document could not be updated.'), _('Note: %count% documents could not be updated.')],
				alert: [_('Updated %count% document!'), _('Updated %count% documents!')],
				confirmation: [_('You are going to update %count% document.'), _('You are going to update %count% documents.')],
			},
		},
		tag: {
			submit: {
				alreadySubmitted: [_('Note: %count% document was already submitted.'), _('Note: %count% documents were already submitted.')],
			},
			retract: {
				notSubmitted: [
					_('Note: %count% document was not submitted and could therefore not be retracted.'),
					_('Note: %count% documents were not submitted and could therefore not be retracted.'),
				],
			},
		},
		type: {
			export: {
				success: [_('Exporting %totalCount% booking.'), _('Exporting %totalCount% bookings.')],
				confirmation: [_('You are going to export %count% booking.'), _('You are going to export %count% bookings.')],
			},
		},
	};

	static usePlural(count: number): number {
		if (count === 1) {
			return 0;
		}
		return 1;
	}

	static getCurrentCountSentence(type: string, action: string, success: string, count: number, totalCount: number, am?: boolean): string {
		let sentences = this.modalActionMessages;
		if (am) {
			sentences = Object.assign(sentences, this.modalActionMessagesAM);
		}
		if (
			sentences[type] === undefined ||
			sentences[type][action] === undefined ||
			sentences[type][action][success] === undefined ||
			sentences[type][action][success][this.usePlural(count)] === undefined
		) {
			return sentences['undefined']['undefined'][success][this.usePlural(count)];
		}
		return sentences[type][action][success][this.usePlural(count)];
	}

	static getCurrentCountSentenceTranslated(type: string, action: string, success: string, count: number, totalCount: number, am?: boolean) {
		const message = this.getCurrentCountSentence(type, action, success, count, totalCount, am);
		const params = { count: count, totalCount: totalCount };
		return {
			message: message,
			params: params,
		};
	}

	static getUserNamedType(str: string) {
		if (str === 'group') {
			return 'folder';
		}
		return str;
	}
}
