export class Language {
	Code: string;
	Title: string;
	OfficialCode: string;

	constructor(code: string, data = null) {
		this.Code = code;
		if (data) {
			Object.assign(this, data);
		}
	}

	getTitle() {
		return this.Title;
	}

	getCode() {
		return this.Code;
	}

	getOfficialCode() {
		return this.OfficialCode;
	}
}
