import { Injectable } from '@angular/core';
import { APIService } from '~/app/api/services/api.service';
import { APINotificationsService } from '~/app/api/services/apinotifications.service';
import { CompanyService } from '#/services/company/company.service';
import { Company, CompanyGroup, CompanyGroupData, CompanyGroupListAPIRequest } from '#/models/company/company.model';
import { ImportResultCount } from '#/models/import-result.model';
import { isValueSet } from '#/util/values';

@Injectable({
	providedIn: 'root',
})
export class CompanyGroupService {
	constructor(private apiService: APIService, private notifications: APINotificationsService, private companyService: CompanyService) {}

	public getCompanyOfLoggedUser(): Company {
		return this.companyService.getCompanyOfLoggedUser();
	}

	getCompanyGroups(companyGroupListAPIRequest: CompanyGroupListAPIRequest): Promise<CompanyGroupData> {
		return this.apiService
			.get(companyGroupListAPIRequest.getRequestURL())
			.then((r) => new CompanyGroupData(r.data))
			.catch((e) => {
				this.notifications.handleAPIError(e);
				throw e;
			});
	}

	getCompanyGroupsByIDs(companyGroupListAPIRequest: CompanyGroupListAPIRequest, ids: string[]): Promise<CompanyGroupData> {
		companyGroupListAPIRequest.max = 999;
		companyGroupListAPIRequest.list = true;
		return this.apiService
			.post(companyGroupListAPIRequest.getRequestURL(), { ids })
			.then((r) => new CompanyGroupData(r.data))
			.catch((e) => {
				this.notifications.handleAPIError(e);
				throw e;
			});
	}

	public async getAllUsersGroup(companyId: string = null): Promise<CompanyGroup> {
		let start = 0;
		let result = null;
		const compId = companyId ?? this.getCompanyOfLoggedUser()?.id;
		while (!isValueSet(result)) {
			const filters = new CompanyGroupListAPIRequest();
			filters.company = compId;
			filters.start = start;
			filters.max = 999;
			const groups = await this.getCompanyGroups(filters);
			result = groups.company_groups.find((e) => e.assignAll);
			if (isValueSet(result)) {
				return result;
			}
			if (!groups.moreresults) {
				return null;
			}
			start += groups.company_groups.length;
		}
	}

	importCompanyGroups(companyId: string, file: File, skipFirstRow = true): Promise<ImportResultCount> {
		const formData = new FormData();
		formData.append('file', file);

		if (skipFirstRow) {
			formData.append('skip_first_row', '1');
		}

		let url = '/api/v1/companyGroup/import';
		if (companyId !== '') {
			url = '/api/v1/company/' + companyId + '/companyGroup/import';
		}

		return this.apiService
			.post(url, formData)
			.then((r) => new ImportResultCount(r.data))
			.catch((e) => {
				this.notifications.handleAPIError(e);
				throw e;
			});
	}

	createCompanyGroup(companyId: string, companyGroup: CompanyGroup): Promise<CompanyGroup> {
		let url = '/api/v1/companyGroup';
		if (companyId !== '') {
			url = '/api/v1/company/' + companyId + '/companyGroup';
		}

		return this.apiService
			.post(url, companyGroup)
			.then((r) => new CompanyGroup(r.data))
			.catch((e) => {
				this.notifications.handleAPIError(e);
				throw e;
			});
	}

	updateCompanyGroup(companyId: string, companyGroup: CompanyGroup): Promise<CompanyGroup> {
		let url = '/api/v1/companyGroup/' + companyGroup.id;
		if (companyId !== '') {
			url = '/api/v1/company/' + companyId + '/companyGroup/' + companyGroup.id;
		}

		return this.apiService
			.patch(url, companyGroup)
			.then((r) => new CompanyGroup(r.data))
			.catch((e) => {
				this.notifications.handleAPIError(e);
				throw e;
			});
	}

	bulkCompanyGroups(companyId: string, type: string, groups: CompanyGroup[]): Promise<ImportResultCount> {
		let url = '/api/v1/companyGroup/bulk';
		if (companyId !== '') {
			url = '/api/v1/company/' + companyId + '/companyGroup/bulk';
		}

		return this.apiService
			.post(url, {
				type,
				groups,
			})
			.then((r) => new ImportResultCount(r.data))
			.catch((e) => {
				this.notifications.handleAPIError(e);
				throw e;
			});
	}

	getCompanyGroup(companyId: string, id: string): Promise<CompanyGroup> {
		let url = '/api/v1/companyGroup/' + id;
		if (companyId !== '') {
			url = '/api/v1/company/' + companyId + '/companyGroup/' + id;
		}

		return this.apiService
			.get(url)
			.then((r) => new CompanyGroup(r.data))
			.catch((e) => {
				this.notifications.handleAPIError(e);
				throw e;
			});
	}

	deleteCompanyGroup(companyId: string, id: string): Promise<any> {
		let url = '/api/v1/companyGroup/' + id;
		if (companyId !== '') {
			url = '/api/v1/company/' + companyId + '/companyGroup/' + id;
		}

		return this.apiService
			.delete(url)
			.then((r) => r)
			.catch((e) => {
				this.notifications.handleAPIError(e);
				throw e;
			});
	}
}
