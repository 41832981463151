<klp-form [formGroup]="importCreditcardFileForm">
	<klp-form-element [caption]="'Upload creditcard transactions' | translate" spaceDistribution="30-70">
		<klp-form-file-input formControlName="file"></klp-form-file-input>
	</klp-form-element>
	<div class="submitButtonContainer">
		<klp-form-submit-button [submitCallback]="sendForm">{{ 'Import' | translate }} </klp-form-submit-button>
	</div>
</klp-form>

<ngx-async-template [promise]="importResultsPromise">
	<ng-template ngx-async-success let-importCreditcardFileData="value">
		<div class="importResultsContainer">
			<div translate [translateParams]="{ totalNumberOfLines: getTotalNumberOfLines(importCreditcardFileData) }">
				Read: %totalNumberOfLines% transactions
			</div>

			<ul>
				<li translate [translateParams]="{ countOfLinesCreated: importCreditcardFileData.created }">
					Created: %countOfLinesCreated% transactions
				</li>
				<li translate [translateParams]="{ countOfLinesUpdated: importCreditcardFileData.updated }">
					Updated: %countOfLinesUpdated% transactions
				</li>
				<li translate [translateParams]="{ countOfLinesNotChanged: getNumberOfLinesNotChanged(importCreditcardFileData) }">
					Not updated: %countOfLinesNotChanged% transactions (no changes detected)
				</li>
				<li translate [translateParams]="{ countOfFailures: importCreditcardFileData.failures.amount }">
					Failures: %countOfFailures% transactions
				</li>
			</ul>

			<app-button
				variant="greenFilled"
				class="downloadImportResultsButton"
				(click)="downloadExceptionReport(importCreditcardFileData.download_url)"
				translate
			>
				Download exception report
			</app-button>
		</div>
	</ng-template>
</ngx-async-template>
