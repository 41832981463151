export class FileUtils {
	static getFileExtension(file: File): string {
		const findDot = file.name.lastIndexOf('.');
		if (findDot > 0) {
			return file.name.substr(findDot).toLowerCase();
		}

		return '';
	}

	static isFileAllowed(file: File): boolean {
		const ext = this.getFileExtension(file);
		if (ext === '') {
			return false;
		}

		// Images
		if (ext === '.png' || ext === '.gif' || ext === '.jpg' || ext === '.jpeg') {
			return true;
		}

		// Text documents.
		if (ext === '.pdf' || ext === '.txt' || ext === '.csv' || ext === '.xml' || ext === '.html') {
			return true;
		}

		// Office documents.
		if (
			ext === '.doc' ||
			ext === '.docx' ||
			ext === '.xls' ||
			ext === '.xlsx' ||
			ext === '.rtf' ||
			ext === '.ppt' ||
			ext === '.pptx' ||
			ext === '.odt'
		) {
			return true;
		}

		// Other files.
		if (ext === '.zip') {
			return true;
		}

		return false;
	}

	static isImage(file: Blob): boolean {
		if (file.type === 'image/jpg' || file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/gif') {
			return true;
		}

		return false;
	}

	static isPDF(file: File): boolean {
		const ext = this.getFileExtension(file);
		if (ext === '.pdf') {
			return true;
		}

		return false;
	}

	static downloadFile(window, blob: Blob, fileName: string, ObjectURL: string = null) {
		// IE doesn't allow using a blob object directly as link href
		// instead it is necessary to use msSaveOrOpenBlob
		if (window.navigator && window.navigator.msSaveOrOpenBlob) {
			window.navigator.msSaveOrOpenBlob(blob, fileName);
		} else {
			const a = window.document.createElement('a');
			if (ObjectURL) {
				a.href = ObjectURL;
			} else {
				a.href = URL.createObjectURL(blob);
			}
			a.download = fileName;
			a.target = '_blank';
			if (navigator.userAgent.indexOf('Safari') !== -1 && navigator.userAgent.indexOf('Chrome') === -1) {
				a.target = '';
			}

			document.body.appendChild(a);
			a.click();
			document.body.removeChild(a);
		}
	}
}
