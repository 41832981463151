import { User } from '#/models/user/user.model';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';

export class FolderACL {
	role: string;
	userid: string;
	email: string;
	locked: boolean;
	accepted: boolean;
	sharedbyid: string;
	sharedbyemail: string;

	static fromData(data): FolderACL[] {
		return data.map((item) => new FolderACL(item));
	}

	constructor(data = null) {
		if (data) {
			Object.assign(this, data);
		}
	}

	getEmail(): string {
		return this.email;
	}

	getAccepted(): boolean {
		return this.accepted;
	}

	getRole(): string {
		return this.role;
	}

	isLocked(): boolean {
		return this.locked;
	}

	setEmail(email: string) {
		this.email = email;
		return this;
	}

	setRole(role: string) {
		this.role = role;
		return this;
	}

	describeRole(): string {
		const role = this.getRole();
		if (role === 'Guest') {
			return _('View contents');
		} else if (role === 'Manager') {
			return _('View contents, add receipts, change folder name');
		} else if (role === 'Owner') {
			return _('View contents, add receipts, change folder name, delete folder');
		} else if (role === 'Submitter') {
			return _('View contents, add receipts');
		}
	}
}

export class FolderStats {
	TotalAmount: number;
	TotalReceipts: number;
	TotalVATAmount: number;

	constructor(data = null) {
		if (data) {
			Object.assign(this, data);
		}
	}

	getTotalAmount() {
		return this.TotalAmount;
	}

	getTotalReceipts() {
		return this.TotalReceipts;
	}

	getTotalVATAmount() {
		return this.TotalVATAmount;
	}
}

export class Folder {
	id: string;
	title: string;
	createdate: Date;
	updatedate: Date;
	acl: FolderACL[];
	stats: FolderStats;
	company?: string;

	constructor(data = null) {
		if (data) {
			Object.assign(this, data);
			this.createdate = Date.parse(data.createdate) > 0 ? new Date(data.createdate) : null;
			this.updatedate = Date.parse(data.updatedate) > 0 ? new Date(data.updatedate) : null;

			if (data.acl) {
				this.acl = FolderACL.fromData(data.acl);
			}

			if (data.stats) {
				this.stats = new FolderStats(data.stats);
			}
		}
	}

	getID(): string {
		return this.id;
	}

	getTitle(): string {
		return this.title;
	}

	getACL(): FolderACL[] {
		return this.acl;
	}

	getStats() {
		return this.stats;
	}

	isExpensable(user: User): boolean {
		// @todo: more conditions? Like finance type?
		if (user.canSubmitExpenses()) {
			return true;
		}

		return false;
	}

	userCanDelete(user: User): boolean {
		if (this.isOwnerOfGroup(user)) {
			return true;
		}

		return false;
	}

	isOwnerOfGroup(user: User): boolean {
		const ownerACL = this.getACL().find((i) => i.email === user.getEmail() && i.role === 'Owner');
		if (ownerACL != undefined) {
			return true;
		}

		return false;
	}

	setTitle(title: string) {
		this.title = title;
		return this;
	}

	clone(): Folder {
		return new Folder(JSON.parse(JSON.stringify(this)));
	}

	matches(string: string): boolean {
		/** Returns whether this group matches a given search string. */
		return this.getTitle().toLowerCase().includes(string.toLowerCase());
	}
}
