import { Component, EventEmitter, Input, OnInit, Output, QueryList, SimpleChanges, ViewChildren } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { isEqual, memoize } from 'lodash';
import { DynamicFormInputComponent } from '../../composed/dynamic-form-element/dynamic-form-input.component';
import { AccountingIntegrationV2Service, DynamicBookingField } from '#/services/integration/accounting-integration-v2.service';
import { isValueSet, stringIsSetAndFilled } from '#/util/values';
import { UI_TYPE } from '#/models/uiType';
import { Subscription } from 'rxjs';
import { AccountingFieldsService } from '#/services/company/accounting-fields.service';
import { DynamicFormElementParameters } from '#/models/dynamic-form-element';
import { BookingFieldLevel } from '#/models/accounting-integrations/BookingFieldLevel';
import { ScreenDisplayOption } from '#/models/screenDisplayOption';
import { arrayIsSetAndFilled } from '#/util/arrays';
import { AccountingBookingType } from '#/models/transaction/bookingType';
import { AppSelectOption } from '#/models/appSelectOption.model';
import { AccountingCustomStylingTemplate } from '#/models/accounting-integrations/accountingCustomStylingTemplate';

@Component({
	selector: 'app-integration-input-fields',
	templateUrl: './integration-input-fields.component.html',
	styleUrls: ['./integration-input-fields.component.scss'],
})
export class IntegrationInputFieldsComponent implements OnInit {
	private paramsByFormControl = new Map<FormControl, DynamicBookingField>();
	public form = this.fb.group({});
	public formWarnings = new Map<AbstractControl, string>();
	public ScreenDisplayOption = ScreenDisplayOption;
	public CustomStylingTemplate = AccountingCustomStylingTemplate;
	public stringIsSetAndFilled = stringIsSetAndFilled;

	@Input() integrationId: string;
	@Input() parameters: Array<DynamicBookingField>;
	@Input() onlyShowForBookingFieldLevel: BookingFieldLevel = null; // leaving it out will show all fields
	@Input() accountingBookingType: AccountingBookingType;
	@Input() screenDisplayOption: ScreenDisplayOption = ScreenDisplayOption.SPACIOUS;
	@Input() spaceDistribution: '40-60' | '34-66' | '30-70';
	@Input() orientation: 'horizontal' | 'vertical' = 'vertical';
	@Output() onCreateButtonClicked = new EventEmitter<{
		topic: string;
		ctrl: FormControl;
	}>();
	@ViewChildren(DynamicFormInputComponent) inputs: QueryList<DynamicFormInputComponent>;

	public initiated = false;
	private subscriptions: Array<Subscription> = [];
	public formIsClean = true;

	@Input() getReportContextFn: () => Promise<Record<string, any>> = () => Promise.resolve({});
	@Input() getReceiptContextFn: () => Promise<Record<string, any>> = () => Promise.resolve({});

	constructor(
		private fb: FormBuilder,
		private accountingIntegrationServiceV2: AccountingIntegrationV2Service,
		private accountingFieldsService: AccountingFieldsService,
	) {}

	ngOnInit(): void {}

	ngOnChanges(simpleChanges: SimpleChanges) {
		if (!this.initiated) {
			return;
		}
		if (simpleChanges.parameters && !isEqual(simpleChanges.parameters.previousValue, simpleChanges.parameters.currentValue)) {
			this.setUpFields();
		}
	}

	private setUpFields(valuesBefore?: Record<string, any>) {
		this.subscriptions.forEach((e) => e.unsubscribe());
		this.subscriptions = [];

		const fieldsBefore: Array<string> = Object.keys(this.form.controls);

		const fieldsAfter = this.parameters
			.filter((e) => {
				if (!isValueSet(this.onlyShowForBookingFieldLevel)) {
					return true;
				}
				return e.level === this.onlyShowForBookingFieldLevel;
			})
			.map((e) => e.key);
		const fieldsDeleted: Array<string> = fieldsBefore.filter((e) => !fieldsAfter.includes(e));
		let fieldsNewlyAdded: Array<string> = fieldsAfter.filter((e) => !fieldsBefore.includes(e));
		const fieldsThatStayed: Array<string> = fieldsAfter.filter((e) => fieldsBefore.includes(e));

		if (arrayIsSetAndFilled(fieldsThatStayed)) {
			// fields will sometimes have properties that change without the key changing
			const fieldsThatChanged: Array<string> = this.getFieldsThatChanged(fieldsThatStayed);

			if (arrayIsSetAndFilled(fieldsThatChanged)) {
				fieldsThatChanged.forEach((e) => this.form.removeControl(e));
				fieldsNewlyAdded = [...fieldsNewlyAdded, ...fieldsThatChanged];
			}
		}

		fieldsDeleted.forEach((e) => this.form.removeControl(e));

		const valuesBeforeToUse = valuesBefore ?? this.form.getRawValue();
		fieldsNewlyAdded.forEach((key) => {
			const fieldParam = this.parameters.find((e) => e.key === key);
			let value = valuesBeforeToUse[fieldParam.key];
			if (!isValueSet(value) && fieldParam.uiType === UI_TYPE.BOOLEAN) {
				value = false;
			}
			const ctrl = new FormControl(value);
			if (fieldParam.options?.required) {
				ctrl.setValidators([Validators.required]);
			}
			this.form.setControl(fieldParam.key, ctrl);
			this.paramsByFormControl.set(ctrl, fieldParam);
		});

		const fieldLevelParams = this.parameters.filter((e) => {
			if (!isValueSet(this.onlyShowForBookingFieldLevel)) {
				return true;
			}
			return e.level === this.onlyShowForBookingFieldLevel;
		});

		this.accountingFieldsService.setUpAsyncValidators(
			[this.form],
			fieldLevelParams,
			async (fieldParamKey: string, formContext: Record<string, any>) => {
				return this.accountingIntegrationServiceV2.validateField(
					this.integrationId,
					fieldParamKey,
					{ context: formContext, receipt: await this.getReceiptContextFn() },
					this.accountingBookingType,
				);
			},
			(warningMessage: string, formCtrl: AbstractControl) => {
				this.formWarnings.set(formCtrl, warningMessage);
			},
		);
		this.subscriptions.push(...this.accountingFieldsService.setUpFieldValidators([this.form], fieldLevelParams, this.parameters));
		this.subscriptions.push(
			...this.accountingFieldsService.setUpFieldEnablers([this.form], fieldLevelParams, this.parameters, (ctrl) =>
				this._ext_getInputComponentByFormControl(ctrl)._ext_refetchItems(),
			),
		);
		this.subscriptions.push(
			...this.accountingFieldsService.setUpFieldConditionallyEnablers(
				[this.form],
				fieldLevelParams,
				this.parameters,
				(fieldParamKey: string, context: Record<string, any>) => {
					return this.accountingIntegrationServiceV2.isFieldEnabled(this.integrationId, fieldParamKey, context, this.accountingBookingType);
				},
			),
		);
		this.subscriptions.push(
			...this.accountingFieldsService.setUpFieldSuggestions(
				[this.form],
				fieldLevelParams,
				this.parameters,
				this.integrationId,
				this.getReportContextFn,
				this.getReceiptContextFn,
				this.getBookingContext,
				this.accountingBookingType,
			),
		);
		this.subscriptions.push(
			...this.accountingFieldsService.setUpRefetchItemsForPickers(
				[this.form],
				fieldLevelParams,
				this.parameters,
				this._ext_getInputComponentByFormControl,
			),
		);

		const s = this.form.valueChanges.subscribe(() => {
			this.formIsClean = false;
			s.unsubscribe();
		});
	}

	public getControlsOfFormGroup(f: FormGroup): Array<FormControl> {
		// we want to keep the order of the fields according to the BE provided order

		const fieldKeys = this.parameters
			.filter((e) => {
				if (!isValueSet(this.onlyShowForBookingFieldLevel)) {
					return true;
				}
				return e.level === this.onlyShowForBookingFieldLevel;
			})
			.map((e) => e.key);

		const orderedControls: Array<AbstractControl> = fieldKeys.map((key) => f.controls[key]);
		return Object.values(orderedControls) as Array<FormControl>;
	}

	public getParamsOfFormControl(f: FormControl): DynamicBookingField {
		return this.paramsByFormControl.get(f);
	}

	/* tslint:disable:member-ordering */
	public getAsDynamicFormInputParameters = memoize((formControl: FormControl): DynamicFormElementParameters => {
		const inputParam = this.getParamsOfFormControl(formControl);
		if (inputParam.uiType === UI_TYPE.SELECT || inputParam.uiType === UI_TYPE.MULTI_SELECT) {
			return {
				uiType: inputParam.uiType,
				fetchItemsFn: async (start: number, searchQuery: string) => {
					if (!formControl.enabled) {
						return {
							items: [],
							hasMoreResults: false,
						};
					}
					return this.accountingIntegrationServiceV2.getPickerValues(
						this.integrationId,
						inputParam.key,
						start,
						searchQuery,
						await this.getBookingContext(),
						this.accountingBookingType,
						true,
					);
				},
				fetchSelectedItemsFn: async (ids: Array<string>): Promise<Array<AppSelectOption>> => {
					return this.accountingIntegrationServiceV2.getPickerValuesByIds(
						this.integrationId,
						inputParam.key,
						ids,
						await this.getBookingContext(),
						this.accountingBookingType,
					);
				},
			};
		}
		if (inputParam.uiType === UI_TYPE.MONEY) {
			return {
				uiType: inputParam.uiType,
				showCurrencyPicker: false,
			};
		}
		return {
			uiType: inputParam.uiType,
		};
	});

	public getTemplate(formControl: FormControl): AccountingCustomStylingTemplate {
		const inputParam: DynamicBookingField = this.getParamsOfFormControl(formControl);
		return inputParam.template;
	}

	public getBookingContext = async (): Promise<Record<string, any>> => {
		if (this.formIsClean) {
			return {};
		}
		return this.form.getRawValue();
	};

	/* tslint:disable:member-ordering */
	public isCreatable = memoize((formControl: FormControl): boolean => {
		return this.getParamsOfFormControl(formControl).options.creatable;
	});

	public _ext_getInputComponentByFormControl = (ctrl: FormControl): DynamicFormInputComponent => {
		return this.inputs.find((e) => e.formControl === ctrl);
	};

	ngOnDestroy(): void {
		this.subscriptions.forEach((e) => e.unsubscribe());
	}

	private getFieldsThatChanged(fieldsWhichRemainedTheSame: Array<string>): Array<string> {
		// fields will sometimes have properties that change without the key changing
		const oldControls: Array<DynamicBookingField> = (Object.values(this.form.controls) as Array<FormControl>).map((e) =>
			this.paramsByFormControl.get(e),
		);
		const fieldsThatChanged: Array<string> = fieldsWhichRemainedTheSame.filter(
			(e: string) =>
				!isEqual(
					oldControls.find((oldControl: DynamicBookingField) => oldControl.key === e),
					this.parameters.find((newControl: DynamicBookingField) => newControl.key === e),
				),
		);

		return fieldsThatChanged;
	}

	public onInjected(values: Record<string, any>) {
		if (Object.values(values ?? {}).some((e) => isValueSet(e))) {
			this.formIsClean = false;
		}
		if (!this.initiated) {
			this.setUpFields(values);
			this.initiated = true;
		}
	}
}
