import { Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { cloneDeep } from 'lodash';
import { User } from '#/models/user/user.model';
import { Store } from '@ngrx/store';
import { AppState } from '~/app/reducers';
import { LocalStorageService } from 'angular-2-local-storage';
import { ColumnsInterface } from '~/app/shared/ui/table/table';

@Component({
	selector: 'app-table-column-config',
	templateUrl: './table-column-config.component.html',
	styleUrls: ['./table-column-config.component.scss'],
})
export class TableColumnConfigComponent implements OnInit {
	@Input() public sortableColumns: Array<ColumnsInterface>;
	@Output() public onDismiss: EventEmitter<string> = new EventEmitter<string>();
	@Output() public onSave: EventEmitter<Array<ColumnsInterface>> = new EventEmitter<Array<ColumnsInterface>>();

	protected destroyCallbacks = [];
	public user: User;

	@HostListener('document:keydown.escape', ['$event']) onKeydownHandler(event: KeyboardEvent) {
		this.closeConfig();
	}

	constructor(private store: Store<AppState>, private localStorageService: LocalStorageService) {}

	closeConfig(): void {
		this.onDismiss.emit();
	}

	saveColumnOrder() {
		this.onSave.emit(this.sortableColumns);
	}

	ngOnInit(): void {
		const subscription = this.store.select('user').subscribe((val) => {
			this.user = val.currentUser;
		});

		this.destroyCallbacks.push(() => {
			subscription.unsubscribe();
		});
		this.sortableColumns = cloneDeep(this.sortableColumns);
	}

	ngOnDestroy(): void {
		this.destroyCallbacks.forEach((cb) => cb());
	}
}
