import { Component, OnInit } from '@angular/core';
import { AuthenticatedComponent } from '~/app/pages/authenticated/authenticated.component';
import { Company, AccountingIntegrationV1 } from '#/models/company/company.model';
import { BaseComponent } from '~/app/base/base.component';
import { User } from '#/models/user/user.model';
import { TranslationReplaceService } from '~/app/services/translation-replace.service';
import { isValueSet } from '#/util/values';
import { CompanyPaymentMethod } from '#/models/company/payment-method.model';
import { Order } from '#/models/utils/order';
import { CompanyApiService } from '#/services/company/company-api.service';
import { CompanyMiscService } from '#/services/company/company-misc.service';
import { CompanyService } from '#/services/company/company.service';
import { SubCompanyServiceDeprecated } from '~/app/modules/company/services/sub-company-deprecated.service';

@Component({
	selector: 'company-component',
	template: '',
})
export abstract class CompanyComponent extends AuthenticatedComponent implements OnInit {
	protected companyService: CompanyService;
	protected companyAPIService: CompanyApiService;
	protected companyMiscService: CompanyMiscService;
	protected invoiceInboundMailKey: string;
	protected companyPaymentMethods: CompanyPaymentMethod[];
	protected subCompanyServiceDeprecated: SubCompanyServiceDeprecated;
	public translationReplaceService: TranslationReplaceService;
	public company: Company;
	public parentCompany: Company;
	public users: User[];
	public integrations: Array<AccountingIntegrationV1>;

	public sort: string = 'name';
	public sortOrder: Order | boolean = Order.ASCENDING;

	constructor() {
		super();
		this.store.select('company').subscribe((companyState) => {
			this.integrations = companyState.integrations;
		});
		this.companyService = BaseComponent.injector.get(CompanyService);
		this.companyAPIService = BaseComponent.injector.get(CompanyApiService);
		this.companyMiscService = BaseComponent.injector.get(CompanyMiscService);
		this.translationReplaceService = BaseComponent.injector.get(TranslationReplaceService);
		this.subCompanyServiceDeprecated = BaseComponent.injector.get(SubCompanyServiceDeprecated);
	}

	ngOnInit() {
		super.ngOnInit();

		const companySubscription = this.store.select('company').subscribe((val) => {
			Object.assign(this, val);
		});

		this.destroyCallbacks.push(() => {
			companySubscription.unsubscribe();
		});
	}

	toggleSort(column) {
		if (this.sort === column) {
			this.sortOrder = this.sortOrder === Order.DESCENDING ? Order.ASCENDING : Order.DESCENDING;
		} else {
			this.sort = column;
			this.sortOrder = Order.DESCENDING;
		}
	}

	getUsers(userIds: string | string[]) {
		if (!Array.isArray(userIds)) {
			userIds = [userIds];
		}
		const users = userIds.map((userId) => this.users?.find((u) => u.getID() === userId));
		return users.filter(isValueSet);
	}

	public getUser(userId: string): User {
		return this.users.find((u) => u.getID() === userId);
	}

	transformDate($event) {
		if ($event === false) {
			return null;
		}
		return $event;
	}
}
