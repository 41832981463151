import { CompanyActionsUnion, CompanyActionTypes } from './company.actions';
import { AuthorizationFlow, Company, AccountingIntegrationV1 } from '#/models/company/company.model';
import { User } from '#/models/user/user.model';
import { remove, upsert } from '#/models/utils/generic';
import { CompanyPaymentMethod, PaymentMethodTransaction } from '#/models/company/payment-method.model';
import { PaymentMethod } from '#/services/company/payment-method.service';

export interface CompanyState {
	company: Company;
	parentCompany: Company;
	invoiceInboundMailKey: string;
	users: User[];
	integrations: Array<AccountingIntegrationV1>;
	companyPaymentMethods: CompanyPaymentMethod[];
	companyAuthorizationFlows: AuthorizationFlow[];
	transactions: PaymentMethodTransaction[];
	paymentMethods: PaymentMethod[] | null;
}

export const initialCompanyState: CompanyState = {
	company: null,
	parentCompany: null,
	invoiceInboundMailKey: null,
	users: null,
	integrations: null,
	companyPaymentMethods: null,
	companyAuthorizationFlows: null,
	transactions: null,
	paymentMethods: null,
};

export function companyReducer(state = initialCompanyState, action: CompanyActionsUnion): CompanyState {
	let index;

	switch (action.type) {
		case CompanyActionTypes.SET_COMPANY:
			/* Reset store to initial company store. */
			return {
				...initialCompanyState,
				integrations: state.integrations,
				company: action.payload.company,
				parentCompany: action.payload.company,
			};
		case CompanyActionTypes.SET_SUB_COMPANY:
			/* Same as SET_COMPANY, but does not override parentCompany */
			const company = action.payload.company || state.parentCompany;
			return { ...initialCompanyState, company, parentCompany: state.parentCompany };
		case CompanyActionTypes.UPDATE_COMPANY:
			if (state.parentCompany.id === action.payload.company.id) {
				return { ...state, company: action.payload.company, parentCompany: action.payload.company };
			}
			return { ...state, company: action.payload.company };
		case CompanyActionTypes.SET_INVOICE_INBOUND_MAIL_KEY:
			return { ...state, invoiceInboundMailKey: action.payload.key };
		case CompanyActionTypes.SET_COMPANY_USERS:
			return { ...state, users: action.payload.users };
		case CompanyActionTypes.SET_INTEGRATIONS:
			return { ...state, integrations: action.payload.integrations };
		case CompanyActionTypes.RESET_COMPANY:
			return initialCompanyState;
		case CompanyActionTypes.UPDATE_COMPANY_USER:
			index = state.users.findIndex((u) => u.getID() === action.payload.getID());
			if (index > -1) {
				state.users[index] = action.payload;
			}
			return state;
		case CompanyActionTypes.DELETE_COMPANY_USER:
			index = state.users.findIndex((u) => u.getID() === action.payload.getID());
			if (index > -1) {
				state.users.splice(index, 1);
			}
			return state;
		case CompanyActionTypes.ADD_COMPANY_USER:
			state.users.push(action.payload);
			return state;
		case CompanyActionTypes.SET_COMPANY_PAYMENT_METHODS:
			return { ...state, companyPaymentMethods: action.payload.companyPaymentMethods };
		case CompanyActionTypes.UPSERT_COMPANY_PAYMENT_METHOD:
			return { ...state, companyPaymentMethods: upsert(state.companyPaymentMethods, action.payload.companyPaymentMethod, 'id') };
		case CompanyActionTypes.REMOVE_COMPANY_PAYMENT_METHOD:
			return { ...state, companyPaymentMethods: remove(state.companyPaymentMethods, action.payload.companyPaymentMethodID, 'id') };
		case CompanyActionTypes.SET_COMPANY_AUTHORIZATION_FLOWS:
			return { ...state, companyAuthorizationFlows: action.payload.companyAuthorizationFlows };
		case CompanyActionTypes.UPSERT_COMPANY_AUTHORIZATION_FLOW:
			return {
				...state,
				companyAuthorizationFlows: upsert(state.companyAuthorizationFlows, action.payload.companyAuthorizationFlow, 'id'),
			};
		case CompanyActionTypes.REMOVE_COMPANY_AUTHORIZATION_FLOW:
			return {
				...state,
				companyAuthorizationFlows: remove(state.companyAuthorizationFlows, action.payload.companyAuthorizationFlowID, 'id'),
			};
		case CompanyActionTypes.SET_TRANSACTIONS:
			return { ...state, transactions: action.payload.transactions };
		case CompanyActionTypes.UPSERT_TRANSACTION:
			return { ...state, transactions: upsert(state.transactions, action.payload.transaction, 'id') };
		case CompanyActionTypes.REMOVE_TRANSACTION:
			return { ...state, transactions: remove(state.transactions, action.payload.transactionID, 'id') };
		default:
			return state;
	}
}
