<div class="componentContainer">
	<div class="uploadContainer" *ngIf="showUploadButton">
		<klp-form-file-input
			[multiple]="true"
			[(ngModel)]="selectedFile"
			(ngModelChange)="onUploadFileSelected.emit($event)"
			[onlyShowUploadButton]="true"
			[isLoading]="isUploading"
			[buttonText]="'Upload a file' | translate"
		></klp-form-file-input>
	</div>
	<ng-container *ngIf="attachmentsAmount > 0">
		<div class="fileNavContainer">
			<app-button variant="icon" (click)="goToPrevAttachment()" [disabled]="!hasPrev()">
				<app-icon variant="arrowLeft" size="medium"></app-icon>
			</app-button>
			<div class="fileIndexContainer">
				<div class="fileName">{{ currentActiveFileName }}</div>
				<div class="indexAndAmount">
					{{ currentActiveFileIndex + 1 + (hasPrefixedEntry ? 1 : 0) }} / {{ attachmentsAmount + (hasPrefixedEntry ? 1 : 0) }}
					<app-info-tooltip
						*ngIf="attachmentsAmount > 1"
						[showIcon]="false"
						[title]="'There are multiple documents attached. Make sure you checked all before submitting.' | translate"
					>
						<app-icon color="warning" size="medium" variant="alert"></app-icon>
					</app-info-tooltip>
				</div>
			</div>
			<app-button variant="icon" (click)="goToNextAttachment()" [disabled]="!hasNext()">
				<app-icon variant="arrowRight" size="medium"></app-icon>
			</app-button>
		</div>
		<div class="fileDeletionContainer">
			<app-button variant="icon" [disabled]="!currentActiveFileBlob" (click)="downloadFile()"
				><app-icon variant="download" size="small"></app-icon
			></app-button>
			<app-button variant="redFilled" *ngIf="showDeleteButton" (click)="onDeleteFile.emit(currentActiveFileIndex)"
				><app-icon variant="trash" size="medium"></app-icon
			></app-button>
		</div>
	</ng-container>
</div>
