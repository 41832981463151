import { Injectable } from '@angular/core';
import { APIService } from '~/app/api/services/api.service';
import { APINotificationsService } from '~/app/api/services/apinotifications.service';
import { AuthorizationFlow } from '#/models/company/company.model';
import { Order } from '#/models/utils/order';
import { isNullOrUndefined, isValueSet, stringIsSetAndFilled } from '#/util/values';
import { CompanyState } from '#/models/company/company.reducer';
import { SetCompanyAuthorizationFlows } from '#/models/company/company.actions';
import { UserService } from '~/app/modules/user/user.service';
import { StoreService } from '~/app/services/store.service';

@Injectable({
	providedIn: 'root',
})
export class CompanyAuthorizationService {
	constructor(
		private userService: UserService,
		private apiService: APIService,
		private notifications: APINotificationsService,
		private store: StoreService,
	) {}

	getAuthorizationFlows(
		companyId: string,
		per_page: number = 100,
		page: number = 1,
		sort: string,
		sortOrder: boolean | Order,
		useCache: boolean = true,
	): Promise<any> {
		let url = '/api/v1/authorizationFlow?';
		if (companyId !== '') {
			url = '/api/v1/company/' + companyId + '/authorizationFlow?';
		}

		url += 'max=' + per_page + '&';
		url += 'start=' + per_page * (page - 1) + '&';
		url += 'sort=' + sort + '&';
		url += 'sortorder=' + sortOrder;

		const cacheTime = useCache ? 60 * 1000 : 0;
		return this.apiService
			.get(url, cacheTime)
			.then((r) => {
				r.data.authorization_flows = r.data.authorization_flows.map((e) => {
					// if the require_approver_count is null/undefined, what it actually means is
					// that the required approvers should be everyone (so the length of all approvers)
					if (isNullOrUndefined(e.require_approver_count)) {
						e.require_approver_count = e.approvers.length;
					}
					return e;
				});
				return r;
			})
			.catch((e) => {
				this.notifications.handleAPIError(e);
				return Promise.reject(e);
			});
	}

	private deleteAuthFlowsFromCache() {
		this.apiService.deleteFromCacheWhereUrlStartsWith('authorizationFlow/');
	}

	createAuthorizationFlow(companyId: string, authorizationFlow: AuthorizationFlow): Promise<any> {
		this.deleteAuthFlowsFromCache();
		let url = '/api/v1/authorizationFlow';
		if (companyId !== '') {
			url = '/api/v1/company/' + companyId + '/authorizationFlow';
		}

		return this.apiService
			.post(url, authorizationFlow)
			.then((r) => {
				return Promise.resolve(r);
			})
			.catch((e) => {
				this.notifications.handleAPIError(e);
				return Promise.reject(e);
			});
	}

	updateAuthorizationFlow(companyId: string, authorizationFlow: AuthorizationFlow): Promise<any> {
		this.deleteAuthFlowsFromCache();
		let url = '/api/v1/authorizationFlow/' + authorizationFlow.id;
		if (companyId !== '') {
			url = '/api/v1/company/' + companyId + '/authorizationFlow/' + authorizationFlow.id;
		}

		return this.apiService
			.patch(url, authorizationFlow)
			.then((r) => {
				return Promise.resolve(r);
			})
			.catch((e) => {
				this.notifications.handleAPIError(e);
				return Promise.reject(e);
			});
	}

	deleteAuthorizationFlow(companyId: string, authorizationFlow: AuthorizationFlow): Promise<any> {
		this.deleteAuthFlowsFromCache();
		let url = '/api/v1/authorizationFlow/' + authorizationFlow.id;
		if (companyId !== '') {
			url = '/api/v1/company/' + companyId + '/authorizationFlow/' + authorizationFlow.id;
		}

		return this.apiService
			.delete(url)
			.then((r) => {
				return Promise.resolve(r);
			})
			.catch((e) => {
				this.notifications.handleAPIError(e);
				return Promise.reject(e);
			});
	}

	public initializeAuthorizationFlows() {
		/* We need the current company before we load the authorization flows. */
		let previousCompanyState: CompanyState = null;

		return new Promise<any>((res, rej) => {
			return this.store.select('company').subscribe((companyState: CompanyState) => {
				const prevCompanyState = previousCompanyState;
				previousCompanyState = companyState;
				/* Resolve with the value of the current authorization flows. */
				if (prevCompanyState?.company?.id === companyState?.company?.id && isValueSet(companyState.companyAuthorizationFlows)) {
					return res(companyState.companyAuthorizationFlows);
				} else {
					if (
						stringIsSetAndFilled(companyState?.company?.id) &&
						this.userService.getCurrentLoggedUser().canListCompanyAuthorizationFlows()
					) {
						return this.getAuthorizationFlows(companyState?.company.id, 1000, 1, 'description', Order.ASCENDING)
							.then((r) => {
								const AuthorizationFlows: any[] = r['data']['authorization_flows'];
								const newAuthorizationFlows: AuthorizationFlow[] = [];
								if (AuthorizationFlows != null) {
									AuthorizationFlows.map((authorizationFlow) => newAuthorizationFlows.push(new AuthorizationFlow(authorizationFlow)));
								}
								this.store.dispatch(new SetCompanyAuthorizationFlows({ companyAuthorizationFlows: newAuthorizationFlows }));
								res(newAuthorizationFlows);
							})
							.catch((e) => {
								rej(e);
							});
					} else {
						/* No company, resolve anyway. */
						res(null);
					}
				}
			});
		});
	}
}
