import { Component, Host, OnInit, Optional } from '@angular/core';
import { Period } from '#/models/period.model';
import { TranslateService } from '@ngx-translate/core';
import { ControlContainer, NG_VALUE_ACCESSOR } from '@angular/forms';
import { FormElementComponent, ValueAccessorBase } from '@klippa/ngx-enhancy-forms';

export enum PeriodString {
	TODAY = 'today',
	YESTERDAY = 'yesterday',
	THIS_WEEK = 'this_week',
	PREVIOUS_WEEK = 'previous_week',
	THIS_MONTH = 'this_month',
	PREVIOUS_MONTH = 'previous_month',
	THIS_QUARTER = 'this_quarter',
	PREVIOUS_QUARTER = 'previous_quarter',
	THIS_YEAR = 'this_year',
	PREVIOUS_YEAR = 'previous_year',
}

@Component({
	selector: 'app-deprecated-period-picker',
	templateUrl: './period-picker.component.html',
	providers: [{ provide: NG_VALUE_ACCESSOR, useExisting: DeprecatedPeriodPickerComponent, multi: true }],
})
export class DeprecatedPeriodPickerComponent extends ValueAccessorBase<Period> implements OnInit {
	public periods: Array<Period>;

	constructor(
		private translate: TranslateService,
		@Optional() @Host() protected parent: FormElementComponent,
		@Optional() @Host() protected controlContainer: ControlContainer,
	) {
		super(parent, controlContainer);
		this.periods = Period.ALL.map((period: Period) => period);
		this.periods.forEach((period: Period) => {
			period.label = translate.instant(period.label);
		});
	}

	ngOnInit(): void {
		super.ngOnInit();
	}
}
