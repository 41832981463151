<app-modal #modal variant="modalAlmostFullScreen">
	<ng-template app-modal-body>
		<klp-grid-container [gutter]="30" [fluid]="true">
			<klp-grid-row>
				<klp-grid-column [width]="{ xs: 6 }">
					<div class="fileContainer">
						<ng-container *ngIf="attachments.length === 0">
							<div class="noAttachmentAvailableContainer" translate>No attachment available.</div>
						</ng-container>
						<ng-container *ngIf="attachments.length > 0">
							<div class="filesActionBar">
								<app-attachment-actions-bar
									#attachmentsActionsController
									[showUploadButton]="false"
									[showDeleteButton]="false"
									[isUploading]="false"
								></app-attachment-actions-bar>
							</div>
							<div class="attachmentViewerContainer">
								<app-attachment-viewer
									[actionsController]="attachmentsActionsController"
									[receiptId]="transactionId"
									[attachments]="attachments"
								></app-attachment-viewer>
							</div>
						</ng-container>
					</div>
				</klp-grid-column>
				<klp-grid-column [width]="{ xs: 6 }">
					<div class="editorContainer">
						<klp-form [formGroup]="creationForm">
							<klp-form-element direction="vertical" *ngFor="let formControl of getCreationFieldControls()">
								<klp-form-caption>
									<span class="caption">{{ getParamsOfFormControl(formControl).label }}</span>
									<app-info-tooltip
										*ngIf="getParamsOfFormControl(formControl).description"
										[title]="getParamsOfFormControl(formControl).description"
									></app-info-tooltip>
								</klp-form-caption>
								<app-dynamic-form-input
									[parameters]="getAsDynamicFormInputParametersForCreation(formControl)"
									[formControl]="formControl"
								></app-dynamic-form-input>
								<klp-form-error error="async"></klp-form-error>
							</klp-form-element>
							<div class="creationButtons">
								<app-button variant="white" (click)="modal.closeModal()">{{ 'Cancel' | translate }}</app-button>
								<klp-form-submit-button [submitCallback]="createEntity">{{ 'Save' | translate }}</klp-form-submit-button>
							</div>
						</klp-form>
					</div>
				</klp-grid-column>
			</klp-grid-row>
		</klp-grid-container>
	</ng-template>
</app-modal>
