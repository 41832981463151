<ng-container>
	<div class="captionAndToggle" *ngIf="innerValue">
		<i class="dragIcon ti-menu"></i>
		<div class="caption">{{ innerValue.label }}</div>
		<div class="toggle">
			<klp-form-toggle [(ngModel)]="innerValue.enabled" [disabled]="disabled"></klp-form-toggle>
		</div>
		<button class="settingsButton" (click)="toggleCustomExtraSettings()">
			<i class="ti-settings"></i>
		</button>
	</div>
	<div *ngIf="showCustomExtraSettings" class="extraSettingsContainer">
		<div translate class="fieldLabel">Field label</div>
		<klp-form-text-input [(ngModel)]="innerValue.custom_label" [placeholder]="innerValue.label" [disabled]="disabled"></klp-form-text-input>

		<div *ngIf="innerValue.showCurrencyToggle" class="currencySymbolContainer">
			<div translate class="currencySymbol">Display currency symbol</div>
			<klp-form-toggle [(ngModel)]="innerValue.add_currency_symbol" [disabled]="disabled"></klp-form-toggle>
		</div>

		<div *ngIf="innerValue.showDateFormatPicker && !hideDateFormatPicker" class="dateFormatContainer">
			<div translate class="dateFormat">Date format</div>
			<app-date-format-picker [(ngModel)]="innerValue.date_format" [disabled]="disabled"></app-date-format-picker>
		</div>
	</div>
</ng-container>
