import { Component, DoCheck, HostBinding, Input, KeyValueDiffer, KeyValueDiffers, OnInit } from '@angular/core';
import { ReceiptListAPIRequest } from '#/models/transaction/receipt';
import { isValueSet } from '#/util/values';
import { PaginatedExpenseReportsRequest } from '#/models/transaction/expense-reports';

@Component({
	selector: 'app-filters-count',
	templateUrl: './filters-count.component.html',
	styleUrls: ['./filters-count.component.scss'],
})
export class FiltersCountComponent implements OnInit, DoCheck {
	private exceptions = [
		'report',
		'company',
		'companyID',
		'max',
		'start',
		'sharedonly',
		'unaccepted',
		'activeFilters',
		'expenses',
		'declarations',
		'desc',
		'start_date',
		'purchasedate',
		'sort',
		'sortorder',
		'creatorType',
		'type',
	];
	// Initialization is required for differ constructor.
	@Input() public requestQuery: ReceiptListAPIRequest | PaginatedExpenseReportsRequest = new ReceiptListAPIRequest();
	@HostBinding('class.hasFilters') hasFilters: boolean = false;

	private differ: KeyValueDiffer<any, any>;
	filtersCount = 0;

	constructor(private differs: KeyValueDiffers) {
		this.differ = this.differs.find(this.requestQuery).create();
	}

	ngDoCheck() {
		const changes = this.differ.diff(this.requestQuery);

		if (changes) {
			this.countFilters();
		}
	}

	ngOnInit() {
		this.countFilters();
	}

	countFilters(): void {
		this.filtersCount = 0;
		Object.keys(this.requestQuery).forEach((key) => {
			if (this.exceptions.includes(key)) {
				return;
			}
			if (key === 'statusses' && isValueSet(this.requestQuery[key])) {
				// Status to do is not a filter in FE but a tab.
				if (this.requestQuery[key][0] === 'Todo') {
					return;
				}
			}

			if (isValueSet(this.requestQuery[key]) && this.requestQuery[key].length !== 0) {
				if (this.exceptions.includes(this.requestQuery[key])) {
					return;
				}

				this.filtersCount++;
			}
		});

		this.hasFilters = this.filtersCount > 0;
	}
}
