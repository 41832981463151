import {
	apiToFrontend as transactionApiToFrontend,
	frontendToApi as transactionFrontendToApi,
} from '#/models/transaction/transaction/transformer';
import { ReceiptFrontendModel } from '#/models/transaction/receipt/frontendModel';
import { ReceiptApiModel } from '#/models/transaction/receipt/apiModel';

export function apiToFrontend(apiModel: ReceiptApiModel, loggedUserId: string): ReceiptFrontendModel {
	const result: ReceiptFrontendModel = transactionApiToFrontend(apiModel, loggedUserId, ReceiptFrontendModel);
	return result;
}

export function frontendToApi(frontendModel: ReceiptFrontendModel): ReceiptApiModel {
	const result: ReceiptApiModel = transactionFrontendToApi(frontendModel);

	result.isinvoice = false;

	return result;
}
