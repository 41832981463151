<ng-select
	id="groups"
	name="groups"
	placeholder="{{ 'Select %items%' | translate: { items: translationReplaceService.groups | lowercase } }}"
	[items]="selectOptions"
	[(ngModel)]="groups"
	[multiple]="multiple"
	[disabled]="disabled"
	bindLabel="Label"
	bindValue="id"
	[selectOnTab]="true"
	[clearable]="clearable"
	[loading]="loading"
	[trackByFn]="trackSelectOption"
	*ngIf="!useTypeAhead"
	[ngClass]="{ showErrors: isInErrorState() }"
>
	<ng-template let-index="index" let-item="item" let-search="searchTerm" ng-option-tmp>
		<div attr.data-cy="{{ item.getTitle() }}">
			{{ item.getTitle() }}
			<div *ngIf="item.description" class="dropdown-item-description">
				{{ item.description }}
			</div>
		</div>
	</ng-template>
</ng-select>

<ng-select
	id="groups"
	name="groups"
	placeholder="{{ 'Start typing to search for a %item%' | translate: { item: translationReplaceService.group | lowercase } }}"
	[items]="selectOptions"
	[typeahead]="selectOptionsTypeahead"
	[(ngModel)]="groups"
	[multiple]="true"
	[disabled]="disabled"
	bindLabel="Label"
	bindValue="id"
	[selectOnTab]="true"
	[clearable]="true"
	[loading]="loading"
	[trackByFn]="trackSelectOption"
	*ngIf="useTypeAhead"
	[ngClass]="{ showErrors: isInErrorState() }"
>
	<ng-template let-index="index" let-item="item" let-search="searchTerm" ng-option-tmp>
		<div>
			{{ item.getTitle() }}
			<div *ngIf="item.description" class="dropdown-item-description">
				{{ item.description }}
			</div>
		</div>
	</ng-template>
</ng-select>
