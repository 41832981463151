import { Injectable } from '@angular/core';
import { Company } from '#/models/company/company.model';
import { Store } from '@ngrx/store';
import { AppState } from '../reducers';

@Injectable({
	providedIn: 'root',
})
export class CompanyUnitCacheService {
	public company: Company;
	private userHasCacheTime = 600; // 600 seconds.
	public userHasAdministrations?: boolean;
	public userHasCategories?: boolean;
	public userHasCostCenters?: boolean;
	public userHasCostUnits?: boolean;
	public userHasProjects?: boolean;

	constructor(protected store: Store<AppState>) {
		this.store.select('company').subscribe((val) => {
			this.company = val.company;
			this.clearCache();
		});

		this.store.select('user').subscribe((val) => {
			this.clearCache();
		});
	}

	setUserHasAdministrations(userHasAdministrations: boolean) {
		this.userHasAdministrations = userHasAdministrations;
		setTimeout(() => {
			this.userHasAdministrations = undefined;
		}, this.userHasCacheTime * 1000);
	}

	setUserHasCategories(userHasCategories: boolean) {
		this.userHasCategories = userHasCategories;
		setTimeout(() => {
			this.userHasCategories = undefined;
		}, this.userHasCacheTime * 1000);
	}

	setUserHasCostCenters(userHasCostCenters: boolean) {
		this.userHasCostCenters = userHasCostCenters;
		setTimeout(() => {
			this.userHasCostCenters = undefined;
		}, this.userHasCacheTime * 1000);
	}

	setUserHasCostUnits(userHasCostUnits: boolean) {
		this.userHasCostUnits = userHasCostUnits;
		setTimeout(() => {
			this.userHasCostUnits = undefined;
		}, this.userHasCacheTime * 1000);
	}

	setUserHasProjects(userHasProjects: boolean) {
		this.userHasProjects = userHasProjects;
		setTimeout(() => {
			this.userHasProjects = undefined;
		}, this.userHasCacheTime * 1000);
	}

	clearCache() {
		this.userHasAdministrations = undefined;
		this.userHasCategories = undefined;
		this.userHasCostCenters = undefined;
		this.userHasCostUnits = undefined;
		this.userHasProjects = undefined;
	}
}
