import { Component, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { InterfaceFrontendModel as TransactionInterfaceModel } from '#/models/transaction/interface/frontendModel';
import { TransactionInterfaceService } from '#/services/transaction/transaction-interface.service';
import { TransactionType } from '#/models/transaction/transactionType';
import { companyRegistrationPrefix } from '#/models/transaction/registration/transformer';
import { RegistrationFrontendModel } from '#/models/transaction/registration/frontendModel';
import { RegistrationUnderlyingTypeModel } from '#/models/transaction/registrationUnderlyingType.model';
import { ExportFormModal } from '~/app/modules/export/export-form-modal/export-form-modal.component';
import { ExportType } from '#/models/company/exporterInterfaces';

@Component({
	selector: 'app-manage-registrations-list',
	templateUrl: './manage-registrations-list.component.html',
	styleUrls: ['./manage-registrations-list.component.scss'],
})
export class ManageRegistrationsListComponent {
	public transactionInterfaces: Array<TransactionInterfaceModel> = undefined;
	@ViewChild('exportFormModal') exportFormModal: ExportFormModal;
	public exportIds: Array<string>;
	public exportType: ExportType;
	public readonly ExportType = ExportType;

	constructor(
		private transactionInterfaceService: TransactionInterfaceService,
		private router: Router,
		private activatedRoute: ActivatedRoute,
	) {}

	public ngOnInit(): void {
		this.transactionInterfaceService.getInterfaces(true, false, true, null, true).then((res) => {
			this.transactionInterfaces = res.filter((e) => e.transactionType === TransactionType.Registration);
		});
	}

	public async goToTransaction(registration: RegistrationFrontendModel): Promise<boolean> {
		if (registration.underlyingType === RegistrationUnderlyingTypeModel.EXPENSE) {
			return await this.router.navigate([`./tx/${registration.id}`], {
				relativeTo: this.activatedRoute,
				queryParams: {
					'view-mode': 'submit',
				},
			});
		}
		return await this.router.navigate([`./tx/${companyRegistrationPrefix}${registration.id}`], {
			relativeTo: this.activatedRoute,
			queryParams: {
				'view-mode': 'submit',
			},
		});
	}

	public async goToNewRegistration(txiId: string): Promise<void> {
		await this.router.navigate([`./tx/new`], {
			relativeTo: this.activatedRoute,
			queryParams: { 'tx-interface-id': txiId, 'view-mode': 'submit' },
		});
	}

	public exportRegistrations(registrationRequest: { ids: Array<string>; exportType: ExportType }): void {
		this.exportIds = registrationRequest.ids;
		this.exportType = registrationRequest.exportType;
		this.exportFormModal.openExportModal();
	}
}
