import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { UiModule } from '~/app/shared/ui/ui.module';
import { NgSelectModule } from '@ng-select/ng-select';
import { TranslateModule } from '@ngx-translate/core';
import { SortablejsModule } from 'ngx-sortablejs';
import { ModalModule } from '~/app/shared/ui/modal/modal.module';
import { ExportFormModal } from '~/app/modules/export/export-form-modal/export-form-modal.component';
import { ExportColumnComponent } from '~/app/modules/export/components/export-column/export-column.component';
import { ExportFormConfigurationComponent } from '~/app/modules/export/components/export-form-configuration/export-form-configuration.component';
import { ExportFormComponent } from '~/app/modules/export/components/export-form/export-form.component';
import { RouterModule } from '@angular/router';

@NgModule({
	imports: [
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		UiModule,
		NgSelectModule,
		TranslateModule,
		SortablejsModule,
		ModalModule,
		RouterModule,
	],
	declarations: [ExportFormModal, ExportColumnComponent, ExportFormConfigurationComponent, ExportFormComponent],
	providers: [],
	exports: [ExportFormModal, ExportFormConfigurationComponent, ExportFormComponent],
})
export class ExportModule {}
