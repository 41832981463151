<div class="componentContainer">
	<div class="searchSymbol" [ngClass]="{ showErrors: isInErrorState(), disabled: disabled }">
		<app-icon variant="search" size="large"></app-icon>
	</div>
	<klp-form-text-input
		[(ngModel)]="innerValue"
		(ngModelChange)="setInnerValueAndNotify($event)"
		[disabled]="disabled"
		[inErrorState]="isInErrorState()"
		[hasBorderLeft]="false"
		[placeholder]="'Search...' | translate"
	></klp-form-text-input>
</div>
