import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CompanyComponent } from '~/app/modules/company/components/company.component';
import { User, UserRole } from '#/models/user/user.model';

@Component({
	selector: 'app-deprecated-company-user-picker',
	templateUrl: './company-user-picker.component.html',
})
export class CompanyUserPickerComponent extends CompanyComponent implements OnInit {
	public usersValue: string | string[];
	@Output()
	public companyUsersChange = new EventEmitter<string | string[]>();
	@Input()
	public multiple = true;

	@Input()
	public filterApproverRoles = false;

	@Input()
	public filterExpenseApproverRoles = false;

	@Input()
	public filterInvoiceApproverRoles = false;

	@Input() invalidApprovers?: boolean;

	@Input() user: User;

	@Input()
	public filterSelf = false;

	@Input()
	bindToPropery: 'id' | 'email' = 'id';

	ngOnInit() {
		super.ngOnInit();
		if (this.filterApproverRoles) {
			this.users = this.users.filter((user) => {
				return (
					user.hasRole(UserRole.CompanyAdmin) ||
					user.hasRole(UserRole.CompanyFinance) ||
					user.hasRole(UserRole.CompanyInvoiceApprover) ||
					user.hasRole(UserRole.CompanyManager)
				);
			});
		}

		if (this.filterExpenseApproverRoles) {
			this.users = this.users.filter((user) => {
				return user.hasRole(UserRole.CompanyAdmin) || user.hasRole(UserRole.CompanyFinance) || user.hasRole(UserRole.CompanyManager);
			});
		}

		if (this.filterInvoiceApproverRoles) {
			this.users = this.users.filter((user) => {
				return (
					user.hasRole(UserRole.CompanyAdmin) || user.hasRole(UserRole.CompanyFinance) || user.hasRole(UserRole.CompanyInvoiceApprover)
				);
			});
		}

		if (this.filterSelf && this.user) {
			this.users = this.users.filter((user) => {
				return user.id !== this.user.id;
			});
		}
	}

	@Input()
	get companyUsers() {
		return this.usersValue;
	}

	set companyUsers(val) {
		this.usersValue = val;
		this.companyUsersChange.emit(this.usersValue);
	}
}
