import { User } from '#/models/user/user.model';
import { Company } from '#/models/company/company.model';

export interface ImportResultFunctions {
	getSuccessResults(): ImportSuccessResult[] | number;
	getFailResults(): ImportFailResult[];
	hasFailResults(): boolean;
}

export class ImportSuccessResult {
	User: User;

	static fromData(data): ImportSuccessResult[] {
		if (data == null) {
			return [];
		}
		return data.map((item) => new ImportSuccessResult(item));
	}

	constructor(data = null) {
		if (data) {
			Object.assign(this, data);

			if (data.User) {
				this.User = new User(data.User);
			}
		}
	}
}

export class ImportFailResult {
	RowText: string;
	Error: string;
	RowId: number;

	static fromData(data): ImportFailResult[] {
		if (data == null) {
			return [];
		}
		return data.map((item) => new ImportFailResult(item));
	}

	constructor(data = null) {
		if (data) {
			Object.assign(this, data);
		}
	}
}

export class ImportResult implements ImportResultFunctions {
	Company: Company;
	SuccessResults: ImportSuccessResult[];
	FailResults: ImportFailResult[];

	static fromData(data): ImportResult[] {
		if (data == null) {
			return [];
		}
		return data.map((item) => new ImportResult(item));
	}

	constructor(data = null) {
		if (data) {
			Object.assign(this, data);

			if (data.Company) {
				this.Company = new Company(data.Company);
			}

			if (data.SuccessResults) {
				this.SuccessResults = ImportSuccessResult.fromData(data.SuccessResults);
			}

			if (data.FailResults) {
				this.FailResults = ImportFailResult.fromData(data.FailResults);
			}
		}
	}

	getCompany(): Company {
		return this.Company;
	}

	getSuccessResults(): ImportSuccessResult[] {
		return this.SuccessResults;
	}

	getFailResults(): ImportFailResult[] {
		return this.FailResults;
	}

	hasFailResults(): boolean {
		return this.getFailResults() && this.getFailResults().length > 0;
	}
}

export class ImportResultCount implements ImportResultFunctions {
	Company: Company;
	SuccessResults: number;
	FailResults: ImportFailResult[];

	static fromData(data): ImportResultCount[] {
		if (data == null) {
			return [];
		}
		return data.map((item) => new ImportResultCount(item));
	}

	constructor(data = null) {
		if (data) {
			Object.assign(this, data);

			if (data.Company) {
				this.Company = new Company(data.Company);
			}

			if (data.FailResults) {
				this.FailResults = ImportFailResult.fromData(data.FailResults);
			}
		}
	}

	getCompany(): Company {
		return this.Company;
	}

	public getSuccessResults(): number {
		return this.SuccessResults;
	}

	public getFailResults(): ImportFailResult[] {
		return this.FailResults;
	}

	hasFailResults(): boolean {
		return this.getFailResults() && this.getFailResults().length > 0;
	}
}
