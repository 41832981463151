import { Component, Injector, OnDestroy, OnInit } from '@angular/core';
import { NotificationService } from '../services/notification.service';
import { environment } from '../../environments/environment';
import { Store } from '@ngrx/store';
import { User } from '#/models/user/user.model';
import { AppState } from '../reducers';
import { Login, Update, UpdateKey } from '../modules/user/models/user.actions';
import { AuthManagerService } from '../services/auth-manager.service';
import { Router } from '@angular/router';
import { UntypedFormBuilder } from '@angular/forms';
import { UserService } from '../modules/user/user.service';
import { APINotificationsService } from '../api/services/apinotifications.service';
import { TranslateService } from '@ngx-translate/core';
import { GeneralAPIService } from '../api/services/general-api.service';
import { FolderService } from '../modules/folder/folder.service';
import { MerchantService } from '../modules/merchant/merchant.service';
import { DomSanitizer } from '@angular/platform-browser';
import { IconService } from '../modules/icon/icon.service';
import { StatisticsService } from '../pages/authenticated/statistics/statistics.service';
import { InboxService } from '../modules/inbox/inbox.service';
import { ModalService } from '~/app/services/modal.service';
import { LocalStorageService } from 'angular-2-local-storage';
import { Subscription } from 'rxjs';
import { Environment } from '~/environments/environment.types';
import { TranslateUtilService } from '../services/translate-util.service';
import { ReceiptService } from '#/services/transaction/receipt.service';
import { ComplianceUrlsService } from '#/services/util/compliance-urls.service';

@Component({
	selector: 'app-base',
	templateUrl: './base.component.html',
	styleUrls: ['./base.component.css'],
})
export class BaseComponent implements OnInit, OnDestroy {
	static injector: Injector;
	protected apiNotificationService: APINotificationsService;
	protected notificationService: NotificationService;
	protected store: Store<AppState>;
	protected translate: TranslateService;
	protected authManagerService: AuthManagerService;
	protected router: Router;
	protected formBuilder: UntypedFormBuilder;
	protected userAPIService: UserService;
	protected generalAPIService: GeneralAPIService;
	protected receiptAPIService: ReceiptService;
	protected folderService: FolderService;
	protected merchantAPIService: MerchantService;
	protected iconAPIService: IconService;
	protected APINotifications: APINotificationsService;
	protected domSanitizer: DomSanitizer;
	protected statsAPIService: StatisticsService;
	protected inboxAPIService: InboxService;
	protected modalService: ModalService;
	protected localStorageService: LocalStorageService;
	protected translateUtilService: TranslateUtilService;
	protected destroyCallbacks = [];
	protected complianceUrlsService: ComplianceUrlsService;
	public environment: Environment;

	constructor() {
		this.translate = BaseComponent.injector.get(TranslateService);
		this.apiNotificationService = BaseComponent.injector.get(APINotificationsService);
		this.notificationService = BaseComponent.injector.get(NotificationService);
		this.store = BaseComponent.injector.get(Store);
		this.authManagerService = BaseComponent.injector.get(AuthManagerService);
		this.router = BaseComponent.injector.get(Router);
		this.formBuilder = BaseComponent.injector.get(UntypedFormBuilder);
		this.userAPIService = BaseComponent.injector.get(UserService);
		this.generalAPIService = BaseComponent.injector.get(GeneralAPIService);
		this.receiptAPIService = BaseComponent.injector.get(ReceiptService);
		this.folderService = BaseComponent.injector.get(FolderService);
		this.merchantAPIService = BaseComponent.injector.get(MerchantService);
		this.iconAPIService = BaseComponent.injector.get(IconService);
		this.APINotifications = BaseComponent.injector.get(APINotificationsService);
		this.domSanitizer = BaseComponent.injector.get(DomSanitizer);
		this.statsAPIService = BaseComponent.injector.get(StatisticsService);
		this.inboxAPIService = BaseComponent.injector.get(InboxService);
		this.modalService = BaseComponent.injector.get(ModalService);
		this.environment = environment;
		this.localStorageService = BaseComponent.injector.get(LocalStorageService);
		this.translateUtilService = BaseComponent.injector.get(TranslateUtilService);
		this.complianceUrlsService = BaseComponent.injector.get(ComplianceUrlsService);
	}
	ngOnInit(): void {}

	ngOnDestroy(): void {
		this.destroyCallbacks.forEach((cb) => cb());
	}

	getTranslation(str: string) {
		return this.translate.instant(str);
	}

	setUser(r) {
		const userPayload = new User(r['data']['user']);
		this.authManagerService.setActiveAccountID(0);
		this.authManagerService.setKey(r['data']['key']['key']);
		this.authManagerService.setSecret(r['data']['key']['secret']);
		this.authManagerService.setRefreshToken(r['data']['refresh_token']);
		this.authManagerService.setUserID(userPayload.getID());
		this.store.dispatch(new Login({ user: userPayload }));
	}

	async finalizeLogin(r, redirectTo?: string) {
		this.setUser(r);
		const needsMFAVerification = await this.userAPIService.needsMFAVerification();
		if (needsMFAVerification) {
			await this.router.navigate(['/login'], { queryParams: { mfa: true } });
			return;
		}
		const userPayload = new User(r['data']['user']);
		if (userPayload.getPreferences() && userPayload.getPreferences().language !== '') {
			this.translateUtilService.setLanguage(userPayload.getPreferences().language);
		}

		this.store.dispatch(new UpdateKey({ key: r['data']['key']['key'], secret: r['data']['key']['secret'] }));

		if (redirectTo) {
			await this.router.navigate([redirectTo]);
			return;
		}

		const returnUrl = this.localStorageService.get('returnUrl');
		if (!returnUrl) {
			const homepage = await this.userAPIService.getHomePage();
			await this.router.navigate([homepage]);
			return;
		}

		this.localStorageService.remove('returnUrl');
		await this.router.navigate([returnUrl]);
		return;
	}

	updateUser(user: User) {
		const userPayload = new User(user);
		this.store.dispatch(new Update({ user: userPayload }));

		// Change language.
		if (userPayload.getPreferences() && userPayload.getPreferences().language !== '') {
			this.translateUtilService.setLanguage(userPayload.getPreferences().language);
		}
	}

	on(subscription: Subscription) {
		/* Shorthand method to push a Subscription into the destroyCallbacks that get unsubscribed in ngOnDestroy. */
		if (!subscription) {
			return;
		}

		this.destroyCallbacks.push(() => {
			subscription.unsubscribe();
		});
	}
}
