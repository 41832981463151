import { Component, Input, OnInit } from '@angular/core';
import { DeclarationStatus, Receipt, ReceiptAuthorizationFlowStatus } from '#/models/transaction/receipt';
import { AuthenticatedComponent } from '~/app/pages/authenticated/authenticated.component';
import { ExpenseReport } from '#/models/transaction/expense-reports';
import { ExpenseReportsService } from '#/services/transaction/expense-reports.service';

@Component({
	selector: 'app-authorization-flow-tooltip',
	templateUrl: './authorization-flow-tooltip.component.html',
})
export class AuthorizationFlowTooltipComponent extends AuthenticatedComponent implements OnInit {
	// Input can either be ExpenseReport or Receipt.
	@Input() public parentItem: Receipt | ExpenseReport;
	// From async-value it is not possible to detect type in compiled JS, therefore parentType is provided as another option.
	@Input() public parentType: 'receipt' | 'report' | 'transaction' = 'receipt';
	public authorizationFlowStatusPromise: Promise<ReceiptAuthorizationFlowStatus>;
	public lastDeclarationStatusUpdate: DeclarationStatus;

	constructor(private expenseReportsService: ExpenseReportsService) {
		super();
	}

	ngOnInit(): void {
		super.ngOnInit();
		if (this.parentItem instanceof Receipt || this.parentType === 'receipt' || this.parentType === 'transaction') {
			this.authorizationFlowStatusPromise = this.receiptAPIService.getReceiptAuthorizationFlowStatus(this.parentItem.id);
			this.authorizationFlowStatusPromise.then((receiptAuthFlow) => {
				if (!receiptAuthFlow.has_flow) {
					const receipt = this.parentItem as Receipt;
					this.lastDeclarationStatusUpdate = [...receipt.declarationstatushistory].reverse()[0];
				}
			});
		}

		if (this.parentItem instanceof ExpenseReport || this.parentType === 'report') {
			this.authorizationFlowStatusPromise = this.expenseReportsService.getExpenseReportAuthorizationFlowStatus(this.parentItem.id);
			this.authorizationFlowStatusPromise.then((reportAuthFlow) => {
				if (!reportAuthFlow.has_flow) {
					const report = this.parentItem as ExpenseReport;
					this.lastDeclarationStatusUpdate = report.expense_status.current_status;
				}
			});
		}
	}
}
