import { CompanyService } from '#/services/company/company.service';
import { OnDestroy, OnInit, Directive } from '@angular/core';
import { Store } from '@ngrx/store';
import { AuthorizationFlow, Company } from '#/models/company/company.model';
import { User } from '#/models/user/user.model';
import { BaseComponent } from '~/app/base/base.component';
import { TranslationReplaceService } from '~/app/services/translation-replace.service';
import { CompanyPaymentMethod } from '#/models/company/payment-method.model';

@Directive()
export abstract class CompanyPickerComponent implements OnInit, OnDestroy {
	protected companySubscription;
	protected userSubscription;
	protected company: Company;
	protected parentCompany: Company;
	protected user: User;
	protected store;
	protected companyAPIService: CompanyService;
	protected companyPaymentMethods: CompanyPaymentMethod[];
	protected companyAuthorizationFlows: AuthorizationFlow[];
	protected translationReplaceService: TranslationReplaceService;

	constructor() {
		this.store = BaseComponent.injector.get(Store);
		this.companyAPIService = BaseComponent.injector.get(CompanyService);
		this.translationReplaceService = BaseComponent.injector.get(TranslationReplaceService);
	}

	ngOnInit() {
		this.companySubscription = this.store.select('company').subscribe((val) => {
			Object.assign(this, val);
		});

		this.userSubscription = this.store.select('user').subscribe((val) => {
			this.user = val.currentUser;
		});
	}

	ngOnDestroy(): void {
		if (this.companySubscription) {
			this.companySubscription.unsubscribe();
		}

		if (this.userSubscription) {
			this.userSubscription.unsubscribe();
		}
	}
}
