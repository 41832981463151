<div class="componentContainer" #fileContainer>
	<ng-container *ngIf="shouldShowFile()">
		<div *ngIf="type === 'document' && isPDF" class="pdf-container" data-cy="pdfContainer">
			<ng2-pdfjs-viewer
				#pdfView
				viewerFolder="assets/pdfjs-v2.9.359"
				[pdfSrc]="url"
				[download]="true"
				[downloadFileName]="pdfFilename"
				[openFile]="false"
				[externalWindow]="false"
				[showSpinner]="true"
			></ng2-pdfjs-viewer>
		</div>
		<div *ngIf="type === 'image'" class="img-container">
			<img
				[src]="imageUrl"
				alt="An image of the uploaded document"
				class="img-fluid d-block mx-auto"
				*ngIf="viewerType === 'image'"
				data-cy="imageContainer"
			/>
			<ngx-imageviewer [src]="url" filetype="image" #imageViewer *ngIf="viewerType === 'canvas'"></ngx-imageviewer>
		</div>
		<div *ngIf="(type !== 'image' && type !== 'document') || (type === 'document' && !isPDF)">
			<div class="d-flex flex-column text-center mb-3">
				<label class="text-bold">{{ filename }}</label>
				<span translate>Your browser is not able to display this file.</span>
			</div>
			<p class="text-center">
				<app-button variant="greenFilled" (click)="downloadFile()" translate>Click here to download the file</app-button>
			</p>
		</div>
	</ng-container>
	<ng-container *ngIf="!shouldShowFile()">
		<app-virus-scan-result
			*ngIf="virusScanResult?.status === 'pending'"
			variant="hasNotBeenScanned"
			[filename]="filename"
			(showClick)="showFileAnyway()"
		></app-virus-scan-result>
		<app-virus-scan-result
			*ngIf="virusScanResult?.status === 'virus_detected'"
			variant="virusDetected"
			[filename]="filename"
			(downloadClick)="downloadFile()"
		></app-virus-scan-result>
		<app-virus-scan-result
			*ngIf="virusScanResult?.status === 'error'"
			variant="couldNotBeScanned"
			[filename]="filename"
			(downloadClick)="downloadFile()"
		></app-virus-scan-result>
	</ng-container>
</div>
