import { Component } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { AppSelectOptions } from '@klippa/ngx-enhancy-forms';
import { StaticOptionsValueAccessorBase } from './base/static-options-value-accessor-base';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';

@Component({
	selector: 'app-form-expense-registration-type-picker',
	templateUrl: './base/static-options-picker.template.html',
	styleUrls: ['./base/static-options-picker.template.scss'],
	providers: [{ provide: NG_VALUE_ACCESSOR, useExisting: ExpenseRegistrationTypePicker, multi: true }],
})
export class ExpenseRegistrationTypePicker extends StaticOptionsValueAccessorBase<string> {
	clearable: boolean = true;
	defaultPlaceHolder: string = _('Select transaction type');

	fetchStaticItemsFn(): AppSelectOptions {
		return [
			{ id: 'REGISTRATION', name: _('Registration') },
			{ id: 'EXPENSE', name: _('Expense') },
		];
	}
}
