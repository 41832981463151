export interface WSMessage {
	type?: MessageTypes;
	data?: any;
}

export enum MessageTypes {
	Authenticate = 'authenticate',
	AccessDenied = 'access-denied',
	LoggedOut = 'logged-out',
	Authenticated = 'authenticated',
	Group = 'group', // use .startsWith() to check all the variants at once.
	GroupAdd = 'group-add',
	GroupUpdate = 'group-update',
	GroupRemove = 'group-remove',
	GroupShare = 'group-share',
	Receipt = 'receipt', // use .startsWith() to check all the variants at once.
	ReceiptRemove = 'receipt-remove',
	ReceiptAdd = 'receipt-add',
	ReceiptUpdate = 'receipt-update',
	ReceiptDeclaration = 'receipt-declaration', // use .startsWith() to check all the variants at once.
	ReceiptDeclarationUpdate = 'receipt-declaration-update',
	ReceiptDeclarationEnable = 'receipt-declaration-enable',
	ReceiptDeclarationDisable = 'receipt-declaration-disable',
	ReportUpdate = 'report-update',
	UserUpdate = 'user-update',
	CompanyUpdate = 'company-update',
	ReceiptShare = 'receipt-share',
	Inbox = 'inbox', // use .startsWith() to check all the variants at once.
	InboxNew = 'inbox-new',
	InboxRead = 'inbox-read',
	InboxUnread = 'inbox-unread',
	OCRReviewAvailable = 'ocr-review-available',
	PaymentMethod = 'payment-method', // use .startsWith() to check all the variants at once.
	PaymentMethodAdd = 'payment-method-add',
	PaymentMethodUpdate = 'payment-method-update',
	PaymentMethodRemove = 'payment-method-remove',
	PaymentMethodRefresh = 'payment-method-refresh',
	AuthorizationFlowAdd = 'authorization-flow-add',
	AuthorizationFlowUpdate = 'authorization-flow-update',
	AuthorizationFlowRemove = 'authorization-flow-remove',
	CompanyGroup = 'company-group', // use .startsWith() to check all the variants at once.
	CompanyGroupAdd = 'company-group-add',
	CompanyGroupUpdate = 'company-group-update',
	CompanyGroupRemove = 'company-group-remove',
	CompanyGroupRefresh = 'company-group-refresh',
	CompanyCategory = 'company-category', // use .startsWith() to check all the variants at ones.
	CompanyCategoryAdd = 'company-category-add',
	CompanyCategoryUpdate = 'company-category-update',
	CompanyCategoryRemove = 'company-category-remove',
	CompanyCategoryRefresh = 'company-category-refresh',
	CompanyCostCenter = 'company-costcenter', // use .startsWith() to check all the variants at ones.
	CompanyCostCenterAdd = 'company-costcenter-add',
	CompanyCostCenterUpdate = 'company-costcenter-update',
	CompanyCostCenterRemove = 'company-costcenter-remove',
	CompanyCostCenterRefresh = 'company-costcenter-refresh',
	CompanyCostUnit = 'company-costunit', // use .startsWith() to check all the variants at ones.
	CompanyCostUnitAdd = 'company-costunit-add',
	CompanyCostUnitUpdate = 'company-costunit-update',
	CompanyCostUnitRemove = 'company-costunit-remove',
	CompanyCostUnitRefresh = 'company-costunit-refresh',
	CompanyProject = 'company-project', // use .startsWith() to check all the variants at ones.
	CompanyProjectAdd = 'company-project-add',
	CompanyProjectUpdate = 'company-project-update',
	CompanyProjectRemove = 'company-project-remove',
	CompanyProjectRefresh = 'company-project-refresh',
	BatchStatusUpdate = 'batch-status-update',
}

export function needsReceiptListUpdate(updateType: MessageTypes) {
	if (
		updateType === MessageTypes.ReceiptRemove ||
		updateType === MessageTypes.ReceiptUpdate ||
		updateType === MessageTypes.ReceiptDeclarationUpdate ||
		updateType === MessageTypes.ReceiptDeclarationEnable ||
		updateType === MessageTypes.ReceiptDeclarationDisable ||
		updateType === MessageTypes.ReceiptAdd ||
		updateType === MessageTypes.ReceiptShare ||
		updateType === MessageTypes.GroupShare
	) {
		return true;
	}
}

export function needsPaymentMethodsUpdate(updateType: MessageTypes) {
	return (
		updateType === MessageTypes.PaymentMethodAdd ||
		updateType === MessageTypes.PaymentMethodRemove ||
		updateType === MessageTypes.PaymentMethodUpdate
	);
}

export function needsAuthorizationFlowsUpdate(updateType: MessageTypes) {
	return (
		updateType === MessageTypes.AuthorizationFlowAdd ||
		updateType === MessageTypes.AuthorizationFlowRemove ||
		updateType === MessageTypes.AuthorizationFlowUpdate
	);
}

export function needsGroupListUpdate(updateType: MessageTypes) {
	// Any receipt update could also mean a change in groups.
	if (needsReceiptListUpdate(updateType)) {
		return true;
	}

	if (
		updateType === MessageTypes.GroupAdd ||
		updateType === MessageTypes.GroupUpdate ||
		updateType === MessageTypes.GroupRemove ||
		updateType === MessageTypes.GroupShare
	) {
		return true;
	}
}

export function needsInboxUpdate(updateType: MessageTypes) {
	if (updateType === MessageTypes.InboxNew || updateType === MessageTypes.InboxRead) {
		return true;
	}
}

export function needsReportUpdate(updateType: MessageTypes): boolean {
	return updateType === MessageTypes.ReportUpdate;
}
