import { Injectable } from '@angular/core';

export enum colors {
	PRIMARY = '#7774e7',
	INFO = '#0f9aee',
	SUCCESS = '#37c936',
	WARNING = '#ffcc00',
	DANGER = '#ff3c7e',
	PRIMARY_INVERSE = 'rgba(119, 116, 231, 0.1)',
	INFO_INVERSE = 'rgba(15, 154, 238, 0.1)',
	SUCCESS_INVERSE = 'rgba(55, 201, 54, 0.1)',
	WARNING_INVERSE = 'rgba(255, 204, 0, 0.1)',
	DANGER_INVERSE = 'rgba(255, 60, 126, 0.1)',
	GRAY = '#ebeef6',
	WHITE = '#ffffff',
	DARK = '#515365',
	BLUE_GREEN = '#17a2b8',
	ORANGE = '#ff8000',
	SEA_GREEN = '#27BB5F',
	BLUE = '#0f9aee',
	MEDIUM_BLUE = '#7774e7',
}

export enum ChartColors {
	COLOR_PRIMARY_0 = '#00AC81',
	COLOR_PRIMARY_1 = '#4BC1A3',
	COLOR_PRIMARY_2 = '#27B18F',
	COLOR_PRIMARY_3 = '#008564',
	COLOR_PRIMARY_4 = '#00684E',
	COLOR_SECONDARY_1_0 = '#0B68A9',
	COLOR_SECONDARY_1_1 = '#5192BF',
	COLOR_SECONDARY_1_2 = '#2F7AAF',
	COLOR_SECONDARY_1_3 = '#075083',
	COLOR_SECONDARY_1_4 = '#053E67',
	COLOR_SECONDARY_2_0 = '#FF9000',
	COLOR_SECONDARY_2_1 = '#FFBB63',
	COLOR_SECONDARY_2_2 = '#FFA838',
	COLOR_SECONDARY_2_3 = '#CC7300',
	COLOR_SECONDARY_2_4 = '#A15B00',
	COLOR_SECONDARY_3_0 = '#FD0006',
	COLOR_SECONDARY_3_1 = '#FD6266',
	COLOR_SECONDARY_3_2 = '#FD373C',
	COLOR_SECONDARY_3_3 = '#CB0005',
	COLOR_SECONDARY_3_4 = '#A00004',
	COLOR_SECONDARY_4_0 = '#CE0079',
	COLOR_SECONDARY_4_1 = '#DB55A3',
	COLOR_SECONDARY_4_2 = '#D22E8E',
	COLOR_SECONDARY_4_3 = '#A3005F',
	COLOR_SECONDARY_4_4 = '#80004B',
	COLOR_COMPLEMENT_0 = '#FF5F00',
	COLOR_COMPLEMENT_1 = '#FF9D63',
	COLOR_COMPLEMENT_2 = '#FF8238',
	COLOR_COMPLEMENT_3 = '#CC4C00',
	COLOR_COMPLEMENT_4 = '#A13C00',
	CHART_COLOR_1 = '#27BB5F',
	CHART_COLOR_2 = '#00AF8C',
	CHART_COLOR_3 = '#00A0B3',
	CHART_COLOR_4 = '#008CCA',
	CHART_COLOR_5 = '#0075C9',
	CHART_COLOR_6 = '#4058B0',
	CHART_COLOR_7 = '#9d0076',
	CHART_COLOR_8 = '#be0490',
	CHART_COLOR_9 = '#fd009f',
	CHART_COLOR_10 = '#FF9D63',
	CHART_COLOR_11 = '#ffbe4e',
	CHART_COLOR_12 = '#ffcc63',
}

@Injectable()
export class ThemeConstants {
	private colorConfig: any;

	constructor() {
		this.colorConfig = {
			colors: {
				primary: colors.PRIMARY,
				info: colors.INFO,
				success: colors.SUCCESS,
				warning: colors.WARNING,
				danger: colors.DANGER,
				primaryInverse: colors.PRIMARY_INVERSE,
				infoInverse: colors.INFO_INVERSE,
				successInverse: colors.SUCCESS_INVERSE,
				warningInverse: colors.WARNING_INVERSE,
				dangerInverse: colors.DANGER_INVERSE,
				gray: colors.GRAY,
				white: colors.WHITE,
				dark: colors.DARK,
			},
		};
	}

	get() {
		return this.colorConfig;
	}
}
