import { Component, ElementRef, Input, OnChanges, OnDestroy, SimpleChanges, ViewChild } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { createButtonConfig, IMAGEVIEWER_CONFIG, ImageViewerConfig } from '@hallysonh/ngx-imageviewer';
import { FileUtils } from '../../../helpers/file-utils';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { PdfJsViewerComponent } from 'ng2-pdfjs-viewer';
import { VirusScanResult } from '#/models/transaction/receipt';
import { isNullOrUndefined } from '#/util/values';

const MY_IMAGEVIEWER_CONFIG: ImageViewerConfig = {
	width: 600,
	height: 800,
	loadingMessage: ' ',
	buttonStyle: {
		iconFontFamily: 'fontawesome', // font used to render the button icons
		alpha: 0.5, // buttons' transparence value
		hoverAlpha: 0.7, // buttons' transparence value when mouse is over
		bgStyle: '#000000', //  buttons' background style
		iconStyle: '#ffffff', // buttons' icon colors
		borderStyle: '#000000', // buttons' border style
		borderWidth: 0, // buttons' border width (0 == disabled)
	},
	bgStyle: 'transparent',
	zoomOutButton: createButtonConfig(String.fromCharCode(0xf010), 'Zoom out', 0),
	zoomInButton: createButtonConfig(String.fromCharCode(0xf00e), 'Zoom in', 1),
	rotateLeftButton: createButtonConfig(String.fromCharCode(0xf0e2), 'Rotate left', 2),
	rotateRightButton: createButtonConfig(String.fromCharCode(0xf01e), 'Rotate right', 3),
	resetButton: createButtonConfig(String.fromCharCode(0xf0b2), 'Reset zoom', 4),
};

@Component({
	selector: 'app-file-viewer-deprecated',
	templateUrl: './file-viewer-deprecated.component.html',
	styleUrls: ['file-viewer-deprecated.component.scss'],
	providers: [
		{
			provide: IMAGEVIEWER_CONFIG,
			useValue: MY_IMAGEVIEWER_CONFIG,
		},
	],
})
export class FileViewerDeprecatedComponent implements OnDestroy, OnChanges {
	@ViewChild('fileContainer') fileContainer: ElementRef;
	@ViewChild('imageViewer') imageViewer: any;
	@ViewChild('pdfView') pdfView: PdfJsViewerComponent;
	@Input() blob: Blob;
	@Input() filename: string;
	@Input() type: string;
	@Input() viewerType = 'image';
	@Input() fileCount: number;
	@Input() currentFile: number;
	@Input() isOCR: boolean;
	@Input() uploaderID: string;
	@Input() virusScanResult: VirusScanResult;
	@Input() isUploader: boolean;

	public url: string;
	public imageUrl: SafeUrl;
	public pdfFilename: string;
	public isPDF: boolean = false;
	public forceShowingFile: boolean = false;

	constructor(private http: HttpClient, private domSanitizer: DomSanitizer) {}

	public ngOnChanges(changes: SimpleChanges): void {
		this.updateContent();
	}

	public ngOnDestroy(): void {
		if (this.url && this.url !== '') {
			URL.revokeObjectURL(this.url);
		}
	}

	private updateContent(): void {
		if (this.url && this.url !== '') {
			URL.revokeObjectURL(this.url);
		}

		this.url = URL.createObjectURL(this.blob);
		this.imageUrl = this.domSanitizer.bypassSecurityTrustResourceUrl(this.url);

		if (this.filename && this.filename.slice(-4).toLowerCase() === '.pdf') {
			this.pdfFilename = this.filename.substr(0, this.filename.length - 4);
		} else {
			this.pdfFilename = this.filename;
		}

		if (
			this.blob.type === 'application/pdf' ||
			this.blob.type === 'application/x-pdf' ||
			this.blob.type === 'x-application/apple-pdf' ||
			this.filename.slice(-4).toLowerCase() === '.pdf'
		) {
			this.isPDF = true;

			// Workaround when pdfView is already loaded. They did not implement ngOnChanges.
			if (this.pdfView) {
				this.pdfView.pdfSrc = this.url;
				this.pdfView.downloadFileName = this.pdfFilename;
				this.pdfView.refresh();
			}
		}
	}

	public downloadFile(): void {
		FileUtils.downloadFile(window, this.blob, this.filename, this.url);
	}

	public showFileAnyway(): void {
		this.forceShowingFile = true;
	}

	public shouldShowFile(): boolean {
		if (this.forceShowingFile) {
			return true;
		}
		if (isNullOrUndefined(this.virusScanResult)) {
			return true;
		}
		if (this.virusScanResult?.status === 'ok') {
			return true;
		}
		if (this.virusScanResult?.status === 'pending' && this.isUploader) {
			return true;
		}
		return false;
	}
}
