import { CompanyUnitBase } from './company-unit-base.model';
import { CompanyObjectListAPIRequest } from '#/models/company/company-object-api-request.model';

export class CostUnit extends CompanyUnitBase {
	constructor(data = null) {
		super(data);
	}

	static fromData(data): CostUnit[] {
		if (data == null) {
			return [];
		}
		return data.map((item) => new CostUnit(item));
	}

	clone(): CostUnit {
		return new CostUnit(JSON.parse(JSON.stringify(this)));
	}
}

export class CompanyCostUnitData {
	company_costunits: CostUnit[] = [];
	count: number;
	moreresults: boolean;

	constructor(data = null) {
		if (data) {
			Object.assign(this, data);

			if (data.company_costunits) {
				this.company_costunits = CostUnit.fromData(data.company_costunits);
			}
		}
	}
}

export class CompanyCostUnitListAPIRequest extends CompanyObjectListAPIRequest {
	ids?: Array<string>;
	administrations?: Array<string>;
	getURL(): string {
		if (this.list) {
			if (this.company && this.company !== '') {
				return `/api/v1/company/${this.company}/companyCostUnit/list`;
			}
			return `/api/v1/companyCostUnit/list`;
		}
		if (this.company && this.company !== '') {
			return `/api/v1/company/${this.company}/companyCostUnit`;
		}
		return `/api/v1/companyCostUnit`;
	}
}
