<ngx-async-template [promise]="optionsPromise" [successStateOnReload]="true">
	<ng-template ngx-async-success let-options="value">
		<klp-form-select
			[options]="options"
			[inErrorState]="isInErrorState()"
			[ngClass]="{ showErrors: isInErrorState(), hideOptionsPanel: hideOptionsPanel, noBorder: !hasBorder }"
			(ngModelChange)="setInnerValueAndNotify($event)"
			[(ngModel)]="innerValue"
			[placeholder]="getPlaceholderTextFn()"
			[multiple]="multiple"
			[disabled]="disabled || disabledBecauseNoOptions"
			[clearable]="clearable"
			(onOpened)="pickerOpened()"
			(onClosed)="onClosed.emit()"
			(onClear)="onSearch(null)"
			(onSearch)="onSearch($event)"
			(onEndReached)="loadMore()"
			(onEnterKey)="enterPressed($event)"
			[dropdownPosition]="dropdownPosition"
			[orientation]="orientation"
			[truncateOptions]="truncateOptions"
			[withSeparatingLine]="withSeparatingLine"
			[footerElement]="searchQuery?.length > 0 && showAddNewOptionFooter() ? addNewOptionFooter : null"
			[customSearchFn]="searchItemsFn"
			[prefix]="prefix"
			[getTailTplFn]="getTailTpl"
		>
			<ng-container *ngIf="customOptionTpl">
				<ng-template klpSelectOptionTpl let-item="item">
					<ng-container [ngTemplateOutlet]="customOptionTpl" [ngTemplateOutletContext]="{ item: item }"> </ng-container>
				</ng-template>
			</ng-container>
		</klp-form-select>
	</ng-template>
	<ng-template ngx-async-pending>
		<klp-form-loading-indicator variant="picker"></klp-form-loading-indicator>
	</ng-template>
</ngx-async-template>
<ng-template #addNewOptionFooter>
	<klp-select-footer
		[prefix]="newOptionFooterPrefix | translate"
		[text]="searchQuery"
		(click)="_onFooterClick(searchQuery)"
	></klp-select-footer>
</ng-template>
