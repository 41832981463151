import { NgbModal, NgbModalOptions, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ElementRef, Injectable } from '@angular/core';
import { ConfirmModalComponent } from '~/app/shared/ui/confirm-modal/confirm-modal.component';
import { TranslateService } from '@ngx-translate/core';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';

export interface ModalOptions extends NgbModalOptions {
	confirmDismiss?: boolean;
	canDismiss?: boolean;
	keyboard?: boolean;
	backdrop?: boolean | 'static';
}

interface ModalRefEntry {
	elementRef: ElementRef;
	ngbRef: NgbModalRef;
	modalOptions: ModalOptions;
}

@Injectable({
	providedIn: 'root',
})
export class ModalService {
	constructor(public modal: NgbModal, private translate: TranslateService) {}

	openModals: Array<ModalRefEntry> = [];

	open(content: any, options?: ModalOptions): NgbModalRef {
		options = options || {};
		this.setOptions(options);
		const ref = this.modal.open(content, options);
		const modalRefEntry: ModalRefEntry = { elementRef: content, ngbRef: ref, modalOptions: options };

		// we keep references for our open modals, so they can be closed easily from anywhere
		this.openModals.push(modalRefEntry);

		// when we close the modal, clean up the reference
		ref.result
			.then(
				() => {
					// on closing
					this.cleanUpModalRef(modalRefEntry);
				},
				() => {
					// on dismissal
					this.cleanUpModalRef(modalRefEntry);
				},
			)
			.catch(() => {
				this.cleanUpModalRef(modalRefEntry);
			});
		return ref;
	}

	setOptions(options: ModalOptions) {
		/* Requests confirmation before closing this modal. Defaults to true. */
		options.confirmDismiss = 'confirmDismiss' in options ? options.confirmDismiss : true;
		options.canDismiss = 'canDismiss' in options ? options.canDismiss : true;
		options.keyboard = options.canDismiss ? true : false;
		options.backdrop = options.canDismiss ? true : 'static';
		/* Only applied to a modal if the beforeDismiss handler is not set yet. */
		if (options.confirmDismiss && !options.beforeDismiss) {
			options.beforeDismiss = () => {
				/* Query selector for the top-most modal. */
				const topModal = 'ngb-modal-window:last-child .modal-content';
				/* Test the following input fields for the ng-dirty class. */
				const inputs = ['input', 'ng-select', 'datetime-picker'];
				const inputQuerySelector = inputs.map((input) => `${topModal} ${input}`).join(', ');
				const items = Array.from(window.document.querySelectorAll(inputQuerySelector)).filter((i) =>
					i.classList.contains('ng-dirty'),
				).length;

				return items > 0
					? this.requestConfirmation() // Request confirmation for dismissal if there are dirty items.
					: true;
			};
		}
	}

	getOptions(modalElement: ElementRef): ModalOptions {
		const foundModal = this.openModals.find((item) => item.elementRef === modalElement);
		return foundModal.modalOptions;
	}

	hasModalOpen() {
		return this.openModals.length > 0;
	}

	cleanUpModalRef(refEntry: ModalRefEntry) {
		this.openModals = this.openModals.filter((i) => i !== refEntry);
	}

	close(modalElement: ElementRef): void {
		const foundModal = this.openModals.find((item) => item.elementRef === modalElement);
		if (foundModal) {
			foundModal.ngbRef.close();
		}
	}

	dismiss(modalElement: ElementRef): void {
		const foundModal = this.openModals.find((item) => item.elementRef === modalElement);
		if (foundModal) {
			foundModal.ngbRef.dismiss();
		}
	}

	requestConfirmation(title = null, message = null, confirmLabel = null): Promise<boolean> {
		const confirmModalRef = this.open(ConfirmModalComponent);
		confirmModalRef.componentInstance.type = 'text';
		confirmModalRef.componentInstance.title = this.translate.instant(title ?? _('Dismiss changes'));
		confirmModalRef.componentInstance.message = this.translate.instant(message ?? _('Are you sure that you want to dismiss your changes?'));
		confirmModalRef.componentInstance.confirmLabel = this.translate.instant(confirmLabel ?? _("Yes, I'm sure"));
		return confirmModalRef.result.then((r) => r === 'Confirm');
	}
}
