import { isValueSet } from '#/util/values';
import { Order } from '#/models/utils/order';

export interface QueryParam {
	key: string;
	value: string | number;
}

export class ListAPIRequest {
	start = 0;
	max = 50;
	sort: string = 'createdate';
	sortorder: Order = Order.DESCENDING;

	getURL(): string {
		return '';
	}

	getQueryParams(): QueryParam[] {
		const parts: QueryParam[] = [];
		parts.push({ key: 'start', value: this.start });
		parts.push({ key: 'max', value: this.max });
		parts.push({ key: 'sort', value: this.sort });
		parts.push({ key: 'sortorder', value: this.sortorder });
		return parts;
	}

	getQueryString(): string {
		return (
			'?' +
			this.getQueryParams()
				.filter((param) => isValueSet(param.value))
				.map((part) => encodeURIComponent(part.key) + '=' + encodeURIComponent(part.value.toString()))
				.join('&')
		);
	}

	getRequestURL(): string {
		return `${this.getURL()}${this.getQueryString()}`;
	}
}

export class ListFilterAPIRequest extends ListAPIRequest {
	activeFilters: number;

	updateActiveFilters(toCheck: any[]) {
		this.activeFilters = toCheck.reduce((acc, item) => {
			if (item === undefined || item === null) {
				return acc;
			}
			if ((typeof item === 'string' || Array.isArray(item)) && item.length === 0) {
				return acc;
			}
			return acc + 1;
		}, 0);
	}
}
