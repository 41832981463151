import { Component, EventEmitter, Input } from '@angular/core';
import { ModalOptions } from '~/app/services/modal.service';

@Component({
	selector: '',
	template: '',
})
export class BaseModalComponent {
	@Input() modalOptions: ModalOptions = {};
	@Input() closeConfirmation: boolean = false;
	@Input() closeConfirmationOptions: ModalCloseConfirmationOptions;

	public _closeModalEmitter: EventEmitter<void> = new EventEmitter<void>();
	public _dismissModalEmitter: EventEmitter<void> = new EventEmitter<void>();
	public _openModalEmitter: EventEmitter<void> = new EventEmitter<void>();

	public openModal() {
		this._openModalEmitter.emit();
	}

	public closeModal() {
		this._closeModalEmitter.emit();
	}

	public dismissModal() {
		this._dismissModalEmitter.emit();
	}
}

export interface ModalCloseConfirmationOptions {
	title?: string;
	message?: string;
	confirmLabel?: string;
}
