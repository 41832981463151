import { AuthorizationFlowApiModel } from '#/models/transaction/authorization-flow/apiModel';
import { AuthorizationFlowFrontendModel } from '#/models/transaction/authorization-flow/frontendModel';
import { DeclarationStatusFlag } from '#/models/transaction/receipt';

export function apiToFrontend(
	apiModel: AuthorizationFlowApiModel,
	status: DeclarationStatusFlag,
	loggedUserId: string,
): AuthorizationFlowFrontendModel {
	const result = new AuthorizationFlowFrontendModel();
	result.id = apiModel.flow_id;
	result.isInFlow = apiModel.in_flow;
	result.requireApproverOrder = apiModel.require_approver_order;
	result.possibleApprovers = apiModel.approvers; // to select the users
	result.possibleApproversOrder = apiModel.approvers; // to specify the order
	result.completedApprovals = apiModel.approvals;
	result.requireApproverAmount = apiModel.require_approver_count >= 1 ? apiModel.require_approver_count : apiModel.approvers.length;
	result.awaitingApprovers = apiModel.waiting_approvers;
	result.myTurnToApprove = (statusAllowsApprovals(status) && apiModel.next_approvers?.includes(loggedUserId)) ?? false;
	result.otherPersonTurnToApprove = statusAllowsApprovals(status) && apiModel.in_flow && !apiModel.next_approvers?.includes(loggedUserId);
	result.requiredApprovalsUntilFullyApproved = apiModel.next_approvers?.length ?? 0;
	result.myApprovalIsFinalApproval =
		result.myTurnToApprove &&
		(result.requireApproverAmount ?? result.possibleApproversOrder.length) === result.completedApprovals.length + 1;
	return result;
}

function statusAllowsApprovals(status: DeclarationStatusFlag) {
	switch (status) {
		case DeclarationStatusFlag.Accepted:
			return true;
	}
	return false;
}

export function frontendToApi(frontendModel: AuthorizationFlowFrontendModel): AuthorizationFlowApiModel {
	const result = new AuthorizationFlowApiModel();
	result.flow_id = frontendModel.id;
	result.in_flow = frontendModel.isInFlow;
	result.require_approver_order = frontendModel.requireApproverOrder;
	result.approvers = frontendModel.possibleApproversOrder;
	result.approvals = frontendModel.completedApprovals;
	result.require_approver_count =
		frontendModel.requireApproverAmount >= 1 ? frontendModel.requireApproverAmount : frontendModel.possibleApproversOrder.length;
	result.waiting_approvers = frontendModel.awaitingApprovers;
	return result;
}
