import { Component, Host, Input, Optional } from '@angular/core';
import { AppSelectOptions, FormElementComponent, ValueAccessorBase } from '@klippa/ngx-enhancy-forms';
import { ControlContainer, NG_VALUE_ACCESSOR } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import * as fromGeneric from '~/app/modules/generic/generic.reducer';
import { AppState } from '~/app/reducers';
import { sortBy } from 'lodash';

// TODO: make use of static options picker

@Component({
	selector: 'app-currency-picker',
	templateUrl: './currency-picker.component.html',
	styleUrls: ['./currency-picker.component.scss'],
	providers: [{ provide: NG_VALUE_ACCESSOR, useExisting: CurrencyPickerComponent, multi: true }],
})
export class CurrencyPickerComponent extends ValueAccessorBase<string> {
	private currenciesSubscription;
	public currencies: AppSelectOptions = [];

	@Input() public multiple = false;
	@Input() public filterExchangeCurrencies = false;
	@Input() public clearable = true;
	@Input() public hasBorderLeft = true;

	constructor(
		@Optional() @Host() protected parent: FormElementComponent,
		@Optional() @Host() protected controlContainer: ControlContainer,
		private store: Store<AppState>,
	) {
		super(parent, controlContainer);
		this.currenciesSubscription = this.store
			.select('generic')
			.pipe(select(fromGeneric.selectAllCurrencies))
			.subscribe((currencies) => {
				currencies = sortBy(currencies, ['Label']);
				this.currencies = currencies.map((val) => ({
					id: val.Code,
					name: val.Code,
					description: val.Title + ` (${val.Symbol})`,
				}));
			});
	}

	public search = (
		term: string,
		item: {
			id: string;
			name: string;
			description: string;
		},
	): boolean => {
		return item.name.toLowerCase().includes(term) || item.description.toLowerCase().includes(term);
	};

	ngOnDestroy() {
		this.currenciesSubscription?.unsubscribe();
	}
}
