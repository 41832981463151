import { Component, EventEmitter, Input, Output } from '@angular/core';
import {
	CloseHandler,
	NotificationButton,
	NotificationCategory,
} from '~/app/shared/ui/toast-notification/toast-notifications-manager/toast-notifications-manager.component';

@Component({
	selector: 'app-toast-notification',
	templateUrl: './toast-notification.component.html',
	styleUrls: ['./toast-notification.component.scss'],
})
export class ToastNotificationComponent {
	@Input() category: NotificationCategory;
	@Input() description: string;
	@Input() buttons: Array<NotificationButton>;
	@Output() onClose = new EventEmitter<void>();

	close() {
		this.onClose.emit();
	}

	callButtonAction(action: (closeHandler: CloseHandler) => void) {
		action(() => this.close());
	}
}
