import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Environment } from '~/environments/environment.types';
import { environment } from '~/environments/environment';
import { ActivatedRoute } from '@angular/router';
import { Clipboard } from '@angular/cdk/clipboard';
import { firstValueFrom, interval, Subscription } from 'rxjs';
import { APINotificationsService } from '~/app/api/services/apinotifications.service';
import { ExportResponse, ExportStatusType } from '#/models/exportData.model';
import { isValueSet, stringIsSetAndFilled } from '#/util/values';
import { HttpClient } from '@angular/common/http';
import { Themed } from '#/providers/themed';

@Component({
	selector: 'app-share-export-page',
	templateUrl: './share-export-page.component.html',
	styleUrls: ['./share-export-page.component.scss'],
	providers: [Themed],
})
export class ShareExportPageComponent implements OnInit {
	private loading: boolean = false;
	private exportKey: string;
	private exportData: ExportResponse;
	private subscription: Subscription;
	private ExportStatusType = ExportStatusType;

	public timeDifference: number;
	public secondsRemaining: number | string;
	public minutesRemaining: number | string;
	public hoursRemaining: number | string;
	public daysRemaining: number;
	public error: boolean = false;
	public error_msg: string;
	public environment: Environment = environment;
	public showCopiedText: boolean = false;
	public theme: string = 'klippa';

	constructor(
		private route: ActivatedRoute,
		private translate: TranslateService,
		private http: HttpClient,
		private clipboard: Clipboard,
		private notifications: APINotificationsService,
		private themed: Themed,
	) {
		this.theme = themed.getTheme();
	}

	ngOnInit(): void {
		this.setExportKey(this.route.snapshot.params.exportId);
		this.loadExport();
		this.subscription = interval(1000).subscribe((x) => {
			this.setExportExpirationTime();
		});
	}

	ngOnDestroy(): void {
		this.subscription.unsubscribe();
	}

	private setExportKey(key: string): void {
		this.exportKey = key;
	}

	private loadExport(): void {
		this.loading = true;
		this.error = false;
		firstValueFrom(this.http.get(`${environment.api_url}/api/v1/export/${this.exportKey}`))
			.then((response) => {
				this.setExportData((response as any).data);
				return response;
			})
			.catch((e) => {
				this.error = true;
				this.error_msg = e.error.message;
				this.notifications.handleAPIError(e);
			})
			.then((res) => {
				this.loading = false;
				return res;
			});
	}

	private setExportData(exportData: ExportResponse): void {
		this.exportData = exportData;
		if (this.isFinished()) {
			this.downloadExport();
			this.setExportExpirationTime();
		} else if (this.isInProgress() || this.isInQueue()) {
			setTimeout(() => {
				this.loadExport();
			}, 5000);
		}
	}

	public showLoading(): boolean {
		return this.loading || this.isInQueue() || this.isInProgress();
	}

	public showCountdown(): boolean {
		return isValueSet(this.exportData) && this.getExportExpirationDate() > new Date();
	}

	public isInQueue(): boolean {
		return this.exportData?.status === ExportStatusType.IN_QUEUE;
	}

	public isInProgress(): boolean {
		return this.exportData?.status === ExportStatusType.IN_PROGRESS;
	}

	public errorOccurred(): boolean {
		return this.exportData?.status === ExportStatusType.ERROR;
	}

	public isFinished(): boolean {
		return this.exportData?.status === ExportStatusType.FINISHED;
	}

	private getDownloadLink(): string {
		return `${environment.api_url}/api/v1/export/${this.exportKey}/download`;
	}

	public copiedDownloadLink(): void {
		this.clipboard.copy(this.getDownloadLink());
		this.showCopiedText = true;

		setTimeout(() => {
			this.showCopiedText = false;
		}, 1200);
	}

	public downloadExport(): void {
		document.location.replace(this.getDownloadLink());
	}

	private getExportExpirationDate(): Date {
		return new Date(this.exportData?.expire * 1000);
	}

	private setExportExpirationTime(): void {
		this.timeDifference = this.getExportExpirationDate().getTime() - new Date().getTime();
		this.allocateTimeUnits(this.timeDifference);
	}

	private allocateTimeUnits(timeDifference: number): void {
		const milliSecondsInASecond: number = 1000;
		const secondsInAMinute: number = 60;
		const minutesInAnHour: number = 60;
		const hoursInADay: number = 24;

		this.secondsRemaining = this.setDoubleDigits(Math.floor((timeDifference / milliSecondsInASecond) % secondsInAMinute));
		this.minutesRemaining = this.setDoubleDigits(
			Math.floor((timeDifference / (milliSecondsInASecond * minutesInAnHour)) % secondsInAMinute),
		);
		this.hoursRemaining = this.setDoubleDigits(
			Math.floor((timeDifference / (milliSecondsInASecond * minutesInAnHour * secondsInAMinute)) % hoursInADay),
		);
		this.daysRemaining = Math.floor(timeDifference / (milliSecondsInASecond * minutesInAnHour * secondsInAMinute * hoursInADay));
	}

	public moreThanOneDayRemaining(): boolean {
		return this.daysRemaining > 0 && this.daysRemaining > 1;
	}

	public oneDayRemaining(): boolean {
		return this.daysRemaining > 0 && this.daysRemaining === 1;
	}

	private setDoubleDigits(number: number): string | number {
		if (number < 10) {
			return `0${number}`;
		}
		return number;
	}

	public hasItunesLink(): boolean {
		return stringIsSetAndFilled(this.environment.share_page.itunes_link);
	}

	public hasGooglePlayLink(): boolean {
		return stringIsSetAndFilled(this.environment.share_page.google_play_link);
	}

	public hasFacebookPage(): boolean {
		return stringIsSetAndFilled(this.environment.share_page.facebook);
	}

	public hasTwitterPage(): boolean {
		return stringIsSetAndFilled(this.environment.share_page.twitter);
	}

	public hasLinkedinPage(): boolean {
		return stringIsSetAndFilled(this.environment.share_page.linkedin);
	}

	public hasGetInTouchInfo(): boolean {
		return (
			stringIsSetAndFilled(this.environment.share_page.title) &&
			stringIsSetAndFilled(this.environment.share_page.contact_us) &&
			stringIsSetAndFilled(this.environment.share_page.website)
		);
	}

	public hasSupportContactInfo(): boolean {
		return (
			stringIsSetAndFilled(this.environment.support_email) &&
			stringIsSetAndFilled(this.environment.share_page.knowledge_base) &&
			stringIsSetAndFilled(this.environment.share_page.support_phone)
		);
	}

	public hasAnySocialMedia(): boolean {
		return this.hasSupportContactInfo() || this.hasFacebookPage() || this.hasLinkedinPage() || this.hasTwitterPage();
	}
}
