import { Injectable } from '@angular/core';
import { APIService } from '~/app/api/services/api.service';
import { UserService } from '~/app/modules/user/user.service';
import { Order } from '#/models/utils/order';
import { TransportationType } from '#/models/transaction/transportationType';
import { filterUnsetValues, objectToQueryParamString } from '#/util/objects';
import { RegistrationApiModel } from '#/models/transaction/registration/apiModel';
import { APINotificationsService } from '~/app/api/services/apinotifications.service';
import { memoizeFull } from '#/util/functions';
import { ItemsWithHasMoreResultsPromise } from '#/models/appSelectOption.model';
import { RegistrationType } from '#/models/transaction/registrationType';
import { arrayToQueryParamString } from '#/util/arrays';

export enum RegistrationsUrlType {
	COMPANY_REGISTRATIONS,
	PERSONAL_REGISTRATIONS,
}

export class RegistrationsFilters {
	type: string;
	user: string;
	fromDate: string;
	toDate: string;
	transportationTypes: Array<string>;
	administrations: Array<string>;
	categories: Array<string>;
	registrationTypes: Array<string>;

	constructor(data: Record<string, any> = {}) {
		this.type = data.type;
		this.user = data.user;
		this.fromDate = data.fromDate;
		this.toDate = data.toDate;
		this.transportationTypes = data.transportationTypes;
		this.administrations = data.administrations;
		this.categories = data.categories;
		this.registrationTypes = data.registrationTypes;
	}
}

@Injectable({
	providedIn: 'root',
})
export class RegistrationService {
	private registrationCache = new Map<string, { timestamp: number; data: Promise<RegistrationApiModel> }>();
	constructor(private userService: UserService, private apiService: APIService, private notificationService: APINotificationsService) {}

	public async createCompanyMobilityRegistration(registrationApiModel: RegistrationApiModel): Promise<RegistrationApiModel> {
		const companyId = this.userService.getCurrentLoggedUser().company;
		return this.apiService
			.postToApi(`company/${companyId}/registrations`, registrationApiModel)
			.then((res): RegistrationApiModel => {
				return new RegistrationApiModel(res['data']);
			})
			.catch((r) => {
				this.notificationService.handleAPIError(r);
				throw r;
			});
	}

	public async updateCompanyMobilityRegistration(registrationApiModel: RegistrationApiModel, id: string): Promise<RegistrationApiModel> {
		const companyId = this.userService.getCurrentLoggedUser().company;
		return this.apiService
			.patchToApi(`company/${companyId}/registrations/${id}`, registrationApiModel)
			.then((res): RegistrationApiModel => {
				return new RegistrationApiModel(res['data']);
			})
			.catch((r) => {
				this.notificationService.handleAPIError(r);
				throw r;
			});
	}

	public async createPersonalMobilityRegistration(registrationApiModel: RegistrationApiModel): Promise<RegistrationApiModel> {
		return this.apiService
			.postToApi(`registrations`, registrationApiModel)
			.then((res): RegistrationApiModel => {
				return new RegistrationApiModel(res['data']);
			})
			.catch((r) => {
				this.notificationService.handleAPIError(r);
				throw r;
			});
	}

	public async updatePersonalMobilityRegistration(registrationApiModel: RegistrationApiModel, id: string): Promise<RegistrationApiModel> {
		return this.apiService
			.patchToApi(`registrations/${id}`, registrationApiModel)
			.then((res): RegistrationApiModel => {
				return new RegistrationApiModel(res['data']);
			})
			.catch((r) => {
				this.notificationService.handleAPIError(r);
				throw r;
			});
	}

	private async getMobilityRegistrations(
		urlType: RegistrationsUrlType,
		start: number,
		search: string,
		itemsPerPage: number,
		sortField: string,
		sortOrder: string,
		filters?: RegistrationsFilters,
	): Promise<{ count: number; moreResults: boolean; results: Array<RegistrationApiModel> }> {
		const queryParams = objectToQueryParamString(
			filterUnsetValues({
				start,
				search,
				max: itemsPerPage,
				sort: sortField,
				sortorder: sortOrder.toLowerCase(),
				type: filters?.type,
				user: filters?.user,
				fromDate: filters?.fromDate,
				toDate: filters?.toDate,
				transportationTypes: arrayToQueryParamString(filters?.transportationTypes),
				administrations: arrayToQueryParamString(filters?.administrations),
				categories: arrayToQueryParamString(filters?.categories),
				registrationTypes: arrayToQueryParamString(filters?.registrationTypes),
			}),
		);

		let url: string;
		if (urlType === RegistrationsUrlType.COMPANY_REGISTRATIONS) {
			const companyId = this.userService.getCurrentLoggedUser().company;
			url = `company/${companyId}/registrations`;
		} else {
			url = 'registrations';
		}
		return this.apiService
			.getFromApi(url + '?' + queryParams)
			.then((res) => res.data)
			.then((res) => {
				const result = {
					count: res.count,
					moreResults: res.moreresults,
					results: res.results.map((e) => new RegistrationApiModel(e)),
				};
				result.results.forEach((e) => {
					this.registrationCache.set(e.id, { timestamp: new Date().getTime(), data: Promise.resolve(e) });
				});
				return result;
			})
			.catch((r) => {
				this.notificationService.handleAPIError(r);
				throw r;
			});
	}

	public async getCompanyMobilityRegistrations(
		start: number,
		search: string,
		itemsPerPage: number,
		sortField: string,
		sortOrder: string,
		filters: RegistrationsFilters,
	): Promise<{ count: number; moreResults: boolean; results: Array<RegistrationApiModel> }> {
		return this.getMobilityRegistrations(
			RegistrationsUrlType.COMPANY_REGISTRATIONS,
			start,
			search,
			itemsPerPage,
			sortField,
			sortOrder,
			filters,
		);
	}

	public async getPersonalMobilityRegistrations(
		start: number,
		search: string,
		itemsPerPage: number,
		sortField: string,
		sortOrder: string,
		filters?: RegistrationsFilters,
	): Promise<{ count: number; moreResults: boolean; results: Array<RegistrationApiModel> }> {
		return this.getMobilityRegistrations(
			RegistrationsUrlType.PERSONAL_REGISTRATIONS,
			start,
			search,
			itemsPerPage,
			sortField,
			sortOrder,
			filters,
		);
	}

	public async getPersonalMobilityRegistrationById(id: string, fromCacheIfNewerThanMsAgo: number = 0): Promise<RegistrationApiModel> {
		if (fromCacheIfNewerThanMsAgo > 0) {
			const cached = this.registrationCache.get(id);
			if (cached && cached.timestamp + fromCacheIfNewerThanMsAgo > new Date().getTime()) {
				return cached.data;
			}
		}
		const promise = this.apiService
			.getFromApi(`registrations/${id}`)
			.then((res): RegistrationApiModel => {
				return new RegistrationApiModel(res['data']);
			})
			.catch((r) => {
				this.registrationCache.delete(id);
				this.notificationService.handleAPIError(r);
				throw r;
			});
		this.registrationCache.set(id, { timestamp: new Date().getTime(), data: promise });
		return promise;
	}

	public async getCompanyMobilityRegistrationById(id: string, fromCacheIfNewerThanMsAgo: number = 0): Promise<RegistrationApiModel> {
		if (fromCacheIfNewerThanMsAgo > 0) {
			const cached = this.registrationCache.get(id);
			if (cached && cached.timestamp + fromCacheIfNewerThanMsAgo > new Date().getTime()) {
				return cached.data;
			}
		}
		const companyId = this.userService.getCurrentLoggedUser().company;
		const promise = this.apiService
			.getFromApi(`company/${companyId}/registrations/${id}`)
			.then((res): RegistrationApiModel => {
				return new RegistrationApiModel(res['data']);
			})
			.catch((r) => {
				this.registrationCache.delete(id);
				this.notificationService.handleAPIError(r);
				throw r;
			});
		this.registrationCache.set(id, { timestamp: new Date().getTime(), data: promise });
		return promise;
	}

	public async getTransportationTypes(
		start: number = 0,
		search: string = '',
		max: number = 100,
		sort: string = 'id',
		sortOrder: Order = Order.ASCENDING,
	): Promise<any> {
		const queryParams = { start, search, max, sort, sortOrder };
		const transportationTypes = await this.apiService.getFromApi(
			`company/${this.userService.getCurrentLoggedUser().company}/transportationTypes?${objectToQueryParamString(queryParams)}`,
		);

		return transportationTypes;
	}

	/* tslint:disable:member-ordering */
	public getTransportationTypesById = memoizeFull(async (ids: Array<string>): Promise<Array<TransportationType>> => {
		const body = { ids };
		const transportationTypes = await this.apiService.postToApi(
			`company/${this.userService.getCurrentLoggedUser().company}/transportationTypes/list`,
			body,
		);
		return transportationTypes.data.results;
	});

	public deleteCompanyRegistration(id: string): Promise<void> {
		const companyId = this.userService.getCurrentLoggedUser().company;
		return this.apiService.deleteToApi(`company/${companyId}/registrations/${id}`);
	}

	public deletePersonalRegistration(id: string): Promise<void> {
		return this.apiService.deleteToApi(`registrations/${id}`);
	}

	public async getRegistrationTypes(
		start: number = 0,
		search: string = '',
		max: number = 100,
		sort: string = 'id',
		sortOrder: Order = Order.ASCENDING,
	): Promise<ItemsWithHasMoreResultsPromise<RegistrationType>> {
		const queryParams = { start, search, max, sort, sortOrder };
		const result = await this.apiService.getFromApi(
			`company/${this.userService.getCurrentLoggedUser().company}/registrationTypes?${objectToQueryParamString(queryParams)}`,
		);

		return {
			hasMoreResults: result.data.moreResults,
			items: result.data.results,
		};
	}

	/* tslint:disable:member-ordering */
	public getRegistrationTypesById = memoizeFull(async (ids: Array<string>): Promise<Array<RegistrationType>> => {
		const body = { ids };
		const registrationTypes = await this.apiService.postToApi(
			`company/${this.userService.getCurrentLoggedUser().company}/registrationTypes/list`,
			body,
		);
		return registrationTypes.data.results;
	});
}
