export const importReceiptModalInBackground = () => {
	let href = window.location.href;
	setTimeout(() => {
		// Compair the href here
		// If the user click to another route we don't want to lazy load this anymore
		// If the new route has a receipt modal it will re-call this function
		if (href === window.location.href) {
			import('./receipt-edit-modal/receipt-edit-modal.component');
		}
	}, 4000);
};
