import { Component, ElementRef, EventEmitter, Input, OnDestroy, Output, ViewChild } from '@angular/core';
import { TagStat } from '~/app/pages/authenticated/statistics/models/stats';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { TagsEditModalComponent } from '../tags-edit-modal/tags-edit-modal.component';
import { ModalService } from '~/app/services/modal.service';
import { Company } from '#/models/company/company.model';
import { User } from '#/models/user/user.model';
import { ReceiptService } from '#/services/transaction/receipt.service';
import { UserService } from '~/app/modules/user/user.service';
import { isValueSet } from '#/util/values';

@Component({
	selector: '[app-tags-table-row]',
	templateUrl: './tags-table-row.component.html',
})
export class TagsTableRowComponent implements OnDestroy {
	@Input() tag: TagStat;
	@Input() checkboxes: {};
	@Input() company: Company;
	@Input() user: User;
	@Output() checkboxClick = new EventEmitter<any>();
	@Output() openExport = new EventEmitter<string>();

	@ViewChild('exportModule') exportModule: ElementRef;
	exportPromise: Promise<string>;

	protected destroyCallbacks = [];

	constructor(private modalService: ModalService, private receiptAPIService: ReceiptService, private userService: UserService) {}

	ngOnDestroy(): void {
		this.destroyCallbacks.forEach((cb) => cb());
	}

	public rowActionEdit(event): boolean {
		const modalRef = this.modalService.open(TagsEditModalComponent);
		modalRef.componentInstance.tag = this.tag;
		this.setupTagsModal(modalRef);
		return false;
	}

	public async rowActionDelete(event): Promise<boolean> {
		const { DeleteModalComponent } = await import('~/app/modules/generic/components/modals/delete-modal/delete-modal.component');

		const modalRef = this.modalService.open(DeleteModalComponent);
		modalRef.componentInstance.type = 'tag';
		this.setupTagsModal(modalRef);
		return false;
	}

	public async rowActionRetractExpenses(event): Promise<boolean> {
		const { ExpensesModalComponent } = await import('~/app/modules/generic/components/modals/expenses-modal/expenses-modal.component');

		const modalRef = this.modalService.open(ExpensesModalComponent);
		modalRef.componentInstance.type = 'tag';
		modalRef.componentInstance.action = 'retract';
		this.setupTagsModal(modalRef);
		return false;
	}

	public async rowActionSubmitExpenses(event): Promise<boolean> {
		const { ExpensesModalComponent } = await import('~/app/modules/generic/components/modals/expenses-modal/expenses-modal.component');

		const modalRef = this.modalService.open(ExpensesModalComponent);
		modalRef.componentInstance.type = 'tag';
		modalRef.componentInstance.action = 'submit';
		this.setupTagsModal(modalRef);
		return false;
	}

	private setupTagsModal(modalRef: NgbModalRef): void {
		modalRef.componentInstance.checkboxes = {};
		modalRef.componentInstance.checkboxes[this.tag.id] = true;
	}

	public checkBoxClicked($event): void {
		this.checkboxClick.emit({
			event: $event,
			id: this.tag.id,
		});
	}

	public userIsConnectedToCompany(): boolean {
		return isValueSet(this.company) && this.userService.isUserConnectedToCurrentCompany(this.company?.id);
	}
}
