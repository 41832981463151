import { CompanyObjectListAPIRequest } from '#/models/company/company-object-api-request.model';
import { DimensionSettings } from './dimension/dimenstionSettings.model';

export class Administration {
	id: string;
	code: string;
	company: string;
	name: string;
	description: string;
	groups: string[];
	is_default: boolean;
	active: boolean;
	issynced: boolean;
	provider: string;
	remote_id: string;
	createdate: Date;
	updatedate: Date;
	currency?: string;
	leading_dimension: 'costcenter' | 'costunit' | 'project' | null;
	dimension_settings: DimensionSettings;
	pickable: boolean;

	static fromData(data): Administration[] {
		if (data == null) {
			return [];
		}
		return data.map((item) => new Administration(item));
	}

	constructor(data = null) {
		if (data) {
			Object.assign(this, data);
			if (data.createdate) {
				this.createdate = Date.parse(data.createdate) > 0 ? new Date(data.createdate) : null;
			}
			if (data.updatedate) {
				this.updatedate = Date.parse(data.updatedate) > 0 ? new Date(data.updatedate) : null;
			}
		}
	}

	getGroups(): string[] {
		if (this.groups) {
			return this.groups;
		}
		return [];
	}

	getID() {
		return this.id;
	}

	get Label() {
		return this.getTitle(true);
	}

	get Name() {
		return this.name;
	}

	get Code() {
		return this.code;
	}

	get Title() {
		return (this.code && this.code + ' - ') + this.name;
	}

	getTitle(showCode = true) {
		if (showCode) {
			return (this.code && this.code + ' - ') + this.name;
		}
		return this.name;
	}

	clone(): Administration {
		return new Administration(JSON.parse(JSON.stringify(this)));
	}
}

export class CompanyAdministrationData {
	administrations: Administration[] = [];
	count: number;
	moreresults: boolean;

	constructor(data = null) {
		if (data) {
			Object.assign(this, data);

			if (data.administrations) {
				this.administrations = Administration.fromData(data.administrations);
			}
		}
	}
}

export class CompanyAdministrationListAPIRequest extends CompanyObjectListAPIRequest {
	getURL(): string {
		if (this.list) {
			if (this.company && this.company !== '') {
				return `/api/v1/company/${this.company}/administration/list`;
			}
			return `/api/v1/administration/list`;
		}
		if (this.company && this.company !== '') {
			return `/api/v1/company/${this.company}/administration`;
		}
		return `/api/v1/administration`;
	}
}
