<div class="componentContainer">
	<app-generic-dynamic-options-picker
		*ngIf="parameters.uiType === UI_TYPE.SELECT"
		[(ngModel)]="innerValue"
		(ngModelChange)="setInnerValueAndNotify($event)"
		[multiple]="false"
		[disabled]="disabled"
		[fetchItemsFn]="parameters.fetchItemsFn"
		[fetchSelectedItemsFn]="parameters.fetchSelectedItemsFn"
		[inErrorState]="isInErrorState() || inErrorState"
		[dropdownPosition]="dropdownPosition"
		[truncateOptions]="truncateOptions"
		[getTailTplFn]="getTailTpl"
		(onOpened)="onPickerOpened.emit()"
		(onClosed)="onPickerClosed.emit()"
	>
		<ng-container *ngIf="customOptionTpl">
			<ng-template klpSelectOptionTpl let-item="item">
				<ng-container [ngTemplateOutlet]="customOptionTpl" [ngTemplateOutletContext]="{ item: item }"> </ng-container>
			</ng-template>
		</ng-container>
	</app-generic-dynamic-options-picker>

	<app-generic-dynamic-options-picker
		*ngIf="parameters.uiType === UI_TYPE.MULTI_SELECT"
		[(ngModel)]="innerValue"
		(ngModelChange)="setInnerValueAndNotify($event)"
		[multiple]="true"
		[disabled]="disabled"
		[fetchItemsFn]="parameters.fetchItemsFn"
		[fetchSelectedItemsFn]="parameters.fetchSelectedItemsFn"
		[inErrorState]="isInErrorState() || inErrorState"
		[dropdownPosition]="dropdownPosition"
		[truncateOptions]="truncateOptions"
		[getTailTplFn]="getTailTpl"
		(onOpened)="onPickerOpened.emit()"
		(onClosed)="onPickerClosed.emit()"
	>
		<ng-container *ngIf="customOptionTpl">
			<ng-template klpSelectOptionTpl let-item="item">
				<ng-container [ngTemplateOutlet]="customOptionTpl" [ngTemplateOutletContext]="{ item: item }"> </ng-container>
			</ng-template>
		</ng-container>
	</app-generic-dynamic-options-picker>

	<klp-form-text-input
		*ngIf="parameters.uiType === UI_TYPE.TEXT"
		[disabled]="disabled"
		[(ngModel)]="innerValue"
		(ngModelChange)="setInnerValueAndNotify($event)"
		[inErrorState]="isInErrorState() || inErrorState"
		[getTailTplFn]="getTailTpl"
	></klp-form-text-input>

	<app-amount-with-currency-input
		*ngIf="parameters.uiType === UI_TYPE.MONEY"
		[disabled]="disabled"
		[(ngModel)]="innerValue"
		(ngModelChange)="setInnerValueAndNotify($event)"
		[inErrorState]="isInErrorState() || inErrorState"
		[hideCurrencyPicker]="!parameters.showCurrencyPicker"
		[getTailTplFn]="getTailTpl"
	></app-amount-with-currency-input>

	<klp-form-date-picker
		*ngIf="parameters.uiType === UI_TYPE.DATE"
		[disabled]="disabled"
		[(ngModel)]="innerValue"
		(ngModelChange)="setInnerValueAndNotify($event)"
		[inErrorState]="isInErrorState() || inErrorState"
		[getTailTplFn]="getTailTpl"
	></klp-form-date-picker>

	<klp-form-number-input
		*ngIf="parameters.uiType === UI_TYPE.NUMBER"
		[disabled]="disabled"
		[parseNumber]="true"
		[(ngModel)]="innerValue"
		(ngModelChange)="setInnerValueAndNotify($event)"
		[inErrorState]="isInErrorState() || inErrorState"
		[getTailTplFn]="getTailTpl"
	></klp-form-number-input>

	<klp-form-toggle
		*ngIf="parameters.uiType === UI_TYPE.BOOLEAN"
		[disabled]="disabled"
		[(ngModel)]="innerValue"
		(ngModelChange)="setInnerValueAndNotify($event)"
		[inErrorState]="isInErrorState() || inErrorState"
		[getTailTplFn]="getTailTpl"
	></klp-form-toggle>

	<klp-form-password-field
		*ngIf="parameters.uiType === UI_TYPE.SECRET"
		[disabled]="disabled"
		[(ngModel)]="innerValue"
		(ngModelChange)="setInnerValueAndNotify($event)"
		[inErrorState]="isInErrorState() || inErrorState"
		[getTailTplFn]="getTailTpl"
	></klp-form-password-field>
	<app-button
		class="createButton"
		*ngIf="sideButton"
		variant="icon"
		[hasPadding]="false"
		[hasBorder]="false"
		[fullWidth]="true"
		(click)="onSideButtonClicked.emit()"
	>
		<app-icon [variant]="sideButton"></app-icon>
	</app-button>
</div>
