import { ApplicationRef, enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import dateTimePicker from './app/shared/ui/datetime-picker/bootstrap-datetimepicker';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { enableDebugTools } from '@angular/platform-browser';

if (environment.production) {
	enableProdMode();
}

dateTimePicker();

platformBrowserDynamic()
	.bootstrapModule(AppModule)
	.catch((err) => console.log(err))
	.then((mod) => {
		if (!environment.production) {
			// @ts-ignore
			const applicationRef = mod.injector.get(ApplicationRef);
			const appComponent = applicationRef.components[0];
			enableDebugTools(appComponent);
		}
	});
