import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Action } from '@ngrx/store/src/models';
import { Observable } from 'rxjs';
import { AppState } from '~/app/reducers';

@Injectable({
	providedIn: 'root',
})
export class StoreService {
	constructor(private store: Store<AppState>) {}

	public select(mapFn): Observable<any> {
		return this.store.select(mapFn);
	}

	public dispatch(action: Action) {
		this.store.dispatch(action);
	}
}
