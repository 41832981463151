import { Component, Input, OnInit } from '@angular/core';
import { CreateCustomRelationCapabilityField, CustomRelationFieldShow, CustomRelationFieldType } from '#/models/company/company.model';
import { CompanyPickerComponent } from '../../company-picker.component';
import { TranslateService } from '@ngx-translate/core';
import { minimumStyle } from '~/app/util/component';
import { CompanyIntegrationService } from '#/services/company/company-integration.service';
import { isValueSet } from '#/util/values';
import { arrayIsSetAndFilled } from '#/util/arrays';
import { CompanyService } from '#/services/company/company.service';

@Component({
	selector: 'app-integration-custom-relation-field',
	templateUrl: './integration-custom-relation-field.component.html',
	styleUrls: ['integration-custom-relation-field.component.scss'],
	styles: minimumStyle,
})
export class IntegrationCustomRelationField extends CompanyPickerComponent implements OnInit {
	@Input() public field: CreateCustomRelationCapabilityField;
	@Input() public isOrg: boolean;
	@Input() public integration: string;
	@Input() public division: string;
	@Input() public newCustomRelationFieldName: string;

	public dropDownInfo: { [key: string]: { Key: string; Name: string }[] } = {};
	constructor(
		public translate: TranslateService,
		protected companyIntegrationService: CompanyIntegrationService,
		private companyService: CompanyService,
	) {
		super();
	}

	public ngOnInit(): void {
		super.ngOnInit();
		if (isValueSet(this.field)) {
			for (const field of this.fields) {
				if (!this.isGroup(field)) {
					field.Value = '';
					if (field.Type === CustomRelationFieldType.Dropdown) {
						this.getDropdownOptions(field);
					}

					if (field.Key === 'Name' && isValueSet(this.newCustomRelationFieldName)) {
						field.Value = this.newCustomRelationFieldName;
					}
				}
			}
		}
	}

	public get fields(): CreateCustomRelationCapabilityField[] {
		return arrayIsSetAndFilled(this.field.Group) ? this.field.Group : this.field.GroupHorizontal;
	}

	public get isHorizontal(): boolean {
		return !arrayIsSetAndFilled(this.field.Group) && arrayIsSetAndFilled(this.field.GroupHorizontal);
	}

	public get containerCSS(): Record<string, string> {
		return this.isHorizontal ? { display: 'flex', 'align-items': 'baseline', 'margin-bottom': '1rem' } : {};
	}

	public canShow(field: CreateCustomRelationCapabilityField): boolean {
		return (
			!field.Show ||
			(field.Show === CustomRelationFieldShow.Person && !this.isOrg) ||
			(field.Show === CustomRelationFieldShow.Org && this.isOrg)
		);
	}

	public isGroup(field: CreateCustomRelationCapabilityField): boolean {
		return arrayIsSetAndFilled(field.Group) || arrayIsSetAndFilled(field.GroupHorizontal);
	}

	public isDropdown(field: CreateCustomRelationCapabilityField): boolean {
		return field.Type === CustomRelationFieldType.Dropdown;
	}

	public isValid(field: CreateCustomRelationCapabilityField): boolean {
		return field.isValid();
	}

	private async getDropdownOptions(field: CreateCustomRelationCapabilityField): Promise<void> {
		const company = this.companyService.getCompanyOfLoggedUser();

		try {
			const res = await this.companyIntegrationService.getIntegrationRelationDropdownOptions(
				company,
				this.integration,
				this.division,
				field.Key,
			);

			if (!isValueSet(res.Options)) {
				throw new Error('Options failed to load');
			}

			this.dropDownInfo[field.Key] = res.Options;

			if (res.Selected) {
				field.Value = res.Selected;
			}
		} catch (error) {
			this.dropDownInfo[field.Key] = [];
		}
	}
}
