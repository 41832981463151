import { frontendToApi } from './transformer';
import { RegistrationApiModel } from '#/models/transaction/registration/apiModel';
import { RegistrationUnderlyingTypeModel } from '#/models/transaction/registrationUnderlyingType.model';
import { AmountWithCurrency } from '#/models/transaction/amountWithCurrency';

export class RegistrationFrontendModel {
	id?: string;
	description?: string;
	administration?: string;
	amount: AmountWithCurrency;
	routing?: {
		route: string;
		googleMapsRouteDistanceMeters?: number;
		locations: Array<{
			title: string;
			address: string;
			lat?: number;
			lng?: number;
		}>;
	};
	category?: string;
	dates?: Array<string>; // YMD
	registrationDate: string; // YMD
	transportationType?: string;
	transactionInterface: string;
	user?: string;
	// this type is necessary to differentiate registrations from travel expenses on registrations dashboards
	underlyingType: RegistrationUnderlyingTypeModel;
	distanceTotalInKM: number;

	constructor(data?: any) {
		Object.assign(this, data);
	}

	public transformToApi(): RegistrationApiModel {
		return frontendToApi(this);
	}
}
