export enum ActionsMenuActionEnum {
	add_receipt = 'add_receipt',
	add_receipt_with_preset = 'add_receipt_with_preset',
	travel_expense = 'travel_expense',
	document_splitter = 'document_splitter',
	bulk_upload = 'bulk_upload',
	edit = 'edit',
	exclude = 'exclude',
	delete = 'delete',
	duplicate = 'duplicate',
	split = 'split',
	submit = 'submit',
	export = 'export',
}

export interface ActionsMenuInterface {
	title: string;
	action: ActionsMenuActionEnum;
	context?: any;
}
