import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { NgbDateAdapter, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgbDateStringAdapter } from '../../../util/date';
import { NgSelectModule } from '@ng-select/ng-select';
import { UiModule } from '../../../shared/ui/ui.module';
import { AccountComponent } from './account/account.component';
import { AgreementsComponent } from './agreements/agreements.component';
import { ProComponent } from './pro/pro.component';
import { IdealConfirmComponent } from './pro/components/ideal-confirm/ideal-confirm.component';
import { SubscriptionHistoryComponent } from './pro/components/subscription-history/subscription-history.component';
import { EmailChangeConfirmOldComponent } from './email-change/email-change-confirm-old/email-change-confirm-old.component';
import { EmailChangeConfirmNewComponent } from './email-change/email-change-confirm-new/email-change-confirm-new.component';
import { CompanyInviteConfirmComponent } from './company-invite-confirm/company-invite-confirm.component';
import { StripeCheckoutConfirmComponent } from '~/app/pages/authenticated/settings/pro/components/stripe-checkout-confirm/stripe-checkout-confirm.component';
import { StripeUpdateConfirmComponent } from '~/app/pages/authenticated/settings/pro/components/stripe-update-confirm/stripe-update-confirm.component';
import { PipesModule } from '~/app/helpers/pipes/pipes.module';
import { FeaturesComponent } from './general-page/features/features.component';
import { ConnectedSocialNetworkComponent } from './general-page/connected-social-network/connected-social-network.component';
import { GeneralPage } from './general-page/general.page';
import { GeneralComponent } from './general-page/general/general.component';
import { ImpersonationSettingsComponent } from '~/app/pages/authenticated/settings/general-page/impersonation/impersonation-settings.component';

@NgModule({
	imports: [
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		TranslateModule,
		NgbModule,
		NgSelectModule,
		UiModule,
		PipesModule,
		RouterModule,
	],
	declarations: [
		GeneralComponent,
		GeneralPage,
		AccountComponent,
		AgreementsComponent,
		ProComponent,
		IdealConfirmComponent,
		SubscriptionHistoryComponent,
		EmailChangeConfirmOldComponent,
		EmailChangeConfirmNewComponent,
		CompanyInviteConfirmComponent,
		StripeCheckoutConfirmComponent,
		StripeUpdateConfirmComponent,
		FeaturesComponent,
		ConnectedSocialNetworkComponent,
		ImpersonationSettingsComponent,
	],
	providers: [
		{
			provide: NgbDateAdapter,
			useClass: NgbDateStringAdapter,
		},
	],
})
export class SettingsModule {}
