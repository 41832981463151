<div class="componentContainer">
	<div class="notificationContainer" [ngClass]="{ isVisible: entity.isVisible }" *ngFor="let entity of activeEntities">
		<app-toast-notification
			[category]="entity.category"
			[description]="entity.description"
			[buttons]="entity.buttons"
			(onClose)="closeInstantly(entity)"
		></app-toast-notification>
	</div>
</div>
