import { Attachment } from '#/models/transaction/attachment';
import { BookingLineApiModel } from '#/models/transaction/booking-line/apiModel';
import {
	DeclarationStatusActor,
	DeclarationStatusFlag,
	OCRStatus,
	ReceiptBookingStatus,
	ReceiptTravelDeclaration,
} from '#/models/transaction/receipt';
import { AuthorizationFlowApiModel } from '#/models/transaction/authorization-flow/apiModel';
import { TransactionFrontendModel } from '#/models/transaction/transaction/frontendModel';
import { apiToFrontend } from './transformer';
import { FinanceType } from '#/models/transaction/financeType';
import { AccountingStatus } from '#/models/accounting-integrations/accounting-integration-v2';
import { TransactionStatus } from '#/models/transaction/transactionStatus.model';

export class TransactionApiModel {
	id: string;
	user: string;
	report: string;
	noDocumentAvailable: boolean;
	transactionStatus: TransactionStatus;
	isinvoice: boolean;
	description: string;
	transaction_interface: string;
	companyadministration: string;
	companycategory: string;
	companycostcenter: string;
	companycostunit: string;
	companyproject: string;
	country: string;
	amount: number;
	tipAmount: number;
	purchaseAmount: number;
	vatitems: Array<{
		currency: string;
		amount: number;
		original_amount: number;
		percentage: number; // bug in backend, percentage is factor 100 too big
	}>;
	currency: string;
	purchase_date_ymd: string; // YYYY-MM-DD
	booking_date: Date;
	payment_condition: string;
	integration_payment_method: string;
	paymentmethod: string; // paymentmethod is for the static payment methods.
	payment_method_id: string; // payment_method_id is for the company or custom payment methods
	accountnumber: string;
	invoice_number: string;
	merchant: string;
	custommerchant: string;
	purchasedate: Date;
	dates: Array<string>; // YYYY-MM-DD
	documents: Array<Attachment>;
	ocrEnhancedFiles: Array<string>;
	duplicate_documents: Array<string>;
	images: Array<Attachment>;
	declarationstatus: {
		status: string;
	};
	authorization_flow: AuthorizationFlowApiModel;
	provider: string;
	division: string;
	journal: string;
	relation_number: string;
	dueDate: string;

	bookingstatus: ReceiptBookingStatus;
	bookings: Array<BookingLineApiModel>;
	currency_exchange: {
		exchange_rate?: number;
		from_currency?: string;
		original_amount?: number;
		is_exchanged: boolean;
		to_currency?: string;
	};
	ocr_checks: {
		merchant_bank_account_numbers: Array<string>;
	};
	needs_ocr_review: boolean;
	receipt_data_manually_validated: boolean;
	declarationstatushistory: Array<{
		by: DeclarationStatusActor;
		comment: string;
		previousstatus: DeclarationStatusFlag;
		status: DeclarationStatusFlag;
		date: Date;
	}>;
	finance_type: FinanceType;
	validated_bookings: boolean;
	type: 'travel' | null;
	ocr_status: OCRStatus;
	traveldeclaration: ReceiptTravelDeclaration;
	transportationType: string;
	compensationRules: { [k in string]: Array<string> };
	amount_of_minutes: number;
	group: string; // folderId
	tags: Array<string>;
	accounting: {
		status?: AccountingStatus;
		authorization: string;
		headers?: Record<string, any>;
		lines?: Array<Record<string, any>>;
	};
	gAccount: boolean;

	constructor(data?: any) {
		Object.assign(this, data);
		this.authorization_flow = new AuthorizationFlowApiModel(data?.authorization_flow);
	}

	public transformToFrontend(loggedUserId: string): TransactionFrontendModel {
		return apiToFrontend(this, loggedUserId, TransactionFrontendModel);
	}
}
