import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import {
	endOfDay,
	endOfMonth,
	endOfQuarter,
	endOfWeek,
	endOfYear,
	startOfDay,
	startOfMonth,
	startOfQuarter,
	startOfWeek,
	startOfYear,
	sub,
} from 'date-fns';

export enum TimeComparisonOperator {
	EQUAL_TO = '==',
	NOT_EQUAL_TO = '!=',
	BEFORE = '<',
	AFTER = '>',
	BEFORE_OR_EQUAL_TO = '<=',
	LATER_OR_EQUAL_TO = '>=',
}

export class Period {
	static ALL = [
		Period.TODAY(),
		Period.YESTERDAY(),
		Period.THIS_WEEK(),
		Period.PREVIOUS_WEEK(),
		Period.THIS_MONTH(),
		Period.PREVIOUS_MONTH(),
		Period.THIS_QUARTER(),
		Period.PREVIOUS_QUARTER(),
		Period.THIS_YEAR(),
		Period.PREVIOUS_YEAR(),
	];

	start: Date;
	end: Date;
	public label: string;

	static TODAY() {
		const d = new Date();
		return new Period(startOfDay(d), endOfDay(d), _('Today'));
	}

	static YESTERDAY() {
		const d = sub(new Date(), { days: 1 });
		return new Period(startOfDay(d), endOfDay(d), _('Yesterday'));
	}

	static THIS_WEEK() {
		const d = new Date();
		return new Period(startOfWeek(d), endOfWeek(d), _('This week'));
	}

	static PREVIOUS_WEEK() {
		const d = sub(new Date(), { weeks: 1 });
		return new Period(startOfWeek(d), endOfWeek(d), _('Previous week'));
	}

	static THIS_MONTH() {
		const d = new Date();
		return new Period(startOfMonth(d), endOfMonth(d), _('This month'));
	}

	static PREVIOUS_MONTH() {
		const d = sub(new Date(), { months: 1 });
		return new Period(startOfMonth(d), endOfMonth(d), _('Previous month'));
	}

	static THIS_QUARTER() {
		const d = new Date();
		return new Period(startOfQuarter(d), endOfQuarter(d), _('This quarter'));
	}

	static PREVIOUS_QUARTER() {
		const d = sub(new Date(), { months: 3 });
		return new Period(startOfQuarter(d), endOfQuarter(d), _('Previous quarter'));
	}

	static THIS_YEAR() {
		const d = new Date();
		return new Period(startOfYear(d), endOfYear(d), _('This year'));
	}

	static PREVIOUS_YEAR() {
		const d = sub(new Date(), { years: 1 });
		return new Period(startOfYear(d), endOfYear(d), _('Previous year'));
	}

	constructor(start, end, label) {
		this.start = start;
		this.end = end;
		this.label = label;
	}

	static fromData(data = null): Period {
		const newPeriod = new Period(null, null, '');
		if (data) {
			Object.assign(newPeriod, data);

			if (data.start) {
				newPeriod.start = Date.parse(data.start) > 0 ? new Date(data.start) : null;
			}

			if (data.end) {
				newPeriod.end = Date.parse(data.end) > 0 ? new Date(data.end) : null;
			}
		}

		return newPeriod;
	}

	getLabel() {
		return this.label;
	}

	getStart() {
		return this.start;
	}

	getEnd() {
		return this.end;
	}
}
