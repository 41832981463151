import { Action } from '@ngrx/store';
import { Update } from '@ngrx/entity';

import { Icon } from './icon';

export enum IconActionTypes {
	LOAD_ICONS = '[Icon] Load Icons',
	ADD_ICON = '[Icon] Add Icon',
	UPSERT_ICON = '[Icon] Upsert Icon',
	ADD_ICONS = '[Icon] Add Icons',
	UPSERT_ICONS = '[Icon] Upsert Icons',
	UPDATE_ICON = '[Icon] Update Icon',
	UPDATE_ICONS = '[Icon] Update Icons',
	DELETE_ICON = '[Icon] Delete Icon',
	DELETE_ICONS = '[Icon] Delete Icons',
	CLEAR_ICONS = '[Icon] Clear Icons',
}

export class LoadIcons implements Action {
	readonly type = IconActionTypes.LOAD_ICONS;

	constructor(public payload: { icons: Icon[] }) {}
}

export class AddIcon implements Action {
	readonly type = IconActionTypes.ADD_ICON;

	constructor(public payload: { icon: Icon }) {}
}

export class UpsertIcon implements Action {
	readonly type = IconActionTypes.UPSERT_ICON;

	constructor(public payload: { icon: Icon }) {}
}

export class AddIcons implements Action {
	readonly type = IconActionTypes.ADD_ICONS;

	constructor(public payload: { icons: Icon[] }) {}
}

export class UpsertIcons implements Action {
	readonly type = IconActionTypes.UPSERT_ICONS;

	constructor(public payload: { icons: Icon[] }) {}
}

export class UpdateIcon implements Action {
	readonly type = IconActionTypes.UPDATE_ICON;

	constructor(public payload: { icon: Update<Icon> }) {}
}

export class UpdateIcons implements Action {
	readonly type = IconActionTypes.UPDATE_ICONS;

	constructor(public payload: { icons: Update<Icon>[] }) {}
}

export class DeleteIcon implements Action {
	readonly type = IconActionTypes.DELETE_ICON;

	constructor(public payload: { id: string }) {}
}

export class DeleteIcons implements Action {
	readonly type = IconActionTypes.DELETE_ICONS;

	constructor(public payload: { ids: string[] }) {}
}

export class ClearIcons implements Action {
	readonly type = IconActionTypes.CLEAR_ICONS;
}

export type IconActionsUnion =
	| LoadIcons
	| AddIcon
	| UpsertIcon
	| AddIcons
	| UpsertIcons
	| UpdateIcon
	| UpdateIcons
	| DeleteIcon
	| DeleteIcons
	| ClearIcons;
