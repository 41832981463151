import { Injectable } from '@angular/core';
import { Observable, Subscriber } from 'rxjs';
import {
	NotificationConfig,
	NotificationFundamentalOptions,
	ObservableOptions,
} from '~/app/shared/ui/toast-notification/toast-notifications-manager/toast-notifications-manager.component';

@Injectable({
	providedIn: 'root',
})
export class NotificationService {
	showApi: Subscriber<NotificationFundamentalOptions>;
	hideApi: Subscriber<null>;
	registerShowApi(notificationApi: Subscriber<NotificationFundamentalOptions>) {
		this.showApi = notificationApi;
	}
	registerHideApi(notificationApi: Subscriber<null>) {
		this.hideApi = notificationApi;
	}
	show(options: NotificationFundamentalOptions) {
		this.showApi.next(options);
	}
	hideAll() {
		this.hideApi.next();
	}

	success(description: string, config: NotificationConfig = {}) {
		this.show({ category: 'success', description, ...config });
	}

	info(description: string, config: NotificationConfig = {}) {
		this.show({ category: 'info', description, ...config });
	}

	warning(description: string, config: NotificationConfig = {}) {
		this.show({ category: 'warning', description, ...config });
	}

	error(description: string, config: NotificationConfig = {}) {
		this.show({ category: 'error', description, ...config });
	}

	updatable(description: string, observableConfig: Observable<ObservableOptions>) {
		this.show({ category: 'info', description, observable: observableConfig });
	}
}
