import { ListFilterAPIRequest, QueryParam } from '#/models/listApiRequest.model';
import { UserRole } from '#/models/user/user.model';
import { UserType } from '#/services/user/partial-users.service';

export class CompanyUsersFilterAPIRequest extends ListFilterAPIRequest {
	active?: boolean;

	// The user endpoint supports pagination, but the frontend is currently doing searching and pagination.
	// When the endpoint supports searching, then we can remove frontend pagination.
	start = undefined;
	max = undefined;
	sort = undefined;
	sortorder = undefined;
	search: string;
	role: Array<UserRole>;
	userType: UserType;

	constructor(data = null) {
		super();
		if (!data) {
			return;
		}

		Object.assign(this, data);

		this.updateActiveFilters();
	}

	updateActiveFilters() {
		super.updateActiveFilters([this.active]);
	}

	reset() {
		this.active = undefined;
		this.updateActiveFilters();
	}

	getQueryParams(): QueryParam[] {
		const parts = super.getQueryParams();
		parts.push({ key: 'active', value: this.active?.toString() });
		parts.push({ key: 'search', value: this.search });
		parts.push({ key: 'role', value: this.role?.join(',') });
		parts.push({ key: 'type', value: this.userType });
		return parts;
	}
}
