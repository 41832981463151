import { RouterModule, Routes } from '@angular/router';
import { CompanyDashboardComponent } from './dm-dashboard/company-dashboard.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { OAuthAuthorizedConnectComponent } from './oauth-authorized-connect/oauth-authorized-connect.component';
import { FoldersComponent } from './folders/folders.component';
import { InboxComponent } from './inbox/inbox.component';
import { CompanyInvoicesComponent } from './company-invoices/company-invoices.component';
import { TagsComponent } from './tags/tags.component';
import { OAuthAuthorizeComponent } from './oauth-authorize/oauth-authorize.component';
import { ManageReportsRouting, ReportsRouting } from './report-declarations/report-declarations.routing';
import { SettingsRoutes } from './settings/settings-routes';
import { NgModule } from '@angular/core';
import { AuthenticatedModule } from './authenticated.module';
import {
	RouteNames,
	TranslationReplaceRouteNameResolver,
} from '~/app/modules/company/resolvers/dynamicRouteNameResolvers/translation-replace-route-name-resolver.service';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { CanAccessUiPlaygroundGuard } from '~/app/guards/can-access-ui-playground-guard.service';
import { EmptyComponent } from '~/app/emptyComponent';
import { TransactionRedirectGuard } from '~/app/guards/transaction-redirect-guard';
import { GalleryListComponent } from '~/app/pages/authenticated/gallery-list/gallery-list.component';
import { RegistrationsListComponent } from '~/app/pages/authenticated/registrations/personal/registrations-list.component';
import { ManageRegistrationsListComponent } from '~/app/pages/authenticated/registrations/manage/manage-registrations-list.component';

const routes: Routes = [
	{
		path: '',
		redirectTo: 'dashboard',
		pathMatch: 'full',
	},
	{
		path: 'tx/:txId',
		canActivate: [TransactionRedirectGuard],
		component: EmptyComponent,
	},
	{
		path: 'dashboard',
		data: { dynamicRouteName: RouteNames.DASHBOARD, dynamicRouteNameResolver: TranslationReplaceRouteNameResolver },
		children: [
			{
				path: '',
				component: DashboardComponent,
			},
			{
				path: 'tx',
				loadChildren: () => import('./transaction/transaction.module').then((m) => m.TransactionModule),
			},
			{
				path: 'folder/:folder',
				component: DashboardComponent,
			},
			{
				path: 'tag/:tag',
				component: DashboardComponent,
			},
		],
	},
	{
		path: 'saved-receipts',
		data: { dynamicRouteName: RouteNames.SAVED_RECEIPTS, dynamicRouteNameResolver: TranslationReplaceRouteNameResolver },
		children: [
			{
				path: '',
				component: GalleryListComponent,
			},
			{
				path: 'tx',
				data: { isPreTx: true },
				loadChildren: () => import('./transaction/transaction.module').then((m) => m.TransactionModule),
			},
		],
	},
	{
		path: 'registrations',
		data: {
			routeName: _('My mobility registrations'),
			isPersonalMobilityRegistration: true,
		},
		children: [
			{
				path: '',
				component: RegistrationsListComponent,
			},
			{
				path: 'tx',
				loadChildren: () => import('./transaction/transaction.module').then((m) => m.TransactionModule),
			},
		],
	},
	{
		path: 'manage-registrations',
		data: {
			routeName: _('Manage mobility registrations'),
			isCompanyMobilityRegistration: true,
		},
		children: [
			{
				path: '',
				component: ManageRegistrationsListComponent,
			},
			{
				path: 'tx',
				loadChildren: () => import('./transaction/transaction.module').then((m) => m.TransactionModule),
			},
		],
	},
	{ path: 'oauth_authorized/:provider/connect', component: OAuthAuthorizedConnectComponent },
	{
		path: 'folders',
		component: FoldersComponent,
		data: { routeName: _('Folders') },
	},
	{
		path: 'tags',
		component: TagsComponent,
		data: { routeName: _('Tags') },
	},
	{
		path: 'inbox',
		component: InboxComponent,
		data: { routeName: _('Inbox') },
	},
	{
		path: 'statistics',
		loadChildren: () => import('./statistics/statistics-routing.module').then((m) => m.StatisticsRoutes),
	},
	{
		path: 'ui-playground',
		loadChildren: () => import('./ui-test/ui-playground.module').then((m) => m.UiPlaygroundModule),
		canActivate: [CanAccessUiPlaygroundGuard],
	},
	{
		path: 'user',
		loadChildren: () => import('./settings/settings-routing.module').then((m) => m.SettingsUser),
	},
	{
		path: 'settings',
		children: SettingsRoutes,
		data: { routeName: _('Personal settings') },
	},
	{
		path: 'reports',
		data: { routeName: _('My reports') },
		children: ReportsRouting,
	},
	{
		path: 'manage-reports',
		data: { routeName: _('Manage reports') },
		children: ManageReportsRouting,
	},
	{ path: 'dm/dashboard', redirectTo: 'dm/dashboard/todo', pathMatch: 'full' },
	{
		path: 'dm/dashboard/:tab',
		data: { isManagingExpenses: true, routeName: _('Manage expenses') },
		children: [
			{
				path: '',
				component: CompanyDashboardComponent,
			},
			{
				path: 'tx',
				loadChildren: () => import('./transaction/transaction.module').then((m) => m.TransactionModule),
			},
			{
				path: 'registration',
				data: { isPersonalMobilityRegistration: true },
				loadChildren: () => import('./transaction/transaction.module').then((m) => m.TransactionModule),
			},
		],
	},
	{ path: 'dm/invoices', redirectTo: 'dm/invoices/todo', pathMatch: 'full' },
	{
		path: 'dm/invoices/:tab',
		data: { routeName: _('Manage invoices') },
		children: [
			{
				path: '',
				component: CompanyInvoicesComponent,
			},
			{
				path: 'tx',
				loadChildren: () => import('./transaction/transaction.module').then((m) => m.TransactionModule),
			},
		],
	},
	{
		path: 'dm',
		loadChildren: () => import('../../modules/company/company-routing.module').then((m) => m.CompanyRoutes),
	},
	{
		path: 'oauth',
		component: OAuthAuthorizeComponent,
	},
	{
		path: 'companies',
		loadChildren: () => import('../../modules/admin/admin.routing').then((m) => m.RoutesCompanies),
	},
	{
		path: 'icons',
		loadChildren: () => import('../../modules/admin/admin.routing').then((m) => m.RoutesIcons),
	},
	{
		path: 'merchants',
		loadChildren: () => import('../../modules/admin/admin.routing').then((m) => m.RoutesMerchants),
	},
	{
		path: 'admin',
		loadChildren: () => import('../../modules/admin/admin.routing').then((m) => m.RoutesAdmin),
	},
];

@NgModule({
	exports: [RouterModule],
	imports: [AuthenticatedModule, RouterModule.forChild(routes)],
})
export class AuthenticatedRoutes {}
