import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthenticatedComponent } from '../authenticated.component';
import { OAuthService } from '~/app/modules/oauth-app/service';
import { OAuthApp } from '~/app/modules/oauth-app/models/app';

@Component({
	selector: 'oauth-authorize',
	templateUrl: './oauth-authorize.component.html',
	styleUrls: ['./oauth-authorize.component.css'],
})
export class OAuthAuthorizeComponent extends AuthenticatedComponent implements OnInit {
	app = new OAuthApp();
	loading = true;
	loadingAuthorize = false;
	redirectsTo = '';
	error = '';
	loadingError: string;

	constructor(protected route: ActivatedRoute, private oauthService: OAuthService) {
		super();
	}

	async ngOnInit() {
		super.ngOnInit();

		const params = await this.getQueryParams();
		const clientID = params['client_id'];
		this.redirectsTo = params['redirect_uri'];
		if (!clientID) {
			this.userAPIService.getHomePage().then((homePage) => {
				this.router.navigate([homePage]);
			});
			return;
		}

		try {
			this.app = await this.oauthService.getOauthApp(clientID);
		} catch (e) {
			this.loadingError = e.message;
		} finally {
			this.loading = false;
		}
	}

	getQueryParams() {
		return new Promise((res) => {
			let query = this.route.queryParams.subscribe((params) => {
				res(params);
			});
			this.destroyCallbacks.push(() => {
				query.unsubscribe();
			});
		});
	}

	async authorize() {
		this.loadingAuthorize = true;

		try {
			const output = await this.oauthService.allowApp(location.search);
			this.notificationService.success('You are being redirected!');
			window.location = output.Location;
		} catch (e) {
			this.error = `${JSON.stringify(e, null, 2)}`;
			this.notificationService.error('App could not be authenticated!');
		} finally {
			this.loadingAuthorize = false;
		}
	}

	backToKlippa() {
		this.userAPIService.getHomePage().then((homePage) => {
			this.router.navigate([homePage]);
		});
	}
}
