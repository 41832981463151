import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { TranslateModule } from '@ngx-translate/core';
import { SortablejsModule } from 'ngx-sortablejs';
import { RouterModule } from '@angular/router';
import { IntegrationRelationPickerComponent } from '~/app/shared/ui/forms/composed/pickers/dynamic/integration-relation-picker/integration-relation-picker.component';
import { ModalModule } from '~/app/shared/ui/modal/modal.module';
import { UiModule } from '~/app/shared/ui/ui.module';
import { AddCustomIntegrationRelationModalModule } from '~/app/modules/receipt/components/modals/add-custom-integration-relation-modal/add-custom-integration-modal.module';

@NgModule({
	imports: [
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		NgSelectModule,
		TranslateModule,
		SortablejsModule,
		RouterModule,
		UiModule,
		ModalModule,
		AddCustomIntegrationRelationModalModule,
	],
	declarations: [IntegrationRelationPickerComponent],
	providers: [],
	exports: [IntegrationRelationPickerComponent],
})
export class IntegrationRelationModule {}
