import { Pipe, PipeTransform } from '@angular/core';
import { isValueSet } from '#/util/values';

@Pipe({
	name: 'isValueSet',
})
export class IsValueSet implements PipeTransform {
	transform(value: any): boolean {
		return isValueSet(value);
	}
}
