import { format } from '~/app/util/i18n';
import { parseISO } from 'date-fns';
import { Injectable } from '@angular/core';
import { NgbDateAdapter, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';

@Injectable()
export class NgbDateStringAdapter extends NgbDateAdapter<string> {
	/* NgbDateAdapter to use a date string coming from the API in the NgbDatePicker. */

	/* Parse a date string using moment and return a NgbDateStruct */
	fromModel(str: string): NgbDateStruct {
		const parsed = parseISO(str);
		if (parsed) {
			return { year: parsed.getFullYear(), month: parsed.getMonth() + 1, day: parsed.getDate() };
		}
		return null;
	}

	/* Parse a NgbDateStruct and construct a date string */
	toModel(date: NgbDateStruct): string {
		if (date) {
			return format(new Date(date.year, date.month - 1, date.day), 'yyyy-MM-dd');
		}
		return null;
	}
}
