import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ModalOptions, ModalService } from '~/app/services/modal.service';
import { PasswordMaxLength, PasswordMinLength } from '#/models/utils/generic';
import { EqualsValidator } from '~/app/helpers/validators/equals.validator';
import { UserService } from '~/app/modules/user/user.service';
import { User } from '#/models/user/user.model';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { NotificationService } from '~/app/services/notification.service';
import { APINotificationsService } from '~/app/api/services/apinotifications.service';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { ChangePasswordModalService } from '~/app/shared/ui/change-password-modal/change-password-modal.service';

@Component({
	selector: 'app-change-password-modal',
	templateUrl: './change-password-modal.component.html',
	styleUrls: ['./change-password-modal.component.scss'],
})
export class ChangePasswordModalComponent implements OnInit {
	@ViewChild('changePasswordModal') changePasswordModal: ElementRef;

	passwordMinLength = PasswordMinLength;
	passwordMaxLength = PasswordMaxLength;

	changePasswordPromise: Promise<any>;

	changePasswordForm: UntypedFormGroup = this.fb.group(
		{
			currentPassword: [null, Validators.required],
			password: [null, [Validators.required, Validators.minLength(this.passwordMinLength), Validators.maxLength(this.passwordMaxLength)]],
			password_repeat: [
				null,
				[Validators.required, Validators.minLength(this.passwordMinLength), Validators.maxLength(this.passwordMaxLength)],
			],
		},
		{
			validator: EqualsValidator.MatchPassword,
		},
	);

	showModal: Observable<ModalOptions>;

	constructor(
		private fb: UntypedFormBuilder,
		private modalService: ModalService,
		private userApi: UserService,
		private notificationService: NotificationService,
		private apiNotificationService: APINotificationsService,
		private translate: TranslateService,
		private changePasswordModalService: ChangePasswordModalService,
	) {}

	ngOnInit(): void {
		this.showModal = new Observable<ModalOptions>((subscriber) => {
			this.changePasswordModalService.registerShowModal(subscriber);
		});

		this.showModal.subscribe((options) => {
			this.show(options);
		});
	}

	changePassword = (value) => {
		const user: User = this.userApi.getCurrentLoggedUser();
		user.password = value.password;
		user.currentpassword = value.currentPassword;

		this.changePasswordPromise = this.userApi.updateUser(user);
		return this.changePasswordPromise.then(() => {
			this.notificationService.success(this.translate.instant(_('Password changed successfully')));
			this.close();
		});
	};

	get f() {
		return this.changePasswordForm.value;
	}

	show(options) {
		this.modalService.open(this.changePasswordModal, options);
	}

	close() {
		this.modalService.close(this.changePasswordModal);
		this.changePasswordForm.reset();
	}
}
