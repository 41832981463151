import { NgModule, Injector } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SortablejsModule } from 'ngx-sortablejs';
import { UiModule } from '../../shared/ui/ui.module';
import { NgSelectModule } from '@ng-select/ng-select';
import { TagsTableComponent } from './components/tags-table/tags-table.component';
import { TagsTableRowComponent } from './components/tags-table-row/tags-table-row.component';
import { TagsEditModalComponent } from './components/tags-edit-modal/tags-edit-modal.component';
import { ExportModule } from '~/app/modules/export/export.module';

@NgModule({
	imports: [CommonModule, TranslateModule, FormsModule, ReactiveFormsModule, SortablejsModule, UiModule, NgSelectModule, ExportModule],
	declarations: [TagsTableComponent, TagsTableRowComponent, TagsEditModalComponent],
	exports: [TagsTableComponent, TagsTableRowComponent, TagsEditModalComponent],
})
export class TagModule {
	constructor(private injector: Injector) {}
}
