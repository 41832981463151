import { CategoryApiModel } from '#/models/transaction/category/apiModel';
import { CategoryFrontendModel } from '#/models/transaction/category/frontendModel';
import { categoryDefaultsApiToFrontend, categoryDefaultsFrontendToApi } from '#/models/transaction/category/categoryDefaults/transformer';
import { DistanceUnit, KilometerToMileRatio } from '#/models/distance-unit';
import { stringIsSetAndFilled } from '#/util/values';
import { AAV2_PREFIX } from '#/models/transaction/transaction/transformer';
import { roundToX } from '#/util/numbers';
import { CategoryAccountingDefaults, CategoryTransactionType } from '#/models/company/category.model';
import { arrayIsSetAndFilled } from '#/util/arrays';

export function apiToFrontend(categoryApiModel: CategoryApiModel): CategoryFrontendModel {
	const categoryFrontendModel: CategoryFrontendModel = new CategoryFrontendModel();

	categoryFrontendModel.id = categoryApiModel.id;
	categoryFrontendModel.code = categoryApiModel.code;
	categoryFrontendModel.name = categoryApiModel.name;
	categoryFrontendModel.types = categoryApiModel.types;
	categoryFrontendModel.registrationType = categoryApiModel.registrationType;
	categoryFrontendModel.createDate = categoryApiModel.createdate;
	categoryFrontendModel.updateDate = categoryApiModel.updatedate;
	categoryFrontendModel.groups = categoryApiModel.groups;
	categoryFrontendModel.active = categoryApiModel.active;
	categoryFrontendModel.description = categoryApiModel.description;
	categoryFrontendModel.merchants = categoryApiModel.merchants;
	const aav2Auth =
		categoryApiModel.accountingDefaults?.expense?.authorization ?? categoryApiModel.accountingDefaults?.invoice?.authorization;
	categoryFrontendModel.authorizationId =
		categoryApiModel.defaults?.Provider ?? (stringIsSetAndFilled(aav2Auth) ? AAV2_PREFIX + aav2Auth : null);
	categoryFrontendModel.aav1Defaults = categoryDefaultsApiToFrontend(categoryApiModel.defaults);
	categoryFrontendModel.aav2Defaults = categoryApiModel.accountingDefaults;
	categoryFrontendModel.vatDeductible = categoryApiModel.vat_deductible;

	const centsPerKm = categoryApiModel?.module_leveled_travel_expense_compensation_per_km;
	categoryFrontendModel.travelExpenseCompensationPerKmInCents = { currency: null, amount: roundToX(centsPerKm, 2) };
	categoryFrontendModel.travelExpenseCompensationPerMileInCents = Number.isFinite(centsPerKm)
		? { currency: null, amount: roundToX(centsPerKm * KilometerToMileRatio.MILE_TO_KM, 2) }
		: null;
	categoryFrontendModel.fromDate = categoryApiModel.from_date;
	categoryFrontendModel.toDate = categoryApiModel.to_date;

	return categoryFrontendModel;
}

export function frontendToApi(categoryFrontendModel: CategoryFrontendModel, distanceUnit: DistanceUnit): CategoryApiModel {
	const categoryApiModel: CategoryApiModel = new CategoryApiModel();

	categoryApiModel.id = categoryFrontendModel.id;
	categoryApiModel.code = categoryFrontendModel.code;
	categoryApiModel.name = categoryFrontendModel.name;
	categoryApiModel.types = categoryFrontendModel.types;
	categoryApiModel.registrationType = categoryFrontendModel.registrationType;
	categoryApiModel.createdate = categoryFrontendModel.createDate;
	categoryApiModel.updatedate = categoryFrontendModel.updateDate;
	categoryApiModel.groups = categoryFrontendModel.groups;
	categoryApiModel.active = categoryFrontendModel.active;
	categoryApiModel.description = categoryFrontendModel.description;
	categoryApiModel.merchants = categoryFrontendModel.merchants;

	if (stringIsSetAndFilled(categoryFrontendModel.authorizationId)) {
		categoryApiModel.defaults = categoryDefaultsFrontendToApi(categoryFrontendModel.aav1Defaults);
		categoryApiModel.accountingDefaults = new CategoryAccountingDefaults(categoryFrontendModel.aav2Defaults);

		if (categoryFrontendModel.authorizationId.startsWith(AAV2_PREFIX)) {
			categoryApiModel.defaults = null;

			const authId = categoryFrontendModel.authorizationId.substring(AAV2_PREFIX.length);
			if (
				!arrayIsSetAndFilled(categoryFrontendModel.types) ||
				categoryFrontendModel.types.includes(CategoryTransactionType.RECEIPT) ||
				categoryFrontendModel.types.includes(CategoryTransactionType.FIXED) ||
				categoryFrontendModel.types.includes(CategoryTransactionType.DISTANCE) ||
				categoryFrontendModel.types.includes(CategoryTransactionType.TIME)
			) {
				categoryApiModel.accountingDefaults.expense.authorization = authId;
			} else {
				delete categoryApiModel.accountingDefaults.expense;
			}
			if (!arrayIsSetAndFilled(categoryFrontendModel.types) || categoryFrontendModel.types.includes(CategoryTransactionType.INVOICE)) {
				categoryApiModel.accountingDefaults.invoice.authorization = authId;
			} else {
				delete categoryApiModel.accountingDefaults.invoice;
			}
		} else {
			categoryApiModel.accountingDefaults = null;
			categoryApiModel.defaults.Provider = categoryFrontendModel.authorizationId;
		}
	} else if (stringIsSetAndFilled(categoryFrontendModel.aav1Defaults?.ledger)) {
		// defaults.ledger is the only value on the aav1Defaults that doesnt require a selected integration
		categoryApiModel.accountingDefaults = null;
		categoryApiModel.defaults = categoryDefaultsFrontendToApi(categoryFrontendModel.aav1Defaults);
	} else {
		categoryApiModel.accountingDefaults = null;
		categoryApiModel.defaults = null;
	}

	categoryApiModel.vat_deductible = categoryFrontendModel.vatDeductible;
	if (distanceUnit === DistanceUnit.KILOMETER) {
		categoryApiModel.module_leveled_travel_expense_compensation_per_km =
			categoryFrontendModel?.travelExpenseCompensationPerKmInCents?.amount;
	} else {
		const centsPerMile = categoryFrontendModel?.travelExpenseCompensationPerMileInCents?.amount;
		categoryApiModel.module_leveled_travel_expense_compensation_per_km = Number.isFinite(centsPerMile)
			? centsPerMile * KilometerToMileRatio.KM_TO_MILE
			: null;
	}
	categoryApiModel.from_date = categoryFrontendModel.fromDate;
	categoryApiModel.to_date = categoryFrontendModel.toDate;

	return categoryApiModel;
}
