<ng-template #loginModal>
	<div class="modal-header">
		<h4 class="modal-title" translate>Revalidate your session</h4>
		<button type="button" class="close" aria-label="Close" (click)="dismiss()">
			<span aria-hidden="true">&times;</span>
		</button>
	</div>
	<div class="modal-body">
		<ngx-async-template [promise]="authMethodPromise">
			<ng-template ngx-async-pending>
				<app-loader-deprecated></app-loader-deprecated>
			</ng-template>
			<ng-template ngx-async-success>
				<ng-container *ngIf="authMethod == 'mfa'">
					<p translate>
						This action can only be performed by an admin user. For security reasons, please revalidate your session by entering the 6 digit
						code from your authenticator app.
					</p>
					<form [formGroup]="authForm" (ngSubmit)="verifySudo()">
						<klp-form-text-input formControlName="authToken"></klp-form-text-input>
						<app-button [disabled]="!isFormValid()" variant="greenFilled" class="mrg-top-10" type="submit" translate>Login</app-button>
					</form>
				</ng-container>
				<ng-container *ngIf="authMethod == 'password'">
					<form [formGroup]="authForm" (ngSubmit)="verifySudo()">
						<p translate>This action can only be performed by an admin user. For security reasons, please revalidate your session.</p>
						<label for="password" translate>Password</label>
						<klp-form-password-field id="password" formControlName="authToken"></klp-form-password-field>
						<app-button [disabled]="!isFormValid()" variant="greenFilled" class="mrg-top-10" type="submit" translate>Validate</app-button>
					</form>
				</ng-container>
			</ng-template>
		</ngx-async-template>
		<ngx-async-template [promise]="authenticationPromise">
			<ng-template ngx-async-error let-errorMessages="errorMessages">
				<app-error-message [errorMessages]="errorMessages"></app-error-message>
			</ng-template>
		</ngx-async-template>
	</div>
</ng-template>
