import { Component, OnInit } from '@angular/core';
import { AuthenticatedComponent } from '../../../../pages/authenticated/authenticated.component';
import { SetSidePanelOpen, ToggleSidePanel } from '../../side-panel.actions';
import { initialSidePanelState, SidePanelState } from '../../side-panel.reducer';
import { User } from '#/models/user/user.model';

@Component({
	selector: 'app-side-panel',
	templateUrl: './side-panel.component.html',
})
export class SidePanelComponent extends AuthenticatedComponent implements OnInit {
	state: SidePanelState = initialSidePanelState;
	public user: User;

	constructor() {
		super();
	}

	ngOnInit(): void {
		super.ngOnInit();

		const sidePanelStoreSubscription = this.store.select('sidePanel').subscribe((val) => {
			this.state = val;
		});

		const userSubscription = this.store.select('user').subscribe((val) => {
			this.user = val.currentUser;
		});

		this.destroyCallbacks.push(() => {
			sidePanelStoreSubscription.unsubscribe();
			userSubscription.unsubscribe();
		});
	}

	open() {
		this.store.dispatch(new SetSidePanelOpen({ open: true }));
	}

	close() {
		this.store.dispatch(new SetSidePanelOpen({ open: false }));
	}

	toggle() {
		this.store.dispatch(new ToggleSidePanel());
	}
}
