import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SidePanelComponent } from './components/side-panel/side-panel.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
	imports: [CommonModule, NgbModule, NgSelectModule, TranslateModule],
	declarations: [SidePanelComponent],
	exports: [SidePanelComponent],
})
export class SidePanelModule {}
