import { Component, Input, OnChanges } from '@angular/core';
import { select } from '@ngrx/store';
import * as fromGroup from '~/app/modules/folder/models/folder.reducer';
import { Observable } from 'rxjs';
import { BaseComponent } from '~/app/base/base.component';
import { Folder } from '#/models/user/folder';

@Component({
	selector: 'app-folder-label',
	templateUrl: './folder-label.component.html',
})
export class FolderLabelComponent extends BaseComponent implements OnChanges {
	@Input() GroupID: string;
	group: Observable<Folder>;

	ngOnChanges() {
		this.group = this.store.select('group').pipe(select(fromGroup.getItemById(this.GroupID)));
	}
}
