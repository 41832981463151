import format from 'date-fns/format';

export function convertDateToYMD(dateToConvert: Date): string {
	return format(dateToConvert, 'yyyy-MM-dd');
}

export function getEarliestStringDateFromArray(arrayOfStringDates: Array<string>): string {
	return arrayOfStringDates.reduce((acc, cur) => {
		if (new Date(cur).getTime() < new Date(acc).getTime()) {
			return cur;
		} else {
			return acc;
		}
	}, arrayOfStringDates[0]);
}
