import { amountToStringPipe } from './numbers/amounttostring.modules';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FilterArrayPipe } from './arrays/filter.pipe';
import { CapitalizePipe } from './strings/capitalize.pipe';
import { ReceiptAmountPipe } from './numbers/receipt-amount.pipe';
import { RoundnumberPipe } from './numbers/roundnumber.pipe';
import { DistanceAmountPipe } from '~/app/helpers/pipes/numbers/distance-amount.pipe';
import { DateFormat } from './date/date-format.pipe';
import { TimeSince } from './time-since/time-since.pipe';
import { CustomIntegrationStatus } from './custom-integration-status/custom-integration-status.pipe';
import { ExportModuleLabelPipe } from '~/app/modules/export-templates/components/export-templates-overview/export-module-label/export-module-label.component';
import { CompanyExportTemplateNamePipe } from '~/app/helpers/pipes/custom/company-export-template-name.pipe';
import { DayOfTheWeekPipe } from '~/app/helpers/pipes/custom/day-of-the-week.pipe';
import { HourOfTheDayPipe } from '~/app/helpers/pipes/custom/hour-of-the-day.pipe';
import { ExpenseStatusPipe } from '~/app/helpers/pipes/custom/expense-status.pipe';
import { CreditCardStatusPipe } from '~/app/helpers/pipes/custom/crecitcard-status.pipe';
import { IsValueSet } from '~/app/helpers/pipes/value/isValueSet.pipe';
import { ExportFrequencyPipe } from '~/app/helpers/pipes/custom/export-frequency.pipe';
import { AmountWithCurrencyPipe } from '#/pipes/amount-with-currency.pipe';
import { ConvertToAmountWithCurrencyTypePipe } from '#/pipes/convertToAmountWithCurrencyType.pipe';

@NgModule({
	imports: [CommonModule],
	declarations: [
		FilterArrayPipe,
		CapitalizePipe,
		amountToStringPipe,
		ReceiptAmountPipe,
		RoundnumberPipe,
		DistanceAmountPipe,
		DateFormat,
		TimeSince,
		CustomIntegrationStatus,
		ExportModuleLabelPipe,
		CompanyExportTemplateNamePipe,
		DayOfTheWeekPipe,
		HourOfTheDayPipe,
		ExpenseStatusPipe,
		CreditCardStatusPipe,
		IsValueSet,
		ExportFrequencyPipe,
		AmountWithCurrencyPipe,
		ConvertToAmountWithCurrencyTypePipe,
	],
	exports: [
		FilterArrayPipe,
		CapitalizePipe,
		amountToStringPipe,
		ReceiptAmountPipe,
		RoundnumberPipe,
		DistanceAmountPipe,
		DateFormat,
		TimeSince,
		CustomIntegrationStatus,
		ExportModuleLabelPipe,
		CompanyExportTemplateNamePipe,
		DayOfTheWeekPipe,
		HourOfTheDayPipe,
		ExpenseStatusPipe,
		CreditCardStatusPipe,
		IsValueSet,
		ExportFrequencyPipe,
		AmountWithCurrencyPipe,
		ConvertToAmountWithCurrencyTypePipe,
	],
	providers: [DistanceAmountPipe, TimeSince, ExportModuleLabelPipe, DateFormat],
})
export class PipesModule {}
