<klp-form [formGroup]="wrapperForm">
	<app-modal #manualCardUploadModal>
		<ng-template app-modal-header>
			<span translate>Upload card statement</span>
		</ng-template>
		<ng-template app-modal-body>
			<klp-sub-form [injectInto]="wrapperForm" at="subform">
				<app-manual-card-upload-form></app-manual-card-upload-form>
			</klp-sub-form>
		</ng-template>
		<ng-template app-modal-footer>
			<div class="manualCardUploadModalFooter">
				<app-button variant="white" (click)="manualCardUploadModal.dismissModal()" translate>Cancel</app-button>
				<klp-form-submit-button [submitCallback]="saveManually">{{ 'Upload statement' | translate }}</klp-form-submit-button>
			</div>
		</ng-template>
	</app-modal>
</klp-form>
