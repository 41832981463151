import { Environment } from '~/environments/environment.types';
import 'angular-server-side-configuration/process';
import { deepMerge } from '#/util/objects';

const _environment: Environment = {
	production: true,
	name: 'production',
	api_url: process.env.API_URL || 'https://api.klippa.com',
	web_root: process.env.WEB_ROOT || 'https://app.klippa.com',
	websockets_uri: process.env.WEBSOCKETS_URI || 'wss://api.klippa.com/api/v1/ws',
	mail_domain: process.env.MAIL_DOMAIN || 'receipt.klippa.com',
	mail_domain_invoices: process.env.MAIL_DOMAIN_INVOICES || 'invoice.klippa.com',
	mail_domain_expenses: process.env.MAIL_DOMAIN_EXPENSES || 'expense.klippa.com',
	stripe_pk: process.env.STRIPE_PK || 'pk_live_HPygL4VOaajgAoJ54FqtnmM4',
	support_email: process.env.SUPPORT_EMAIL || 'support@klippa.com',
	google_maps_key: process.env.GOOGLE_MAPS_KEY || 'AIzaSyDqp69a2b3RY2cqAjds5STuDqOJt3yS-c4',
	raven: process.env.SENTRY_DSN || 'https://3757602ba1f94dc490a1f3a79680a74d@sentry.klippa.com/6',
	release: '',
	feature_flags: {
		custom_SSO_address: process.env.CUSTOM_SSO_ADDRESS,
		disable_company_agreements: process.env.DISABLE_COMPANY_AGREEMENTS === 'true',
		disable_costcenters_tab: process.env.DISABLE_COSTCENTERS_TAB === 'true',
		disable_costunits_tab: process.env.DISABLE_COSTUNITS_TAB === 'true',
		disable_create_users: process.env.DISABLE_CREATE_USERS === 'true',
		disable_delete_users: process.env.DISABLE_DELETE_USERS === 'true',
		disable_edit_user_IBAN: process.env.DISABLE_EDIT_USER_IBAN === 'true',
		disable_edit_user_defaultcostcenter: process.env.DISABLE_EDIT_USER_DEFAULTCOSTCENTER === 'true',
		disable_edit_user_edit_name: process.env.DISABLE_EDIT_USER_EDIT_NAME === 'true',
		disable_edit_user_groups: process.env.DISABLE_EDIT_USER_GROUPS === 'true',
		disable_edit_user_titlelabel: process.env.DISABLE_EDIT_USER_TITLELABEL === 'true',
		disable_edit_user_travelexpense: process.env.DISABLE_EDIT_USER_TRAVELEXPENSE === 'true',
		disable_folders: process.env.DISABLE_FOLDERS === 'true',
		disable_general_settings_fields_birthdate: process.env.DISABLE_GENERAL_SETTINGS_FIELDS_BIRTHDATE === 'true',
		disable_general_settings_fields_email: process.env.DISABLE_GENERAL_SETTINGS_FIELDS_EMAIL === 'true',
		disable_general_settings_fields_gender: process.env.DISABLE_GENERAL_SETTINGS_FIELDS_GENDER === 'true',
		disable_general_settings_fields_name: process.env.DISABLE_GENERAL_SETTINGS_FIELDS_NAME === 'true',
		disable_login: process.env.DISABLE_LOGIN === 'true',
		disable_normal_login_options: process.env.DISABLE_NORMAL_LOGIN_OPTIONS === 'true',
		disable_ocr_mode: process.env.DISABLE_OCR_MODE === 'true',
		disable_projects_tab: process.env.DISABLE_PROJECTS_TAB === 'true',
		disable_social_login: process.env.DISABLE_SOCIAL_LOGIN === 'true',
		disable_your_account_tab: process.env.DISABLE_YOUR_ACCOUNT_TAB === 'true',
		klippa_email_title: process.env.KLIPPA_EMAIL_TITLE,
		my_klippa_title: process.env.MY_KLIPPA_TITLE,
		new_helpdesk_url: process.env.NEW_HELPDESK_URL,
		new_logo_link: process.env.NEW_LOGO_LINK,
		new_logout_redirect: process.env.NEW_LOGOUT_REDIRECT,
		product_title: process.env.PRODUCT_TITLE,
		canManuallyUploadCardStatements: process.env.canManuallyUploadCardStatements === 'true',
	},
	share_page: {
		title: process.env.TITLE || 'Klippa',
		contact_us: 'SHARE_PAGE_CONTACT_US' in process.env ? process.env.SHARE_PAGE_CONTACT_US : 'https://www.klippa.com/contact/',
		website: 'SHARE_PAGE_WEBSITE' in process.env ? process.env.SHARE_PAGE_WEBSITE : 'https://www.klippa.com/',
		itunes_link:
			'SHARE_PAGE_ITUNES_LINK' in process.env
				? process.env.SHARE_PAGE_ITUNES_LINK
				: 'https://itunes.apple.com/us/app/klippa-app/id1449587888',
		google_play_link:
			'SHARE_PAGE_GOOGLE_PLAY_LINK' in process.env
				? process.env.SHARE_PAGE_GOOGLE_PLAY_LINK
				: 'https://play.google.com/store/apps/details?id=com.klippa.app',
		knowledge_base: 'SHARE_PAGE_KNOWLEDGE_BASE' in process.env ? process.env.SHARE_PAGE_KNOWLEDGE_BASE : 'https://help.klippa.com',
		support_phone: 'SHARE_PAGE_SUPPORT_PHONE' in process.env ? process.env.SHARE_PAGE_SUPPORT_PHONE : '+31 50 2111631',
		facebook: 'SHARE_PAGE_FACEBOOK' in process.env ? process.env.SHARE_PAGE_FACEBOOK : 'https://www.facebook.com/klippaappNL/',
		twitter: 'SHARE_PAGE_TWITTER' in process.env ? process.env.SHARE_PAGE_TWITTER : 'https://twitter.com/klippaapp',
		linkedin: 'SHARE_PAGE_LINKEDIN' in process.env ? process.env.SHARE_PAGE_LINKEDIN : 'https://www.linkedin.com/company/klippaapp/',
	},
	show_crisp_chat: 'SHOW_CRISP_CHAT' in process.env ? process.env.SHOW_CRISP_CHAT === 'true' : true,
};

const mergedResult = deepMerge(_environment, JSON.parse(process.env.white_label_config ?? '{}'));
export const environment = Object.assign(_environment, mergedResult);
