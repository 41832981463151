<div class="componentContainer">
	<div class="captionAndTooltip">
		<div class="captionContainer">
			<app-icon *ngIf="menuIcon" [size]="iconSize.SMALL" variant="menu"> </app-icon>
			<span class="caption" (click)="toggle()">{{ caption }}</span>
			<app-info-tooltip *ngIf="tooltip" [title]="tooltip"></app-info-tooltip>
		</div>
	</div>
	<klp-form-toggle
		*ngIf="showToggle"
		[disabled]="disabled"
		[(ngModel)]="innerValue"
		(ngModelChange)="setInnerValueAndNotify($event)"
	></klp-form-toggle>
</div>
