import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { NodeAttribute, NodeSettings, NodeType, TreeNode } from '~/app/shared/ui/custom-xml-export/interfaces/treeNode';
import { isNullOrUndefined, isValueSet } from '#/util/values';
import { TreeLogicService } from '~/app/shared/ui/custom-xml-export/services/tree-logic.service';
import { flatten, isEqual, uniq } from 'lodash';
import { arrayIsSetAndFilled } from '#/util/arrays';
import { NotificationService } from '~/app/services/notification.service';
import { TranslateService } from '@ngx-translate/core';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { AppSelectOptions } from '@klippa/ngx-enhancy-forms';
import { ModalService } from '~/app/services/modal.service';

@Component({
	selector: 'app-tree-node-settings',
	templateUrl: './tree-node-settings.component.html',
	styleUrls: ['./tree-node-settings.component.scss'],
})
export class TreeNodeSettingsComponent implements OnInit {
	@Input() node: TreeNode;
	@Output() onDismiss: EventEmitter<any> = new EventEmitter<any>();
	@Output() onSave: EventEmitter<NodeSettings> = new EventEmitter<NodeSettings>();

	public NodeType = NodeType;

	private fb: UntypedFormBuilder = new UntypedFormBuilder();
	nodeSettingsForm: UntypedFormGroup = this.fb.group({
		custom_label: [null, Validators.pattern('^[\\S]+$')],
		context: [[]],
		condition: [],
		value: [],
		dateFormat: [],
		attributes: new UntypedFormArray([]),
	});
	contextOptions: AppSelectOptions = [];
	dynamicAttributeOptions: AppSelectOptions;

	constructor(
		private treeLogicService: TreeLogicService,
		private notificationService: NotificationService,
		private translate: TranslateService,
		private modalService: ModalService,
	) {}

	ngOnInit(): void {
		this.nodeSettingsForm.patchValue({
			custom_label: this.node.settings.custom_label,
			context: this.getContextValues(),
			value: this.node.settings.value,
			dateFormat: this.node.settings.dateFormat,
			condition: this.node.settings.condition,
		});
		this.updateContextOption();
		this.updateAttributeOptions();
		this.initAttributes();
	}

	initAttributes() {
		const attributeFormGroups: UntypedFormGroup[] = this.node.settings.attributes?.map((attribute) => {
			const attributeValue = attribute.isDynamicValue ? (attribute.value as TreeNode).value : attribute.value;
			return this.fb.group({
				name: [attribute.name, [Validators.pattern('^[\\S]+$'), Validators.required]], // The regex requires that there are no spaces.
				value: [attributeValue, [Validators.required]],
				isDynamicValue: attribute.isDynamicValue,
			});
		});

		this.nodeSettingsForm.setControl('attributes', new UntypedFormArray(attributeFormGroups || []));
	}

	getAttributeControls() {
		const attributes = this.nodeSettingsForm.get('attributes') as UntypedFormArray;
		return attributes.controls;
	}

	addAttribute() {
		const attributes = this.nodeSettingsForm.get('attributes') as UntypedFormArray;
		attributes.push(
			this.fb.group({
				name: ['', [Validators.pattern('^[\\S]+$'), Validators.required]], // The regex requires that there are no spaces.
				value: [null, [Validators.required]],
				isDynamicValue: false,
			}),
		);
	}

	deleteAttribute(index: number) {
		const attributes = this.nodeSettingsForm.get('attributes') as UntypedFormArray;
		attributes.removeAt(index);
	}

	getContextValues(): Array<string> {
		return this.node.settings?.context?.map((treeNode) => treeNode.value);
	}

	updateContextOption() {
		this.contextOptions = this.getAllowedContextOptions();
	}

	validateSelectedContexts() {
		const allowedContextOptions = this.getAllowedContextOptions().map((option) => option.id);
		const allowedSelectedContexts = this.nodeSettingsForm
			.get('context')
			?.value?.filter((selectedContext) => allowedContextOptions.includes(selectedContext));
		const selectedContextsValid = isEqual(allowedSelectedContexts, this.nodeSettingsForm.get('context')?.value);
		if (!selectedContextsValid) {
			this.nodeSettingsForm.patchValue({ context: allowedSelectedContexts });
		}
	}

	getAllowedContextOptions(): AppSelectOptions {
		const allowedNodes = this.node.settings?.allowedContext?.filter((contextNode) => {
			// The parent of the node can't be in allowedContext.
			// Unless that parent is selected.
			const parentOfContextNode = contextNode.parent;
			const isParentOfContextNodeInAllowedContext = this.node.settings.allowedContext.includes(parentOfContextNode);
			const isParentOfContextNodeSelected = this.getSelectedContextsAsTreeNodeArray()?.includes(parentOfContextNode);
			return !isParentOfContextNodeInAllowedContext || isParentOfContextNodeSelected;
		});
		return isNullOrUndefined(allowedNodes)
			? []
			: allowedNodes.map((treeNode) => {
					return {
						id: treeNode.value,
						name: treeNode.name,
					};
			  });
	}

	validateContextChangeAndUpdateOptions() {
		this.checkIfContextsAreValidForTree();
		this.updateAttributeOptions();
		this.updateContextOption();
		this.validateSelectedContexts();
	}

	checkIfContextsAreValidForTree() {
		const dependantChildren = this.getDependantChildrenOnContext();
		const requiredContext = this.getRequiredContext(dependantChildren).map((node) => node.value);
		const selectedContext: Array<string> = this.nodeSettingsForm.get('context').value;

		// Check if the all required context are in the selected list.
		const requiredContextMissingInSelectedContext = requiredContext.filter((required) => {
			const foundRequiredInSelected = selectedContext.find((selected) => {
				return selected === required;
			});
			return isNullOrUndefined(foundRequiredInSelected);
		});
		if (arrayIsSetAndFilled(requiredContextMissingInSelectedContext)) {
			this.nodeSettingsForm.patchValue({ context: [...selectedContext, ...requiredContextMissingInSelectedContext] });
			this.notificationService.error(this.translate.instant(_('You can not delete that context as some children nodes depend on it.')));
		}
	}

	getDependantChildrenOnContext(): Array<TreeNode> {
		const childrenOfNode = this.treeLogicService.getDeepChildrenOfNode(this.node);
		const dependantChildren = childrenOfNode.filter((child) => {
			const parentOfChildInStructureTree = this.treeLogicService.findParentOfNodeInStructureTree(child);
			// If the parent of the child in the structure tree is `null` it means it is a top-level node. It can not be dependant on any other node.
			if (isValueSet(parentOfChildInStructureTree)) {
				const allParentsOfChildInBuilderTree = this.treeLogicService.findAllParentsOfNode(child);
				const doesNodeHaveParentOrContextOfNodeInRightTree = allParentsOfChildInBuilderTree.some((parentOfChild) => {
					// If the parent of the child in the builder tree is `null` it means it is a top-level node. It can not be dependant on any other node.
					if (isValueSet(parentOfChild)) {
						// We want to ignore the context of the node we are editing to test if the tree is valid if this the nodes are dependant on it.
						const shouldTestContext = parentOfChild.id !== this.node.id;
						const allowedByContext = shouldTestContext
							? parentOfChild.settings.context?.some((contextNode) => contextNode.value === parentOfChildInStructureTree.value)
							: false;
						return parentOfChild.value === parentOfChildInStructureTree.value || allowedByContext;
					}
					return false;
				});
				if (!doesNodeHaveParentOrContextOfNodeInRightTree) {
					return true;
				}
			}
			return false;
		});
		return dependantChildren;
	}

	getRequiredContext(dependantChildren: Array<TreeNode>): Array<TreeNode> {
		const requiredContextNodes: Array<TreeNode> = uniq(
			dependantChildren.map((child) => this.treeLogicService.findParentOfNodeInStructureTree(child)),
		);
		// Also add the parents of these required nodes if they are in the options.
		for (const contextNode of requiredContextNodes) {
			const contextNodeParent = contextNode.parent;
			if (this.contextOptions.some((option) => option.id === contextNodeParent.value)) {
				requiredContextNodes.push(contextNodeParent);
			}
		}
		return requiredContextNodes;
	}

	updateAttributeOptions() {
		this.dynamicAttributeOptions = this.getAllowedDynamicAttributeNodes();
		this.clearDisallowedDynamicAttributes();
	}

	clearDisallowedDynamicAttributes() {
		// Check if the selected attribute is anywhere in the list of dynamicAttributeOptions, if not, clear the value.
		const attributes: Array<NodeAttribute> = this.nodeSettingsForm.get('attributes').value;
		attributes.forEach((attribute, index) => {
			if (isValueSet(attribute.value) && attribute.isDynamicValue) {
				const foundAttributeNodeInDynamicAttributeOptions = this.dynamicAttributeOptions.find((option) => option.id === attribute.value);
				if (isNullOrUndefined(foundAttributeNodeInDynamicAttributeOptions)) {
					this.getAttributeControls()[index].patchValue({ value: null });
				}
			}
		});
	}

	getAllowedDynamicAttributeNodes(): AppSelectOptions {
		// The allowed dynamic attributes consist of all the children of the
		// settingsNode, the children of all its parents and the children of the selected context nodes.
		// We remove any nodes that are a node to prevent folder from becoming a leaf and folder nodes.
		const childrenOfNodeAndChildrenOfParents = this.treeLogicService.findChildrenOfNodeAndChildrenOfParentsInStructureTree(this.node);
		const selectedContexts = this.getSelectedContextsAsTreeNodeArray();
		let childrenOfContextNodes = [];
		if (isValueSet(selectedContexts)) {
			childrenOfContextNodes = flatten(
				selectedContexts.map((node) => {
					return this.treeLogicService.findChildrenOfNodeInStructureTree(node);
				}),
			);
		}
		let allowedDynamicAttributeNodes: Array<TreeNode> = [...childrenOfNodeAndChildrenOfParents, ...childrenOfContextNodes];
		allowedDynamicAttributeNodes = allowedDynamicAttributeNodes.filter(
			(node) => !node.isNodeToPreventNodeBecomingLeaf && node.type !== NodeType.NODE,
		);
		const allowedDynamicAttributeNodeOptions: AppSelectOptions = allowedDynamicAttributeNodes.map((node) => {
			return {
				id: node.value,
				name: node.name,
			};
		});
		return allowedDynamicAttributeNodeOptions;
	}

	getSelectedContextsAsTreeNodeArray(): Array<TreeNode> {
		const formContextValues: Array<string> = this.nodeSettingsForm.get('context').value;
		const contextNodes: Array<TreeNode> = formContextValues?.map((contextValue) => {
			return this.convertContextValueToTreeNode(contextValue);
		});
		return contextNodes;
	}

	convertContextValueToTreeNode(value: string) {
		return this.node.settings.allowedContext.find((contextNode) => contextNode.value === value);
	}

	getFormAttributesAsNodeAttribute() {
		// We need to convert the value field of attributes that have `isDynamicValue = true`
		// to a TreeNode as the form saves it as a string in the value field.
		const formAttributeValues: Array<NodeAttribute> = this.nodeSettingsForm.get('attributes').value;
		const nodeAttributes: Array<NodeAttribute> = formAttributeValues.map((attribute) => {
			return this.convertAttributeValueToTreeNodeOrString(attribute);
		});
		return nodeAttributes;
	}

	convertAttributeValueToTreeNodeOrString(attribute: NodeAttribute): NodeAttribute {
		if (!attribute.isDynamicValue) {
			return attribute;
		}
		return {
			name: attribute.name,
			value: this.treeLogicService.findNodeInStructureTreeByValue(attribute.value as string),
			isDynamicValue: attribute.isDynamicValue,
		};
	}

	save = async (values: any) => {
		if (isValueSet(values.context)) {
			// app-form-select returns Array<string> as a it's value, so we convert it to a TreeNode
			values.context = this.getSelectedContextsAsTreeNodeArray();
		}
		if (isValueSet(values.attributes)) {
			values.attributes = this.getFormAttributesAsNodeAttribute();
		}

		if (
			(isNullOrUndefined(values.condition) && this.node.type === NodeType.CONDITIONAL) ||
			(isValueSet(values.condition) && this.node.type === NodeType.NODE)
		) {
			const nodeChildren = this.node.children.filter((child) => !child.isNodeToPreventNodeBecomingLeaf);
			if (arrayIsSetAndFilled(nodeChildren)) {
				const header = _('Saving settings');
				const body = _('Are you sure you want save? everything in the condition will be lost. ');
				if (await this.modalService.requestConfirmation(header, body)) {
					this.onSave.emit(values);
					return;
				}
			}
		}

		this.onSave.emit(values);
		return;
	};

	dismissModal() {
		this.onDismiss.emit();
	}

	clearAttributeValue(attribute: AbstractControl) {
		attribute.patchValue({ value: null });
	}

	public canHaveConditions(): boolean {
		return this.node?.attachedData?.key === 'booking_lines';
	}
}
