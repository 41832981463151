<!--Class required for functionality of data-tables library.-->
<div class="dataTables_wrapper">
	<div class="toolbar">
		<div *ngIf="this.selected.rows?.length > 0" id="selected" class="selectAllContainer">
			<label>
				{{ this.selected.rows?.length }}
				<span *ngIf="this.selected.rows?.length === 1">{{ itemName }} </span>
				<span *ngIf="this.selected.rows?.length > 1">{{ itemsName }} </span>
				<span translate>selected</span>
			</label>
			<app-button
				class="selectAllButton"
				variant="greenFilled"
				size="tiny"
				(click)="toggleSelectedItemsOnAllPages()"
				*ngIf="this.selected.all && selectAllItemFn !== null && selectedOnAllPages === 'deselectAll'"
			>
				{{ 'Deselect %items% on all pages' | translate: { items: itemsName } }}
			</app-button>
			<app-button
				class="selectAllButton"
				variant="greenFilled"
				size="tiny"
				(click)="toggleSelectedItemsOnAllPages()"
				*ngIf="this.selected.all && selectAllItemFn !== null && selectedOnAllPages === 'selectAll'"
			>
				{{ 'Select %items% on all pages' | translate: { items: itemsName } }}
			</app-button>
		</div>
	</div>
	<div class="table-responsive-container">
		<table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" class="table" [ngClass]="[hasClickableRows ? 'clickable-rows' : '']">
			<thead>
				<tr>
					<th *ngFor="let column of filterEnabledColumns" [ngSwitch]="column.type" data-orderable="false" class="text-nowrap">
						<ng-template [ngSwitchCase]="'selector'">
							<klp-form-checkbox [(ngModel)]="selected.all" (ngModelChange)="toggleAllRowsSelection()"> </klp-form-checkbox>
						</ng-template>
						<ng-template [ngSwitchCase]="'column_config'">
							<app-button [hasBorder]="false" [fullWidth]="true" (click)="openColumnConfig()">
								<i class="ti-settings default-dark"></i>
							</app-button>
						</ng-template>
						<ng-template ngSwitchDefault>
							<div
								class="toggleSortingButton"
								*ngIf="!column.hide_header && column.sortable"
								(click)="toggleSorting(column.sortingProperty)"
							>
								{{ column.name }}
								<span>
									<i
										*ngIf="this.sortingParameters.sort === column.sortingProperty"
										class="fa fa-sort-{{ this.sortingParameters.sortorder }}"
									></i>
									<i *ngIf="this.sortingParameters.sort !== column.sortingProperty" class="fa fa-sort"></i>
								</span>
							</div>
							<div *ngIf="!column.hide_header && !column.sortable">
								{{ column.name }}
							</div>
						</ng-template>
					</th>
				</tr>
			</thead>
			<tbody>
				<tr
					*ngFor="let row of rows"
					table-row
					[row]="row"
					[columns]="filterEnabledColumns"
					[company]="company"
					[itemName]="typeName"
					[rowSelected]="isRowSelected(row.id)"
					(toggleRow)="toggleRow($event)"
					(clickRow)="clickRowHandler($event)"
					[rowActions]="rowActions(row)"
					(onRowAction)="rowActionHandler($event)"
				></tr>
			</tbody>
			<tfoot *ngIf="totalRow">
				<tr table-row [row]="totalRow" [columns]="totalColumns" [company]="company" [itemsName]="itemsName"></tr>
			</tfoot>
		</table>
		<div class="paginationRow">
			<div translate [translateParams]="{ amount: getMinimum(max, total), total: total, name: itemsName }">
				Showing %amount% of %total% %name%
			</div>
			<app-pager [totalItems]="total" [itemsPerPage]="max" [currentPage]="currentPage" (pageChange)="pageChanged($event)"></app-pager>
		</div>
	</div>
</div>
<div *ngIf="showTableConfig">
	<app-table-column-config [sortableColumns]="sortableColumns" (onSave)="sortColumns($event)" (onDismiss)="closeColumnConfig()">
	</app-table-column-config>
</div>
