import { ModalService } from '~/app/services/modal.service';
import { NgbActiveModal, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ModalBaseComponent } from '~/app/modules/generic/components/modals/modal-base/modal-base';
import { Component, ElementRef, Input, NgModule, OnInit, ViewChild } from '@angular/core';
import { Company, CreateCustomRelationCapability, CreateCustomRelationCapabilityField } from '#/models/company/company.model';
import { NotificationService } from '~/app/services/notification.service';
import { CommonModule } from '@angular/common';
import { MerchantModule } from '~/app/modules/merchant/merchant.module';
import { FolderModule } from '~/app/modules/folder/folder.module';
import { IconModule } from '~/app/modules/icon/icon.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SortablejsModule } from 'ngx-sortablejs';
import { TranslateModule } from '@ngx-translate/core';
import { NgSelectModule } from '@ng-select/ng-select';
import { UiModule } from '~/app/shared/ui/ui.module';
import { CompanyUI } from '~/app/modules/company/components/ui/ui.module';
import { PipesModule } from '~/app/helpers/pipes/pipes.module';
import { DirectivesModule } from '~/app/directives/directives.module';
import { ReceiptModule } from '../../../receipt.module';
import { Receipt, ReceiptFile } from '#/models/transaction/receipt';
import { CompanyIntegrationService } from '#/services/company/company-integration.service';
import { isValueSet } from '#/util/values';
import { UserService } from '~/app/modules/user/user.service';
import { User } from '#/models/user/user.model';

@Component({
	selector: 'app-new-relation-modal',
	templateUrl: './new-relation-modal.component.html',
})
export class NewRelationModal extends ModalBaseComponent implements OnInit {
	@Input() receipt: Receipt;
	@Input() activeFileIndex?: number;
	@Input() files: Array<ReceiptFile>;

	@ViewChild('modalBody') modalBody: ElementRef;

	protected notificationService: NotificationService;

	public company: Company;
	public integration: string;
	public division: string;
	public capabilities: CreateCustomRelationCapability;
	public isOrg: boolean = false;
	public baseField = new CreateCustomRelationCapabilityField({});
	public user: User;

	constructor(
		public modalService: ModalService,
		public activeModal: NgbActiveModal,
		private companyIntegrationService: CompanyIntegrationService,
		private userService: UserService,
	) {
		super(activeModal);
		this.notificationService = ModalBaseComponent.injector.get(NotificationService);
	}

	ngOnInit(): void {
		if (isValueSet(this.capabilities)) {
			this.baseField.Group = this.capabilities.Fields.map((field) => new CreateCustomRelationCapabilityField(field));
			if (!this.capabilities.CreatePerson && this.capabilities.CreateOrg) {
				this.isOrg = true;
			}
			if (this.capabilities.CreatePerson && this.capabilities.CreateOrg) {
				this.isOrg = this.receipt.isinvoice;
			}
		}

		setTimeout(() => {
			// Focus the first text input and fix a bug where if you press esc without having pressed anything results in the underlying popup closing

			const modalEl = this.modalBody.nativeElement as Element;
			if (!isValueSet(modalEl)) {
				return;
			}
			const textEl = modalEl.querySelector('input[type="text"]') as HTMLInputElement;
			if (!isValueSet(textEl)) {
				return;
			}
			textEl.focus();
		}, 350);

		this.user = this.userService.getCurrentLoggedUser();
		super.ngOnInit();
	}

	public async create(): Promise<void> {
		const { company, integration, division, values, isOrg } = this;

		this.companyIntegrationService
			.createIntegrationRelation(company, integration, division, { values, isOrg })
			.then(this.activeModal.close)
			.catch((e) => {
				this.notificationService.error(e);
				throw e;
			});
	}

	public fieldValid(field: CreateCustomRelationCapabilityField): boolean {
		if (!field.isValid()) {
			return false;
		}
		if (field.Group && field.Group.some((relationField) => !this.fieldValid(relationField))) {
			return false;
		}
		if (field.GroupHorizontal && field.GroupHorizontal.some((relationField) => !this.fieldValid(relationField))) {
			return false;
		}

		return true;
	}

	private getValues(field: CreateCustomRelationCapabilityField) {
		let response = {};

		if (field.Key && field.Value) {
			response[field.Key] = field.Value;
		}
		if (field.GroupHorizontal) {
			field.GroupHorizontal.forEach((relationField) => {
				response = { ...response, ...this.getValues(relationField) };
			});
		}
		if (field.Group) {
			field.Group.forEach((relationField) => {
				response = { ...response, ...this.getValues(relationField) };
			});
		}

		return response;
	}

	public get values(): Record<string, string> {
		return this.getValues(this.baseField);
	}

	public previousFile(): void {
		if (this.activeFileIndex > 0) {
			this.activeFileIndex--;
			this.loadFile(this.files[this.activeFileIndex]);
		}
	}

	public nextFile(): void {
		if (this.activeFileIndex < this.files.length - 1) {
			this.activeFileIndex++;
			this.loadFile(this.files[this.activeFileIndex]);
		}
	}

	private loadFile(file: ReceiptFile): void {
		if (file.blob == null) {
			file.loading = true;
			if (file.internal_type === 'image') {
				this.fileService
					.getImage(this.receipt.id, file.id)
					.then((r) => {
						file.loading = false;
						file.blob = r;
					})
					.catch(() => {
						file.loading = false;
					});
			} else {
				this.fileService
					.getDocument(this.receipt.id, file.id)
					.then((r) => {
						file.loading = false;
						file.blob = r;
					})
					.catch(() => {
						file.loading = false;
					});
			}
		}
	}
}

@NgModule({
	declarations: [NewRelationModal],
	imports: [
		CommonModule,
		MerchantModule,
		FolderModule,
		IconModule,
		FormsModule,
		ReactiveFormsModule,
		SortablejsModule,
		TranslateModule,
		NgSelectModule,
		NgbModule,
		UiModule,
		CompanyUI,
		PipesModule,
		DirectivesModule,
		ReceiptModule,
	],
	exports: [NewRelationModal],
})
export class NewRelationModule {}
