import { Component, Host, Input, Optional, SimpleChanges } from '@angular/core';
import { AppSelectOption, FormElementComponent } from '@klippa/ngx-enhancy-forms';
import { ControlContainer, NG_VALUE_ACCESSOR } from '@angular/forms';
import { DynamicOptionsValueAccessorBase } from '~/app/shared/ui/forms/composed/pickers/dynamic/dynamic-options-picker/dynamic-options-value-accessor-base';
import { PaymentCondition } from '#/models/company/payment-condition.model';
import { stringIsSetAndFilled } from '#/util/values';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { CompanyIntegrationService } from '#/services/company/company-integration.service';
import { ItemsWithHasMoreResultsPromise } from '#/models/appSelectOption.model';

@Component({
	selector: 'app-form-payment-condition-picker',
	templateUrl: './dynamic-options-picker/dynamic-options-picker.template.html',
	styleUrls: ['./dynamic-options-picker/dynamic-options-picker.template.scss'],
	providers: [{ provide: NG_VALUE_ACCESSOR, useExisting: PaymentConditionPickerComponent, multi: true }],
})
export class PaymentConditionPickerComponent extends DynamicOptionsValueAccessorBase<string, PaymentCondition> {
	constructor(
		@Optional() @Host() protected parent: FormElementComponent,
		@Optional() @Host() protected controlContainer: ControlContainer,
		private companyIntegrationService: CompanyIntegrationService,
	) {
		super(parent, controlContainer);
		this.defaultPlaceHolder = _('Select payment condition');
	}

	@Input() integrationId: string;
	@Input() divisionId: string;

	ngOnChanges(simpleChanges: SimpleChanges) {
		if (this.initiated) {
			if (simpleChanges.integrationId || simpleChanges.divisionId || simpleChanges.selectableItems) {
				this.picker._ext_refetchOptions();
				this.picker._ext_deselectInvalidItems();
			}
		}
	}

	private hasRequirements(): boolean {
		return stringIsSetAndFilled(this.integrationId) && stringIsSetAndFilled(this.divisionId);
	}

	fetchItemsFn = (start: number, searchQuery: string): ItemsWithHasMoreResultsPromise<PaymentCondition> => {
		if (!this.hasRequirements()) {
			return;
		}
		return this.companyIntegrationService
			.getIntegrationPaymentConditions(
				this.companyIntegrationService.getCompanyOfLoggedUser()?.id,
				this.integrationId,
				this.divisionId,
				start,
				searchQuery,
			)
			.then((res) => {
				return {
					hasMoreResults: res.moreresults,
					items: res.payment_conditions,
				};
			});
	};

	fetchSelectedItemsFn = (ids: Array<string>): Promise<Array<PaymentCondition>> => {
		if (!this.hasRequirements()) {
			return Promise.resolve([]);
		}
		return this.companyIntegrationService.getIntegrationPaymentConditionsByIds(
			this.companyIntegrationService.getCompanyOfLoggedUser()?.id,
			this.integrationId,
			this.divisionId,
			ids,
		);
	};

	mapToSelectOptionFn = (e: PaymentCondition): AppSelectOption => {
		return {
			id: e.Identifier,
			name: (e.Code !== '' ? e.Code + ' - ' : '') + e.Name,
			description: e.Description,
			active: true,
		};
	};
}
