import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { ExpenseReport, ReportBookingData } from '#/models/transaction/expense-reports';
import { AccountingIntegrationV1, Company, UIDefinitionCapabilities } from '#/models/company/company.model';
import { Store } from '@ngrx/store';
import { AppState } from '~/app/reducers';
import { CompanyState } from '#/models/company/company.reducer';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { ExpenseReportsService } from '#/services/transaction/expense-reports.service';
import { CompanyService } from '#/services/company/company.service';
import { stringIsSetAndFilled, useIfStringIsSet } from '#/util/values';
import { CompanyAdministrationService } from '#/services/company/company-administration.service';
import { Administration } from '#/models/company/administration.model';
import { subscribeToFormChanges } from '#/util/forms';
import { convertDateToYMD } from '#/util/date';
import { AAV2_PREFIX } from '#/models/transaction/transaction/transformer';
import { AccountingIntegrationV2Service, DynamicBookingField } from '#/services/integration/accounting-integration-v2.service';
import { debounce } from 'lodash';
import { AccountingBookingType } from '#/models/transaction/bookingType';
import { BookingFieldLevel } from '#/models/accounting-integrations/BookingFieldLevel';
import { Aav2EntityCreationComponent } from '~/app/pages/authenticated/transaction/modals/aav2-entity-creation/aav2-entity-creation.component';
import { PossibleCompanyFeatureFlags } from '#/models/company/possible-feature-flags';
import { memoizeFull } from '#/util/functions';
import { CompanyIntegrationService } from '#/services/company/company-integration.service';

@Component({
	selector: 'app-expense-report-book-modal',
	templateUrl: './expense-report-book-modal.html',
	styleUrls: ['./expense-report-book-modal.scss'],
})
export class ExpenseReportBookModal implements OnInit, OnDestroy {
	@Input() report: ExpenseReport;
	@Output() public onDismiss: EventEmitter<void> = new EventEmitter<void>();
	@Output() public onSave: EventEmitter<void> = new EventEmitter<void>();
	protected destroyCallbacks = [];
	public integrations: Array<AccountingIntegrationV1>;
	public AccountingBookingTypeEnum = AccountingBookingType;
	public BookingFieldLevelEnum = BookingFieldLevel;
	public aav1UiCapabilities: UIDefinitionCapabilities;

	@ViewChild('aav2EntityCreationModal', { static: false }) aav2EntityCreationComponent: Aav2EntityCreationComponent;

	public expenseReportBookForm: FormGroup = this.formBuilder.group({
		provider: [null, Validators.required],
		division: [null, Validators.required],
		journal: [null, Validators.required],
		relation: null,
		bookingDate: null,
		paymentCondition: null,
		accountingHeaders: null,
	});

	public isEdit = false;
	public company: Company;

	constructor(
		private store: Store<AppState>,
		public translate: TranslateService,
		public formBuilder: FormBuilder,
		private expenseReportsService: ExpenseReportsService,
		private companyService: CompanyService,
		private companyAdministration: CompanyAdministrationService,
		private accountingIntegrationServiceV2: AccountingIntegrationV2Service,
		private companyIntegrationService: CompanyIntegrationService,
	) {}

	public async ngOnInit(): Promise<void> {
		if (this.report.booking_data?.provider || stringIsSetAndFilled(this.report.accounting?.authorization)) {
			this.isEdit = true;
		}
		this.company = this.companyService.getCompanyOfLoggedUser();

		if (stringIsSetAndFilled(this.report.accounting?.authorization)) {
			this.expenseReportBookForm.patchValue({
				provider: AAV2_PREFIX + this.report.accounting.authorization,
				accountingHeaders: this.report.accounting.fields,
			});
			await this.updateAAv2Fields();
		} else {
			this.expenseReportBookForm.patchValue({
				provider: useIfStringIsSet(this.report.booking_data.provider),
				division: useIfStringIsSet(this.report.booking_data.division),
				journal: useIfStringIsSet(this.report.booking_data.journal),
				relation: useIfStringIsSet(this.report.booking_data.relation),
				paymentCondition: useIfStringIsSet(this.report.booking_data.paymentCondition),
				bookingDate: useIfStringIsSet(this.report.booking_data.booking_date)
					? convertDateToYMD(new Date(this.report.booking_data.booking_date))
					: undefined,
			});
		}

		if (!this.isEdit) {
			const administration = await this.companyAdministration.getCompanyAdministration(this.company.id, this.report.administration);
			await this.setDefaults(administration);
			await this.getSuggestions();
		}

		this.setUpFormListeners();
	}

	private setUpFormListeners(): void {
		subscribeToFormChanges<keyof typeof this.expenseReportBookForm.value>(this.expenseReportBookForm).subscribe(async (changes) => {
			if (changes.has('provider')) {
				this.integrationChanged();
			}
			if (changes.has('division')) {
				this.divisionChanged();
			}
			if (changes.has('relation')) {
				this.relationChanged();
			}
			if (changes.has('accountingHeaders')) {
				await this.updateAAv2Fields();
			}
		});
	}

	private divisionChanged(): void {
		this.getSuggestions();
	}

	private async relationChanged(): Promise<void> {
		const divisionId = this.expenseReportBookForm.get('division').value;
		const integrationId = this.expenseReportBookForm.get('provider').value;
		const relationId = this.expenseReportBookForm.get('relation').value;
		const companyId = this.companyService.getCompanyOfLoggedUser().id;

		const paymentConditionId = await this.companyIntegrationService
			.getIntegrationRelationsByIds(companyId, integrationId, divisionId, [relationId])
			.then((r) => useIfStringIsSet(r[0]?.PaymentCondition));

		this.expenseReportBookForm.patchValue({ paymentCondition: paymentConditionId });
	}

	private async getSuggestions(): Promise<void> {
		const divisionId = this.expenseReportBookForm.get('division').value;

		if (stringIsSetAndFilled(divisionId)) {
			const suggestions = await this.expenseReportsService.getBookingSuggestions(
				this.companyService.getCompanyOfLoggedUser().id,
				divisionId,
			);
			this.expenseReportBookForm.patchValue({
				journal: suggestions.journal,
			});
		}
	}

	private async setDefaults(administration: Administration) {
		const companySubscription = this.store.select('company').subscribe((val: CompanyState) => {
			this.integrations = val.integrations;
		});

		const suggestedIntegration = await this.companyIntegrationService.getSuggestedIntegration(
			administration.id,
			null,
			AccountingBookingType.EXPENSE_REPORT,
		);
		if (suggestedIntegration?.isAAv2) {
			this.expenseReportBookForm.patchValue({
				provider: AAV2_PREFIX + suggestedIntegration.id,
			});
		} else if (stringIsSetAndFilled(suggestedIntegration?.id)) {
			const division = administration.remote_id || this.company?.authorizations?.get(suggestedIntegration.id)?.defaults?.Division;
			this.expenseReportBookForm.patchValue({
				provider: suggestedIntegration.id,
				division,
			});
		}

		this.destroyCallbacks.push(() => {
			companySubscription.unsubscribe();
		});
	}

	public bookReport = async (values) => {
		if (this.getAAv2IntegrationId()) {
			this.saveBookingDataAav2(values);
		} else {
			this.saveBookingDataAav1(values);
		}
	};

	private saveBookingDataAav1(values): void {
		const valuesTransformed = new ReportBookingData({
			booking_date: stringIsSetAndFilled(values.bookingDate) ? new Date(values.bookingDate).toISOString() : undefined,
			provider: values.provider,
			division: values.division,
			journal: values.journal,
			relation: values.relation,
			paymentCondition: values.paymentCondition,
		});

		this.expenseReportsService.updateBookingDataExpenseReportAav1(this.report.id, valuesTransformed).then(() => {
			this.onSave.emit();
		});
	}

	private saveBookingDataAav2(values): void {
		this.expenseReportsService
			.updateBookingDataExpenseReportAav2(this.report.id, this.getAAv2IntegrationId(), values.accountingHeaders)
			.then(() => {
				this.onSave.emit();
			});
	}

	public closeModal() {
		this.onDismiss.emit();
	}

	public ngOnDestroy(): void {
		this.destroyCallbacks.forEach((unsubscribe) => unsubscribe());
	}

	private async integrationChanged() {
		this.expenseReportBookForm.get('journal').setValue(null);
		this.expenseReportBookForm.get('division').setValue(null);
		const integrationId = this.expenseReportBookForm.get('provider').value;

		if (!stringIsSetAndFilled(integrationId) || integrationId.startsWith(AAV2_PREFIX)) {
			this.aav1UiCapabilities = null;
			return;
		}
		const integration = await this.companyIntegrationService.getIntegration(this.company.id, integrationId);
		this.aav1UiCapabilities = integration.UIDefinition.Capabilities;
	}

	public getAAv2IntegrationId(): string {
		if (!this.expenseReportBookForm.get('provider').value?.startsWith(AAV2_PREFIX)) {
			return;
		}
		return this.expenseReportBookForm.get('provider').value?.replace(AAV2_PREFIX, '');
	}

	/* tslint:disable:member-ordering */
	public uiFieldForIntegrationPromise: Promise<Array<DynamicBookingField>>;
	public setUiFieldForIntegrationPromise = debounce(async (): Promise<void> => {
		const integrationId = this.getAAv2IntegrationId();
		if (stringIsSetAndFilled(integrationId)) {
			this.uiFieldForIntegrationPromise = this.accountingIntegrationServiceV2.getAllUIFields(
				integrationId,
				AccountingBookingType.EXPENSE_REPORT,
				this.getAccountingContext(),
			);
		}
	}, 300);

	/* tslint:disable:member-ordering */
	public getAccountingContext = () => {
		return this.expenseReportBookForm.get('accountingHeaders')?.getRawValue() ?? {};
	};

	/* tslint:disable:member-ordering */
	public getReportContext = async () => {
		return this.report;
	};

	/* tslint:disable:member-ordering */
	public getReceiptContext = async () => null;

	public async createAAv2Entity($event: { topic: string; ctrl: FormControl }): Promise<void> {
		const newId = await this.aav2EntityCreationComponent.openModal($event.topic);
		$event.ctrl.setValue(newId);
	}

	async updateAAv2Fields(): Promise<void> {
		await this.setUiFieldForIntegrationPromise();
	}

	/* tslint:disable:member-ordering */
	public shouldIncludeAAV2Options = memoizeFull((): boolean => {
		return this.company.feature_flags.includes(PossibleCompanyFeatureFlags.AAV2);
	});

	public integrationChangeInterceptor = async (prev: string, cur: string): Promise<void> => {
		if (stringIsSetAndFilled(prev) && stringIsSetAndFilled(cur) && prev !== cur) {
			this.uiFieldForIntegrationPromise = null;
			await this.setUiFieldForIntegrationPromise();
		}
	};
}
