import { UIFieldKey } from '#/models/transaction/interface/frontendModel';
import { isValueSet } from '#/util/values';
import { OcrTypeModel } from '#/models/ocrType.model';

export class InterfaceApiModel {
	public id: string;
	public type: string;
	public name: string;
	public description: string;
	public markNoAttachmentAvailable: boolean;
	public allow_attachments: boolean;
	public allowed_groups: Array<string>;
	public ocr_enabled: boolean;
	public visualizeMap: boolean;
	public active: boolean;
	public ocrMode: OcrTypeModel;
	public usedForPreTransactions: boolean;
	public input_fields: {
		[k in UIFieldKey]: {
			label?: string;
			is_visible_on_pre_transaction: boolean;
			is_editable_on_pre_transaction: boolean;
			is_required_on_pre_transaction: boolean;
			is_visible_on_submit: boolean;
			is_editable_on_submit: boolean;
			is_required_on_submit: boolean;
			is_visible_on_approve: boolean;
			is_editable_on_approve: boolean;
			is_visible_on_finance: boolean;
			is_editable_on_finance: boolean;
			required?: boolean;
			preset: any;
		};
	};
	public displayOrder: number;
	public order: Array<Array<UIFieldKey>>;

	constructor(data = null) {
		if (isValueSet(data)) {
			this.id = data.id;
			Object.assign(this, data);
		}
	}
}
