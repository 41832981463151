import { Injectable } from '@angular/core';
import { AbstractControl, FormArray, FormControl, FormGroup } from '@angular/forms';
import { isValueSet } from '#/util/values';
import { Subscription } from 'rxjs';
import { arrayIsSetAndFilled } from '#/util/arrays';
import { AccountingIntegrationV2Service, DynamicBookingField } from '#/services/integration/accounting-integration-v2.service';
import { CanRefetchItemsInterface } from '#/models/interfaces/CanRefetchItemsInterface';
import { runNextRenderCycle } from '#/util/angular';
import { RelationType } from '#/models/accounting-integrations/accountingRelationType';
import { isEqual } from 'lodash';
import { AccountingBookingType } from '#/models/transaction/bookingType';
import { BookingFieldLevel } from '#/models/accounting-integrations/BookingFieldLevel';
import { filterObjectOnCondition } from '#/util/objects';

@Injectable({
	providedIn: 'root',
})
export class AccountingFieldsService {
	constructor(private accountingIntegrationServiceV2: AccountingIntegrationV2Service) {}

	public setUpFieldEnablers(
		searchInGroups: Array<FormGroup>,
		parameters: Array<DynamicBookingField>,
		parentParameters: Array<DynamicBookingField>,
		onParentValueChangedFn: (thisCtrl: FormControl) => void,
	): Array<Subscription> {
		const subscriptions: Array<Subscription> = [];
		parameters.forEach((fieldParam) => {
			const { thisCtrl, parentCtrls } = this.getCtrlAndItsParents(searchInGroups, fieldParam.key, RelationType.ENABLES, parentParameters);
			if (fieldParam.options?.readOnly) {
				thisCtrl.disable();
				return;
			}
			this.enableOrDisableControl(
				parentCtrls.map((e) => e.ctrl),
				thisCtrl,
			);

			parentCtrls
				.map((e) => e.ctrl)
				.forEach((parentCtrl) =>
					subscriptions.push(
						this.onValueChange(parentCtrl, () => {
							this.enableOrDisableControl(
								parentCtrls.map((e) => e.ctrl),
								thisCtrl,
							);
							onParentValueChangedFn(thisCtrl);
						}),
					),
				);
		});
		return subscriptions;
	}

	public setUpFieldConditionallyEnablers(
		searchInGroups: Array<FormGroup>,
		parameters: Array<DynamicBookingField>,
		parentParameters: Array<DynamicBookingField>,
		isEnabledFn: (fieldParamKey: string, context: Record<string, any>) => Promise<boolean>,
	): Array<Subscription> {
		const subscriptions: Array<Subscription> = [];

		function enableOrDisableCtrl(fieldParam: DynamicBookingField, thisCtrl: FormControl<any>): void {
			const context = searchInGroups.reduce((acc, cur) => {
				return { ...acc, ...cur.getRawValue() };
			}, {});
			isEnabledFn(fieldParam.key, context).then((isEnabled) => {
				if (isEnabled) {
					thisCtrl.enable();
				} else {
					thisCtrl.disable();
				}
			});
		}

		parameters.forEach((fieldParam) => {
			const { thisCtrl, parentCtrls } = this.getCtrlAndItsParents(
				searchInGroups,
				fieldParam.key,
				RelationType.CONDITIONALLY_ENABLES,
				parentParameters,
			);
			if (arrayIsSetAndFilled(parentCtrls)) {
				enableOrDisableCtrl(fieldParam, thisCtrl);
			}
			parentCtrls
				.map((e) => e.ctrl)
				.forEach((parentCtrl) => {
					subscriptions.push(
						this.onValueChange(parentCtrl, () => {
							enableOrDisableCtrl(fieldParam, thisCtrl);
						}),
					);
				});
		});
		return subscriptions;
	}

	public setUpAsyncValidators(
		searchInGroups: Array<FormGroup>,
		parameters: Array<DynamicBookingField>,
		validateFn: (fieldParamKey: string, formContext: Record<string, any>) => Promise<{ errors: Array<string>; warnings: Array<string> }>,
		onWarningFn: (warningMessage: string, formCtrl: AbstractControl) => void,
	): void {
		parameters.forEach(async (fieldParam) => {
			const thisCtrl: FormControl = this.getFormControlInGroups(searchInGroups, fieldParam.key);
			if (fieldParam.options?.validatable) {
				thisCtrl.setAsyncValidators(async () => {
					if (!isValueSet(thisCtrl.getRawValue())) {
						return;
					}
					const formContext = searchInGroups.reduce((acc, cur) => {
						return { ...acc, ...cur.getRawValue() };
					}, {});
					onWarningFn(null, thisCtrl);
					const errorsAndWarnings = await validateFn(fieldParam.key, formContext);
					const errs: Array<string> = errorsAndWarnings.errors;
					const warns: Array<string> = errorsAndWarnings.warnings;
					onWarningFn(warns[0], thisCtrl);
					if (!arrayIsSetAndFilled(errs)) {
						return;
					}
					return {
						async: errs[0],
					};
				});
			}
		});
	}

	public setUpFieldValidators(
		searchInGroups: Array<FormGroup>,
		parameters: Array<DynamicBookingField>,
		parentParameters: Array<DynamicBookingField>,
	): Array<Subscription> {
		const subscriptions: Array<Subscription> = [];
		parameters.forEach((fieldParam: DynamicBookingField) => {
			const { thisCtrl, parentCtrls } = this.getCtrlAndItsParents(searchInGroups, fieldParam.key, RelationType.VALIDATES, parentParameters);
			parentCtrls
				.map((e) => e.ctrl)
				.forEach((parentCtrl) =>
					subscriptions.push(
						this.onValueChange(parentCtrl, () => {
							thisCtrl.updateValueAndValidity();
						}),
					),
				);
		});
		return subscriptions;
	}

	public setUpColumnSuggestions(
		searchInGroups: Array<FormGroup>,
		parentParameters: Array<DynamicBookingField>,
		integrationId: string,
		getReceiptContextFn: () => Promise<Record<string, any>>,
		getBookingHeaderContextFn: () => Promise<Record<string, any>>,
		getBookingLinesContextFn: () => Array<Record<string, any>>,
		bookingType: AccountingBookingType,
		linesToFill: FormArray<FormGroup>,
	): Array<Subscription> {
		const subscriptions: Array<Subscription> = [];
		const fieldsThatSuggestColumn = parentParameters.filter((e) => e.relations.some((r) => r.types.includes(RelationType.COLUMN_SUGGESTS)));
		const fieldsCtrls = this.getCtrls(searchInGroups, fieldsThatSuggestColumn);
		fieldsCtrls.forEach(({ ctrl: parentCtrl, key }) => {
			subscriptions.push(
				this.onValueChange(parentCtrl, async (prev) => {
					const fieldParam = fieldsThatSuggestColumn.find((e) => e.key === key);
					const columnsToSuggest = fieldParam.relations.filter((e) => e.types.includes(RelationType.COLUMN_SUGGESTS)).map((e) => e.key);
					const collectionContext = filterObjectOnCondition(await getBookingHeaderContextFn(), (kvp) => {
						return parentParameters.find((e) => e.key === kvp[0]).level === BookingFieldLevel.COLLECTION;
					});
					const prevHeaderContext = filterObjectOnCondition(await getBookingHeaderContextFn(), (kvp) => {
						return parentParameters.find((e) => e.key === kvp[0]).level === BookingFieldLevel.HEADER;
					});
					const currentHeaderContext = filterObjectOnCondition(await getBookingHeaderContextFn(), (kvp) => {
						return parentParameters.find((e) => e.key === kvp[0]).level === BookingFieldLevel.HEADER;
					});
					let prevLinesContext = getBookingLinesContextFn();
					if (fieldParam.level === BookingFieldLevel.HEADER) {
						prevHeaderContext[fieldParam.key] = prev;
					} else if (fieldParam.level === BookingFieldLevel.LINE) {
						prevLinesContext = prevLinesContext.map((e) => ({ ...e, [fieldParam.key]: prev }));
					}
					this.writeColumnSuggestions(
						collectionContext,
						prevHeaderContext,
						prevLinesContext,
						currentHeaderContext,
						getBookingLinesContextFn(),
						getReceiptContextFn,
						columnsToSuggest,
						integrationId,
						bookingType,
						linesToFill,
					);
				}),
			);
		});
		return subscriptions;
	}

	/* tslint:disable:member-ordering */
	private fnTimeouts = new Map<string, NodeJS.Timeout>();
	public writeColumnSuggestions(
		collectionContext: Record<string, any>,
		prevHeaderContext: Record<string, any>,
		prevLinesContext: Array<Record<string, any>>,
		currentHeaderContext: Record<string, any>,
		currentLinesContext: Array<Record<string, any>>,
		getReceiptContextFn: () => Promise<Record<string, any>>,
		columnsToSuggest: Array<string>,
		integrationId: string,
		bookingType: AccountingBookingType,
		linesToFill: FormArray<FormGroup>,
	) {
		const writeSuggestionForSingleColumn = async (columnToSuggest: string) => {
			this.accountingIntegrationServiceV2
				.getSuggestionForFieldsInColumn(
					integrationId,
					columnToSuggest,
					await getReceiptContextFn(),
					collectionContext,
					currentHeaderContext,
					currentLinesContext,
					prevHeaderContext,
					prevLinesContext,
					bookingType,
				)
				.then((res) => {
					linesToFill.controls
						.map((e) => e.get(columnToSuggest) as FormControl)
						.forEach((e, i) => {
							if (!isValueSet(e.value) || res[i]?.overwrite) {
								e.setValue(res[i].value);
							}
						});
				});
		};

		// debounce getting suggestions, so that we don't spam the server with requests
		// Not using lodash debounce here since that does not support fn arguments
		// TODO I am not sure if this is going to break things with the correct previous vs current context since the previous context
		//  is not being tracked and might be overwritten by the next call. Disabling this for now.
		/*
		columnsToSuggest.forEach((columnToSuggest) => {
			clearTimeout(this.fnTimeouts.get(columnToSuggest));
			this.fnTimeouts.set(
				columnToSuggest,
				setTimeout(() => {
					writeSuggestionForSingleColumn(columnToSuggest);
				}, 300),
			);
		});
		 */
		columnsToSuggest.forEach((columnToSuggest) => {
			writeSuggestionForSingleColumn(columnToSuggest);
		});
	}

	public setUpFieldSuggestions(
		searchInGroups: Array<FormGroup>,
		parameters: Array<DynamicBookingField>,
		parentParameters: Array<DynamicBookingField>,
		integrationId: string,
		getReportContextFn: () => Promise<Record<string, any>>,
		getReceiptContextFn: () => Promise<Record<string, any>>,
		getBookingContextFn: () => Promise<Record<string, any>>,
		accountingBookingType: AccountingBookingType,
		getLineIndexFn?: () => number,
	): Array<Subscription> {
		const subscriptions: Array<Subscription> = [];
		const writeSuggestion = (fieldParam: DynamicBookingField, thisCtrl: FormControl, prevBookingContext: Record<string, any>): void => {
			const lineIndex = getLineIndexFn?.();
			if (lineIndex === -1) {
				return;
			}
			if (!this.isAllowedToGetSuggestion(searchInGroups, fieldParam, parentParameters)) {
				return;
			}
			this.writeSuggestionToCtrl(
				integrationId,
				fieldParam,
				getReportContextFn,
				getReceiptContextFn,
				getBookingContextFn,
				prevBookingContext,
				accountingBookingType,
				thisCtrl,
				lineIndex,
			);
		};

		parameters
			.filter((e) => e.options.suggestions.enabled && !e.options.suggestions.column)
			.forEach((fieldParam: DynamicBookingField) => {
				{
					const { thisCtrl, parentCtrls } = this.getCtrlAndItsParents(
						searchInGroups,
						fieldParam.key,
						RelationType.ENABLES,
						parentParameters,
					);
					if (parentCtrls.map((e) => e.ctrl).every((e) => isValueSet(e.value))) {
						writeSuggestion(fieldParam, thisCtrl, null);
					}
					parentCtrls.forEach((parentCtrl) => {
						subscriptions.push(
							this.onValueChange(parentCtrl.ctrl, async (prev) => {
								if (parentCtrls.map((e) => e.ctrl).every((e) => isValueSet(e.value))) {
									const prevBookingContext = { ...(await getBookingContextFn()), [parentCtrl.key]: prev };
									writeSuggestion(fieldParam, thisCtrl, prevBookingContext);
								}
							}),
						);
					});
				}

				{
					const { thisCtrl, parentCtrls } = this.getCtrlAndItsParents(
						searchInGroups,
						fieldParam.key,
						RelationType.OVERWRITABLY_SUGGESTS,
						parentParameters,
					);
					parentCtrls.forEach((parentCtrl) =>
						subscriptions.push(
							this.onValueChange(parentCtrl.ctrl, async (prev) => {
								const prevBookingContext = { ...(await getBookingContextFn()), [parentCtrl.key]: prev };
								writeSuggestion(fieldParam, thisCtrl, prevBookingContext);
							}),
						),
					);
				}

				{
					const { thisCtrl, parentCtrls } = this.getCtrlAndItsParents(
						searchInGroups,
						fieldParam.key,
						RelationType.NON_OVERWRITABLY_SUGGESTS,
						parentParameters,
					);
					parentCtrls.forEach((parentCtrl) =>
						subscriptions.push(
							this.onValueChange(parentCtrl.ctrl, async (prev) => {
								if (!isValueSet(thisCtrl.value)) {
									const prevBookingContext = { ...(await getBookingContextFn()), [parentCtrl.key]: prev };
									writeSuggestion(fieldParam, thisCtrl, prevBookingContext);
								}
							}),
						),
					);
				}
			});
		return subscriptions;
	}

	private async writeSuggestionToCtrl(
		integrationId: string,
		fieldParam: DynamicBookingField,
		getReportContextFn: () => Promise<Record<string, any>>,
		getReceiptContextFn: () => Promise<Record<string, any>>,
		getBookingContextFn: () => Promise<Record<string, any>>,
		prevBookingContext: Record<string, any>,
		accountingBookingType: AccountingBookingType,
		thisCtrl: FormControl,
		lineIndex?: number,
	) {
		this.accountingIntegrationServiceV2
			.getSuggestionForField(
				integrationId,
				fieldParam.key,
				await getReportContextFn(),
				await getReceiptContextFn(),
				await getBookingContextFn(),
				prevBookingContext,
				accountingBookingType,
				lineIndex,
			)
			.then((res) => {
				if (res.overwrite || !isValueSet(thisCtrl.value)) {
					thisCtrl.setValue(res.value);
				}
			});
	}

	public setUpRefetchItemsForPickers(
		searchInGroups: Array<FormGroup>,
		parameters: Array<DynamicBookingField>,
		parentParameters: Array<DynamicBookingField>,
		getInputFn: (f: FormControl) => CanRefetchItemsInterface,
	): Array<Subscription> {
		const subscriptions: Array<Subscription> = [];
		parameters.forEach((fieldParam: DynamicBookingField) => {
			const { thisCtrl, parentCtrls } = this.getCtrlAndItsParents(searchInGroups, fieldParam.key, RelationType.FILTERS, parentParameters);
			parentCtrls
				.map((e) => e.ctrl)
				.forEach((parentCtrl) =>
					subscriptions.push(
						this.onValueChange(parentCtrl, () => {
							getInputFn(thisCtrl)?._ext_refetchItems();
						}),
					),
				);
		});
		return subscriptions;
	}

	private onValueChange(formControl: FormControl, fn: (prev) => void): Subscription {
		let prevVal = formControl.value;
		return formControl.valueChanges.subscribe((cur) => {
			const actualPrevVal = prevVal;
			prevVal = cur;
			if (isEqual(actualPrevVal, cur)) {
				return;
			}
			if (!isValueSet(actualPrevVal) && !isValueSet(cur)) {
				return;
			}
			// next cycle, because otherwise our context form data is 1 cycle behind
			runNextRenderCycle(() => fn(actualPrevVal));
		});
	}

	public isAllowedToGetSuggestion(
		searchInGroups: Array<FormGroup>,
		fieldParam: DynamicBookingField,
		parameters: Array<DynamicBookingField>,
	): boolean {
		const { thisCtrl, parentCtrls } = this.getCtrlAndItsParents(searchInGroups, fieldParam.key, RelationType.ENABLES, parameters);
		if (!fieldParam.options.suggestions.enabled) {
			return false;
		}
		if (!fieldParam.options.suggestions.overwrite && isValueSet(thisCtrl.value)) {
			return false;
		}
		return parentCtrls.map((e) => e.ctrl).every((parentCtrl) => isValueSet(parentCtrl.value));
	}

	private getCtrlAndItsParents(
		searchInGroups: Array<FormGroup>,
		fieldParamKey: string,
		relationType: RelationType,
		parameters: Array<DynamicBookingField>,
	): { parentCtrls: Array<{ key: string; ctrl: FormControl }>; thisCtrl: FormControl<any> } {
		const thisCtrl: FormControl = this.getFormControlInGroups(searchInGroups, fieldParamKey);
		const parentsOfThisCtrl = parameters.filter((field) =>
			field.relations.some((relation) => relation.key === fieldParamKey && relation.types.includes(relationType)),
		);
		const parentCtrls: Array<{ key: string; ctrl: FormControl }> = this.getCtrls(searchInGroups, parentsOfThisCtrl);
		return { thisCtrl: thisCtrl, parentCtrls: parentCtrls };
	}

	private getCtrls(searchInGroups: Array<FormGroup>, parameters: Array<DynamicBookingField>): Array<{ key: string; ctrl: FormControl }> {
		return parameters.map((e) => {
			return { key: e.key, ctrl: this.getFormControlInGroups(searchInGroups, e.key) };
		});
	}

	private getFormControlInGroups(searchInGroups: Array<FormGroup>, key: string): FormControl {
		return searchInGroups.find((e) => e.get(key)).get(key) as FormControl;
	}

	private enableOrDisableControl(parentCtrls: Array<FormControl>, thisCtrl: FormControl): void {
		const allFilledIn = parentCtrls.every((e) => isValueSet(e.value));
		if (allFilledIn) {
			thisCtrl.enable();
		} else {
			thisCtrl.setValue(null);
			thisCtrl.disable();
		}
	}
}
