import { Injectable } from '@angular/core';
import { APIService } from '../../api/services/api.service';
import { APINotificationsService } from '../../api/services/apinotifications.service';
import { InboxItem } from './models/inbox';
import { Receipt } from '#/models/transaction/receipt';

@Injectable({
	providedIn: 'root',
})
export class InboxService {
	constructor(private apiService: APIService, private notifications: APINotificationsService) {}

	getInbox(): Promise<InboxItem[]> {
		return this.apiService
			.get('/api/v1/inbox?sort=read,date&sortorder=asc,desc')
			.then((r) => {
				const items = InboxItem.fromData(r.data.inbox);
				return Promise.resolve(items);
			})
			.catch((r) => {
				this.notifications.handleAPIError(r);
				return Promise.reject(r);
			});
	}

	getInboxItem(id: string): Promise<InboxItem> {
		return this.apiService
			.get('/api/v1/inbox/' + id)
			.then((r) => {
				const item = new InboxItem(r.data);
				return Promise.resolve(item);
			})
			.catch((r) => {
				this.notifications.handleAPIError(r);
				return Promise.reject(r);
			});
	}

	markRead(inboxItem: InboxItem) {
		return this.apiService
			.post(`/api/v1/inbox/${inboxItem.getID()}/read`, null)
			.then((r) => {
				return Promise.resolve(r);
			})
			.catch((r) => {
				this.notifications.handleAPIError(r);
				return Promise.reject(r);
			});
	}

	markReceiptInboxItemAsRead(receiptId: string) {
		return this.apiService.patchToApi(`receipt/${receiptId}/inbox/read`).catch((r) => {
			this.notifications.handleAPIError(r);
		});
	}
}
