<ng-template #emptyState>
	<app-empty-view [text]="'There are no saved receipts at the moment.' | translate"></app-empty-view>
</ng-template>
<wutu-data-table
	[emptyTpl]="emptyState"
	[fetchItemsFn]="fetchItemsFn"
	(onRowClicked)="_onRowClicked($event)"
	[getActionsForRowFn]="getActionsForRowFn"
	[getActionsForMultipleRowsFn]="getActionsForMultipleRowsFn"
	[persistColumnsFn]="persistColumnsFn"
	[retrieveColumnsFn]="retrieveColumnsFn"
>
	<ng-template columnKey="description" [columnCaption]="'Description' | translate" let-value="value" sortKey="description">
		{{ value ? value : ('No description' | translate) }}
	</ng-template>
	<ng-template columnKey="amount" [columnCaption]="'Amount' | translate" sortKey="amount" let-value="value" [fixedWidthOnContents]="false">
		{{ value | amountWithCurrency }}
	</ng-template>
	<ng-template columnKey="purchaseDate" [columnCaption]="'Transaction date' | translate" sortKey="purchaseDate" let-value="value">
		{{ value | dateFormat }}
	</ng-template>
	<ng-template
		columnKey="administration"
		[columnCaption]="getCustomCaptionForField('administration')"
		sortKey="administration"
		let-value="value"
	>
		<app-company-administration-label [administrationId]="value"></app-company-administration-label>
	</ng-template>
	<ng-template columnKey="project" [columnCaption]="getCustomCaptionForField('project')" sortKey="project" let-value="value">
		<app-company-project-label [project]="value"></app-company-project-label>
	</ng-template>
	<ng-template columnKey="costUnit" [columnCaption]="getCustomCaptionForField('costUnit')" sortKey="costUnit" let-value="value">
		<app-company-cost-unit-label [costunit]="value"></app-company-cost-unit-label>
	</ng-template>
	<ng-template columnKey="costCenter" [columnCaption]="getCustomCaptionForField('costCenter')" sortKey="costCenter" let-value="value">
		<app-company-cost-center-label [costcenter]="value"></app-company-cost-center-label>
	</ng-template>
	<ng-template columnKey="merchant" [columnCaption]="'Merchant' | translate" sortKey="merchant" let-value="value">
		<app-merchant-label [MerchantID]="value"></app-merchant-label>
	</ng-template>
	<ng-template columnKey="category" [columnCaption]="getCustomCaptionForField('category')" sortKey="category" let-value="value">
		<app-company-category-label [company]="company" [category]="value"></app-company-category-label>
	</ng-template>
	<ng-template columnKey="customPaymentMethod" [columnCaption]="'Payment method' | translate" sortKey="paymentMethod" let-value="value">
		<app-company-payment-method-label [payment_method_id]="value"></app-company-payment-method-label>
	</ng-template>
	<ng-template columnKey="createDate" [columnCaption]="'Creation date' | translate" sortKey="createDate" let-value="value">
		{{ value | dateFormat }}
	</ng-template>
	<ng-template columnKey="updateDate" [columnCaption]="'Updated date' | translate" sortKey="updateDate" let-value="value">
		{{ value | dateFormat }}
	</ng-template>
</wutu-data-table>
