import { AbstractControl, ValidationErrors } from '@angular/forms';

export function getValidTipAmountValidator(tipAmountCtrl: AbstractControl, amountWithoutTipCtrl: AbstractControl) {
	return (control: AbstractControl): ValidationErrors | null => {
		if (!Number.isFinite(control.value?.amount)) {
			return null;
		}
		if (!Number.isFinite(tipAmountCtrl.value?.amount)) {
			return null;
		}
		if (!Number.isFinite(amountWithoutTipCtrl.value?.amount)) {
			return null;
		}
		if (control.value.amount <= tipAmountCtrl.value.amount + amountWithoutTipCtrl.value.amount) {
			return null;
		}
		return { tipAmount: true };
	};
}
