import { Component, Host, Optional } from '@angular/core';
import { AppSelectOption, FormElementComponent } from '@klippa/ngx-enhancy-forms';
import { ControlContainer, NG_VALUE_ACCESSOR } from '@angular/forms';
import { DynamicOptionsValueAccessorBase } from '~/app/shared/ui/forms/composed/pickers/dynamic/dynamic-options-picker/dynamic-options-value-accessor-base';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { ItemsWithHasMoreResultsPromise } from '#/models/appSelectOption.model';
import { RegistrationService } from '#/services/transaction/registration.service';
import { TransportationType } from '#/models/transaction/transportationType';
import { stringIsSetAndFilled } from '#/util/values';

@Component({
	selector: 'app-form-transportation-type-picker',
	templateUrl: './dynamic-options-picker/dynamic-options-picker.template.html',
	styleUrls: ['./dynamic-options-picker/dynamic-options-picker.template.scss'],
	providers: [{ provide: NG_VALUE_ACCESSOR, useExisting: TransportationTypePickerComponent, multi: true }],
})
export class TransportationTypePickerComponent extends DynamicOptionsValueAccessorBase<string, TransportationType> {
	constructor(
		@Optional() @Host() protected parent: FormElementComponent,
		@Optional() @Host() protected controlContainer: ControlContainer,
		private registrationService: RegistrationService,
	) {
		super(parent, controlContainer);
		this.truncateOptions = false;
		this.defaultPlaceHolder = _('Select transportation type');
	}

	public fetchItemsFn = (start: number, searchQuery: string): ItemsWithHasMoreResultsPromise<TransportationType> => {
		return this.registrationService.getTransportationTypes(start, searchQuery).then((res) => {
			return {
				hasMoreResults: res.moreresults,
				items: res.data.results,
			};
		});
	};

	public fetchSelectedItemsFn = (ids: Array<string>): Promise<Array<TransportationType>> => {
		return this.registrationService.getTransportationTypesById(ids);
	};

	mapToSelectOptionFn = (e: TransportationType): AppSelectOption => {
		return {
			id: e.id,
			name: [e.name, e.description].filter(stringIsSetAndFilled).join(' - '),
		};
	};
}
