import { Component, OnInit } from '@angular/core';
import { BaseComponent } from '../../base/base.component';
import { User } from '#/models/user/user.model';
import { WebsocketsService } from '../../websockets/services/websockets.service';
import { GenericService } from '../../modules/generic/generic.service';
import { Company } from '#/models/company/company.model';
import { ReceiptFlowService } from '~/app/modules/receipt/receipt-flow.service';
import { CompanyUnitCacheService } from '~/app/services/company-unit-cache.service';
import { importReceiptModalInBackground } from '~/app/modules/receipt/components/modals/lazy';
import { TranslationReplaceService } from '~/app/services/translation-replace.service';

@Component({
	selector: 'app-authenticated',
	templateUrl: './authenticated.component.html',
})
export class AuthenticatedComponent extends BaseComponent implements OnInit {
	public user: User;
	public company: Company;
	public parentCompany: Company;
	protected websocketsService: WebsocketsService;
	protected genericService: GenericService;
	protected receiptFlowService: ReceiptFlowService;
	protected companyUnitCacheService: CompanyUnitCacheService;
	public translationReplaceService: TranslationReplaceService;

	constructor() {
		super();
		this.websocketsService = BaseComponent.injector.get(WebsocketsService);
		this.genericService = BaseComponent.injector.get(GenericService);
		this.receiptFlowService = BaseComponent.injector.get(ReceiptFlowService);
		this.companyUnitCacheService = BaseComponent.injector.get(CompanyUnitCacheService);
		this.translationReplaceService = BaseComponent.injector.get(TranslationReplaceService);
	}

	ngOnInit() {
		const userStoreSubscription = this.store.select('user').subscribe((val) => {
			this.user = val.currentUser;
		});
		const companySubscription = this.store.select('company').subscribe((val) => {
			this.company = val.company;
		});

		this.destroyCallbacks.push(() => {
			userStoreSubscription.unsubscribe();
			companySubscription.unsubscribe();
		});

		importReceiptModalInBackground();
	}

	isAdministrationOffice(): boolean {
		return this.company && this.company.isadministrationoffice;
	}

	isSubCompanyActive(): boolean {
		return this.company && this.parentCompany && this.company.id !== this.parentCompany.id;
	}
}
