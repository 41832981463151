import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { TranslateService } from '@ngx-translate/core';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FullScope, ScopesGroup } from '~/app/modules/oauth-app/models/app';
import { OAuthService } from '~/app/modules/oauth-app/service';

@Component({
	selector: 'scope-view',
	templateUrl: './scope-view.html',
	styleUrls: ['./scope-view.css'],
})
export class ScopeView implements OnInit {
	availableScopes: ScopesGroup[] = [];
	internalScope: string[] = [];

	@Input()
	set scope(val) {
		this.internalScope = val;
		this.scopeChange.emit(this.scope);
	}
	get scope() {
		return this.internalScope;
	}

	@Output()
	scopeChange = new EventEmitter();

	focusedRow = 0;

	constructor(public translate: TranslateService, public oauthService: OAuthService) {}

	ngOnInit() {
		this.oauthService.scopeGroups().then((groups) => {
			this.availableScopes = groups;
		});
	}

	codeToRole(id: string) {
		for (let groupI = 0; groupI < this.availableScopes.length; groupI++) {
			const group = this.availableScopes[groupI];
			for (var i = 0; i < group.scopes.length; i++) {
				if (group.scopes[i].id === id) return group.scopes[i];
			}
		}
	}

	isDisabled(fullScope: FullScope) {
		const allScopes = this.availableScopes.reduce((acc, group) => [...acc, ...group.scopes], []);
		for (let i = 0; i < allScopes.length; i++) {
			const scope = allScopes[i];
			if (
				scope.requires &&
				scope.requires.find((requiresID) => requiresID === fullScope.id) &&
				this.scope.find((scopeID) => scopeID === scope.id)
			) {
				return true;
			}
		}

		return false;
	}

	groupHasEnabledScopes(group: ScopesGroup) {
		return !!group.scopes.find((scope) => !!this.internalScope.find((scopeID) => scopeID === scope.id));
	}

	isChecked(fullScope: FullScope) {
		return !!this.scope.find((scope) => scope === fullScope.id);
	}
}
