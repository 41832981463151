import { Injectable, TemplateRef } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class CommonLayoutService {
	private dynamicHeaderContentSubject = new Subject<TemplateRef<HTMLElement>>();

	public setDynamicHeaderContent(tpl: TemplateRef<HTMLElement>): void {
		this.dynamicHeaderContentSubject.next(tpl);
	}
	public onDynamicHeaderContentChanged(): Observable<TemplateRef<HTMLElement>> {
		return this.dynamicHeaderContentSubject.asObservable();
	}
}
