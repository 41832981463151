<div class="float-right mrg-right-20" *ngIf="receipts.length > 0">
	<div class="float-right mrg-right-15" *ngIf="getSelectedCount() > 0">
		<app-button
			(click)="updateDropdownItems()"
			variant="greenFilled"
			size="small"
			id="table-actions-dropdown"
			data-toggle="dropdown"
			aria-haspopup="true"
			aria-expanded="false"
		>
			{{ 'Actions' | translate }} <i class="ti-angle-down font-size-11"></i>
		</app-button>

		<div class="dropdown-menu" aria-labelledby="table-actions-dropdown">
			<button *ngFor="let dropdownItem of dropdownItems" class="dropdown-item" (click)="dropdownItem.click($event)">
				{{ dropdownItem.label }}
			</button>
		</div>
	</div>
</div>
<div class="table-responsive" *ngIf="receipts.length > 0">
	<table class="table table-lg table-hover">
		<thead>
			<tr>
				<th class="table-end-left-padding">
					<div class="checkbox">
						<input
							[attr.id]="'receipt-checkbox-all'"
							[attr.name]="'receipt-checkbox-all'"
							type="checkbox"
							[(ngModel)]="checkAll.checked"
							(ngModelChange)="changedCheckAll($event)"
							[value]="true"
						/>
						<label [attr.for]="'receipt-checkbox-all'"></label>
					</div>
				</th>
				<th [ngClass]="column.table_class" *ngFor="let column of columns">
					<span *ngIf="column.sortable" class="sortable" (click)="toggleSort(column.id)">
						<span class="mr-1">{{ column.table_label }}</span>
						<i *ngIf="filters.sort === sortingProperty[column.id]" class="fa fa-sort-{{ filters.sortorder }}"></i>
						<i *ngIf="filters.sort !== sortingProperty[column.id]" class="fa fa-sort"></i>
					</span>
					<ng-container *ngIf="!column.sortable">{{ column.table_label }}</ng-container>
				</th>
				<th>&nbsp;</th>
				<th class="table-end-right-padding text-center" *ngIf="user.canConfigureDashboardColumns()">
					<a
						href="#"
						href="javascript:void(0);"
						class="side-panel-toggle"
						data-toggle="modal"
						data-target="#modal-receipts-table-column-settings"
					>
						<i class="ti-settings default-dark"></i>
					</a>
				</th>
				<th class="table-end-right-padding text-center" *ngIf="!user.canConfigureDashboardColumns()"></th>
			</tr>
		</thead>
		<tbody data-cy="tableReceipts">
			<tr
				class="pointer"
				role="row"
				app-receipts-table-row
				*ngFor="let receipt of receipts; trackBy: trackReceipt"
				[ngClass]="{ callToActionBackground: receipt.isProcessing() || receipt.needs_ocr_review }"
				[receipt]="receipt"
				[columns]="columns"
				[checkboxes]="checkboxes"
				[user]="user"
				[company]="company"
				(filtersChanged)="onFiltersChanged()"
				(checkboxClick)="checkBoxClicked($event)"
				(openExport)="openExportModal($event)"
				(click)="TableActionOpenEditModal($event, receipt)"
				[tableType]="tableType"
				[changeDetection]="changeDetection"
				[integrations]="integrations"
				[reports]="reports"
			></tr>
		</tbody>
		<tfoot>
			<tr role="row" app-receipts-table-report [receipts]="receipts" [columns]="columns"></tr>
		</tfoot>
	</table>
</div>

<div *ngIf="receipts.length == 0">
	<div class="container text-center">
		<div class="col">
			<app-empty-view gif="1" [text]="getEmptyViewTitle()" [subtitle]="getEmptyViewSubtitle()"></app-empty-view>

			<button
				class="btn btn-primary"
				data-toggle="modal"
				data-target="#modal-receipts-table-filters"
				(click)="openFilters()"
				translate
				*ngIf="filtered"
			>
				Change filters
			</button>
		</div>
	</div>
</div>

<div class="modal modalRight fade" id="modal-receipts-table-column-settings" *ngIf="user.canConfigureDashboardColumns()">
	<div class="modal-dialog" role="document">
		<div class="modal-content">
			<div class="side-modal-wrapper">
				<div class="pdd-horizon-15 scroll-wrapper pt-5">
					<h4 translate>Configure your dashboard</h4>
					<p class="mrg-btm-15 font-size-13" translate>Pick and order the columns in any way you want!</p>
					<div class="list-group" [sortablejs]="sortableColumns">
						<div class="list-group-item list-group-item-action" *ngFor="let column of sortableColumns">
							<a href="javascript:void(0);" class="d-flex justify-content-between align-items-center">
								<div class="font-size-15">
									<i class="ti-menu mrg-right-10 text-gray"></i> <b class="text-dark">{{ column.label }}</b>
								</div>
								<div class="toggle-checkbox toggle-primary checkbox-inline float-right mrg-top-15">
									<input
										type="checkbox"
										[attr.id]="'receipt-column-' + column.id"
										[attr.name]="'receipt-column-' + column.id"
										[(ngModel)]="column.status"
									/>
									<label [attr.for]="'receipt-column-' + column.id"></label>
								</div>
							</a>
						</div>
					</div>
				</div>
				<div class="modal-footer">
					<button
						(click)="saveColumnOrder()"
						class="btn btn-primary btn-block"
						data-toggle="modal"
						data-target="#modal-receipts-table-column-settings"
						type="submit"
						translate
					>
						Save
					</button>
				</div>
			</div>
		</div>
	</div>
</div>

<ng-template #exportModal>
	<app-export-form
		[exportIds]="exportIds"
		exportType="receipt"
		[companyExport]="tableType !== 'personal'"
		[exportPromise]="exportPromise"
		(onDismiss)="closeExportModal()"
		(onExport)="processExport($event)"
		[headerMessage]="headerMessageRef"
	>
	</app-export-form>

	<ng-template #headerMessageRef>
		<header *ngIf="exportIds.length === 1" translate>You are going to export 1 receipt.</header>
		<header *ngIf="exportIds.length > 1" [translateParams]="{ count: exportIds.length }" translate>
			You are going to export %count% receipts.
		</header>
	</ng-template>
</ng-template>
