import { BrowserModule } from '@angular/platform-browser';
import { ErrorHandler, Injectable, Injector, NgModule } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { PageLayoutComponent } from './layouts/pages/page-layout.component';
import { NavbarComponent } from './shared/navbar/navbar.component';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { LocalStorageModule } from 'angular-2-local-storage';
import { BaseComponent } from './base/base.component';
import { PipesModule } from './helpers/pipes/pipes.module';
import { CommonLayoutComponent } from './layouts/common-layout.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AuthenticationLayoutComponent } from './layouts/authentication-layout.component';
import { MissingTranslationHandler, TranslateLoader, TranslateModule, TranslateParser } from '@ngx-translate/core';
import { Sidebar_Directives } from './shared/directives/side-nav.directive';
import { StoreModule } from '@ngrx/store';
import { reducers } from './reducers';
import { WebsocketsModule } from './websockets/websockets.module';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { CustomHandler, CustomParser } from './util/i18n';
import { SidePanelModule } from './modules/side-panel/side-panel.module';
import { SortablejsModule } from 'ngx-sortablejs';
import { ReceiptModule } from './modules/receipt/receipt.module';
import { CompanyModule } from './modules/company/company.module';
import { CookieService } from 'ngx-cookie-service';
import { InboxModule } from './modules/inbox/inbox.module';
import { VersionCheckService } from '~/app/services/version-check.service';
import { RequestInterceptor } from '~/app/util/RequestInterceptor';
import { TagModule } from '~/app/modules/tag/tag.module';
import { TranslationReplaceService } from '~/app/services/translation-replace.service';
import { reportError } from './util/sentry';
import { UiModule } from './shared/ui/ui.module';
import { ModalBaseComponent } from './modules/generic/components/modals/modal-base/modal-base';
import { CompanySubCompanyPickerModule } from './modules/company/components/ui/company-sub-company-picker/company-sub-company-picker.component';
import { UserRoleSwitcherModule } from './shared/ui/user-role-switcher/user-role-switcher';
import { TimezoneService } from '~/app/services/timezone.service';
import { RedirectComponent } from './pages/unauthenticated/redirect/redirect.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BlobErrorHttpInterceptor } from './util/BlobErrorHttpInterceptor';
import { ExternalTemplateDeps } from '~/app/externalTemplateDeps';

export function HttpLoaderFactory(http: HttpClient) {
	return new TranslateHttpLoader(http);
}

@Injectable()
export class SentryErrorHandler implements ErrorHandler {
	handleError(err: any) {
		reportError(err);
	}
}

@NgModule({
	imports: [
		BrowserModule,
		BrowserAnimationsModule,
		TranslateModule.forRoot({
			loader: {
				provide: TranslateLoader,
				useFactory: HttpLoaderFactory,
				deps: [HttpClient],
			},
			parser: { provide: TranslateParser, useClass: CustomParser },
			missingTranslationHandler: { provide: MissingTranslationHandler, useClass: CustomHandler },
		}),
		AppRoutingModule,
		NgbModule,
		FormsModule,
		HttpClientModule,
		LocalStorageModule.forRoot({
			prefix: 'klippa',
			storageType: 'localStorage',
		}),
		PipesModule,
		StoreModule.forRoot(reducers, {
			runtimeChecks: {
				strictStateImmutability: false,
				strictActionImmutability: false,
			},
		}),
		FormsModule,
		ReactiveFormsModule,
		WebsocketsModule,
		SidePanelModule,
		SortablejsModule.forRoot({ animation: 150 }),
		ReceiptModule,
		CompanyModule,
		InboxModule,
		TagModule,
		UiModule,
		CompanySubCompanyPickerModule,
		UserRoleSwitcherModule,
	],
	declarations: [
		AppComponent,
		PageLayoutComponent,
		NavbarComponent,
		BaseComponent,
		RedirectComponent,
		CommonLayoutComponent,
		AuthenticationLayoutComponent, // @todo move into separate module. Now we have to import SidePanelModule here.
		Sidebar_Directives,
		ExternalTemplateDeps,
	],
	providers: [
		{
			provide: ErrorHandler,
			useClass: SentryErrorHandler,
		},
		CookieService,
		VersionCheckService,
		TranslationReplaceService,
		TimezoneService,
		{ provide: HTTP_INTERCEPTORS, useClass: RequestInterceptor, multi: true },
		{ provide: HTTP_INTERCEPTORS, useClass: BlobErrorHttpInterceptor, multi: true },
	],
	bootstrap: [AppComponent],
})
export class AppModule {
	constructor(private injector: Injector) {
		BaseComponent.injector = this.injector;
		ModalBaseComponent.injector = this.injector;
	}
}
