import { stringIsSetAndFilled } from '../../util/values';
import { ListAPIRequest } from '../listApiRequest.model';
import { CreatorType } from '../transaction/creatorType.model';
import { CompanyObjectListAPIRequest } from './company-object-api-request.model';

export class CompanyPaymentMethod {
	id: string;
	createdate: Date;
	updatedate: Date;
	company: string;
	code: string;
	name: string;
	groups: string[];
	active: boolean;
	description: string;
	currency: string = 'EUR';
	module_payment_method_balances_balance: number;
	/* Internal use */
	groupsIndex: string;
	searchIndex: string = '';
	from: string;
	to: string;
	creator: { type: CreatorType };

	constructor(data = null) {
		if (data) {
			Object.assign(this, data);

			if (data.createdate) {
				this.createdate = Date.parse(data.createdate) > 0 ? new Date(data.createdate) : null;
			}
			if (data.updatedate) {
				this.updatedate = Date.parse(data.updatedate) > 0 ? new Date(data.updatedate) : null;
			}

			this.updateIndices();
		}
	}

	static fromData(data): CompanyPaymentMethod[] {
		if (data == null) {
			return [];
		}
		return data.map((item) => new CompanyPaymentMethod(item));
	}

	updateIndices() {
		/* Updates the indices that are being used for filtering the receipts. */
		if (this.groups && this.groups.length > 0) {
			this.groupsIndex = '|' + this.groups.join('|') + '|';
		}

		this.searchIndex = (this.code + ' - ' + this.name + ' - ' + this.description).toLowerCase();
	}

	isActive() {
		return this.active;
	}

	getGroups(): string[] {
		if (this.groups) {
			return this.groups;
		}
		return [];
	}

	clone(): CompanyPaymentMethod {
		return new CompanyPaymentMethod(JSON.parse(JSON.stringify(this)));
	}

	getID() {
		return this.id;
	}

	getCode() {
		return this.code;
	}

	getName() {
		return this.name;
	}

	getSubtitle() {
		let subtitle = '';
		if (this.code) {
			subtitle += this.code;
		}

		if (this.description) {
			if (subtitle !== '') {
				subtitle += ' ~ ';
			}
			subtitle += this.description;
		}

		return subtitle;
	}

	matches(search: string): boolean {
		/** Returns whether this currency matches a given search string. */
		return (
			this.getName().toLowerCase().includes(search.toLowerCase()) ||
			this.getCode().toLowerCase().includes(search.toLowerCase()) ||
			this.getSubtitle().toLowerCase().includes(search.toLowerCase())
		);
	}
}

export class PaymentMethodData {
	payment_methods: CompanyPaymentMethod[] = [];
	count: number;
	moreresults: boolean;

	constructor(data = null) {
		if (data) {
			Object.assign(this, data);

			if (data.payment_methods) {
				this.payment_methods = CompanyPaymentMethod.fromData(data.payment_methods);
			}
		}
	}
}

export class PaymentMethodTransactionData {
	payment_method_transactions: PaymentMethodTransaction[] = [];
	count: number;
	moreresults: boolean;

	constructor(data = null) {
		if (data) {
			Object.assign(this, data);

			if (data.payment_method_transactions) {
				this.payment_method_transactions = PaymentMethodTransaction.fromData(data.payment_method_transactions);
			}
		}
	}
}

export class PaymentMethodTransaction {
	id: string;
	createdate: Date;
	updatedate: Date;
	company: string;
	payment_method: string;
	user: string;
	description: string;
	amount: number;

	constructor(data = null) {
		if (data) {
			Object.assign(this, data);

			if (data.createdate) {
				this.createdate = Date.parse(data.createdate) > 0 ? new Date(data.createdate) : null;
			}
			if (data.updatedate) {
				this.updatedate = Date.parse(data.updatedate) > 0 ? new Date(data.updatedate) : null;
			}
		}
	}

	static fromData(data): PaymentMethodTransaction[] {
		if (data == null) {
			return [];
		}
		return data.map((item) => new PaymentMethodTransaction(item));
	}

	clone(): PaymentMethodTransaction {
		return new PaymentMethodTransaction(JSON.parse(JSON.stringify(this)));
	}
}

export class PaymentMethodListAPIRequest extends CompanyObjectListAPIRequest {
	name: string;
	description: string;
	balance: number;

	getURL(): string {
		if (this.list) {
			if (stringIsSetAndFilled(this.company)) {
				return `/api/v1/company/${this.company}/paymentMethod/list`;
			}

			return `/api/v1/paymentMethod/list`;
		}
		if (stringIsSetAndFilled(this.company)) {
			return `/api/v1/company/${this.company}/paymentMethod`;
		}

		return `/api/v1/paymentMethod`;
	}
}

export class PaymentMethodTransactionListAPIRequest extends ListAPIRequest {
	paymentMethodID: string;
	company: string;

	constructor(paymentMethodID: string) {
		super();
		this.paymentMethodID = paymentMethodID;
	}

	getURL(): string {
		if (this.company && this.company !== '') {
			return `/api/v1/company/${this.company}/paymentMethod/${this.paymentMethodID}/transactions`;
		}

		return `/api/v1/paymentMethod/${this.paymentMethodID}/transactions`;
	}
}
