import { Order } from '#/models/utils/order';

export class DefaultFilters {
	public start: number;
	public max: number;
	public sortOrder: Order;
	public search?: string;

	constructor(data: Record<string, any> = {}) {
		this.start = data.start ?? 0;
		this.max = data.max ?? 10;
		this.sortOrder = data.sortOrder ?? Order.ASCENDING;
		this.search = data.search;
	}
}
