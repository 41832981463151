import { apiToFrontend } from './transformer';
import { CategoryAccountingDefaults, CategoryTransactionType } from '#/models/company/category.model';
import { CategoryDefaultsApiModel } from '#/models/transaction/category/categoryDefaults/apiModel';
import { CategoryFrontendModel } from '#/models/transaction/category/frontendModel';

export class CategoryApiModel {
	id: string;
	code?: string;
	name?: string;
	types?: Array<CategoryTransactionType>;
	registrationType: string;
	createdate?: Date;
	updatedate?: Date;
	groups?: Array<string>;
	active?: boolean;
	description?: string;
	merchants?: Array<string>;
	defaults?: CategoryDefaultsApiModel;
	accountingDefaults?: CategoryAccountingDefaults;
	vat_deductible?: boolean;
	module_leveled_travel_expense_compensation_per_km?: number;
	from_date?: Date;
	to_date?: Date;

	constructor(data?: any) {
		if (data) {
			Object.assign(this, data);
		}
	}

	transformToFrontend(): CategoryFrontendModel {
		return apiToFrontend(this);
	}
}
