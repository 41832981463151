import { InterfaceApiModel } from '#/models/transaction/interface/apiModel';
import { InterfaceFrontendModel, UIField } from '#/models/transaction/interface/frontendModel';
import { mapObjectValues } from '#/util/objects';
import { TransactionType } from '#/models/transaction/transactionType';
import { isValueSet } from '#/util/values';

export function apiToFrontend(apiModel: InterfaceApiModel): InterfaceFrontendModel {
	const result = new InterfaceFrontendModel();
	result.id = apiModel.id;
	result.menuCaption = apiModel.description;
	result.name = apiModel.name;
	result.allowAttachments = apiModel.allow_attachments;
	result.allowedGroups = apiModel.allowed_groups;
	result.markNoAttachmentAvailable = apiModel.markNoAttachmentAvailable;
	result.ocrEnabled = apiModel.ocr_enabled;
	result.ocrMode = apiModel.ocrMode;
	result.visualizeGoogleMaps = apiModel.visualizeMap;
	result.transactionType = apiModel.type as TransactionType;
	result.displayOrder = apiModel.displayOrder;
	result.ordering = apiModel.order;
	result.active = apiModel.active;
	result.usedForPreTransactions = apiModel.usedForPreTransactions;
	result.inputFields = mapObjectValues(apiModel.input_fields, (e) => {
		return {
			label: e?.label,
			isVisibleOnPreTransaction: e?.is_visible_on_pre_transaction,
			isEditableOnPreTransaction: e?.is_editable_on_pre_transaction,
			isRequiredOnPreTransaction: e?.is_required_on_pre_transaction,
			isVisibleOnSubmit: e?.is_visible_on_submit,
			isEditableOnSubmit: e?.is_editable_on_submit,
			isRequiredOnSubmit: isValueSet(e?.is_required_on_submit) ? e?.is_required_on_submit : e?.required,
			isVisibleOnApprove: e?.is_visible_on_approve,
			isEditableOnApprove: e?.is_editable_on_approve,
			isVisibleOnFinance: e?.is_visible_on_finance,
			isEditableOnFinance: e?.is_editable_on_finance,
			preset: e?.preset,
		};
	});
	return result;
}

export function frontendToApi(frontendModel: InterfaceFrontendModel): InterfaceApiModel {
	const result = new InterfaceApiModel();
	result.id = frontendModel.id;
	result.description = frontendModel.menuCaption;
	result.name = frontendModel.name;
	result.allow_attachments = frontendModel.allowAttachments;
	result.allowed_groups = frontendModel.allowedGroups;
	result.markNoAttachmentAvailable = frontendModel.markNoAttachmentAvailable;
	result.ocr_enabled = frontendModel.ocrEnabled;
	result.ocrMode = frontendModel.ocrMode;
	result.visualizeMap = frontendModel.visualizeGoogleMaps;
	result.type = frontendModel.transactionType;
	result.displayOrder = frontendModel.displayOrder;
	result.order = frontendModel.ordering;
	result.active = frontendModel.active;
	result.usedForPreTransactions = frontendModel.usedForPreTransactions;
	result.input_fields = mapObjectValues(frontendModel.inputFields, (e: UIField<any>) => {
		let preset = e?.preset;
		if (Array.isArray(preset) && preset.length === 0) {
			preset = null;
		}
		return {
			label: e?.label?.trim(),
			is_visible_on_pre_transaction: e?.isVisibleOnPreTransaction,
			is_editable_on_pre_transaction: e?.isEditableOnPreTransaction,
			is_required_on_pre_transaction: e?.isRequiredOnPreTransaction,
			is_visible_on_submit: e?.isVisibleOnSubmit,
			is_editable_on_submit: e?.isEditableOnSubmit,
			is_required_on_submit: e?.isRequiredOnSubmit,
			is_visible_on_approve: e?.isVisibleOnApprove,
			is_editable_on_approve: e?.isEditableOnApprove,
			is_visible_on_finance: e?.isVisibleOnFinance,
			is_editable_on_finance: e?.isEditableOnFinance,
			required: e?.isRequiredOnSubmit,
			preset,
		};
	});
	return result;
}
