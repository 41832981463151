<ng-template ngFor let-group [ngForOf]="availableScopes">
	<div *ngIf="groupHasEnabledScopes(group)">
		<h5 class="pt-2">{{group.name}}</h5>
		<ng-template ngFor let-scope [ngForOf]="group.scopes">
			<div *ngIf="isChecked(scope)">
				<div class="d-flex align-items-center">
					<div class="toggle-checkbox toggle-primary checkbox-inline toggle-sm disabled">
						<input [id]="scope.id" type="checkbox" [name]="scope.id" [checked]="true" [disabled]="true" />
						<label class="mb-0" [for]="scope.id"></label>
					</div>
					<label>
						<i [class]="'fa fa-' + (scope.type === 'view' ? 'eye' : 'edit')"></i>
						{{ scope.name }}
					</label>
				</div>
			</div>
		</ng-template>
	</div>
</ng-template>
