import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BaseComponent } from '../../../../base/base.component';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { getBookingProviderLabel, Receipt } from '#/models/transaction/receipt';
import { AccountingIntegrationV1, Company } from '#/models/company/company.model';
import { TranslateService } from '@ngx-translate/core';
import { format } from '~/app/util/i18n';
import { TimezoneService } from '~/app/services/timezone.service';
import { Themed } from '#/providers/themed';
import { isValueSet } from '#/util/values';

@Component({
	selector: 'app-receipt-booking-status',
	templateUrl: './receipt-booking-status.component.html',
	styleUrls: ['./receipt-booking-status.component.scss'],
})
export class ReceiptBookingStatusComponent extends BaseComponent {
	@Input() company: Company;
	@Input() receipt: Receipt;
	@Input() integrations: Array<AccountingIntegrationV1>;
	@Output() receiptChange = new EventEmitter<Receipt>();

	constructor(public translate: TranslateService, private timezoneService: TimezoneService, private themed: Themed) {
		super();
	}

	getBookingInformation(): string {
		if (this.receipt.isBooked()) {
			// @todo: add bookedby? Resolve to company user.

			if (!isValueSet(this.receipt.bookingstatus?.bookedon)) {
				return '';
			}

			return this.translate.instant(_('Booked to %provider% on %date%'), {
				provider: getBookingProviderLabel(this.receipt.bookingstatus.externalprovider, this.themed.getThemeProductTitle()),
				date: format(this.receipt.bookingstatus.bookedon, 'PPPPp'),
			});
		}
		return '';
	}

	async bookReceipt() {
		const timezone = await this.timezoneService.guessTimezone();
		const updatedReceipt = await this.receiptAPIService.bookExpense(this.receipt, timezone);
		this.receipt.setData(updatedReceipt);
		this.receiptChange.emit(updatedReceipt);
		return false;
	}
}
