export class TrustedContact {
	id: string;
	name: string;
	email: string;
	adddate: Date;

	constructor(data = null) {
		if (data) {
			Object.assign(this, data);

			if (data.adddate) {
				this.adddate = Date.parse(data.adddate) > 0 ? new Date(data.adddate) : null;
			}
		}
	}
	static fromData(data): Array<TrustedContact> {
		if (data == null) {
			return [];
		}

		return data.map((item) => new TrustedContact(item));
	}
}

export class ExportTemplateColumn {
	type: string;
	label: string;
	value: string;
	enabled: boolean;
	add_currency_symbol?: boolean;
	date_format?: string;
	custom_label?: string;

	constructor(data = null) {
		if (data) {
			Object.assign(this, data);
		}
	}

	static fromData(data): Array<ExportTemplateColumn> {
		if (data == null) {
			return [];
		}

		return data.map((item) => new ExportTemplateColumn(item));
	}
}

export class ExportTemplate {
	columns: ExportTemplateColumn[];
	travel_columns: ExportTemplateColumn[];
	travel_and_receipt_columns: ExportTemplateColumn[];
	creditcard_statement_columns: ExportTemplateColumn[];
	user_integration_columns: ExportTemplateColumn[];

	constructor(data = null) {
		if (data) {
			Object.assign(this, data);

			if (data.columns) {
				this.columns = ExportTemplateColumn.fromData(data.columns);
			}

			if (data.travel_columns) {
				this.travel_columns = ExportTemplateColumn.fromData(data.travel_columns);
			}
		}
	}
}
