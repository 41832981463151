<ng-template #changePasswordModal>
	<div class="modal-header">
		<h4 class="modal-title" translate>New password required</h4>
		<button type="button" class="close" aria-label="Close" (click)="close()">
			<span aria-hidden="true">&times;</span>
		</button>
	</div>
	<div class="modal-body">
		<p translate>
			For security reasons we now require your password to contain at least 8 characters, please change your password accordingly
		</p>
		<klp-form [formGroup]="changePasswordForm">
			<klp-form-element caption="{{ 'Current password' | translate }}">
				<klp-form-text-input type="password" formControlName="currentPassword"></klp-form-text-input>
			</klp-form-element>
			<klp-form-element caption="{{ 'New password' | translate }}">
				<klp-form-text-input type="password" formControlName="password"></klp-form-text-input>
				<app-password-strength-meter *ngIf="f.password?.length > 0" [password]="f.password"></app-password-strength-meter>
			</klp-form-element>
			<klp-form-element caption="{{ 'Confirm password' | translate }}">
				<klp-form-text-input type="password" formControlName="password_repeat"></klp-form-text-input>
				<klp-form-error error="MatchPassword">{{ 'Passwords must match' | translate }}</klp-form-error>
			</klp-form-element>
			<klp-form-submit-button [submitCallback]="changePassword">{{ 'Change' | translate }}</klp-form-submit-button>
		</klp-form>
		<ngx-async-template [promise]="changePasswordPromise">
			<ng-template ngx-async-error let-errorMessages="errorMessages">
				<app-error-message [errorMessages]="errorMessages"></app-error-message>
			</ng-template>
		</ngx-async-template>
	</div>
</ng-template>
