<td></td>
<td *ngFor="let column of columns" [ngSwitch]="column.id">
	<div *ngSwitchCase="'description'">
		<span *ngIf="tableType === 'personal' || tableType === 'expenses'">{{
			'%receiptCount% receipts in this view' | translate: translateReceiptCount
		}}</span>
		<span *ngIf="tableType === 'invoices'">{{ '%receiptCount% invoices in this view' | translate: translateReceiptCount }}</span>
	</div>
	<div *ngSwitchCase="'user'">
		<span>{{ '%userCount% persons' | translate: translateUserCount }}</span>
	</div>
	<div *ngSwitchCase="'amount'">
		<span *ngIf="oneCurrency">{{ [totalAmount, currency] | convertToAmountWithCurrencyType | amountWithCurrency }}</span>
	</div>
	<div *ngSwitchCase="'vat_amount'">
		<span *ngIf="oneCurrency">{{ [totalVATAmount, currency] | convertToAmountWithCurrencyType | amountWithCurrency }}</span>
	</div>
</td>
<td></td>
<td></td>
