import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { isValueSet, stringIsSetAndFilled } from '#/util/values';
import { DeclarationStatusFlag } from '#/models/transaction/receipt';

export class MetaData {
	receipt: string;
	receipt_description: string;

	report: string;
	report_description: string;

	group: string;
	group_title: string;

	shared_by_email: string;
	shared_by_id: string;
	shared_by_name: string;

	declaration_comment: string;
	declaration_new_status: DeclarationStatusFlag;
	declaration_old_status: DeclarationStatusFlag;

	updated_by_email: string;
	updated_by_id: string;
	updated_by_name: string;

	message: string;
	title?: string;

	receipt_is_invoice: string;

	constructor(data = null) {
		if (data) {
			for (const key of Object.keys(data)) {
				this[key.replace(/-/g, '_')] = data[key];
			}
		}
	}

	getReceiptDescription(): string {
		if (stringIsSetAndFilled(this.receipt_description)) {
			return this.receipt_description;
		}
		if (stringIsSetAndFilled(this.report_description)) {
			return this.report_description;
		}
		return _('No description');
	}

	getReceipt(): string {
		return this.receipt;
	}

	getGroupTitle(): string {
		if (this.group_title && this.group_title !== '') {
			return this.group_title;
		}
		return _('No description');
	}

	getGroup(): string {
		return this.group;
	}

	getName() {
		return this.getSharedByName() ? this.getSharedByName() : this.getUpdatedByName();
	}

	getEmail() {
		return this.getSharedByEmail() ? this.getSharedByEmail() : this.getUpdatedByEmail();
	}

	getTitle(short = true) {
		const name = this.getName();
		const email = this.getEmail();
		if (name && email && !short) {
			return `${name} (${email})`;
		} else {
			return name || email;
		}
	}

	getSharedByName() {
		return this.shared_by_name;
	}

	getSharedByEmail() {
		return this.shared_by_email;
	}

	getUpdatedByName() {
		return this.updated_by_name;
	}

	getUpdatedByEmail() {
		return this.updated_by_email;
	}

	getDeclarationComment() {
		return this.declaration_comment;
	}

	getDeclarationNewStatus() {
		return this.declaration_new_status;
	}

	getDeclarationOldStatus() {
		return this.declaration_old_status;
	}

	getMessage(): string {
		return this.message;
	}
}

export enum InboxItemType {
	ReceiptShare = 'receipt-share',
	GroupShare = 'group-share',
	ReceiptDeclarationUpdate = 'receipt-declaration-update',
	ReceiptOCRProcessed = 'receipt-ocr-processed',
	ReceiptMessage = 'receipt-message',
	ReportMessage = 'report-message',
	ReceiptDeclined = 'receipt-declined',
	ReportStatusChange = 'report-status-change',
	AuthorizationFlowTurn = 'authorization-flow-turn',
	ReportAuthorizationFlowTurn = 'report-authorization-flow-turn',
	ReportCardSettledTransaction = 'report-card-settled-transaction',
	ReportCardUnsettledTransaction = 'report-card-unsettled-transaction',
	BasicMessage = 'basic-message',
}

export function inboxItemTypeLabel(inboxItemType: InboxItemType) {
	switch (inboxItemType) {
		case InboxItemType.ReceiptShare:
			return _('Shared receipt');
		case InboxItemType.GroupShare:
			return _('Shared group');
		case InboxItemType.ReceiptDeclarationUpdate:
			return _('Declaration update');
		case InboxItemType.ReceiptOCRProcessed:
			return _('OCR processed');
		case InboxItemType.ReceiptMessage:
			return _('Message about receipt');
		case InboxItemType.ReportMessage:
			return _('Message about report');
		case InboxItemType.ReceiptDeclined:
			return _('Invoice declined');
		case InboxItemType.ReportStatusChange:
			return _('Report status change');
		case InboxItemType.AuthorizationFlowTurn:
		case InboxItemType.ReportAuthorizationFlowTurn:
			return _('It is your turn to approve a document');
		case InboxItemType.ReportCardUnsettledTransaction:
			return _('New card transaction, add your receipt now.');
		case InboxItemType.ReportCardSettledTransaction:
			return _('Your payments are final, submit your expenses now.');
		default:
			return inboxItemType;
	}
}

export class InboxItem {
	id: string;
	type: InboxItemType;
	date: Date;
	target_type: string;
	target_user: string;
	target_group: string;
	target_app: string;
	metadata: MetaData;
	read: boolean;
	read_on: Date;

	/* Internal use */
	header: string = null;

	static fromData(data): InboxItem[] {
		if (data == null) {
			return [];
		}
		return data.map((item) => new InboxItem(item));
	}

	constructor(data = null) {
		if (data) {
			Object.assign(this, data);
			this.date = Date.parse(data.date) > 0 ? new Date(data.date) : null;
			this.read_on = Date.parse(data.read_on) > 0 ? new Date(data.read_on) : null;

			if (data.metadata) {
				this.metadata = new MetaData(data.metadata);
			}
		}
	}

	getID(): string {
		return this.id;
	}

	getType(): InboxItemType {
		return this.type;
	}

	getMetaData(): MetaData {
		return this.metadata;
	}

	getTitle(): string {
		// TODO: this whole thing needs to be rethought and refactored, very weird things happening here
		switch (this.getType()) {
			case InboxItemType.ReceiptMessage:
				return this.getMetaData().getMessage();
			case InboxItemType.BasicMessage:
				return this.getMetaData().title;
			case InboxItemType.GroupShare:
				return this.getMetaData().getGroupTitle();
			default:
				return this.getMetaData().getReceiptDescription();
		}
	}

	getMessage(): string {
		if (this.metadata?.getDeclarationNewStatus()) {
			return _('Status updated to %status%');
		} else if (isValueSet(this.metadata?.message)) {
			return this.getMetadataMessage();
		}
		return inboxItemTypeLabel(this.getType());
	}

	getFrom(short = true): string {
		return this.getMetaData().getTitle(short);
	}

	getItemType() {
		if (this.getType() === InboxItemType.GroupShare) {
			return 'group';
		}
		return 'receipt';
	}

	getDate(): Date {
		return this.date;
	}

	getReadOn(): Date {
		return this.read_on;
	}

	private getMetadataMessage(): string {
		return this.metadata.message;
	}

	isRead(): boolean {
		return this.read;
	}

	getHeader() {
		return this.header;
	}

	setHeader(str: string) {
		this.header = str;
		return this;
	}
}
