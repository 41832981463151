<klp-grid-container [fluid]="true">
	<app-nav-and-cta>
		<klp-gretel-bar></klp-gretel-bar>
		<app-button variant="greenFilled" id="addDropdown" (click)="tableActionNew($event)">
			<i class="ti-plus"></i> {{'Add' | translate}}
		</app-button>
	</app-nav-and-cta>
	<div class="row">
		<div class="col-md-12">
			<div class="card">
				<div class="card-block">
					<app-loader-deprecated *ngIf="!initialLoaded"></app-loader-deprecated>
					<div *ngIf="initialLoaded" id="DataTables_Table_1_wrapper" class="dataTables_wrapper no-footer">
						<div class="dataTables_length">
							<div class="float-left table-header-action-row" *ngIf="(groups | async).length > 0">
								<label *ngIf="getSelectedGroupsCount() > 0"> {{getSelectedGroupsCount()}} <span translate>folders selected</span> </label>
								<label *ngIf="getSelectedGroupsCount() == 0"> &nbsp; </label>
							</div>
						</div>

						<app-folders-table
							[folders]="groups | async"
							[(checkboxes)]="checkboxes"
							[(checkAll)]="checkAll"
							(groupsChanged)="updateGroups()"
						></app-folders-table>
					</div>
				</div>
			</div>
		</div>
	</div>
</klp-grid-container>
