import { Injectable } from '@angular/core';
import { CompanyProjectData, CompanyProjectListAPIRequest, Project } from '#/models/company/dimension/project.model';
import { ImportResultCount } from '#/models/import-result.model';
import { CompanyDimensionsService } from '#/services/company/dimension/company-dimensions.service';
import { arrayIsSetAndFilled } from '#/util/arrays';

@Injectable({
	providedIn: 'root',
})
export class CompanyProjectService extends CompanyDimensionsService {
	public async getCompanyProjects(companyProjectListAPIRequest: CompanyProjectListAPIRequest): Promise<CompanyProjectData> {
		companyProjectListAPIRequest.list = true;
		if (arrayIsSetAndFilled(companyProjectListAPIRequest.ids)) {
			companyProjectListAPIRequest.max = 9999;
		}
		return this.apiService
			.post(companyProjectListAPIRequest.getRequestURL(), this.getRequestBody(companyProjectListAPIRequest))
			.then((r) => {
				return new CompanyProjectData(r.data);
			})
			.catch((e) => {
				this.notifications.handleAPIError(e);
				throw e;
			});
	}

	getCompanyProjectsFilteredOnPresetsAndAdministration(
		ListAPIRequest: CompanyProjectListAPIRequest,
		presetOptions: string[],
		administration,
	): Promise<CompanyProjectData> {
		return this.getCompanyProjects(ListAPIRequest).then((data) => {
			const result = data;
			result.company_projects = this.filterBasedOnPresetsAndAdministration(data.company_projects, presetOptions, administration);
			return result;
		});
	}

	importCompanyProjects(companyId: string, administration: string, file: File, skipFirstRow = true): Promise<ImportResultCount> {
		const formData = new FormData();
		formData.append('file', file);

		if (skipFirstRow) {
			formData.append('skip_first_row', '1');
		}
		if (administration) {
			formData.append('administration', administration);
		}

		let url = '/api/v1/companyProject/import';
		if (companyId !== '') {
			url = '/api/v1/company/' + companyId + '/companyProject/import';
		}

		return this.apiService
			.post(url, formData)
			.then((r) => {
				return Promise.resolve(new ImportResultCount(r.data));
			})
			.catch((e) => {
				this.notifications.handleAPIError(e);
				return Promise.reject(e);
			});
	}

	bulkCompanyProjects(companyId: string, type: string, projects: Project[]): Promise<ImportResultCount> {
		let url = '/api/v1/companyProject/bulk';
		if (companyId !== '') {
			url = '/api/v1/company/' + companyId + '/companyProject/bulk';
		}

		return this.apiService
			.post(url, {
				type,
				projects,
			})
			.then((r) => {
				return Promise.resolve(new ImportResultCount(r.data));
			})
			.catch((e) => {
				this.notifications.handleAPIError(e);
				return Promise.reject(e);
			});
	}

	createCompanyProject(companyId: string, project: Project): Promise<Project> {
		let url = '/api/v1/companyProject';
		if (companyId !== '') {
			url = '/api/v1/company/' + companyId + '/companyProject';
		}

		return this.apiService
			.post(url, project)
			.then((r) => {
				return Promise.resolve(new Project(r.data));
			})
			.catch((e) => {
				this.notifications.handleAPIError(e);
				return Promise.reject(e);
			});
	}

	updateCompanyProject(companyId: string, project: Project): Promise<Project> {
		let url = '/api/v1/companyProject/' + project.id;
		if (companyId !== '') {
			url = '/api/v1/company/' + companyId + '/companyProject/' + project.id;
		}

		return this.apiService
			.patch(url, project)
			.then((r) => {
				return Promise.resolve(new Project(r.data));
			})
			.catch((e) => {
				this.notifications.handleAPIError(e);
				return Promise.reject(e);
			});
	}

	getCompanyProject(companyId: string, id: string): Promise<Project> {
		let url = '/api/v1/companyProject/' + id;
		if (companyId !== '') {
			url = '/api/v1/company/' + companyId + '/companyProject/' + id;
		}
		if (CompanyDimensionsService.requestCache.has(url)) {
			return CompanyDimensionsService.requestCache.get(url);
		}
		const promise = this.apiService
			.get(url)
			.then((r) => {
				CompanyDimensionsService.requestCache.delete(url);
				return Promise.resolve(new Project(r.data));
			})
			.catch((e) => {
				this.notifications.handleAPIError(e);
				CompanyDimensionsService.requestCache.delete(url);
				return Promise.reject(e);
			});
		CompanyDimensionsService.requestCache.set(url, promise);
		return promise;
	}

	deleteCompanyProject(companyId: string, id: string): Promise<any> {
		let url = '/api/v1/companyProject/' + id;
		if (companyId !== '') {
			url = '/api/v1/company/' + companyId + '/companyProject/' + id;
		}

		return this.apiService
			.delete(url)
			.then((r) => {
				return Promise.resolve(r);
			})
			.catch((e) => {
				this.notifications.handleAPIError(e);
				return Promise.reject(e);
			});
	}
}
