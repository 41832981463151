// hmac_sha512 hashes data using the secret and tries to do that using the buildin borwser crypto tools
// If the buildin tools are not available js-sha512 will be loaded in and that will be used
export const hmac_sha512 = async (secret: string, data: string): Promise<string> => {
	if (!window.crypto || !window.crypto.subtle || !window.crypto.subtle.importKey || !window.crypto.subtle.sign) {
		return await fallback_hmac_sha512(secret, data);
	}

	try {
		const enc = new TextEncoder();

		const key = await window.crypto.subtle.importKey(
			'raw',
			enc.encode(secret),
			{
				name: 'HMAC',
				hash: { name: 'SHA-512' },
			},
			false, // Make this key not exportable
			['sign'] // Only allow signing using this key
		);

		const sig = await window.crypto.subtle.sign('HMAC', key, enc.encode(data));

		const sigBytes = new Uint8Array(sig);

		return Array.prototype.map.call(sigBytes, (x) => ('00' + x.toString(16)).slice(-2)).join('');
	} catch (e) {
		// The browser support for window.crypto is a bit wired because of that the crypto functions are wrapped inside a try catch
		return await fallback_hmac_sha512(secret, data);
	}
};

const fallback_hmac_sha512 = async (secret: string, data: string): Promise<string> => {
	const jsSha = await import('js-sha512');
	return jsSha.sha512.hmac(secret, data);
};
