import { Component, Input } from '@angular/core';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { Themed } from '#/providers/themed';

@Component({
	selector: 'app-empty-view',
	templateUrl: './empty-view.component.html',
	styleUrls: ['empty-view.component.scss'],
	providers: [Themed],
})
export class EmptyViewComponent {
	@Input() public text: string = _('No items available.');
	@Input() public subtitle;
	@Input() public gif: '1' | '2' | '3' | '4' | '5' = '1';
	public theme: string = 'klippa';

	constructor(private themed: Themed) {
		this.theme = themed.getTheme();
	}
}
