import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { NotificationService } from '~/app/services/notification.service';

export type NotificationEvents = 'show' | 'hide';
export interface ObservableOptions {
	category: NotificationCategory;
	description: string;
}
export type NotificationFundamentalOptions = {
	category: NotificationCategory;
	description: string;
	observable?: Observable<ObservableOptions>;
} & NotificationConfig;
export type CloseHandler = () => void;
export interface NotificationConfig {
	bodyMaxLength?: number;
	timeout?: number;
	buttons?: Array<NotificationButton>;
	onDismissClick?: () => void;
}
export type NotificationCategory = 'warning' | 'success' | 'error' | 'info';
export interface NotificationButton {
	text: string;
	action: (CloseHandler) => void;
}

export type ObservableConfig = Observable<{ description: string }>;

interface NotificationEntity {
	isVisible: boolean;
	category: NotificationCategory;
	description: string;
	buttons: Array<NotificationButton>;
	onDismissClick?: () => void;
}

const defaultTimeout = 5000;
@Component({
	selector: 'app-toast-notifications-manager',
	templateUrl: './toast-notifications-manager.component.html',
	styleUrls: ['./toast-notifications-manager.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ToastNotificationsManagerComponent implements OnInit {
	public activeEntities: Array<NotificationEntity> = [];
	showApi = new Observable<NotificationFundamentalOptions>();
	hideApi = new Observable<null>();

	constructor(private notificationService: NotificationService, private changeDetector: ChangeDetectorRef) {}

	ngOnInit(): void {
		this.showApi = new Observable<NotificationFundamentalOptions>((subscriber) => {
			this.notificationService.registerShowApi(subscriber);
		});
		this.hideApi = new Observable<null>((subscriber) => {
			this.notificationService.registerHideApi(subscriber);
		});

		this.showApi.subscribe((options) => {
			this.show(options);
		});
		this.hideApi.subscribe(() => {
			this.activeEntities = [];
		});
	}

	show(options: NotificationFundamentalOptions): void {
		const { category, description, timeout = defaultTimeout, buttons = [], onDismissClick } = options;
		const duplicateNotification = this.activeEntities.find((e) => e.description === description);
		const entity: NotificationEntity = {
			isVisible: true,
			category,
			description,
			buttons,
			onDismissClick,
		};

		if (duplicateNotification) {
			this.closeInstantly(duplicateNotification);
			setTimeout(() => {
				this.show(options);
			}, 100); // tiny delay so it actually feels like a new notification
		} else {
			this.activeEntities.push(entity);
			this.changeDetector.markForCheck();
		}

		// if we have buttons in our notification, dont auto-close the notification. That would be bad UX.
		// same goes for updatable notifications
		const { observable } = options;
		if (entity.buttons.length === 0 && !observable) {
			setTimeout(() => {
				this.hide(entity, true);
			}, timeout);
		}

		if (observable) {
			observable.subscribe({
				next: (val) => {
					entity.category = val.category;
					entity.description = val.description;
				},
				error: (err) => {
					setTimeout(() => {
						this.hide(entity, true);
					}, timeout);
				},
				complete: () => {
					setTimeout(() => {
						this.hide(entity, true);
					}, timeout);
				},
			});
		}
	}

	closeInstantly(entity: NotificationEntity): void {
		entity.onDismissClick?.();
		this.hide(entity, false);
	}

	private removeEntityFromArray(entity: NotificationEntity) {
		this.activeEntities = this.activeEntities.filter((e) => e !== entity);
		this.changeDetector.markForCheck();
	}

	hide(entity: NotificationEntity, withTransition: boolean = true): void {
		if (withTransition) {
			entity.isVisible = false;
			this.changeDetector.markForCheck();
			setTimeout(() => {
				this.removeEntityFromArray(entity);
			}, 500); // this has to match the animation defined in the notification itself
		} else {
			this.removeEntityFromArray(entity);
		}
	}
}
