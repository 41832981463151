import { Component } from '@angular/core';
import { InboxComponent } from '~/app/pages/authenticated/inbox/inbox.component';

@Component({
	selector: '[app-inbox-notification]',
	templateUrl: './inbox-notification.component.html',
	styleUrls: ['./inbox-notification.component.scss'],
})
export class InboxNotificationComponent extends InboxComponent {
	domChange(e: MutationRecord) {
		if (e.type === 'attributes' && e.target) {
			const classes = Array.from((e.target as HTMLElement).classList);
			const showing = classes.includes('show');
			if (!showing) {
				/* Notification list became hidden. */
				this.markRead(true);
			}
		}
	}

	public getUnreadCounter(): string {
		return this.unreadCounter > 99 ? '99+' : this.unreadCounter.toString();
	}
}
