import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Company } from '#/models/company/company.model';
import { CompanyService } from '#/services/company/company.service';
import { Project } from '#/models/company/dimension/project.model';
import { CompanyProjectService } from '#/services/company/dimension/company-project.service';
import { UserService } from '~/app/modules/user/user.service';

@Component({
	selector: 'app-company-project-label',
	templateUrl: './company-project-label.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CompanyProjectLabelComponent implements OnInit, OnDestroy {
	private static objectCache = new Map<string, Project>();
	public value: string;

	@Input() public project = '';

	@Input() public company: Company;

	public projectObject?: Project;
	destroyed = false;

	constructor(
		private ref: ChangeDetectorRef,
		private userService: UserService,
		protected companyAPIService: CompanyService,
		private companyProject: CompanyProjectService,
	) {}

	ngOnInit() {
		this.loadProject();
	}

	ngOnDestroy() {
		this.destroyed = true;
	}

	updateView() {
		if (!this.destroyed) {
			this.ref.markForCheck();
		}
	}

	loadProject() {
		this.projectObject = null;
		if (this.project && this.project !== '') {
			if (CompanyProjectLabelComponent.objectCache.has(this.project)) {
				this.projectObject = CompanyProjectLabelComponent.objectCache.get(this.project);
				this.updateView();
			} else {
				this.companyProject
					.getCompanyProject(this.company?.id ?? this.userService.getCurrentLoggedUser().company, this.project)
					.then((project) => {
						CompanyProjectLabelComponent.objectCache.set(this.project, project);
						this.projectObject = project;

						this.updateView();
					});
			}
		} else {
			this.updateView();
		}
	}
}
