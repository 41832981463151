export class IntegrationPaymentMethodData {
	payment_methods: Array<IntegrationPaymentMethod> = [];
	count: number;
	moreresults: boolean;

	constructor(data = null) {
		if (data) {
			Object.assign(this, data);

			if (data.payment_conditions) {
				this.payment_methods = IntegrationPaymentMethod.fromData(data.payment_conditions);
			}
		}
	}
}

export class IntegrationPaymentMethod {
	id: string;
	Code: string;
	Description: string;
	Identifier: string;
	Name: string;

	constructor(data) {
		if (data) {
			Object.assign(this, data);
		}
		this.id = this.Identifier;
	}
	static fromData(data): Array<IntegrationPaymentMethod> {
		if (data == null) {
			return [];
		}
		return data.map((item) => new IntegrationPaymentMethod(item));
	}
}
