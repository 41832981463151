<div class="virus-scan-container">
	<label class="warning-title">{{ filename }}</label>
	<div class="warning-container">
		<div *ngIf="variant === 'hasNotBeenScanned'">
			<app-icon variant="alert" class="not-scanned-icon"></app-icon>
			<div translate>Warning: This file has not yet been scanned for viruses.</div>
			<div translate>We advise you wait until the scan has finished before viewing or downloading it.</div>
		</div>
		<div *ngIf="variant === 'virusDetected'">
			<app-icon variant="alert" class="virus-detected-icon"></app-icon>
			<div translate>Warning: A virus has been detected in this file.</div>
			<div translate>We advise you to delete the file and contact the sender.</div>
		</div>
		<div *ngIf="variant === 'couldNotBeScanned'">
			<app-icon variant="alert" class="not-scanned-icon"></app-icon>
			<div translate>Warning: This file could not be scanned for viruses.</div>
		</div>
	</div>
	<div class="button-container">
		<app-button
			*ngIf="variant === 'virusDetected' || variant === 'couldNotBeScanned'"
			[variant]="buttonVariant"
			(click)="download($event)"
			translate
		>
			Click here to download the file at your own risk
		</app-button>
		<app-button *ngIf="variant === 'hasNotBeenScanned'" [variant]="buttonVariant" (click)="show($event)" translate>
			Click here to show the file anyway
		</app-button>
	</div>
</div>
