import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { UserService } from '~/app/modules/user/user.service';
import { CompanyService } from '#/services/company/company.service';
import { AsyncInfoDependantGuard } from '~/app/guards/async-info-dependant-guard.service';
import { CompanyApiService } from '#/services/company/company-api.service';

@Injectable({
	providedIn: 'root',
})
export class ReportRedirectionGuard extends AsyncInfoDependantGuard implements CanActivate {
	constructor(
		protected userService: UserService,
		protected companyService: CompanyService,
		protected companyApiService: CompanyApiService,
		private router: Router,
	) {
		super(userService, companyService, companyApiService);
	}

	async canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<UrlTree> {
		const company = await this.getCompany();
		const hasOutOfPocketReports = company?.modules?.report?.enabled;
		const hasCardReports = company?.modules?.cardReports?.enabled;

		if (state.url?.includes('/reports')) {
			if (hasOutOfPocketReports) {
				return this.router.createUrlTree(['/reports/out-of-pocket']);
			} else if (hasCardReports) {
				return this.router.createUrlTree(['/reports/card']);
			}
		}
		if (state.url?.includes('/manage-reports')) {
			if (hasOutOfPocketReports) {
				return this.router.createUrlTree(['/manage-reports/out-of-pocket']);
			} else if (hasCardReports) {
				return this.router.createUrlTree(['/manage-reports/card']);
			}
		}
		return this.router.createUrlTree(['/dashboard']);
	}
}
