import { Injectable } from '@angular/core';
import { Subscriber } from 'rxjs';
import { ModalOptions } from '~/app/services/modal.service';

@Injectable({
	providedIn: 'root',
})
export class ChangePasswordModalService {
	private showModal: Subscriber<ModalOptions>;

	registerShowModal(changePasswordModal: Subscriber<ModalOptions>) {
		this.showModal = changePasswordModal;
	}

	show(options?: ModalOptions) {
		options = options || {};
		this.showModal.next(options);
	}
}
