import { Component, EventEmitter, Injector, Input, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FolderService } from '~/app/modules/folder/folder.service';
import { User } from '#/models/user/user.model';
import { NotificationService } from '~/app/services/notification.service';
import { TranslateService } from '@ngx-translate/core';
import { UntypedFormBuilder } from '@angular/forms';
import { Store } from '@ngrx/store';
import { AppState } from '~/app/reducers';
import { ModalMessages } from '~/app/helpers/strings';

@Component({
	selector: 'app-folders-modal-base-component',
	template: '',
})
export class FoldersModalBase {
	static injector: Injector;

	@Input() checkboxes: {};
	@Input() user: User;
	@Output() done = new EventEmitter<any>();

	protected notificationService: NotificationService;
	protected folderService: FolderService;
	protected formBuilder: UntypedFormBuilder;
	protected store: Store<AppState>;
	protected translate: TranslateService;

	public inProgress = false;
	protected currentGroup = 0;
	protected failedGroups = 0;
	protected succeededGroups = 0;

	protected destroyCallbacks = [];

	constructor(public activeModal: NgbActiveModal) {
		this.notificationService = FoldersModalBase.injector.get(NotificationService);
		this.folderService = FoldersModalBase.injector.get(FolderService);
		this.formBuilder = FoldersModalBase.injector.get(UntypedFormBuilder);
		this.store = FoldersModalBase.injector.get(Store);
		this.translate = FoldersModalBase.injector.get(TranslateService);
	}

	getSelectedCount(): number {
		return Object.keys(this.checkboxes).filter((checkbox) => this.checkboxes[checkbox]).length;
	}

	getTranslation(str: string) {
		return this.translate.instant(str);
	}

	getUserNamedType(str: string) {
		return ModalMessages.getUserNamedType(str);
	}

	getCurrentCountSentence(type: string, action: string, success: string, count: number, totalCount?: number): string {
		return ModalMessages.getCurrentCountSentence(type, action, success, count, totalCount);
	}

	getCurrentCountSentenceTranslated(type: string, action: string, success: string, count: number, totalCount?: number) {
		const results = ModalMessages.getCurrentCountSentenceTranslated(type, action, success, count, totalCount);
		return this.translate.instant(results.message, results.params);
	}
}
