import { Component, OnInit, ViewChild } from '@angular/core';
import { LoginModalService } from '~/app/services/login-modal.service';
import { Observable } from 'rxjs';
import { ModalOptions, ModalService } from '~/app/services/modal.service';
import { MultipleFactorAuthService } from '~/app/shared/ui/multiple-factor-auth/multiple-factor-auth.service';
import { APIService } from '~/app/api/services/api.service';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ValidateSudoService } from '~/app/services/validate-sudo.service';

@Component({
	selector: 'app-login-modal',
	templateUrl: './login-modal.component.html',
})
export class LoginModalComponent implements OnInit {
	@ViewChild('loginModal') loginModal;
	authMethodEnum = {
		MFA: 'mfa',
		PASSWORD: 'password',
	};

	authMethod: string;
	authMethodPromise: Promise<any>;
	authenticationPromise: Promise<any>;
	showModal = new Observable<ModalOptions>();

	authForm: UntypedFormGroup;

	constructor(
		private loginModalService: LoginModalService,
		private mfaAuthService: MultipleFactorAuthService,
		private modalService: ModalService,
		private apiService: APIService,
		private verifySudoService: ValidateSudoService
	) {}

	ngOnInit(): void {
		this.showModal = new Observable<ModalOptions>((subscriber) => {
			this.loginModalService.registerShowModal(subscriber);
		});

		this.showModal.subscribe((options) => {
			this.show(options);
			this.initAuthMethod();
		});

		this.authForm = new UntypedFormGroup({
			authToken: new UntypedFormControl('', [Validators.required]),
		});
	}

	initAuthMethod() {
		this.authMethodPromise = this.verifySudoService.getAuthMethod().then((res) => {
			if (res === this.authMethodEnum.MFA) {
				this.authMethod = this.authMethodEnum.MFA;
			} else if (res === this.authMethodEnum.PASSWORD) {
				this.authMethod = this.authMethodEnum.PASSWORD;
			}
		});
	}

	verifySudo() {
		if (this.authMethod === this.authMethodEnum.MFA && this.isFormValid()) {
			this.validateWithMfa(this.authForm.value.authToken);
		} else if (this.authMethod === this.authMethodEnum.PASSWORD && this.isFormValid()) {
			this.validateWithPassword(this.authForm.value.authToken);
		}
	}

	validateWithMfa(authToken: string) {
		this.authenticationPromise = new Promise<void>((resolve, reject) => {
			this.verifySudoService
				.validateWithMfa(authToken)
				.then(() => {
					resolve();
					this.loginModalService.resolve();
					this.dismiss();
				})
				.catch(reject);
		});
	}

	validateWithPassword(password: string) {
		this.authenticationPromise = new Promise<void>((resolve, reject) => {
			this.verifySudoService
				.validateWithPassword(password)
				.then(() => {
					resolve();
					this.loginModalService.resolve();
					this.dismiss();
				})
				.catch(reject);
		});
	}

	isFormValid() {
		return this.authForm.status === 'VALID';
	}

	show(options: ModalOptions) {
		this.modalService.open(this.loginModal, options);
	}

	dismiss() {
		this.modalService.dismiss(this.loginModal);
		this.authForm.reset();
	}
}
