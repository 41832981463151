import { Action } from '@ngrx/store';
import { Update } from '@ngrx/entity';

import { Folder } from '#/models/user/folder';

export enum FolderActionTypes {
	LOAD_GROUPS = '[Group] Load Groups',
	ADD_GROUP = '[Group] Add Group',
	UPSERT_GROUP = '[Group] Upsert Group',
	ADD_GROUPS = '[Group] Add Groups',
	UPSERT_GROUPS = '[Group] Upsert Groups',
	UPDATE_GROUP = '[Group] Update Group',
	UPDATE_GROUPS = '[Group] Update Groups',
	DELETE_GROUP = '[Group] Delete Group',
	DELETE_GROUPS = '[Group] Delete Groups',
	CLEAR_GROUPS = '[Group] Clear Groups',
}

export class LoadFolders implements Action {
	readonly type = FolderActionTypes.LOAD_GROUPS;

	constructor(public payload: { groups: Folder[] }) {}
}

export class AddFolder implements Action {
	readonly type = FolderActionTypes.ADD_GROUP;

	constructor(public payload: { group: Folder }) {}
}

export class UpsertFolder implements Action {
	readonly type = FolderActionTypes.UPSERT_GROUP;

	constructor(public payload: { group: Folder }) {}
}

export class AddFolders implements Action {
	readonly type = FolderActionTypes.ADD_GROUPS;

	constructor(public payload: { groups: Folder[] }) {}
}

export class UpsertFolders implements Action {
	readonly type = FolderActionTypes.UPSERT_GROUPS;

	constructor(public payload: { groups: Folder[] }) {}
}

export class UpdateFolder implements Action {
	readonly type = FolderActionTypes.UPDATE_GROUP;

	constructor(public payload: { group: Update<Folder> }) {}
}

export class UpdateFolders implements Action {
	readonly type = FolderActionTypes.UPDATE_GROUPS;

	constructor(public payload: { groups: Update<Folder>[] }) {}
}

export class DeleteFolder implements Action {
	readonly type = FolderActionTypes.DELETE_GROUP;

	constructor(public payload: { id: string }) {}
}

export class DeleteFolders implements Action {
	readonly type = FolderActionTypes.DELETE_GROUPS;

	constructor(public payload: { ids: string[] }) {}
}

export class ClearFolders implements Action {
	readonly type = FolderActionTypes.CLEAR_GROUPS;
}

export type FolderActionsUnion =
	| LoadFolders
	| AddFolder
	| UpsertFolder
	| AddFolders
	| UpsertFolders
	| UpdateFolder
	| UpdateFolders
	| DeleteFolder
	| DeleteFolders
	| ClearFolders;
