import { Component, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { CompanyCardStatementService } from '#/services/company/company-card-statement.service';
import { ModalComponent } from '~/app/shared/ui/modal/modal.component';
import { NotificationService } from '~/app/services/notification.service';
import { TranslateService } from '@ngx-translate/core';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';

@Component({
	selector: 'app-manual-card-upload-modal',
	templateUrl: './manual-card-upload-modal.component.html',
	styleUrls: ['manual-card-upload-modal.component.scss'],
})
export class ManualCardUploadModalComponent {
	@ViewChild('manualCardUploadModal') manualCardUploadModal: ModalComponent;
	public wrapperForm: FormGroup = this.fb.group({ subform: null });

	constructor(
		private companyCardStatementService: CompanyCardStatementService,
		private fb: FormBuilder,
		private notificationService: NotificationService,
		private translation: TranslateService,
	) {}

	public openModal(): void {
		this.manualCardUploadModal.openModal();
	}

	public saveManually = (formData: {
		subform: { administration: string; transactionInterface: string; cardStatement: File };
	}): Promise<void> => {
		const data = formData.subform;
		return this.companyCardStatementService
			.uploadCardStatement(data.cardStatement, data.administration, data.transactionInterface)
			.then(() => {
				const message = this.translation.instant(_('Card statement successfully received, processing might take a few minutes.'));
				this.notificationService.info(message);
				this.manualCardUploadModal.closeModal();
			});
	};
}
