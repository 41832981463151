import { Component } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { arrayIsSetAndFilled } from '#/util/arrays';
import { stringIsSetAndFilled } from '#/util/values';
import { ValueAccessorBase } from '@klippa/ngx-enhancy-forms';

@Component({
	selector: 'app-form-multiple-email',
	templateUrl: './multiple-email.component.html',
	styleUrls: ['./multiple-email.component.scss'],
	providers: [{ provide: NG_VALUE_ACCESSOR, useExisting: MultipleEmailComponent, multi: true }],
})
export class MultipleEmailComponent extends ValueAccessorBase<Array<string>> {
	public helper: Array<string> = [''];
	public trackByIndex = (i) => i;

	writeValue(value: Array<string>) {
		this.helper = value;
		if (arrayIsSetAndFilled(this.helper)) {
			super.writeValue(this.helper);
		} else {
			this.helper = [''];
			// not sure why, but isn't propagated to the outer component if i dont wrap this in a timeout
			setTimeout(() => {
				this.valueChanged();
			});
		}
	}

	valueChanged() {
		this.setInnerValueAndNotify(this.helper.filter(stringIsSetAndFilled));
	}

	addAnother() {
		this.helper = [...this.helper, ''];
		this.valueChanged();
	}

	remove(i: number) {
		this.helper.splice(i, 1);
		this.valueChanged();
	}
}
