<ngx-async-template [promise]="vatOptionsPromise">
	<ng-template ngx-async-success let-vatOptions="value">
		<klp-form-select
			[options]="vatOptions"
			[(ngModel)]="innerValue"
			(ngModelChange)="setInnerValueAndNotify($event)"
			placeholder="{{ 'Select VAT code' | translate }}"
			[multiple]="false"
			[clearable]="true"
			[dropdownPosition]="dropdownPosition"
			[disabled]="disabled"
		>
		</klp-form-select>
	</ng-template>
	<ng-template ngx-async-pending>
		<klp-form-loading-indicator variant="picker"></klp-form-loading-indicator>
	</ng-template>
</ngx-async-template>
