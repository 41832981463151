import { AuthorizationFlow, Company } from '#/models/company/company.model';
import { PaymentMethodListAPIRequest } from '#/models/company/payment-method.model';
import { User } from '#/models/user/user.model';
import { isValueSet, stringIsSetAndFilled } from '#/util/values';
import { Injectable, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { APINotificationsService } from '~/app/api/services/apinotifications.service';
import { CompanyService } from '#/services/company/company.service';
import { SetCompanyAuthorizationFlows, SetCompanyPaymentMethods, UpdateCompany } from '#/models/company/company.actions';
import { FolderService } from '~/app/modules/folder/folder.service';
import { Update } from '~/app/modules/user/models/user.actions';
import { UserService } from '~/app/modules/user/user.service';
import { AppState } from '~/app/reducers';
import { TranslateUtilService } from '~/app/services/translate-util.service';
import { MessageTypes, needsAuthorizationFlowsUpdate, needsPaymentMethodsUpdate, WSMessage } from '#/models/websocket.model';
import { WebsocketsService as Websockets } from '../services/websockets.service';
import { CompanyAuthorizationService } from '#/services/company/company-authorization.service';
import { PaymentMethodService } from '#/services/company/payment-method.service';
import { CompanyApiService } from '#/services/company/company-api.service';

@Injectable({
	providedIn: 'root',
})
export class WebsocketsService implements OnDestroy {
	private websocketSubscription: Subscription;
	protected user: User;
	protected company: Company;

	constructor(
		private websocketsService: Websockets,
		private userApiService: UserService,
		private store: Store<AppState>,
		private translateUtilService: TranslateUtilService,
		private notifications: APINotificationsService,
		private userService: UserService,
		private companyService: CompanyService,
		private companyApiService: CompanyApiService,
		private companyAuthorizationService: CompanyAuthorizationService,
		private paymentMethodService: PaymentMethodService,
		private folderService: FolderService,
	) {}

	public setWebsockets(): void {
		this.user = this.userService.getCurrentLoggedUser();
		this.company = this.companyService.getCompanyOfLoggedUser();

		this.websocketSubscription = this.websocketsService.onMessage.subscribe((message: WSMessage) => {
			if (message.type === MessageTypes.UserUpdate) {
				this.userApiService
					.getCurrentUser()
					.then((r) => {
						const userPayload = new User(r.data.user);
						this.store.dispatch(new Update({ user: userPayload }));

						// Change language.
						if (isValueSet(userPayload.getPreferences()) && stringIsSetAndFilled(userPayload.getPreferences().language)) {
							this.translateUtilService.setLanguage(userPayload.getPreferences().language);
						}
					})
					.catch((r) => {
						this.notifications.handleAPIError(r);
					});
			}

			if (needsAuthorizationFlowsUpdate(message.type) && this.user.canListCompanyAuthorizationFlows()) {
				this.companyAuthorizationService.getAuthorizationFlows(this.company.id, 1000, 1, '', false).then((r) => {
					const AuthorizationFlows: any[] = r.data.authorization_flows;
					const newAuthorizationFlows: AuthorizationFlow[] = [];
					if (isValueSet(AuthorizationFlows)) {
						AuthorizationFlows.forEach((authorizationFlow) => newAuthorizationFlows.push(new AuthorizationFlow(authorizationFlow)));
					}

					this.store.dispatch(new SetCompanyAuthorizationFlows({ companyAuthorizationFlows: newAuthorizationFlows }));
				});
			}

			if (message.type === MessageTypes.CompanyUpdate) {
				this.companyApiService.getCompany(this.user.getCompany()).then((company) => {
					this.store.dispatch(new UpdateCompany({ company: company }));
				});
			}

			if (needsPaymentMethodsUpdate(message.type)) {
				this.paymentMethodService.getPaymentMethods(new PaymentMethodListAPIRequest()).then((paymentMethodData) => {
					this.store.dispatch(new SetCompanyPaymentMethods({ companyPaymentMethods: paymentMethodData.payment_methods }));
				});
			}

			if (
				message.type === MessageTypes.GroupShare ||
				message.type === MessageTypes.GroupRemove ||
				message.type === MessageTypes.GroupAdd ||
				message.type === MessageTypes.GroupUpdate
			) {
				this.folderService.initializeFolders();
			}
		});
	}

	ngOnDestroy() {
		this.websocketSubscription.unsubscribe();
	}
}
