import { Component, EventEmitter, Host, Input, OnInit, Optional, Output } from '@angular/core';
import { CompanyGroup, CompanyGroupListAPIRequest } from '#/models/company/company.model';
import { debounceTime, Subject, switchMap } from 'rxjs';
import { sortBy, uniqWith } from 'lodash';
import { FormElementComponent, ValueAccessorBase } from '@klippa/ngx-enhancy-forms';
import { ControlContainer, NG_VALUE_ACCESSOR } from '@angular/forms';
import { TranslationReplaceService } from '~/app/services/translation-replace.service';
import { CompanyGroupService } from '#/services/company/company-group.service';

@Component({
	selector: 'app-company-group-picker',
	templateUrl: './company-group-picker.component.html',
	styleUrls: ['./company-group-picker.component.scss'],
	providers: [{ provide: NG_VALUE_ACCESSOR, useExisting: CompanyGroupPickerComponent, multi: true }],
})
export class CompanyGroupPickerComponent extends ValueAccessorBase<string | string[]> implements OnInit {
	@Output() public groupsChange = new EventEmitter<string | string[]>();

	@Input() multiple = true;
	@Input() clearable = true;
	public companyGroupsPromise;

	public loading = true;
	public useTypeAhead = false;

	public selectOptions: CompanyGroup[] = [];
	public selectOptionsTypeahead = new Subject<string>();
	public selectedOptions: CompanyGroup[] = [];
	private companyId: string;

	constructor(
		@Optional() @Host() protected parent: FormElementComponent,
		@Optional() @Host() protected controlContainer: ControlContainer,
		private companyGroupService: CompanyGroupService,
		public translationReplaceService: TranslationReplaceService,
	) {
		super(parent, controlContainer);
	}

	ngOnInit(): void {
		super.ngOnInit();
		this.companyId = this.companyGroupService.getCompanyOfLoggedUser()?.id;

		const filters = new CompanyGroupListAPIRequest();
		filters.company = this.companyId;

		this.selectOptionsTypeahead
			.pipe(
				debounceTime(100),
				switchMap((term) => {
					this.loading = true;
					return new Promise((resolve, reject) => {
						if (term === '' || term === null) {
							resolve([]);
							return;
						}

						filters.search = term;
						filters.max = 100;
						filters.start = 0;

						this.companyGroupService
							.getCompanyGroups(filters)
							.then((companyGroupData) => {
								resolve(companyGroupData.company_groups);
							})
							.catch((e) => {
								reject(e);
							});
					});
				}),
			)
			.subscribe(
				(items) => {
					// @ts-ignore
					this.selectOptions = items;
					this.loadCurrentValue();
				},
				(err) => {
					this.selectOptions = [];
					this.loadCurrentValue();
				},
			);

		this.loadItems(filters);
	}

	loadItems(filters: CompanyGroupListAPIRequest) {
		this.selectOptions = [];
		this.getItemsPage(filters, 100);
	}

	getItemsPage(filters: CompanyGroupListAPIRequest, per_page: number = 100, page: number = 1) {
		filters.max = per_page;
		filters.start = (page - 1) * per_page;
		this.companyGroupsPromise = this.companyGroupService
			.getCompanyGroups(filters)
			.then((companyGroupData) => {
				if (companyGroupData && companyGroupData.company_groups) {
					companyGroupData.company_groups.forEach((item) => {
						this.selectOptions.push(item);
					});
				}
				if (companyGroupData && companyGroupData.moreresults) {
					// More than 100 items, change to typeahead.
					this.useTypeAhead = true;
				}
				this.loadCurrentValue();
			})
			.catch((e) => {
				this.loading = false;
			});
	}

	loadCurrentValue() {
		const currentValueCheck = (currentValue) => {
			return new Promise<void>((resolve, reject) => {
				// Check whether already in selectedOptions.
				if (this.selectedOptions && this.selectedOptions.filter((o) => o.id === currentValue).length === 0) {
					// Check if value is in options.
					const currentValueInOptions = this.selectOptions.filter((o) => o.getID() === currentValue);
					if (currentValueInOptions.length === 0) {
						// Current value is not in available list, load it.
						this.companyGroupService
							.getCompanyGroup(this.companyId, currentValue)
							.then((group) => {
								this.selectedOptions.push(group);
								resolve();
							})
							.catch((e) => {
								reject(e);
							});
					} else {
						this.selectedOptions.push(currentValueInOptions[0]);
						resolve();
					}
				} else {
					resolve();
				}
			});
		};

		if (this.innerValue && typeof this.innerValue === 'string' && this.innerValue !== '') {
			currentValueCheck(this.innerValue)
				.then((res) => {
					this.finalizeLoading();
				})
				.catch((e) => {
					this.finalizeLoading();
				});
		} else if (this.innerValue && typeof this.innerValue === 'object' && this.innerValue.length > 0) {
			const currentValuePromises = [];
			this.innerValue.forEach((val) => {
				currentValuePromises.push(currentValueCheck(val));
			});

			Promise.all(currentValuePromises)
				.then((res) => {
					this.finalizeLoading();
				})
				.catch((e) => {
					this.finalizeLoading();
				});
		} else {
			this.selectedOptions = [];
			this.finalizeLoading();
		}
	}

	finalizeLoading() {
		// Add selected options to the array.
		if (this.selectedOptions && this.selectedOptions.length > 0) {
			this.selectOptions.push(...this.selectedOptions);
		}

		// Remove duplicate values.
		this.selectOptions = uniqWith(this.selectOptions, (a, b) => {
			if (a.id === b.id) {
				return true;
			}
			return false;
		});

		// Sort by title.
		this.selectOptions = sortBy(this.selectOptions, ['Title']);
		this.loading = false;
	}

	trackSelectOption(item: any): any {
		return item.id;
	}

	@Input()
	get groups() {
		return this.innerValue;
	}

	set groups(val) {
		this.setInnerValueAndNotify(val);
		this.groupsChange.emit(this.innerValue);
	}
}
