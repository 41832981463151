import { Directive, HostListener, ElementRef, OnInit } from '@angular/core';

const timeout = (timeout: number) => new Promise((res) => setTimeout(res, timeout));

const openOrClose = async (el: HTMLElement, time: number, open: boolean) => {
	if (!el || !el.style) {
		return;
	}

	const transitionParts = el.style.transition
		.split(',')
		.map((item) => item.trim())
		.filter((item) => item && item.indexOf('max-height') === -1 && item.indexOf('opacity') === -1);

	const maxHeight = `${el.querySelectorAll('.nav-item').length * 45}px`;

	if (!el.style.maxHeight) el.style.maxHeight = open ? '0px' : maxHeight;
	if (!el.style.opacity) el.style.opacity = open ? '0' : '1';

	el.style.transition = [...transitionParts, `max-height ${time}ms`, `opacity ${time}ms`].join(', ');

	await timeout(time);

	el.style.maxHeight = open ? maxHeight : '0px';
	el.style.opacity = open ? '1' : '0';

	await timeout(time);
};
const close = (el: HTMLElement, time: number) => openOrClose(el, time, false);
const open = (el: HTMLElement, time: number) => openOrClose(el, time, true);

//sidebar toggler
@Directive({
	selector: '[sidebarToggler]',
})
export class sidebarToggler {
	constructor() {}

	@HostListener('click', ['$event'])
	toggleOpen($event: any) {
		$event.preventDefault();
		document.querySelector('.app').classList.toggle('is-collapsed');
	}
}

//sidebar dropdown
@Directive({
	selector: '[sideBar]',
})
export class sidebarDropdown implements OnInit {
	constructor(private el: ElementRef) {}

	selectedDropdown: HTMLElement;

	ngOnInit(): any {
		const aTags = Array.from(document.querySelectorAll('.side-nav .side-nav-menu li a')) as HTMLElement[];
		aTags.map((aTag) => {
			aTag.addEventListener('click', () => {
				const dropdown = aTag.parentElement;
				const otherDropdowns = Array.from(dropdown.parentElement.children).filter(
					(el) => el.classList.contains('nav-item') && el.classList.contains('dropdown') && el !== dropdown
				);

				if (this.selectedDropdown === dropdown) {
					// close the dropdown because it's already open
					close(dropdown.querySelector('.dropdown-menu'), 200).then(() => dropdown.classList.remove('open'));
					this.selectedDropdown = undefined;
				} else {
					// Close all other dropdowns if any are open
					const promises = otherDropdowns.map((el) => close(el.querySelector('.dropdown-menu') as HTMLElement, 200));
					Promise.all(promises).then(() => otherDropdowns.map((el) => el.classList.remove('open')));

					// Open the selected newly selected dropdown
					dropdown.classList.add('open');
					open(dropdown.querySelector('.dropdown-menu'), 150);
					this.selectedDropdown = dropdown;
				}
			});
		});
	}
}

export const Sidebar_Directives = [sidebarDropdown, sidebarToggler];
