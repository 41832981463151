import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
	selector: 'app-virus-scan-result',
	templateUrl: './virus-scan-result.component.html',
	styleUrls: ['./virus-scan-result.component.scss'],
})
export class VirusScanResultComponent implements OnInit {
	@Input() filename: string;
	@Input() variant: 'virusDetected' | 'couldNotBeScanned' | 'hasNotBeenScanned' = 'virusDetected';

	@Output() downloadClick = new EventEmitter<MouseEvent>();
	@Output() showClick = new EventEmitter<MouseEvent>();

	buttonVariant: 'redFilled' | 'orangeFilled';

	ngOnInit() {
		switch (this.variant) {
			case 'hasNotBeenScanned':
			case 'couldNotBeScanned':
				this.buttonVariant = 'orangeFilled';
				break;
			case 'virusDetected':
			default:
				this.buttonVariant = 'redFilled';
		}
	}

	download(event: MouseEvent) {
		this.downloadClick.emit(event);
	}

	show(event: MouseEvent) {
		this.showClick.emit(event);
	}
}
