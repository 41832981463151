import { Injectable } from '@angular/core';
import { APIService } from '~/app/api/services/api.service';
import { possibleFlags } from '~/app/shared/ui/forms/composed/pickers/user-feature-flag-picker/possible-feature-flags';
import { UserService } from '~/app/modules/user/user.service';

@Injectable({
	providedIn: 'root',
})
export class UserFeatureFlagsService {
	constructor(private userService: UserService, private apiService: APIService) {}

	getActiveFeatureFlags(userId: string): Promise<typeof possibleFlags> {
		return this.apiService.getFromApi(`admin/users/${userId}/featureflags`).then((res) => {
			return res.data;
		});
	}

	saveActiveFeatureFlags(userId: string, flags: typeof possibleFlags): Promise<typeof possibleFlags> {
		return this.apiService
			.patchToApi(`admin/users/${userId}/featureflags`, {
				flags: flags,
			})
			.then((res) => res.data);
	}
}
