import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { CompanyComponent } from '../../../modules/company/components/company.component';
import { CompanyDimensionLimitationListAPIRequest, DimensionLimitation } from '#/models/company/company.model';
import { sortBy, uniqWith } from 'lodash';
import { User } from '#/models/user/user.model';
import { debounceTime, Subject, switchMap } from 'rxjs';
import { isValueSet, stringIsSetAndFilled, useIfArrayIsSetWithOneItem } from '#/util/values';
import { arrayIsSetAndFilled } from '#/util/arrays';
import { CompanyCostCenterListAPIRequest, CostCenter } from '#/models/company/dimension/cost-center.model';
import { CompanyCostCenterService } from '#/services/company/dimension/company-cost-center.service';
import { DimensionLimitationsService } from '#/services/company/dimension/dimension-limitations-service';
import { CompanyDimensionsService } from '#/services/company/dimension/company-dimensions.service';

@Component({
	selector: 'app-company-cost-center-picker',
	templateUrl: './company-cost-center-picker.component.html',
})
export class CompanyCostCenterPickerComponent extends CompanyComponent implements OnInit, OnChanges {
	private static dimensionLimitationCache = new Map<string, DimensionLimitation[]>();
	public value: string | string[];
	@Output()
	public costCenterChange = new EventEmitter<string | string[]>();

	@Input() filterOnCompanyGroups = false;
	@Input() filterOnActive = false;
	@Input() filterOnMoreThanZeroGroups = false;
	@Input() public disabled = false;
	@Input() public receiptUser: User;
	@Input() public multiple = false;
	@Input() public costunit;
	@Input() public project;
	@Input() public administration;
	@Input() public filterOnCostCenterIds: string[];

	public loading = true;
	public useTypeAhead = false;

	public options: CostCenter[] = [];
	public originalOptions: CostCenter[] = [];
	public selectOptionsTypeahead = new Subject<string>();
	public selectedOptions: CostCenter[] = [];

	public bindLabel;

	private dimensionLimitations: DimensionLimitation[] = [];

	constructor(
		private companyCostCenter: CompanyCostCenterService,
		private dimensionLimitationsService: DimensionLimitationsService,
		private companyDimensionsService: CompanyDimensionsService,
	) {
		super();
	}

	ngOnInit(): void {
		super.ngOnInit();
		this.loadItemsForTypeAheadAndPicker();
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (
			(changes.disabled && !changes.disabled.firstChange) ||
			(changes.costunit && !changes.costunit.firstChange) ||
			(changes.project && !changes.project.firstChange)
		) {
			this.options = this.originalOptions;
			this.filterWithDimensionLimitations().then(
				() => {
					this.loadCurrentValue();
				},
				() => {
					this.loadCurrentValue();
				},
			);
		}
		if (
			(changes.administration && !changes.administration.firstChange) ||
			changes.filterOnCompanyGroups ||
			changes.filterOnActive ||
			changes.filterOnMoreThanZeroGroups ||
			changes.receiptUser
		) {
			this.loadItemsForTypeAheadAndPicker();
		}
	}

	private loadItemsForTypeAheadAndPicker() {
		const filters = new CompanyCostCenterListAPIRequest();
		filters.company = this.company?.id;
		if (stringIsSetAndFilled(this.administration)) {
			filters.administrations = [this.administration];
		}
		this.bindLabel = this.company?.getCompanyUnitBindLabel();

		if (this.filterOnCompanyGroups) {
			let userGroups = [];
			this.receiptUser ? (userGroups = this.receiptUser.getCompanyGroups()) : (userGroups = this.user.getCompanyGroups());
			filters.groups = userGroups;
		}

		if (this.filterOnActive) {
			filters.active = true;
		}

		this.selectOptionsTypeahead
			.pipe(
				debounceTime(100),
				switchMap((term) => {
					this.loading = true;
					return new Promise((resolve, reject) => {
						if (term === '' || term === null) {
							resolve([]);
							return;
						}

						filters.search = term;
						filters.max = 100;
						filters.start = 0;

						this.companyCostCenter
							.getCompanyCostCenterFilteredOnPresetsAndAdministration(filters, this.filterOnCostCenterIds, this.administration)
							.then((companyCostCenterData) => {
								if (this.filterOnMoreThanZeroGroups) {
									companyCostCenterData.company_costcenters = companyCostCenterData.company_costcenters.filter(
										(costCenter) => costCenter.groups.length > 0,
									);
								}
								resolve(companyCostCenterData.company_costcenters);
							})
							.catch((e) => {
								reject(e);
							});
					});
				}),
			)
			.subscribe(
				(items) => {
					// @ts-ignore
					this.options = items;
					// @ts-ignore
					this.originalOptions = items;
					this.filterWithDimensionLimitations().then(
						() => {
							this.loadCurrentValue();
						},
						() => {
							this.loadCurrentValue();
						},
					);
				},
				(err) => {
					this.options = [];
					this.loadCurrentValue();
				},
			);

		this.loadItems(filters);
	}

	loadDimensionLimitations(): Promise<any> {
		const filters = new CompanyDimensionLimitationListAPIRequest();
		filters.company = this.company?.id;
		filters.targetType = 'CostCenter';
		filters.active = true;
		const url = filters.getURL();
		return new Promise<void>((resolve, reject) => {
			if (CompanyCostCenterPickerComponent.dimensionLimitationCache.has(url)) {
				this.dimensionLimitations = CompanyCostCenterPickerComponent.dimensionLimitationCache.get(url);
				resolve();
			} else {
				this.loadDimensionLimitationsPage(1).then(() => {
					CompanyCostCenterPickerComponent.dimensionLimitationCache.set(url, this.dimensionLimitations);
					resolve();
				}, reject);
			}
		});
	}

	loadDimensionLimitationsPage(page: number): Promise<any> {
		const per_page = 100;
		const filters = new CompanyDimensionLimitationListAPIRequest();
		filters.company = this.company?.id;
		filters.targetType = 'CostCenter';
		filters.active = true;
		filters.max = per_page;
		filters.start = (page - 1) * per_page;
		return new Promise<void>((resolve, reject) => {
			this.companyDimensionsService
				.getCompanyDimensionLimitations(filters)
				.then((data) => {
					this.dimensionLimitations.push(...data.company_dimension_limitations);
					if (data.moreresults) {
						this.loadDimensionLimitationsPage(page++).then(resolve, reject);
					} else {
						resolve();
					}
				})
				.catch((e) => {
					reject(e);
				});
		});
	}

	loadItems(filters: CompanyCostCenterListAPIRequest) {
		this.options = [];
		this.loadDimensionLimitations().then(
			() => {
				this.getItemsPage(filters, 1000);
			},
			() => {
				this.getItemsPage(filters, 1000);
			},
		);
	}

	getItemsPage(filters: CompanyCostCenterListAPIRequest, per_page: number = 100, page: number = 1) {
		filters.max = per_page;
		filters.start = (page - 1) * per_page;
		this.companyCostCenter
			.getCompanyCostCenterFilteredOnPresetsAndAdministration(filters, this.filterOnCostCenterIds, this.administration)
			.then((companyCostCenterData) => {
				if (companyCostCenterData && companyCostCenterData.company_costcenters) {
					if (this.filterOnMoreThanZeroGroups) {
						companyCostCenterData.company_costcenters = companyCostCenterData.company_costcenters.filter(
							(costCenter) => costCenter.groups.length > 0,
						);
					}
					this.options = companyCostCenterData.company_costcenters;
					this.originalOptions = companyCostCenterData.company_costcenters;
				}
				if (companyCostCenterData && companyCostCenterData.moreresults) {
					// More than 100 items, change to typeahead.
					this.useTypeAhead = true;
				}
				return this.filterWithDimensionLimitations().then(
					() => {
						this.loadCurrentValue();
					},
					() => {
						this.loadCurrentValue();
					},
				);
			})
			.catch((e) => {
				this.loading = false;
			});
	}

	loadCurrentValue() {
		const currentValueCheck = (currentValue) => {
			return new Promise<void>((resolve, reject) => {
				// Check whether already in selectedOptions.
				if (this.selectedOptions && this.selectedOptions.filter((o) => o.id === currentValue).length === 0) {
					// Check if value is in options.
					const currentValueInOptions = this.originalOptions.filter((o) => o.getID() === currentValue);
					if (currentValueInOptions.length === 0) {
						// Current value is not in available list, load it.
						this.companyCostCenter
							.getCompanyCostCenter(this.company?.id, currentValue)
							.then((costCenter) => {
								this.selectedOptions.push(costCenter);
								resolve();
							})
							.catch((e) => {
								reject(e);
							});
					} else {
						this.selectedOptions.push(currentValueInOptions[0]);
						resolve();
					}
				} else {
					resolve();
				}
			});
		};

		if (this.value && typeof this.value === 'string' && this.value !== '') {
			currentValueCheck(this.value)
				.then((res) => {
					this.finalizeLoading();
				})
				.catch((e) => {
					this.finalizeLoading();
				});
		} else if (this.value && typeof this.value === 'object' && this.value.length > 0) {
			const currentValuePromises = [];
			this.value.forEach((val) => {
				currentValuePromises.push(currentValueCheck(val));
			});

			Promise.all(currentValuePromises)
				.then((res) => {
					this.finalizeLoading();
				})
				.catch((e) => {
					this.finalizeLoading();
				});
		} else {
			this.selectedOptions = [];
			this.finalizeLoading();
		}
	}

	finalizeLoading() {
		// Add selected options to the array.
		if (this.selectedOptions && this.selectedOptions.length > 0) {
			this.options.push(...this.selectedOptions);
		}

		// Remove duplicate values.
		this.options = uniqWith(this.options, (a, b) => {
			return a.id === b.id;
		});

		if (this.administration) {
			this.options = this.options.filter((c) => c.administration === this.administration);
			this.selectedOptions = this.selectedOptions.filter((c) => c.administration === this.administration);
			if (this.selectedOptions.length === 0) {
				this.value = Array.isArray(this.value) ? [] : undefined;
			}
		}

		this.setOptionsBasedOnIdFilter();

		// Sort by title.
		this.options = sortBy(this.options, ['Title']);
		this.loading = false;
	}

	filterWithDimensionLimitations(): Promise<any> {
		// Do not run when disabled.
		// Do not run when we don't have select options.
		// Do not run when we don't have limitations.
		// Do not run when we don't have another field to check the limitations.
		if (
			this.disabled ||
			!this.options ||
			this.options.length === 0 ||
			!this.dimensionLimitations ||
			this.dimensionLimitations.length === 0 ||
			(!this.costunit && !this.project)
		) {
			return Promise.resolve();
		}
		return this.dimensionLimitationsService
			.filterWithDimensionLimitations(this.company?.id, null, this.costunit, this.project, this.options, this.dimensionLimitations)
			.then((matchedItems) => {
				this.options = matchedItems;
			});
	}

	trackSelectOption(item: any): any {
		return item.id;
	}

	setOptionsBasedOnIdFilter() {
		// set the value if there is only one option and none is selected and filter on country ids is used
		if (isValueSet(this.filterOnCostCenterIds) && !arrayIsSetAndFilled(this.selectedOptions)) {
			this.costCenter = useIfArrayIsSetWithOneItem(this.options)?.id;
		}
	}

	@Input()
	get costCenter() {
		return this.value;
	}

	set costCenter(val) {
		this.value = val;
		this.costCenterChange.emit(this.value);
	}
}
