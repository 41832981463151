import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { BaseComponent } from '../../../../base/base.component';
import { DeclarationStatusBadge, getExpenseLabel, Receipt } from '#/models/transaction/receipt';
import { PossibleCompanyFeatureFlags } from '#/models/company/possible-feature-flags';
import { CompanyFeatureFlagsService } from '#/services/company/company-feature-flags.service';
import { isValueSet } from '#/util/values';

type Placement =
	| 'auto'
	| 'top'
	| 'bottom'
	| 'left'
	| 'right'
	| 'top-left'
	| 'top-right'
	| 'bottom-left'
	| 'bottom-right'
	| 'left-top'
	| 'left-bottom'
	| 'right-top'
	| 'right-bottom';
type PlacementArray = Placement | Array<Placement> | string;

@Component({
	selector: 'app-receipt-expense-status',
	templateUrl: './receipt-expense-status.component.html',
	styleUrls: ['./receipt-expense-status.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReceiptExpenseStatusComponent extends BaseComponent implements OnChanges {
	@Input() receipt: Receipt;
	@Input() status: DeclarationStatusBadge;
	@Input() tooltipPlacement: PlacementArray = 'top';

	expenseClasses: string[] = [];
	authorizationFlowApprovedCount = 0;
	authorizationFlowApproverCount = 0;
	statusValue: DeclarationStatusBadge | null = null;
	inAuthorizationFlow = false;
	private companyHasTxi: boolean = false;

	constructor(private companyFeatureFlagsService: CompanyFeatureFlagsService) {
		super();
		this.companyHasTransactionInterfaces().then((r) => (this.companyHasTxi = r));
	}

	private companyHasTransactionInterfaces(): Promise<boolean> {
		return this.companyFeatureFlagsService.companyHasSpecificFeatureFlagEnabled(PossibleCompanyFeatureFlags.TRANSACTION_INTERFACES);
	}

	public getExpenseLabel(): string {
		const status = this.statusValue;
		if (!isValueSet(status)) {
			return null;
		}
		// Authorization flow is in a "fake" approved state.
		const expenseLabel = getExpenseLabel(this.inAuthorizationFlow ? DeclarationStatusBadge.Approved : status, this.companyHasTxi);
		return isValueSet(expenseLabel) ? this.translate.instant(expenseLabel) : '';
	}

	getExpenseClasses(): string[] {
		const classes = ['badge'];
		const status = this.statusValue;
		if (status) {
			if (status === DeclarationStatusBadge.NeedsInformation) {
				classes.push('badge-warning');
			} else if (this.inAuthorizationFlow) {
				// Authorization flow is in a "fake" approved state.
				classes.push('badge-quick-status');
				classes.push('mr-0');
				classes.push('badge-primary');
				classes.push('invert');
			} else if (status === DeclarationStatusBadge.Accepted) {
				classes.push('badge-info');
			} else if (status === DeclarationStatusBadge.Approved) {
				classes.push('badge-primary');
			} else if (status === DeclarationStatusBadge.Denied) {
				classes.push('badge-danger');
			} else if (status === DeclarationStatusBadge.Claimed) {
				classes.push('badge-primary');
			} else if (status === DeclarationStatusBadge.NeedsReview) {
				classes.push('badge-outline-secondary badge-quick-status');
			} else if (status === DeclarationStatusBadge.OcrInProgress) {
				classes.push('badge-outline-secondary badge-quick-status');
			}
		}

		return classes;
	}

	private setStatuses(): void {
		this.status = this.receipt.isProcessing()
			? DeclarationStatusBadge.OcrInProgress
			: this.receipt.needs_ocr_review
			? DeclarationStatusBadge.NeedsReview
			: this.status;

		this.statusValue = this.status || this.receipt?.getExpenseStatus() || null;
		this.inAuthorizationFlow = this.statusValue === DeclarationStatusBadge.Accepted && this.receipt?.inAuthorizationFlow();
		this.expenseClasses = this.getExpenseClasses();
		this.authorizationFlowApprovedCount = this.receipt?.authorizationFlowApprovedCount() || 0;
		this.authorizationFlowApproverCount = this.receipt?.authorizationFlowApproverCount() || 0;
	}

	ngOnChanges(changes: SimpleChanges): void {
		this.setStatuses();
	}
}
