import { Inject, Injectable } from '@angular/core';
import { Company } from '#/models/company/company.model';
import { User } from '#/models/user/user.model';
import { APIService } from '~/app/api/services/api.service';
import { DOCUMENT } from '@angular/common';
import { APINotificationsService } from '~/app/api/services/apinotifications.service';
import { NotificationService } from '~/app/services/notification.service';
import { isValueSet, stringIsSetAndFilled } from '#/util/values';
import { CompanyApiService } from '#/services/company/company-api.service';
import { BehaviorSubject, take } from 'rxjs';
import { SetCompany, SetCompanyUsers } from '#/models/company/company.actions';
import { CompanyState } from '#/models/company/company.reducer';
import { StoreService } from '~/app/services/store.service';
import { UserService } from '~/app/modules/user/user.service';
import { CompanyUsersFilterAPIRequest } from '#/models/company/companyUsersApiRequestFilter';
import { UserType } from '#/services/user/partial-users.service';

@Injectable({
	providedIn: 'root',
})
export class CompanyService {
	public companyObservable: BehaviorSubject<Company>;
	private parentCompany: Company;
	private user: User;
	private company: Company;
	private companyUsers: User[];

	constructor(
		@Inject(DOCUMENT) private document: any,
		private apiService: APIService,
		private notifications: APINotificationsService,
		private notificationService: NotificationService,
		private store: StoreService,
		private userService: UserService,
		private companyApiService: CompanyApiService,
	) {
		this.companyObservable = new BehaviorSubject<Company>(new Company());

		this.userService.userObservable.subscribe((user: User) => {
			if (stringIsSetAndFilled(user?.company)) {
				this.companyApiService.getCompany(user.company).then((company: Company) => {
					this.saveCompany(company);
				});
			}
		});

		this.store.select('company')?.subscribe((value: any) => {
			this.parentCompany = value?.parentCompany;
			this.companyUsers = value?.users;
			this.companyObservable.next(value?.company);
			this.company = value?.company;
		});
	}

	public clearCompany(): void {
		const emptyCompany = new Company();
		this.companyObservable?.next(emptyCompany);
		this.company = emptyCompany;
		this.parentCompany = emptyCompany;
	}

	public saveCompany(company: Company): void {
		this.company = company;
		this.companyObservable.next(company);
	}

	public getCompanyId(): string {
		return this.getCompanyOfLoggedUser()?.id;
	}

	public getCompanyOfLoggedUser(): Company {
		return this.company;
	}

	public getCompanyUsersOfLoggedUser(): User[] {
		return this.companyUsers;
	}

	public getCompanyUserById(userID: string): Promise<User> {
		return this.companyApiService.getCompanyUsersByIds(this.getCompanyId(), [userID])
			.then(res => res.users[0]);
	}

	public getParentCompanyOfLoggedUser(): Company {
		return this.parentCompany;
	}

	public patchCompany(company: Company): Promise<Company> {
		return this.companyApiService.patchCompany(company);
	}

	public getAllCompanyUsersOfLoggedUser(): Promise<Array<User>> {
		const filters = new CompanyUsersFilterAPIRequest();

		return this.companyApiService.getCompanyUsers(this.company.id, filters).then((users) => users.users);
	}

	public getPartialCompanyUsersOfLoggedUser(): Promise<Array<User>> {
		const filters = new CompanyUsersFilterAPIRequest();
		filters.userType = UserType.Partial;
		return this.companyApiService.getCompanyUsers(this.company.id, filters).then((users) => users.users);
	}

	public initializeCompany(): Promise<Company> {
		/* We need the current user before we can load all related company details. */
		this.store.select('user')?.subscribe((user: any) => (this.user = user.currentUser));

		return new Promise<Company>((res, rej) => {
			/* We first check the store. Is there already a company set? Then resolve right away. */
			return this.store
				.select('company')
				.pipe(take(1))
				.subscribe((companyStore: CompanyState) => {
					/* Resolve with the value of the current company. */
					if (companyStore.company != null) {
						return res(companyStore.company);
					} else {
						/* No company found in store, so check if we have load it from the API. */
						if (isValueSet(this.user) && stringIsSetAndFilled(this.user.getCompany())) {
							/* Load the user's company from the API, set it in the store, resolve the route. */
							return this.companyApiService
								.getCompany(this.user.getCompany())
								.then((company) => {
									this.company = company;
									this.store.dispatch(new SetCompany({ company: company }));
									this.companyObservable.next(company);
									res(company);
								})
								.catch((e) => {
									rej(e);
								});
						} else {
							/* No company to load, so resolve with null. */
							res(null);
						}
					}
				});
		});
	}

	public initializeCompanyUsers(): Promise<any> {
		/* We need the current company before we load the users. */
		let previousCompanyState: CompanyState = null;

		return new Promise<any>((res, rej) => {
			return this.store.select('company')?.subscribe((companyState: CompanyState) => {
				const prevCompanyState = previousCompanyState;
				previousCompanyState = companyState;
				const filters = new CompanyUsersFilterAPIRequest();
				// getting only the real users as the pickers should not be populated with Partial users
				filters.userType = UserType.Real;

				if (prevCompanyState?.company?.id === companyState?.company?.id && isValueSet(companyState?.users)) {
					return res(companyState.users);
				}
				if (
					stringIsSetAndFilled(companyState?.company?.id) &&
					(this.user.canSeeCompanyReceipts() || this.user.canApproveCompanyInvoices())
				) {
					return this.companyApiService
						.getCompanyUsers(companyState.company.id, filters)
						.then((users) => {
							this.store.dispatch(new SetCompanyUsers({ users: users.users ?? [] }));
							res(users.users ?? []);
						})
						.catch((e) => {
							rej(e);
						});
				} else {
					res(null);
				}
			});
		});
	}
}
