import { Injectable } from '@angular/core';
import { Subscriber } from 'rxjs';
import { ModalOptions } from '~/app/services/modal.service';
import { Router } from '@angular/router';

@Injectable({
	providedIn: 'root',
})
export class MfaConfigModalService {
	private ShowMfaModalSubscriber: Subscriber<ModalOptions>;

	private successfulMFAConfigurationCallbacks: Array<() => void> = [];
	private onRedirectToDashBoardOnDismissAndNoConfigCallbacks: Array<() => void> = [];

	constructor(private router: Router) {}

	registerShowModal(mfaModalSubscriber: Subscriber<ModalOptions>) {
		this.ShowMfaModalSubscriber = mfaModalSubscriber;
	}

	showMfaModal(options?: ModalOptions) {
		options = options || {};
		this.ShowMfaModalSubscriber.next(options);
	}

	addSuccessfulMFAConfigurationListener(cb: () => void) {
		this.successfulMFAConfigurationCallbacks.push(cb);
	}

	addRedirectToDashBoardOnDismissAndNoConfigListener(cb: () => void) {
		this.onRedirectToDashBoardOnDismissAndNoConfigCallbacks.push(cb);
	}

	resolve() {
		this.successfulMFAConfigurationCallbacks.forEach((cb) => cb());
		this.successfulMFAConfigurationCallbacks = [];
	}

	// This function can be put in the modal options as the callback for the "beforeDismiss" key
	// Because it is used for that key it needs to return a boolean. If the function returns false, the modal won't close.
	// We DO want the modal to close, so we return TRUE
	redirectToDashBoardOnDismissAndNoConfig(): boolean {
		this.router.navigate(['/dashboard']);
		this.onRedirectToDashBoardOnDismissAndNoConfigCallbacks.forEach((cb) => cb());
		this.onRedirectToDashBoardOnDismissAndNoConfigCallbacks = [];
		return true;
	}
}
