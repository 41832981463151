import { Injectable } from '@angular/core';
import { APIService } from '~/app/api/services/api.service';
import { APINotificationsService } from '~/app/api/services/apinotifications.service';
import {
	CompanyCustomIntegrationAPIRequestFilter,
	CompanyCustomIntegrationBooking,
	CompanyCustomIntegrationBookingsAPIRequestFilter,
	CompanyCustomIntegrationData,
	CompanyCustomIntegrationDimension,
	CompanyCustomIntegrationDimensionData,
	IntegrationBookingsExportRequest,
} from '#/models/company/company.model';
import { ImportResultCount } from '#/models/import-result.model';

@Injectable({
	providedIn: 'root',
})
export class CompanyCustomIntegrationService {
	constructor(private apiService: APIService, private notifications: APINotificationsService) {}

	async customIntegrationGetBookings(
		filters?: CompanyCustomIntegrationBookingsAPIRequestFilter,
		companyId?: string,
	): Promise<{ total: number; results: CompanyCustomIntegrationBooking[] }> {
		try {
			const url = companyId ? `/api/v1/company/${companyId}/customIntegration/booking` : `/api/v1/customIntegration/booking`;

			const r = filters ? await this.apiService.post(url + '/list', filters.toAPIBody()) : await this.apiService.get(url);

			return {
				results: r?.data?.results?.map((item) => new CompanyCustomIntegrationBooking(item)) || [],
				total: r?.data?.total || 0,
			};
		} catch (e) {
			this.notifications.handleAPIError(e);
			throw e;
		}
	}

	public getCustomIntegrationPaymentMethods(companyID: string): Promise<Array<CompanyCustomIntegrationData>> {
		return this.apiService.getFromApi(`company/${companyID}/customIntegration/PaymentMethod`).then((r) => {
			return r.data.results.map((e) => new CompanyCustomIntegrationData(e));
		});
	}

	async customIntegrationGetItems(
		type: 'Division' | 'Journal' | 'GeneralLedger' | 'VATCode' | 'PaymentMethod' | 'Supplier',
		filters?: CompanyCustomIntegrationAPIRequestFilter,
		companyId?: string,
	): Promise<{ total: number; results: CompanyCustomIntegrationData[] }> {
		try {
			const url = companyId ? `/api/v1/company/${companyId}/customIntegration/${type}` : `/api/v1/customIntegration/${type}`;
			const r = filters ? await this.apiService.post(url + '/list', filters.toAPIBody()) : await this.apiService.get(url);

			return {
				results: r?.data?.results?.map((item) => new CompanyCustomIntegrationData(item)) || [],
				total: r?.data?.total || 0,
			};
		} catch (e) {
			this.notifications.handleAPIError(e);
			throw e;
		}
	}

	async customIntegrationGetDimensions(
		filters?: CompanyCustomIntegrationAPIRequestFilter,
		companyId?: string,
	): Promise<{ total: number; results: CompanyCustomIntegrationDimension[] }> {
		try {
			const url = companyId ? `/api/v1/company/${companyId}/customIntegration/dimensions` : `/api/v1/customIntegration/dimensions`;
			const r = filters ? await this.apiService.post(url + '/list', filters.toAPIBody()) : await this.apiService.get(url);

			return {
				results: r?.data?.results?.map((item) => new CompanyCustomIntegrationDimension(item)) || [],
				total: r?.data?.total || 0,
			};
		} catch (e) {
			this.notifications.handleAPIError(e);
			throw e;
		}
	}

	async customIntegrationGetDimensionData(
		dimensionID: string,
		filters?: CompanyCustomIntegrationAPIRequestFilter,
		companyId?: string,
	): Promise<{ total: number; results: CompanyCustomIntegrationDimensionData[] }> {
		try {
			const url = companyId
				? `/api/v1/company/${companyId}/customIntegration/dimensions/${dimensionID}`
				: `/api/v1/customIntegration/dimensions/${dimensionID}`;
			const r = filters ? await this.apiService.post(url + '/list', filters.toAPIBody()) : await this.apiService.get(url + '/data');

			return {
				results: r?.data?.results?.map((item) => new CompanyCustomIntegrationDimensionData(item)) || [],
				total: r?.data?.total || 0,
			};
		} catch (e) {
			this.notifications.handleAPIError(e);
			throw e;
		}
	}

	async customIntegrationGetDimension(dimensionID: string, companyID?: string): Promise<CompanyCustomIntegrationDimension> {
		try {
			const url = companyID
				? `/api/v1/company/${companyID}/customIntegration/dimensions/${dimensionID}`
				: `/api/v1/customIntegration/dimensions/${dimensionID}`;
			const r = await this.apiService.get(url);
			return new CompanyCustomIntegrationDimension(r.data);
		} catch (e) {
			this.notifications.handleAPIError(e);
			throw e;
		}
	}

	async customIntegrationCreateDimension(
		nameSingle: string,
		nameMultiple: string,
		key: string,
		companyID?: string,
	): Promise<CompanyCustomIntegrationDimension> {
		try {
			const url = companyID ? `/api/v1/company/${companyID}/customIntegration/dimensions` : `/api/v1/customIntegration/dimensions`;
			const r = await this.apiService.post(url, {
				nameSingle,
				nameMultiple,
				key,
			});
			return new CompanyCustomIntegrationDimension(r.data);
		} catch (e) {
			this.notifications.handleAPIError(e);
			throw e;
		}
	}

	async customIntegrationImport(
		file: File,
		type: 'Division' | 'Journal' | 'GeneralLedger' | 'VATCode' | 'PaymentMethod' | 'Supplier' | 'Dimension',
		divisionCode: string,
		dimensionID?: string,
		skipFirstRow?: boolean,
		updateExisting?: boolean,
		companyId?: string,
	): Promise<ImportResultCount> {
		const formData = new FormData();
		formData.append('file', file);

		if (skipFirstRow) {
			formData.append('skip_first_row', '1');
		}

		if (updateExisting) {
			formData.append('update_existing', '1');
		}

		if (divisionCode) {
			formData.append('division', divisionCode);
		}

		if (dimensionID) {
			formData.append('dimension_id', dimensionID);
		}

		const url =
			(companyId ? `/api/v1/company/${companyId}/customIntegration/` : `/api/v1/customIntegration/`) +
			(type === 'Dimension' ? `dimensions/import` : `${type}/import`);

		try {
			const r = await this.apiService.post(url, formData);
			return new ImportResultCount(r.data);
		} catch (e) {
			this.notifications.handleAPIError(e);
			throw e;
		}
	}

	async customIntegrationBookingsExport(companyID: string, request: IntegrationBookingsExportRequest): Promise<string> {
		try {
			const preUrl = companyID ? `/api/v1/company/${companyID}/customIntegration/` : `/api/v1/customIntegration/`;
			const r = await this.apiService.post(preUrl + `booking/export`, request);
			return r.data.url;
		} catch (e) {
			this.notifications.handleAPIError(e);
			throw e;
		}
	}

	async customIntegrationBookingsExportForXml(companyID: string, body): Promise<string> {
		try {
			const preUrl = companyID ? `/api/v1/company/${companyID}/customIntegration/` : `/api/v1/customIntegration/`;
			const r = await this.apiService.post(preUrl + `booking/export`, body);
			return r.data.url;
		} catch (e) {
			this.notifications.handleAPIError(e);
			throw e;
		}
	}
}
