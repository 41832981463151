import { Component, OnDestroy, OnInit, TemplateRef } from '@angular/core';
import { User } from '#/models/user/user.model';
import { environment } from '../../environments/environment';
import { AuthenticatedComponent } from '../pages/authenticated/authenticated.component';
import { ToggleSidePanel } from '../modules/side-panel/side-panel.actions';
import { Company } from '#/models/company/company.model';
import { ConfirmModalComponent } from '../shared/ui/confirm-modal/confirm-modal.component';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { ReceiptFiltersService, SEARCH_FILTER_LOCALSTORAGE_KEY } from '~/app/modules/receipt/receipt-filters.service';
import { ActivationStart, ChildrenOutletContexts, IsActiveMatchOptions } from '@angular/router';
import { isNullOrUndefined, isValueSet, stringIsSetAndFilled } from '#/util/values';
import { VersionCheckService } from '~/app/services/version-check.service';
import { CompanyService } from '#/services/company/company.service';
import { CompanyApiService } from '#/services/company/company-api.service';
import { CompanyFeatureFlagsService } from '#/services/company/company-feature-flags.service';
import { ImpersonationService } from '#/services/user/impersonation.service';
import { Theme, Themed } from '#/providers/themed';
import { CommonLayoutService } from '~/app/layouts/common-layout.service';
import { UserAccessService } from '#/services/user/user-access.service';
import { memoize } from 'lodash';
import { PossibleCompanyFeatureFlags } from '#/models/company/possible-feature-flags';
import { AccountingIntegrationV2Service } from '#/services/integration/accounting-integration-v2.service';
import { arrayIsSetAndFilled } from '#/util/arrays';
import { AccountingCapability } from '#/models/accounting-integrations/accounting-integration-v2';
import { memoizeMax1Param } from '#/util/functions';

@Component({
	selector: 'app-dashboard',
	styleUrls: ['./common-layout.component.scss'],
	templateUrl: './common-layout.component.html',
	providers: [Themed],
})
export class CommonLayoutComponent extends AuthenticatedComponent implements OnInit, OnDestroy {
	public app: any;
	public headerThemes: any;
	public changeHeader: any;
	public sidenavThemes: any;
	public changeSidenav: any;
	public headerSelected: any;
	public sidenavSelected: any;
	public user: User;
	public company: Company;
	public manualBookkeepingIntegrationEnabled = false;
	public parentCompany: Company;
	public helpLink: string;
	public smallLogo: SafeUrl;
	public largeLogo: SafeUrl;
	public homepage: string;
	public appVersion: string = '';
	public companyFeatureFlags: Array<PossibleCompanyFeatureFlags>;
	public impersonationListPromise: Promise<Array<{ id: string; caption: string }>>;
	public theme: string = 'klippa';
	public appTheme = Theme;
	public dynamicHeaderContent: TemplateRef<HTMLElement>;
	public showBookingDataTab: boolean;

	constructor(
		private companyService: CompanyService,
		private companyApiService: CompanyApiService,
		private sanitizer: DomSanitizer,
		public receiptFiltersService: ReceiptFiltersService,
		private outlets: ChildrenOutletContexts,
		private versionCheckService: VersionCheckService,
		private companyFeatureFlagsService: CompanyFeatureFlagsService,
		private impersonationService: ImpersonationService,
		public themed: Themed,
		private commonLayoutService: CommonLayoutService,
		private userAccessService: UserAccessService,
		private accountingIntegrationV2Service: AccountingIntegrationV2Service,
	) {
		super();
		this.app = {
			layout: {
				isMenuCollapsed: false,
				forceMenuCollapsed: false,
				themeConfigOpen: false,
				rtlActived: false,
				searchActived: false,
			},
		};

		this.headerThemes = ['header-default', 'header-primary', 'header-info', 'header-success', 'header-danger', 'header-dark'];
		this.changeHeader = changeHeader;

		function changeHeader(headerTheme) {
			this.headerSelected = headerTheme;
		}

		this.sidenavThemes = ['sidenav-default', 'side-nav-dark'];
		this.changeSidenav = changeSidenav;

		function changeSidenav(sidenavTheme) {
			this.sidenavSelected = sidenavTheme;
		}
		this.theme = themed.getTheme();

		this.commonLayoutService.onDynamicHeaderContentChanged().subscribe((e) => {
			this.dynamicHeaderContent = e;
		});
	}

	async ngOnInit() {
		super.ngOnInit();

		this.versionCheckService.getAppVersion().then((res) => {
			this.appVersion = res;
		});
		this.helpLink = this.createHelpLink();

		const companyStoreSubscription = this.store.select('company').subscribe(async (val) => {
			this.company = val.company;
			this.manualBookkeepingIntegrationEnabled =
				this.company?.authorizations?.get('user_api')?.integration_configuration?.manual_bookkeeping_integration === '1';
			this.parentCompany = val.parentCompany;
			this.checkCustomLogoAndIcon();

			if (isValueSet(this.companyFeatureFlags)) {
				this.showBookingDataTab = await this.shouldShowBookingDataTab(this.company.id);
			}
		});

		let childOutlet = this.outlets.getContext('primary');
		while (isValueSet(childOutlet.children.getContext('primary'))) {
			childOutlet = childOutlet.children.getContext('primary');
		}
		if (childOutlet?.route?.snapshot?.data?.menuCollapsed) {
			this.app.layout.forceMenuCollapsed = true;
		}
		const routeSub = this.router.events.subscribe((event) => {
			if (event instanceof ActivationStart) {
				if (event.snapshot.data.menuCollapsed) {
					this.app.layout.forceMenuCollapsed = true;
				} else {
					this.app.layout.forceMenuCollapsed = false;
				}
			}
		});

		this.destroyCallbacks.push(() => {
			companyStoreSubscription.unsubscribe();
			routeSub.unsubscribe();
		});

		this.onboarding();

		this.determineHomePage();
		if (isValueSet(this.company)) {
			this.companyFeatureFlags = await this.companyFeatureFlagsService.getAllFeatureFlags(this.companyService.getCompanyId());
			this.impersonationListPromise = this.impersonationService
				.getListOfPeopleICanImpersonate()
				.then((ids) => this.companyApiService.getCompanyUsersByIds(this.userAPIService.getCurrentLoggedUser().company, ids))
				.then((res) => {
					return res.users.map((e) => ({ id: e.id, caption: e.getName() }));
				});
		} else {
			this.companyFeatureFlags = [];
		}
		this.setSearchFilterBasedOnLocalStorageData();

		this.showBookingDataTab = await this.shouldShowBookingDataTab(this.company.id);
	}

	/* tslint:disable:member-ordering */
	private shouldShowBookingDataTab = memoizeMax1Param(async (companyId: string): Promise<boolean> => {
		return (
			(this.user?.hasCompanyAdminRole() || this.user?.hasCompanyFinanceRole()) &&
			this.companyFeatureFlags?.includes(PossibleCompanyFeatureFlags.AAV2) &&
			arrayIsSetAndFilled(
				await this.accountingIntegrationV2Service.getConnectedIntegrationsWithCapability(AccountingCapability.allowsImportingValues),
			)
		);
	});

	public setSearchFilterBasedOnLocalStorageData(): void {
		if (stringIsSetAndFilled(this.localStorageService.get(SEARCH_FILTER_LOCALSTORAGE_KEY))) {
			this.receiptFiltersService.filters.search = this.localStorageService.get(SEARCH_FILTER_LOCALSTORAGE_KEY);
		}
	}

	public updateSearchParam(searchParam: string): void {
		this.receiptFiltersService.filters.search = searchParam;
		this.localStorageService.set(SEARCH_FILTER_LOCALSTORAGE_KEY, searchParam);
	}

	/* tslint:disable:member-ordering */
	public showCompanySettingsDropdown = memoize(async (companyId: string): Promise<boolean> => {
		return this.userAccessService.canSeeCompanySettings();
	});

	/* tslint:disable:member-ordering */
	public showAdminDropdown = memoize(async (companyId: string): Promise<boolean> => {
		return this.userAccessService.canSeeAdminSettings();
	});

	async determineHomePage() {
		this.homepage = await this.userAPIService.getHomePage();
	}

	async checkCustomLogoAndIcon() {
		if (this.user && this.company && this.company.preferences && this.company.preferences.has_icon) {
			this.companyApiService.getCompanyIcon(this.company).then((blob) => {
				if (!blob) {
					return;
				}
				const blobURL = URL.createObjectURL(blob);
				const img = new Image();
				// @ts-ignore
				img.onload = () => this.user.setAvatarURL(this.sanitizer.bypassSecurityTrustUrl(blobURL));
				img.src = blobURL;
			});
		} else {
			this.user?.setAvatarURL(null);
		}

		if (this.company && this.company.modules && this.company.modules.custom_logo && this.company.modules.custom_logo.enabled) {
			const [largeLogo, smallLogo] = await Promise.all([this.loadLogo('large_logo_light'), this.loadLogo('small_logo_light')]);
			if (largeLogo && smallLogo) {
				this.largeLogo = largeLogo;
				this.smallLogo = smallLogo;
			} else {
				this.largeLogo = undefined;
				this.smallLogo = undefined;
			}
		} else {
			this.largeLogo = undefined;
			this.smallLogo = undefined;
		}
	}

	loadLogo(type: 'small_logo_dark' | 'large_logo_dark' | 'small_logo_light' | 'large_logo_light'): Promise<SafeUrl | undefined> {
		return new Promise((res) => {
			this.companyApiService
				.getCustomCompanyLogo(this.company, type)
				.then((blob) => {
					if (!blob) {
						return res(undefined);
					}
					const blobURL = URL.createObjectURL(blob);
					const img = new Image();
					img.onload = () => res(this.sanitizer.bypassSecurityTrustUrl(blobURL));
					img.src = blobURL;
				})
				.catch((e) => {
					return res(undefined);
				});
		});
	}

	public showSearchBar(): boolean {
		const options: IsActiveMatchOptions = { paths: 'subset', matrixParams: 'ignored', queryParams: 'ignored', fragment: 'ignored' };

		return (
			this.router.isActive('/dashboard', options) ||
			this.router.isActive('/dm/dashboard', options) ||
			this.router.isActive('dm/invoices', options)
		);
	}

	showSubCompanies(): boolean {
		// Only admin and finance user should be allowed to access the sub companies;
		const userIsAdminOrFinance = this.user.adminRole || this.user.financeRole;
		if (!userIsAdminOrFinance) {
			return false;
		}
		if (isNullOrUndefined(this.parentCompany)) {
			return false;
		}
		if (!this.parentCompany.can_have_sub_companies) {
			return false;
		}
		return true;
	}

	toggleSidePanel() {
		this.store.dispatch(new ToggleSidePanel());
	}

	async logoutUser() {
		if (environment.feature_flags.disable_login) {
			await this.authManagerService.logOut();
			window.location.assign(environment.feature_flags.new_logout_redirect);
		} else {
			await this.authManagerService.logOut();
			window.location.href = window.location.origin;
		}
	}

	public getTermsAndConditionsLink(): string {
		return this.complianceUrlsService.getTermsAndConditionsStatement(this.complianceUrlsService.getApplicationName(environment.web_root));
	}

	public getPrivacyStatementLink(): string {
		return this.complianceUrlsService.getPrivacyStatement(this.complianceUrlsService.getApplicationName(environment.web_root));
	}

	get currentYear() {
		return new Date().getFullYear();
	}

	onboarding() {
		/* Onboarding for authenticated users. Only shown once. */
		const localStorage = this.localStorageService;
		const key = '2.0_onboarding_shown';
		const result = localStorage.get(key);
		const now = new Date().valueOf();
		const until = new Date('2019-3-4').valueOf();
		if (!result && now < until) {
			localStorage.set(key, true);
			const confirmModalRef = this.modalService.open(ConfirmModalComponent);
			confirmModalRef.componentInstance.type = 'text';
			confirmModalRef.componentInstance.showCancel = false;
			confirmModalRef.componentInstance.title = this.translate.instant(_('A new version of Klippa: 2.0!'));
			confirmModalRef.componentInstance.message = this.translate.instant(
				_(
					'We are very proud to announce the next step for Klippa with a completely renewed 2.0 version. Over the past months we have been working day and night, but now its time to share it with you! All your information has remained the same, but the appearance has changed. Besides this new 2.0 web application and a new website, we will also be releasing a completely new version of the mobile apps this month!\n\nEnjoy using our new app! Feedback can be shared via the chat in the bottom left corner.\n\nStill want to use the old version of Klippa? Up to the end of march that will be possible via the button in the top right corner.\n\nTeam Klippa',
				),
			);
		}
	}

	showStatisticsLink(): boolean {
		return this.showCompanyStatisticsLink() || this.showPersonalStatisticsLink();
	}

	showStatisticsAsDropdown(): boolean {
		return this.showCompanyStatisticsLink() && this.showPersonalStatisticsLink();
	}

	showCompanyStatisticsLink(): boolean {
		return this.user.canViewCompanyStatistics() && !this.hideCompanyStatistics();
	}

	showPersonalStatisticsLink(): boolean {
		return this.user.canViewPersonalStatistics() && !this.isSubCompanyActive();
	}

	getStatisticsLink(): string {
		if (this.showPersonalStatisticsLink()) {
			return '/statistics';
		}

		return '/dm/statistics/insights';
	}

	hideCompanyStatistics(): boolean {
		return this.company && this.company.modules && this.company.modules.hide_statistics && this.company.modules.hide_statistics.enabled;
	}

	public getAmountOfReportModulesEnabled(): number {
		return this.company?.getAmountOfReportModulesEnabled() ?? 0;
	}

	public canUsePreTransactions(): boolean {
		return this.user.canUseGalleryView(this.companyFeatureFlags);
	}

	public canUseMobilityRegistrations(): boolean {
		if (this.company?.modules?.registrations?.enabled === true && this.user.canSubmitPersonalRegistrations()) {
			return true;
		}
		return false;
	}

	async impersonate(userId: string) {
		await this.impersonationService.impersonate(userId);
		// reload to refresh the page with the impersonated user
		window.location.reload();
	}

	private createHelpLink(): string {
		if (stringIsSetAndFilled(environment.feature_flags.new_helpdesk_url)) {
			return environment.feature_flags.new_helpdesk_url;
		}

		if (this.user.preferences.language === 'nl-nl') {
			return 'https://help.klippa.com/nl/';
		}
		return 'https://help.klippa.com/en/';
	}
}
