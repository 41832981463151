import { TransactionFrontendModel } from '#/models/transaction/transaction/frontendModel';
import { frontendToApi } from './transformer';
import { InvoiceApiModel } from '#/models/transaction/invoice/apiModel';
import { isValueSet } from '#/util/values';

export class InvoiceFrontendModel extends TransactionFrontendModel {
	constructor(data?: any) {
		super(data);
		if (!isValueSet(data)) {
			return;
		}
	}

	transformToApi(): InvoiceApiModel {
		return frontendToApi(this);
	}
}
