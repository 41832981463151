<wutu-data-table
	[fetchItemsFn]="fetchItemsFn"
	(onRowClicked)="_onRowClicked($event)"
	[getActionsForRowFn]="getActionsForRowFn"
	[getActionsForMultipleRowsFn]="getActionsForMultipleRowsFn"
	[emptyTpl]="emptyState"
	[persistColumnsFn]="persistColumnsFn"
	[retrieveColumnsFn]="retrieveColumnsFn"
>
	<ng-template
		columnKey="description"
		[columnCaption]="'Description' | translate"
		sortKey="description"
		let-value="value"
		defaultSort="ASC"
		[fixedWidthInREM]="20"
		[sortKey]="null"
	>
		{{ value }}
	</ng-template>
	<ng-template columnKey="amount" [columnCaption]="'Amount' | translate" sortKey="amount" let-value="value" [fixedWidthOnContents]="false">
		{{ value | amountWithCurrency }}
	</ng-template>
	<ng-template columnKey="dates" [columnCaption]="'Dates' | translate" sortKey="dates" let-value="value">
		<span *ngFor="let date of value; last as isLast" class="dateLabel"> {{ date | dateFormat }}<span *ngIf="!isLast"> - </span></span>
	</ng-template>
	<ng-template columnKey="category" [columnCaption]="'Category' | translate" sortKey="category" let-value="value">
		<app-company-category-label [company]="company" [category]="value"></app-company-category-label>
	</ng-template>
	<ng-template columnKey="administration" [columnCaption]="'Administration' | translate" sortKey="administration" let-value="value">
		<app-company-administration-label [administrationId]="value"></app-company-administration-label>
	</ng-template>
	<ng-template columnKey="underlyingType" [columnCaption]="'Expense type' | translate" sortKey="underlyingType" let-value="value">
		{{ value.toString().toLowerCase() }}
	</ng-template>
	<ng-template columnKey="registrationType" [columnCaption]="'Registration type' | translate" [sortKey]="null" let-value="value">
		<app-registration-type-label [registrationTypeId]="value"></app-registration-type-label>
	</ng-template>
	<ng-template columnKey="user" [columnCaption]="'User' | translate" sortKey="user" let-value="value">
		<ng-container *ngIf="value === 'Company'; else userRegistration">
			<span>{{ 'Company registration' | translate }}</span>
		</ng-container>
		<ng-template #userRegistration>
			<app-company-user-label [userID]="value"></app-company-user-label>
		</ng-template>
	</ng-template>

	<ng-template columnKey="distanceTotalInKM" [columnCaption]="'Km' | translate" let-value="value">
		{{ displayDistanceInCompanyUnit(value) }}
	</ng-template>
	<ng-template
		columnKey="transportationType"
		[columnCaption]="'Transportation type' | translate"
		sortKey="transportationType"
		let-value="value"
	>
		<app-transportation-type-label [transportationTypeId]="value"></app-transportation-type-label>
	</ng-template>
	<ng-template filterForm>
		<klp-form [formGroup]="filtersForm" class="filters">
			<klp-form-element [caption]="'From date' | translate" direction="vertical">
				<klp-form-date-picker [clearable]="true" formControlName="fromDate"></klp-form-date-picker>
			</klp-form-element>
			<klp-form-element [caption]="'To date' | translate" direction="vertical">
				<klp-form-date-picker [clearable]="true" formControlName="toDate"></klp-form-date-picker>
			</klp-form-element>
			<klp-form-element [caption]="'User' | translate" direction="vertical">
				<app-form-user-picker formControlName="user"></app-form-user-picker>
			</klp-form-element>
			<klp-form-element [caption]="'Expense type' | translate" direction="vertical">
				<app-form-expense-registration-type-picker formControlName="type"> </app-form-expense-registration-type-picker>
			</klp-form-element>
			<klp-form-element [caption]="'Transportation type' | translate" direction="vertical">
				<app-form-transportation-type-picker [multiple]="true" formControlName="transportationTypes"></app-form-transportation-type-picker>
			</klp-form-element>
			<klp-form-element [caption]="'Registration type' | translate" direction="vertical">
				<app-form-category-registration-type-picker
					[multiple]="true"
					formControlName="registrationTypes"
				></app-form-category-registration-type-picker>
			</klp-form-element>
			<klp-form-element [caption]="'Categories' | translate" direction="vertical">
				<app-category-picker [multiple]="true" formControlName="categories"></app-category-picker>
			</klp-form-element>
			<klp-form-element [caption]="'Administrations' | translate" direction="vertical">
				<app-administration-picker [multiple]="true" formControlName="administrations"></app-administration-picker>
			</klp-form-element>
			<div class="submitButtonContainer">
				<klp-form-button (click)="resetFilters()">
					<span translate>Reset filters</span>
				</klp-form-button>
				<klp-form-submit-button [submitCallback]="onSubmit">
					<span translate>Save filters</span>
				</klp-form-submit-button>
			</div>
		</klp-form>
	</ng-template>
</wutu-data-table>
<ng-template #emptyState>
	<app-empty-view [text]="'There are no registrations at the moment.' | translate"></app-empty-view>
</ng-template>
