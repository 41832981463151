import { VelosPluginTypes } from '#/models/velosPluginTypes';
import { OcrTypeModel } from '#/models/ocrType.model';

export class CompanyModule {
	enabled: boolean;

	constructor(data = null) {
		if (data) {
			Object.assign(this, data);
		}
	}
}

export class CompanyModuleOCR extends CompanyModule {
	enabled: boolean;
	mode: OcrTypeModel;
}
export class CompanyModuleHideIntegrations extends CompanyModule {}
export class CompanyModuleGenerateGroupPerUser extends CompanyModule {}
export class CompanyModuleCreditcardStatements extends CompanyModule {}
export class CompanyModuleAutomaticallySubmitInboundMail extends CompanyModule {}
export class CompanyModuleAutomaticallyBookExpenses extends CompanyModule {}
export class CompanyModuleAutomaticallyBookInvoices extends CompanyModule {}
export class CompanyModuleBookingLinesBeforeApproved extends CompanyModule {
	can_see_roles: number;
	can_edit_roles: number;
}
export class CompanyModuleHistoryToBookkeeping extends CompanyModule {}
export class CompanyModuleDatevExportType extends CompanyModule {}
export class CompanyModuleCategoryForInvoices extends CompanyModule {}
export class CompanyModuleCorrectionFields extends CompanyModule {}
export class CompanyModuleCorrectionFieldsCounters extends CompanyModule {}
export class CompanyModulePaymentMethods extends CompanyModule {}
export class CompanyModulePaymentMethodTransactions extends CompanyModule {}
export class CompanyModuleCustomLogo extends CompanyModule {}
export class CompanyModuleReceiptCountry extends CompanyModule {}
export class CompanyModuleRegistrations extends CompanyModule {}
export class CompanyModuleExpenseReports extends CompanyModule {}
export class CompanyModuleHideStatistics extends CompanyModule {}
export class CompanyModuleShowXml extends CompanyModule {}
export class CompanyModuleHideCrisp extends CompanyModule {}
export class CompanyModuleFinanceCanDuplicate extends CompanyModule {}
export class CompanyModuleCardReports extends CompanyModule {}
export class CompanyModuleCreditCardManualUpload extends CompanyModule {
	administration: string;
	transactionInterface: string;
}
export class CompanyModuleVelos extends CompanyModule {
	type: VelosPluginTypes;
}
export class CompanyModuleSpanishCompliance extends CompanyModule {}
export class CompanyModuleImpersonation extends CompanyModule {
	adminsCanImpersonateUsers: boolean;
}
