import { AbstractControl } from '@angular/forms';

export class EqualsValidator {
	static MatchPassword(AC: AbstractControl) {
		const password = AC.get('password').value;
		const password_repeat = AC.get('password_repeat').value;
		if (password !== password_repeat) {
			AC.get('password_repeat').setErrors({ MatchPassword: true });
		} else {
			return null;
		}
	}
}
