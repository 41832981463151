import { NgModule } from '@angular/core';
import { NgxGridModule } from '@klippa/ngx-grid';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { TranslateModule } from '@ngx-translate/core';
import { RouterModule } from '@angular/router';
import { GeneralCompanySettingsPage } from '../../components/company-settings/general-company-settings/general-company-settings.page';
import { GeneralCompanySettingsComponent } from '../../components/company-settings/general-company-settings/general/general-company-settings.component';
import { CompanyDefaultPreferencesComponent } from '../../components/company-settings/general-company-settings/default-preferences/company-default-preferences.component';
import { UsersSettingsComponent } from './users-settings/users-settings.component';
import { ConnectionSettingsComponent } from '../../components/company-settings/connection-settings/connection-settings.component';
import { CostCenterSettingsOverviewComponent } from '~/app/modules/company/components/company-settings/cost-center-settings/cost-center-settings-overview/cost-center-settings-overview.component';
import { AdminSettingsComponent } from '../../components/company-settings/admin-settings/admin-settings.component';
import { PaymentMethodsOverviewComponent } from '~/app/modules/company/components/company-settings/payment-methods/payment-methods-overview/payment-methods-overview.component';
import { PaymentMethodsTableComponent } from '~/app/modules/company/components/company-settings/payment-methods/payment-methods-table/payment-methods-table.component';
import { PaymentMethodImportModalComponent } from '~/app/modules/company/components/company-settings/payment-methods/payment-method-import-modal/payment-method-import-modal.component';
import { BusinessRulesComponent } from '~/app/modules/company/components/company-settings/business-rules/business-rules.component';
import { AuthorizationFlowsComponent } from '~/app/modules/company/components/company-settings/authorization-flows/authorization-flows.component';
import { GroupSettingsOverviewComponent } from '~/app/modules/company/components/company-settings/group-settings/group-settings-overview/group-settings-overview.component';
import { CategorySettingsOverviewComponent } from '~/app/modules/company/components/company-settings/category-settings/category-settings-overview/category-settings-overview.component';
import { CostUnitSettingsOverviewComponent } from '~/app/modules/company/components/company-settings/cost-unit-settings/cost-unit-settings-overview/cost-unit-settings-overview.component';
import { ProjectSettingsOverviewComponent } from '~/app/modules/company/components/company-settings/project-settings/project-settings-overview/project-settings-overview.component';
import { CompensationRulesComponent } from '~/app/modules/company/components/company-settings/compensation-rules/compensation-rules.component';
import { NgbActiveModal, NgbDatepickerModule, NgbDropdownModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { UiModule } from '~/app/shared/ui/ui.module';
import { SortablejsModule } from 'ngx-sortablejs';
import { SharedCompanySettingsModule } from './shared-company-settings.module';
import { CompanyUI } from '../ui/ui.module';
import { PipesModule } from '~/app/helpers/pipes/pipes.module';
import { SubCompaniesOverviewComponent } from '~/app/modules/company/components/company-settings/sub-companies/sub-companies-overview/sub-companies-overview.component';
import { CompanyAgreementsComponent } from '~/app/modules/company/components/company-settings/company-agreements/company-agreements.component';
import { AdministrationSettingsOverviewComponent } from '~/app/modules/company/components/company-settings/administration-settings/administration-settings-overview/administration-settings-overview.component';
import { DeleteCompanyModalComponent } from '~/app/modules/company/components/company-settings/admin-settings/delete-company-modal/delete-company-modal.component';
import { PresetEntryComponent } from '~/app/modules/company/components/company-settings/admin-settings/preset-entry/preset-entry.component';
import { DeprecatedCompensationRulesModalComponent } from '~/app/modules/company/components/company-settings/compensation-rules/modals/deprecated-compensation-rules-modal/deprecated-compensation-rules-modal.component';
import { CompensationRulesEditorModalComponent } from '~/app/modules/company/components/company-settings/compensation-rules/modals/compensation-rules-editor-modal/compensation-rules-editor-modal.component';
import { MappingRulesModalComponent } from './sub-companies/modals/mapping-rules/mapping-rules-modal.component';
import { PluginSettingsComponent } from './plugin-settings/plugin-settings.component';
import { AddNewPluginComponent } from '~/app/modules/company/components/company-settings/plugin-settings/add-new-plugin/add-new-plugin.component';
import { ConfigPluginComponent } from '~/app/modules/company/components/company-settings/plugin-settings/config-plugin/config-plugin.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatTreeModule } from '@angular/material/tree';
import { ExportTemplatesModule } from '~/app/modules/export-templates/export-templates.module';
import { CompanyCreateModalComponent } from '~/app/modules/admin/components/companies/modals/company-create-modal/company-create-modal.component';
import { UsersFilterModalComponent } from '~/app/modules/company/components/company-settings/users-settings/modals/users-filter-modal/users-filter-modal.component';
import { FeatureFlagsComponent } from '~/app/modules/company/components/company-settings/admin-settings/feature-flags/feature-flags.component';
import { BusinessRuleComponent } from './business-rules-new/business-rule/business-rule.component';
import { BusinessRuleGeneralComponent } from './business-rules-new/business-rule/business-rule-general/business-rule-general.component';
import { BusinessRulesTargetComponent } from './business-rules-new/business-rule/business-rules-target/business-rules-target.component';
import { BusinessRulesActionsComponent } from './business-rules-new/business-rule/business-rules-action/business-rules-action.component';
import { BusinessRulesConditionsComponent } from './business-rules-new/business-rule/business-rules-conditions/business-rules-conditions.component';
import { BusinessRulesConditionsFieldValueComponent } from './business-rules-new/business-rule/business-rules-conditions/business-rules-conditions-field-value/business-rules-conditions-field-value.component';
import { BusinessRulesConditionsAgeComponent } from './business-rules-new/business-rule/business-rules-conditions/business-rules-conditions-age/business-rules-conditions-age.component';
import { BusinessRulesConditionsTransactionCountComponent } from './business-rules-new/business-rule/business-rules-conditions/business-rules-conditions-transaction-count/business-rules-conditions-transaction-count.component';
import { ModalModule } from '~/app/shared/ui/modal/modal.module';
import { BusinessRulesCumulativeAmountComponent } from './business-rules-new/business-rule/business-rules-conditions/business-rules-cumulative-amount/business-rules-cumulative-amount.component';
import { ReceiptPresetsComponent } from './admin-settings/receipt-presets/receipt-presets.component';
import { CompanyModule } from '~/app/modules/company/company.module';
import { GlobalAdminCompanyModulesComponent } from './admin-settings/global-admin-company-modules/global-admin-company-modules.component';
import { GlobalAdminCompanyGeneralAddOnsComponent } from './admin-settings/global-admin-company-general-add-ons/global-admin-company-general-add-ons.component';
import { GlobalAdminCompanyExpenseAddOnsComponent } from './admin-settings/global-admin-company-expense-add-ons/global-admin-company-expense-add-ons.component';
import { GlobalAdminCompanyInvoiceAddOnsComponent } from './admin-settings/global-admin-company-invoice-add-ons/global-admin-company-invoice-add-ons.component';
import { GlobalAdminCompanyCustomAddOnsComponent } from './admin-settings/global-admin-company-custom-add-ons/global-admin-company-custom-add-ons.component';
import { GlobalAdminCompanyLegacySettingsComponent } from './admin-settings/global-admin-company-legacy-settings/global-admin-company-legacy-settings.component';
import { GlobalAdminCompanyPreferencesComponent } from './admin-settings/global-admin-company-preferences/global-admin-company-preferences.component';
import { GlobalAdminCompanyLicenseInfoComponent } from '~/app/modules/company/components/company-settings/admin-settings/global-admin-company-license-info/global-admin-company-license-info.component';
import { CompanyTitleChangeModalComponent } from './users-settings/modals/company-title-change-modal/company-title-change-modal.component';
import { CompanyImportCreditcardFilesComponent } from './company-import-creditcard-files/company-import-creditcard-files.component';
import { UserMergeEditorComponent } from '~/app/modules/company/components/company-settings/users-settings/user-merge-editor/user-merge-editor.component';
import { UserEditModalComponent } from '~/app/modules/company/components/company-settings/users-settings/modals/user-edit-modal/user-edit-modal.component';
import { AccountingIntegrationV2Connection } from '~/app/modules/company/components/company-settings/connection-settings/components/accounting-integration-v2-connection/accounting-integration-v2-connection.component';
import { AccountingIntegrationsV2ListComponent } from '~/app/modules/company/components/company-settings/connection-settings/components/accounting-integrations-v2-list/accounting-integrations-v2-list.component';
import { AccountingIntegrationV2ConfigurationCardComponent } from '~/app/modules/company/components/company-settings/connection-settings/components/accounting-integration-v2-configuration-card/accounting-integration-v2-configuration-card.component';
import { AccountingIntegrationFieldsFormComponent } from '~/app/modules/company/components/company-settings/connection-settings/components/accounting-integration-fields-form/accounting-integration-fields-form.component';
import { ConnectionStatusComponent } from '~/app/modules/company/components/company-settings/connection-settings/components/connection-status/connection-status.component';
import { ConnectedIntegrationsListComponent } from '~/app/modules/company/components/company-settings/connection-settings/components/accounting-integrations-v2-list/connected-integrations-list/connected-integrations-list.component';
import { WutuDataTableModule } from 'wutu-data-table';
import { CategoriesDataTable } from '~/app/shared/ui/data-tables/categories-data-table/categories-data-table.component';
import { CategorySettingsFormComponent } from '~/app/modules/company/components/company-settings/category-settings/category-settings-form/category-settings-form.component';
import { CategoryEditBulkFormComponent } from '~/app/modules/company/components/company-settings/category-settings/category-edit-bulk-form/category-edit-bulk-form.component';

/*

IMPORTANT NOTE:
Do not to import this module on other places.
This module is lazy loaded on company settings routes because this module takes up around 1MB of space

*/

@NgModule({
	imports: [
		NgxGridModule,
		RouterModule,
		CommonModule,
		FormsModule,
		SharedCompanySettingsModule,
		NgSelectModule,
		TranslateModule,
		ReactiveFormsModule,
		CommonModule,
		NgbDatepickerModule,
		UiModule,
		SortablejsModule,
		CompanyUI,
		PipesModule,
		NgbTooltipModule,
		NgbDropdownModule,
		DragDropModule,
		MatTreeModule,
		ExportTemplatesModule,
		ModalModule,
		CompanyModule,
		UserEditModalComponent,
		WutuDataTableModule,
	],
	declarations: [
		GeneralCompanySettingsPage,
		CompanyDefaultPreferencesComponent,
		GeneralCompanySettingsComponent,
		GroupSettingsOverviewComponent,
		CategorySettingsOverviewComponent,
		CategoryEditBulkFormComponent,
		CategoriesDataTable,
		CostCenterSettingsOverviewComponent,
		BusinessRulesComponent,
		CompensationRulesComponent,
		AuthorizationFlowsComponent,
		UsersSettingsComponent,
		ConnectionSettingsComponent,
		ConnectionStatusComponent,
		AccountingIntegrationsV2ListComponent,
		ConnectedIntegrationsListComponent,
		AccountingIntegrationV2ConfigurationCardComponent,
		CostUnitSettingsOverviewComponent,
		ProjectSettingsOverviewComponent,
		AdminSettingsComponent,
		PaymentMethodsOverviewComponent,
		PaymentMethodsTableComponent,
		PaymentMethodImportModalComponent,
		SubCompaniesOverviewComponent,
		CompanyAgreementsComponent,
		AdministrationSettingsOverviewComponent,
		DeleteCompanyModalComponent,
		PresetEntryComponent,
		DeprecatedCompensationRulesModalComponent,
		CompensationRulesEditorModalComponent,
		PluginSettingsComponent,
		AddNewPluginComponent,
		ConfigPluginComponent,
		MappingRulesModalComponent,
		CompanyCreateModalComponent,
		UsersFilterModalComponent,
		FeatureFlagsComponent,
		BusinessRuleComponent,
		BusinessRuleGeneralComponent,
		BusinessRulesTargetComponent,
		BusinessRulesActionsComponent,
		BusinessRulesConditionsComponent,
		BusinessRulesConditionsFieldValueComponent,
		BusinessRulesConditionsAgeComponent,
		BusinessRulesConditionsTransactionCountComponent,
		BusinessRulesCumulativeAmountComponent,
		GlobalAdminCompanyLicenseInfoComponent,
		ReceiptPresetsComponent,
		GlobalAdminCompanyModulesComponent,
		GlobalAdminCompanyGeneralAddOnsComponent,
		GlobalAdminCompanyExpenseAddOnsComponent,
		GlobalAdminCompanyInvoiceAddOnsComponent,
		GlobalAdminCompanyCustomAddOnsComponent,
		GlobalAdminCompanyLegacySettingsComponent,
		GlobalAdminCompanyPreferencesComponent,
		CompanyTitleChangeModalComponent,
		CompanyImportCreditcardFilesComponent,
		UserMergeEditorComponent,
		AccountingIntegrationV2Connection,
		AccountingIntegrationFieldsFormComponent,
		CategorySettingsFormComponent,
		CategoryEditBulkFormComponent,
	],
	exports: [CompanyImportCreditcardFilesComponent],
	providers: [NgbActiveModal],
})
export class CompanySettingsModule {}
