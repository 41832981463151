import { AmountWithCurrency } from '#/models/transaction/amountWithCurrency';

export class Currency {
	Code: string;
	Title: string;
	Official: boolean;
	Show: boolean;
	Symbol: string;

	// Internal usage:
	Exchangeable: boolean;

	constructor(data = null) {
		if (data) {
			Object.assign(this, data);
		}
	}

	static fromData(data): Currency[] {
		return data.map((item) => new Currency(item));
	}

	getTitleOrSymbol() {
		if (this.Symbol !== '') {
			return this.Symbol;
		}
		if (this.Code === 'XXX') {
			return '';
		}

		return this.Code;
	}

	get Label() {
		return `${this.getTitle()} (${this.getSymbol()})`;
	}

	getTitle() {
		return this.Title;
	}

	getCode() {
		return this.Code;
	}

	getSymbol() {
		return this.Symbol;
	}

	isExchangeable(): boolean {
		if (typeof this.Exchangeable === 'undefined' || this.Exchangeable === null) {
			return false;
		}
		return this.Exchangeable;
	}

	matches(string: string): boolean {
		/** Returns whether this currency matches a given search string. */
		return (
			this.getTitle().toLowerCase().includes(string.toLowerCase()) ||
			this.getCode().toLowerCase().includes(string.toLowerCase()) ||
			this.getSymbol().toLowerCase().includes(string.toLowerCase())
		);
	}
}

export interface PercentageAndAmount {
	percentage: number;
	amountWithCurrency: AmountWithCurrency;
}
