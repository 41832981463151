import { ExportFormat, ExportType } from '#/models/company/exporterInterfaces';

export enum ExportTemplateUserRole {
	admin = 'admin',
	company = 'company',
	user = 'user',
}

export interface CompactCompany {
	id: string;
	name: string;
}

export class ExportTemplate {
	access_type: 'global' | 'company' | 'user' = null;
	active: boolean = null;
	available_for_all_companies: boolean = null;
	available_for_all_groups: boolean = null;
	can_duplicate: boolean = null;
	can_edit: boolean = null;
	companies: Array<string> = null;
	description: string = null;
	export_type: ExportFormat = null;
	id: string = null;
	createdate: string = null;
	settings: ExportTemplateSettings = null;
	template: {
		Columns?: Array<any>;
		TravelAndReceiptColumns?: Array<any>;
		TravelColumns?: Array<any>;
		integration?: Array<any>;
		CreditcardColumns?: Array<any>;
	} = null;
	type: ExportType = null;
	user_groups: Array<string> = null;
	users: Array<string> = null;

	constructor(data = null) {
		if (data) {
			Object.assign(this, data);
		}
	}

	setData(data) {
		for (const key in data) {
			if (this.hasOwnProperty(key)) {
				this[key] = data[key];
			}
		}
	}
}
export class ExportTemplateDataList {
	data: {
		export_templates: Array<ExportTemplate>;
	};
	request_id: string;
	result: string;
}

export class ExportTemplateSettings {
	URL?: string;
	combine_travel_and_receipts: boolean;
	csv_separator: string;
	decimal_separator: string;
	overview: boolean;
	ubl_add_pdf: boolean;
	ubl_attachments: boolean;
	ubl_combine_attachments: boolean;
	split_per_person: boolean;
	stats: boolean;
	empty_footer?: boolean;
	split_images_from_data?: boolean;
}
