import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { memoize } from 'lodash';
import { ExpenseReportsService } from '#/services/transaction/expense-reports.service';
import { ExpenseReport, PaginatedExpenseReportsRequest, ReportBookingData } from '#/models/transaction/expense-reports';
import { DeclarationStatusFlag, Receipt, ReceiptListAPIRequest } from '#/models/transaction/receipt';
import { AuthenticatedComponent } from '~/app/pages/authenticated/authenticated.component';
import { ActivatedRoute, Params } from '@angular/router';
import { combineLatest, Subscription } from 'rxjs';
import { ColumnsInterface, DataTablesParameters } from '~/app/shared/ui/table/table';
import { ConfirmModalComponent } from '~/app/shared/ui/confirm-modal/confirm-modal.component';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { ActionService, ColumnService, FilterService } from './report-declarations-manage-single.service';
import { SelectActionEnum } from '~/app/shared/ui/select-action/select-action';
import { ActionsMenuActionEnum } from '~/app/shared/ui/actions-menu/actions-menu';
import { isValueSet, stringIsSetAndFilled } from '#/util/values';
import { FilterEnum } from '~/app/shared/ui/filters/filter';
import { CompanyCategoryListAPIRequest } from '#/models/company/category.model';
import { CompanyCategoryService } from '#/services/company/company-category.service';
import { Order } from '#/models/utils/order';
import { ColumnSortingLocalStorageInterface, DashboardColumnSortingService } from '~/app/services/dashboard-column-sorting.service';
import { PossibleCompanyFeatureFlags } from '#/models/company/possible-feature-flags';
import { CompanyFeatureFlagsService } from '#/services/company/company-feature-flags.service';
import { TransactionEditorService } from '#/services/transaction/transaction-editor.service';
import { TransactionInterfaceService } from '#/services/transaction/transaction-interface.service';
import { TransactionFrontendModel } from '#/models/transaction/transaction/frontendModel';
import { SelectActionComponent } from '~/app/shared/ui/select-action/select-action.component';
import { MessageTypes, WSMessage } from '#/models/websocket.model';
import { PageStateService } from '#/services/util/page-state.service';
import { SelectAction } from '#/models/utils/tables';
import { arrayIsSetAndFilled, getObjectFromKeyValuePairs } from '#/util/arrays';
import { RouteUtilsService } from '#/services/util/route-utils.service';
import { CompanyIntegrationService } from '#/services/company/company-integration.service';
import { AccountingIntegrationV2Service } from '#/services/integration/accounting-integration-v2.service';
import { AccountingStatus } from '#/models/accounting-integrations/accounting-integration-v2';

const DEPRECATED_COLUMN_SORTING_LOCALSTORAGE_KEY = 'column_configuration_reports_manage_single';
const COLUMN_SORTING_LOCALSTORAGE_KEY = 'column_configuration_reports_manage_single_v2022-07-12';
const FILTERS_LOCALSTORAGE_KEY = 'filters-report-declarations-manage-single';

@Component({
	templateUrl: './report-declarations-manage-single.component.html',
	styleUrls: ['./report-declarations-manage-single.component.scss'],
})
export class ReportDeclarationsManageSingleComponent extends AuthenticatedComponent implements OnInit, OnDestroy {
	constructor(
		private expenseReportsService: ExpenseReportsService,
		private route: ActivatedRoute,
		private columnService: ColumnService,
		private actionService: ActionService,
		private filterService: FilterService,
		private companyCategory: CompanyCategoryService,
		private dashboardColumnSortingService: DashboardColumnSortingService,
		private companyFeatureFlagsService: CompanyFeatureFlagsService,
		private transactionEditorService: TransactionEditorService,
		private transactionInterfaceService: TransactionInterfaceService,
		private pageStateService: PageStateService,
		private routeUtilsService: RouteUtilsService,
		private companyIntegrationService: CompanyIntegrationService,
		private accountingIntegrationV2Service: AccountingIntegrationV2Service,
	) {
		super();
		this.pageStateService.initPageState();
		this.websocketSubscription = this.websocketsService.onMessage.subscribe((message: WSMessage) => {
			if (message.type === MessageTypes.ReportUpdate) {
				this.initReport();
			}
		});
	}

	@ViewChild('changeStatusReceiptsModal') changeStatusReceiptsModal: ElementRef;
	@ViewChild('bookReportModal') bookReportModal: ElementRef;
	@ViewChild(SelectActionComponent) selectActionComponent: SelectActionComponent;

	public hasAtLeastOneActiveIntegration: boolean;
	public reportPromise: Promise<ExpenseReport>;
	public bookPromise: Promise<void>;
	public reportReceiptsPromise: Promise<{ data: { receipts: Receipt[]; count: number } }>;
	public columns: ColumnsInterface[];
	public totalColumns: ColumnsInterface[];
	public filterOptions: FilterEnum[] = this.filterService.getFilterOptions();
	public filtersLocalStorageKey: string = FILTERS_LOCALSTORAGE_KEY;
	public receiptListAPIRequest = new ReceiptListAPIRequest();
	public showFilters = false;
	public showSelectActions = false;
	public selected: {
		rows: Array<string>;
		all: boolean;
	};

	private predefinedColumns: ColumnsInterface[];
	private prefixedColumns: ColumnsInterface[];
	private endColumns: ColumnsInterface[];
	private combinedRoutesSubscription: Subscription;
	private reportId: string;
	private report: ExpenseReport;
	private paymentInfoEnabled: boolean;
	private websocketSubscription: Subscription;

	public async ngOnInit(): Promise<void> {
		super.ngOnInit();
		this.receiptListAPIRequest.max = 25;
		this.receiptListAPIRequest.start = 0;
		this.receiptListAPIRequest.declarations = true;
		this.receiptListAPIRequest.company = true; // Yes this is a boolean.
		this.receiptListAPIRequest.companyID = this.company.id;
		this.receiptListAPIRequest.sort = 'purchasedate';
		this.receiptListAPIRequest.sortorder = Order.DESCENDING;

		await this.updateAPIRequestBasedOnQueryParams();

		this.combinedRoutesSubscription = combineLatest(this.route.params, this.route.queryParams, (params: Params, queryParams: Params) => ({
			params,
			queryParams,
		})).subscribe(async (res: { params: Params; queryParams: Params }) => {
			this.reportId = res.params.id;
			this.receiptListAPIRequest.report = this.reportId;
			await this.updateAPIRequestBasedOnQueryParams();
			await this.initReport();
		});

		const aav1Items = await this.companyIntegrationService.getActiveIntegrations(
			this.companyIntegrationService.getCompanyOfLoggedUser().id,
		);
		this.hasAtLeastOneActiveIntegration = arrayIsSetAndFilled(aav1Items);
		if (
			!this.hasAtLeastOneActiveIntegration &&
			(await this.companyFeatureFlagsService.companyHasSpecificFeatureFlagEnabled(PossibleCompanyFeatureFlags.AAV2))
		) {
			this.hasAtLeastOneActiveIntegration =
				(await this.accountingIntegrationV2Service.getAllV2ConnectedAccountingIntegrations()).length > 0;
		}
	}

	private async initReport() {
		const categoryAPIFilters = new CompanyCategoryListAPIRequest();
		categoryAPIFilters.max = 1;
		categoryAPIFilters.start = 0;

		const companyCategoryData = await this.companyCategory.getCompanyCategories(categoryAPIFilters);
		const states = {
			categoriesEnabled: Math.floor(companyCategoryData.count) > 0,
			paymentInfoEnabled: this.paymentInfoEnabled,
			compactModeEnabled: this.user?.preferences?.display_mode === 'compact',
		};
		this.setPredefinedColumns(states);

		if (!this.dashboardColumnSortingService.hasColumnsSavedLocally(COLUMN_SORTING_LOCALSTORAGE_KEY)) {
			this.dashboardColumnSortingService.setFirstTimeLocalStorageColumnOrder(
				COLUMN_SORTING_LOCALSTORAGE_KEY,
				(this.localStorageService.get(DEPRECATED_COLUMN_SORTING_LOCALSTORAGE_KEY) as ColumnSortingLocalStorageInterface)?.sortedColumns ??
					this.columnService.getColumns(states),
				DEPRECATED_COLUMN_SORTING_LOCALSTORAGE_KEY,
			);
		}

		this.columns = this.dashboardColumnSortingService.getColumnsBasedOnSortingOrder(
			COLUMN_SORTING_LOCALSTORAGE_KEY,
			this.predefinedColumns,
			this.prefixedColumns,
			this.endColumns,
		);
		if (this.company?.modules?.cardReports?.enabled) {
			this.columns.splice(this.columns.findIndex((e) => e.id === 'declaration_status') + 1, 0, {
				name: 'Document',
				slug: 'document',
				type: 'document',
				enabled: true,
				sortable: false,
				id: 'document',
				sortingProperty: 'document',
			});
		}
		this.totalColumns = this.dashboardColumnSortingService.getBottomRowColumns(this.columns);
		this.fetchReport();
		this.fetchPaymentInfoState();
		await this.setFilteredTransactions();
		await this.updateAPIRequestBasedOnQueryParams();
		await this.fetchReceipts();
	}

	private async updateAPIRequestBasedOnQueryParams(): Promise<void> {
		if (stringIsSetAndFilled(this.route.snapshot.queryParams.itemsPerPage)) {
			this.receiptListAPIRequest.max = Number(this.route.snapshot.queryParams.itemsPerPage);
		}
		if (stringIsSetAndFilled(this.route.snapshot.queryParams.page)) {
			this.receiptListAPIRequest.start = (Number(this.route.snapshot.queryParams.page) - 1) * this.receiptListAPIRequest.max;
		}
		if (stringIsSetAndFilled(this.route.snapshot.queryParams.sort)) {
			this.receiptListAPIRequest.sort = this.route.snapshot.queryParams.sort;
		}

		if (stringIsSetAndFilled(this.route.snapshot.queryParams.sortOrder)) {
			this.receiptListAPIRequest.sortorder = this.route.snapshot.queryParams.sortOrder;
		}
	}

	private async setFilteredTransactions(): Promise<void> {
		const localStoragefilters: PaginatedExpenseReportsRequest = this.localStorageService.get(this.filtersLocalStorageKey);
		if (isValueSet(localStoragefilters)) {
			const allowedFilterFields = Object.entries(localStoragefilters).filter(([key]) => this.filterOptions.includes(key as FilterEnum));
			await this.updateAPIRequestBasedOnQueryParams();
			this.updateRequestFilters(Object.assign(this.receiptListAPIRequest, getObjectFromKeyValuePairs(allowedFilterFields)));
		}
	}

	// Change of table events such as pageLength or next, previous.
	navigateTable(dataTablesParameters: DataTablesParameters) {
		this.receiptListAPIRequest.start = dataTablesParameters.start;
		this.receiptListAPIRequest.max = dataTablesParameters.length;
		this.fetchReceipts();
	}

	updateRequestFilters(request: ReceiptListAPIRequest) {
		this.receiptListAPIRequest = Object.assign(this.receiptListAPIRequest, request);
		this.fetchReceipts();
		this.closeFilters();
	}

	setPredefinedColumns(states: { categoriesEnabled: boolean; paymentInfoEnabled: boolean; compactModeEnabled: boolean }) {
		this.predefinedColumns = this.columnService.getColumns(states);
		this.prefixedColumns = this.predefinedColumns.filter((e) => e.position === 'pre');
		this.endColumns = this.predefinedColumns.filter((e) => e.position === 'end');
	}

	sortTable(sortedColumnsObj) {
		this.columns = sortedColumnsObj.sortedColumns;
		this.totalColumns = sortedColumnsObj.sortedTotalColumns;
		this.dashboardColumnSortingService.setColumnSortingToLocalStorage(sortedColumnsObj.sortedColumns, COLUMN_SORTING_LOCALSTORAGE_KEY);
		this.fetchReceipts();
		this.fetchReport();
	}

	public fetchSortedColumns(event: { sortingProperty: string; sortingOrder: Order }) {
		this.receiptListAPIRequest.sort = event.sortingProperty;
		this.receiptListAPIRequest.sortorder = event.sortingOrder;
		this.fetchReceipts();
		this.fetchReport();
	}

	public async openEditModalWithReceiptId(id: string): Promise<void> {
		const flags = await this.companyFeatureFlagsService.getAllFeatureFlags(this.company.id);
		if (flags.includes(PossibleCompanyFeatureFlags.TRANSACTION_INTERFACES)) {
			this.router.navigate([`tx/${id}`], { relativeTo: this.route });
			return;
		}
		this.reportReceiptsPromise.then((receipts) => {
			const receipt = new Receipt(receipts.data.receipts.find((x) => x.id === id));
			this.editReceiptModal(receipt);
		});
	}

	// Function that receives the callback from the select action.
	executeRequestedSelectAction(action: SelectActionEnum, reportId: string) {
		switch (action) {
			case SelectActionEnum.change_status:
				this.modalService.open(this.changeStatusReceiptsModal);
				break;
			default:
				throw new Error(`Missing action to preform: '${action}'`);
		}
		this.closeSelectActions();
	}

	// Function that receives callback for row action and id of the item.
	executeRequestedRowAction(rowActionObj: { action: ActionsMenuActionEnum; id: string }) {
		switch (rowActionObj.action) {
			case ActionsMenuActionEnum.edit:
				this.openEditModalWithReceiptId(rowActionObj.id);
				break;
			default:
				throw new Error(`Missing action to preform: '${rowActionObj.action}'`);
		}
	}

	saveReceiptStatus(statusChangeObj) {
		const promiseArray = [];
		for (const i in this.selected.rows) {
			if (this.selected.rows.hasOwnProperty(i)) {
				promiseArray.push(
					Promise.resolve(
						this.receiptAPIService.submitStatus(this.selected.rows[i], {
							status: statusChangeObj.status,
							comment: statusChangeObj.remark,
						}),
					),
				);
			}
		}
		Promise.all(promiseArray).then(() => {
			this.notificationService.success(this.getTranslation('Receipt statuses updated'));
			this.modalService.close(this.changeStatusReceiptsModal);
			this.fetchReceipts();
			this.fetchReport();
		});
	}

	fetchPaymentInfoState() {
		this.paymentInfoEnabled = this.userAPIService.getCurrentLoggedUser().preferences.paymentinfo;
	}

	private fetchReport(): void {
		this.reportPromise = this.expenseReportsService.getExpenseReport(this.reportId, false);
		this.reportPromise.then((report: ExpenseReport) => {
			this.report = report;
		});
	}

	bookReport() {
		if (stringIsSetAndFilled(this.report.accounting?.authorization)) {
			this.bookPromise = this.expenseReportsService
				.bookExpenseReportAav2(this.report.id, this.report.accounting)
				.then(async () => {
					this.notificationService.success(this.getTranslation(_('Report is being booked')));
					await this.fetchReceipts();
					this.fetchReport();
				})
				.catch((e) => {
					this.apiNotificationService.handleAPIError(e);
				});
		} else {
			this.bookPromise = this.expenseReportsService
				.bookExpenseReport(this.report.id, this.report.booking_data)
				.then(async () => {
					this.notificationService.success(this.getTranslation(_('Report is being booked')));
					await this.fetchReceipts();
					this.fetchReport();
				})
				.catch((e) => {
					this.apiNotificationService.handleAPIError(e);
				});
		}
	}

	public async fetchReceipts(openIndex = null): Promise<void> {
		this.transactionEditorService.setLatestDashboardFilters(this.receiptListAPIRequest);
		const interfaces = await this.transactionInterfaceService.getInterfaces(false, true, false, null, true);
		this.reportReceiptsPromise = this.receiptAPIService.getReceipts(this.receiptListAPIRequest).then((res) => {
			const result = {
				...res,
				data: {
					...res.data,
					receipts: res.data.receipts?.map((e) => {
						e.transaction_interface_type = interfaces.find((int) => int.id === e.transaction_interface)?.transactionType;
						return new Receipt(e);
					}),
				},
			};
			return result;
		});

		if (openIndex !== null) {
			this.reportReceiptsPromise.then((receipts) => {
				this.editReceiptModal(new Receipt(receipts.data.receipts[openIndex]));
			});
		}
	}

	fetchReceiptsAndGetFirst() {
		this.fetchReport(); // Required for updated booking data.
		this.reportReceiptsPromise = this.receiptAPIService.getReceipts(this.receiptListAPIRequest);

		this.reportReceiptsPromise.then((receipts) => {
			this.editReceiptModal(new Receipt(receipts.data.receipts[0]));
			this.closeBookReportModal();
		});
	}

	private async getNextBookablePage(): Promise<void> {
		this.receiptListAPIRequest.start = this.receiptListAPIRequest.start + this.receiptListAPIRequest.max;
		await this.fetchReceipts(0);
		this.reportReceiptsPromise.then((receipts) => {
			this.getNextBookableReceipt(receipts.data.receipts[0].id);
		});
	}

	getNextBookableReceipt(receiptId) {
		// Check if bookable, else use directional normal next.
		if (!stringIsSetAndFilled(this.report?.booking_data?.provider)) {
			return this.getReceiptWithDirection(receiptId, 'ArrowRight');
		}

		this.reportReceiptsPromise.then(async (receipts) => {
			const currentIndex = receipts.data.receipts.findIndex((x) => x.id === receiptId);
			const receiptsToBeBooked = receipts.data.receipts
				.filter((receipt: Receipt) => receipt.validated_bookings === false) // only receipts with not yet validated bookings
				.filter((receipt: Receipt) => receipt.id !== receiptId); // not the current receipt
			if (receiptsToBeBooked.length > 0) {
				return this.editReceiptModal(new Receipt(receiptsToBeBooked[0]));
			}
			// Check if next page is available.
			if (
				currentIndex + 1 === receipts.data.receipts.length &&
				receipts.data.count > this.receiptListAPIRequest.start + this.receiptListAPIRequest.max
			) {
				await this.getNextBookablePage();
			}
		});
	}

	getReceiptWithDirection(receiptId, direction: 'ArrowRight' | 'ArrowLeft') {
		this.reportReceiptsPromise.then(async (receipts) => {
			// Get receipts that have not been processed.
			const currentIndex = receipts.data.receipts.findIndex((x) => x.id === receiptId);
			if (direction === 'ArrowRight') {
				// Check if next page is available.
				if (
					currentIndex + 1 === receipts.data.receipts.length &&
					receipts.data.count > this.receiptListAPIRequest.start + this.receiptListAPIRequest.max
				) {
					this.receiptListAPIRequest.start = this.receiptListAPIRequest.start + this.receiptListAPIRequest.max;
					await this.fetchReceipts(0);
				}
				if (currentIndex + 1 < receipts.data.receipts.length) {
					this.editReceiptModal(new Receipt(receipts.data.receipts[currentIndex + 1]));
				}
			}
			if (direction === 'ArrowLeft') {
				if (this.receiptListAPIRequest.start > 0) {
					this.receiptListAPIRequest.start = this.receiptListAPIRequest.start - this.receiptListAPIRequest.max;
					await this.fetchReceipts(this.receiptListAPIRequest.max - 1); // minus is for index starting at 0.
				}
				if (currentIndex > 0 && currentIndex < receipts.data.receipts.length) {
					this.editReceiptModal(new Receipt(receipts.data.receipts[currentIndex - 1]));
				}
			}
		});
	}

	getSelectActions = () => {
		return this.actionService.getSelectActions();
	};
	getRowActions = (row: Receipt) => {
		return this.actionService.getRowActions();
	};

	// FIXME: Replace this modal service
	async editReceiptModal(receipt: Receipt) {
		const flags = await this.companyFeatureFlagsService.getAllFeatureFlags(this.company.id);
		if (flags.includes(PossibleCompanyFeatureFlags.TRANSACTION_INTERFACES)) {
			await this.router.navigate([`tx/${receipt.id}`], {
				relativeTo: this.route,
			});
			return;
		}
		this.receiptFlowService.initWithPreset(null, this.user);
		this.showSelectActions = false;
		const { ReceiptEditModalComponent } = await import(
			'~/app/modules/receipt/components/modals/receipt-edit-modal/receipt-edit-modal.component'
		);
		const modalRef = this.modalService.open(ReceiptEditModalComponent, {
			size: 'lg',
			beforeDismiss: () => {
				if (modalRef.componentInstance.hasReceiptBeenChanged()) {
					return new Promise((resolve, reject) => {
						const confirmModalRef = this.modalService.open(ConfirmModalComponent);
						confirmModalRef.componentInstance.type = 'text';
						confirmModalRef.componentInstance.title = this.translate.instant(_('Dismiss changes'));
						confirmModalRef.componentInstance.message = this.translate.instant(_('Are you sure that you want to dismiss your changes?'));
						const sub = confirmModalRef.componentInstance.result.subscribe((data) => {
							resolve(data.result);
							sub.unsubscribe();
						});
						this.destroyCallbacks.push(() => {
							sub.unsubscribe();
						});
					});
				}
			},
		});

		modalRef.componentInstance.report = this.report;
		modalRef.componentInstance.savedInReport = isValueSet(receipt.report);
		modalRef.componentInstance.receipt = receipt;
		modalRef.componentInstance.user = this.user;
		modalRef.componentInstance.tableType = 'expenses';
		let arrowSubscription = null;
		let nextSubscription = null;
		if (modalRef.componentInstance.arrow) {
			arrowSubscription = modalRef.componentInstance.arrow.subscribe((response) => {
				this.getReceiptWithDirection(receipt.id, response[0]);
				modalRef.close();
			});
			nextSubscription = modalRef.componentInstance.nextReceipt.subscribe((response) => {
				this.getNextBookableReceipt(receipt.id);
				modalRef.close();
			});
		}
		modalRef.result.then(() => {
			if (arrowSubscription) {
				nextSubscription.unsubscribe();
				arrowSubscription.unsubscribe();
			}
		});
		try {
			await modalRef.result;
		} finally {
			this.fetchReport();
			await this.fetchReceipts();
		}
	}

	openSelectActions(event: any) {
		this.selected = event;
		this.showSelectActions = true;
		setTimeout(() => {
			// timeout because the action component needs to be rendered first based on `this.showSelectActions = true;`
			this.selectActionComponent.openActionList();
		});
	}

	openFilters() {
		this.showFilters = true;
	}

	closeFilters() {
		this.showFilters = false;
	}

	closeSelectActions() {
		this.showSelectActions = false;
	}

	public async closeChangeStatusModal(): Promise<void> {
		this.modalService.close(this.changeStatusReceiptsModal);
		await this.fetchReceipts();
		this.fetchReport();
	}

	openReportBookingsSettingsModal() {
		this.modalService.open(this.bookReportModal);
	}

	closeBookReportModal() {
		this.modalService.close(this.bookReportModal);
	}

	public updateRouteWithSortingParams(event: { sortingProperty: string; sortingOrder: Order }): void {
		const newUrl: string = this.routeUtilsService.getUpdateRouteWithNewParamValue(
			this.router.url.split('?')[0],
			this.route.snapshot.queryParams,
			{
				sort: event.sortingProperty,
				sortOrder: event.sortingOrder,
			},
		);
		this.router.navigateByUrl(newUrl);
	}

	/* tslint:disable:member-ordering */
	public getStatusesToChooseFrom = memoize(async (txIds: Array<string>) => {
		const transactions: Array<TransactionFrontendModel> = await Promise.all(
			txIds.map((e) => this.transactionEditorService.getTransaction(e) as Promise<TransactionFrontendModel>),
		);
		const statuses: Array<DeclarationStatusFlag> = await this.transactionInterfaceService.getOverlappingStatusesToChooseFrom(
			transactions.map((e) => e.transactionInterfaceId),
		);
		return statuses;
	});

	public selectAllTransactionsOnAllPages = async (selectAction: SelectAction): Promise<Array<string>> => {
		this.selected = await this.receiptAPIService.getSelectedForAllReceipts(selectAction, this.receiptListAPIRequest);
		return this.selected.rows;
	};

	public ngOnDestroy(): void {
		super.ngOnDestroy();
		this.combinedRoutesSubscription.unsubscribe();
		this.websocketSubscription.unsubscribe();
	}

	shouldShowStartBooking(): boolean {
		if (stringIsSetAndFilled(this.report.accounting?.authorization)) {
			return false;
		}
		return this.report.expense_status?.current_status?.status === 'Approved' && !this.report?.booking_data?.startedBooking;
	}

	shouldShowEditBooking(): boolean {
		if (
			stringIsSetAndFilled(this.report.accounting?.authorization) &&
			this.report.accounting.status !== AccountingStatus.BOOKED &&
			this.report.accounting.status !== AccountingStatus.PROCESSED
		) {
			return true;
		}
		return (
			this.report.expense_status?.current_status?.status === 'Approved' &&
			this.report?.booking_data?.startedBooking &&
			!this.report.booking_data?.bookingstatus?.is_booked
		);
	}

	shouldShowBookReport(): boolean {
		if (this.report.accounting?.status === 'PRE_BOOKING') {
			return true;
		}
		return (
			this.report.expense_status?.current_status?.status === 'Approved' &&
			this.report?.booking_data?.startedBooking &&
			!this.report.booking_data?.bookingstatus?.is_booked
		);
	}

	reportIsBookedAAv1(): boolean {
		if (this.report?.booking_data?.bookingstatus?.is_booked) {
			return true;
		}
		return false;
	}
	reportIsBookedAAv2(): boolean {
		if (stringIsSetAndFilled(this.report?.accounting?.bookedOn)) {
			return true;
		}
		return false;
	}

	getBookedStatusAAv2(): ReportBookingData {
		return {
			bookingstatus: { is_booked: true, bookedon: new Date(this.report.accounting.bookedOn) },
			bookingQueue_data: { backgroundQueueError: null, canbePickedUpByQueue: false },
			booking_date: this.report.accounting.bookedOn,
			startedBooking: false,
		};
	}
}
