import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { UiModule } from '~/app/shared/ui/ui.module';
import { NgSelectModule } from '@ng-select/ng-select';
import { TranslateModule } from '@ngx-translate/core';
import { CompanyUI } from '~/app/modules/company/components/ui/ui.module';
import { RouterModule } from '@angular/router';
import { PipesModule } from '~/app/helpers/pipes/pipes.module';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { SortablejsModule } from 'ngx-sortablejs';
import { ExportTemplateEditorComponent } from '~/app/pages/authenticated/export-templates/export-template-editor/export-template-editor.component';
import { ExportTemplatesOverviewPageComponent } from '~/app/pages/authenticated/export-templates/export-templates-overview/export-templates-overview-page.component';
import { DuplicateDownModalComponent } from './models/duplicate-down-modal/duplicate-down-modal.component';
import { ExportTemplatesOverviewComponent } from '~/app/modules/export-templates/components/export-templates-overview/export-templates-overview.component';
import { ModalModule } from '~/app/shared/ui/modal/modal.module';
import { ExportModule } from '~/app/modules/export/export.module';

@NgModule({
	imports: [
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		UiModule,
		NgSelectModule,
		TranslateModule,
		CompanyUI,
		RouterModule,
		PipesModule,
		NgbTooltipModule,
		SortablejsModule,
		ModalModule,
		ExportModule,
	],
	declarations: [
		ExportTemplateEditorComponent,
		ExportTemplatesOverviewPageComponent,
		DuplicateDownModalComponent,
		ExportTemplatesOverviewComponent,
	],
	providers: [],
})
export class ExportTemplatesModule {}
