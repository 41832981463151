import { Injectable } from '@angular/core';
import { APIService } from '~/app/api/services/api.service';
import {
	ExpenseReport,
	ExpenseReportExportRequest,
	ExportDocumentResponse,
	PaginatedExpenseReports,
	PaginatedExpenseReportsRequest,
	ReportBookingData,
} from '#/models/transaction/expense-reports';
import { DeclarationStatusFlag, Receipt, ReceiptAuthorizationFlowStatus } from '#/models/transaction/receipt';
import { APINotificationsService } from '~/app/api/services/apinotifications.service';
import { isNullOrUndefined, isValueSet } from '#/util/values';
import { ReportType } from '#/models/reportType.model';
import { SelectAction, SelectedRows } from '#/models/utils/tables';

const expenseReportsURL = 'report';
const fromCacheIfNewerThanMsAgo = 60 * 1000;

@Injectable({
	providedIn: 'root',
})
export class ExpenseReportsService {
	constructor(private apiService: APIService, private notifications: APINotificationsService) {}

	public getExpenseReports(request: PaginatedExpenseReportsRequest): Promise<PaginatedExpenseReports> {
		return this.apiService.get(request.getRequestURL()).then((res) => {
			return new PaginatedExpenseReports(res.data);
		});
	}

	public async getSelectedForAllExpenseReports(selectAction: SelectAction, request: PaginatedExpenseReportsRequest): Promise<SelectedRows> {
		if (selectAction === 'deselectAll') {
			return { all: false, rows: [] };
		}
		request.start = null;
		const rows = await this.getAllExpenseReports(request).then((res) => res?.map((e) => e.id));
		return { all: true, rows };
	}

	public getAllExpenseReports(request: PaginatedExpenseReportsRequest): Promise<Array<ExpenseReport>> {
		request.max = 100;
		return this.apiService.get(request.getRequestURL()).then(async (res) => {
			let reports = res?.data?.reports.map((r) => new ExpenseReport(r));
			if (res.data.moreresults) {
				const moreDataRequest = request;
				moreDataRequest.start = request.start + request.max;
				const moreReports = await this.getAllExpenseReports(moreDataRequest);
				reports = [...reports, ...moreReports];
			}
			return reports;
		});
	}

	public getExpenseReport(id: string, useCache: boolean = true): Promise<ExpenseReport> {
		const newerThanMsAgo = useCache ? fromCacheIfNewerThanMsAgo : 0;
		return this.apiService.getFromApi(expenseReportsURL + '/' + id, newerThanMsAgo).then((res) => {
			return new ExpenseReport(res.data);
		});
	}

	getExpenseReportAuthorizationFlowStatus(id: string): Promise<ReceiptAuthorizationFlowStatus> {
		return this.apiService.getFromApi(`${expenseReportsURL}/${id}/authorizationFlow`).then((res) => {
			return new ReceiptAuthorizationFlowStatus(res.data);
		});
	}

	public createExpenseReport(body: ExpenseReport): Promise<ExpenseReport> {
		return this.apiService
			.postToApi(expenseReportsURL, body)
			.then((res) => {
				return new ExpenseReport(res.data);
			})
			.catch((e) => {
				this.notifications.handleAPIError(e);
				throw e;
			});
	}

	public deleteExpenseReport(id: string, target: 'expenses' | 'report'): Promise<ExpenseReport> {
		this.deleteReportFromCache(id);

		// Expenses target is used for retracting the full report.
		let url;
		switch (target) {
			case 'expenses':
				url = `/api/v1/${expenseReportsURL}/${id}/expenses`;
				break;
			case 'report':
				url = `/api/v1/${expenseReportsURL}/${id}`;
				break;
		}
		return this.apiService
			.delete(url)
			.then((res) => {
				return new ExpenseReport(res.data);
			})
			.catch((e) => {
				this.notifications.handleAPIError(e);
				throw e;
			});
	}

	public excludeReceipt(reportId: string, receiptId: string) {
		this.deleteReportFromCache(reportId);
		return this.apiService
			.postToApi(`${expenseReportsURL}/${reportId}/receipts/${receiptId}/remove`)
			.then((res) => {
				return new ExpenseReport(res.data);
			})
			.catch((e) => {
				this.notifications.handleAPIError(e);
				throw e;
			});
	}

	public updateExpenseReport(body: ExpenseReport): Promise<ExpenseReport> {
		this.deleteReportFromCache(body.id);
		return this.apiService
			.patch(`/api/v1/${expenseReportsURL}/${body.id}`, body)
			.then((res) => {
				return new ExpenseReport(res.data);
			})
			.catch((e) => {
				this.notifications.handleAPIError(e);
				throw e;
			});
	}

	public submitExpenseReport(id: string): Promise<ExpenseReport> {
		this.deleteReportFromCache(id);
		return this.apiService
			.postToApi(`${expenseReportsURL}/${id}/expenses`)
			.then((res) => {
				return new ExpenseReport(res.data);
			})
			.catch((e) => {
				this.notifications.handleAPIError(e);
				throw e;
			});
	}

	public resubmitExpenseReportReceipt(reportId: string, receiptId: string): Promise<Receipt> {
		this.deleteReportFromCache(reportId);
		return this.apiService
			.patch(`/api/v1/${expenseReportsURL}/${reportId}/receipts/${receiptId}/resubmit`, null)
			.then((res) => {
				return new Receipt(res.data);
			})
			.catch((e) => {
				this.notifications.handleAPIError(e);
				throw e;
			});
	}

	public bookExpenseReport(reportId: string, bookingData: ReportBookingData): Promise<ExpenseReport> {
		this.deleteReportFromCache(reportId);
		return this.apiService.postToApi(`${expenseReportsURL}/${reportId}/integrations/book`, bookingData).then((res) => {
			return new ExpenseReport(res.data);
		});
	}

	public bookExpenseReportAav2(reportId: string, accounting: Record<string, any>): Promise<ExpenseReport> {
		this.deleteReportFromCache(reportId);
		return this.apiService
			.postToApi(`${expenseReportsURL}/${reportId}/accounting/book`, {
				authorization: accounting.authorization,
				fields: accounting.fields,
			})
			.catch((e) => {
				throw { ...e, error: { ...e.error, message: e.error.data } };
			})
			.then((res) => {
				return new ExpenseReport(res.data);
			});
	}

	public updateBookingDataExpenseReportAav1(reportId: string, bookingData: ReportBookingData): Promise<ExpenseReport> {
		this.deleteReportFromCache(reportId);
		return this.apiService.patch(`/api/v1/${expenseReportsURL}/${reportId}/integrations/book/data`, bookingData).then((res) => {
			return new ExpenseReport(res.data);
		});
	}

	public updateBookingDataExpenseReportAav2(
		reportId: string,
		authorizationId: string,
		bookingData: Record<string, any>,
	): Promise<ExpenseReport> {
		this.deleteReportFromCache(reportId);
		return this.apiService
			.patchToApi(`${expenseReportsURL}/${reportId}/accounting`, {
				accounting: {
					authorization: authorizationId,
					fields: bookingData,
				},
			})
			.then((res) => {
				return new ExpenseReport(res.data);
			});
	}

	public updateReportAuthorizationFlowStatus(
		id: string,
		authorizationFlowID: string,
		approvers: string[],
		requireApproverOrder: boolean,
		requireApproverCount?: number,
	): Promise<Receipt> {
		const body = {
			authorization_flow_id: authorizationFlowID,
			approvers: approvers,
			require_approver_order: requireApproverOrder,
			require_approver_count: requireApproverCount,
		};
		this.deleteReportFromCache(id);
		return this.apiService
			.post('/api/v1/report/' + id + '/authorizationFlow', body)
			.then((res) => {
				return res.data;
			})
			.catch((e) => {
				this.notifications.handleAPIError(e);
				throw e;
			});
	}

	public getBookingSuggestions(companyId: string, divisionId: string) {
		return this.apiService
			.postToApi(`company/${companyId}/report/bookings/suggestions`, {
				division: divisionId,
			})
			.then((res) => res.data);
	}

	private hasStatus(flag: DeclarationStatusFlag, ...possibleFlags: Array<DeclarationStatusFlag>) {
		if (isNullOrUndefined(flag)) {
			return false;
		}
		return possibleFlags.includes(flag);
	}

	public canEditReport(report: ExpenseReport): boolean {
		if (!isValueSet(report)) {
			return false;
		}

		if (report?.type === ReportType.CARD) {
			return false;
		}
		if (this.hasStatus(report?.status_to_show, DeclarationStatusFlag.NotSubmitted)) {
			return true;
		}
		return false;
	}

	public canSubmitReport(report: ExpenseReport): boolean {
		if (!isValueSet(report)) {
			return false;
		}
		if (!report.allowedActions.submit) {
			return false;
		}
		if (this.hasStatus(report.status_to_show, DeclarationStatusFlag.NotSubmitted) && report.receipt_count > 0) {
			return true;
		}
		return false;
	}

	public showSubmitButton(report: ExpenseReport): boolean {
		if (this.hasStatus(report.status_to_show, DeclarationStatusFlag.NotSubmitted) && report.receipt_count > 0) {
			return true;
		}
		return false;
	}

	public canRetractReport(report: ExpenseReport): boolean {
		if (!isValueSet(report)) {
			return false;
		}
		if (!report.allowedActions.retract) {
			return false;
		}
		if (
			this.hasStatus(
				report.status_to_show,
				DeclarationStatusFlag.ToClaim,
				DeclarationStatusFlag.Accepted,
				DeclarationStatusFlag.NeedsInformation,
			)
		) {
			return true;
		}
		return false;
	}

	public canDeleteReport(report: ExpenseReport): boolean {
		if (!isValueSet(report)) {
			return false;
		}
		if (!report.allowedActions.delete) {
			return false;
		}
		if (this.hasStatus(report?.status_to_show, DeclarationStatusFlag.NotSubmitted)) {
			return true;
		}
		return false;
	}

	public canAddItemInReport(report: ExpenseReport): boolean {
		if (!isValueSet(report)) {
			return false;
		}
		if (!report.allowedActions.addReceipts) {
			return false;
		}
		if (this.hasStatus(report.status_to_show, DeclarationStatusFlag.NotSubmitted)) {
			return true;
		}
		return false;
	}

	public canEditItemInReport(receipt: Receipt): boolean {
		if (
			isNullOrUndefined(receipt?.declarationstatus?.status) ||
			receipt.declarationstatus?.status === DeclarationStatusFlag.NeedsInformation
		) {
			return true;
		}
		return false;
	}

	public canDuplicateItemInReport(report: ExpenseReport): boolean {
		return this.canAddItemInReport(report);
	}

	public canExcludeItemInReport(report: ExpenseReport): boolean {
		if (!isValueSet(report)) {
			return false;
		}
		if (!report.allowedActions?.removeReceipts) {
			return false;
		}
		if (
			this.hasStatus(
				report.status_to_show,
				DeclarationStatusFlag.NotSubmitted,
				DeclarationStatusFlag.NeedsInformation,
				DeclarationStatusFlag.Denied,
			)
		) {
			return true;
		}
		return false;
	}

	public canDeleteItemInReport(report: ExpenseReport): boolean {
		if (!isValueSet(report)) {
			return false;
		}
		if (!report.allowedActions.removeReceipts) {
			return false;
		}
		if (this.hasStatus(report.status_to_show, DeclarationStatusFlag.NotSubmitted)) {
			return true;
		}
		return false;
	}

	public canShareItemInReport(report: ExpenseReport) {
		if (!isValueSet(report)) {
			return false;
		}
		if (!report.allowedActions.shareReceipts) {
			return false;
		}
		return true;
	}

	public exportExpenseReports(request: ExpenseReportExportRequest): Promise<ExportDocumentResponse> {
		return this.apiService.post('/api/v1/report/export', request).catch((r) => {
			this.notifications.handleAPIError(r);
			throw r;
		});
	}

	private deleteReportFromCache(reportId: string) {
		this.apiService.deleteFromCacheWhereUrlStartsWith(expenseReportsURL + '/' + reportId);
	}
}
