import { Injectable } from '@angular/core';
import { APIService } from '~/app/api/services/api.service';
import { APINotificationsService } from '~/app/api/services/apinotifications.service';
import { isValueSet } from '#/util/values';
import { arrayIsSetAndFilled } from '#/util/arrays';
import { CompanyDimensionLimitationData, CompanyDimensionLimitationListAPIRequest } from '#/models/company/company.model';
import { CompanyCostCenterListAPIRequest } from '#/models/company/dimension/cost-center.model';
import { CompanyCostUnitListAPIRequest } from '#/models/company/dimension/cost-unit.model';
import { CompanyProjectListAPIRequest } from '#/models/company/dimension/project.model';

@Injectable({
	providedIn: 'root',
})
export class CompanyDimensionsService {
	protected static requestCache = new Map<string, Promise<any>>();

	constructor(protected apiService: APIService, protected notifications: APINotificationsService) {}

	// TODO abstract dimension based request
	protected getRequestBody(request: CompanyCostCenterListAPIRequest | CompanyCostUnitListAPIRequest | CompanyProjectListAPIRequest): {
		[k in string]: any;
	} {
		const body: { [k in string]: any } = {};
		const ids = request.ids?.filter(isValueSet);
		if (arrayIsSetAndFilled(ids)) {
			body.ids = ids;
		}

		const administrationIds = request.administrations?.filter(isValueSet);
		if (arrayIsSetAndFilled(administrationIds)) {
			body.administrationFilter = {
				operator: 'IN',
				ids: administrationIds,
			};
		}
		return body;
	}

	protected filterBasedOnPresetsAndAdministration(data: any[], presets: string[], administration) {
		let tempData = data;
		if (isValueSet(administration)) {
			tempData = tempData.filter((c) => c.administration === administration);
		}
		if (arrayIsSetAndFilled(presets)) {
			tempData = tempData.filter((item) => presets.includes(item.id));
		}
		return tempData;
	}

	getCompanyDimensionLimitations(
		companyDimensionLimitationListAPIRequest: CompanyDimensionLimitationListAPIRequest,
	): Promise<CompanyDimensionLimitationData> {
		return this.apiService
			.get(companyDimensionLimitationListAPIRequest.getRequestURL())
			.then((r) => new CompanyDimensionLimitationData(r.data))
			.catch((e) => {
				this.notifications.handleAPIError(e);
				throw e;
			});
	}
}
