<app-dynamic-options-picker
	[getPlaceholderTextFn]="getPlaceholderTextFn"
	[fetchItemsFn]="fetchItemsFn"
	[fetchSelectedItemsFn]="fetchSelectedItemsFn"
	[mapToSelectOptionFn]="mapToSelectOptionFn"
	[sortValuesFn]="sortValuesFn"
	[selectableItems]="selectableItems"
	[inErrorState]="isInErrorState()"
	[(ngModel)]="innerValue"
	(ngModelChange)="setInnerValueAndNotify($event)"
	[multiple]="multiple"
	[prefix]="prefix"
	[disabled]="disabled"
	[clearable]="clearable"
	[dropdownPosition]="dropdownPosition"
	[orientation]="orientation"
	[truncateOptions]="truncateOptions"
	[autoSelectOnSingleResult]="autoSelectOnSingleResult"
	(onOpened)="onOpened.emit()"
	(onClosed)="onClosed.emit()"
	[customSearchFn]="customSearchFn"
	[newOptionFooterPrefix]="newOptionFooterPrefix"
	(onFooterClick)="_onFooterClick($event)"
	[getTailTplFn]="getTailTpl"
>
	<ng-container *ngIf="customOptionTpl">
		<ng-template klpSelectOptionTpl let-item="item">
			<ng-container [ngTemplateOutlet]="customOptionTpl" [ngTemplateOutletContext]="{ item: item }"> </ng-container>
		</ng-template>
	</ng-container>
</app-dynamic-options-picker>
