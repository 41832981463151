export class Merchant {
	id: string;
	createdate: Date;
	updatedate: Date;
	title: string;
	logo?: string;
	synonyms: string[];
	reviewed: boolean;
	color?: string;
	ignored: boolean;

	constructor(data = null) {
		if (data) {
			Object.assign(this, data);
		}
	}

	static fromData(data): Merchant[] {
		return data.map((item) => new Merchant(item));
	}

	getID(): string {
		return this.id;
	}

	hasLogo(): boolean {
		if (this.logo && this.logo !== '') {
			return true;
		}

		return false;
	}

	getLogo(): string {
		return this.logo;
	}

	getTitle(): string {
		return this.title;
	}

	getSynonyms(): string[] {
		return this.synonyms;
	}

	isIgnored(): boolean {
		return this.ignored;
	}

	isReviewed(): boolean {
		return this.reviewed;
	}

	getSortedSynonyms(): string[] {
		if (this.getSynonyms()) {
			return this.getSynonyms().sort((a, b) => a.localeCompare(b));
		}
		return [];
	}

	matches(string: string): boolean {
		/** Returns whether this Merchants matches a given search string. */
		return this.getTitle().toLowerCase().includes(string.toLowerCase());
	}

	clone(): Merchant {
		return new Merchant(JSON.parse(JSON.stringify(this)));
	}
}

export class UnreviewedMerchant {
	merchant: Merchant;
	total_receipts: number;

	constructor(data = null) {
		if (data) {
			Object.assign(this, data);

			if (data.merchant) {
				this.merchant = new Merchant(data.merchant);
			}
		}
	}

	static fromData(data): UnreviewedMerchant[] {
		return data.map((item) => new UnreviewedMerchant(item));
	}

	getMerchant(): Merchant {
		return this.merchant;
	}

	getTotalReceipts(): number {
		return this.total_receipts;
	}
}
