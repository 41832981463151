import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { AuthManagerService } from '~/app/services/auth-manager.service';
import { stringIsSetAndFilled } from '#/util/values';

@Injectable({
	providedIn: 'root',
})
export class CanAccessAuthenticatedRoutesGuard implements CanActivate {
	constructor(
		private authManagerService: AuthManagerService,
		private router: Router
	) {
	}
	canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree {
		if (stringIsSetAndFilled(this.authManagerService.getRefreshToken()) || stringIsSetAndFilled(this.authManagerService.getKey())) {
			return true;
		}
		return this.router.createUrlTree(['/logout']);
	}
}
