import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';

const apiErrorCodes = {
	0: _('Something went wrong, please try again.'),
	100001: _('Missing X-Auth-Key header'),
	100002: _('Missing X-Auth-Timestamp header'),
	100003: _('Missing X-Auth-Nonce header'),
	100004: _('Missing X-Auth-Signature header'),
	100005: _('Request expired: timestamp is too old or too new, try syncing your time with NTP.'),
	100006: _('Nonce is too long.'),
	100007: _('Invalid number_once, should be [a-zA-Z0-9]'),
	100008: _('Duplicated request, please try again'),
	100009: _('Unknown key'),
	100010: _('Invalid signature'),
	100011: _('Signature mismatch'),
	100012: _('Could not load user'),
	100013: _('User is disabled'),
	100014: _('Token Expired, use the refresh token to renew your token'),
	100016: _('Insufficient Permissions'),
	100017: _('As an impersonator, you are not allowed to make changes for this'),
	100018: _('You need to log in with 2FA in order to proceed'),
	100019: _('2FA is required in order to proceed'),
	200001: _('Unknown email/password combination.'),
	200002: _('User is disabled'),
	200003: _('Key creation failed'),
	300001: _('Refresh token mismatch'),
	300002: _('Key creation failed'),
	400002: _('Email already exists'),
	400003: _('Error while checking username/email'),
	400004: _('Key creation failed'),
	400005: _('User creation failed'),
	400006: _('Email activate failed'),
	400007: _('Email already validated'),
	400008: null,
	400009: _('Password reset failed'),
	400010: _('Password reset request failed'),
	400011: _('User update failed'),
	400012: _('Current password is wrong'),
	400013: _('Missing current password'),
	400014: _('User preference update failed'),
	400015: _('Barcode error'),
	400016: _('Unknown push type'),
	400017: _('Push subscribe failed'),
	400018: _('Push unsubscribe failed'),
	400019: _('Already subscribed with this id'),
	400020: _('No push subscription found'),
	400021: _('Wrong inbound mail password'),
	400022: _('Mail address is not validated yet'),
	400023: _('Inbound mail key reset failed'),
	400024: _('Trusted contact not found'),
	400025: _('Invalid Trusted Contact ID'),
	400026: _('Error while updating trusted contacts'),
	400027: _('Error while inviting user'),
	400028: _('OAuth login failed, reason in message'),
	400029: null,
	400030: _('No email given by provider or user'),
	400031: _('OAuth connect failed, reason in message'),
	400032: _('OAuth ID already connected to another account'),
	400033: _('OAuth disconnect failed, reason in message'),
	400034: _('Already connected to this provider'),
	400035: _('Already disconnected from this provider'),
	400036: _('Unknown provider'),
	400037: _('Payment failed'),
	400038: _('Payment cancel failed'),
	400039: _('Payment already exists'),
	400040: _('Error while fetching shares'),
	400041: _('Error while used payment methods'),
	400048: _('Request failed'),
	400049: _('Confirmation failed'),
	400050: _('Password is incorrect'),
	400051: null,
	400052: _('Wrong confirmation key'),
	400059: _('Not a valid email address'),
	400060: _('Error looking up SSO company'),
	400061: _('Not an SSO email address'),
	400062: _('SSO token not found, possibly it expired, please try again'),
	400063: _('Invalid redirection URI'),
	400064: _('SSO error, please try again'),
	400065: _('SSO did not return an email address to log in with'),
	400066: _('User not found, and company had creation of new users disabled'),
	400067: _('User was found, but it is not linked to the SSO company'),
	400068: _('Can not reset user, because it was created by the SSO functionality'),
	400069: _('SSO returned email address with a domain that is not connected to the company'),
	400070: _('Current password is wrong'),
	400071: null,
	400072: _('User is not in email change process, you possible already confirmed the change'),
	400073: _('Email change token mismatch, you possible used an old link'),
	400074: _('Email change token already confirmed, please move to the next step'),
	400075: _('Old email change token has not been confirmed yet'),
	400076: _('Email change request failed'),
	400077: _('The new email address is is already taken by another account'),
	400078: _('The account belongs to a company user, company users can not change the email address'),
	400079: _('New email address must be different from old email address'),
	400080: _('User is not in a company invite process, you possible already accepted the invite'),
	400081: _('Company invite token mismatch, you possible used an old link'),
	400082: _('Error while accepting company invite'),
	400086: _('Incorrect token. Make sure you enter the exact token given by your authenticator app.'),
	400087: _('Token can not be empty.'),
	400088: _('Incorrect token. Make sure you enter the exact token given by your authenticator app.'),
	400093: _('Token can not be empty.'),
	400094: _('Incorrect recovery code. Make sure you enter the correct recovery code'),
	400095: _('You do not have recovery codes enabled. Please contact support.'),
	500001: _('Could not load receipts'),
	500002: _('Could not create receipt'),
	500003: _('Could not attach image'),
	500004: _('This action is not allowed; you do not have sufficient permissions'),
	500005: _('Image not found'),
	500006: _('Could not load image'),
	500007: _('Receipt delete error'),
	500008: _('Receipt update error'),
	500009: _('Error while updating receipt ACL'),
	500010: _('Not a valid image ID'),
	500011: _('Could not remove receipt image'),
	500012: _('Error while claiming receipt'),
	500013: _('Receipt has been updated since last sync'),
	500014: _('No items to export found'),
	500015: _('Error exporting items'),
	500016: _('Export expired'),
	500017: _('Receipt does not support declarations'),
	500018: _('Error while attaching document'),
	500019: _('Document not found'),
	500020: _('Invalid document ID'),
	500021: _('Error while removing document'),
	500022: _('Error while loading document'),
	500023: _('Error while accepting receipt share'),
	500024: _('Error while declining receipt share'),
	500025: _('Share already accepted'),
	500026: _('Receipt is already a declaration receipt'),
	500027: _('Category error, you can not use the category'),
	500028: _('Error when searching for user'),
	500029: _('Error while booking receipt'),
	500030: _('Receipt already booked'),
	500040: _('Receipt needs to be accepted to be booked'),
	500041: _('You need to add a booking to book this receipt'),
	500043: _('Relation field is required for booking invoices'),
	500045: _('Uploading of this image is not possible. Please check your file.'),
	500046: _('Uploading of this document is not possible. Please check your file.'),
	500049: _('The given emailadress is not connected to a Klippa account.'),
	500052: _('The exchange rate for this date cannot be loaded'),
	500057: _('The authorization flow details could not be loaded'),
	500058: _('The approval could not be submitted'),
	500059: _('The approval could not be submitted, you are not the next approver in the authorization flow'),
	500060: _('The authorization flow could not be applied'),
	500061: _('You can not approve your own expenses'),
	500062: _('You can not approve your own invoices'),
	500066: _('The item is already being booked.'),
	500067: _('You are not allowed to set a preset.'),
	500068: _('Transaction is not in auth flow'),
	500069: _('You can only prepend when approver order is required'),
	500070: _('The user you selected already approved this transaction'),
	500071: _('The user you selected does not have the proper role to approve this transaction'),
	500076: _('Invoice cannot be part of report'),
	500077: _('Could not split receipt, receipt does not have more than one attachment'),
	500082: _('Please use currency exchange to make sure the expense currency matches the currency of the report'),
	600001: _('Could not load folders'),
	600002: _('Could not create folder'),
	600003: _('No access to folder or folder not found'),
	600004: _('Folder delete error'),
	600005: _('Folder update error'),
	600006: _('Could not create folder, folder with that name already exists'),
	600007: _('Error while checking folder'),
	600008: _('Error while updating folder ACL'),
	600009: _('Folder has been updated since last sync'),
	600010: _('Error while accepting folder share'),
	600011: _('Error while declining folder share'),
	600012: _('Folder share already accepted'),
	600013: _('Error while declaring folder'),
	600015: _('The given emailadress is not connected to a Klippa account.'),
	700001: _('Could not access merchant'),
	700002: _('Error while fetching merchant'),
	700003: _('Error while fetching logo for merchant'),
	700004: _('Error while attaching logo to merchant'),
	700005: _('Error while creating merchant'),
	700006: _('Error while deleting logo for merchant'),
	700007: _('Error while deleting merchant'),
	700008: _('Error while updating merchant'),
	800001: _('Error fetching stats'),
	800002: _('Error filtering stats for user'),
	900001: _('Error fetching audit log'),
	110001: _('Error while accessing company'),
	110002: _('Error while fetching company'),
	110003: _('Error while creating company'),
	110004: _('Error while updating company'),
	110005: _('Error while deleting company'),
	110006: _('Error while importing users to company'),
	110007: _('Wrong file extension'),
	110008: _('Error accessing company user'),
	110009: _('Error updating company user'),
	110010: _('Error creating company user'),
	110011: _('OAuth error, unknown provider'),
	110012: _('OAuth error, connection filed'),
	110013: _('OAuth error, already connected to provider'),
	110014: _('OAuth error, already disconnected from provider'),
	110015: _('OAuth error, disconnecting failed'),
	110016: _('Error while deleting user.'),
	110017: _(
		"You can not delete this user: the user has transaction data. You can deactivate the user instead by changing its status to 'not active'.",
	),
	110018: _('Unsupported OAuth call.'),
	110019: _('Error on OAuth call.'),
	110020: _('Not connected to OAuth provider.'),
	110021: _('GLAccount not found.'),
	110022: _('Division not found.'),
	110023: _('Journal not found'),
	110024: _('VAT code not found'),
	110025: _('Error in webhook'),
	110033: _('Your trial has ended, please contact info@klippa.com.'),
	110036: null,
	110041: _('Error while connecting to integration'),
	110046: _('Integration error'),
	110048: _('Email address taken.'),
	110049: _('Company name taken.'),
	110050: _('Email not taken'),
	110051: _('Error while inviting'),
	110052: _('User is already connected to another company'),
	110089: _('Could not set company icon, the image must png or jpeg, square and at least 100x100'),
	110108: _(
		'You can not delete this user: user is connected to an authorization flow. Please change the authorization flow first and assign a new approver. Afterwards you can delete the user.',
	),
	120001: _('Error while fetching inbox'),
	120002: _('Inbox already marked as read'),
	120003: _('Inbox not read yet'),
	120004: _('Inbox access error'),
	120005: _('Could not mark Inbox as read'),
	120006: _('Could not mark Inbox as unread'),
	1300001: _('Claim expired'),
	1300003: _('No reviews found'),
	1300011: _('Receipt deleted'),
	1500012: _('Could not export items'),
	1600007: _('New email address is the same as the old email address'),
	1600008: _('New email address is already taken by another account'),
	1600010: _('Wrong password'),
	1600021: _('Auth method client credentials require a company and user'),
	1700003: _('Error while creating authorization flow'),
	1700004: _('Error while updating authorization flow'),
	1700005: _('Error while deleting authorization flow'),
	1800035: _('No items to export were provided'),
	1800040: _('There is no connection with the Klippa SpendControl API'),
	1900301: _('You have to pick a country'),
	1900101: _('You do not have permission to view this report'),
	1900102: _('Cannot create report'),
	1900103: _('Could not update report'),
	1900104: _('Somebody changed the report in the meantime. Please try again.'),
	1900105: _('You do not have access to this cost unit'),
	1900106: _('You do not have access to this project'),
	1900107: _('You do not have access to this cost center'),
	1900108: _('Authorization flow error'),
	1900109: _('Could not apply authorization flow'),
	1900110: _('Report not in an authorization flow'),
	1900111: _('User can not authorize report'),
	1900112: _('User has already approved this report'),
	1900113: _('It is not your turn to approve this report'),
	1900114: _('Can only prepend when approval order is required'),
	1900115: _('Report does not exist'),
	1900116: _('Please use currency exchange to make sure the expense currency matches the currency of the administration'),
	1900117: _('Dimension is required'),
	1900118: _('Administration is required'),
	1900119: _('Cannot add receipt to a report which is already submitted'),
	1900120: _('Report is already booked'), // being booked
	1900121: _('Report is already booked'), // has been booked
	1900122: _('This report has not been submitted yet'),
	1900123: _('This report is already submitted'),
	1900124: _('Something went wrong while booking report'),
	1900125: _('Report could not be retracted'),
	1900126: _('Report could not be exported'),
	1900127: _('Could not get administration for report'),
	1900128: _('No reports found'),
	1900129: _('Validate booking lines before booking report'),
	1900130: _('The selected administration has multiple currencies selected. This is not allowed when creating a report'),
	1901101: _('Could not create saved receipt'),
	1901102: _('Could not get list of saved receipts'),
};

export default apiErrorCodes;

export const errorsAsInfo = [1300003];
export const errorsAsWarning = [500045, 500046];
