import { OnDestroy, Pipe, PipeTransform } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '~/app/reducers';
import { User } from '#/models/user/user.model';
import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';

@Pipe({
	name: 'distance',
})
export class DistanceAmountPipe implements PipeTransform, OnDestroy {
	private user: User;
	private userStoreSubscription: Subscription;
	private miles = false;

	constructor(private store: Store<AppState>, private translate: TranslateService) {
		this.userStoreSubscription = this.store.select('user').subscribe((val) => {
			this.user = val.currentUser;
			if (this.user && this.user.preferences.show_miles_instead_of_km) {
				this.miles = true;
			}
		});
	}

	ngOnDestroy(): void {
		if (this.userStoreSubscription) {
			this.userStoreSubscription.unsubscribe();
		}
	}

	transform(value: number, label: boolean = false): string {
		let output = '';
		if (!value) {
			value = 0.0;
		}

		if (this.miles) {
			value = value * 0.621371192;
		}

		output = value.toFixed(2);

		if (label) {
			return output + ' ' + (this.miles ? _('mi.') : _('km'));
		}
	}
}
