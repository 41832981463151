<klp-form-select
	[placeholder]="'View as other user role' | translate"
	[options]="selectOptions"
	[multiple]="true"
	[multipleDisplayedAsAmount]="true"
	[clearable]="true"
	[(ngModel)]="selectedOption"
	(ngModelChange)="change($event)"
>
</klp-form-select>
