<klp-form-select
	id="authorizationFlows"
	name="authorizationFlows"
	[placeholder]="getPlaceholderTextFn()"
	[options]="authorizationFlowsOptions"
	[disabled]="disabled"
	[(ngModel)]="innerValue"
	(ngModelChange)="setInnerValueAndNotify($event)"
	[ngClass]="{ showErrors: isInErrorState() }"
	[multiple]="multiple"
	[clearable]="clearable"
>
</klp-form-select>
