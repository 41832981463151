import { Component, OnInit } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { ValueAccessorBase } from '@klippa/ngx-enhancy-forms';

@Component({
	selector: 'app-form-search-input',
	templateUrl: './search-input.component.html',
	styleUrls: ['./search-input.component.scss'],
	providers: [{ provide: NG_VALUE_ACCESSOR, useExisting: SearchInputComponent, multi: true }],
})
export class SearchInputComponent extends ValueAccessorBase<string> implements OnInit {}
