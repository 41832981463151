import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Company } from '#/models/company/company.model';
import { ModalService } from '~/app/services/modal.service';
import { Store } from '@ngrx/store';
import { AppState } from '~/app/reducers';
import { cloneDeep } from 'lodash';
import { TranslateService } from '@ngx-translate/core';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { DataTableDirective } from 'angular-datatables';
import { Subject, Subscription } from 'rxjs';
import { ColumnsInterface, DataTablesParameters } from '~/app/shared/ui/table/table';
import { ActionsMenuActionEnum, ActionsMenuInterface } from '~/app/shared/ui/actions-menu/actions-menu';
import { ExpenseReport } from '#/models/transaction/expense-reports';
import { Receipt } from '#/models/transaction/receipt';
import { Order } from '#/models/utils/order';
import { DashboardColumnSortingService } from '~/app/services/dashboard-column-sorting.service';
import { stringIsSetAndFilled } from '#/util/values';
import { ActivatedRoute, Router } from '@angular/router';
import { PageStateService } from '#/services/util/page-state.service';
import { SelectAction } from '#/models/utils/tables';

@Component({
	selector: 'app-table',
	templateUrl: './table-wrapper.component.html',
	styleUrls: ['./table-wrapper.component.scss'],
})
export class TableWrapperComponent implements OnInit, AfterViewInit {
	constructor(
		private store: Store<AppState>,
		private translate: TranslateService,
		protected modalService: ModalService,
		private dashboardColumnSortingService: DashboardColumnSortingService,
		private router: Router,
		private activatedRoute: ActivatedRoute,
		private pageStateService: PageStateService,
	) {}

	dtOptions: object = {};

	@Input() public rows: Receipt[] | ExpenseReport[];
	@Input() public totalRow: object;
	@Input() public totalColumns: Array<ColumnsInterface>;
	@Input() public total: number;
	@Input() public columns: Array<ColumnsInterface>;
	public fixedPreColumns: Array<ColumnsInterface> = []; // Columns displayed before sortable columns.
	public sortableColumns: Array<ColumnsInterface> = [];
	public fixedEndColumns: Array<ColumnsInterface> = []; // Columns displayed after sortable columns.
	@Input() public rowActions: (row) => ActionsMenuInterface[];
	protected companySubscription: Subscription;
	@Input() public itemsName = 'entries';
	@Input() public customizedEmptyTableMessage: string;
	@Input() public itemName = 'entry';
	@Input() public typeName = 'entry';
	@Input() public start: number;
	@Input() public max: number;
	@Input() public hasClickableRows = false;
	@Input() public sortingParameters: { sort: string; sortorder: Order };
	@Input() public selectAllItemFn: (selectAction: SelectAction) => Promise<Array<string>> = null;
	@Output() navigateTable: EventEmitter<DataTablesParameters> = new EventEmitter();
	@Output() sortTable: EventEmitter<{ sortedColumns; sortedTotalColumns }> = new EventEmitter();
	@Output() openSelectActions: EventEmitter<any> = new EventEmitter();
	@Output() clickRow: EventEmitter<any> = new EventEmitter();
	@Output() onRowAction: EventEmitter<{ action: ActionsMenuActionEnum; id: string }> = new EventEmitter();
	@Output() onSortingArrowClicked: EventEmitter<{ sortingProperty: string; sortingOrder: Order }> = new EventEmitter();

	@ViewChild(DataTableDirective, { static: false })
	dtElement: DataTableDirective;
	dtTrigger: Subject<any> = new Subject();

	public currentPage: number = 1;
	public showTableConfig = false;
	public company: Company;
	public rowSelected = false;
	public selectedOnAllPages: SelectAction = 'selectAll';
	protected destroyCallbacks = [];

	public selected = {
		all: false,
		rows: [],
	};

	public ngOnInit(): void {
		this.setTableData();
	}

	public ngAfterViewInit(): void {
		// Prevents datatables from breaking, when re-rendering the table with the same ID.
		this.dtTrigger.next(null);
	}

	private setTableColumns(): void {
		// Copy columns to change sort them without affecting table until order is saved.
		const columns = cloneDeep(this.columns);
		// Adding originIndex, used in sortColumns() to reorder the totalColumns after sorting.
		columns.forEach((column: ColumnsInterface, i) => {
			column.originIndex = i;
			if (column?.position === 'pre') {
				return this.fixedPreColumns.push(column);
			}
			if (column?.position === 'end') {
				return this.fixedEndColumns.push(column);
			}
			this.sortableColumns.push(column);
		});
	}

	private setTableData(): void {
		this.setTableColumns();
		const itemsName = this.getTranslation(this.itemsName);

		const pageNr = Number(this.activatedRoute.snapshot.queryParams?.page) || 1;
		this.currentPage = pageNr;
		const newStart = (pageNr - 1) * this.max;
		const itemsPerPage = Number(this.activatedRoute.snapshot.queryParams?.itemsPerPage) || this.max;
		if (newStart !== this.start || itemsPerPage !== this.max) {
			this.max = itemsPerPage;
			setTimeout(() => {
				this.navigateTable.emit({
					length: this.max,
					start: newStart,
				});
			});
		}

		this.dtOptions = {
			// deferLoading, displayStart and serverSide settings are required to show paging for records that have not been loaded yet.
			deferLoading: this.total,
			displayStart: this.start,
			pageLength: this.max,
			serverSide: true,
			// Ajax is used as callback for navigation events such as next, first page and change of pageLength.
			ajax: (dataTablesParameters: any, callback) => {
				const newPageNr = dataTablesParameters.start / dataTablesParameters.length + 1;
				this.currentPage = newPageNr;
				this.router.navigate([], {
					relativeTo: this.activatedRoute,
					queryParams: { page: newPageNr, itemsPerPage: dataTablesParameters.length },
					queryParamsHandling: 'merge',
				});
				this.navigateTable.emit(dataTablesParameters);
			},
			pagingType: 'full_numbers',
			lengthMenu: [10, 25, 50, 100],
			processing: true,
			searching: false,
			// https://datatables.net/reference/option/language
			language: {
				processing: '',
				lengthMenu: this.translate.instant(_('Display')) + '_MENU_' + itemsName,
				loadingRecords: '',
				zeroRecords: stringIsSetAndFilled(this.customizedEmptyTableMessage)
					? this.translate.instant(this.customizedEmptyTableMessage)
					: this.translate.instant(_('No %itemsName%'), { itemsName }),
				emptyTable: this.translate.instant(_('No %itemsName%'), { itemsName }),
				sInfo: this.translate.instant(_('Showing _START_ to _END_ of _TOTAL_')) + ' ' + itemsName,
				sInfoEmpty: '',
				paginate: {
					first: this.translate.instant(_('First')),
					previous: this.translate.instant(_('Previous')),
					next: this.translate.instant(_('Next')),
					last: this.translate.instant(_('Last')),
				},
			},
		};

		this.companySubscription = this.store.select('company').subscribe((val) => {
			this.company = val.company;
		});
		this.selected.rows = this.pageStateService.getSelectedIds();
	}

	public toggleAllRowsSelection() {
		if (this.selected.all) {
			this.rowSelected = false;
			this.selected.rows = this.rows.map((r) => r.id);
			this.rowSelected = true;
		} else {
			this.selected.rows = [];
			this.rowSelected = false;
		}
		this.pageStateService.setSelectedIds(this.selected.rows);
		this.openSelectActions.emit(this.selected);
	}

	public clickRowHandler(id: string) {
		this.clickRow.emit(id);
	}

	public rowActionHandler(rowActionObj: { action: ActionsMenuActionEnum; id: string }) {
		this.onRowAction.emit(rowActionObj);
	}

	public toggleRow(id: string) {
		this.selected.all = false;
		if (this.selected.rows.indexOf(id) > -1) {
			this.selected.rows.splice(this.selected.rows.indexOf(id), 1);
		} else {
			this.selected.rows.push(id);
		}
		this.pageStateService.setSelectedIds(this.selected.rows);
		this.openSelectActions.emit(this.selected);
	}

	openColumnConfig() {
		this.showTableConfig = true;
	}

	closeColumnConfig() {
		this.showTableConfig = false;
	}

	getTranslation(name) {
		return this.translate.instant(_(name));
	}

	get filterEnabledColumns(): Array<ColumnsInterface> {
		return [
			...this.fixedPreColumns.filter((x) => x.enabled),
			...this.sortableColumns.filter((x) => x.enabled),
			...this.fixedEndColumns.filter((x) => x.enabled),
		];
	}

	sortColumns(sortedColumns: []) {
		this.closeColumnConfig();
		const sortedColumnsResult = [...this.fixedPreColumns, ...sortedColumns, ...this.fixedEndColumns];
		this.sortTable.emit({
			// fixed columns are included because they also need to be set in the new order.
			sortedColumns: sortedColumnsResult,
			sortedTotalColumns: this.dashboardColumnSortingService.getBottomRowColumns(sortedColumnsResult),
		});
	}

	public toggleSorting(sortingProperty: string): void {
		// only swap the order when the property is the same
		if (sortingProperty === this.sortingParameters.sort) {
			if (this.sortingParameters.sortorder === Order.DESCENDING) {
				this.sortingParameters.sortorder = Order.ASCENDING;
			} else if (this.sortingParameters.sortorder === Order.ASCENDING) {
				this.sortingParameters.sortorder = Order.DESCENDING;
			}
		}
		this.onSortingArrowClicked.emit({
			sortingProperty: sortingProperty,
			sortingOrder: this.sortingParameters.sortorder,
		});
	}

	ngOnDestroy() {
		this.companySubscription.unsubscribe();
	}

	public async toggleSelectedItemsOnAllPages(): Promise<void> {
		if (this.selectedOnAllPages === 'deselectAll') {
			this.selected.all = false;
			this.toggleAllRowsSelection();
		}
		const selected = await this.selectAllItemFn(this.selectedOnAllPages);
		this.selected.rows = selected;
		this.pageStateService.setSelectedIds(selected);
		this.selectedOnAllPages = this.selectedOnAllPages === 'selectAll' ? 'deselectAll' : 'selectAll';
	}

	isRowSelected(id: string): boolean {
		return this.selected.rows.includes(id);
	}

	pageChanged(newPageNr: number) {
		this.currentPage = newPageNr;
		this.router.navigate([], {
			relativeTo: this.activatedRoute,
			queryParams: { page: newPageNr, itemsPerPage: this.max },
			queryParamsHandling: 'merge',
		});
		this.navigateTable.emit({
			length: this.max,
			start: (newPageNr - 1) * this.max,
		});
	}

	getMinimum(max: number, total: number) {
		return Math.min(max, total);
	}
}
