import { Pipe, PipeTransform } from '@angular/core';
import { AmountWithCurrency } from '#/models/transaction/amountWithCurrency';

@Pipe({
	name: 'convertToAmountWithCurrencyType',
})
export class ConvertToAmountWithCurrencyTypePipe implements PipeTransform {
	transform(amountAndCurrencyArray: [amount: number, currency: string]): AmountWithCurrency {
		return {
			amount: amountAndCurrencyArray[0] ?? null,
			currency: amountAndCurrencyArray[1],
		};
	}
}
