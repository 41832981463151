import { routerReducer } from '@ngrx/router-store';
import { merchantReducer, MerchantState } from '../modules/merchant/models/merchant.reducer';
import { userReducer, UserState } from '../modules/user/models/user.reducer';
import { companyReducer, CompanyState } from '#/models/company/company.reducer';
import { iconReducer, IconState } from '../modules/icon/models/icon.reducer';
import { folderReducer, FolderState } from '../modules/folder/models/folder.reducer';
import { genericReducer, GenericState } from '../modules/generic/generic.reducer';
import { receiptReducer, ReceiptState } from '../modules/receipt/models/receipt.reducer';
import { sidePanelReducer, SidePanelState } from '../modules/side-panel/side-panel.reducer';
import { tagsReducer, TagState } from '../modules/tag/models/tag.reducer';

export interface AppState {
	router: any;
	merchant: MerchantState;
	user: UserState;
	company: CompanyState;
	icon: IconState;
	group: FolderState;
	generic: GenericState;
	receipt: ReceiptState;
	sidePanel: SidePanelState;
	tags: TagState;
}

export const reducers = {
	router: routerReducer,
	merchant: merchantReducer,
	user: userReducer,
	company: companyReducer,
	icon: iconReducer,
	group: folderReducer,
	generic: genericReducer,
	receipt: receiptReducer,
	sidePanel: sidePanelReducer,
	tags: tagsReducer,
};
