import { TransactionFrontendModel } from '#/models/transaction/transaction/frontendModel';
import { FixedCompensationApiModel } from '#/models/transaction/fixedCompensation/apiModel';
import { frontendToApi } from './transformer';
import { DatesPerAppliedCompensationRule } from '#/services/transaction/compensation-rules.service';

export class FixedCompensationFrontendModel extends TransactionFrontendModel {
	datesPerAppliedCompensationRule: DatesPerAppliedCompensationRule;
	amountOfMinutes: number;

	constructor(data?: any) {
		super(data);
		this.amountOfMinutes = data?.amountOfMinutes;
		this.datesPerAppliedCompensationRule = data?.datesPerAppliedCompensationRule;
	}

	transformToApi(): FixedCompensationApiModel {
		return frontendToApi(this);
	}
}
