import { Component, Host, Input, Optional } from '@angular/core';
import { AppSelectOption, FormElementComponent } from '@klippa/ngx-enhancy-forms';
import { ControlContainer, NG_VALUE_ACCESSOR } from '@angular/forms';
import { User, UserRole } from '#/models/user/user.model';
import { CompanyUsersFilterAPIRequest } from '#/models/company/companyUsersApiRequestFilter';
import { arrayIsSetAndFilled } from '#/util/arrays';
import { UserService } from '~/app/modules/user/user.service';
import { DynamicOptionsValueAccessorBase } from '~/app/shared/ui/forms/composed/pickers/dynamic/dynamic-options-picker/dynamic-options-value-accessor-base';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { CompanyApiService } from '#/services/company/company-api.service';
import { CompanyService } from '#/services/company/company.service';
import { UserType } from '#/services/user/partial-users.service';
import { ItemsWithHasMoreResultsPromise } from '#/models/appSelectOption.model';

@Component({
	selector: 'app-form-user-picker',
	templateUrl: './dynamic-options-picker/dynamic-options-picker.template.html',
	styleUrls: ['./dynamic-options-picker/dynamic-options-picker.template.scss'],
	providers: [{ provide: NG_VALUE_ACCESSOR, useExisting: FormCompanyUserPickerComponent, multi: true }],
})
export class FormCompanyUserPickerComponent extends DynamicOptionsValueAccessorBase<string, User> {
	@Input() filterOnUserRoles: Array<UserRole> = [];
	@Input() hideLoggedInUser: boolean = false;
	@Input() clearable: boolean = true;
	@Input() showEmail: boolean = false;
	@Input() onlyRealUsers: boolean = true;
	@Input() showOnlyActiveUsers: boolean = false;

	constructor(
		@Optional() @Host() protected parent: FormElementComponent,
		@Optional() @Host() protected controlContainer: ControlContainer,
		private companyApiService: CompanyApiService,
		private companyService: CompanyService,
		private userService: UserService,
	) {
		super(parent, controlContainer);
		this.defaultPlaceHolder = _('Select user');
	}

	fetchItemsFn = (start: number, searchQuery: string): ItemsWithHasMoreResultsPromise<User> => {
		const companyId = this.companyService.getCompanyOfLoggedUser().id;
		const filters = new CompanyUsersFilterAPIRequest();
		filters.start = start;
		filters.search = searchQuery;
		filters.userType = this.onlyRealUsers ? UserType.Real : undefined;
		filters.role = arrayIsSetAndFilled(this.filterOnUserRoles) ? this.filterOnUserRoles : null;

		if (this.showOnlyActiveUsers) {
			// we either want to get all users or only active users
			filters.active = true;
		}

		return this.companyApiService
			.getCompanyUsers(companyId, filters)
			.then((res) => {
				let items = res.users;
				if (this.hideLoggedInUser) {
					items = items.filter((e) => e.id !== this.userService.getCurrentLoggedUser().id);
				}
				return {
					hasMoreResults: res.moreresults,
					items,
				};
			})
			.catch(() => {
				return {
					hasMoreResults: false,
					items: [],
				};
			});
	};

	fetchSelectedItemsFn = (ids: Array<string>): Promise<Array<User>> => {
		const companyId = this.companyService.getCompanyOfLoggedUser().id;
		const filters = new CompanyUsersFilterAPIRequest();
		return this.companyApiService.getCompanyUsersByIds(companyId, ids, filters).then((res) => res.users);
	};

	mapToSelectOptionFn = (e: User): AppSelectOption => {
		return {
			id: e.id,
			name: e.name,
			description: this.showEmail ? e.email : null,
		};
	};
}
