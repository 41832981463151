import { Routes } from '@angular/router';

export const SettingsRoutes: Routes = [
	{
		path: '',
		redirectTo: '/settings/general',
		pathMatch: 'full',
	},
	{
		path: '',
		data: { browseChildren: true },
		loadChildren: () => import('./settings-routing.module').then((m) => m.SettingsSettings),
	},
];
