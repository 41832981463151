import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FlatTreeNode } from '~/app/shared/ui/custom-xml-export/interfaces/treeNode';
import { conditionOptions } from '~/app/shared/ui/forms/composed/pickers/static/xml-condition-picker';
import { isValueSet } from '#/util/values';
import { arrayIsSetAndFilled } from '#/util/arrays';
import { TranslateService } from '@ngx-translate/core';
import { XmlConditionType } from '#/models/company/exporterInterfaces';

@Component({
	selector: 'app-tree-node',
	templateUrl: './tree-node.component.html',
	styleUrls: ['./tree-node.component.scss'],
})
export class TreeNodeComponent {
	@Input() node: FlatTreeNode;
	@Input() isNode: boolean;
	@Input() isExpanded: boolean;
	@Input() showNodeSettingsButtons: boolean = true;
	@Input() disabled: boolean = false;

	@Output() onToggleNode: EventEmitter<FlatTreeNode> = new EventEmitter<FlatTreeNode>();
	@Output() onOpenNodeSettingsModal: EventEmitter<FlatTreeNode> = new EventEmitter<FlatTreeNode>();

	constructor(private translate: TranslateService) {}

	toggleNode() {
		this.onToggleNode.emit(this.node);
	}

	openNodeSettingsModal() {
		this.onOpenNodeSettingsModal.emit(this.node);
	}

	nodeHasSettings(): boolean {
		const settings = this.node.settings;
		return (
			isValueSet(settings?.custom_label) ||
			isValueSet(settings?.value) ||
			isValueSet(settings?.dateFormat) ||
			arrayIsSetAndFilled(settings?.context) ||
			arrayIsSetAndFilled(settings?.attributes) ||
			isValueSet(settings?.condition)
		);
	}

	public getContextNameArray(): Array<string> {
		return this.node.settings.context?.map((c) => c?.name).filter(isValueSet) ?? [];
	}

	public getConditionText(xmlConditionType: XmlConditionType): string {
		const name = conditionOptions.find((option) => option.id === xmlConditionType)?.name;
		return this.translate.instant(name);
	}
}
