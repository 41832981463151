<ng-select
	id="activeBulk"
	name="active"
	placeholder="{{ 'Keep current status' | translate }} "
	[items]="activeItems"
	[(ngModel)]="active"
	[multiple]="false"
	bindLabel="Label"
	bindValue="id"
	[clearable]="true"
></ng-select>
