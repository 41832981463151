import { roundToX } from '#/util/numbers';

export class CompanyUnitBase {
	id: string;
	code: string;
	name: string;
	createdate: Date;
	updatedate: Date;
	groups: string[];
	active: boolean;
	description: string;
	issynced: boolean;
	remoteid: string;
	integration_identifier: string;
	provider: string;
	division: string;
	module_leveled_travel_expense_compensation_per_km?: number;
	from_date?: Date;
	to_date?: Date;
	administration: string;

	constructor(data) {
		if (data) {
			Object.assign(this, data);
			if (data.createdate) {
				this.createdate = Date.parse(data.createdate) > 0 ? new Date(data.createdate) : null;
			}
			if (data.updatedate) {
				this.updatedate = Date.parse(data.updatedate) > 0 ? new Date(data.updatedate) : null;
			}
			if (data.from_date) {
				this.from_date = Date.parse(data.from_date) > 0 ? new Date(data.from_date) : null;
			}
			if (data.to_date) {
				this.to_date = Date.parse(data.to_date) > 0 ? new Date(data.to_date) : null;
			}
		}
	}

	getID() {
		return this.id;
	}

	getRemoteID() {
		return this.remoteid;
	}

	getIntegrationIdentifier() {
		return this.integration_identifier;
	}

	getProvider() {
		return this.provider;
	}

	getDivision() {
		return this.division;
	}

	isSynced() {
		return this.issynced;
	}

	getCode() {
		return this.code;
	}

	getName() {
		return this.name;
	}

	getGroups() {
		if (this.groups) {
			return this.groups;
		}

		return [];
	}

	isActive() {
		return this.active;
	}

	getDescription() {
		return this.description;
	}

	get Label() {
		return this.getTitle(true);
	}

	get Name() {
		return this.name;
	}

	get Code() {
		return this.code;
	}

	get Title() {
		return (this.code && this.code + ' - ') + this.name;
	}

	getTitle(showCode = true) {
		if (showCode) {
			return (this.code && this.code + ' - ') + this.name;
		}
		return this.name;
	}

	matches(string: string): boolean {
		/** Returns whether this unit matches a given search string. */
		return (
			this.getName().toLowerCase().includes(string.toLowerCase()) ||
			this.getCode().toLowerCase().includes(string.toLowerCase()) ||
			this.getDescription().toLowerCase().includes(string.toLowerCase())
		);
	}

	get moduleLeveledTravelExpenseCompensationPerKM(): string {
		if (
			typeof this.module_leveled_travel_expense_compensation_per_km !== 'undefined' &&
			this.module_leveled_travel_expense_compensation_per_km !== null
		) {
			return roundToX(this.module_leveled_travel_expense_compensation_per_km, 2).toString(10);
		}

		return null;
	}

	set moduleLeveledTravelExpenseCompensationPerKM(cents: string) {
		if (cents && cents !== '') {
			cents = cents.replace(/,/g, '.');
			const value = parseFloat(cents);
			this.module_leveled_travel_expense_compensation_per_km = isNaN(value) ? 0 : value;
		} else {
			this.module_leveled_travel_expense_compensation_per_km = null;
		}
	}

	get moduleLeveledTravelExpenseCompensationPerMile(): string {
		if (
			typeof this.module_leveled_travel_expense_compensation_per_km !== 'undefined' &&
			this.module_leveled_travel_expense_compensation_per_km !== null
		) {
			return roundToX(this.module_leveled_travel_expense_compensation_per_km / 0.621371192, 2).toString(10);
		}

		return null;
	}

	set moduleLeveledTravelExpenseCompensationPerMile(cents: string) {
		if (cents && cents !== '') {
			cents = cents.replace(/,/g, '.');
			const value = parseFloat(cents) * 0.621371192;
			this.module_leveled_travel_expense_compensation_per_km = isNaN(value) ? 0 : value;
		} else {
			this.module_leveled_travel_expense_compensation_per_km = null;
		}
	}

	getValueForField(field: string): string {
		if (field === 'ID') {
			return this.id;
		} else if (field === 'Code') {
			return this.code;
		} else if (field === 'Description') {
			return this.description;
		} else if (field === 'Name') {
			return this.name;
		}

		return null;
	}
}
