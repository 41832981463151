import { Injectable } from '@angular/core';
import { CompanyService } from '#/services/company/company.service';
import { isNullOrUndefined, stringIsSetAndFilled } from '#/util/values';
import { UserService } from '~/app/modules/user/user.service';
import { Company } from '#/models/company/company.model';
import { User } from '#/models/user/user.model';
import { CompanyApiService } from '#/services/company/company-api.service';

@Injectable({
	providedIn: 'root',
})
export class AsyncInfoDependantGuard {
	constructor(
		protected userService: UserService,
		protected companyService: CompanyService,
		protected companyApiService: CompanyApiService,
	) {}

	protected async getUser(): Promise<User> {
		let user = this.userService.getCurrentLoggedUser();
		if (isNullOrUndefined(user)) {
			user = await this.userService.getCurrentUserParsed();
		}
		return user;
	}

	protected async getCompany(): Promise<Company> {
		let user = this.userService.getCurrentLoggedUser();
		let company = this.companyService.getCompanyOfLoggedUser();
		if (isNullOrUndefined(company)) {
			if (isNullOrUndefined(user)) {
				user = await this.userService.getCurrentUserParsed();
			}
			if (stringIsSetAndFilled(user.company)) {
				company = await this.companyApiService.getCompany(user.company);
			}
		}
		return company;
	}

	protected async getParentCompany(user: User): Promise<Company> {
		const company = await this.getCompany();
		if (!stringIsSetAndFilled(company?.parentcompany)) {
			return null;
		}
		if (user.company === company.parentcompany) {
			return await this.companyApiService.getCompany(company.parentcompany);
		}
		return null;
	}
}
