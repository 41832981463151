import { UserService } from '~/app/modules/user/user.service';
import { Injectable } from '@angular/core';
import { User } from '#/models/user/user.model';
import { isNullOrUndefined, stringIsSetAndFilled } from '#/util/values';

@Injectable({
	providedIn: 'root',
})
export class UserAccessService {
	constructor(private userService: UserService) {}

	public async canSeeCompanySettings(): Promise<boolean> {
		const user = await this.getUser();

		if (!stringIsSetAndFilled(user.company)) {
			return false;
		}

		if (user?.hasCompanyAdminRole()) {
			return true;
		}

		if (user?.hasSuperAdminRole()) {
			return true;
		}

		if (user?.hasReadWriteAdminRole()) {
			return true;
		}

		if (user?.hasReadAdminRole()) {
			return true;
		}

		if (user?.hasSupportRole()) {
			const realUserCompanyId: string = await this.getRealUserCompanyId();
			return user.company !== realUserCompanyId;
		}

		return false;
	}

	public async canSeeAdminSettings(): Promise<boolean> {
		const user = await this.getUser();

		if (user?.hasSuperAdminRole()) {
			return true;
		}

		if (user?.hasReadWriteAdminRole()) {
			return true;
		}

		if (user?.hasReadAdminRole()) {
			return true;
		}

		if (user?.hasSupportRole()) {
			return true;
		}

		return false;
	}

	public async canSeeAdminSettingsDashboard(): Promise<boolean> {
		const user = await this.getUser();

		if (user?.hasSuperAdminRole()) {
			return true;
		}

		if (user?.hasReadWriteAdminRole()) {
			return true;
		}

		if (user?.hasReadAdminRole()) {
			return true;
		}

		if (user?.hasSupportRole()) {
			const realUserCompanyId: string = await this.getRealUserCompanyId();
			return user.company !== realUserCompanyId;
		}

		return false;
	}

	private async getRealUserCompanyId(): Promise<string> {
		return (await this.userService.getUserFromApi()).company;
	}

	protected async getUser(): Promise<User> {
		let user = this.userService.getCurrentLoggedUser();
		if (isNullOrUndefined(user)) {
			user = await this.userService.getCurrentUserParsed();
		}
		return user;
	}
}
