<div class="modal modalRight fade show">
	<div class="modal-dialog" role="document">
		<div class="modal-content">
			<div class="side-modal-wrapper">
				<div class="table-config" *ngIf="user.canConfigureDashboardColumns()">
					<h4 translate>Configure your dashboard</h4>
					<p translate>Pick and order the columns in any way you want!</p>
					<klp-form-sortable-items [(ngModel)]="sortableColumns">
						<ng-template let-index="index">
							<div class="table-sort-option">
								<div class="text-bold"><i class="ti-menu mrg-right-10 text-gray"></i>{{ sortableColumns[index].name }}</div>
								<klp-form-toggle *ngIf="!sortableColumns[index].required" [disabled]="false" [(ngModel)]="sortableColumns[index].enabled">
								</klp-form-toggle>
								<klp-form-toggle *ngIf="sortableColumns[index].required" [disabled]="true" [ngModel]="true"> </klp-form-toggle>
							</div>
						</ng-template>
					</klp-form-sortable-items>
					<app-button variant="greenFilled" [fullWidth]="true" (click)="saveColumnOrder()" translate> Save </app-button>
				</div>
			</div>
		</div>
	</div>
</div>
