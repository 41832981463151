<ngx-async-template [promise]="xmlItemsPromise">
	<ng-template ngx-async-success>
		<div class="xmlContainer" cdkDropListGroup>
			<div class="card xmlCard">
				<div class="cardHeader">
					<h4 class="card-title" translate>XML Structure</h4>
					<app-button variant="contextMenuItem" (click)="toggleExpandStateStructureTree()">
						{{ isStructureTreeExpanded() ? ('Collapse Tree' | translate) : ('Expand Tree' | translate) }}
					</app-button>
				</div>
				<div class="card-block node-card-content">
					<app-tree-drag-drop
						#xmlStructureTree
						cdkScrollable
						class="scroll"
						[(ngModel)]="xmlStructure"
						(ngModelChange)="updateInnerValue(xmlStructure)"
						[allowDrop]="allowDrop"
						[expandOnLoadNodes]="true"
						[preventFolderFromBecomingLeaf]="true"
						[disableSorting]="disabled"
						[showNodeSettingsButtons]="!disabled"
						[disabled]="disabled"
					></app-tree-drag-drop>
				</div>
			</div>

			<div class="card xmlCard" *ngIf="!disabled">
				<div class="cardHeader">
					<h4 class="card-title" translate>XML Items</h4>
					<app-button variant="contextMenuItem" (click)="toggleExpandStateItemsTree()">
						{{ isItemsTreeExpanded() ? ('Collapse Tree' | translate) : ('Expand Tree' | translate) }}
					</app-button>
				</div>
				<div class="card-block node-card-content">
					<app-tree-drag-drop
						#xmlItemsTree
						cdkScrollable
						class="scroll"
						[allowDrop]="neverAllowDrop"
						[disableSorting]="true"
						[showNodeSettingsButtons]="false"
						[preventFolderFromBecomingLeaf]="true"
						(onDropFromConnectedTree)="deleteNodeFromXmlStructureTree($event)"
						[(ngModel)]="nodes"
					></app-tree-drag-drop>
				</div>
			</div>
		</div>
	</ng-template>
</ngx-async-template>
