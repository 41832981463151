import { Component, Input, OnInit } from '@angular/core';
import { ReceiptAuthorizationFlowStatus } from '#/models/transaction/receipt';
import { ExpenseReportsService } from '#/services/transaction/expense-reports.service';
import { ReceiptService } from '#/services/transaction/receipt.service';
import { stringIsSetAndFilled } from '#/util/values';

interface ApproverCountAndAuthFlowStatus {
	requireApproverCount: number;
	authorizationFlowStatus: ReceiptAuthorizationFlowStatus;
}

@Component({
	selector: 'app-receipt-current-approver',
	templateUrl: './current-approver.component.html',
	styleUrls: ['./current-approver.component.scss'],
})
export class CurrentApproverComponent implements OnInit {
	@Input() public receiptId: string;
	@Input() public reportId: string;
	public approverCountAndAuthFlowStatusPromise: Promise<ApproverCountAndAuthFlowStatus>;

	constructor(private expenseReportsService: ExpenseReportsService, private receiptAPIService: ReceiptService) {}

	ngOnInit(): void {
		if (stringIsSetAndFilled(this.receiptId)) {
			this.approverCountAndAuthFlowStatusPromise = Promise.all([
				this.receiptAPIService.getReceipt(this.receiptId),
				this.receiptAPIService.getReceiptAuthorizationFlowStatus(this.receiptId),
			]).then(([receipt, authorizationFlowStatus]) => {
				return {
					requireApproverCount: receipt.authorization_flow?.require_approver_count,
					authorizationFlowStatus,
				};
			});
		} else if (stringIsSetAndFilled(this.reportId)) {
			this.approverCountAndAuthFlowStatusPromise = Promise.all([
				this.expenseReportsService.getExpenseReport(this.reportId),
				this.expenseReportsService.getExpenseReportAuthorizationFlowStatus(this.reportId),
			]).then(([report, authorizationFlowStatus]) => {
				return {
					requireApproverCount: report.authorization_flow?.require_approver_count,
					authorizationFlowStatus,
				};
			});
		}
	}

	hasMultipleNextApproversInAnyOrder(authorizationFlowStatus: ReceiptAuthorizationFlowStatus) {
		return (
			authorizationFlowStatus.next_approvers.length > 0 &&
			authorizationFlowStatus.require_approver_count > 0 &&
			!authorizationFlowStatus.require_approver_order
		);
	}
}
