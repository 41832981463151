import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { GAccountCapability } from '#/models/company/company.model';

export interface ScopesGroup {
	name: string;
	scopes: FullScope[];
}

export interface FullScope {
	type: 'view' | 'manage';
	id: string;
	name: string;
	requires?: string[];
	hint: string;
}

export class OAuthAppAuthMethods {
	authorization_code = false;
	client_credentials = false;

	constructor(data?: { authorization_code?: boolean; client_credentials?: boolean }) {
		if (data) {
			Object.assign(this, data);
		}
	}
}

export class OAuthApp {
	id = '';
	name = '';
	description = '';
	icon = '';
	clientID = '';
	clientSecret = '';
	redirectURL = [];
	allowedScopes: string[] = [];
	disabled = false;
	allowAllScopes = false;
	authMethods: OAuthAppAuthMethods = new OAuthAppAuthMethods();
	company: string = null;
	user: string = null;

	constructor(data?: {
		id?: string;
		name?: string;
		description?: string;
		icon?: string;
		clientID?: string;
		clientSecret?: string;
		allowedScopes?: number;
		disabled?: boolean;
		allowAllScopes?: boolean;
		authMethods?: OAuthAppAuthMethods;
		company?: string;
		user?: string;
	}) {
		if (data) {
			Object.assign(this, data);

			if (data.authMethods) {
				data.authMethods = new OAuthAppAuthMethods(data.authMethods);
			}
		}
	}
}
