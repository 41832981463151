<div class="float-right table-header-action-row mrg-right-20">
	<div class="float-right mrg-right-15" *ngIf="getSelectedGroupsCount() > 0">
		<a
			class="btn btn-primary dropdown-toggle table-header-button px-3"
			href="#"
			role="button"
			id="table-actions-dropdown"
			data-toggle="dropdown"
			aria-haspopup="true"
			aria-expanded="false"
		>
			{{ 'Actions' | translate }} <i class="ti-angle-down font-size-11"></i>
		</a>

		<div class="dropdown-menu" aria-labelledby="table-actions-dropdown">
			<button class="dropdown-item" *ngIf="getSelectedGroupsCount() === 1" (click)="tableActionRename($event)" translate>Rename</button>
			<button class="dropdown-item" *ngIf="user.canSubmitExpenses()" (click)="tableActionSubmitExpenses($event)" translate>
				Submit expenses
			</button>
			<button class="dropdown-item" *ngIf="user.canSubmitExpenses()" (click)="tableActionRetractExpenses($event)" translate>
				Retract expenses
			</button>
			<button class="dropdown-item" (click)="tableActionShare($event)" translate>Share</button>
			<button class="dropdown-item" (click)="tableActionDownloadImages($event)" translate>Download images</button>
			<button class="dropdown-item" (click)="openExportModalBySelectedCheckboxes()" translate>Export</button>

			<div class="dropdown-divider"></div>
			<button class="dropdown-item" (click)="tableActionDelete($event)" translate>Delete</button>
		</div>
	</div>
</div>
<div class="table-responsive">
	<table class="table table-lg table-hover" *ngIf="folders.length > 0">
		<thead>
			<tr>
				<th class="table-end-left-padding">
					<div class="checkbox">
						<input
							[attr.id]="'group-checkbox-all'"
							[attr.name]="'group-checkbox-all'"
							type="checkbox"
							[(ngModel)]="checkAll.checked"
							(ngModelChange)="changedCheckAll($event)"
						/>
						<label [attr.for]="'group-checkbox-all'"></label>
					</div>
				</th>
				<th translate>Description</th>
				<th class="text-center text-nowrap">#</th>
				<th translate>Create date</th>
				<th translate>Update date</th>
				<th class="table-end-right-padding text-center"></th>
			</tr>
		</thead>
		<tbody>
			<tr
				role="row"
				app-folders-table-row
				class="cursor-pointer"
				*ngFor="let group of folders"
				[group]="group"
				[checkboxes]="checkboxes"
				[user]="user"
				(checkboxClick)="checkBoxClicked($event)"
				(click)="navigateToGroup($event, group)"
				(groupsChanged)="updateGroups()"
				(openExport)="openExportModal($event)"
			></tr>
		</tbody>
	</table>
</div>

<app-empty-view
	*ngIf="folders.length == 0"
	gif="2"
	[text]="'Your account does not contain any folders at the moment.' | translate"
	[subtitle]="'You can add folders by clicking the button in the top right corner.' | translate"
>
</app-empty-view>

<ng-template #exportModal>
	<app-export-form
		[exportIds]="exportIds"
		exportType="receipt"
		[companyExport]="false"
		[exportPromise]="exportPromise"
		(onDismiss)="closeExportModal()"
		(onExport)="processExport($event)"
		[headerMessage]="headerMessageRef"
	>
	</app-export-form>

	<ng-template #headerMessageRef>
		<header *ngIf="exportIds.length === 1" translate>You are going to export 1 receipt.</header>
		<header *ngIf="exportIds.length > 1" [translateParams]="{ count: exportIds.length }" translate>
			You are going to export %count% receipts.
		</header>
	</ng-template>
</ng-template>
