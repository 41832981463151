<ngx-async-template [promise]="optionsPromise">
	<ng-template ngx-async-pending>
		<klp-form-loading-indicator variant="textInput"></klp-form-loading-indicator>
	</ng-template>
	<ng-template ngx-async-success let-options="value">
		<klp-form-select
			*ngIf="options.length > 0"
			[options]="options"
			[ngClass]="{ showErrors: isInErrorState() }"
			[(ngModel)]="innerValue"
			(ngModelChange)="setInnerValueAndNotify($event)"
			[clearable]="clearable"
		>
		</klp-form-select>
		<p *ngIf="options.length === 0" translate>There are no templates available</p>
	</ng-template>
</ngx-async-template>
