import { NgModule, Injector } from '@angular/core';
import { FolderLabelComponent } from '~/app/modules/folder/components/folder-label/folder-label.component';
import { CommonModule } from '@angular/common';
import { FoldersModalBase } from './components/modals/folders-modal-base/folders-modal-base';
import { FoldersTableRowComponent } from './components/folders-table-row/folders-table-row.component';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FoldersTableComponent } from './components/folders-table/folders-table.component';
import { SortablejsModule } from 'ngx-sortablejs';
import { UiModule } from '../../shared/ui/ui.module';
import { NgSelectModule } from '@ng-select/ng-select';
import { PipesModule } from '~/app/helpers/pipes/pipes.module';
import { ExportModule } from '~/app/modules/export/export.module';

@NgModule({
	imports: [
		CommonModule,
		TranslateModule,
		FormsModule,
		ReactiveFormsModule,
		SortablejsModule,
		UiModule,
		NgSelectModule,
		PipesModule,
		ExportModule,
	],
	declarations: [FolderLabelComponent, FoldersModalBase, FoldersTableComponent, FoldersTableRowComponent],
	exports: [FolderLabelComponent, FoldersTableComponent, FoldersModalBase, FoldersTableRowComponent],
})
export class FolderModule {
	constructor(private injector: Injector) {
		FoldersModalBase.injector = this.injector;
	}
}
