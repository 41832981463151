export class AddressDeprecated {
	// new api call responses should have the non-deprecated fields format
	AddressLine1: string;
	AddressLine2: string;
	AddressLine3: string;
	ZipCode: string;
	State: string;
	City: string;
	Country: string;
	Addition: string;
	Number: string;
	HouseNumber: string;
	NumberFull: string;
	Street: string;

	constructor(data = null) {
		if (data) {
			Object.assign(this, data);
		}
	}
}

export class Address {
	addressLine1: string;
	addressLine2: string;
	addressLine3: string;
	zipCode: string;
	state: string;
	city: string;
	country: string;
	addition: string;
	number: string;
	houseNumber: string;
	numberFull: string;
	street: string;

	constructor(data = null) {
		if (data) {
			Object.assign(this, data);
		}
	}
}
