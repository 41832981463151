import { Action } from '@ngrx/store';
import { Update } from '@ngrx/entity';

import { Merchant } from '#/models/merchant';

export enum MerchantActionTypes {
	LOAD_MERCHANTS = '[Merchant] Load Merchants',
	ADD_MERCHANT = '[Merchant] Add Merchant',
	UPSERT_MERCHANT = '[Merchant] Upsert Merchant',
	ADD_MERCHANTS = '[Merchant] Add Merchants',
	UPSERT_MERCHANTS = '[Merchant] Upsert Merchants',
	UPDATE_MERCHANT = '[Merchant] Update Merchant',
	UPDATE_MERCHANTS = '[Merchant] Update Merchants',
	DELETE_MERCHANT = '[Merchant] Delete Merchant',
	DELETE_MERCHANTS = '[Merchant] Delete Merchants',
	CLEAR_MERCHANTS = '[Merchant] Clear Merchants',
}

export class LoadMerchants implements Action {
	readonly type = MerchantActionTypes.LOAD_MERCHANTS;

	constructor(public payload: { merchants: Merchant[] }) {}
}

export class AddMerchant implements Action {
	readonly type = MerchantActionTypes.ADD_MERCHANT;

	constructor(public payload: { merchant: Merchant }) {}
}

export class UpsertMerchant implements Action {
	readonly type = MerchantActionTypes.UPSERT_MERCHANT;

	constructor(public payload: { merchant: Merchant }) {}
}

export class AddMerchants implements Action {
	readonly type = MerchantActionTypes.ADD_MERCHANTS;

	constructor(public payload: { merchants: Merchant[] }) {}
}

export class UpsertMerchants implements Action {
	readonly type = MerchantActionTypes.UPSERT_MERCHANTS;

	constructor(public payload: { merchants: Merchant[] }) {}
}

export class UpdateMerchant implements Action {
	readonly type = MerchantActionTypes.UPDATE_MERCHANT;

	constructor(public payload: { merchant: Update<Merchant> }) {}
}

export class UpdateMerchants implements Action {
	readonly type = MerchantActionTypes.UPDATE_MERCHANTS;

	constructor(public payload: { merchants: Update<Merchant>[] }) {}
}

export class DeleteMerchant implements Action {
	readonly type = MerchantActionTypes.DELETE_MERCHANT;

	constructor(public payload: { id: string }) {}
}

export class DeleteMerchants implements Action {
	readonly type = MerchantActionTypes.DELETE_MERCHANTS;

	constructor(public payload: { ids: string[] }) {}
}

export class ClearMerchants implements Action {
	readonly type = MerchantActionTypes.CLEAR_MERCHANTS;
}

export type MerchantActionsUnion =
	| LoadMerchants
	| AddMerchant
	| UpsertMerchant
	| AddMerchants
	| UpsertMerchants
	| UpdateMerchant
	| UpdateMerchants
	| DeleteMerchant
	| DeleteMerchants
	| ClearMerchants;
