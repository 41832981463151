import { Component, OnInit } from '@angular/core';
import { ActivationStart, ChildrenOutletContexts, NavigationEnd, NavigationStart } from '@angular/router';
import { BaseComponent } from './base/base.component';
import { captureMessage } from '~/app/util/sentry';
import { isValueSet } from '#/util/values';
import { EscapeToNavigateToParentOption } from '~/app/pages/navigateBackToParentConditionally';
import { RouteUtilsService } from '#/services/util/route-utils.service';
import { VersionCheckService } from '~/app/services/version-check.service';
import { AnnouncementsService } from './services/announcements.service';
import { Themed } from '#/providers/themed';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.css'],
	providers: [Themed],
})
export class AppComponent extends BaseComponent implements OnInit {
	title = 'Klippa';

	constructor(
		private versionCheckService: VersionCheckService,
		private announcementService: AnnouncementsService,
		private routeUtilsService: RouteUtilsService,
		private outlets: ChildrenOutletContexts,
		private themed: Themed,
	) {
		super();
		this.title = themed.getThemeProductTitle();
	}

	ngOnInit() {
		this.router.events.subscribe((event) => {
			if (event instanceof NavigationStart) {
				// to support old urls based on angularJs, we can redirect them here to the new routes
				if (!!event.url && event.url.match(/^\/#\//)) {
					captureMessage(`Someone is still using old angularJs urls: ${event.url}`);
					const redirect = event.url.replace('/#/', '/');
					document.location.href = redirect;
				}
			}

			if (event instanceof ActivationStart) {
				if (event.snapshot.data.escapeToNavigateToParent === EscapeToNavigateToParentOption.ALWAYS) {
					document.addEventListener('keydown', this.navigateToParent);
				} else if (event.snapshot.data.escapeToNavigateToParent === EscapeToNavigateToParentOption.CONDITIONALLY) {
					document.addEventListener('keydown', this.navigateToParentConditionally);
				} else {
					document.removeEventListener('keydown', this.navigateToParent);
					document.removeEventListener('keydown', this.navigateToParentConditionally);
				}
			}

			if (event instanceof NavigationEnd) {
				const urlAndQueryParams = event.urlAfterRedirects.split('?');
				this.routeUtilsService.setQueryParamsForVisitedUrl(urlAndQueryParams[0], urlAndQueryParams[1]);
			}
		});

		this.initLanguage();
		this.versionCheckService.initVersionCheck('/version.json', 600000);
	}

	private navigateToParent = (ev) => {
		if (ev.key === 'Escape') {
			if (this.modalService.hasModalOpen()) {
				// when we already have a modal open, ESC should close that modal. We dont want to navigate back to parent
				return;
			}
			let childOutlet = this.outlets.getContext('primary');
			while (isValueSet(childOutlet.children.getContext('primary'))) {
				childOutlet = childOutlet.children.getContext('primary');
			}
			this.routeUtilsService.navigateToParentRoute(childOutlet.route);
		}
	};
	private navigateToParentConditionally = (ev) => {
		if (ev.key === 'Escape') {
			if (this.modalService.hasModalOpen()) {
				// when we already have a modal open, ESC should close that modal. We dont want to navigate back to parent
				return;
			}
			let childOutlet = this.outlets.getContext('primary');
			while (isValueSet(childOutlet.children.getContext('primary'))) {
				childOutlet = childOutlet.children.getContext('primary');
			}
			if (childOutlet.outlet.isActivated) {
				// @ts-ignore
				childOutlet.outlet.component.isAllowedToNavigateBackToParent?.()?.then((isAllowedToNavigateBack) => {
					if (isAllowedToNavigateBack) {
						this.routeUtilsService.navigateToParentRoute(childOutlet.route);
					}
				});
			}
		}
	};

	private initLanguage(): void {
		this.translate.setDefaultLang('en-gb');
		const browserLang = this.translate.getBrowserLang();
		let selectedLanguage: string;

		switch (browserLang) {
			case 'en':
				selectedLanguage = 'en-gb';
				break;
			case 'nl':
				selectedLanguage = 'nl-nl';
				break;
			case 'fr':
				selectedLanguage = 'fr-fr';
				break;
			case 'de':
				selectedLanguage = 'de-de';
				break;
			case 'es':
				selectedLanguage = 'es-es';
				break;
			case 'pt':
				selectedLanguage = 'pt-pt';
				break;
			case 'el':
				selectedLanguage = 'el-gr';
				break;
			default:
				selectedLanguage = 'en-gb';
		}

		this.translateUtilService.setLanguage(selectedLanguage);
	}
}
