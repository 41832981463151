import { AbstractControl, ValidationErrors } from '@angular/forms';
import { arrayIsSetAndFilled } from '#/util/arrays';

export function vatLinesRequired(control: AbstractControl): ValidationErrors | null {
	const valid =
		arrayIsSetAndFilled(control.value) &&
		control.value.some((e) => {
			return Number.isFinite(e.amountWithCurrency?.amount) && Number.isFinite(e.percentage);
		});
	return !valid ? { required: true } : null;
}
