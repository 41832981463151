import { Injectable } from '@angular/core';
import { APIService } from '../../api/services/api.service';
import { APINotificationsService } from '../../api/services/apinotifications.service';
import { Merchant } from '#/models/merchant';
import { AddMerchants, ClearMerchants } from './models/merchant.actions';
import { Store } from '@ngrx/store';
import { Order } from '#/models/utils/order';
import { CompanyService } from '#/services/company/company.service';
import { UserService } from '~/app/modules/user/user.service';
import { MerchantService as SharedMerchantService } from '#/services/merchant.service';

@Injectable({
	providedIn: 'root',
})
export class MerchantService extends SharedMerchantService {
	constructor(
		protected apiService: APIService,
		protected notifications: APINotificationsService,
		protected companyService: CompanyService,
		protected userService: UserService,
		private store: Store,
	) {
		super(apiService, notifications, companyService, userService);
	}

	getAllMerchants(): Promise<Merchant[]> {
		return this.getAllMerchantsPage();
	}

	getAllMerchantsPage(per_page: number = 100, page: number = 1, merchants: Merchant[] = []): Promise<Merchant[]> {
		return this.getMerchantsPage(per_page, page)
			.then((r) => {
				const loadedMerchants: any[] = r['data']['merchants'];

				if (loadedMerchants) {
					loadedMerchants.map((merchant) => merchants.push(new Merchant(merchant)));
				}

				// Load next page.
				if (r['data']['moreresults']) {
					return this.getAllMerchantsPage(per_page, page + 1, merchants);
				}

				return Promise.resolve(merchants);
			})
			.catch((r) => {
				return Promise.reject(r);
			});
	}

	getMerchantsPage(
		per_page: number = 100,
		page: number = 1,
		search: string = null,
		sort: string = 'title',
		sortOrder: Order = Order.ASCENDING,
	): Promise<any> {
		let url = '/api/v1/merchant?';
		url += 'sort=' + sort + '&';
		url += 'sortorder=' + sortOrder + '&';
		url += 'max=' + per_page + '&';
		url += 'start=' + per_page * (page - 1);

		if (search) {
			url += `&search=${search}`;
		}

		return this.apiService
			.get(url)
			.then((r) => {
				return Promise.resolve(r);
			})
			.catch((r) => {
				this.notifications.handleAPIError(r);
				return Promise.reject(r);
			});
	}

	public getAllCompanyOwnedMerchants(sort: string = 'title', sortOrder: Order = Order.ASCENDING): Promise<Array<Merchant>> {
		const url: string = '/api/v1/merchant/company?sort=' + sort + '&sortorder=' + sortOrder;
		return this.apiService
			.get(url)
			.then((r: { data: { merchants: Array<Merchant> } }) => {
				return r.data.merchants;
			})
			.catch((r) => {
				this.notifications.handleAPIError(r);
				throw r;
			});
	}

	getUnreviewedMerchants(per_page: number = 100, page: number = 1): Promise<any> {
		let url = '/api/v1/merchant/unreviewed?';
		url += 'max=' + per_page + '&';
		url += 'start=' + per_page * (page - 1);
		return this.apiService
			.get(url)
			.then((r) => {
				return Promise.resolve(r);
			})
			.catch((r) => {
				this.notifications.handleAPIError(r);
				return Promise.reject(r);
			});
	}

	getIgnoredMerchants(per_page: number = 100, page: number = 1): Promise<any> {
		let url = '/api/v1/merchant/ignored?';
		url += 'max=' + per_page + '&';
		url += 'start=' + per_page * (page - 1);
		return this.apiService
			.get(url)
			.then((r) => {
				return Promise.resolve(r);
			})
			.catch((r) => {
				this.notifications.handleAPIError(r);
				return Promise.reject(r);
			});
	}

	getAllPersonalMerchants(): Promise<Merchant[]> {
		return this.getAllPersonalMerchantsPage();
	}

	public getAllAvailableMerchants(): Promise<Array<Merchant>> {
		/* Gets all merchants that are available for this user */
		const result = this.getAllMerchants()
			.then((merchants) => {
				return this.getAllPersonalMerchants()
					.then((personal_merchants) => {
						return merchants.concat(personal_merchants).sort((a, b) => (a.title.toLowerCase() > b.title.toLowerCase() ? 1 : -1));
					})
					.then(async (allMerchants) => {
						return (await this.getAllCompanyOwnedMerchants())
							.concat(allMerchants)
							.sort((a, b) => (a.title.toLowerCase() > b.title.toLowerCase() ? 1 : -1));
					})
					.catch((r) => {
						this.notifications.handleAPIError(r);
						return Promise.reject(r);
					});
			})
			.catch((r) => {
				this.notifications.handleAPIError(r);
				return Promise.reject(r);
			});
		return result.then((res) => res.map((e) => new Merchant(e)));
	}

	getAllPersonalMerchantsPage(per_page: number = 100, page: number = 1, merchants: Merchant[] = []): Promise<Merchant[]> {
		return this.getPersonalMerchantsPage(per_page, page)
			.then((r) => {
				if (r['data']['merchants']) {
					const loadedMerchants: any[] = r['data']['merchants'];
					loadedMerchants.map((merchant) => merchants.push(new Merchant(merchant)));
				}

				// Load next page when it exists.
				if (r['data']['moreresults']) {
					return this.getAllPersonalMerchantsPage(per_page, page + 1, merchants);
				}

				return Promise.resolve(merchants);
			})
			.catch((r) => {
				return Promise.reject(r);
			});
	}

	getPersonalMerchantsPage(per_page: number = 100, page: number = 1, sort: string = 'title', sortOrder: Order = Order.ASCENDING) {
		let url = '/api/v1/merchant/personal?';
		url += 'sort=' + sort + '&';
		url += 'sortorder=' + sortOrder + '&';
		url += 'max=' + per_page + '&';
		url += 'start=' + per_page * (page - 1);
		return this.apiService
			.get(url)
			.then((r) => {
				return Promise.resolve(r);
			})
			.catch((r) => {
				this.notifications.handleAPIError(r);
				return Promise.reject(r);
			});
	}

	getLogo(id: string) {
		return this.apiService
			.getBlob('/api/v1/merchant/' + id + '/logo?w=45&h=45')
			.then((r) => {
				return Promise.resolve(r);
			})
			.catch((r) => {
				this.notifications.handleAPIError(r);
				return Promise.reject(r);
			});
	}

	public getMerchant(merchantID: string): Promise<Merchant> {
		return this.apiService
			.get(`/api/v1/merchant/${merchantID}`)
			.then((r) => {
				return Promise.resolve(new Merchant(r.data));
			})
			.catch((r) => {
				this.notifications.handleAPIError(r);
				return Promise.reject(r);
			});
	}

	patchMerchant(merchant: Merchant): Promise<Merchant> {
		const payload = {
			ignored: merchant.isIgnored(),
			reviewed: merchant.isReviewed(),
			synonyms: merchant.getSynonyms(),
			title: merchant.getTitle(),
		};
		return this.apiService
			.patch(`/api/v1/merchant/${merchant.getID()}`, payload)
			.then((r) => {
				return Promise.resolve(new Merchant(r.data));
			})
			.catch((r) => {
				this.notifications.handleAPIError(r);
				return Promise.reject(r);
			});
	}

	public createMerchant(merchant: Merchant): Promise<Merchant> {
		const payload = {
			ignored: merchant.isIgnored(),
			reviewed: merchant.isReviewed(),
			synonyms: merchant.getSynonyms(),
			title: merchant.getTitle(),
		};
		return this.apiService
			.post(`/api/v1/merchant`, payload)
			.then((r) => {
				return Promise.resolve(new Merchant(r.data));
			})
			.catch((r) => {
				this.notifications.handleAPIError(r);
				return Promise.reject(r);
			});
	}

	uploadLogo(merchant: Merchant, file: File): Promise<Merchant> {
		const formData = new FormData();
		formData.append('logo', file);
		return this.apiService
			.post(`/api/v1/merchant/${merchant.getID()}/logo`, formData)
			.then((r) => {
				return Promise.resolve(new Merchant(r.data));
			})
			.catch((e) => {
				this.notifications.handleAPIError(e);
				return Promise.reject(e);
			});
	}

	deleteMerchant(merchant: Merchant): Promise<any> {
		return this.apiService
			.delete(`/api/v1/merchant/${merchant.getID()}`)
			.then((r) => {
				return Promise.resolve(r);
			})
			.catch((e) => {
				this.notifications.handleAPIError(e);
				return Promise.reject(e);
			});
	}

	migrateMerchant(source: Merchant, target: Merchant): Promise<Merchant> {
		const payload = {
			newmerchant: target.getID(),
		};
		return this.apiService
			.post(`/api/v1/merchant/${source.getID()}/migrate`, payload)
			.then((r) => {
				return Promise.resolve(new Merchant(r.data));
			})
			.catch((e) => {
				this.notifications.handleAPIError(e);
				return Promise.reject(e);
			});
	}

	public initializeMerchants(): Promise<Array<void>> {
		this.store.dispatch(new ClearMerchants());

		return Promise.all([
			this.getAllMerchants().then((r) => {
				this.store.dispatch(new AddMerchants({ merchants: r }));
			}),

			this.getAllPersonalMerchants().then((r) => {
				this.store.dispatch(new AddMerchants({ merchants: r }));
			}),
		]);
	}
}
