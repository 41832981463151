import { UI_TYPE } from '#/models/uiType';
import { RelationType } from '#/models/accounting-integrations/accountingRelationType';
import { AccountingBookingType } from '#/models/transaction/bookingType';
import { BookedBatchStatus } from '#/models/accounting-integrations/bookingStatus';
import { Address } from '#/models/address.model';
import { DefaultFilters } from '#/models/defaultFilters.model';

export enum AccountingFormType {
	AUTHORIZATION_FORM = 'authorizationForm',
	PRE_CONNECTION_AUTHORIZATION_FORM = 'preConnectionAuthorizationForm',
	CONFIGURATION_FORM = 'configurationForm',
}

export enum AccountingFormFieldType {
	AUTH_FIELD = 'authFields',
	CONFIG_FIELD = 'configFields',
}

export enum AccountingStatus {
	PROCESSED = 'PROCESSED',
	BOOKED = 'BOOKED',
	PRE_BOOKING = 'PRE_BOOKING',
	QUEUED_FOR_BATCH = 'QUEUED_FOR_BATCH',
	BATCH_IS_PENDING = 'BATCH_IS_PENDING',
	BATCH_ERROR = 'BATCH_ERROR',
	QUEUED_FOR_BACKGROUND = 'QUEUED_FOR_BACKGROUND',
	BACKGROUND_ERROR = 'BACKGROUND_ERROR',
}

export enum AccountingCapability {
	supportsBookingTypes = 'supportsBookingTypes',
	supportsBookingBatches = 'supportsBookingBatches',
	supportsMultipleAttachments = 'supportsMultipleAttachments',
	syncBookingStatuses = 'syncBookingStatuses',
	allowsImportingValues = 'allowsImportingValues',
	needsReconnectAfterConnect = 'needsReconnectAfterConnect',
	supportsFetchingOfUserInfo = 'supportsFetchingOfUserInfo',
}

export enum ApiStatus {
	UNKNOWN = 'UNKNOWN',
	ONLINE = 'ONLINE',
	ISSUES = 'ISSUES',
	DOWN = 'DOWN',
}

export enum ConnectionStatus {
	UNKNOWN = 'UNKNOWN',
	CONNECTED = 'CONNECTED',
	DISCONNECTED = 'DISCONNECTED',
}

export interface AccountingIntegrationV2WithCompleteConfigFields {
	company: string;
	createDate: Date;
	description: string;
	id: string; // this is the authorization id
	integrationId: string;
	settings: Array<CompleteConfigField>;
	updateDate: Date;
	version: AccountingIntegrationV2Version;
	webhookSecret: string;
}

export class AccountingIntegrationConnectionResponse {
	public authorization: AccountingIntegrationV2Authorization;
	public needsReconnect: boolean;

	constructor(data: Record<string, any> = {}) {
		this.authorization = new AccountingIntegrationV2Authorization(data.authorization);
		this.needsReconnect = data.needsReconnect;
	}
}

export class AccountingIntegrationV2 {
	public id: string;
	public label: string;
	public description: string;
	public authenticationMethod: string;
	public capabilities: AccountingIntegrationV2Capabilities;

	constructor(data: Record<string, any> = {}) {
		this.id = data.id;
		this.label = data.label;
		this.description = data.description;
		this.authenticationMethod = data.authenticationMethod;
		this.capabilities = new AccountingIntegrationV2Capabilities(data.capabilities);
	}
}

export class AccountingIntegrationV2Capabilities {
	public supportsBookingTypes: Array<AccountingBookingType>;
	public supportsBookingBatches: Record<string, any>;
	public supportsMultipleAttachments: boolean;
	public syncBookingStatuses: boolean;
	public allowsImportingValues: boolean;
	public needsReconnectAfterConnect: boolean;
	public supportsFetchingOfUserInfo: boolean;

	constructor(data: Record<string, any> = {}) {
		this.supportsBookingTypes = data.supportsBookingTypes;
		this.supportsBookingBatches = data.supportsBookingBatches;
		this.supportsMultipleAttachments = data.supportsMultipleAttachments;
		this.syncBookingStatuses = data.syncBookingStatuses;
		this.allowsImportingValues = data.allowsImportingValues;
		this.needsReconnectAfterConnect = data.needsReconnectAfterConnect;
		this.supportsFetchingOfUserInfo = data.supportsFetchingOfUserInfo;
	}
}

abstract class SuperBasicConfigInfo {
	public uiType: UI_TYPE;
	public key: string;

	constructor(data: Record<string, any> = {}) {
		this.uiType = data.uiType;
		this.key = data.key;
	}
}

export class BasicConfigInfo extends SuperBasicConfigInfo {
	public value: string;

	constructor(data: Record<string, any> = {}) {
		super(data);
		this.value = data.value;
	}
}

export class CompleteConfigField extends BasicConfigInfo {
	public label: string;
	public description: string;
	public options: ConfigFieldOptions;
	public relations?: Array<{
		key: string;
		types: Array<RelationType>;
	}>;

	constructor(data: Record<string, any> = {}) {
		super(data);
		this.label = data.label;
		this.description = data.description;
		this.options = new ConfigFieldOptions(data.options);
		this.relations = data.relations;
	}
}

export class ConfigFieldOptions {
	public creatable?: boolean;
	public validatable?: boolean;
	public required?: boolean;
	public defaultable?: boolean;
	public readOnly?: boolean;
	public refresh?: boolean;
	public suggestable?: boolean;

	constructor(data: Record<string, any> = {}) {
		this.creatable = data.creatable;
		this.validatable = data.validatable;
		this.required = data.required;
		this.defaultable = data.defaultable;
		this.readOnly = data.readOnly;
		this.refresh = data.refresh;
		this.suggestable = data.suggestable;
	}
}

export class AccountingIntegrationConfigurationField extends SuperBasicConfigInfo {
	public label: string;
	public description: string;
	public options: ConfigFieldOptions;
	public relations?: Array<{
		key: string;
		types: Array<RelationType>;
	}>;

	constructor(data: Record<string, any> = {}) {
		super(data);
		this.label = data.label;
		this.description = data.description;
		this.options = new ConfigFieldOptions(data.options);
		this.relations = data.relations;
	}
}

export class AccountingIntegrationV2Authorization {
	public company: string;
	public createDate: Date;
	public description: string;
	public id: string; // this is the authorization id
	public integrationId: string;
	public settings: Record<string, any>; // any is necessary since the settings are fully dynamic
	public updateDate: Date;
	public version: AccountingIntegrationV2Version;
	public webhookSecret: string;

	constructor(data: Record<string, any> = {}) {
		this.company = data.company;
		this.createDate = data.createDate;
		this.description = data.description;
		this.id = data.id;
		this.integrationId = data.integrationId;
		this.settings = data.settings;
		this.updateDate = data.updateDate;
		this.version = new AccountingIntegrationV2Version(data.version);
		this.webhookSecret = data.webhookSecret;
	}
}

export class AccountingIntegrationV2Version {
	public major: number;
	public minor: number;

	constructor(data: Record<string, any> = {}) {
		this.major = data.major;
		this.minor = data.minor;
	}
}

export class AccountingIntegrationV2AuthorizationUpdateRequest {
	public description: string;
	public settings: Record<string, any>; // any is necessary since the settings are fully dynamic
	public version: AccountingIntegrationV2Version;

	constructor(data: Record<string, any> = {}) {
		this.description = data.description;
		this.settings = data.settings;
		this.version = new AccountingIntegrationV2Version(data.version);
	}
}

export class QueuedForBookingResponse {
	public authorization: string;
	public bookedBy: string;
	public bookedOn: string;
	public headers: Record<string, any>;
	public lines: Array<Record<string, any>>;
	public status: AccountingStatus;
	public version: AccountingIntegrationV2Version;
	constructor(data: Record<string, any> = {}) {
		this.authorization = data.authorization;
		this.bookedBy = data.bookedBy;
		this.bookedOn = data.bookedOn;
		this.headers = data.headers;
		this.lines = data.lines;
		this.status = data.status;
		this.version = new AccountingIntegrationV2Version(data.version);
	}
}

export class QueuedBookingsFilters extends DefaultFilters {
	public status: AccountingStatus | 'ALL'; // All is not a valid Accounting status but needed for showing all statuses in dashboard
	public sort: AccountingStatus;

	constructor(data: Record<string, any> = {}) {
		super();
		this.status = data.status ?? AccountingStatus.QUEUED_FOR_BATCH;
		this.sort = data.sort ?? AccountingStatus.QUEUED_FOR_BATCH;
	}
}

export class BookedBatchesFilters extends DefaultFilters {
	public status: BookedBatchStatus | 'ALL'; // All is not a valid Accounting status but needed for showing all statuses in dashboard;
	public sort: BookedBatchStatus;

	constructor(data: Record<string, any> = {}) {
		super();
		this.status = data.status ?? null;
		this.sort = data.sort ?? 'status';
	}
}

export interface BookedBatchOfTransactionsResponse {
	authorization: string;
	company: string;
	id: string;
	bookedBy: string;
	bookedOn: Date;
	createDate: Date;
	updateDate: Date;
	externalId: string;
	failures: Array<string>;
	status: BookedBatchStatus;
	transaction: Array<string>;
}

export interface IndividualPickerValue {
	code: string;
	name: string;
	identifier: string;
	description?: string;
}

export class BookedBatch {
	public name: string;
	public authorization: string;
	public company: string;
	public id: string;
	public bookedBy: string;
	public bookedOn: Date;
	public createDate: Date;
	public updateDate: Date;
	public externalId: string;
	public failures: Array<BookedBatchFailure>;
	public status: BookedBatchStatus;
	public transactions: Array<string>;

	constructor(data: Record<string, any> = {}) {
		this.name = data.name;
		this.authorization = data.authorization;
		this.company = data.company;
		this.id = data.id;
		this.bookedBy = data.bookedBy;
		this.bookedOn = data.bookedOn;
		this.createDate = data.createDate;
		this.updateDate = data.updateDate;
		this.failures = data.failures;
		this.status = data.status;
		this.transactions = data.transactions;
	}
}

export class StaticDashboardColumnData {
	public addresses: Array<Address>;
	public chamberOfCommerceNumber: string;
	public code: string;
	public description: string;
	public emails: Array<string>;
	public identifier: string;
	public name: string;
	public paymentInfo: Array<string>;
	public phoneNumbers: Array<string>;
	public vatNumber: string;
	public vatPercentage: number;
	public websites: Array<string>;

	constructor(data: Record<string, any> = {}) {
		this.addresses = data.addresses;
		this.chamberOfCommerceNumber = data.chamberOfCommerceNumber;
		this.code = data.code;
		this.description = data.description;
		this.emails = data.emails;
		this.identifier = data.identifier;
		this.name = data.name;
		this.paymentInfo = data.paymentInfo;
		this.phoneNumbers = data.phoneNumbers;
		this.vatNumber = data.vatNumber;
		this.vatPercentage = data.vatPercentage;
		this.websites = data.websites;
	}
}

export interface BookedBatchFailure {
	message: string;
	transaction: string;
}

export interface UserInfo {
	identifier: string;
	name: string;
	username: string;
}

export interface ConnectionInfo {
	additionalFields: Array<{ label: string; value: string }>;
	apiStatus: ApiStatus;
	apiStatusUrl: string;
	connectionStatus: ConnectionStatus;
	expirationDate: Date;
}

export enum StaticColumns {
	IDENTIFIER = 'identifier',
	CODE = 'code',
	NAME = 'name',
	DESCRIPTION = 'description',
	VAT_PERCENTAGE = 'vatPercentage',
	ADDRESSES = 'addresses',
	WEBSITES = 'websites',
	EMAILS = 'emails',
	PHONE_NUMBERS = 'phoneNumbers',
	PAYMENT_INFOS = 'paymentInfos',
	CHAMBER_OF_COMMERCE_NUMBER = 'chamberOfCommerceNumber',
	VAT_NUMBER = 'vatNumber',
}
