export class AuthorizationFlowApiModel {
	approvals: Array<string>;
	approvers: Array<string>;
	flow_id: string;
	in_flow: boolean;
	next_approvers: Array<string>;
	require_approver_count: number;
	require_approver_order: boolean;
	waiting_approvers: Array<string>;

	constructor(data?: any) {
		Object.assign(this, data);
	}
}
