<ng-container *ngIf="bindLabel">
	<ng-select
		id="costcenters"
		name="costcenters"
		placeholder="{{ 'Select %item%' | translate: { item: translationReplaceService.costCenter | lowercase } }}"
		[items]="options"
		[disabled]="disabled"
		[(ngModel)]="costCenter"
		[multiple]="multiple"
		[bindLabel]="bindLabel"
		bindValue="id"
		[selectOnTab]="true"
		[clearable]="true"
		[loading]="loading"
		[trackByFn]="trackSelectOption"
		*ngIf="!useTypeAhead"
	>
		<ng-template let-index="index" let-item="item" let-search="searchTerm" ng-option-tmp>
			<div>
				{{ item[bindLabel] }}
				<div *ngIf="item.description && !company.hideCompanyUnitDescription()" class="dropdown-item-description">
					{{ item.description }}
				</div>
			</div>
		</ng-template>
	</ng-select>

	<ng-select
		id="costcenters"
		name="costcenters"
		placeholder="{{ 'Start typing to search for a %item%' | translate: { item: translationReplaceService.costCenter | lowercase } }}"
		[items]="options"
		[disabled]="disabled"
		[typeahead]="selectOptionsTypeahead"
		[(ngModel)]="costCenter"
		[multiple]="multiple"
		[bindLabel]="bindLabel"
		bindValue="id"
		[selectOnTab]="true"
		[clearable]="true"
		[loading]="loading"
		[trackByFn]="trackSelectOption"
		*ngIf="useTypeAhead"
	>
		<ng-template let-index="index" let-item="item" let-search="searchTerm" ng-option-tmp>
			<div>
				{{ item[bindLabel] }}
				<div *ngIf="item.description && !company.hideCompanyUnitDescription()" class="dropdown-item-description">
					{{ item.description }}
				</div>
			</div>
		</ng-template>
	</ng-select>
</ng-container>
