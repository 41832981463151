<klp-grid-container [fluid]="true">
	<app-nav-and-cta>
		<klp-gretel-bar></klp-gretel-bar>
		<div class="header-actions">
			<app-button variant="greenFilled" (click)="openFilters()">
				<span translate>Filters</span>
				<app-filters-count [requestQuery]="receiptListAPIRequest"></app-filters-count>
			</app-button>
		</div>
	</app-nav-and-cta>
	<section>
		<div class="card">
			<div class="card-block">
				<ngx-async-template [promise]="reportReceiptsPromise">
					<ng-template ngx-async-error let-errorMessages="errorMessages">
						<app-error-message [errorMessages]="errorMessages"></app-error-message>
					</ng-template>
					<ng-template ngx-async-pending>
						<app-loader-deprecated></app-loader-deprecated>
					</ng-template>
					<ng-template ngx-async-success let-reportReceipts="value">
						<ngx-async-template [promise]="reportPromise" [successStateOnReload]="true">
							<ng-template ngx-async-success let-report="value">
								<div *ngIf="showSelectActions" class="actionMenu">
									<app-select-action
										[actions]="getSelectActions"
										[selected]="selected"
										(onAction)="executeRequestedSelectAction($event, report.id)"
									>
									</app-select-action>
								</div>
								<app-table
									[total]="reportReceipts.data.count"
									[start]="this.receiptListAPIRequest.start"
									[max]="this.receiptListAPIRequest.max"
									[sortingParameters]="{ sort: receiptListAPIRequest.sort, sortorder: receiptListAPIRequest.sortorder }"
									[rows]="reportReceipts.data.receipts"
									[columns]="columns"
									[totalRow]="report"
									[totalColumns]="totalColumns"
									typeName="transaction"
									[itemName]="'transaction' | translate"
									[itemsName]="'transactions' | translate"
									[hasClickableRows]="true"
									[selectAllItemFn]="selectAllTransactionsOnAllPages"
									(clickRow)="openEditModalWithReceiptId($event)"
									[rowActions]="getRowActions"
									(onRowAction)="executeRequestedRowAction($event)"
									(navigateTable)="navigateTable($event)"
									(sortTable)="sortTable($event)"
									(openSelectActions)="openSelectActions($event)"
									(onSortingArrowClicked)="fetchSortedColumns($event); updateRouteWithSortingParams($event)"
								>
								</app-table>
								<div class="table-action-footer" *ngIf="user.hasCompanyFinanceRole()">
									<div class="reportBookingBtnContainer" *ngIf="hasAtLeastOneActiveIntegration">
										<app-button *ngIf="shouldShowStartBooking()" variant="greenFilled" (click)="openReportBookingsSettingsModal()">
											<span translate>Start booking</span>
										</app-button>
										<app-button *ngIf="shouldShowEditBooking()" variant="white" (click)="openReportBookingsSettingsModal()">
											<span translate>Edit booking</span>
										</app-button>
										<ngx-async-template [promise]="bookPromise">
											<ng-template ngx-async-all let-status="status">
												<app-button
													*ngIf="shouldShowBookReport()"
													variant="greenFilled"
													(click)="bookReport()"
													[isLoading]="status === 'pending'"
													[disabled]="status === 'pending'"
												>
													<span translate>Book report</span>
												</app-button>
											</ng-template>
										</ngx-async-template>
									</div>
									<app-booking-status-badge
										*ngIf="reportIsBookedAAv1()"
										[bookingData]="report.booking_data"
										[declarationStatus]="report.expense_status?.current_status?.status"
										[company]="company"
										variant="report"
									>
									</app-booking-status-badge>
									<app-booking-status-badge *ngIf="reportIsBookedAAv2()" [bookingData]="getBookedStatusAAv2()"></app-booking-status-badge>
								</div>
							</ng-template>
						</ngx-async-template>
					</ng-template>
				</ngx-async-template>
			</div>
		</div>
	</section>
</klp-grid-container>

<ng-template #changeStatusReceiptsModal>
	<app-change-status-modal
		(onDismiss)="closeChangeStatusModal()"
		(onSave)="saveReceiptStatus($event)"
		[filterOnStatuses]="getStatusesToChooseFrom(this.selected.rows) | async"
	>
		<ng-template>
			<span *ngIf="selected.rows.length === 1" translate> Updating status of 1 receipt </span>
			<span *ngIf="selected.rows.length > 1" [translateParams]="{ count: selected.rows.length }" translate>
				Updating status of %count% receipts
			</span>
		</ng-template>
	</app-change-status-modal>
</ng-template>

<div *ngIf="showFilters">
	<app-filters
		[requestQuery]="receiptListAPIRequest"
		[filtersLocalStorageKey]="filtersLocalStorageKey"
		[filterOptions]="filterOptions"
		(onDismiss)="closeFilters()"
		(onSave)="updateRequestFilters($event)"
	>
	</app-filters>
</div>

<ng-template #bookReportModal>
	<ngx-async-template [promise]="reportPromise">
		<ng-template ngx-async-success let-report="value">
			<app-expense-report-book-modal (onDismiss)="closeBookReportModal()" (onSave)="fetchReceiptsAndGetFirst()" [report]="report">
			</app-expense-report-book-modal>
		</ng-template>
	</ngx-async-template>
</ng-template>
