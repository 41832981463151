import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MfaConfigModalService } from '~/app/services/mfa-config-modal.service';
import { Observable } from 'rxjs';
import { ModalOptions, ModalService } from '~/app/services/modal.service';

@Component({
	selector: 'app-mfa-config-modal',
	templateUrl: './mfa-config-modal.component.html',
	styleUrls: ['./mfa-config-modal.component.scss'],
})
export class MfaConfigModalComponent implements OnInit {
	private showModal: Observable<ModalOptions>;
	@ViewChild('mfaModal') mfaModal: ElementRef;

	constructor(private mfaModalService: MfaConfigModalService, private modalService: ModalService) {}

	ngOnInit(): void {
		this.showModal = new Observable<ModalOptions>((subscriber) => {
			this.mfaModalService.registerShowModal(subscriber);
		});

		this.showModal.subscribe((options) => {
			this.show(options);
		});
	}

	show(options?: ModalOptions) {
		this.modalService.open(this.mfaModal, options);
	}

	dismiss() {
		this.modalService.dismiss(this.mfaModal);
	}

	close() {
		this.modalService.close(this.mfaModal);
	}

	onMFAConfigure() {
		this.mfaModalService.resolve();
		this.close();
	}
}
